/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Pencil1Icon } from '@radix-ui/react-icons'
import { PopoverClose } from '@radix-ui/react-popover'
import { useAppSelector } from 'app/hooks'
import Button from 'components/Buttons/Button'
import { Input } from 'components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { selectCurrencyOptions } from 'features/authentication/authentication.slice'
import type { Amount } from 'interfaces'
import { useState } from 'react'
import { displayAmount } from 'utils/currency'
import { ComboSelect } from './ComboSelect'

interface Props {
	onSave?: (value?: Amount) => void
	defaultValue?: Amount
	placeholder?: string
	content?: React.ReactNode
	showEditButton?: boolean
	title?: string
	id?: string
	isReadOnly?: boolean
	shouldConvert?: boolean
}

export default function SpokAmountInput({
	defaultValue,
	onSave,
	placeholder,
	content,
	showEditButton = true,
	id,
	title,
	isReadOnly,
	shouldConvert = false
}: Props) {
	const currencyOptions = useAppSelector(selectCurrencyOptions)
	const [amount, setAmount] = useState(defaultValue?.amount || 0)
	const [currency, setCurrency] = useState(defaultValue?.currency || 'USD')

	if (isReadOnly)
		return (
			<div>
				{content ? (
					<div className='group flex items-center gap-2'>{content}</div>
				) : (
					<div className='group flex w-fit items-center gap-1'>
						{displayAmount({ currency, amount }, shouldConvert)}
					</div>
				)}
			</div>
		)

	return (
		<Popover>
			<div className='group flex items-center gap-1'>
				<div className='cursor-pointer truncate text-sm'>
					{content ? (
						<div
							data-testid={`trigger-${id}`}
							className='group flex items-center gap-2'
						>
							{content}{' '}
						</div>
					) : (
						<div
							data-testid={`trigger-${id}`}
							className='group flex w-fit items-center gap-1 truncate'
						>
							{displayAmount({ currency, amount }, shouldConvert)}
						</div>
					)}
				</div>
				<PopoverTrigger asChild>
					{showEditButton && (
						<Pencil1Icon className='cursor-pointer opacity-20 group-hover:opacity-100' />
					)}
				</PopoverTrigger>
			</div>
			<PopoverContent
				align='start'
				className='z-[999999] flex w-auto flex-col space-y-2 rounded p-2'
			>
				<div>
					{title && <div className='text-sm font-semibold'>{title}</div>}
				</div>

				<div className='flex gap-1 rounded-md border bg-gray-50 p-4'>
					<ComboSelect
						label='Currency'
						onSelect={value => {
							setCurrency(value)
						}}
						defaultValue={currency}
						options={currencyOptions}
						content={
							<div className='mr-3 text-base font-semibold text-blue-700'>
								{currency}
							</div>
						}
					/>
					<Input
						id={id}
						label='Amount'
						type='number'
						className='w-96 rounded-md border'
						placeholder={placeholder}
						value={amount}
						onChange={e => {
							setAmount(Number(e.target.value))
						}}
					/>
				</div>

				<PopoverClose className='w-fit rounded-md'>
					<div className='flex items-center justify-center gap-2'>
						<Button
							id={`cancel-${id}`}
							variant='ghost'
							className='rounded px-5 text-red-500'
							onClick={() => {
								setAmount(defaultValue?.amount || 0)
							}}
						>
							Cancel
						</Button>
						<Button
							id={`save-${id}`}
							variant='blue'
							className='rounded px-5'
							onClick={() => {
								onSave?.({ currency, amount })
							}}
						>
							Save
						</Button>
					</div>
				</PopoverClose>
			</PopoverContent>
		</Popover>
	)
}
