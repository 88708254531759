/* eslint-disable @typescript-eslint/no-explicit-any */

import { useAppSelector } from 'app/hooks'
import { AuthContext } from 'context/AuthContext'
import { useFormik } from 'formik'
import { nanoid } from 'nanoid'
import { useContext } from 'react'
import { toast } from 'sonner'
import { getColorCode } from 'utils/colors'
import * as Yup from 'yup'
import { addFunnel } from '../funnels.api'
import type { IFunnel } from '../funnels.interface'
import { selectFunnelCount } from '../funnels.slice'

export default function useCreateFunnel({
	onSuccess,
	type,
	group
}: {
	type: 'company' | 'prospect' | 'task'
	onSuccess?: () => void
	group: string
}) {
	const { authUser } = useContext(AuthContext)

	const count = useAppSelector(selectFunnelCount)

	const validationSchema = Yup.object({
		name: Yup.string().required('Name is required')
		// .test('unique', 'Company already exists', async value => {
		// 	try {
		// 		const exists = await checkIfCompanyExists(
		// 			value,
		// 			value.toLowerCase().replaceAll(' ', '-')
		// 		)
		// 		return !exists
		// 	} catch {
		// 		return false
		// 	}
		// })
	})

	const formik = useFormik({
		initialValues: {
			name: ''
		},
		validationSchema,
		onSubmit: async values => {
			if (!authUser) return
			try {
				formik.setSubmitting(true)

				const funnel: IFunnel = {
					id: nanoid(),
					name: values.name,
					slug: values.name.toLowerCase().replaceAll(' ', '-'),
					color: `#${getColorCode(values.name)}`,
					index: count + 1,
					category: group || 'inbound',
					fund: authUser.fund,
					creator: {
						id: authUser.id,
						name: authUser.name,
						photoUrl: authUser.photoUrl ?? ''
					},
					isDefault: false,
					isArchived: false,
					type,
					createdAt: new Date().toISOString(),
					lastUpdatedAt: new Date().toISOString()
				}

				await addFunnel(funnel)
				toast.success('Funnel Created')
				onSuccess?.()
			} catch (error: any) {
				toast.error(error.message)
			} finally {
				formik.setSubmitting(false)
				formik.resetForm()
			}
		}
	})

	return { formik }
}
