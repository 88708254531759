/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AppThunk } from 'app/store'
import { setTag, setTags } from 'features/tags/tags.slice'
import type { Unsubscribe } from 'firebase/firestore'
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	where
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import type { ITag } from 'interfaces'
import { firestore, functions } from 'lib/firebase'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'

const tagsRef = collection(firestore, 'tags')

export const addTag = async (tag: ITag): Promise<ITag> => {
	const docRef = doc(tagsRef, tag.id)
	await setDoc(docRef, tag, { merge: true })

	return tag
}

export const getTag = async (id: string): Promise<ITag> => {
	const docRef = doc(tagsRef, id)
	const docSnap = await getDoc(docRef)
	return docSnap.data() as ITag
}

export const deleteTag = async (id: string): Promise<void> => {
	const docRef = doc(tagsRef, id)
	await deleteDoc(docRef)
}

export const listenToTag =
	(id: string): AppThunk<Unsubscribe> =>
	dispatch => {
		dispatch(setTag({ data: null, status: 'loading' }))

		const docRef = doc(tagsRef, id)
		const unsubscribe = onSnapshot(
			docRef,
			docSnap => {
				if (!docSnap.exists()) {
					dispatch(
						setTag({
							data: null,
							status: 'failed',
							error: 'Tag not found'
						})
					)
					return
				}

				const tag = docSnap.data() as ITag
				dispatch(setTag({ data: tag, status: 'success' }))
			},
			error => {
				Errorhandler.captureException(error)
				Logger.error(error)
			}
		)

		return unsubscribe
	}

export const getTags = async (fundId: string): Promise<ITag[]> => {
	const q = query(tagsRef, where('fund.id', '==', fundId))
	const querySnapshot = await getDocs(q)
	const tags = querySnapshot.docs.map(d => d.data() as ITag)
	return tags
}

export const updateTag = async (tag: ITag): Promise<ITag> => {
	const docRef = doc(tagsRef, tag.id)
	await updateDoc(docRef, tag as any, { merge: true })
	return tag
}

export const listenToTags =
	(fundId: string): AppThunk<Unsubscribe> =>
	dispatch => {
		const q = query(tagsRef, where('fund.id', '==', fundId))
		const unsubscribe = onSnapshot(q, querySnapshot => {
			const tags = querySnapshot.docs.map(d => d.data() as ITag)
			dispatch(setTags(tags))
		})

		return unsubscribe
	}

interface CheckIfTagExistsData {
	name: string
	fundId: string
}

interface CheckIfTagExistsResult {
	exists: boolean
}

const checkIfTagExistsFunction = httpsCallable<
	CheckIfTagExistsData,
	CheckIfTagExistsResult
>(functions, 'checkIfTagExists')

/**
 * Checks if a tag exists.
 *
 * @param name - The name of the tag.
 * @param fundId - The fund ID.
 *
 * @returns {Promise<CheckIfTagExistsResult>} A Promise that resolves to the result of the check.
 */
export const checkIfTagExists = async (
	name: string,
	fundId: string
): Promise<boolean> => {
	const result = await checkIfTagExistsFunction({ name, fundId })
	return result.data.exists
}
