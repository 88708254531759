/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-extraneous-class */
import axiosInstance from 'lib/axios'
import Logger from 'lib/logger'
import Errorhandler from 'lib/sentry'
import { toast } from 'sonner'

export class GoogleMailService {
	public static authorize = async (code: string) => {
		try {
			toast.loading('Authorizing Gmail...')

			const { data: response } = await axiosInstance.post(
				'/google-mail/authorize',
				{ code }
			)

			toast.dismiss()
			if (response.success) toast.success('Gmail connected successfully!')
			if (!response.success) toast.error(response.message)
		} catch (error: any) {
			const message = error.response?.data?.message ?? error.message

			toast.dismiss()
			toast.error(message ?? 'An error occurred')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}
}
