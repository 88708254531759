import clsxm from 'utils/clsxm'
import type { ITask } from '../interafces/task.interface'
import { useMemo } from 'react'
import { Clock, UserCircle2 } from 'lucide-react'
import { format, isPast } from 'date-fns'
import markdownToHTML from 'markdown-to-htm'
import { ImFlag } from 'react-icons/im'
import { motion } from 'framer-motion'
import { doc, setDoc } from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'

interface TaskCardProps {
	task: ITask
	isDragging?: boolean
	onCardClick: (task: ITask) => void
}

function TaskCard({ task, isDragging, onCardClick }: Readonly<TaskCardProps>) {
	const content = useMemo(() => {
		const description: string = markdownToHTML(task.description)
			.replaceAll(/<img.*[^>]*>/g, '\n[image]\n')
			.replaceAll(/style="[^"]*"/g, '')
			.replaceAll(/<table [^>]*>.*<\/table>/g, '[Table]')
		// .replaceAll(/^<\/?.*\/?>$/g, '')

		return description.trim().length > 0 ? description : '[No content]'
	}, [task])

	async function flagTask(evt: React.MouseEvent<HTMLButtonElement>) {
		evt.stopPropagation()

		try {
			const docRef = doc(firestore, `tasks/${task.id}`)
			await setDoc(docRef, { flagged: !task.flagged }, { merge: true })
		} catch (error) {
			Logger.error(error)
		}
	}

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
		<div
			className={clsxm([
				'flex flex-col gap-1 rounded  border border-gray-300 bg-white p-2.5',
				' text-gray-800  transition-all hover:bg-gray-50 ',
				'hover:border-gray-200 dark:hover:border-gray-600',
				'hover:cursor-pointer dark:bg-[#374151] dark:text-white',
				'h-full w-full',
				{
					'border-green-500': isDragging,
					'border-red-500 bg-[#FFF8F2] hover:border-red-400 hover:bg-[#FFF8F2]':
						isPast(task.dueDate),
					'border-green-500  bg-[#F7FFF2] hover:border-green-400  hover:bg-[#F7FFF2]':
						task.flagged
				}
			])}
			role='button'
			onClick={() => onCardClick(task)}
		>
			<div className='flex items-center gap-x-2'>
				{task.executor?.photoUrl ? (
					<img
						src={task.executor.photoUrl}
						alt='executor'
						className='h-auto w-6 rounded-full object-cover'
					/>
				) : (
					<UserCircle2 size={24} className='text-gray-400' />
				)}

				<span className='block  max-w-[200px] rounded-lg bg-blue-100 px-2 py-1 text-xs font-semibold text-blue-600'>
					{task.executor?.name ?? 'No executor'}
				</span>
				<motion.button whileHover={{ scale: 1.3 }} onClick={flagTask}>
					<ImFlag
						size={12}
						className={clsxm('text-gray-400', {
							'text-green-500': task.flagged
						})}
					/>
				</motion.button>
			</div>
			<div className=' flex flex-1 flex-wrap text-sm text-gray-500'>
				<div
					className=' line-clamp-2 text-wrap text-sm'
					dangerouslySetInnerHTML={{ __html: content }}
				/>
			</div>

			<div className='flex items-center justify-between'>
				<div className='flex items-center gap-x-2 text-xs'>
					<Clock
						className={clsxm('h-4 w-4 text-blue-500', {
							'text-red-500': isPast(task.dueDate),
							'text-green-500': task.flagged
						})}
					/>
					<span className='font-medium'>
						{format(task.dueDate, 'dd/LL/yyyy hh:mm aa')}
					</span>
				</div>
			</div>
		</div>
	)
}

export default TaskCard
