import { Wizard, useWizard } from 'react-use-wizard'
import {
	selectIsEmailAddressesNextStepDisable,
	selectIsPaymentDetailsNextStepDisable,
	selectIsRecipientNextStepDisable,
	selectIsRecipientsCapitalToBeCalledStepDisable,
	selectSetIsNewCapitalCallSaved,
	setIsNewCapitalCallSaved,
	setRecipientCapitalToBeCalled,
	setRecipients,
	setRecipientsEmailEditor,
	setRecipientsPaymentDetails,
	setRecipientsSelectedEmailAddress
} from '../redux/newCapitalCall.slice'
import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { CapitalCallTemplateService } from 'features/settings/CapitalCallTemplates/service/CapitalCallTemplate.service'
import DashboardLayout from 'components/Dashboard/DashboardLayout'
import type { IEmailEditor } from '../interfaces/NewCapitalCall.interface'
import NewCapitalCallAccountEmailEditorStep from './NewCapitalCallAccountEmailEditorStep'
import NewCapitalCallAccountForPaymentStep from './NewCapitalCallAccountForPaymentStep'
import NewCapitalCallCapitalTobeCalledStep from './NewCapitalCallCapitalTobeCalledStep'
import NewCapitalCallEmailAddressesStep from './NewCapitalCallEmailAddressesStep'
import NewCapitalCallRepcientsStep from './NewCapitalCallRecipientsStep'
import clsx from 'clsx'
import { listenToBankAccounts } from 'features/bank_accounts/api/bank-accounts.api'
import { listenToCryptoAccounts } from 'features/crypto_accounts/api/crypto-accounts.api'
import { setCapitalCallSettings } from 'features/settings/CapitalCallTemplates/redux/capital_call_template.slice'
import { store } from 'app/store'
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
// import Button from 'components/Buttons/Button';
import { useAppSelector } from 'app/hooks'

function WizardNavigationHeader() {
	const { authUser } = useContext(AuthContext)

	const { activeStep, goToStep } = useWizard()

	const isRecipientNextStepDisable = useAppSelector(
		selectIsRecipientNextStepDisable
	)
	const isEmailAddressesNextStepDisable = useAppSelector(
		selectIsEmailAddressesNextStepDisable
	)
	const isPaymentDetailsNextStepDisable = useAppSelector(
		selectIsPaymentDetailsNextStepDisable
	)

	const isRecipientsCapitalToBeCalledStepDisable = useAppSelector(
		selectIsRecipientsCapitalToBeCalledStepDisable
	)

	const isNewCapitalCallSaved = useAppSelector(selectSetIsNewCapitalCallSaved)

	useEffect(() => {
		return () => {
			store.dispatch(setRecipientsSelectedEmailAddress({}))
			store.dispatch(setRecipients([]))
			store.dispatch(setRecipientsPaymentDetails({}))
			store.dispatch(setRecipientsEmailEditor({} as IEmailEditor))
			store.dispatch(setRecipientCapitalToBeCalled({}))
			store.dispatch(setIsNewCapitalCallSaved(false))
			store.dispatch(setCapitalCallSettings(null))
		}
	}, [])

	useEffect(() => {
		if (!authUser?.fund.id) return
		
		const cryptoUnsubscribe = listenToCryptoAccounts(authUser.fund.id)
		const bankUnsubscribe = listenToBankAccounts(authUser.fund.id)

		return () => {
			cryptoUnsubscribe()
			bankUnsubscribe()
		}
	}, [authUser?.fund.id])

	useEffect(() => {
		if (!authUser) return
		const unsubscribe =
			CapitalCallTemplateService.listenToCapitalCallTemplateSettings({
				fundId: authUser.fund.id
			})
		return () => unsubscribe()
	}, [authUser?.fund.id])

	return (
		<div className='mx-auto flex max-w-[1200px] flex-col bg-white pt-10'>
			<div className='relative'>
				<div
					className={clsx(
						'h-[6px] bg-gradient-to-r from-white to-blue-600',
						activeStep + 1 === 5 && 'w-[92%]',
						activeStep + 1 === 4 && 'w-[70%]',
						activeStep + 1 === 3 && 'w-[50%]',
						activeStep + 1 === 2 && 'w-[30%]',
						activeStep + 1 === 1 && 'w-[10%]'
					)}
				/>
				<div
					className={clsx(
						'absolute top-[-5px] z-10 h-4 w-4 rounded-full border border-white bg-black',
						activeStep + 1 === 5 && 'left-[92%]',
						activeStep + 1 === 4 && 'left-[69%]',
						activeStep + 1 === 3 && 'left-[49%]',
						activeStep + 1 === 2 && 'left-[29%]',
						activeStep + 1 === 1 && 'left-[9%]'
					)}
				/>
			</div>
			<div className='flex w-full justify-around'>
				<div className='flex flex-1 items-center justify-center'>
					<Button
						variant='ghost'
						className={`${
							activeStep + 1 === 1 && 'text-blue-700'
						} font-semibold`}
						disabled={isNewCapitalCallSaved}
						onClick={() => goToStep(0)}
					>
						Recipients
					</Button>
				</div>
				<div className='flex flex-1 items-center justify-center'>
					<Button
						variant='ghost'
						className={`${
							activeStep + 1 === 2 && 'text-blue-700'
						} font-semibold`}
						disabled={isRecipientNextStepDisable || isNewCapitalCallSaved}
						onClick={() => goToStep(1)}
					>
						Email Addresses
					</Button>
				</div>
				<div className='flex flex-1 items-center justify-center'>
					<Button
						variant='ghost'
						className={`${
							activeStep + 1 === 3 && 'text-blue-700'
						} font-semibold`}
						disabled={
							isRecipientNextStepDisable ||
							isEmailAddressesNextStepDisable ||
							isNewCapitalCallSaved
						}
						onClick={() => goToStep(2)}
					>
						Capital to be called
					</Button>
				</div>
				<div className='flex flex-1 items-center justify-center'>
					<Button
						variant='ghost'
						className={`${
							activeStep + 1 === 4 && 'text-blue-700'
						} font-semibold`}
						disabled={
							isRecipientNextStepDisable ||
							isEmailAddressesNextStepDisable ||
							isRecipientsCapitalToBeCalledStepDisable ||
							isNewCapitalCallSaved
						}
						onClick={() => goToStep(3)}
					>
						Account for payment
					</Button>
				</div>
				<div className='flex flex-1 items-center justify-center'>
					<Button
						variant='ghost'
						className={`${
							activeStep + 1 === 5 && 'text-blue-700'
						} font-semibold`}
						disabled={
							isRecipientNextStepDisable ||
							isEmailAddressesNextStepDisable ||
							isPaymentDetailsNextStepDisable ||
							isRecipientsCapitalToBeCalledStepDisable
						}
						onClick={() => goToStep(4)}
					>
						Email Editor
					</Button>
				</div>
			</div>
		</div>
	)
}
export default function NewCapitalCallPage() {
	return (
		<DashboardLayout
			hideHeader={false}
			showSidebar={false}
			title='Capital Calls'
		>
			<Wizard header={<WizardNavigationHeader />}>
				<NewCapitalCallRepcientsStep />
				<NewCapitalCallEmailAddressesStep />
				<NewCapitalCallCapitalTobeCalledStep />
				<NewCapitalCallAccountForPaymentStep />
				<NewCapitalCallAccountEmailEditorStep />
			</Wizard>
		</DashboardLayout>
	)
}
