import { Archive, Mail, MailOpen, Pin } from 'lucide-react'
// import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import { TableBody, TableCell, TableRow } from 'components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import {
	selectSelectedThread,
	setSelectedThread
} from 'features/mail/redux/threads.slice'
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useAppDispatch, useAppSelector } from 'app/hooks'

import Button from 'components/Buttons/Button'
import type { IEmailThread } from 'features/mail/interfaces/email.interface'
import ThreadEmailCounter from '../components/ThreadEmailsCounter'
import { ThreadService } from '../service/thread.service'
import clsx from 'clsx'
import { purifyHtml } from 'utils/purifyHtml'
import { renderDate } from 'utils/date.util'

interface Props {
	thread: IEmailThread
	style?: React.CSSProperties
}

export default function EmailThreadItem({ thread, style }: Props) {
	const dispatch = useAppDispatch()
	const subject = thread.latestEmail?.subject || 'No Subject'
	const snippet = thread.latestEmail?.snippet
	const from = thread.latestEmail?.from
	const fromName = from?.name || from?.email || 'No Name'
	const fromEmail = from?.email || 'No Email'
	const date = renderDate(new Date(thread.latestTimeStamp))

	const selectedEmailThread = useAppSelector(selectSelectedThread)

	return (
		<TableBody
			// style={style}
			key={thread.id}
			data-testid={`email-thread-${thread.id}`}
			className={clsx(
				'group relative w-full cursor-pointer border border-l-4 transition-all hover:bg-gray-100 hover:shadow-sm',
				selectedEmailThread?.id === thread.id && 'bg-gray-100 shadow-sm',
				thread.hasUnreadEmails && 'border-l-blue-500 shadow-sm'
			)}
		>
			<TableRow className=''>
				<TableCell
					onClick={() => {
						dispatch(setSelectedThread(thread))
					}}
				>
					<div className='flex gap-2'>
						<div className='flex h-7 w-7 items-center justify-center rounded-full bg-blue-100 text-sm font-semibold uppercase'>
							{fromName ? fromName[0] : fromEmail[0]}
						</div>
						<div className='flex items-center justify-start gap-2 truncate text-ellipsis text-xs text-gray-700'>
							{fromName || fromEmail}
						</div>
					</div>
				</TableCell>
				<TableCell
					onClick={() => {
						dispatch(setSelectedThread(thread))
					}}
				>
					<div className='flex items-center  justify-center gap-2'>
						<div className='mx-2 flex items-center gap-2'>
							<ThreadEmailCounter length={thread.emailIds.length} />
						</div>
						<div className='line-clamp-1 font-semibold' dangerouslySetInnerHTML={({ __html: purifyHtml(subject || '') })} />
						<div>-</div>
						<div className='line-clamp-1 flex-1 text-gray-500' dangerouslySetInnerHTML={({ __html: purifyHtml(snippet || '') })} />
					</div>
				</TableCell>

				<TableCell>
					<div className='flex items-center'>
						{/* <div className='flex flex-1'>
							{thread.companies?.map(company => (
								<Avatar
									key={company.id}
									className='-ml-2 h-6 w-6 border bg-gray-100'
								>
									{company.websiteUrl && (
										<AvatarImage
											src={
												company.websiteUrl
													? `https://www.google.com/s2/favicons?sz=128&domain_url=${company.websiteUrl}`
													: '/images/logo/Logo-4.png'
											}
										/>
									)}
									<AvatarFallback>{company.name.slice(0, 1)}</AvatarFallback>
								</Avatar>
							))}
						</div>
						<div className='flex flex-1'>
							{thread.prospects?.map(prospect => (
								<Avatar
									key={prospect.id}
									className='-ml-2 h-6 w-6 border bg-gray-100'
								>
									{prospect.profileImage && (
										<AvatarImage src={prospect.profileImage} />
									)}
									<AvatarFallback>{prospect.name.slice(0, 1)}</AvatarFallback>
								</Avatar>
							))}
						</div> */}
						<div className='truncate'>{date}</div>
					</div> 
					<div className='absolute right-0 top-0 flex h-full items-center justify-center gap-1 bg-blue-600 px-3 opacity-0 group-hover:opacity-100'>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									className='px-2 py-2'
									onClick={e => {
										e.preventDefault()
										ThreadService.pin(thread.id, !thread.isPinned)
									}}
								>
									<Pin size={20} color='white' />
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								<p>Pin Thread</p>
							</TooltipContent>
						</Tooltip>
						{/* <Tooltip>
					<TooltipTrigger asChild>
						<Button
							onClick={() => {
								toast('Coming soon')
							}}
							className='px-2 py-2'
						>
							<Timer size={20} color='white' />
						</Button>
					</TooltipTrigger>
					<TooltipContent>
						<p>Set Reminder</p>
					</TooltipContent>
				</Tooltip> */}
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									className='px-2 py-2'
									onClick={() => {
										ThreadService.archive(thread.id)
									}}
								>
									<Archive size={20} color='white' />
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								<p>Archive</p>
							</TooltipContent>
						</Tooltip>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									className='px-2 py-2'
									onClick={() => {
										thread.hasUnreadEmails
											? ThreadService.read(thread.id)
											: ThreadService.unread(thread.id)
									}}
								>
									{thread.hasUnreadEmails ? <MailOpen size={20} color='white' /> : <Mail size={20} color='white' />}
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								{thread.hasUnreadEmails ? (
									<p>Mark as Read</p>
								) : (
									<p>Mark as Unread</p>
								)}
							</TooltipContent>
						</Tooltip>
					</div>
				</TableCell>
			</TableRow>
		</TableBody>
	)
}
