/* eslint-disable react-hooks/exhaustive-deps */
import { AuthContext } from 'context/AuthContext'
import UserAuthForm from 'features/authentication/view/UserAuthForm'
import { useContext, useEffect, type ReactElement } from 'react'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { toast } from 'sonner'

export default function LoginPage(): ReactElement {
	const location = useLocation()

	// Redirect to dashboard if user is already logged in
	const { authUser, authError, setAuthError } = useContext(AuthContext)

	useEffect(() => {
		if (!authError) return

		toast.dismiss()
		toast.error(authError)

		setTimeout(() => {
			setAuthError(null)
		}, 1000)
	}, [authError])

	if (authUser) {
		return <Navigate to='/dashboard' state={{ from: location }} replace />
	}

	return (
		<div className='relative h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0'>
			<div className='bg-muted relative hidden h-full w-[40vw] flex-col p-10 text-white lg:flex dark:border-r'>
				<div className='absolute inset-0 m-5 w-full rounded-lg bg-zinc-900 object-cover'>
					<img
						src='/images/login-background.jpg'
						className='h-full w-full rounded-lg object-cover'
						alt='Spok login background'
					/>
				</div>
				<div className='relative  z-20 flex items-center gap-2 text-lg font-medium'>
					<img className='h-10' src='/images/logo/Logo-1.png' alt='Spok logo' />
				</div>
				<div className='relative z-20 mt-auto'>
					<blockquote className='space-y-2'>
						<p className='text-lg'>
							&ldquo;This platform is a game changer. It streamlines my workflow
							in a way that I never have to leave spok.&rdquo;
						</p>
						<footer className='text-sm'>Kweku Boateng</footer>
					</blockquote>
				</div>
			</div>
			<div className='flex h-full items-center p-4 lg:p-8'>
				<div className='mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]'>
					<div className='flex flex-col space-y-2 text-center'>
						<h1 className='text-2xl font-semibold tracking-tight'>
							Login to your fund
						</h1>
					</div>

					<UserAuthForm />

					<p className='text-muted-foreground px-8 text-center text-sm'>
						By clicking continue, you agree to our{' '}
						<Link
							to='/terms-and-conditions'
							className='hover:text-primary underline underline-offset-4'
						>
							Terms of Service
						</Link>
						and{' '}
						<Link
							to='/privacy-policy'
							className='hover:text-primary underline underline-offset-4'
						>
							Privacy Policy
						</Link>
						.
					</p>
				</div>
			</div>
		</div>
	)
}
