/* eslint-disable @typescript-eslint/no-useless-template-literals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/return-await */

import {
	ArrowUp,
	ChevronDown,
	ChevronRight,
	FolderPlus,
	GalleryThumbnails,
	Trash2,
	X
} from 'lucide-react'
import { IFile, IFileData } from 'features/files/file.interface'
import {
	Popover,
	PopoverContent,
	PopoverTrigger
} from '@radix-ui/react-popover'
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger
} from '@radix-ui/react-tooltip'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { selectFiles, setFiles, setOracleData } from 'features/files/files.slice'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import AllCompanyFiles from 'features/companies/components/AllCompanyFiles'
import { AuthContext } from 'context/AuthContext'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import CreateFolderComponent from '../components/create.component'
import CustomContextMenu from '../components/CustomContextMenu'
import DeleteComponent from '../components/delete.component'
import DeleteModal from 'components/DeleteModal'
import { DialogModal } from 'components/shadcn/DialogModal'
import EditComponent from '../components/edit.component'
import { FileService } from 'features/files/services/file.service'
import { FileUploadResult } from 'features/storage/hooks/useFirebaseStorageUploader'
import FileViewer from 'features/fileviewers/views/FileViewer'
import FoldersApi from '../api/folders.api'
import type { FoldersInterface } from '../interface/folders.interface'
import type { ICompany } from 'interfaces'
import { ImSpinner10 } from 'react-icons/im'
import MoveFolder from '../components/move.component'
import axiosInstance from 'lib/axios'
import clsx from 'clsx'
import { formatFileSize } from 'features/fileviewers/helpers/fileviewer.helper'
import { getFiles } from 'features/files/files.api'
import { nanoid } from 'nanoid'
import { storage } from 'lib/firebase'
import { store } from 'app/store'
import { toast } from 'sonner'
import { useAppSelector } from 'app/hooks'

interface FolderViewProps {
	company: ICompany
	onUpload?: (files: FileUploadResult[]) => void
	shouldUpload?: boolean
	isReadOnly?: boolean
}

function FolderView({
	company,
	onUpload,
	isReadOnly,
	shouldUpload = true
}: FolderViewProps) {
	const initialState = { show: false, x: 0, y: 0 }
	const { authUser } = useContext(AuthContext)
	const modalRef = useRef<HTMLDivElement>(null)
	const [isShow, setIsShow] = useState<boolean>(false)
	const [deletePrompt, setDeletePrompt] = useState<boolean>(false)
	const [createNewFolderPrompt, setCreateNewFolderPrompt] =
		useState<boolean>(false)
	const [contextMenu, setContextMenu] = useState(initialState)
	const contextMenuRef = useRef(null)
	const [subFolders, setSubFolders] = useState<FoldersInterface[]>([])
	const [selectedFiles, setSelectedFiles] = useState<any>([])
	const [isOpen, setIsOpen] = useState(false)
	const [isUploading, setIsUploading] = useState(false)
	const id = 'company-file-upload'
	const [fileSort, setFileSort] = useState<{
		field: keyof IFile
		order: string
	}>({
		field: 'name',
		order: 'asc'
	})
	const fileSortOptions = [
		{ label: 'Name', value: 'name' },
		{ label: 'Date', value: 'createdAt' }
	]
	const [view, setView] = useState<'grid' | 'list'>('grid')
	const [activeFolderId, setActiveFolderId] = useState(null)
	const [unsubscribe, setUnsubscribe] = useState(null)
	const [showMovePrompt, setShowMovePrompt] = useState<boolean>(false)
	const [clickedIndex, setClickedIndex] = useState<number | null>(null)
	const unsubscribeRef = useRef(null)

	const {
		folders,
		files,
		listenToFiles,
		allFolders,
		getAllFolders,
		listenToFolders
	} = FoldersApi()
	const allFiles = useAppSelector(selectFiles)

	// Start listening to folders when the page loads
	useEffect(() => {
		if (unsubscribeRef.current) {
			unsubscribeRef.current() // Unsubscribe from the previous listener
		}

		unsubscribeRef.current = listenToFolders(null, company.id)

		// Clean up the listener when the component unmounts or parentId changes
		return () => {
			if (unsubscribeRef.current) {
				unsubscribeRef.current()
			}
		}
	}, [null, company.id])

	useEffect(() => {
		getAllFolders(company.id)
	}, [allFolders, company.id, getAllFolders])

	const handleClickOutside = () => {
		if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
			setIsShow(false)
			setDeletePrompt(false)
			setCreateNewFolderPrompt(false)
			setShowMovePrompt(false)
		}
	}

	useEffect(() => {
		if (isShow || deletePrompt || createNewFolderPrompt || showMovePrompt) {
			document.addEventListener('mousedown', handleClickOutside)
		} else {
			document.removeEventListener('mousedown', handleClickOutside)
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isShow, deletePrompt, createNewFolderPrompt, showMovePrompt])

	//  open context menu
	const handleContextMenu = (e: {
		preventDefault?: any
		pageX?: any
		pageY?: any
		clientX?: any
		clientY?: any
	}) => {
		e.preventDefault()

		const { pageX, pageY, clientX, clientY } = e
		const contextMenuAttr = contextMenuRef.current?.getBoundingClientRect()

		let x = clientX
		let y = clientY

		if (contextMenuAttr) {
			x =
				clientX < window.innerWidth / 2
					? clientX
					: clientX - contextMenuAttr.width
			y =
				clientY < window.innerHeight / 2
					? clientY
					: clientY - contextMenuAttr.height
		}

		setContextMenu({ show: true, x: pageX, y: pageY })
	}

	// hide context menu when clicked outside
	useEffect(() => {
		function handler(e: { target: any }) {
			if (
				contextMenuRef.current &&
				!contextMenuRef.current.contains(e.target)
			) {
				setContextMenu(initialState)
			}
		}
		window.addEventListener('click', handler)
		return () => window.removeEventListener('click', handler)
	})

	const togglePrompts = () => {
		setCreateNewFolderPrompt(true)
		setContextMenu(initialState)
	}

	// Listening to files in sub folders
	const startListening = useCallback(
		(folderId: string) => {
			if (unsubscribe) {
				unsubscribe()
			}

			const newUnsubscribe = listenToFiles(folderId)
			setUnsubscribe(() => newUnsubscribe)
			setActiveFolderId(folderId)
		},
		[unsubscribe]
	)

	useEffect(() => {
		// Cleanup listener on unmount
		return () => {
			if (unsubscribe) {
				unsubscribe()
			}
		}
	}, [unsubscribe])

	const displayedSubFolders =
		clickedIndex === null ? subFolders : subFolders.slice(0, clickedIndex + 1)

	const folderClick = async (folder: FoldersInterface) => {
		setSubFolders(prevSubFolders => {
			const folderExists = prevSubFolders.some(f => f.id === folder.id)
			if (!folderExists) {
				return [...prevSubFolders, folder]
			}
			return prevSubFolders
		})

		if (unsubscribeRef.current) {
			unsubscribeRef.current() // Unsubscribe from the previous listener
		}
		unsubscribeRef.current = listenToFolders(folder.id, company.id)

		startListening(folder.id) // listens to files in the folder
	}

	// handle subfolders click on the folder path
	const handleFolderClick = async (index: number, folder: FoldersInterface) => {
		// Set the clicked index to update the displayed path
		setClickedIndex(index)

		// Update the subFolders to include only up to the clicked folder
		setSubFolders(prevSubFolders => {
			const updatedSubFolders = prevSubFolders.slice(0, index + 1)
			if (!updatedSubFolders.some(f => f.id === folder.id)) {
				updatedSubFolders.push(folder)
			}
			return updatedSubFolders
		})

		// Fetch folders and update the path
		await folderClick(folder)
	}

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	async function uploadDocumentsToFirestore(): Promise<
		File[] | FileUploadResult[] | undefined
	> {
		if (selectedFiles.length === 0) {
			toast.error('No files selected!')
			return
		}
		if (!shouldUpload) {
			const results = selectedFiles
			setIsUploading(false)
			if (onUpload) {
				onUpload(results)
			}
			closeModal()
			return results
		}

		setIsUploading(true)
		const results: FileUploadResult[] = []
		toast.loading('Uploading files...')

		for (const document of selectedFiles) {
			const file = document
			const fileName = `${nanoid()}_${file.name}`

			const storagePath = `funds/${authUser?.fund.id}/documents/${fileName}`
			const storageRef = ref(storage, storagePath)

			try {
				await uploadBytes(storageRef, file)
				const downloadURL = await getDownloadURL(storageRef)
				results.push({
					id: nanoid(),
					name: file.name,
					url: downloadURL,
					type: file.type,
					size: file.size,
					folderId: subFolders.at(-1).id,
					createdAt: new Date().toISOString()
				})

				setSelectedFiles([])
			} catch (error) {
				setIsUploading(false)
				setSelectedFiles([])
				throw error
			}
		}

		setIsUploading(false)

		onUpload?.(results)

		await getFiles(displayedSubFolders.at(-1).id)
		closeModal()
		toast.dismiss()
		toast.success('All files uploaded successfully!')
		return results
	}

	const openMovePrompt = () => {
		setShowMovePrompt(true)
	}

	useEffect(() => {
		if (!company.oracleData) return
		;(async () => {
			const { data } = await axiosInstance.get(
				`/documents?companyId=${company.id}&oracleId=${company.oracleData?.id}`
			)
			store.dispatch(setFiles(data.data))
		})()
	}, [company])

	return (
		<div>
			<div className='mb-3 flex items-center justify-between px-3'>
				<div className='flex cursor-pointer items-center gap-1'>
					<div className='text-lg font-semibold'>My Documents</div>
				</div>
				<div className='flex items-center gap-3'>
					<div className='flex items-center justify-between'>
						<div>
							<div className='flex w-full items-center'>
								<ComboSelect
									onSelect={value => {
										setView(value as 'grid' | 'list')
									}}
									content={
										<div className='flex items-center gap-1 pr-3 text-xs font-semibold text-gray-700 shadow-none'>
											<GalleryThumbnails size={13} />
											View
										</div>
									}
									defaultValue={view}
									options={[
										{ label: 'Grid', value: 'grid' },
										{ label: 'List', value: 'list' }
									]}
								/>
							</div>
						</div>
					</div>
					<div className='flex items-center gap-1'>
						<div
							className={clsx(
								'cursor-pointer transition-all',
								fileSort.order === 'asc' ? 'rotate-0' : 'rotate-180'
							)}
							onClick={() => {
								setFileSort({
									...fileSort,
									order: fileSort.order === 'asc' ? 'desc' : 'asc'
								})
							}}
						>
							<ArrowUp size={15} color='blue' />
						</div>
						<ComboSelect
							onSelect={value => {
								setFileSort({
									...fileSort,
									field: value as unknown as keyof IFile
								})
							}}
							defaultValue={fileSort.field}
							options={fileSortOptions}
							content={
								<div className='flex items-center gap-1 pr-3 text-xs font-semibold text-gray-700 shadow-none'>
									{
										fileSortOptions.find(
											option => option.value === fileSort.field
										)?.label
									}
								</div>
							}
						/>
					</div>
				</div>
			</div>

			{/* Folders Breadcrumb */}
			<div className='mb-5 flex items-center px-3'>
				<div
					onClick={async () => {
						setSubFolders([])
						if (unsubscribeRef.current) {
							unsubscribeRef.current() // Unsubscribe from the previous listener
						}
						unsubscribeRef.current = listenToFolders(null, company.id)
					}}
					className='flex items-center hover:cursor-pointer hover:text-blue-500'
				>
					Documents
					{displayedSubFolders.length > 0 ? (
						<ChevronRight size={20} />
					) : (
						<ChevronDown size={20} />
					)}
				</div>

				<h3 className='flex items-center'>
					{subFolders.map((folder: FoldersInterface, index: number) => {
						return (
							<div key={folder.id} className='flex'>
								<div
									className='flex items-center hover:cursor-pointer hover:text-blue-500'
									onClick={() => handleFolderClick(index, folder)}
								>
									<p className=''>{folder.name}</p>
									{subFolders.at(-1).id === folder.id ? (
										<ChevronDown size={20} />
									) : (
										<ChevronRight size={20} />
									)}
								</div>
							</div>
						)
					})}
				</h3>
			</div>

			{/* Folders AND FILE VIEWS */}
			<div onContextMenu={handleContextMenu} className='relative h-screen'>
				<div className='mb-20'>
					<div className='mb-4 flex items-center'>
						<h3 className='px-3 font-bold'>Folders</h3>
						<FolderPlus
							size={18}
							onClick={() => setCreateNewFolderPrompt(true)}
						/>
					</div>
					<div className='grid w-full grid-cols-3 gap-3 px-3'>
						{createNewFolderPrompt && (
							<div className='absolute z-50 w-[30rem] pb-5'>
								<CreateFolderComponent
									company={company}
									onClose={() => setCreateNewFolderPrompt(false)}
									lastFolder={subFolders.at(-1)}
								/>
							</div>
						)}

						{/* FOLDERS / SUB-FOLDERS */}
						{folders.map((folder: FoldersInterface) => {
							return (
								<div key={folder.id} className='flex items-center'>
									<div className='flex w-full items-center rounded bg-zinc-100 p-2 text-xs font-medium leading-tight text-blue-600 hover:cursor-pointer'>
										<div
											className='flex w-full items-center overflow-hidden'
											title={folder.name}
											onClick={() => folderClick(folder)}
										>
											<div className='mr-2 w-fit'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													viewBox='0 0 24 24'
													fill='currentColor'
													className='size-5'
												>
													<path d='M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z' />
												</svg>
											</div>
											<div className=''>
												<p className='max-w-20 truncate'>{folder.name}</p>
											</div>
										</div>

										<div>
											<Popover>
												<PopoverTrigger>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														viewBox='0 0 24 24'
														strokeWidth={1.5}
														stroke='currentColor'
														className='size-5 font-bold'
													>
														<path
															strokeLinecap='round'
															strokeLinejoin='round'
															d='M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z'
														/>
													</svg>
												</PopoverTrigger>
												<PopoverContent
													className={`${
														isShow || deletePrompt || showMovePrompt
															? 'w-auto'
															: 'w-[150px]'
													} PopoverContent z-50 rounded-md border bg-white shadow-xl`}
													sideOffset={5}
												>
													{isShow ? (
														<EditComponent
															data={folder}
															company={company}
															onClose={() => setIsShow(false)}
														/>
													) : deletePrompt ? (
														<DeleteComponent
															data={folder}
															onClose={() => setDeletePrompt(false)}
															company={company}
														/>
													) : (
														<>
															<div
																className={`${
																	showMovePrompt ? 'hidden' : 'block'
																}`}
															>
																<div className='text-xs'>
																	<div className='py-3'>
																		<a
																			onClick={() => setIsShow(!isShow)}
																			href='#'
																			className='align-center flex items-center justify-start pl-3 text-center align-middle'
																		>
																			<svg
																				className='mr-2 h-5 w-5 text-green-500'
																				viewBox='0 0 24 24'
																				strokeWidth='2'
																				stroke='currentColor'
																				fill='none'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																			>
																				<path stroke='none' d='M0 0h24v24H0z' />
																				<path d='M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3' />
																				<path d='M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3' />
																				<line x1='16' y1='5' x2='19' y2='8' />
																			</svg>
																			Rename
																		</a>
																	</div>
																	<hr />
																	<div className='py-3'>
																		<a
																			onClick={openMovePrompt}
																			href='#'
																			className='align-center flex items-center justify-start pl-3 text-center align-middle'
																		>
																			<div className='flex items-center gap-2 hover:cursor-pointer'>
																				<svg
																					className='text-blue-500'
																					xmlns='http://www.w3.org/2000/svg'
																					width='20px'
																					height='20px'
																					viewBox='0 0 24 24'
																				>
																					<path
																						fill='currentColor'
																						d='M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h6l2 2h8q.825 0 1.413.588T22 8v10q0 .825-.587 1.413T20 20zm8.2-6l-1.625 1.625l1.4 1.4L16 13l-4.025-4.025l-1.4 1.4L12.2 12H8v2z'
																					/>
																				</svg>
																				Move
																			</div>
																		</a>
																	</div>
																	<hr />
																	<div className='py-3'>
																		<a
																			onClick={() =>
																				setDeletePrompt(!deletePrompt)
																			}
																			href='#'
																			className='align-center flex items-center justify-start pl-3 text-center align-middle'
																		>
																			<svg
																				className='mr-2 h-5 w-5 text-red-500'
																				width='24'
																				height='24'
																				viewBox='0 0 24 24'
																				strokeWidth='2'
																				stroke='currentColor'
																				fill='none'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																			>
																				<path stroke='none' d='M0 0h24v24H0z' />
																				<line x1='4' y1='7' x2='20' y2='7' />
																				<line x1='10' y1='11' x2='10' y2='17' />
																				<line x1='14' y1='11' x2='14' y2='17' />
																				<path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
																				<path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
																			</svg>
																			Delete
																		</a>
																	</div>
																</div>
															</div>
															{showMovePrompt && (
																<div className='p-5'>
																	<MoveFolder
																		isFolder
																		folder={folder}
																		onClose={setShowMovePrompt}
																		company={company}
																		fileId={folder.id}
																		// folders={allFolders}
																		lastFolder={subFolders.at(-1)}
																	/>
																</div>
															)}
														</>
													)}
												</PopoverContent>
											</Popover>
										</div>
									</div>
								</div>
							)
						})}
					</div>

					{folders.length === 0 && (
						<div className='flex h-20 w-full items-center justify-center text-center'>
							<div className='text-gray-500'>No folders here</div>
						</div>
					)}
				</div>

				{/* FILES */}
				<div className='mb-20'>
					<h3 className='mb-3 px-3 font-bold'>
						{subFolders.length > 0 ? 'Files' : 'All Company Files'}
					</h3>

					{/* FILES FOR SPECIFIC FOLDERS */}
					{view === 'grid' && (
						<div className='grid w-full grid-cols-2 gap-3 px-3'>
							{files
								.sort((a, b) => {
									if (fileSort.order === 'asc') {
										return a[fileSort.field] > b[fileSort.field] ? 1 : -1
									}
									return a[fileSort.field] < b[fileSort.field] ? 1 : -1
								})
								.map((file: IFileData) => {
									return (
										<div
											key={file.id}
											data-testid={`file-${file.name}`}
											className={`${
												file.folderId === subFolders.at(-1)?.id
													? 'flex'
													: 'hidden'
											} items-center justify-between rounded border border-gray-200 bg-gray-100 p-1`}
										>
											<div className='w-full items-center gap-2'>
												<FileViewer
													readonly={false}
													file={file}
													onDelete={f => {
														FileService.deleteFile(f)
													}}
													company={company}
												/>
											</div>
										</div>
									)
								})}
						</div>
					)}

					<div className='mt-3 flex flex-col gap-2 px-2'>
						{view === 'list' && (
							<div className=' flex flex-col gap-2'>
								{files
									.sort((a, b) => {
										if (fileSort.order === 'asc') {
											return a[fileSort.field]! > b[fileSort.field]! ? 1 : -1
										}
										return a[fileSort.field]! < b[fileSort.field]! ? 1 : -1
									})
									.map(file => (
										<div
											key={file.id}
											data-testid={`file-${file.name}`}
											className={`${
												file.folderId === subFolders.at(-1)?.id
													? 'flex'
													: 'hidden'
											} items-center rounded border border-gray-200 bg-gray-100 px-3`}
										>
											<div className='flex-1'>
												<FileViewer
													company={company}
													file={file}
													readonly={isReadOnly}
													onDelete={f => FileService.deleteFile(f)}
													content={
														<div className='flex-1 cursor-pointer whitespace-nowrap p-3 text-sm '>
															{file.name}
														</div>
													}
												/>
											</div>
											{!isReadOnly && (
												<div className='flex items-center gap-2'>
													<div className='flex w-fit justify-end '>
														<DeleteModal
															deleteButtonId={`deleteFile-${file.name}`}
															title='Delete File'
															description={`Are you sure you want to delete ${file.name}?`}
															trigger={
																<Tooltip>
																	<TooltipTrigger asChild>
																		{/* <Button
																			id={`file-${file.name}-delete-button`}
																			variant='ghost'
																			className='px-1 py-1'
																		>
																			<Trash2 color='red' size={15} />
																		</Button> */}
																	</TooltipTrigger>
																	<TooltipContent>
																		<>Delete {file.name}</>
																	</TooltipContent>
																</Tooltip>
															}
															onDelete={() => FileService.deleteFile(file)}
														/>
													</div>
												</div>
											)}
										</div>
									))}
							</div>
						)}
					</div>

					{files.length === 0 && subFolders.length > 0 && (
						<div className='flex h-20 w-full items-center justify-center text-center'>
							<div className='text-gray-500'>No files here</div>
						</div>
					)}

					{/* ALL COMPANY FILES */}
					{subFolders.length === 0 && (
						<AllCompanyFiles
							files={allFiles}
							fileSort={fileSort}
							view={view}
							company={company}
						/>
					)}
				</div>
			</div>

			{contextMenu.show && (
				<div className='z-50'>
					<CustomContextMenu
						contextMenuRef={contextMenuRef}
						x={contextMenu.x}
						y={contextMenu.y}
						togglePrompts={togglePrompts}
						lastFolder={displayedSubFolders.at(-1)}
						setSelectedFiles={setSelectedFiles}
						openModal={openModal}
					/>
				</div>
			)}

			<DialogModal
				open={isOpen}
				setOpen={setIsOpen}
				title='Review Selected Documents'
				description='Review the documents you have selected for upload'
				trigger={<div />}
			>
				<div>
					<div className='mt-5'>
						<div>
							{selectedFiles.length} document
							{selectedFiles.length === 1 ? '' : 's'} selected
						</div>
						{selectedFiles.length > 0 && (
							<div className='relative mt-1 overflow-x-auto sm:rounded-lg'>
								<table className='w-full text-left text-sm text-gray-500 dark:text-gray-400'>
									<thead className='bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400'>
										<tr>
											<th scope='col' className='px-6 py-3'>
												Name
											</th>
											<th scope='col' className='px-6 py-3'>
												Type
											</th>
											<th scope='col' className='px-6 py-3'>
												Size
											</th>

											<th scope='col' className='sr-only px-6 py-3'>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{selectedFiles.length > 0 && (
											<>
												{selectedFiles.map((file: any) => (
													<tr
														key={file.name}
														className='border-b bg-white dark:border-gray-700 dark:bg-gray-900'
													>
														<th
															scope='row'
															className='whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white'
														>
															{file.name}
														</th>
														<td className='px-6 py-4 uppercase'>
															{file.type.split('/')[1]}
														</td>
														<td className='px-6 py-4'>
															{formatFileSize(file.size)}
														</td>
														<td
															onClick={() => {
																if (isUploading) return
																setSelectedFiles(
																	selectedFiles.filter(
																		(x: any) => x.name !== file.name
																	)
																)
															}}
															className='cursor-pointer px-6 py-4 text-red-500'
														>
															<X />
														</td>
													</tr>
												))}
											</>
										)}
									</tbody>
								</table>
							</div>
						)}
					</div>

					<div className='mt-10'>
						<button
							data-testid={`${id}-upload-button`}
							disabled={isUploading}
							type='button'
							className='flex items-center justify-center gap-2 rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
							onClick={uploadDocumentsToFirestore}
						>
							{isUploading ? (
								<>
									<ImSpinner10 className='animate-spin' /> Uploading...
								</>
							) : (
								'Upload'
							)}
						</button>
					</div>
				</div>
			</DialogModal>
		</div>
	)
}

export default FolderView
