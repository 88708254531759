import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';

import CompanySelectorOld from 'components/CompanySelectorOld';
import { Input } from "components/ui/input";
import Logger from 'lib/logger';
import SpokDatePicker from "components/shadcn/SpokDatePicker";
import { selectCompanies } from "features/companies/redux/companies.slice";
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppSelector } from "app/hooks";
import useTags from "features/tags/hooks/useTag";

interface Props {
  formik: any
  dataType: string
  entity: string
}

export default function RenderFilterValueSelector({ formik, dataType, entity }: Props) {
  Logger.info('RenderFilterValueSelector', { formik, dataType, entity })
  
  const { tags: allTags } = useTags()
  const companies = useAppSelector(selectCompanies)


  if (dataType === 'tags') {
    return (
      <Select
        onValueChange={(e) => {
          formik.setFieldValue('value', e)
        }}
      >
        <SelectTrigger className='flex-1'>
          <SelectValue placeholder='Select a tag' />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Select a tag</SelectLabel>
            {allTags.map((tag) => (
              <SelectItem key={tag.id} value={tag.name}>
                {tag.name}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    )
  }

  if (dataType === 'date') {
    return <div className="border rounded-md py-1 px-1">
      <SpokDatePicker
        value={formik.values.value}
        onSelect={(value) => {
          formik.setFieldValue('value', value)
        }}
      />
    </div>
  }

  if (dataType === 'company') {
    return (
      <CompanySelectorOld
        variant="static"
        defaultValue={companies.find((company) => company.name === formik.values.value)}
        onSelect={(company) => {
          formik.setFieldValue('value', company?.name)
        }}
      />
    )
  }


  return (
    <Input
      type='text'
      value={formik.values.value}
      placeholder='Enter value...'
      onChange={(e) => {
        formik.setFieldValue('value', e.target.value)
      }}
    />
  )

}