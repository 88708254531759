import { store } from "app/store"
import { doc, updateDoc } from "firebase/firestore"
import { firestore } from "lib/firebase"
import { toast } from "sonner"

export class CurrencyExchangeService {

  public static updateFundCurrency = async (currency: string) => {
    try {
      const fundId = store.getState().authentication.authUserFundData?.id
      if (!fundId) return

      const ref = doc(firestore, 'funds', fundId)
      await updateDoc(ref, { currency })

      toast.success('Currency updated successfully')
    } catch (error: any) {
      toast.error(error.message ?? 'Failed to update currency')
    }
  }

}
