import type { useFormik } from 'formik'
import type { ICompanyFormSection } from '../interfaces/company.interface'

import type { ComponentPropsWithoutRef } from 'react'
import clsxm from 'utils/clsxm'

import CompanyFormField from './CompanyFormField'

interface CompanyFormSectionProps {
	form: ReturnType<typeof useFormik>
	section: ICompanyFormSection
	wrapperProps?: ComponentPropsWithoutRef<'section'>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors: any
}

function CompanyFormSection({
	form,
	section,
	wrapperProps,
	errors
}: CompanyFormSectionProps) {
	const { className: wrapperClassName, ...restOfWrapperProps } =
		wrapperProps ?? {}
	return (
		<section className={clsxm(wrapperClassName)} {...restOfWrapperProps}>
			{section.name && <strong className='text-2xl'>{section.name}</strong>}
			{section.description && (
				<p className='text-sm text-gray-600'>{section.description}</p>
			)}

			{section.fields.map(field => (
				<CompanyFormField
					isSystemField={section.isSystem}
					wrapperProps={{
						className: clsxm(section.description ? 'my-8' : 'mt-4 mb-8')
					}}
					errorMessage={errors[field.id] ?? ''}
					field={field}
					key={field.id}
					form={form}
				/>
			))}
		</section>
	)
}

export default CompanyFormSection
