import { nanoid } from 'nanoid'
import type { ITaskFunnel } from '../interafces/task.interface'

export const DEFAULT_TASK_FUNNELS: Partial<ITaskFunnel>[] = [
	{
		id: nanoid(),
		name: 'Backlog',
		slug: 'backlog',
		color: 'gray',
		index: 0,
		category: 'backlog',
		type: 'task',
		isArchived: false,
		createdAt: new Date().toISOString(),
		lastUpdatedAt: new Date().toISOString(),
		isDefault: true
	},
	{
		id: nanoid(),
		name: 'In Process',
		slug: 'in-process',
		color: 'gray',
		index: 1,
		category: 'in-process',
		type: 'task',
		isArchived: false,
		createdAt: new Date().toISOString(),
		lastUpdatedAt: new Date().toISOString(),
		isDefault: true
	},
	{
		id: nanoid(),
		name: 'In Review',
		slug: 'in-review',
		color: 'gray',
		index: 2,
		category: 'in-review',
		type: 'task',
		isArchived: false,
		createdAt: new Date().toISOString(),
		lastUpdatedAt: new Date().toISOString(),
		isDefault: true
	},
	{
		id: nanoid(),
		name: 'Done',
		slug: 'completed',
		color: 'green',
		index: 3,
		category: 'completed',
		type: 'task',
		isArchived: false,
		createdAt: new Date().toISOString(),
		lastUpdatedAt: new Date().toISOString(),
		isDefault: true
	}
]
