import { Mail, Pin } from 'lucide-react'

import EmailThreadItem from './EmailThreadItem'
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import FlexibleSeparator from 'components/FlexibleSeparator'
import type { IEmailThread } from 'features/mail/interfaces/email.interface'
import LoadingEmails from '../components/LoadingEmails'
import NoEmailsFound from '../components/NoEmailsFound'
import { Table } from 'components/ui/table'

interface Props {
	threads: IEmailThread[]
	loading: boolean
}

function hasPinnedEmails(threads: IEmailThread[]) {
	return threads.some(thread => thread.isPinned)
}

export default function EmailThreads({ threads, loading }: Props) {
	const filterThreads = () => {
		return threads
	}

	if (loading && threads.length === 0 ) return <LoadingEmails />

	if (threads.length === 0) {
		return <NoEmailsFound />
	}

	return (
		<div className='flex flex-col gap-2'>
			{hasPinnedEmails(threads) && (
				<div className='mt-3 flex items-center gap-3'>
					<Pin size={15} />
					<div className='text-sm font-semibold'>Pinned Mail</div>
					<FlexibleSeparator />
				</div>
			)}
			<Table>
				{filterThreads()
					.filter(thread => thread.isPinned)
					.map((thread: IEmailThread) => {
						return <EmailThreadItem thread={thread} key={thread.id} />
					})}
			</Table>
			<div className='mt-3 flex items-center gap-3'>
				<Mail size={15} />
				<div className='text-sm font-semibold'>All Mail</div>
				<FlexibleSeparator />
			</div>
			<Table>
				{filterThreads()
					.filter(thread => !thread.isPinned)
					.map((thread: IEmailThread) => {
						return <EmailThreadItem thread={thread} key={thread.id} />
					})}
			</Table>
		</div>
	)
}
