import * as Yup from 'yup'

import type { Invitation, User } from 'interfaces'
import { InvitationStatus, UserRole } from 'interfaces'
import {
	addInvitation,
	checkIfAlreadyInvited
} from 'features/invitations/invitations.api'

import Logger from 'lib/logger'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmailTaken } from 'features/authentication/authentication.api'
import { nanoid } from 'nanoid'
import { useFormik } from 'formik'

export default function useCreateInvitation({
	partner,
	onSuccess
}: {
	partner: User
	onSuccess?: () => void
}) {
	const validationSchema = Yup.object({
		name: Yup.string().required('Name is required'),
		email: Yup.string()
			.email('Invalid email')
			.required('Email is required')
			.test('email-taken', 'Email is already taken', async value => {
				try {
					const result = await isEmailTaken(value.trim().toLowerCase())
					if (!result.success) return false
					return !result.data.exists
				} catch (error: any) {
					Logger.error(error)
					return false
				}
			})
			.test('already-invited', 'User has already been invited', async value => {
				try {
					const result = await checkIfAlreadyInvited(value.trim().toLowerCase())
					if (!result.success) return false
					return !result.data.exists
				} catch (error: any) {
					Logger.error(error)
					return false
				}
			}),
		role: Yup.string().required('Role is required')
	})

	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			receivesEmails: false,
			linkedIn: '',
			role: UserRole.GeneralPartner
		},
		validationSchema,
		onSubmit: async values => {
			formik.setSubmitting(true)

			const invitation: Invitation = {
				id: nanoid(),
				fund: partner.fund,
				invitor: {
					id: partner.id,
					name: partner.name,
					role: partner.role,
					photoUrl: partner.photoUrl ?? ''
				},
				invitee: {
					name: values.name,
					email: values.email.trim().toLowerCase(),
					role: values.role as UserRole,
					photoUrl: ''
				},
				status: InvitationStatus.PENDING,
				mail: {
					status: 'pending'
				},
				createdAt: new Date().toISOString(),
				lastUpdatedAt: new Date().toISOString()
			}

			await addInvitation(invitation)

			formik.setSubmitting(false)

			formik.resetForm()

			onSuccess?.()
		}
	})

	return { formik }
}
