import { collection, doc, query, updateDoc } from 'firebase/firestore'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
/* eslint-disable import/no-extraneous-dependencies */
import Button from 'components/Buttons/Button'
import DashboardLayout from 'components/Dashboard/DashboardLayout'
import type { Fund } from 'interfaces/fund.interface'
import clsxm from 'utils/clsxm'
import { firestore } from 'lib/firebase'
import { toast } from 'sonner'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import usePageTimeTracking from 'hooks/usePageTracking'

export default function SuperAdmin(): JSX.Element {
	usePageTimeTracking({ pageName: 'Super Admin' })

	const { authUser } = useContext(AuthContext)
	const [funds, setFunds] = useState<Fund[]>([])

	const fundQuery = query(collection(firestore, `funds`))
	const [snapshots, isDataLoading] = useCollectionData(fundQuery)

	useEffect(() => {
		if (isDataLoading) return
		if (!snapshots) return

		setFunds(snapshots as Fund[])
	}, [isDataLoading, snapshots])

	const onHandleChangeFund = (fund: Fund): void => {
		const documentReference = doc(firestore, `users/${authUser?.id}`)

		toast.dismiss()
		toast.promise(
			updateDoc(documentReference, {
				fundId: fund.id,
				fundName: fund.name,
				fundSlug: fund.slug
			}),
			{
				loading: 'Changing fund...',
				success: 'Fund changed successfully',
				error: 'Error changing fund'
			}
		)
	}

	if (isDataLoading)
		return (
			<DashboardLayout hideHeader={false} showSidebar title='Super Admin'>
				<div className='flex h-full w-full'>
					<h1>Loading...</h1>
				</div>
			</DashboardLayout>
		)

	return (
		<DashboardLayout hideHeader={false} showSidebar title='Super Admin'>
			<div className='flex h-max w-full flex-wrap items-stretch gap-5'>
				{funds.map(fund => (
					<Button
						className={clsxm(
							'relative h-max w-full max-w-[350px] cursor-pointer rounded-md border bg-gray-100 p-3 sm:p-5',
							fund.id === authUser?.fund.id ? 'bg-green-100' : ''
						)}
						onClick={() => onHandleChangeFund(fund)}
						key={fund.id}
					>
						<div className='xxs:flex-row xxs:items-stretch flex h-max w-full flex-col items-center gap-3'>
							<div className='ml-3 h-full w-full '>
								<h1 className='w-full truncate text-xs font-bold'>
									{fund.name}
								</h1>
								<div className='mb-0.5 w-full'>
									<div className='mb-1.5 text-xs font-bold'>Email</div>
									<input
										id='imail'
										type='email'
										className='w-full rounded-md border border-gray-100 bg-white px-1 py-0.5 text-xs focus:ring-1 focus:ring-[#30D988]'
										value={`${fund.slug}@spok.vc`}
										disabled
									/>
								</div>
								<div className='mb-0.5 w-full'>
									<div className='mb-1.5 text-xs font-bold'>Fund Id</div>
									<input
										id='imail'
										type='email'
										className='w-full rounded-md border border-gray-100 bg-white px-1 py-0.5 text-xs focus:ring-1 focus:ring-[#30D988]'
										value={fund.id}
										disabled
									/>
								</div>
							</div>
						</div>
					</Button>
				))}

				{funds.length === 0 && (
					<div className='flex h-full w-full'>
						<h1 className='text-lg font-bold'>No funds available</h1>
					</div>
				)}
			</div>
		</DashboardLayout>
	)
}
