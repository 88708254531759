/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import { Checkbox } from 'components/ui/checkbox'
import { Input } from 'components/ui/input'
import type { CountryData } from 'interfaces'
import { Pencil } from 'lucide-react'
import { useState } from 'react'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import clsxm from 'utils/clsxm'

export default function UpdateLocation({
	location,
	onUpdate,
	title,
	description,
	id,
	isReadOnly
}: {
	location?: CountryData
	onUpdate: (data: CountryData) => void
	title: string
	description: string
	id: string
	isReadOnly?: boolean
}) {
	const [isOpen, setIsOpen] = useState(false)

	const [heaquarters, setHeaquarters] = useState({
		hqCountry: location?.country,
		hqRegion: location?.state,
		hqCity: location?.city,
	})

	const [setAsDomiciled, setSetAsDomiciled] = useState(true)

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	function saveHeadquarters() {

		const data = {
			country: heaquarters.hqCountry || '',
			state: heaquarters.hqRegion || '',
			city: heaquarters.hqCity || ''
		}

		onUpdate(data)
		closeModal()
	}

	return (
		<DialogModal
			open={isReadOnly ? false : isOpen}
			setOpen={setIsOpen}
			title={title}
			description={description}
			trigger={
				<div
					data-testid={`open-${id}`}
					className='group flex cursor-pointer items-center gap-2 transition-all text-sm'
					onClick={() => {
						if (!isReadOnly) {
							openModal()
						}
					}}
				>
					{location?.city?.trim()}
					{location?.city && ', '}
					{location?.state?.trim()}
					{location?.state && ', '}
					{location?.country || '-'}
					{!isReadOnly && <div className='opacity-20'>
						<Pencil size={14} />
					</div>}
				</div>
			}
		>
			<div className=''>
				<div
					id='headquarters-content'
					className='mt-2 flex w-full flex-col gap-2 text-sm'
				>
					<div>
						<div>Country</div>
						<CountryDropdown
							id='hqCountry'
							data-testid='hqCountry'
							priorityOptions={['US', 'NZ', 'CA']}
							classes={clsxm('w-full border-gray-300')}
							value={heaquarters.hqCountry || 'US'}
							onChange={e => {
								setHeaquarters({
									...heaquarters,
									hqCountry: e
								})
							}}
						/>

						<div className='my-3 flex items-center space-x-2'>
							<Checkbox
								checked={setAsDomiciled}
								onCheckedChange={e => {
									setSetAsDomiciled(e as boolean)
								}}
								id='domiciled'
							/>
							<div className='text-sm font-medium'>Set as domiciled</div>
						</div>
					</div>
					<div>
						<div>Region/State</div>
						<RegionDropdown
							data-testid='hqRegion'
							classes='w-full border-gray-300'
							disableWhenEmpty
							country={heaquarters.hqCountry || 'US'}
							value={heaquarters.hqRegion || ''}
							onChange={e => {
								setHeaquarters({
									...heaquarters,
									hqRegion: e
								})
							}}
						/>
					</div>
					<div>
						<div>City</div>
						<Input
							id='hqCity'
							value={heaquarters.hqCity}
							onChange={e => {
								setHeaquarters({
									...heaquarters,
									hqCity: e.target.value
								})
							}}
							placeholder='City'
							className='w-full rounded-none'
						/>
					</div>

					{/* {!setAsDomiciled && (
						<div className='mt-5'>
							<div>Domiciled</div>
							<CountryDropdown
								priorityOptions={['US', 'NZ', 'CA']}
								classes={clsxm('w-full border-gray-300')}
								value={heaquarters.domiciled || 'US'}
								onChange={e => {
									setHeaquarters({
										...heaquarters,
										domiciled: e
									})
								}}
							/>
						</div>
					)} */}

					<div className='mt-5 flex w-full items-end justify-end gap-3'>
						<Button
							type='button'
							variant='ghost'
							className='py-1'
							onClick={closeModal}
						>
							Cancel
						</Button>
						<Button
							id='save-location-button'
							type='button'
							onClick={saveHeadquarters}
						>
							Save
						</Button>
					</div>
				</div>
			</div>
		</DialogModal>
	)
}
