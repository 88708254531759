/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-extraneous-class */
import { collection, doc, setDoc } from "firebase/firestore";

import Errorhandler from "lib/sentry";
import type { ITag } from "interfaces";
import Logger from "lib/logger";
import { firestore } from "lib/firebase";
import { toast } from "sonner";

export class TagService {
  private static readonly ref = collection(firestore, 'tags')

  static async addTag(tag: ITag): Promise<void> {
    try {

      const docRef = doc(this.ref, tag.id)
      await setDoc(docRef, tag)

      toast.success('Tag created successfully')
    } catch (error: any) {
      toast.error(error?.message ?? 'Failed to delete file')

			Logger.error(error)
			Errorhandler.captureException(error)
    }
  }
}