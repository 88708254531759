/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppSelector } from 'app/hooks'
import Button from 'components/Buttons/Button'
import { selectCompanyTasks } from 'features/tasks/redux/tasks.slice'
import { listenToCompanyTasks } from 'features/tasks/tasks.api'
import CreateTask from 'features/tasks/view/CreateTask'
import TasksList from 'features/tasks/view/TasksList'
import type { ICompany } from 'interfaces'
import { PlusCircle } from 'lucide-react'
import { useEffect } from 'react'

interface Props {
	company: ICompany
}
export default function CompanyTasks({ company }: Props): JSX.Element {
	const { isLoading } = useAppSelector(state => state.tasks)
	const data = useAppSelector(state => selectCompanyTasks(state, company.id))


	useEffect(() => {
		if (!company.id) return
		const unsubscribe = listenToCompanyTasks(company.id)
		return () => unsubscribe()
	}, [company.id])

	return (
		<div className='flex flex-col bg-white'>
			<div className='relative pb-2'>
				<div className='mt-2 flex w-full items-center justify-between px-2'>
					<div className='font-bold'>Tasks</div>
					<CreateTask
						task={{
							company: {
								id: company.id,
								name: company.name,
								website: company.website || ''
							}
						}}
						trigger={
							<Button id='open-add-company-task' variant='ghost'>
								<PlusCircle size={15} />
								Add Task
							</Button>
						}
					/>
				</div>
				<TasksList tasks={data} isLoading={isLoading} />
			</div>
		</div>
	)
}
