import DashboardLayout from 'components/Dashboard/DashboardLayout'
import MailPage from 'features/mail/views/MailPage'
import usePageTimeTracking from 'hooks/usePageTracking'

export default function Mail(): JSX.Element {
	usePageTimeTracking({ pageName: 'Mail' })

	return (
		<DashboardLayout hideHeader={false} showSidebar title='Mail'>
			<MailPage />
		</DashboardLayout>
	)
}
