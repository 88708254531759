/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger
} from 'components/ui/select'
import { useEffect, useRef, useState } from 'react'

import CompanyHoverCard from 'components/CompanyHoverCard'
import Errorhandler from 'lib/sentry'
import type { ICompany } from 'interfaces'
import { Input } from 'components/ui/input'
import Logger from 'lib/logger'
import axiosInstance from 'lib/axios'
import { nanoid } from 'nanoid'

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
	onSelect: (value?: any) => void
	label?: string
	defaultValue?: any
	companyValue?: boolean
	companyHoverData?: any
}

export default function CompanySearchSelector({
	onSelect,
	defaultValue,
	label = 'Companies',
	companyValue,
	companyHoverData
}: Props) {
	const [companies, setCompanies] = useState<ICompany[]>([])

	const [optionsResult, setOptionsResult] = useState<any[]>([])
	const [searchValue, setSearchValue] = useState('')

	// Search input
	const inputRef = useRef<any>(null)

	function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
		const { value } = e.target
		setSearchValue(value)

		const result = companies
			.filter(option => option.name.toLowerCase().includes(value.toLowerCase()))
			.map(company => ({
				label: company.name,
				value: {
					id: company.id,
					name: company.name,
					website: company.website ?? ''
				}
			}))
		setOptionsResult(result)
	}

	useEffect(() => {
		const options = companies.map(company => ({
			label: company.name,
			value: company
		}))
		setOptionsResult(options)
	}, [companies])

	useEffect(() => {
		const search = async () => {
			try {
				const { data } = await axiosInstance.get(
					`/search?index=companies&query=${searchValue}`
				)
				setCompanies(data.data)
			} catch (error: any) {
				Logger.error(error)
				Errorhandler.captureException(error)
			}
		}

		search()
	}, [searchValue])

	useEffect(() => {
		setSearchValue('')
	}, [])

	useEffect(() => {
		// Focus the input element
		inputRef.current?.focus()
	}, [])

	return (
		<div>
			<label className='mb-2 block text-xs font-bold text-gray-700'>
				{label}
			</label>
			<Select
				defaultValue={defaultValue}
				onValueChange={e => {
					onSelect(e)
				}}
			>
				<div className='flex items-center gap-2'>
					{companyValue && <CompanyHoverCard company={companyHoverData} />}

					{defaultValue ? (
						<SelectTrigger defaultValue='onlyContent'>
							{typeof defaultValue === 'string'
								? defaultValue.replaceAll('-', ' ')
								: defaultValue.name}
						</SelectTrigger>
					) : (
						<SelectTrigger className='border border-gray-200 shadow-none'>
							Select company
						</SelectTrigger>
					)}
				</div>

				<SelectContent className='z-[999999] min-h-8'>
					<Input
						placeholder='Search'
						className='static mb-2 w-full'
						ref={inputRef}
						autoFocus
						onChange={e => {
							onSearchChange(e as React.ChangeEvent<HTMLInputElement>)
						}}
					/>

					{optionsResult.length === 0 && (
						<div className='text-center text-gray-500'>No results found</div>
					)}

					{optionsResult.length === 0 &&
						searchValue &&
						searchValue.trim() !== '' && (
							<SelectGroup>
								<SelectItem key={nanoid()} value={searchValue}>
									{searchValue}
								</SelectItem>
							</SelectGroup>
						)}

					<SelectGroup>
						{optionsResult.map(option => (
							<SelectItem key={nanoid()} value={option.value}>
								{option.label}
							</SelectItem>
						))}
					</SelectGroup>
				</SelectContent>
			</Select>
		</div>
	)
}
