import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import { ColumnsService } from 'features/columns/sevice/column.service'
import { DataTable } from 'features/dataTable/DataTable'
import type { ILimitedPartner } from 'interfaces'
import { listenToColumns } from 'features/columns/columns.api'
import { selectProspectColumns } from 'features/columns/column.slice'
/* eslint-disable consistent-return */
import { useAppSelector } from 'app/hooks'
import useDataTableHook from 'features/dataTable/hooks/useDataTableHook'

interface IAllProspectsTableProps {
	className?: string
	lps: ILimitedPartner[]
	loading?: boolean
}

export default function ProspectsList({
	className,
	lps,
	loading
}: IAllProspectsTableProps): JSX.Element {
	const { authUser } = useContext(AuthContext)
	const columnsData = useAppSelector(selectProspectColumns)
	const { columns, columnData } = useDataTableHook({
		columnData: columnsData,
		detailsRoute: 'limited-partner'
	})

	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribe = listenToColumns(authUser.fund.id)
		return () => unsubscribe()
	}, [authUser?.fund.id])

	return (
		<div className={`${className} flex flex-col`}>
			<div className='relative pb-2'>
				<DataTable
					entity='prospect'
					columns={columns}
					columnData={columnData}
					data={lps}
					allowDownload
					showColumnToggle
					allowFilter
					filterPlaceholder='Search prospects'
					loading={loading}
					onColumnUpdate={updatedColumns => {
						ColumnsService.updateColumns(updatedColumns)
					}}
					onColumnVisibilityChange={(columnId: string, isVisible: boolean) => {
						const data = {
							isVisible,
							lastUpdatedAt: new Date().toISOString()
						}

						ColumnsService.editColumn(columnId, data)
					}}
				/>
			</div>
		</div>
	)
}
