import type { User } from 'interfaces'
import { UserRole } from 'interfaces'

export const mapRoleToName = (role: UserRole): string => {
	switch (role) {
		case UserRole.Admin: {
			return 'Admin'
		}
		case UserRole.GeneralPartner: {
			return 'General Partner'
		}
		case UserRole.LimitedPartner: {
			return 'Limited Partner'
		}
		case UserRole.VenturePartner: {
			return 'Venture Partner'
		}
		case UserRole.OpsManager: {
			return 'Ops Manager'
		}
		case UserRole.TeamMember: {
			return 'Team Member'
		}
		case UserRole.SuperAdmin: {
			return 'Super Admin'
		}
		default: {
			return 'Unknown'
		}
	}
}

export function hasRole(user: User, role: UserRole[]): boolean {
	return role.includes(user.role)
}
