/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Amount, FundData, UserData } from 'interfaces'

import type { CapitalCallRequest } from 'features/new-capital-call/interfaces/CapitalCallRequest'
import type { IFileData } from 'features/files/file.interface'
import type { LimitedPartnerData } from 'features/limited_partners/interfaces/limitedPartner.interface'
import type { WiseTransaction } from 'interfaces/wise.interface'

export interface ICapitalCallSummary {
	id: string
	total: { currency: string; amount: number }[]
	totalCapitalCalls: number
	percentRemaining: number
}

export interface ICapitalCallYearlySummary {
	id: string
	total: { currency: string; amount: number }[]
	totalCapitalCall: number
	percentRemaining: number
}

export interface ICapitalCallMonthlySummary {
	id: string
	total: { currency: string; amount: number }[]
	totalCapitalCall: number
	percentRemaining: number
	percentCalled: number
}

export interface ICapitalCallRequest {
	id: string
	name: string
	recipients: string[]
	percentage: number
	amount: Amount
	amounts: Amount[]
	dateCreated: string
	dateDue: string
	document: string
	status: string
	approvedAt?: string
	deleteAt?: string
}

export interface ICapitalCall {
	id: string
	fund: FundData
	limitedPartner: LimitedPartnerData
	calledBy: UserData
	approvedBy: UserData
	percent: number
	amount: Amount
	dateCalled: string
	dueDate: string
	status?: CapitalCallStatus
	createdAt?: string
	lastUpdatedAt?: string
	requestId: string
	files?: IFileData[]
	fileIds?: string[]
	wiseTransactionId?: string
	wiseTransaction?: WiseTransaction
	wiseStatus?: string
}

export enum CapitalCallStatus {
	PENDING = 'pending',
	SENT = 'sent',
	FAILED = 'failed',
	PAID = 'paid',
	OVERDUE = 'overdue'
}

export type ICapitalCallRequestMap = Record<
	number,
	Record<number, CapitalCallRequest[]>
>
