/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import type { IBankAccount } from '../interface/BankAccount.interface'
import { ImSpinner10 } from 'react-icons/im'
import { Input } from 'components/ui/input'
import { UserRoundPlus } from 'lucide-react'
import { useCreateBankAccount } from '../hooks/useCreateBankAccount'
import { useEffect } from 'react'

interface IProps {
	initialValue?: Partial<IBankAccount>
	editMode?: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	isOpen: boolean
	onSuccess: () => void
}

export default function AddEditBankAccountDialog({
	initialValue,
	editMode = false,
	setIsOpen,
	isOpen,
	onSuccess
}: IProps): JSX.Element {
	const { formik } = useCreateBankAccount({
		initialValue: initialValue as IBankAccount,
		editMode,
		onSuccess
	})

	useEffect(() => {
		if (!editMode) return

		formik.setValues({
			title: initialValue?.title || '',
			accountName: initialValue?.accountName || '',
			accountNumber: initialValue?.accountNumber || '',
			accountAddress: initialValue?.accountAddress || '',
			bankName: initialValue?.bankName || '',
			routingNumber: initialValue?.routingNumber || '',
			swiftCode: initialValue?.swiftCode || '',
			bankAddress: initialValue?.bankAddress || ''
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editMode])

	function onCloseModal(): void {
		setIsOpen(false)
	}

	function onOpenModal(): void {
		formik.resetForm()
		formik.setErrors({})
		setIsOpen(true)
	}

	return (
		<DialogModal
			size='3xl'
			open={isOpen}
			setOpen={setIsOpen}
			title={editMode ? 'Edit Bank Account' : 'Create Bank Account'}
			description=''
			trigger={
				<Button
					id='open-invite-partner-button'
					variant='blue'
					onClick={onOpenModal}
				>
					<UserRoundPlus size={15} />
					Add Bank Account
				</Button>
			}
		>
			<div>
				<div id='manualFields'>
					<div className='mb-4 w-full'>
						<Input
							id='title'
							label='Title'
							type='text'
							isRequired
							value={formik.values.title}
							error={formik.errors.title}
							onInput={e => {
								formik.setFieldValue(
									'title',
									(e.target as HTMLInputElement).value
								)
							}}
						/>
					</div>
					<div className='mb-4 w-full'>
						<Input
							id='accountName'
							type='text'
							label='Account Name'
							isRequired
							value={formik.values.accountName}
							error={formik.errors.accountName}
							onInput={e => {
								formik.setFieldValue(
									'accountName',
									(e.target as HTMLInputElement).value
								)
							}}
						/>
					</div>
					<div className='mb-4 w-full'>
						<Input
							id='accountNumber'
							type='text'
							label='Account Number'
							isRequired
							value={formik.values.accountNumber}
							error={formik.errors.accountNumber}
							onInput={e => {
								formik.setFieldValue(
									'accountNumber',
									(e.target as HTMLInputElement).value
								)
							}}
						/>
					</div>
					<div className='mb-4 w-full'>
						<Input
							id='accountAddress'
							type='text'
							label='Account Address'
							isRequired
							isMultiline
							className='h-[100px]'
							value={formik.values.accountAddress}
							error={formik.errors.accountAddress}
							onInput={e => {
								formik.setFieldValue(
									'accountAddress',
									(e.target as HTMLInputElement).value
								)
							}}
						/>
					</div>
					<div className='mb-4 w-full'>
						<Input
							id='bankName'
							type='text'
							label='Bank Name'
							isRequired
							value={formik.values.bankName}
							error={formik.errors.bankName}
							onInput={e => {
								formik.setFieldValue(
									'bankName',
									(e.target as HTMLInputElement).value
								)
							}}
						/>
					</div>
					<div className='mb-4 w-full'>
						<Input
							id='routingNumber'
							type='text'
							label='Routing Number'
							value={formik.values.routingNumber}
							error={formik.errors.routingNumber}
							onInput={e => {
								formik.setFieldValue(
									'routingNumber',
									(e.target as HTMLInputElement).value
								)
							}}
						/>
					</div>
					<div className='mb-4 w-full'>
						<Input
							id='swiftCode'
							type='text'
							label='Swift Code'
							value={formik.values.swiftCode}
							error={formik.errors.swiftCode}
							onInput={e => {
								formik.setFieldValue(
									'swiftCode',
									(e.target as HTMLInputElement).value
								)
							}}
						/>
					</div>
					<div className='mb-4 w-full'>
						<Input
							id='bankAddress'
							type='text'
							label='Bank Address'
							isRequired
							isMultiline
							className='h-[100px]'
							value={formik.values.bankAddress}
							error={formik.errors.bankAddress}
							onInput={e => {
								formik.setFieldValue(
									'bankAddress',
									(e.target as HTMLInputElement).value
								)
							}}
						/>
					</div>
				</div>
				<div className='mt-3 flex w-full justify-end'>
					{formik.isSubmitting ? (
						<ImSpinner10 className='animate-spin' />
					) : (
						<div className='flex items-center '>
							<Button variant='ghost' className='px-10' onClick={onCloseModal}>
								Cancel
							</Button>
							<Button
								id='invite-partner-button'
								variant='blue'
								disabled={!formik.isValid}
								className='px-10'
								onClick={() => {
									formik.handleSubmit()
								}}
							>
								{editMode ? 'Update' : 'Create'}
							</Button>
						</div>
					)}
				</div>
			</div>
		</DialogModal>
	)
}
