/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface MailComposeState {
	windowState: 'close' | 'maximize' | 'minimize' | 'restore'
	to: string[]
	cc: string[]
	bcc: string[]
	subject: string
	message: string
	action: 'forward' | 'reply' | 'send'
	threadId: string
	messageId: string
}

const initialState: MailComposeState = {
	windowState: 'close',
	to: [],
	cc: [],
	bcc: [],
	subject: '',
	message: '',
	action: 'send',
	threadId: '',
	messageId: ''
}

export const mailComposeSlice = createAppSlice({
	name: 'compose',
	initialState,
	reducers: create => ({
		setMailWindowState: create.reducer(
			(state, action: PayloadAction<MailComposeState['windowState']>) => {
				state.windowState = action.payload
				if (action.payload === 'close') {
					state.to = []
					state.cc = []
					state.bcc = []
					state.subject = ''
					state.message = ''
				}
			}
		),
		setTo: create.reducer((state, action: PayloadAction<string[]>) => {
			state.to = action.payload
		}),
		setCc: create.reducer((state, action: PayloadAction<string[]>) => {
			state.cc = action.payload
		}),
		setBcc: create.reducer((state, action: PayloadAction<string[]>) => {
			state.bcc = action.payload
		}),
		setSubject: create.reducer((state, action: PayloadAction<string>) => {
			state.subject = action.payload
		}),
		setMessage: create.reducer((state, action: PayloadAction<string>) => {
			state.message = action.payload
		}),
		setAction: create.reducer(
			(state, action: PayloadAction<MailComposeState['action']>) => {
				state.action = action.payload
			}
		),
		setThreadId: create.reducer((state, action: PayloadAction<string>) => {
			state.threadId = action.payload
		}),
		setMessageId: create.reducer((state, action: PayloadAction<string>) => {
			state.messageId = action.payload
		})
	}),
	selectors: {
		selectMailComposeState: state => state,
		selectWindowState: state => state.windowState,
		selectTo: state => state.to,
		selectCc: state => state.cc,
		selectBcc: state => state.bcc,
		selectSubject: state => state.subject,
		selectMessage: state => state.message,
		selectAction: state => state.action,
		selectThreadId: state => state.threadId,
		selectMessageId: state => state.messageId
	}
})

export const {
	setMailWindowState,
	setTo,
	setCc,
	setBcc,
	setSubject,
	setMessage,
	setAction,
	setThreadId,
	setMessageId
} = mailComposeSlice.actions

export const {
	selectMailComposeState,
	selectWindowState,
	selectTo,
	selectCc,
	selectBcc,
	selectSubject,
	selectMessage,
	selectAction,
	selectThreadId,
	selectMessageId
} = mailComposeSlice.selectors
