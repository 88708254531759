/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Building2,
	File,
	Mail,
	Search,
	StickyNote,
	UserRound,
	X
} from 'lucide-react'
import { Dialog, Transition } from '@headlessui/react'
import {
	Fragment,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'
import type { ICompany, IContact, ILimitedPartner } from 'interfaces'

import { AuthContext } from 'context/AuthContext'
import type { IFile } from 'features/files/file.interface'
import type { INote } from 'features/notes/interfaces/notes.interface'
import type { IProspect } from 'features/prospects/interfaces/prospect.interface'
import { SearchService } from '../service/search.service'
// import _ from 'lodash'
import clsxm from 'utils/clsxm'
import { listenToProspects } from 'features/prospects/prospects.api'
import { motion } from 'framer-motion'
import { searchProspects } from '../helpers/search.helper'
import { selectProspects } from 'features/prospects/prospects.slice'
import { useAppSelector } from 'app/hooks'
import { useNavigate } from 'react-router-dom'

type TabName =
	| 'All'
	| 'Companies'
	| 'Contacts'
	| 'Documents'
	| 'Founders'
	| 'Limited Partners'
	| 'LP Prospects'
	| 'Notes'
	| 'Tags'

interface Tab {
	tab: TabName
	responseCount: number
}

export default function SearchEverything() {
	const navigate = useNavigate()
	const { authUser } = useContext(AuthContext)

	const [activeTab, setActiveTab] = useState<TabName>('All')
	const [isOpen, setIsOpen] = useState(false)
	const [keyDownTimerId, setKeyDownTimerId] = useState<NodeJS.Timeout>()

	const prospects = useAppSelector(selectProspects)

	// Results
	const [companyResults, setCompanyResults] = useState<ICompany[]>([])
	// const [founderResults, setFounderResults] = useState<ICompany[]>([])
	const [documentResults, setDocumentResults] = useState<IFile[]>([])
	const [notesResults, setNotesResults] = useState<INote[]>([])
	// const [tagsResults, setTagsResults] = useState<ICompany[]>([])
	const [prospectsResults, setProspectsResults] = useState<IProspect[]>([])
	const [contactResults, setContactResults] = useState<IContact[]>([])
	const [limitedPatnerResults, setLimitedPatnerResults] = useState<
		ILimitedPartner[]
	>([])

	const [tabWidth, setTabWidth] = useState(48)
	const [left, setLeft] = useState(0)

	// Search query
	const [searchQuery, setSearchQuery] = useState('')

	// Search input
	const inputRef = useRef<any>(null)

	const noSearchResults = useMemo(() => {
		const noCompanies = companyResults.length === 0
		// const noFounders = founderResults.length === 0
		const noDocuments = documentResults.length === 0
		const noNotes = notesResults.length === 0
		// const noTags = tagsResults.length === 0
		const noProspects = prospectsResults.length === 0
		const noContacts = contactResults.length === 0
		const noLimitedPartners = limitedPatnerResults.length === 0

		return (
			(activeTab === 'All' &&
				noCompanies &&
				// noFounders &&
				noDocuments &&
				noNotes &&
				noProspects &&
				// noTags &&
				noContacts &&
				noLimitedPartners) ||
			(activeTab === 'Companies' && noCompanies) ||
			// (activeTab === 'Founders' && noFounders) ||
			(activeTab === 'Documents' && noDocuments) ||
			(activeTab === 'Notes' && noNotes) ||
			// (activeTab === 'Tags' && noTags) ||
			(activeTab === 'LP Prospects' && noProspects) ||
			(activeTab === 'Contacts' && noContacts) ||
			(activeTab === 'Limited Partners' && noLimitedPartners)
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		companyResults,
		// founderResults,
		documentResults,
		notesResults,
		// tagsResults,
		prospectsResults,
		contactResults
	])

	const tabs: Tab[] = useMemo(() => {
		const companiesCount = companyResults.length
		// const foundersCount = founderResults.length
		const documentsCount = documentResults.length
		const noteCount = notesResults.length
		// const tagsCount = tagsResults.length
		const prospectsCount = prospectsResults.length
		const contactsCount = contactResults.length
		const limitedPatnersCount = limitedPatnerResults.length

		return [
			{
				tab: 'All',
				responseCount:
					companiesCount +
					// foundersCount +
					documentsCount +
					noteCount +
					// tagsCount +
					prospectsCount +
					contactsCount +
					limitedPatnersCount
			},

			{
				tab: 'Companies',
				responseCount: companiesCount
			},
			// {
			// 	tab: 'Founders',
			// 	responseCount: foundersCount
			// },
			// {
			// 	tab: 'Tags',
			// 	responseCount: tagsCount
			// },
			{
				tab: 'LP Prospects',
				responseCount: prospectsCount
			},
			{
				tab: 'Contacts',
				responseCount: contactsCount
			},
			{
				tab: 'Limited Partners',
				responseCount: limitedPatnerResults.length
			},
			{
				tab: 'Documents',
				responseCount: documentsCount
			},
			{
				tab: 'Notes',
				responseCount: noteCount
			}
		]
	}, [
		documentResults,
		companyResults.length,
		// founderResults.length,
		notesResults.length,
		// tagsResults.length,
		prospectsResults.length,
		contactResults.length,
		limitedPatnerResults.length
	])

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	useEffect(() => {
		function keydownEventHandler(evt: any) {
			evt.stopPropagation()
			if (evt.ctrlKey && evt.code === 'KeyS') {
				evt.preventDefault()
			}

			if (keyDownTimerId) {
				clearTimeout(keyDownTimerId)
			}

			const timeOutId = setTimeout(() => {
				if (evt.ctrlKey && evt.code === 'KeyS') {
					setIsOpen(true)
				}
			}, 200)
			setKeyDownTimerId(timeOutId)
		}

		window.addEventListener('keydown', keydownEventHandler)

		return () => window.removeEventListener('keydown', keydownEventHandler)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (!searchQuery) return

		const prospectsSearchResults = searchProspects(prospects, searchQuery)
		setProspectsResults(prospectsSearchResults.nameHits.splice(0, 100))
	}, [prospects, searchQuery])

	useEffect(() => {
		if (!authUser) return
		const unsubscribeProspects = listenToProspects(authUser.fund.id)

		return () => {
			unsubscribeProspects()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authUser?.id])

	function changeTab(evt: any, tab: TabName) {
		setTabWidth(evt.target.offsetWidth)
		setLeft(evt.target.offsetLeft)
		setActiveTab(tab)
	}

	// Perform actual search
	useEffect(() => {
		async function search() {
			const results = await SearchService.search(searchQuery)
			setCompanyResults(results.companies || [])
			setContactResults(results.contacts || [])
			setLimitedPatnerResults(results.limitedPartners || [])
			setNotesResults(results.notes || [])
			setDocumentResults(results.files || [])
		}

		if (searchQuery.length > 0) {
			search()
		}
	}, [searchQuery])

	useEffect(() => {
		if (!isOpen) {
			setTimeout(() => {
				setSearchQuery('')
				setCompanyResults([])
				// setFounderResults([])
				setDocumentResults([])
				setNotesResults([])
				// setTagsResults([])
				setProspectsResults([])
			}, 500)
		}
	}, [isOpen])

	useEffect(() => {
		if (!isOpen) return

		// Ensure the input is focused after the component is mounted
		const focusTimeout = setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus()
			}
		}, 0)

		return () => clearTimeout(focusTimeout)
	}, [isOpen])

	return (
		<>
			<div className='flex items-center px-2'>
				<button
					type='button'
					onClick={openModal}
					className={clsxm(
						'group w-full cursor-pointer items-center gap-1 rounded  bg-gray-50 px-8 py-1 text-gray-600 transition-all hover:bg-gray-100',
						'flex items-center',
						'justify-between gap-x-1 border'
					)}
				>
					<div className='flex items-center gap-3'>
						<Search size={18} />

						<div className='hidden cursor-pointer text-xs transition-all duration-300 group-hover:ml-1 xl:block'>
							Search prospects, companies, and more
						</div>
					</div>

					<div className='ml-3 flex items-center gap-x-1 text-sm'>
						<kbd className='bg-muted font-mono text-muted-foreground pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border px-1.5 text-[10px] font-medium opacity-100'>
							<span className='text-xs'>^</span>S
						</kbd>
					</div>
				</button>
			</div>

			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-black bg-opacity-25' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-hidden'>
						<div className='flex min-h-full  items-start justify-center px-4 pt-8 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<Dialog.Panel
									className={clsxm(
										'w-full max-w-6xl transform overflow-hidden',
										'text-left align-middle transition-all',
										'rounded-md bg-white focus:shadow-md'
									)}
								>
									<div className='mx-4 flex items-center'>
										<Search size={18} />
										<input
											onChange={e => setSearchQuery(e.target.value)}
											type='text'
											ref={inputRef}
											className='w-full rounded-md  border-none px-5 placeholder:text-sm focus:ring-0 '
											placeholder='Search for prospects, companies, contacts, founders, and more'
											value={searchQuery}
										/>
										<motion.button
											whileHover={{ scale: 1.1 }}
											className='flex items-center'
										>
											<X
												size={18}
												onClick={() => {
													if (searchQuery) setSearchQuery('')
													else closeModal()
												}}
											/>
										</motion.button>
									</div>
									<hr className='ml-4' />
									{searchQuery.length > 0 && (
										<section
											className={clsxm(
												'mt-2 gap-10 overflow-hidden rounded-md bg-white',
												'minimized-scrollbar max-h-[80vh] overflow-y-auto overflow-x-hidden'
											)}
										>
											<header
												className={clsxm(
													'sticky top-0 z-20 mx-4 flex items-center border-b-2 bg-white'
												)}
											>
												<div
													className='absolute top-full h-[2px] bg-[#219653]'
													style={{
														left: `${left}px`,
														width: `${tabWidth}px`
													}}
												/>
												{tabs.map(item => (
													<button
														type='button'
														key={item.tab + item.responseCount}
														className={clsxm(
															'whitespace-nowrap px-4 py-2 text-sm ',
															{
																'text-[#219653]': item.tab === activeTab
															}
														)}
														onClick={(evt: any) => changeTab(evt, item.tab)}
													>
														{item.tab}&nbsp;
														<small className='pointer-events-none'>
															({item.responseCount})
														</small>
													</button>
												))}
											</header>

											<article className='ml-4 flex flex-col gap-y-8 py-5'>
												{/* Companies */}
												{(activeTab === 'All' || activeTab === 'Companies') &&
													companyResults.length > 0 && (
														<div>
															{activeTab !== 'Companies' && (
																<div className='text-primary-green flex items-center gap-x-4 px-4 py-2 text-sm font-semibold'>
																	<span>
																		Companies{' '}
																		<span className=''>
																			({companyResults.length})
																		</span>
																	</span>
																	<div className='h-[1px] flex-1 bg-gray-300' />
																</div>
															)}
															<div>
																{/* Companies */}
																{companyResults
																	.sort((a, b) =>
																		(a.name || '').localeCompare(b.name || '')
																	)
																	.map(company => (
																		<button
																			type='button'
																			key={company.id}
																			onClick={() => {
																				navigate(
																					`/company/${company.id}?page=main-info`
																				)
																				closeModal()
																			}}
																			className='flex cursor-pointer items-center gap-2 px-4 py-2 text-gray-500 odd:bg-gray-50 hover:bg-gray-100'
																		>
																			<Building2 size={16} />
																			<p className='text-sm leading-6 '>
																				{company.name}
																			</p>
																		</button>
																	))}
															</div>
														</div>
													)}

												{/* Prospects */}
												{activeTab === 'LP Prospects' && (
													<div>
														{prospectsResults.map(prospect => (
															<button
																type='button'
																key={prospect.id}
																onClick={() => {
																	navigate(`/limited-partner/${prospect.id}`)
																	closeModal()
																}}
																className='flex cursor-pointer items-center gap-2 px-4 py-2 text-gray-500 odd:bg-gray-50 hover:bg-gray-100'
															>
																<div>
																	<p className='text-sm leading-6 '>
																		{prospect.name}
																	</p>
																</div>
															</button>
														))}
													</div>
												)}

												{/* Limited Partners */}
												{activeTab === 'Limited Partners' && (
													<div>
														{limitedPatnerResults.map(limitedPartner => (
															<button
																type='button'
																key={limitedPartner.id}
																onClick={() => {
																	navigate(
																		`/limited-partner/${limitedPartner.id}`
																	)
																	closeModal()
																}}
																className='flex cursor-pointer items-center gap-2 px-4 py-2 text-gray-500 odd:bg-gray-50 hover:bg-gray-100'
															>
																<div>
																	<p className='text-sm leading-6 '>
																		{limitedPartner.name}
																	</p>
																</div>
															</button>
														))}
													</div>
												)}

												{/* Notes */}
												{(activeTab === 'All' || activeTab === 'Documents') &&
													documentResults.length > 0 && (
														<div>
															{activeTab !== 'Documents' && (
																<div className='text-primary-green flex items-center gap-x-4 px-4 py-2 text-sm font-semibold'>
																	<span>
																		Documents{' '}
																		<span className=''>
																			{' '}
																			({documentResults.length})
																		</span>
																	</span>
																	<div className='h-[1px] flex-1 bg-gray-300' />
																</div>
															)}
															<div>
																{documentResults.map(document => (
																	<div
																		key={document.id}
																		className='flex cursor-pointer items-center gap-2  py-2 '
																	>
																		<div
																			className='flex cursor-pointer flex-col items-start gap-2 px-4 py-2 text-gray-500 odd:bg-gray-50 hover:bg-gray-100'
																			onClick={() => {
																				if (document.company?.id) {
																					navigate(
																						`/company/${document.company.id}?rightTab=files&selected=${document.id}`
																					)
																				} else if (
																					document.limitedPartner?.id
																				) {
																					navigate(
																						`/limited-partner/${document.limitedPartner.id}?rightTab=files&selected=${document.id}`
																					)
																				}
																				closeModal()
																			}}
																		>
																			<div className='flex items-center gap-2'>
																				<div>
																					{' '}
																					<StickyNote size={16} />{' '}
																				</div>
																				<p className='text-sm leading-6'>
																					{document.name}
																				</p>
																			</div>

																			<div className='flex items-start gap-2 px-4 py-1 hover:bg-gray-100'>
																				<File size={16} />
																				<p className='text-sm leading-6 '>
																					{document.company?.name ||
																						document.limitedPartner?.name}
																				</p>
																			</div>
																		</div>
																	</div>
																))}
															</div>
														</div>
													)}

												{/* Founders */}
												{/* {(activeTab === 'All' || activeTab === 'Founders') &&
													founderResults.length > 0 && (
														<div>
															{activeTab !== 'Founders' && (
																<div className='text-primary-green flex items-center gap-x-4 px-4 py-2 text-sm font-semibold'>
																	<span>
																		Founders{' '}
																		<span className=''>
																			({founderResults.length})
																		</span>
																	</span>
																	<div className='h-[1px] flex-1 bg-gray-300' />
																</div>
															)}
															<div>
																{founderResults.map(company => (
																	<button
																		type='button'
																		key={company.id}
																		onClick={() => {
																			navigate(
																				`/company/${company.id}?page=main-info`
																			)
																			closeModal()
																		}}
																		className='flex min-w-72 cursor-pointer gap-2 px-4 py-2 hover:bg-gray-100'
																	>
																		<div className='flex flex-col gap-2 '>
																			<div className='flex gap-2'>
																				<Building2 size={16} />
																				<p className='text-sm leading-6 '>
																					{company.name}
																				</p>
																			</div>

																			{company.founders?.map(founder => (
																				<div
																					key={founder.email}
																					className='ml-4 flex items-center gap-2'
																				>
																					<UserRound
																						size={16}
																						className='text-gray-500'
																					/>
																					<p className='truncate text-ellipsis text-xs leading-6  text-gray-500'>
																						{founder.name}
																					</p>
																				</div>
																			))}
																		</div>
																	</button>
																))}
															</div>
														</div>
													)} */}

												{/* Notes */}
												{(activeTab === 'All' || activeTab === 'Notes') &&
													notesResults.length > 0 && (
														<div>
															{activeTab !== 'Notes' && (
																<div className='text-primary-green flex items-center gap-x-4 px-4 py-2 text-sm font-semibold'>
																	<span>
																		Notes{' '}
																		<span className=''>
																			{' '}
																			({notesResults.length})
																		</span>
																	</span>
																	<div className='h-[1px] flex-1 bg-gray-300' />
																</div>
															)}
															<div>
																{notesResults.map(note => (
																	<div
																		key={note.id}
																		className='flex cursor-pointer items-center gap-2  py-2 '
																	>
																		<div
																			className='flex cursor-pointer flex-col items-start gap-2 px-4 py-2 text-gray-500 odd:bg-gray-50 hover:bg-gray-100'
																			onClick={() => {
																				if (note.company?.id) {
																					navigate(
																						`/company/${note.company.id}?rightTab=notes&selected=${note.id}`
																					)
																				} else if (note.limitedPartner?.id) {
																					navigate(
																						`/limited-partner/${note.limitedPartner.id}?activeTab=notes&selected=${note.id}`
																					)
																				}
																				closeModal()
																			}}
																		>
																			<div className='flex items-center gap-2'>
																				<div>
																					<StickyNote size={16} />{' '}
																				</div>
																				<p
																					className='text-sm leading-6'
																					dangerouslySetInnerHTML={{
																						__html: note.title || ''
																					}}
																				/>
																			</div>

																			<div className='flex items-start gap-2 px-4 py-1 hover:bg-gray-100'>
																				<Building2 size={16} />
																				<p className='text-sm leading-6 '>
																					{note.company?.name ||
																						note.limitedPartner?.name}
																				</p>
																			</div>
																		</div>
																	</div>
																))}
															</div>
														</div>
													)}

												{/* Tags */}
												{/* {(activeTab === 'All' || activeTab === 'Tags') &&
													tagsResults.length > 0 && (
														<div>
															{activeTab !== 'Tags' && (
																<div className='text-primary-green flex items-center gap-x-4 px-4 py-2 text-sm font-semibold'>
																	<span>
																		Tags{' '}
																		<span className=''>
																			({tagsResults.length})
																		</span>
																	</span>
																	<div className='h-[1px] flex-1 bg-gray-300' />
																</div>
															)}
															<div>
																{tagsResults.map(company => (
																	<button
																		type='button'
																		key={company.id}
																		onClick={() => {
																			navigate(`/company/${company.id}`)
																			closeModal()
																		}}
																		className='flex min-w-72 cursor-pointer gap-2 px-4 py-2 hover:bg-gray-100'
																	>
																		<div className='flex flex-col gap-2'>
																			<div className='flex gap-2'>
																				<Building2 size={16} />
																				<p className='text-sm leading-6 '>
																					{company.name}
																				</p>
																			</div>
																			{company.tags?.map(tag => (
																				<div
																					key={tag.id}
																					className='ml-4 flex items-center gap-2'
																				>
																					<Tag
																						size={16}
																						className='text-gray-500'
																					/>
																					<p className='truncate text-ellipsis text-xs leading-6  text-gray-500'>
																						{tag.name}
																					</p>
																				</div>
																			))}
																		</div>
																	</button>
																))}
															</div>
														</div>
													)} */}

												{(activeTab === 'All' || activeTab === 'Contacts') &&
													contactResults.length > 0 && (
														<div>
															{activeTab !== 'Contacts' && (
																<div className='text-primary-green flex items-center gap-x-4 px-4 py-2 text-sm font-semibold'>
																	<span>
																		Contacts{' '}
																		<span className=''>
																			({contactResults.length})
																		</span>
																	</span>
																	<div className='h-[1px] flex-1 bg-gray-300' />
																</div>
															)}
															<div>
																{contactResults.map(contact => (
																	<button
																		type='button'
																		key={contact.id}
																		onClick={() => {
																			navigate(`/contact/${contact.id}`)
																			closeModal()
																		}}
																		className='flex min-w-72 cursor-pointer items-center gap-2 px-4 py-2 hover:bg-gray-100'
																	>
																		<div className='flex flex-col gap-2'>
																			<div className='flex gap-2'>
																				<UserRound size={16} />
																				<p className='text-sm leading-6 '>
																					{/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
																					{contact.name || 'No name'}
																				</p>
																			</div>
																			<div className='ml-4 flex items-center gap-2'>
																				<Mail
																					size={16}
																					className='text-gray-500'
																				/>
																				<p className='truncate text-ellipsis text-xs leading-6  text-gray-500'>
																					{contact.email}
																				</p>
																			</div>
																		</div>
																	</button>
																))}
															</div>
														</div>
													)}

												{noSearchResults && (
													<p className='text-center text-sm text-gray-400'>
														{searchQuery
															? 'No search result'
															: 'Search for anything'}
													</p>
												)}
											</article>
										</section>
									)}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}
