/* eslint-disable @typescript-eslint/no-explicit-any */
import type { LucideIcon } from 'lucide-react'

export function generateNavLink({
	title,
	label,
	icon,
	selectedFilter,
	labelFilter
}: {
	title: string
	label: number
	icon: LucideIcon
	selectedFilter: string
	labelFilter: string
}) {
	return {
		title,
		label: label.toString() || '0',
		icon,
		variant: (labelFilter === selectedFilter ? 'default' : 'ghost') as any,
		labelFilter
	}
}

export function isRecentlyUpdated(updatedAt: Date | number | string) {
	return Date.now() - new Date(updatedAt).getTime() < 180_000
}

export const updateLabelFilter = (filter: string) => {
	const url = new URL(window.location.href)
	url.searchParams.set('label', filter)
	window.history.pushState({}, '', url.href)
}
