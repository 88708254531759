import axiosInstance from 'lib/axios'
import { functions } from 'lib/firebase'
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { httpsCallable } from 'firebase/functions'

export const authorizeGoogleDrive = () => {
	const GOOGLE_DRIVE_SCOPES = [
		'https://www.googleapis.com/auth/drive',
		'https://www.googleapis.com/auth/drive.file',
		'https://www.googleapis.com/auth/drive.appdata',
		'https://www.googleapis.com/auth/drive.metadata',
		'https://www.googleapis.com/auth/drive.metadata.readonly',
		'https://www.googleapis.com/auth/drive.photos.readonly',
		'https://www.googleapis.com/auth/drive.readonly'
	]
	const SCOPE_STRING = GOOGLE_DRIVE_SCOPES.join(' ')

	const CLIENT_ID = import.meta.env.VITE_GMAIL_CLIENT_ID
	const REDIRECT_URI = `${
		import.meta.env.VITE_APP_URL
	}/settings?tab=integrations`

	const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE_STRING}&prompt=consent&access_type=offline&include_granted_scopes=true&openid=email`

	window.open(GOOGLE_AUTH_URL, '_self', 'noopener, noreferrer')
}

interface VerifyGoogleDriveCodeData {
	code: string
}

interface VerifyGoogleDriveCodeResult {
	success: boolean
	error: string | null
}

const verifyGoogleDriveCodeFunction = httpsCallable<
	VerifyGoogleDriveCodeData,
	VerifyGoogleDriveCodeResult
>(functions, 'authorizeGoogleDrive')

export const verifyGoogleDriveCode = async (
	code: string
): Promise<VerifyGoogleDriveCodeResult> => {
	const result = await verifyGoogleDriveCodeFunction({ code })
	return result.data
}

export const getFundAccessToken = async () => {
	const { data } = await axiosInstance.get(`google-drive/token`)
	return data.data
}
export const fetchFolders = async (accessToken: string, folderId?: string) => {
	let q = `token=${accessToken}`
	if (folderId) q += `&folderId=${folderId}`

	const { data } = await axiosInstance.get(`google-drive/folders?${q}`)
	return data.data
}
export const fetchFiles = async (accessToken: string, folderId?: string) => {
	let q = `token=${accessToken}`
	if (folderId) q += `&folderId=${folderId}`

	const { data } = await axiosInstance.get(`google-drive/files?${q}`)
	return data.data
}
