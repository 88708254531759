/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { displayAmount, getConvertedAmounts } from "utils/currency"
import { useContext, useEffect, useState } from "react"

import { AuthContext } from "context/AuthContext"
import type { IFirstCheckAndFollowOns } from "../interfaces/reports.interface"
import type { IInvestment } from "features/investments/interfaces/investment.interface"
import PieChart from "../components/PieChart"
import _ from "lodash"
import { firestore } from "lib/firebase"
import { firstCheckAndFollowOnReportMapper } from "../helper/reports.utils"
import { percentangeFormatter } from "utils/percentageFormatter.util"

export default function ReportFirstCheckAndFollowOns() {

  const {authUser} = useContext(AuthContext);

  const [investments, setInvestments] = useState<IInvestment[]>([]);

  const [data, setData] = useState<IFirstCheckAndFollowOns>();

  useEffect(() => {
    if(!authUser?.fund.id) return; 
    
    const q = query(collection(firestore, "investments"), where("fund.id", "==", authUser.fund.id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => doc.data() as IInvestment);
      setInvestments(data);
    });

    // eslint-disable-next-line consistent-return
    return () => unsubscribe();
  }, [authUser?.fund.id]);
  
  const DATA_COLORS = ["#60a5fa", "#f472b6"];

  useEffect(() => {
    if(investments.length === 0) return;

    // Sort investments by date
    investments.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    // Group investments by company.id
    const groupByCompany = _.groupBy(investments, "company.id");

    // Fist check is the first investment for each company
    const firstCheck = Object.values(groupByCompany).map((investments) => investments[0]);

    // Follow ons are the rest of the investments 
    const followOns = investments.filter((investment) => !firstCheck.includes(investment));
    
    const firstCheckAmounts: any = {}
    for (const investment of firstCheck) {
      if(firstCheckAmounts[investment.currency]) {
        firstCheckAmounts[investment.currency] += investment.amount;
      } else {
        firstCheckAmounts[investment.currency] = investment.amount;
      }
    }
    const followOnsAmounts: any = {}
    for (const investment of followOns) {
      if(followOnsAmounts[investment.currency]) {
        followOnsAmounts[investment.currency] += investment.amount;
      } else {
        followOnsAmounts[investment.currency] = investment.amount;
      }
    }

    const firstCheckTotal = getConvertedAmounts(firstCheckAmounts).amount

    const followOnsTotal = getConvertedAmounts(followOnsAmounts).amount

    const total = firstCheckTotal + followOnsTotal;
    
    const firstCheckPercentage = (firstCheckTotal / total) * 100;
    const followOnsPercentage = (followOnsTotal / total) * 100;

    setData({
      firstCheck: firstCheckAmounts,
      firstCheckPercentage,
      followOns: followOnsAmounts,
      followOnsPercentage
    });
  }, [investments]);

  if(!data) return (<div/>)

	return  (
   <div className="border bg-white border-[#E4E5E8] rounded flex py-5 px-4 max-w-[570px]">
    <div className="flex flex-col">
      <div className="font-semibold">
        Investments First Check and Follow ons
      </div>
      <div className="flex items-center mt-5">
        <div className="w-2 h-2 rounded-full bg-blue-400 mr-2"/>
        <span className="w-24">
          First Check 
        </span>
        <span className="mr-2">
           { displayAmount(getConvertedAmounts(data.firstCheck)) }
        </span>
        <span className="font-semibold">
          - { percentangeFormatter(data.firstCheckPercentage) }
        </span>
      </div>
      <div className="flex items-center">
        <div className="w-2 h-2 rounded-full bg-pink-400 mr-2"/>
        <span className="w-24">
          Follow Ons
        </span>
        <span className="mr-2">
          { displayAmount(getConvertedAmounts(data.followOns)) }
        </span>
        <span className="font-semibold">
          - { percentangeFormatter(data.followOnsPercentage) }
        </span>
      </div>
    </div>
    <div>
      <PieChart data={firstCheckAndFollowOnReportMapper(data)} dataColors={DATA_COLORS} />
    </div>
   </div>
  )
}
