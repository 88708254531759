/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
import {
	CaretSortIcon,
	CheckCircledIcon,
	CrossCircledIcon
} from '@radix-ui/react-icons'
import type { Dispatch, SetStateAction } from 'react'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger
} from 'components/ui/dropdown-menu'
import { MailIcon, Pencil, XCircleIcon } from 'lucide-react'
import {
	addSelectedCapitalCallRequest,
	removeSelectedCapitalCallRequest,
	selectSelectedCapitalCallRequests,
	setSelectedCapitalCallRequests
} from 'features/new-capital-call/redux/capitalCallRequests.slice'
import { renderDate, renderDateAndTime } from 'utils/date.util'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useContext, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import type { CapitalCallRequest } from 'features/new-capital-call/interfaces/CapitalCallRequest'
import { CapitalCallRequestService } from 'features/new-capital-call/service/capital_call_request.service'
import { Checkbox } from 'components/ui/checkbox'
import type { ColumnDef } from '@tanstack/react-table'
import { CustomModal } from 'components/Modals/CustomModal'
import { DataTable } from 'features/dataTable/DataTable'
import { DialogModal } from 'components/shadcn/DialogModal'
import FileViewer from 'features/fileviewers/views/FileViewer'
import type { ITableColumn } from 'features/dataTable/interfaces/datatable.interface'
import { ImSpinner10 } from 'react-icons/im'
import { Input } from 'components/ui/input'
import Logger from 'lib/logger'
import UpdateCapitalCallRequestWithWiseTransaction from './UpdateCapitalCallRequestWithWiseTransaction'
import { UserRole } from 'interfaces'
import { addCapitalCall } from '../api/capital-call.api'
import { displayAmount } from 'utils/currency'
import { nanoid } from 'nanoid'
import { percentangeFormatter } from 'utils/percentageFormatter.util'
import { toast } from 'sonner'

const ApprovalRoles = new Set([UserRole.GeneralPartner])

interface ICapitalCallRequestListProps {
	className?: string
	data: CapitalCallRequest[]
}

function ConfirmEmailModal({
	requests,
	isOpen,
	setIsOpen
}: {
	requests: CapitalCallRequest[]
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
}) {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [testEmail, setTestEmail] = useState<string>(
		requests[0].mail.testMailRecipient || ''
	)

	return (
		<DialogModal
			title='Confirm Email'
			open={isOpen}
			setOpen={setIsOpen}
			trigger={
				<div
					onClick={() => {
						setIsOpen(true)
					}}
					className='flex items-center'
				>
					<MailIcon width='24' height='24' color='green' />
					<span className='ml-3'>Send Test Mail</span>
				</div>
			}
			description='Please confirm email receipient address'
		>
			<div>
				<div className='flex flex-col justify-center gap-4'>
					<p>The test email sent will go to this email:</p>

					{/* Input box for email address */}
					<Input
						id='test-email'
						label='Email Address'
						type='email'
						isRequired
						value={testEmail}
						error=''
						onInput={e => {
							e.stopPropagation()
							setTestEmail((e.target as HTMLInputElement).value)
						}}
					/>

					<div className='w-min'>
						<Button
							className='truncate px-5'
							variant='blue'
							isLoading={isLoading}
							onClick={async () => {
								try {
									setIsLoading(true)

									// Send bulk emails
									const tasks = requests.map(item => {
										return CapitalCallRequestService.sendTestMail({
											...item,
											mail: { ...item.mail, testMailRecipient: testEmail }
										})
									})

									await Promise.all(tasks)

									setIsOpen(false)
								} catch {
									Logger.error('Failed to send test email')
								} finally {
									setIsLoading(false)
								}
							}}
						>
							Confirm
						</Button>
					</div>
				</div>
			</div>
		</DialogModal>
	)
}

const ApprovedText = (
	<div className='flex items-center'>
		<CheckCircledIcon width='24' height='24' color='green' />
		<span className='ml-3 text-blue-700'>Approved</span>
	</div>
)

const DeclinedText = (
	<div className='flex items-center'>
		<XCircleIcon width='24' height='24' color='red' />
		<span className='ml-3 text-blue-700'>Decline</span>
	</div>
)

export default function CapitalCallRequestList({
	className,
	data
}: ICapitalCallRequestListProps): JSX.Element {
	const dispatch = useAppDispatch()
	const { authUser } = useContext(AuthContext)

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isConfirmEmailModalOpen, setIsConfirmEmailModalOpen] =
		useState<boolean>(false)
	const [selectedRequests, setSelectedRequests] = useState<
		CapitalCallRequest[]
	>([])

	// Update modal
	const [selectedRequest, setSelectedRequest] = useState<CapitalCallRequest>()
	const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false)

	// Wise Modal
	const [openWiseModal, setOpenWiseModal] = useState<boolean>(false)

	const selectedCapitalCallRequests = useAppSelector(
		selectSelectedCapitalCallRequests
	)

	async function onApproveCapitalCall(request: CapitalCallRequest) {
		if (!authUser) return

		try {
			setIsLoading(true)
			CapitalCallRequestService.updateCapitalCallRequest(request.id, {
				lastUpdatedAt: new Date().toISOString(),
				isApproved: true
			})

			// Add capital call
			await addCapitalCall({
				id: nanoid(),
				amount: request.amount,
				approvedBy: {
					id: authUser.id,
					name: authUser.name,
					photoUrl: authUser.photoUrl
				},
				status: 'Sent' as any,
				calledBy: request.calledBy,
				dateCalled: new Date().toISOString(),
				dueDate: request.dueDate,
				fund: request.fund,
				limitedPartner: request.limitedPartner,
				percent: request.percentage,
				createdAt: request.createdAt,
				lastUpdatedAt: new Date().toISOString(),
				requestId: request.id
			})

			toast.success('Capital call request approved')
			setIsLoading(false)
		} catch {
			setIsLoading(false)
		}
	}

	async function onApproveCapitalCalls(requests: CapitalCallRequest[]) {
		// Has declined capital call requests
		if (requests.some(item => !item.isApproved)) {
			toast.error('Cannot approve declined capital call requests')
			return
		}

		const tasks = requests.map(item => {
			return onApproveCapitalCall(item)
		})

		await Promise.all(tasks)
	}

	async function onDeclineCapitalCall(request: CapitalCallRequest) {
		try {
			setIsLoading(true)
			CapitalCallRequestService.updateCapitalCallRequest(request.id, {
				lastUpdatedAt: new Date().toISOString(),
				isApproved: false
			})
			toast.success('Capital call request declined')
			setIsLoading(false)
		} catch {
			setIsLoading(false)
		}
	}

	async function onDeclineCapitalCalls(requests: CapitalCallRequest[]) {
		// Has approved capital call requests
		if (requests.some(item => item.isApproved)) {
			toast.error('Cannot decline approved capital call requests')
			return
		}

		const tasks = requests.map(item => {
			return onDeclineCapitalCall(item)
		})

		await Promise.all(tasks)
	}

	async function onDeleteCapitalCall(request: CapitalCallRequest) {
		try {
			setIsLoading(true)
			await CapitalCallRequestService.deleteCapitalCallRequest(request.id)
			setIsLoading(false)
		} catch {
			setIsLoading(false)
		}
	}

	async function onDeleteCapitalCalls(requests: CapitalCallRequest[]) {
		// Has approved capital call requests
		if (requests.some(item => item.isApproved)) {
			toast.error('Cannot delete approved capital call requests')
			return
		}

		const tasks = requests.map(item => {
			return onDeleteCapitalCall(item)
		})

		await Promise.all(tasks)
	}

	function CTADropdownMenu(request: CapitalCallRequest) {
		return (
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button className='truncate px-5' variant='blue'>
						Choose Action
						<CaretSortIcon className='ml-2 h-4 w-4' />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent className='w-56' align='end' forceMount>
					<DropdownMenuGroup>
						{/* Approve */}
						<DropdownMenuItem
							disabled={!ApprovalRoles.has(authUser?.role as UserRole)}
							onClick={() => onApproveCapitalCall(request)}
						>
							<div className='flex items-center'>
								<CheckCircledIcon width='24' height='24' color='green' />
								<span className='ml-3'>Approve</span>
							</div>
						</DropdownMenuItem>

						{/* Send Test email */}
						<DropdownMenuItem
							disabled={!request.pdfUrl}
							onClick={() => {
								setSelectedRequests([request])
								setIsConfirmEmailModalOpen(true)
							}}
						>
							<div className='flex items-center'>
								<MailIcon width='24' height='24' color='green' />
								<span className='ml-3'>Send Test Mail</span>
							</div>
						</DropdownMenuItem>

						{/* Decline */}
						<DropdownMenuItem
							disabled={!ApprovalRoles.has(authUser?.role as UserRole)}
							onClick={() => onDeclineCapitalCall(request)}
						>
							<div className='flex items-center'>
								<CrossCircledIcon width='24' height='24' color='red' />
								<span className='ml-3'>Decline</span>
							</div>
						</DropdownMenuItem>

						<DropdownMenuSeparator />

						<DropdownMenuItem onClick={() => onDeleteCapitalCall(request)}>
							<div className='flex items-center'>
								<CrossCircledIcon width='24' height='24' color='red' />

								<span className='ml-3'>Delete</span>
							</div>
						</DropdownMenuItem>
					</DropdownMenuGroup>
				</DropdownMenuContent>
			</DropdownMenu>
		)
	}

	const columns: ColumnDef<CapitalCallRequest>[] = [
		{
			id: 'select',
			header: ({ table }) => (
				<Checkbox
					className='ml-2 mr-4'
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && 'indeterminate')
					}
					onCheckedChange={value => {
						table.toggleAllPageRowsSelected(!!value)

						if (value) {
							dispatch(setSelectedCapitalCallRequests(data))
						} else {
							dispatch(setSelectedCapitalCallRequests([]))
						}
					}}
					aria-label='Select all'
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					className='ml-2 mr-4'
					checked={selectedCapitalCallRequests.includes(row.original)}
					onCheckedChange={value => {
						if (value) {
							dispatch(addSelectedCapitalCallRequest(row.original))
						} else {
							dispatch(removeSelectedCapitalCallRequest(row.original))
						}
					}}
					aria-label='Select row'
				/>
			),
			enableSorting: false,
			enableHiding: false
		},
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Name
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>{row.original.limitedPartner.name}</div>
			)
		},
		{
			accessorKey: 'recipients',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Recipient
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>{row.original.recipients[0]}</div>
			)
		},
		{
			accessorKey: 'percentage',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Percentage
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>
					{percentangeFormatter(row.original.percentage)}
				</div>
			)
		},
		{
			accessorKey: 'amount',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Amount
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>
					{displayAmount(row.original.amount, true)}
				</div>
			)
		},
		{
			accessorKey: 'dateCreated',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Date Created
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>
					{renderDateAndTime(row.original.createdAt)}
				</div>
			)
		},
		{
			accessorKey: 'dateDue',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Due Date
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>
					{renderDateAndTime(row.original.dueDate)}
				</div>
			)
		},
		{
			accessorKey: 'document',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Document
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<FileViewer
					className='truncate'
					file={{
						name: `${row.original.limitedPartner.name}.pdf`,
						url: row.original.pdfUrl
					}}
					isDownloadable
					content={
						<div className='flex justify-center'>
							<div
								className={`group flex w-fit cursor-pointer items-center gap-1 text-ellipsis rounded px-3 py-[1px] text-xs text-blue-800 ${
									row.original.pdfUrl ? ' bg-blue-200' : ' bg-gray-200'
								}`}
							>
								Open
							</div>
						</div>
					}
				/>
			)
		},
		{
			accessorKey: 'wiseTransaction',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Wise
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div>
					{row.original.wiseTransactionId ? (
						<div className='flex items-start justify-center'>
							<CustomModal
								open={openWiseModal}
								setOpen={setOpenWiseModal}
								trigger={
									<div className='flex justify-center'>
										<div
											onClick={() => {
												setOpenWiseModal(true)
											}}
											className={`group flex  w-fit cursor-pointer items-center gap-1 text-ellipsis rounded bg-blue-200 px-3 py-[1px] text-xs
						text-blue-800 whitespace-nowrap`}
										>
											View Txn
										</div>
									</div>
								}
							>
								<h3 className='mb-4 font-semibold'>Wise Tranaction Details</h3>

								<div className='flex flex-col gap-2'>
									<p>
										Title:
										<span
											className='no-strong whitespace-nowrap capitalize'
											dangerouslySetInnerHTML={{
												__html: row.original.wiseTransaction?.title || ''
											}}
										/>
									</p>
									<p>
										Date:{' '}
										{renderDate(
											row.original.wiseTransaction?.createdAt || new Date()
										)}
									</p>
									<p className='flex gap-2'>
										Amount:{' '}
										<span className='capitalize'>
											{row.original.wiseTransaction?.amount
												.replaceAll(/<\/?[^>]+>/g, '')
												.replaceAll('+ ', '')}
										</span>
									</p>
									<p>Type: {row.original.wiseTransaction?.type}</p>
									<p>Satus: {row.original.wiseTransaction?.status}</p>
								</div>
								<Button
									onClick={() => setOpenWiseModal(false)}
									className='ml-auto mt-4 px-4'
								>
									Close
								</Button>
							</CustomModal>

							<div
								onClick={() => {
									setSelectedRequest(row.original)
									setIsUpdateModalOpen(true)
								}}
								className='pl-2 cursor-pointer opacity-30'
							>
								<Pencil size={14} />
							</div>
						</div>
					) : (
						<div className='flex justify-center'>
							<div
								onClick={() => {
									setSelectedRequest(row.original)
									setIsUpdateModalOpen(true)
								}}
								className={`group flex w-fit cursor-pointer items-center gap-1 text-ellipsis rounded bg-blue-200 px-3 py-[1px] text-xs mr-4
									text-blue-800`}
							>
								Assign Txn
							</div>
						</div>
					)}
				</div>
			)
		},
		{
			accessorKey: 'status',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Status
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => <div className='truncate'>{row.original.status}</div>
		},
		{
			accessorKey: 'cta',
			maxSize: 150,
			minSize: 150,
			header: () => '',
			cell: ({ row }) => (
				<div className='flex justify-center'>
					{isLoading && (
						<ImSpinner10 className='text-gray h-4 w-4 animate-spin' />
					)}

					{!isLoading &&
						row.original.isApproved === undefined &&
						CTADropdownMenu(row.original)}
					{!isLoading &&
						row.original.isApproved !== undefined &&
						row.original.isApproved &&
						ApprovedText}
					{!isLoading &&
						row.original.isApproved !== undefined &&
						!row.original.isApproved &&
						DeclinedText}
				</div>
			)
		}
	]

	function CustomHeaders() {
		if (selectedCapitalCallRequests.length === 0) return null

		return (
			<div className='mx-4 flex gap-4'>
				<Button
					className='truncate px-5'
					variant='blue'
					disabled={
						selectedCapitalCallRequests.some(item => item.isApproved) ||
						!ApprovalRoles.has(authUser?.role as UserRole)
					}
					onClick={() => onApproveCapitalCalls(selectedCapitalCallRequests)}
				>
					Approve All
				</Button>
				<Button
					className='truncate px-5'
					variant='blue'
					disabled={
						selectedCapitalCallRequests.some(item => !item.isApproved) ||
						!ApprovalRoles.has(authUser?.role as UserRole)
					}
					onClick={() => onDeclineCapitalCalls(selectedCapitalCallRequests)}
				>
					Decline All
				</Button>
				<Button
					className='truncate px-5'
					variant='blue'
					disabled={
						selectedCapitalCallRequests.some(item => item.isApproved) ||
						selectedCapitalCallRequests.some(item => !item.pdfUrl)
					}
					onClick={() => {
						setSelectedRequests(selectedCapitalCallRequests)
						setIsConfirmEmailModalOpen(true)
					}}
				>
					Send Test Email
				</Button>
				<Button
					className='truncate px-5'
					variant='danger'
					disabled={selectedCapitalCallRequests.some(item => item.isApproved)}
					onClick={() => onDeleteCapitalCalls(selectedCapitalCallRequests)}
				>
					Delete All
				</Button>
			</div>
		)
	}

	return (
		<div className={`${className} flex flex-col`}>
			{selectedRequests.length > 0 && (
				<ConfirmEmailModal
					requests={selectedRequests}
					isOpen={isConfirmEmailModalOpen}
					setIsOpen={setIsConfirmEmailModalOpen}
				/>
			)}
			{selectedRequest && (
				<UpdateCapitalCallRequestWithWiseTransaction
					ccRequest={selectedRequest}
					isOpen={isUpdateModalOpen}
					setIsOpen={setIsUpdateModalOpen}
				/>
			)}
			<DataTable
				isGrouped={false}
				entity='capital-call-request'
				className=''
				columns={columns}
				data={data}
				filterPlaceholder='Search....'
				customRow={<CustomHeaders />}
				onColumnUpdate={(updatedColumns: ITableColumn[]) => {
					Logger.info('Updated columns', updatedColumns)
				}}
				onColumnVisibilityChange={(columnId: string, isVisible: boolean) => {
					Logger.info('Updated columns', columnId, isVisible)
				}}
			/>
		</div>
	)
}
