// ...
import * as Sentry from '@sentry/react'
import { Config } from 'app/config'
import { store } from 'app/store'

Sentry.init({
	dsn: Config.SENTRY_DSN,
	environment: Config.NODE_ENV,

	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration()
	],

	// Performance Monitoring
	tracesSampleRate: 1, //  Capture 100% of the transactions

	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	// tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

	// Session Replay
	replaysSessionSampleRate: Config.NODE_ENV === 'development' ? 1 : 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const Errorhandler = {
	captureException(error: Error) {
		if (Config.NODE_ENV === 'development') return

		const currentUser = store.getState().authentication.authUser

		Sentry.setUser({
			email: currentUser?.email || '',
			id: currentUser?.id || '',
			name: currentUser?.name || ''
		})

		Sentry.captureException(error)
	}
}
export default Errorhandler

export { Errorhandler }
