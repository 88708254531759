import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { useEffect, useState } from 'react'

import { Building2 } from 'lucide-react'
/* eslint-disable react-hooks/exhaustive-deps */
import Button from 'components/Buttons/Button'
import { DialogFooter } from 'components/ui/dialog'
import { DialogModal } from 'components/shadcn/DialogModal'
import type { IContact } from 'interfaces'
import { Input } from 'components/ui/input'
import useCreateCompany from 'features/companies/hooks/useCreateCompany'

export function CreateCompanyFromContact({ contact }: { contact: IContact }) {
	const [isOpen, setIsOpen] = useState(false)
	const { formik } = useCreateCompany({
		onSuccess: () => {
			setIsOpen(false)
		}
	})

	useEffect(() => {
		if (!isOpen) formik.resetForm()
		formik.setFieldValue('name', contact.name)
		formik.setFieldValue('location', contact.location ?? {})
		formik.setFieldValue('tags', contact.tags ?? [])
		formik.setFieldValue('founders', [
			{
				id: contact.id,
				name: contact.name,
				email: contact.email,
				linkedIn: contact.linkedIn ?? ''
			}
		])
	}, [contact, isOpen])

	return (
		<DialogModal
			size='xl'
			title='Create Company'
			description='Create a new company this from this contact'
			trigger={
				<div className='flex items-center gap-2'>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button id='create-company-from-contact-trigger' className='p-1' variant='outline'>
								<Building2 color='blue' />
							</Button>
						</TooltipTrigger>
						<TooltipContent>
							<p>Create company from contact</p>
						</TooltipContent>
					</Tooltip>
				</div>
			}
			open={isOpen}
			setOpen={setIsOpen}
		>
			<div className='grid-col-2 grid gap-2'>
				<Input
					id='name'
					label='Company Name'
					value={formik.values.name}
					error={formik.errors.name}
					className='col-span-3'
					onChange={e => {
						formik.setFieldValue('name', e.target.value)
					}}
				/>

				<DialogFooter className='mt-5'>
					<Button
						id='create-company-from-contact-button'
						type='submit'
						onClick={() => formik.handleSubmit()}
						disabled={formik.isSubmitting || !formik.isValid}
						isLoading={formik.isSubmitting}
					>
						Create
					</Button>
				</DialogFooter>
			</div>
		</DialogModal>
	)
}
