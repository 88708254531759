/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-misused-promises */

import Button from 'components/Buttons/Button'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import { DialogModal } from 'components/shadcn/DialogModal'
import { Checkbox } from 'components/ui/checkbox'
import { Input } from 'components/ui/input'
import { UserRole, type Partner } from 'interfaces'
import { CameraIcon } from 'lucide-react'
import { useState } from 'react'
import { ImSpinner10 } from 'react-icons/im'
import { readFile } from 'utils'
import clsxm from 'utils/clsxm'
import { mapRoleToName } from 'utils/roles'
import useEditPartner from '../hooks/useEditPartner'

const handlePhotoUpload = () => {
	const fileInput = document.getElementById('partner-photo') as HTMLInputElement
	fileInput.click()
}

export default function EditPartner({ editPartner }: { editPartner: Partner }) {
	const [isOpen, setIsOpen] = useState(false)

	const { formik } = useEditPartner({
		partner: editPartner,
		onSuccess: () => {
			setIsOpen(false)
		}
	})

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	const handlePhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target
		if (files) {
			const [file] = files

			readFile(file, value => {
				const photoURL = value.target?.result as string
				formik.setFieldValue('photoUrl', photoURL)
			})
		}
	}

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title='Edit Partner'
			description=''
			trigger={
				<Button
					id={`edit-partner-${editPartner.name}`}
					onClick={openModal}
					variant='ghost'
					className='px-2 py-1'
				>
					Edit
				</Button>
			}
		>
			<div className=''>
				<div className='flex flex-col gap-5'>
					<div className='flex h-max w-full flex-col sm:flex-row'>
						<input
							type='file'
							hidden
							id='partner-photo'
							accept='image/*'
							onChange={handlePhotoChange}
						/>
						<div
							className='relative flex h-20 w-20 cursor-pointer items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300'
							onClick={handlePhotoUpload}
						>
							{formik.values.photoUrl ? (
								<div className='group'>
									<img
										src={formik.values.photoUrl}
										height={200}
										width={400}
										className='h-20 w-20 rounded-full object-cover'
										alt='profile'
									/>
									<div
										className={clsxm(
											'absolute inset-0 cursor-pointer rounded-full bg-black',
											'flex items-center justify-center bg-opacity-5 hover:bg-opacity-10',
											'text-gray-200'
										)}
									>
										<CameraIcon
											className='h-8 w-8 opacity-10 transition-all group-hover:opacity-100'
											color='black'
										/>
									</div>
								</div>
							) : (
								<CameraIcon className='h-8 w-8' />
							)}
						</div>
					</div>
					<Input
						error={formik.errors.name}
						id='name'
						name='name'
						label='Name'
						placeholder='Enter partner name'
						value={formik.values.name}
						onChange={async e => formik.setFieldValue('name', e.target.value)}
					/>

					<Input
						error={formik.errors.email}
						id='email'
						name='email'
						disabled
						label='Email'
						placeholder='Enter partner email'
						value={formik.values.email}
						onChange={async e => formik.setFieldValue('email', e.target.value)}
					/>

					<Checkbox
						id='receives-emails'
						data-testid='receives-emails'
						label='Receives Email Updates'
						checked={formik.values.receivesEmails}
						onCheckedChange={value =>
							formik.setFieldValue('receivesEmails', value)
						}
						aria-label='Receives Email Updates'
					/>

					{/* User Role Selector */}
					<ComboSelect
						label='Role'
						placeholder='Select Role'
						onSelect={value => {
							formik.setFieldValue('role', value)
						}}
						defaultValue={formik.values.role}
						options={Object.values(UserRole)
							.filter(role => role !== UserRole.SuperAdmin)
							.map(role => ({
								label: mapRoleToName(role),
								value: role
							}))}
					/>
				</div>
				<div className='mt-10 flex w-full justify-end gap-5'>
					{!formik.isSubmitting && (
						<Button
							id='cancel-edit-partner-button'
							variant='danger'
							onClick={() => closeModal()}
						>
							Cancel
						</Button>
					)}
					<Button
						id='edit-partner-button'
						disabled={formik.isSubmitting || !formik.isValid}
						onClick={() => formik.handleSubmit()}
					>
						{formik.isSubmitting ? (
							<div className='flex items-center gap-2'>
								<ImSpinner10 className='animate-spin' /> Saving...
							</div>
						) : (
							'Save'
						)}
					</Button>
				</div>
			</div>
		</DialogModal>
	)
}
