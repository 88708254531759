/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/prefer-ternary */
/* eslint-disable @typescript-eslint/no-misused-promises */
import Button from 'components/Buttons/Button'
import { Input } from 'components/ui/input'
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot
} from 'components/ui/input-otp'
import { addSharedLinkView } from 'features/companies/api/sharedLinks.api'
import { userHasAccessToLink } from 'features/companies/helpers/ShareCompany.helper'
import type {
	ISharedLink,
	ISharedLinkView
} from 'features/companies/interfaces/sharedLink.interface'
import { sendOTP, validateOTP } from 'features/otps/otp.api'
import { useFormik } from 'formik'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { toast } from 'sonner'
import * as Yup from 'yup'

interface Props {
	linkData?: ISharedLink | null
}

export default function SharedLinkRequireEmail({ linkData }: Props) {
	const [isSendingOTP, setIsSendingOTP] = useState(false)
	const [isOTPSent, setIsOTPSent] = useState(false)
	const [isOTPVerified, setIsOTPVerified] = useState(false)

	const requireVerificationValidation = Yup.object({
		otp: Yup.string()
			.required('OTP is required')
			.min(6, 'OTP must be 6 characters'),
		email: Yup.string().email('Invalid email').required('Email is required')
	})

	const validationSchema = Yup.object({
		email: Yup.string().email('Invalid email').required('Email is required')
	})

	const formik = useFormik({
		initialValues: {
			email: localStorage.getItem('sharedLinkEmail') || '',
			otpRequired: linkData?.requiresEmailVerification || false,
			otp: ''
		},
		validationSchema: linkData?.requiresEmailVerification
			? requireVerificationValidation
			: validationSchema,
		onSubmit: async values => {
			try {
				formik.setSubmitting(true)

				const data: ISharedLinkView = {
					id: nanoid(),
					email: values.email,
					sharedLinkId: linkData?.id || '',
					createdAt: new Date().toISOString()
				}

				await addSharedLinkView(data)

				if (linkData?.access === 'public') {
					localStorage.setItem('sharedLinkEmail', values.email)
					window.location.reload()
				}

				const hasAccess = await userHasAccessToLink(linkData, values.email)
				if (hasAccess) {
					localStorage.setItem('sharedLinkEmail', values.email)
					window.location.reload()
				} else {
					toast.error('You do not have access to this link')
				}
			} catch (error: any) {
				toast.error(error.message ?? 'Error fetching shared link')
			} finally {
				formik.setSubmitting(false)
			}
		}
	})

	function handleEnterKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key === 'Enter') {
			formik.handleSubmit()
		}
	}

	async function onSendOTP() {
		try {
			setIsSendingOTP(true)

			const result = await sendOTP(formik.values.email)
			if (result.success) {
				toast.success('OTP sent successfully')
				setIsOTPSent(true)
			} else {
				toast.error(result.error ?? 'Error sending OTP')
			}
		} catch (error: any) {
			toast.error(error.message ?? 'Error sending OTP')
		} finally {
			setIsSendingOTP(false)
		}
	}

	async function onViewLink() {
		try {
			if (isOTPSent && !isOTPVerified) {
				const result = await validateOTP(formik.values.email, formik.values.otp)
				if (result.success) {
					toast.success('OTP verified successfully')
					setIsOTPVerified(true)
				} else {
					toast.error(result.error ?? 'Error verifying OTP')
					return
				}
			}

			formik.handleSubmit()
		} catch (error: any) {
			toast.error(error.message ?? 'Error verifying OTP')
		}
	}

	return (
		<div
			data-testid='shared-link-require-email'
			className='w-[30rem]'
		>
			<div>
				You have been invited by {linkData?.creator.name} to view some
				companies. Enter your email to view them.
			</div>

			<Input
				error={formik.errors.email}
				id='email'
				name='email'
				placeholder='Enter Email'
				className='mt-3 flex-1'
				value={formik.values.email}
				onChange={formik.handleChange}
				onKeyDown={handleEnterKeyPress}
			/>

			{linkData?.requiresEmailVerification && (
				<Button
					disabled={formik.isSubmitting}
					isLoading={isSendingOTP}
					onClick={onSendOTP}
					variant='outline'
					className='mt-4 w-fit'
				>
					Send OTP
				</Button>
			)}

			{linkData?.requiresEmailVerification && (
				<div className='mt-5'>
					<div className='mb-2 text-sm'>Enter OTP sent to your email</div>
					<InputOTP
						maxLength={6}
						onChange={value => {
							formik.setFieldValue('otp', value)
						}}
					>
						<InputOTPGroup>
							<InputOTPSlot index={0} />
							<InputOTPSlot index={1} />
							<InputOTPSlot index={2} />
						</InputOTPGroup>
						<InputOTPSeparator />
						<InputOTPGroup>
							<InputOTPSlot index={3} />
							<InputOTPSlot index={4} />
							<InputOTPSlot index={5} />
						</InputOTPGroup>
					</InputOTP>
					<div>
						{formik.errors.otp && formik.touched.otp && (
							<div className='mt-2 text-xs text-red-500'>
								{formik.errors.otp}
							</div>
						)}
					</div>
				</div>
			)}

			<div className='flex w-full justify-between pt-5'>
				{/* <Button
					disabled={formik.isSubmitting || !formik.isValid}
					onClick={() => {
						toast.success('Coming soon')
					}}
					variant='outline'
					className='w-fit'
				>
					Request Access
				</Button> */}

				<Button
					id='view-link-button'
					disabled={formik.isSubmitting || !formik.isValid}
					onClick={onViewLink}
					isLoading={formik.isSubmitting}
					variant='blue'
					className='w-fit'
				>
					View Link
				</Button>
			</div>
		</div>
	)
}
