/* eslint-disable @typescript-eslint/no-misused-promises */
import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import clsxm from 'utils/clsxm'

interface Props {
	deleteButtonId: string
	title: string
	description: string
	trigger: React.ReactNode
	isOpen?: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	onDelete: () => void
	triggerClassName?: string
}

export default function DeleteModal2({
	title,
	description,
	trigger,
	onDelete,
	deleteButtonId,
	triggerClassName,
	isOpen,
	setIsOpen
}: Props) {
	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title={title}
			description={description}
			trigger={
				<Button
					variant='ghost'
					className={clsxm('w-full text-left', triggerClassName)}
					onClick={openModal}
				>
					{trigger}
				</Button>
			}
		>
			<div className='mt-10 flex w-full justify-end gap-5'>
				<Button variant='danger' onClick={() => closeModal()}>
					Cancel
				</Button>
				<Button
					id={`${deleteButtonId}-delete-button`}
					data-testid={`${deleteButtonId}-delete-button`}
					onClick={() => {
						onDelete()
						closeModal()
					}}
				>
					Delete
				</Button>
			</div>
		</DialogModal>
	)
}
