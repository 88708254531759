/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FundData } from 'interfaces'

export interface ICapitalCallTemplate {
	id: string
	fund: FundData
	name: string
	description: string
	html: string
	testData: Record<string, any>
	pdfUrl?: string
	thumbnailUrl?: string
	type: CapitalCallTemplateType,
	createdAt: string
	lastUpdatedAt: string
}

export enum CapitalCallTemplateType {
	BankAcccount = 'Bank Account',
	CryptoAccount = 'Crypto Account'
}