/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-extraneous-class */
/* eslint-disable class-methods-use-this */
import logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'
import { toast } from 'sonner'
import { deleteInvitation } from '../invitations.api'

export class InvitationService {
	public static async delete(invitationId: string): Promise<void> {
		try {
			await deleteInvitation(invitationId)

			toast.success('Invitation deleted successfully')
		} catch (error: any) {
			toast.error(error.message ?? 'Error deleting invitation')

			logger.error('Error deleting invitation:', error)
			Errorhandler.captureException(error)
		}
	}
}
