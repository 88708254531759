import clsxm from 'utils/clsxm'

type SkeletonProps = React.ComponentPropsWithoutRef<'div'>

export default function Skeleton({ className, style, ...rest }: SkeletonProps) {
	return (
		<div
			className={clsxm('animate-pulse rounded bg-gray-300', className)}
			style={{
				backgroundImage:
					'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
				// backgroundSize: '700px 100%',
				backgroundRepeat: 'no-repeat',
				...style
			}}
			{...rest}
		/>
	)
}
