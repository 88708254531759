/* eslint-disable @typescript-eslint/no-shadow */
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "context/AuthContext";
import type { ILineChartData } from "../interfaces/reports.interface";
import { InvestmentService } from "features/investments/service/investment.service";
import LineChart from "../components/LineChart";
import _ from "lodash";
import { getConvertedAmount } from "utils/currency";
import { selectInvestments } from "features/investments/redux/investments.slice";
import { useAppSelector } from "app/hooks";

export default function AveragePercentChart(): JSX.Element {

  const {authUser} = useContext(AuthContext);

  const investments = useAppSelector(selectInvestments); 

  const [data, setData] = useState<ILineChartData[]>();

  useEffect(() => {
    if(!authUser?.fund.id) return;

    const unsubscribe = InvestmentService.listenToInvestments(authUser.fund.id);

    // eslint-disable-next-line consistent-return
    return () => unsubscribe();
  }, [authUser?.fund.id]);

  useEffect(() => {
    // Group by company.name and calculate the average percentage
    const groupByCompany = _.groupBy(investments, "company.name");

    const data: ILineChartData[] = [];

    for (const key of Object.keys(groupByCompany)) {
      const investments = groupByCompany[key];
      // eslint-disable-next-line unicorn/no-array-reduce
      const totalOwnership = investments.reduce((acc, investment) => {
        const investmentAmount = getConvertedAmount({
          currency: investment.currency,
          amount: investment.amount
        }).amount
        const investmentValuation = getConvertedAmount({
          currency: investment.currency,
          amount: investment.valuation
        }).amount 
        if(investmentValuation === 0) return acc;
        const ownership = (investmentAmount / investmentValuation) * 100
        // Make to 2 decimal places
        const ownershipTo2Decimal = Math.round(ownership * 100) / 100
        return acc + ownershipTo2Decimal;
      }, 0)
      data.push({name: key, value: totalOwnership});
    }

    // Sort by value
    data.sort((a, b) => b.value - a.value);
    setData(data);
  } , [investments]);

  if(!data) {
    return <div>Loading...</div>
  }
  
	return  (
    <div className="border bg-white border-[#E4E5E8] rounded-md py-5 px-4 min-w-[570px]">
      <div className="font-semibold">
        Average Percent Chart
      </div>
      <div>
        <LineChart data={data} />
      </div>
    </div>
  )
}
