/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import type { ISharedLinkCompanyComment } from 'features/sharedlinks/interfaces/sharedlink.interface'
import { X } from 'lucide-react'
import { Link } from 'react-router-dom'
import { renderDate } from 'utils/date.util'
import { purifyHtml } from 'utils/purifyHtml'


export default function CommentItem({
  comment, isReadOnly, includeCompany, showEmail = true, commenterPhotoUrl
}: { comment: ISharedLinkCompanyComment, isReadOnly?: boolean, includeCompany?: boolean, showEmail?: boolean, commenterPhotoUrl?: string }) {

  return (
    <div>
      <div className='group'>
        <div className='flex items-center gap-2'>
          <div className='flex items-center gap-2'>
            <Avatar className='h-8 w-8 border'>
              <AvatarImage
                src={commenterPhotoUrl}
              />
              <AvatarFallback>{comment.user.email.charAt(0)}</AvatarFallback>
            </Avatar>
            {includeCompany && <div className='flex items-center gap-2'>
              <X size={13} color='gray' />
              <Link
                to={`/company/${comment.company?.id}`}
              >
                <Tooltip key={comment.id}>
                  <TooltipTrigger asChild >
                    <Avatar className='h-8 w-8 border cursor-pointer'>
                      <AvatarImage
                        src={
                          comment.company?.website
                            ? `https://www.google.com/s2/favicons?sz=128&domain_url=${comment.company.website}`
                            : '/images/logo/Logo-4.png'
                        }
                      />
                      <AvatarFallback>{comment.user.email.charAt(0)}</AvatarFallback>
                    </Avatar>

                  </TooltipTrigger>
                  <TooltipContent side='right'>View {comment.company?.name}</TooltipContent>
                </Tooltip>
              </Link>
            </div>}
          </div>
          {showEmail && <div className='text-sm'>{comment.user.email}</div>}
          <div className='flex-1' />
          <div className='text-xs'>{renderDate(comment.createdAt)}</div>
        </div>
      </div>
      <div
        className='rounded-md mt-3 border border-gray-200 bg-gray-100 p-3 text-sm'
        dangerouslySetInnerHTML={{ __html: purifyHtml(comment.comment) }}
      />
    </div>
  )
}
