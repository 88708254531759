import {
	addTask,
	deleteTask,
	updateTask,
	updateTaskCompany,
	updateTaskExecutor
} from '../tasks.api'
import {
	and,
	collection,
	onSnapshot,
	orderBy,
	query,
	where
} from 'firebase/firestore'
import {
	setFunnelLoadingStatus,
	setTaskFunnels
} from 'features/funnels/funnels.slice'

import { Errorhandler } from 'lib/sentry'
import type { IFunnel } from 'features/funnels/funnels.interface'
import type { ITask } from '../interafces/task.interface'
/* eslint-disable @typescript-eslint/no-explicit-any */
import Logger from 'lib/logger'
import { firestore } from 'lib/firebase'
import { store } from 'app/store'
import { toast } from 'sonner'

export const onCreateTask = async (task: ITask) => {
	try {
		toast.loading('Creating task...')

		await addTask(task)

		toast.dismiss()
		toast.success('Task created successfully')
	} catch (error: any) {
		Logger.error(error)
		toast.dismiss()
		toast.error(error.message ?? 'Error creating task')

		Errorhandler.captureException(error)
		Logger.error(error)
	}
}

export const onUpdateTask = async (task: ITask) => {
	try {
		toast.loading('Updating task...')

		await updateTask(task)

		toast.dismiss()
		toast.success('Task updated successfully')
	} catch (error: any) {
		toast.dismiss()
		toast.error(error.message ?? 'Error updating task')

		Errorhandler.captureException(error)
		Logger.error(error)
	}
}

export const onUpdateTaskCompany = async (id: string, data: any) => {
	try {
		toast.loading('Updating Company...')

		await updateTaskCompany(id, data)

		toast.dismiss()
		toast.success('Company updated successfully')
	} catch (error: any) {
		toast.dismiss()
		toast.error(error.message ?? 'Error updating task')

		Errorhandler.captureException(error)
		Logger.error(error)
	}
}

export const onUpdateTaskExecutor = async (id: string, data: any) => {
	try {
		toast.loading('Updating Executor...')

		await updateTaskExecutor(id, data)

		toast.dismiss()
		toast.success('Executor updated successfully')
	} catch (error: any) {
		toast.dismiss()
		toast.error(error.message ?? 'Error updating task')

		Errorhandler.captureException(error)
		Logger.error(error)
	}
}

export const onDeleteTask = async (id: string) => {
	try {
		toast.loading('Deleting task...')
		await deleteTask(id)

		toast.dismiss()
		toast.success('Task deleted successfully')
	} catch (error: any) {
		toast.dismiss()
		toast.error(error.message ?? 'Error deleting task')

		Errorhandler.captureException(error)
		Logger.error(error)
	}
}

export const listenToTaskFunnels = (fundId: string) => {
	store.dispatch(setFunnelLoadingStatus(true))

	const queryRef = query(
		collection(firestore, 'task_funnels'),
		and(where('fund.id', '==', fundId), where('isArchived', '==', false)),
		orderBy('index')
	)

	return onSnapshot(queryRef, snapshot => {
		if (snapshot.empty) {
			store.dispatch(setTaskFunnels([]))
		} else {
			const funnels = snapshot.docs.map(document => document.data())
			store.dispatch(setTaskFunnels(funnels as IFunnel[]))
		}

		store.dispatch(setFunnelLoadingStatus(false))
	})
}
