import * as Yup from 'yup';

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import FoldersApi from '../api/folders.api'
import type { FoldersInterface } from '../interface/folders.interface'
import type { ICompany } from 'interfaces'
import { Input } from 'components/ui/input'
import { nanoid } from 'nanoid'
import { toast } from 'sonner'
import { useContext } from 'react'
import { useFormik } from 'formik'

interface CreateComponentProps {
	onClose: () => void
	company: ICompany;
	// getFolders: (parentId: null) => void;
	lastFolder: FoldersInterface
}

export default function CreateFolderComponent({ onClose, company, lastFolder }: CreateComponentProps) {
	// const createService = CreateService()

	const { authUser } = useContext(AuthContext)

	const foldersApi = FoldersApi();

	const formik = useFormik({
		initialValues: {
			name: '',
		},
		validationSchema: Yup.object({
			name: Yup.string()
				.max(20, 'Must be 20 characters or less')
				.required('Folder name is required'),
		}),
		onSubmit: async (values) => {
			if (!authUser) return;

			const data: FoldersInterface = {
				id: nanoid(),
				name: values.name,
				companyId: company.id,
				parentId: lastFolder === undefined ? null : lastFolder.id,
				creator: {
					id: authUser.id,
					name: authUser.name,
					photoUrl: authUser.photoUrl,
				},
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
			}

			try {
				formik.setSubmitting(true);
				await foldersApi.createFolder(data);

				toast.success('Folder created', { description: '' });

				// getFolders(data.parentId == null ? null : lastFolder.id, company.id);

				onClose();
			} catch (error) {
				toast.error(error);
			} finally {
				formik.setSubmitting(false);
				formik.resetForm();
			}
		},
	});

	return (
		<div className="gap-1 w-full bg-white border shadow-md z-50 rounded-md">
			<div className="p-2">
				<div className="pb-3 pl-1">Create Folder</div>
				<form onSubmit={formik.handleSubmit}>
					<div>
						<Input
							error={formik.touched.name && formik.errors.name}
							value={formik.values.name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name="name"
							autoFocus
							size="lg"
							maxLength="20"
							placeholder="Enter folder name"
							className="mt-0"
						/>
					</div>
					<div className="mt-3 flex w-full justify-end gap-3">
						<Button
							onClick={onClose}
							variant="danger">Close
						</Button>
						<Button
							type="submit"
							isLoading={formik.isSubmitting}
							disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}>
							Create
						</Button>
					</div>
				</form>
			</div>
		</div>
	)
}