import { ContactService } from '../services/contact.service'
import { CreateCompanyFromContact } from '../components/CreateCompanyFromContact'
import { CreateLpFromContact } from '../components/CreateLPFromContact'
import type { IContact } from 'interfaces'
import SpokInlineInputEditor from 'components/shadcn/SpokInlineInputEditor'

interface Props {
  contact: IContact
}

export default function ContactDetailsHeader({ contact }: Props) {


  return (
    <div className='flex items-center p-3 border rounded-md bg-white h-fit'>
      <div className='flex items-center'>
        <img
          src={
            contact.website
              ? `https://www.google.com/s2/favicons?sz=128&domain_url=${contact.website}`
              : '../../images/logo/Logo-4.png'
          }
          alt='profile'
          className='h-[6vw] max-h-[3rem] w-[6vw] max-w-[3rem] rounded-full border'
        />
        <div className='ml-5'>
          <SpokInlineInputEditor
            id='contact-name'
            title='Contact Name'
            onSave={value => {
              if (!value) return
              ContactService.updateContact({
                id: contact.id,
                key: 'name',
                value,
                contact
              })
            }}
            defaultValue={contact.name || ''}
            content={
              <div
                className='text-lg font-bold'
              >
                {contact.name}
              </div>
            }
          />

          <SpokInlineInputEditor
            title='Website'
            id='contact-website'
            onSave={value => {
              if (!value) return
              ContactService.updateContact({
                id: contact.id,
                key: 'website',
                value,
                contact
              })
            }}
            defaultValue={contact.website ?? ''}
            content={
              <div>
                {contact.website ? (
                  <div className='text-sm text-blue-700'>{contact.website}</div>
                ) : (
                  <div className='flex items-center gap-1 text-sm text-gray-500'>
                    Add website{' '}
                  </div>
                )}
              </div>
            }
          />
          <SpokInlineInputEditor
            title='Email'
            inputType='email'
            id='contact-email'
            onSave={value => {
              if (!value) return
              ContactService.updateContact({
                id: contact.id,
                key: 'email',
                value,
                contact
              })
            }}
            defaultValue={contact.email ?? ''}
            content={
              <div>
                {contact.email ? (
                  <div className='text-sm text-blue-700'>{contact.email}</div>
                ) : (
                  <div className='flex items-center gap-1 text-sm text-gray-500'>
                    Add email{' '}
                  </div>
                )}
              </div>
            }
          />
        </div>
      </div>
      <div className='flex-1' />
      <div className='flex items-center justify-end px-10 gap-3' >
        <CreateCompanyFromContact contact={contact} />
        <CreateLpFromContact contact={contact} />
      </div>
    </div>
  )
}
