/* eslint-disable unicorn/no-new-array */
/* eslint-disable unicorn/no-useless-spread */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Transition } from '@headlessui/react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Download, X } from 'lucide-react'
import { Fragment, useCallback, useEffect, useState } from 'react'

import type { IFile } from 'features/files/file.interface'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { useResizeObserver } from '@wojtekmaj/react-hooks'

const options = {
	cMapUrl: '/cmaps/',
	standardFontDataUrl: '/standard_fonts/'
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

export default function PDFView({
	file,
	content,
	isDownloadable = true
}: {
	file: IFile
	content: any
	isDownloadable?: boolean
}) {
	const [isOpen, setIsOpen] = useState(false)
	const [numPages, setNumPages] = useState<number>(0)
	const [containerRef, setContainerRef] = useState<HTMLElement | null>(null)
	const [containerWidth, setContainerWidth] = useState<number>()

	const resizeObserverOptions = {}
	const maxWidth = 800

	const onResize = useCallback<ResizeObserverCallback>(entries => {
		const [entry] = entries

		if (entry) {
			setContainerWidth(entry.contentRect.width)
		}
	}, [])

	useResizeObserver(containerRef, resizeObserverOptions, onResize)

	function onDocumentLoadSuccess({
		numPages: nextNumPages
	}: PDFDocumentProxy): void {
		setNumPages(nextNumPages)
	}

	function closeModal() {
		setIsOpen(false)
	}

	// When I press the escape key, close the modal
	useEffect(() => {
		function handleEsc(e: KeyboardEvent) {
			if (e.key === 'Escape') {
				closeModal()
			}
		}

		window.addEventListener('keydown', handleEsc)

		return () => {
			window.removeEventListener('keydown', handleEsc)
		}
	}, [])


	const handleDownload = () => {
		axios.get(file.url, {
			responseType: 'blob',
		}).then((res) => {
			fileDownload(res.data, file.name)
		})
	}
 

	return (
		<>
			<div
				onClick={() => {
					setIsOpen(true)
				}}
			>
				{content}
			</div>

			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={() => { }}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-black bg-opacity-25 ' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<Dialog.Panel className='w-full max-w-[95%] transform overflow-hidden rounded bg-white text-left align-middle shadow-xl transition-all'>
									<Dialog.Title
										as='h3'
										className='flex items-start justify-between border-b px-6 py-3 pb-2 text-lg font-medium leading-6 text-gray-900'
									>
										<div className='flex-1 text-sm'>
											<div className='flex flex-col text-xs text-gray-700'>
												PDF Viewer
											</div>
											{file.name}
										</div>

										<div className='flex gap-4 justify-center items-center'>
											{isDownloadable && (
												<div className='flex items-center justify-center cursor-pointer' onClick={() => {
													handleDownload()
												}}>
													<Download scale={18} />
												</div>
											)}

											<div
												onClick={closeModal}
												className='flex cursor-pointer items-center justify-center rounded-md border p-1'
											>
												<X size={18} />
											</div>
										</div>
									</Dialog.Title>
									<div className='flex min-h-[30rem] items-center justify-center'>
									<div
											className='flex h-[80vh] w-full flex-col items-center overflow-auto'
											ref={setContainerRef}
										>
											<Document
												file={file.url}
												onLoadSuccess={onDocumentLoadSuccess}
												options={options}
											>
												{
													[...new Array(numPages)].map((_, index) => (
														<Page
															key={index}
															pageNumber={index + 1}
															width={
																containerWidth
																	? Math.min(containerWidth, maxWidth)
																	: maxWidth
															}
														/>
													))
												}
											</Document>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}
