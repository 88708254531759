/* eslint-disable unicorn/better-regex */
/* eslint-disable import/no-duplicates */
/* eslint-disable unicorn/prefer-spread */
import sanitizeHtml from 'sanitize-html'
import sanitize from 'sanitize-html'

export function purifyHtml(html: string) {
	const sanitizedHtml = sanitize(html, {
		// allowedTags: sanitize.defaults.allowedTags.concat([
		//   'img', 'table', 'thead', 'tbody', 'tfoot', 'tr', 'th', 'td', 'style'
		// ]),
		allowedAttributes: {
			'*': ['style'],
			img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
			a: ['href', 'name', 'target', 'style'],
			table: ['border', 'cellspacing', 'cellpadding', 'style'],
			th: ['colspan', 'rowspan', 'style'],
			td: ['colspan', 'rowspan', 'style']
		},
		transformTags: {
			a: sanitizeHtml.simpleTransform('a', {
				target: '_blank',
				rel: 'noopener noreferrer'
			})
		},
		allowedStyles: {
			'*': {
				// Allow common inline styles
				color: [
					/^#(0x)?[0-9a-f]+$/i,
					/^rgb\(/,
					/^rgba\(/,
					/^hsl\(/,
					/^hsla\(/,
					/^currentColor$/i
				],
				'background-color': [
					/^#(0x)?[0-9a-f]+$/i,
					/^rgb\(/,
					/^rgba\(/,
					/^hsl\(/,
					/^hsla\(/
				],
				'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/],
				'font-size': [/^\d+(?:px|em|%)$/],
				'font-weight': [/^\d{1,3}$/],
				'text-decoration': [
					/^none$/,
					/^underline$/,
					/^overline$/,
					/^line-through$/
				],
				padding: [/^\d+(?:px|em|%)$/],
				margin: [/^\d+(?:px|em|%)$/],
				border: [/^\d+(?:px|em|%)$/],
				'border-width': [/^\d+(?:px|em|%)$/],
				'border-style': [/^none$/, /^solid$/, /^dashed$/, /^dotted$/],
				'border-color': [
					/^#(0x)?[0-9a-f]+$/i,
					/^rgb\(/,
					/^rgba\(/,
					/^hsl\(/,
					/^hsla\(/
				]
			}
		}
	})

	return sanitizedHtml
}
