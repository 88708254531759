/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useAppSelector } from 'app/hooks'
import { store } from 'app/store'
import Button from 'components/Buttons/Button'
import SpokBadge from 'components/shadcn/SpokBadge'
import SpokDatePicker from 'components/shadcn/SpokDatePicker'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { AuthContext } from 'context/AuthContext'
import dayjs from 'dayjs'
import type { IBankAccount } from 'features/bank_accounts/interface/BankAccount.interface'
import type { ICryptoAccount } from 'features/crypto_accounts/interface/cryptoAccount.interface'
import { listenToLPs } from 'features/limited_partners/api/lps.api'
import { selectLPs } from 'features/limited_partners/redux/lps.slice'
import QuillEditor from 'features/quilleditor/views/QuillEditor'
import {
	CapitalCallTemplateType,
	type ICapitalCallTemplate
} from 'features/settings/CapitalCallTemplates/interface/capital_call_template.interface'
import { selectCapitalCallTemplates } from 'features/settings/CapitalCallTemplates/redux/capital_call_template.slice'
import { CapitalCallTemplateService } from 'features/settings/CapitalCallTemplates/service/CapitalCallTemplate.service'
import { nanoid } from 'nanoid'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { currencyFormatter } from 'utils/currencyformatter.util'
import useCreateEmailEditor from '../hooks/useCreateEmailEditor'
import type {
	CapitalCallRequest,
	TemplateBankAccount,
	TemplateCryptoAccount
} from '../interfaces/CapitalCallRequest'
import {
	selectRecipients,
	selectSetIsNewCapitalCallSaved,
	setIsNewCapitalCallSaved
} from '../redux/newCapitalCall.slice'
import { CapitalCallRequestService } from '../service/capital_call_request.service'

export default function NewCapitalCallAccountForPaymentStep(): JSX.Element {
	const { authUser } = useContext(AuthContext)

	// Get all LPs
	const lps = useAppSelector(selectLPs)

	// Recipients
	const recipients = useAppSelector(selectRecipients)

	// Recipients selected email address
	const recipientsSelectedEmailAddress = useAppSelector(
		state => state.newCapitalCall.recipientsSelectedEmailAddress
	)

	// Recipients payment details
	const recipientsPaymentDetails = useAppSelector(
		state => state.newCapitalCall.recipientsPaymentDetails
	)

	// Recipients capital to be called
	const recipientsCapitalToBeCalled = useAppSelector(
		state => state.newCapitalCall.recipientsCapitalToBeCalled
	)

	// const isRecipientNextStepDisable = useAppSelector(
	// 	selectIsRecipientNextStepDisable
	// )
	// const isEmailAddressesNextStepDisable = useAppSelector(
	// 	selectIsEmailAddressesNextStepDisable
	// )
	// const isPaymentDetailsNextStepDisable = useAppSelector(
	// 	selectIsPaymentDetailsNextStepDisable
	// )
	// const isRecipientsCapitalToBeCalledStepDisable = useAppSelector(
	// 	selectIsRecipientsCapitalToBeCalledStepDisable
	// )
	const isNewCapitalCallSaved = useAppSelector(selectSetIsNewCapitalCallSaved)

	// Capital call templates
	const { data: ccTemplates } = useAppSelector(selectCapitalCallTemplates)

	const [isLoading, setIsLoading] = useState(false)

	const navigate = useNavigate()

	// Listen to lps
	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribe = listenToLPs(authUser.fund.id)
		return () => unsubscribe()
	}, [authUser?.fund.id])

	const { formik } = useCreateEmailEditor()

	async function onClickConfirm() {
		navigate(`/capital-call`)
	}

	async function onSave() {
		try {
			// There should be an authenticated user
			if (!authUser) return

			// Save All Capital Call Requests
			const capitalCallRequests = []

			setIsLoading(true)

			for (const recipient of recipients) {
				// Create an LP data object
				const lpData = {
					id: recipient.id,
					name: recipient.name
				}

				// Get the capital to be called
				const capitalToBeCalled = recipientsCapitalToBeCalled[recipient.id]

				// Use a currency formatter to format the amount
				const formattedAmount = currencyFormatter(
					capitalToBeCalled.currency
				).format(capitalToBeCalled.amount)

				const formattedUSDCAmount = currencyFormatter('USD')
					.format(capitalToBeCalled.amount)
					.replace('US$', 'USDC ')

				// Get the to list of the recipient
				const to = recipientsSelectedEmailAddress[recipient.id]

				// Get the recipient's payment details
				const paymentDetails = recipientsPaymentDetails[recipient.id]

				let bankAccounts: TemplateBankAccount[] = []
				let cryptoAccounts: TemplateCryptoAccount[] = []
				let selectedTemplate: ICapitalCallTemplate | undefined

				if (paymentDetails[0].paymentType === 'bank') {
					bankAccounts = (paymentDetails as IBankAccount[]).map(item => {
						return {
							bankName: item.bankName,
							bankAddress: item.bankAddress,
							accountNumber: item.accountNumber,
							accountName: item.accountName,
							accountAddress: item.accountAddress,
							routingNumber: item.routingNumber,
							swiftCode: item.swiftCode
						}
					})

					// Get the template
					selectedTemplate = ccTemplates.find(
						x => x.type === CapitalCallTemplateType.BankAcccount
					)
				} else {
					cryptoAccounts = (paymentDetails as ICryptoAccount[]).map(item => {
						return {
							walletAddress: item.walletAddress
						}
					})

					selectedTemplate = ccTemplates.find(
						x => x.type === CapitalCallTemplateType.CryptoAccount
					)
				}

				// Get the total amount called
				const lp = lps.find(x => x.id === recipient.id)

				if (!lp) {
					toast.error('Limited partner not found')
					return
				}

				const totalAmountCalled = lp.totalCalled
					? {
							currency: lp.totalCalled.currency,
							amount: lp.totalCalled.amount + capitalToBeCalled.amount
						}
					: {
							currency: capitalToBeCalled.currency,
							amount: capitalToBeCalled.amount
						}

				const formattedAmountCalled = currencyFormatter(
					totalAmountCalled.currency
				).format(totalAmountCalled.amount)

				// Get the percentage of the LP's capital called
				const percentage =
					(capitalToBeCalled.amount / (lp.totalCommitted?.amount || 0)) * 100

				// Get the total percentage of the LP's capital called
				const totalPercentage = (lp?.totalCalledPercentage || 0) + percentage

				if (!selectedTemplate) {
					toast.error('No templates configured')
					setIsLoading(false)
					return
				}

				// Format due date properly
				const date = new Date(formik.values.dueDate)
				const formattedDueDate = dayjs(date).format('DD MMM, YYYY.')

				const data: CapitalCallRequest = {
					id: nanoid(),
					template: {
						id: selectedTemplate.id,
						name: selectedTemplate.name,
						pdfUrl: selectedTemplate.pdfUrl || '',
						thumbnailUrl: selectedTemplate.thumbnailUrl || ''
					},
					templateData: {
						name: recipient.name,
						amount: formattedAmount,
						usdcAmount: formattedUSDCAmount.replace('USDC ', ''),
						formattedUSDCAmount,
						percentCalled: Math.round(percentage),
						totalAmountCalled: formattedAmountCalled,
						totalPercentCalled: totalPercentage,
						date: formattedDueDate,
						bankAccounts,
						cryptoAccounts
					},
					mail: {
						from: formik.values.from,
						to: to || [],
						cc: [],
						subject: formik.values.subject,
						replyTo: formik.values.replyTo,
						previewText: formik.values.previewText,
						body: formik.values.emailContent,
						testMailRecipient: formik.values.testEmailRecipient
					},
					fund: authUser.fund,
					limitedPartner: lpData,
					recipients: to || [],
					percentage,
					amount: capitalToBeCalled,
					amounts: [capitalToBeCalled],
					status: 'Created',
					dueDate: formik.values.dueDate,
					createdAt: new Date().toISOString(),
					lastUpdatedAt: new Date().toISOString(),
					calledBy: {
						id: authUser.id,
						name: authUser.name
					}
				}

				capitalCallRequests.push(data)
			}

			await CapitalCallRequestService.addCapitalCallRequests(
				capitalCallRequests
			)
			setIsLoading(false)

			store.dispatch(setIsNewCapitalCallSaved(true))

			onClickConfirm()
		} catch (error: any) {
			toast.error(error.message || 'An error occurred')
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribe = CapitalCallTemplateService.listenToCapitalCallTemplates(
			{ fundId: authUser.fund.id }
		)
		return () => unsubscribe()
	}, [authUser?.fund.id])

	return (
		<div className='h-100vh mx-auto min-h-[calc(100vh-3rem)] gap-x-60 bg-white p-14 pb-0'>
			{!isNewCapitalCallSaved && (
				<div className='h-100vh mx-auto gap-x-60 rounded-md border border-[#E4E5E8] bg-white p-5 py-9'>
					<div className='mb-6 text-center text-3xl font-semibold'>
						Email Editor
					</div>
					<div className='mb-4 flex items-center gap-4'>
						<div className='flex flex-1'>
							<Label className='mr-3 text-lg font-semibold text-blue-600'>
								From
							</Label>
							<div className='w-full'>
								<Input
									placeholder='Sender'
									value={formik.values.from}
									onChange={value =>
										formik.setFieldValue('from', value.target.value.toString())
									}
								/>
							</div>
						</div>
						<div className='flex flex-1 items-center'>
							<Label className='flex-0 mr-3 text-lg font-semibold text-blue-600'>
								Reply to address
							</Label>
							<div className='flex-1'>
								<Input
									width='100%'
									placeholder='Reply to address'
									value={formik.values.replyTo}
									onChange={value =>
										formik.setFieldValue(
											'replyTo',
											value.target.value.toString()
										)
									}
								/>
							</div>
						</div>
					</div>
					<div className='mt-4 flex'>
						<Label className='mr-3 text-lg font-semibold text-blue-600'>
							To
						</Label>
						<SpokBadge data={formik.values.to} />
					</div>
					<div className='mt-4 flex gap-4'>
						<div className='flex-1'>
							<Label className='text-lg font-semibold text-blue-600'>
								Subject line of email
							</Label>
							<Input
								placeholder='Subject'
								value={formik.values.subject}
								onChange={value =>
									formik.setFieldValue('subject', value.target.value.toString())
								}
							/>
						</div>
						<div className='flex-1'>
							<Label className='text-lg font-semibold text-blue-600'>
								Preview Text
							</Label>
							<Input
								placeholder='Preview Text'
								value={formik.values.previewText}
								onChange={value =>
									formik.setFieldValue(
										'previewText',
										value.target.value.toString()
									)
								}
							/>
						</div>
					</div>
					<div className='my-4 flex gap-2'>
						<Label className='mr-3 text-lg font-semibold text-blue-600'>
							Due Date
						</Label>

						<div>
							<SpokDatePicker
								defaultValue={new Date(formik.values.dueDate)}
								onSelect={date => {
									if (!date) return
									formik.setFieldValue('dueDate', date?.toISOString())
								}}
							/>
						</div>
					</div>
					<div className='mb-8 mt-4'>
						<QuillEditor
							id='note-editor'
							className='h-[20vh]'
							value={formik.values.emailContent || ''}
							onChange={value => {
								formik.setFieldValue('emailContent', value)
							}}
						/>
					</div>
				</div>
			)}

			<div className='flex justify-end gap-4'>
				<Button
					className='mt-5 px-10'
					variant='blue'
					id='continue-step-button'
					isLoading={isLoading}
					disabled={isLoading}
					onClick={() => onSave()}
				>
					Save
				</Button>
			</div>
		</div>
	)
}
