import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface ButtonSliceState {
  selectedButton: string | null;
}

const initialState: ButtonSliceState = {
  selectedButton: null,
};

export const customColumnSlice = createSlice({
  name: 'button',
  initialState,
  reducers: {
    setSelectedButton: (state, action: PayloadAction<string>) => {
      state.selectedButton = action.payload;
    },
  },
});

export const { setSelectedButton } = customColumnSlice.actions;

export const selectSelectedButton = (state) => state.button.selectedButton;

export default customColumnSlice.reducer;
