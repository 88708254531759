import * as Yup from 'yup';

import { AuthContext } from 'context/AuthContext';
import Button from 'components/Buttons/Button';
import FoldersApi from '../api/folders.api';
import type { FoldersInterface } from '../interface/folders.interface';
import FoldersService from '../services/folders.service';
import type { ICompany } from 'interfaces';
import { Input } from 'components/ui/input';
import { toast } from 'sonner';
import { useContext } from 'react';
import { useFormik } from 'formik';

interface EditComponentProps {
	data: FoldersInterface;
	company: ICompany;
	onClose: () => void;
	// getFolders: (parentId: null) => void;
	// lastFolder: FoldersInterface
}

export default function EditComponent({ data, company, onClose }: EditComponentProps) {
	const { authUser } = useContext(AuthContext)
	const foldersApi = FoldersApi();


	const formik = useFormik({
		initialValues: {
			name: data.name,
		},
		validationSchema: Yup.object({
			name: Yup.string()
				.max(20, 'Must be 20 characters or less')
				.required('Folder name is required'),
		}),
		onSubmit: async (values) => {
			if (!authUser) return;

			const folderData = {
				id: data.id,
				name: values.name,
				companyId: company.id,
				parentId: data.parentId || null,
				creator: {
					id: authUser.id,
					name: authUser.name,
					photoUrl: authUser.photoUrl,
				},
				createdAt: data.createdAt, // Use the original createdAt value
				updatedAt: new Date().toISOString(),
			};

			try {
				formik.setSubmitting(true);
				await foldersApi.updateFolder(data.id, folderData);
				toast.success('Folder updated', { description: '' });
				// getFolders(data.parentId == null ? null : lastFolder.id, company.id);
				onClose();
			} catch (error) {
				toast.error('Error updating folder', { description: error.message });
			} finally {
				formik.setSubmitting(false);
				formik.resetForm();
			}
		},
	});

	return (
		<div className="gap-1 w-full">
			<div className="p-2">
				<div className="pb-3 pl-1 mb-3">Edit Folder</div>
				<form onSubmit={formik.handleSubmit}>
					<div>
						<Input
							error={formik.touched.name && formik.errors.name}
							value={formik.values.name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name="name"
							autoFocus
							size="lg"
							maxLength="20"
							placeholder="Enter folder name"
							className="mt-0"
						/>
					</div>
					<div className="mt-10 flex w-full justify-end gap-3">
						<Button
							type="submit"
							isLoading={formik.isSubmitting}
							disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}>
							Update
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
}
