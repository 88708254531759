/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import Skeleton from 'components/Skeleton'
import type { IFunnel } from 'features/funnels/funnels.interface'
import React, { createRef, useRef } from 'react'
import type {
	DraggableProvided,
	DraggableRubric,
	DraggableStateSnapshot,
	DroppableProvided,
	DroppableStateSnapshot
} from 'react-beautiful-dnd'
import { Draggable } from 'react-beautiful-dnd'
import { VariableSizeList as List, areEqual } from 'react-window'
import clsxm from 'utils/clsxm'
import FunnelOptions from '../components/FunnelOptions'
import StrictModeDroppable from './Droppable'

export interface Column {
	id: string
	title: string
	items: any[]
	funnel?: IFunnel
	isArchived?: boolean
}

export function getStyle({
	draggableStyle,
	virtualStyle,
	isDragging
}: {
	draggableStyle: any
	virtualStyle: any
	isDragging: boolean
}) {
	const combined = {
		...virtualStyle,
		...draggableStyle
	}

	const grid = 8

	const result = {
		...combined,
		height: isDragging ? combined.height : combined.height - grid,
		left: isDragging ? combined.left : combined.left + grid,
		width: isDragging
			? draggableStyle.width
			: `calc(${combined.width} - ${grid * 2}px)`
	}

	return result
}

interface RowProps {
	data: {
		items: any[]
		columnId: string
		renderItem?: any
	}
	isScrolling?: boolean
	index: number
	style: any
	renderItem: (props: any) => JSX.Element
}

const Row = React.memo(({ data, index, style }: RowProps) => {
	const item = data.items[index]
	const { renderItem } = data

	if (!item) {
		return null
	}

	return (
		<Draggable
			draggableId={item.id ?? `no-id-${index}`}
			index={index}
			key={item.id}
		>
			{(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
				<div
					key={item.id}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={getStyle({
						draggableStyle: provided.draggableProps.style,
						virtualStyle: style,
						isDragging: !!snapshot.isDragging
					})}
				>
					{renderItem({
						item,
						provided,
						isDragging: snapshot.isDragging,
						index
					})}
				</div>
			)}
		</Draggable>
	)
}, areEqual)

const getRowHeight = (items: any[]) => (index: number) => {
	return 120
}

interface DnDColumnProps {
	column: Column
	columnIndex: number
	renderItem: (props: any) => JSX.Element
	loadingItems: boolean
	allowSpaces?: boolean
	entity: string
	workspaces: string[]
}

function DnDColumn({
	column,
	columnIndex,
	renderItem,
	loadingItems,
	allowSpaces,
	entity,
	workspaces
}: DnDColumnProps) {
	const listRef = createRef<any>()
	const divRef = useRef(null)

	return (
		<Draggable
			index={columnIndex}
			draggableId={column.id ?? column.title ?? `no-id-${columnIndex}`}
		>
			{provided => (
				<div
					id={column.title}
					className={clsxm('flex h-full min-w-[350px] flex-col rounded-md', {
						'border bg-gray-100': !loadingItems,
						'border border-white bg-white': loadingItems
					})}
					ref={provided.innerRef}
					{...provided.dragHandleProps}
					{...provided.draggableProps}
				>
					<div className='sticky top-0 z-10 rounded-md pl-2'>
						<div className='flex w-full items-center justify-between rounded-xl  px-2 py-2 pb-2 text-base font-semibold '>
							<div className='flex items-center gap-2 text-sm capitalize'>
								<div
									className={`h-3 w-3 rounded-full bg-${
										column.funnel?.color || 'blue'
									}-500`}
								/>
								{column.title}
								<span className='text-xs text-gray-700'>
									{column.items.length}
								</span>
							</div>

							<div className='flex-1' />
							<FunnelOptions
								workspaces={workspaces}
								entity={entity}
								allowSpaces={allowSpaces}
								column={column}
							/>
						</div>
					</div>

					<div className='relative z-0 flex-1 '>
						<StrictModeDroppable
							droppableId={column.id}
							mode='virtual'
							renderClone={(
								provided: DraggableProvided,
								snapshot: DraggableStateSnapshot,
								rubric: DraggableRubric
							) => (
								<div
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
								>
									{renderItem({
										item: column.items[rubric.source.index],
										provided,
										isDragging: snapshot.isDragging,
										index: rubric.source.index
									})}
								</div>
							)}
						>
							{(
								droppableProvided: DroppableProvided,
								snapshot: DroppableStateSnapshot
							) => {
								return (
									<div
										id='droppable'
										className={clsxm('h-full')}
										ref={divRef}
										{...droppableProvided.droppableProps}
									>
										{loadingItems ? (
											<div className='flex h-full w-full animate-pulse flex-col gap-10 rounded-md bg-gray-200 px-5 pt-10'>
												{Array.from({ length: 5 }).map((_, index) => (
													<div
														key={index}
														className='flex w-full gap-2 rounded-md bg-gray-200'
													>
														<Skeleton className='h-14 w-14 rounded-full bg-white' />
														<div className='flex w-full flex-1 flex-col gap-2'>
															<Skeleton className='h-4 w-full rounded-md bg-white' />
															<Skeleton className='h-4 w-5/6 rounded-md bg-white' />
															<Skeleton className='h-4 w-5/6 rounded-md bg-white' />
														</div>
													</div>
												))}
											</div>
										) : (
											<div ref={divRef}>
												<List
													height={window.innerHeight - 175}
													itemCount={
														snapshot.isUsingPlaceholder
															? column.items.length + 1
															: column.items.length
													}
													itemSize={getRowHeight(column.items)}
													width={350}
													outerRef={droppableProvided.innerRef}
													itemData={{
														columnId: column.id,
														items: column.items.sort(
															(a, b) => (a.index ?? 0) - (b.index ?? 0)
														),
														renderItem
													}}
													ref={listRef}
												>
													{Row}
												</List>
											</div>
										)}
									</div>
								)
							}}
						</StrictModeDroppable>
					</div>
				</div>
			)}
		</Draggable>
	)
}

export default DnDColumn
