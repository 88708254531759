/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { ICalendarEvent } from 'features/companies/interfaces/calendarEvent.interface'
import type { INote } from 'features/notes/interfaces/notes.interface'

import type { ISharedLinkCompanyComment } from 'features/sharedlinks/interfaces/sharedlink.interface'
import type { ICompanyUpdate } from 'features/updates/interfaces/company_update.interface'

export interface DashboardState {
  notes: {
    data: INote[]
    loading: boolean
    error?: string | null
  },
  comments: {
    data: ISharedLinkCompanyComment[]
    loading: boolean
    error?: string | null
  },
  updates: {
    data: ICompanyUpdate[]
    loading: boolean
    error?: string | null
  },
  meetings: {
    data: ICalendarEvent[]
    loading: boolean
    error?: string | null

  }
}

const initialState: DashboardState = {
  notes: {
    data: [],
    loading: true,
    error: null
  },
  comments: {
    data: [],
    loading: true,
    error: null
  },
  updates: {
    data: [],
    loading: true,
    error: null
  },
  meetings: {
    data: [],
    loading: true,
    error: null
  }
}

export const dashboardHomeSlice = createAppSlice({
  name: 'dashboardHome',
  initialState,
  reducers: create => ({
    setNotes: create.reducer((state, action: PayloadAction<INote[]>) => {
      state.notes.data = action.payload
      state.notes.loading = false
    }),
    setNotesIsLoading: create.reducer((state, action: PayloadAction<boolean>) => {
      state.notes.loading = action.payload
    }),
    setNotesError: create.reducer((state, action: PayloadAction<string | null>) => {
      state.notes.error = action.payload
      state.notes.loading = false
    }),
    setComments: create.reducer((state, action: PayloadAction<ISharedLinkCompanyComment[]>) => {
      state.comments.data = action.payload
      state.comments.loading = false
    }),
    setCommentsIsLoading: create.reducer((state, action: PayloadAction<boolean>) => {
      state.comments.loading = action.payload
    }),
    setCommentsError: create.reducer((state, action: PayloadAction<string | null>) => {
      state.comments.error = action.payload
      state.comments.loading = false
    }),
    setUpdates: create.reducer((state, action: PayloadAction<ICompanyUpdate[]>) => {
      state.updates.data = action.payload
      state.updates.loading = false
    }),
    setUpdatesIsLoading: create.reducer((state, action: PayloadAction<boolean>) => {
      state.updates.loading = action.payload
    }),
    setUpdatesError: create.reducer((state, action: PayloadAction<string | null>) => {
      state.updates.error = action.payload
      state.updates.loading = false
    }),
    setMeetings: create.reducer((state, action: PayloadAction<ICalendarEvent[]>) => {
      state.meetings.data = action.payload
      state.meetings.loading = false
    }),
    setMeetingsIsLoading: create.reducer((state, action: PayloadAction<boolean>) => {
      state.meetings.loading = action.payload
    }),
    setMeetingsError: create.reducer((state, action: PayloadAction<string | null>) => {
      state.meetings.error = action.payload
      state.meetings.loading = false
    })
  }),
  selectors: {
    selectNotes: state => state.notes?.data || [],
    selectNoteStatus: state => state.notes?.loading || false,
    selectComments: state => state.comments?.data || [],
    selectCommentStatus: state => state.comments?.loading || false,
    selectUpdates: state => state.updates?.data || [],
    selectUpdateStatus: state => state.updates?.loading || false,
    selectMeetings: state => state.meetings?.data || [],
    selectMeetingStatus: state => state.meetings?.loading || false,
    selectMeetingsError: state => state.meetings?.error || null
  }
})

export const {
  setNotes,
  setNotesIsLoading,
  setNotesError,
  setComments,
  setCommentsIsLoading,
  setCommentsError,
  setUpdates,
  setUpdatesIsLoading,
  setUpdatesError,
  setMeetings,
  setMeetingsIsLoading,
  setMeetingsError
} = dashboardHomeSlice.actions

export const {
  selectNotes,
  selectNoteStatus,
  selectComments,
  selectCommentStatus,
  selectUpdates,
  selectUpdateStatus,
  selectMeetings,
  selectMeetingStatus,
  selectMeetingsError
} = dashboardHomeSlice.selectors
