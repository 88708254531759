/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx'
import Button from 'components/Buttons/Button'
import SpokInlineInputEditor from 'components/shadcn/SpokInlineInputEditor'
import { AuthContext } from 'context/AuthContext'
import { FileService } from 'features/files/services/file.service'
import {
	setMailWindowState,
	setTo
} from 'features/mail/redux/mailcompose.slice'
import { UserRole, type ILimitedPartner } from 'interfaces'
import { Mail } from 'lucide-react'
import { useContext, useRef, useState } from 'react'
import { ImSpinner10 } from 'react-icons/im'
import { LPService } from '../service/lp.service'
import { useAppDispatch } from 'app/hooks'
import Logger from 'lib/logger'

interface Props {
	lp: ILimitedPartner
}

export default function LimitedPartnerDetailsHeader({ lp }: Props) {
	const fileInputRef = useRef<any>(null)
	const [profilePhoto, setProfilePhoto] = useState(lp.photoUrl)
	const [uploadingProfilePhoto, setUploadingProfilePhoto] = useState(false)

	const { authUser } = useContext(AuthContext)
	const dispatch = useAppDispatch()

	return (
		<div className='flex h-fit items-center rounded-md border bg-white p-3'>
			<div className='flex items-center'>
				<div className='relative'>
					<img
						onClick={() => {
							fileInputRef.current?.click()
						}}
						src={profilePhoto || '../../images/logo/Logo-4.png'}
						alt='profile'
						className='h-[6vw] max-h-[3rem] w-[6vw] max-w-[3rem] cursor-pointer rounded-full border object-cover'
					/>
					<ImSpinner10
						className={clsx(
							'absolute bottom-0 right-0 animate-spin rounded-full bg-white transition-all',
							uploadingProfilePhoto ? 'opacity-100' : 'opacity-0'
						)}
					/>
				</div>

				<input
					type='file'
					ref={fileInputRef}
					style={{ display: 'none' }}
					onChange={async e => {
						try {
							setUploadingProfilePhoto(true)
							const file = e.target.files?.[0]
							if (!file) return
							const reader = new FileReader()
							reader.addEventListener('load', () => {
								setProfilePhoto(reader.result as string)
							})
							reader.readAsDataURL(file)

							// Upload profile photo
							const results = await FileService.uploadFiles(
								[file],
								`funds/${lp.fund.id}/personal_documents/profilePhotoImage`
							)
							LPService.updateLP({
								id: lp.id,
								key: 'photoUrl',
								value: results[0].url,
								lp,
								activityDescription: 'Updated profile photo',
								activityKey: 'updated profile photo'
							})
						} catch (error) {
							Logger.error(error)
						} finally {
							setUploadingProfilePhoto(false)
						}
					}}
				/>
				<div className='ml-5'>
					<SpokInlineInputEditor
						id='lp-name'
						title='Limited Partner Name'
						onSave={value => {
							LPService.updateLP({
								id: lp.id,
								key: 'name',
								value: value || '',
								lp
							})
						}}
						defaultValue={lp.name || ''}
						content={<div className='text-lg font-bold'>{lp.name}</div>}
					/>

					{authUser?.role === UserRole.GeneralPartner && (
						<SpokInlineInputEditor
							title='Email'
							id='lp-email'
							onSave={value => {
								if (!value) return
								LPService.updateLP({
									id: lp.id,
									key: 'email',
									value,
									lp
								})
							}}
							defaultValue={lp.email ?? ''}
							content={
								<div>
									{lp.email ? (
										<div className='text-sm text-blue-700'>{lp.email}</div>
									) : (
										<div className='flex items-center gap-1 text-sm text-gray-500'>
											Add email{' '}
										</div>
									)}
								</div>
							}
						/>
					)}
				</div>
			</div>

			<div className='ml-5 line-clamp-3 flex-1 text-sm'>{lp.description}</div>
			{authUser?.role === UserRole.GeneralPartner && (
				<div className='flex items-center justify-end gap-3 px-10'>
					<Button
						id='open-mail-lp-button'
						className='h-10 w-10 rounded-md'
						variant='blue'
						onClick={() => {
							if (lp.email) dispatch(setTo([lp.email]))
							dispatch(setMailWindowState('restore'))
						}}
					>
						<Mail size={20} />
					</Button>
				</div>
			)}
		</div>
	)
}
