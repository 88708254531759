/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { buttonVariants } from 'components/ui/button'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { cn } from 'lib/utils'
import type { LucideIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

interface NavProps {
	isCollapsed: boolean
	selectedFilter: string
	links: {
		title: string
		label?: string
		icon: LucideIcon
		variant: 'default' | 'ghost'
		labelFilter?: string
		selectedFilter?: string
	}[]
}

export function Nav({ links, isCollapsed, selectedFilter }: NavProps) {
	const navigate = useNavigate()
	function onFilterUpdate(filter: string) {
		navigate(`/mail?label=${filter}`)
	}

	return (
		<div
			data-collapsed={isCollapsed}
			className='group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2'
		>
			<nav className='grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2'>
				{links.map(link =>
					isCollapsed ? (
						<Tooltip key={link.labelFilter} delayDuration={0}>
							<TooltipTrigger asChild>
								<div
									onClick={() => {
										onFilterUpdate(link.labelFilter || '')
									}}
									className={cn(
										buttonVariants({ variant: link.variant, size: 'icon' }),
										'h-9 w-9',
										link.variant === 'default' &&
											'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
									)}
								>
									<link.icon className='h-4 w-4' />
									<span className='sr-only'>{link.title}</span>
								</div>
							</TooltipTrigger>
							<TooltipContent
								onClick={() => {
									onFilterUpdate(link.labelFilter || '')
								}}
								side='right'
								className='flex items-center gap-4'
							>
								{link.title}
								{link.label && (
									<span className='text-muted-foreground ml-auto'>
										{link.label}
									</span>
								)}
							</TooltipContent>
						</Tooltip>
					) : (
						<div
							key={link.labelFilter}
							onClick={() => {
								onFilterUpdate(link.labelFilter || '')
							}}
							className={cn(
								buttonVariants({ variant: link.variant, size: 'sm' }),
								link.variant === 'default' &&
									'dark:bg-muted dark:hover:bg-muted cursor-pointer dark:text-white dark:hover:text-white',
								'cursor-pointer justify-start'
							)}
						>
							<link.icon
								color={link.labelFilter === selectedFilter ? 'white' : 'black'}
								className='mr-2 h-4 w-4 text-black'
							/>
							{link.title}
							{link.label && (
								<span
									className={cn(
										'ml-auto',
										link.variant === 'default' &&
											'text-background dark:text-white'
									)}
								>
									{link.label}
								</span>
							)}
						</div>
					)
				)}
			</nav>
		</div>
	)
}
