import { store } from 'app/store'
import {
	setProspects,
	setProspectsError,
	setProspectsLoading
} from 'features/prospects/prospects.slice'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import Errorhandler from 'lib/sentry'
import type { IProspect } from './interfaces/prospect.interface'

const prospectsRef = collection(firestore, 'lps')

export const listenToProspects = (fundId: string) => {
	store.dispatch(setProspectsLoading(true))
	const q = query(
		prospectsRef,
		where('fund.id', '==', fundId),
		where('isApproved', '==', false)
	)
	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const prospects = querySnapshot.docs.map(d => d.data() as IProspect)
			store.dispatch(setProspects(prospects))
		},
		error => {
			store.dispatch(setProspectsError(error.message))

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	)

	return unsubscribe
}
