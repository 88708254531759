/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from 'components/Buttons/Button'
import useTabs from 'components/hooks/useTabs'
import { Input } from 'components/ui/input'
import type { IActivity } from 'features/activity/interfaces/actvitiy.interface'
import { ActivityService } from 'features/activity/service/activity.service'
import type { ISharedLink } from 'features/companies/interfaces/sharedLink.interface'
import { useFormik } from 'formik'
import type { ICompany } from 'interfaces'
import { MessageCirclePlus } from 'lucide-react'
import { nanoid } from 'nanoid'
import { toast } from 'sonner'
import * as Yup from 'yup'
import type { ISharedLinkCompanyComment } from '../interfaces/sharedlink.interface'
import { SharedLinksService } from '../services/sharedlinks.service'

interface Props {
  linkData: ISharedLink
  company: ICompany
  currentUserEmail: string
}

const tabs = [
  {
    name: 'Add comment',
    value: 'add-comment',
    icon: <MessageCirclePlus />,
    disabled: false
  }
]

export default function SharedLinkNewCompanyNote({
  linkData,
  company,
  currentUserEmail
}: Props) {
  const { Tabs } = useTabs({ tabs })

  const formik = useFormik({
    initialValues: {
      comment: '',
      email: currentUserEmail
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Comment is required')
    }),
    onSubmit: async values => {
      try {
        formik.setSubmitting(true)

        const comment: ISharedLinkCompanyComment = {
          id: nanoid(),
          comment: values.comment,
          user: {
            email: values.email
          },
          linkData,
          company: {
            id: company.id,
            name: company.name,
            website: company.website
          },
          createdAt: new Date().toISOString(),
          type: 'slink-company-comment',
          isPinned: false,
          lastUpdatedAt: new Date().toISOString()
        }

        await SharedLinksService.addSharedLinkCompanyComment(comment)

        const activity: IActivity = {
          id: nanoid(),
          user: {
            id: '',
            name: values.email
          },
          fund: linkData.fund,
          activity: `added a new comment`,
          description: values.comment.length > 250 ? `${values.comment.slice(0, 250)}...` : values.comment,
          icon: '',
          summary: '',
          source: 'slink_comment',
          metadata: values,
          company: {
            id: company.id,
            name: company.name
          },
          createdAt: new Date().toISOString()
        }

        ActivityService.add(activity)

        toast.success('Comment added successfully')
      } catch (error: any) {
        toast.error(error.message)
      } finally {
        formik.setSubmitting(false)
        formik.resetForm()
      }
    }
  })

  return (
    <div className='rounded-md bg-white'>
      <Tabs />
      <div className='p-2'>
        <Input
          id='shared-link-comment'
          name='comment'
          isMultiline
          value={formik.values.comment}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.comment && formik.errors.comment}
          placeholder='Add a comment'
          className=''
        />
        <Button
          id='add-comment-button'
          isLoading={formik.isSubmitting}
          onClick={() => {
            formik.handleSubmit()
          }}
          disabled={
            formik.isSubmitting || !formik.dirty || !!formik.errors.comment
          }
          className='mt-4 w-fit'
        >
          Add comment
        </Button>
      </div>
    </div>
  )
}
