import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import type { IStackBarChartData } from "../interfaces/reports.interface";
import { displayAmount } from "utils/currency";
import { nanoid } from "nanoid";
import { selectFundCurrency } from "features/authentication/authentication.slice";
import { useAppSelector } from "app/hooks";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customizedTooltip = (props: any, label: string[], fundCurrency: string) => {
  return (
    <div style={{margin: 0, padding: 10, backgroundColor: "rgb(255, 255, 255)", border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap"}}>
      <p>
        {  props?.payload.length > 0 && props?.payload[0]?.payload.label }
      </p>
      <div className="recharts-tooltip-item-list mt-2">
          { 
            props?.payload.length > 0 && props?.payload[0].payload?.data?.map((item: number, index: number) => (
              <div key={nanoid()} className="flex">
                <span className="mr-3" style={{color: props?.payload[index].color}}>
                  {label[index]} : 
                </span>
                <span className="recharts-tooltip-item">
                  {displayAmount({amount: item, currency: fundCurrency})}
                </span>
              </div>
            ))
          } 
      </div>
    </div>
  );
};


interface ISpokReportsStackedBarChart {
  width?: number | string
  height?: number | string
  data: IStackBarChartData
}

export default function SpokReportsStackedBarChart(
  {
    height = 340,
    width = "100%",
    data
  }: ISpokReportsStackedBarChart
) {
  const fundCurrency = useAppSelector(selectFundCurrency);

  return (
    <div style={{width, height}}>
      <ResponsiveContainer>
        <BarChart data={data.datasets}           
          margin={{
            top: 30,
            right: 0,
            left: 20,
            bottom: 0
          }}
        >
          <XAxis dataKey="label" tick={{fontSize: 14}} />
          <YAxis tick={{fontSize: 14}} />
          <Tooltip content={(value) => customizedTooltip(value, data.label, fundCurrency)} />
          {data.datasets.map((_, index) => (
            <Bar
              key={nanoid()}
              dataKey={`data[${index}]`}
              stackId="stack"
              fill={data.colors[index]} 
              label={data.label[index]}
              barSize={20}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>

  );
}
