/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/max-params */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable jsx-a11y/control-has-associated-label */
import clsxm from 'utils/clsxm'
import {
	DEFAULT_COMPANY_FORM_TEMPLATE,
	DEFAULT_TEMPLATE_ID
} from '../data/default-template.data'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import type { ChangeEvent } from 'react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import {
	ECustomCompanyFormFieldType,
	ECustomFormTemplateStatus
} from 'features/companies/interfaces/company.interface'
import type {
	ICustomCompanyFormField,
	ICustomCompanyFormTemplate
} from 'features/companies/interfaces/company.interface'
import { Field, Label, Select, Switch } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Input } from 'components/ui/input'
import { PlusIcon } from '@radix-ui/react-icons'
import Button from 'components/Buttons/Button'
import { nanoid } from 'nanoid'
import { CheckCircle, Edit, EyeIcon, Share2, TrashIcon, X } from 'lucide-react'
import {
	collection,
	doc,
	onSnapshot,
	orderBy,
	query,
	serverTimestamp,
	setDoc,
	updateDoc,
	where
} from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import { toast } from 'sonner'
import { isArray } from 'lodash'
import { motion } from 'framer-motion'
import SideDrawer from 'components/SideDrawer'
import type { ICustomFieldUpdate } from '../interfaces/customField'
import { toCamelCase } from 'lib/utils'
import Skeleton from 'components/Skeleton'
import { useSelector } from 'react-redux'
import {
	getAuthFund,
	getAuthUserFund
} from 'features/authentication/authentication.selector'
import AddCustomFieldModal from '../components/AddCustomFieldModal'
import PopConfirm from 'components/PopConfirm'
import { getDefaultTemplate } from '../helpers/defaultTemplate.helper'
import type { IOptionChangeEvent } from '../components/OptionListCreator'
import OptionListCreator from '../components/OptionListCreator'
import Logger from 'lib/logger'

function CompanyFormSettings() {
	const navigate = useNavigate()
	const { search, pathname } = useLocation()
	const urlQuery = new URLSearchParams(search)

	const [newTemplateName, setNewTemplateName] = useState('')
	const [showTemplateModal, setShowTemplateModal] = useState(false)

	const authUserFund = useSelector(getAuthUserFund)
	const authFund = useSelector(getAuthFund)
	// const [isPublishingTemplate, setIsPublishingTemplate] = useState('')

	const [loadingTemplates, setLoadingTemplates] = useState(true)

	const [fieldUpdate, setFieldUpdate] = useState<ICustomFieldUpdate>({
		field: '',
		fieldIndex: 0,
		fundId: authUserFund?.id ?? '',
		label: '',
		isRequired: false,
		id: nanoid(),
		sectionIndex: 0
	})

	const [showNewCustomFieldModal, setShowNewCustomFieldModal] = useState(false)
	const [newCustomFieldDataIndex, setNewCustomFieldDataIndex] = useState(-1)

	const [updatingCustomField, setUpdatingCustomField] = useState(false)

	const [isSavingTemplate, setIsSavingTemplate] = useState(false)
	const [showCustomFieldDrawer, setShowCustomFieldDrawer] = useState(false)
	const [isCreatingNewTemplate, setIsCreatingNewTemplate] = useState(false)

	const [templates, setTemplates] = useState<ICustomCompanyFormTemplate[]>([])

	const [optionChangeEvent, setOptionChangeEvent] =
		useState<IOptionChangeEvent>()

	const selectedTemplateData = useMemo(() => {
		const templateId = urlQuery.get('template')

		if (!templateId) {
			return {
				index: -1,
				template: null
			}
		}

		const foundIndex = templates.findIndex(
			template => template.id === templateId
		)

		if (foundIndex < 0) {
			return {
				index: -1,
				template: null
			}
		}

		return {
			index: foundIndex,
			template: templates[foundIndex]
		}
	}, [templates, urlQuery])

	useEffect(() => {
		setLoadingTemplates(true)
		const collectionQuery = query(
			collection(firestore, `company_form_templates`),
			where('fund.id', '==', authFund?.id),
			orderBy('createdAt', 'desc')
		)

		const unsubscribeTemplates = onSnapshot(collectionQuery, snapshot => {
			const templateDocs: ICustomCompanyFormTemplate[] = []

			for (const document of snapshot.docs) {
				templateDocs.push(document.data() as ICustomCompanyFormTemplate)
			}

			setLoadingTemplates(false)

			if (templateDocs.length === 0 && authFund) {
				setTemplates([
					getDefaultTemplate(nanoid(), {
						id: authFund.id,
						name: authFund.name,
						slug: authFund.slug,
						website: authFund.website
					})
				])
			} else {
				setTemplates(templateDocs)
			}
		})

		return () => {
			unsubscribeTemplates()
		}
	}, [])

	function toggleNewFieldModal(sectionIndex: number, open: boolean) {
		setNewCustomFieldDataIndex(sectionIndex)
		setShowNewCustomFieldModal(open)
	}

	function selectTemplate(templateId: string) {
		return () => {
			navigate(
				{
					pathname,
					search: `tab=${urlQuery.get('tab')}&template=${templateId}`
				},
				{ replace: true }
			)
		}
	}

	function addNewSection() {
		const templateId = urlQuery.get('template') ?? DEFAULT_TEMPLATE_ID
		if (!templateId) return

		const foundIndex = templates.findIndex(
			template => template.id === templateId
		)
		if (foundIndex < 0) return

		const templatesCopy = structuredClone(templates)
		const sectionId = nanoid()
		templatesCopy[foundIndex].sections.push({
			id: sectionId,
			fields: [],
			name: '',
			index: templatesCopy[foundIndex].sections.length,
			description: '',
			isSystem: false
		})

		setTemplates(templatesCopy)
	}

	function onSectionHeadingChangeHandler(sectionIndex: number) {
		return (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const templatesCopy = structuredClone(templates)
			templatesCopy[selectedTemplateData.index].sections[sectionIndex].name =
				evt.target.value

			setTemplates(templatesCopy)
		}
	}

	function onSubSectionHeadingChangeHandler(sectionIndex: number) {
		return (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const templatesCopy = structuredClone(templates)
			templatesCopy[selectedTemplateData.index].sections[
				sectionIndex
			].description = evt.target.value

			setTemplates(templatesCopy)
		}
	}

	async function createNewTemplate() {
		if (newTemplateName.trim().length === 0) {
			toast.error('Name is required')
			return
		}

		if (newTemplateName.trim().length <= 2) {
			toast.error('Enter a descriptive name')
			return
		}

		if (!authFund?.id) {
			Logger.error('Fund id is required')
			return
		}

		setIsCreatingNewTemplate(true)
		const id = nanoid()
		const newTemplate: ICustomCompanyFormTemplate = {
			id,
			name: newTemplateName,
			fund: {
				id: authFund.id,
				name: authFund.name,
				slug: authFund.slug,
				website: authFund.website
			},
			sections: [
				DEFAULT_COMPANY_FORM_TEMPLATE.sections[0],
				{
					id: nanoid(),
					description: '',
					fields: [],
					index: templates.length,
					fundId: authUserFund?.id,
					isSystem: false,
					name: ''
				}
			],
			status: ECustomFormTemplateStatus.Published,
			createdAt: serverTimestamp(),
			updatedAt: serverTimestamp()
		}

		const document = doc(firestore, `company_form_templates/${id}`)

		try {
			await setDoc(
				document,
				{ ...newTemplate, fund: authUserFund },
				{ merge: true }
			)
			toast.success(
				<>
					<strong>{newTemplate.name}</strong> created successfully
				</>
			)
		} catch {
			toast.error('Failed to create new template')
		} finally {
			setShowTemplateModal(false)
			setIsCreatingNewTemplate(false)
		}

		setNewTemplateName('')
	}

	const formSections = useMemo(() => {
		const matchingTemplate = templates.find(
			template =>
				template.id === (urlQuery.get('template') ?? DEFAULT_TEMPLATE_ID)
		)
		if (!matchingTemplate) return DEFAULT_COMPANY_FORM_TEMPLATE.sections

		return matchingTemplate.sections
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templates, urlQuery])

	async function saveTemplate() {
		setIsSavingTemplate(true)
		const sections = selectedTemplateData.template?.sections
		if (!sections) return

		let fields = []
		for (const section of sections) {
			for (const field of section.fields) {
				fields.push({ ...field, fundId: authUserFund?.id })
			}

			section.fields = fields as ICustomCompanyFormField[]
			fields = []
		}

		const template: ICustomCompanyFormTemplate = {
			...selectedTemplateData.template,
			sections,
			status: ECustomFormTemplateStatus.Published
		} as ICustomCompanyFormTemplate

		const newTemplateDoc = doc(
			firestore,
			`company_form_templates/${
				selectedTemplateData.template?.id ?? 'no-template-id'
			}`
		)
		await setDoc(newTemplateDoc, template, { merge: true })
		setIsSavingTemplate(false)
		toast.success('Template saved')
	}

	async function removeSectionAtIndex(sectionIndex: number) {
		const loadingToastId = toast.loading('Deleting section...')
		const templatesCopy = structuredClone(templates)
		templatesCopy[selectedTemplateData.index].sections.splice(sectionIndex, 1)
		const templateDoc = doc(
			firestore,
			`company_form_templates/${selectedTemplateData.template?.id}`
		)

		try {
			await setDoc(templateDoc, templatesCopy[selectedTemplateData.index])
			toast.success('Section deleted')
		} catch (error) {
			Logger.error(error)
			toast.error('Failed to delete section')
		} finally {
			toast.dismiss(loadingToastId)
		}

		setTemplates(templatesCopy)
	}

	async function removeFieldAtIndex(sectionIndex: number, fieldIdx: number) {
		const loadingToastId = toast.loading('Deleting field...')
		const { template, index: templateIndex } = selectedTemplateData
		if (template == null) {
			Logger.error('Template is null')
			return
		}

		template.sections[sectionIndex].fields.splice(fieldIdx, 1)

		const templateDoc = doc(firestore, `company_form_templates/${template.id}`)

		try {
			await updateDoc(templateDoc, { sections: template.sections })

			toast.success('Field deleted')
		} catch (error) {
			Logger.error(error)
			toast.error('Failed to delete field')
		} finally {
			toast.dismiss(loadingToastId)
		}

		const templatesCopy = structuredClone(templates)
		templatesCopy[templateIndex].sections = template.sections
		setTemplates(templatesCopy)
	}

	function editCustomField(
		sectionIndex: number,
		fieldIndex: number,
		field: ICustomCompanyFormField
	) {
		setFieldUpdate({ ...field, sectionIndex, fieldIndex })
		setTimeout(() => {
			setShowCustomFieldDrawer(true)
		}, 50)
	}

	async function handleCustomFieldUpdate() {
		setShowCustomFieldDrawer(false)

		setUpdatingCustomField(true)
		const loadingToastId = toast.loading('Updating field')

		if (fieldUpdate.label.trim().length === 0) {
			toast.error('Enter field name')
			return
		}

		if (selectedTemplateData.template === null) {
			Logger.error('Template not found')
			return
		}

		if (
			fieldUpdate.type === ECustomCompanyFormFieldType.Dropdown &&
			optionChangeEvent?.hasDuplicates
		) {
			toast.error('Remove duplicate options')
			return
		}

		if (
			fieldUpdate.sectionIndex === undefined ||
			fieldUpdate.sectionIndex < 0 ||
			fieldUpdate.fieldIndex === undefined ||
			fieldUpdate.fieldIndex < 0
		) {
			Logger.error('Field and section indices are missing')
			return
		}

		const indices = {
			section: fieldUpdate.sectionIndex,
			field: fieldUpdate.fieldIndex
		}

		delete fieldUpdate.sectionIndex
		delete fieldUpdate.fieldIndex

		const sectionsUpdates = selectedTemplateData.template.sections
		sectionsUpdates[indices.section].fields[indices.field] = fieldUpdate

		const templateDoc = doc(
			firestore,
			`company_form_templates/${selectedTemplateData.template.id}`
		)
		await setDoc(templateDoc, { sections: sectionsUpdates }, { merge: true })
		toast.dismiss(loadingToastId)
		toast.success('Field updated')
		setShowCustomFieldDrawer(false)
		setUpdatingCustomField(false)
		optionChangeEvent?.clear()
	}

	function handleCustomFieldUpdateChange(
		field: keyof ICustomFieldUpdate,
		value: unknown
	) {
		const update = structuredClone(fieldUpdate)
		update.label = value as never

		if (field === 'label') {
			update.field = toCamelCase(value as string)
		}

		setFieldUpdate(prev => ({ ...prev, [field]: value }))
	}

	// async function makeDefault(template: ICustomCompanyFormTemplate) {
	// 	setIsPublishingTemplate(template.id)
	// 	const templateDoc = doc(firestore, `company_form_templates/${template.id}`)

	// 	const fundDoc = doc(firestore, `funds/${authUserFund?.id}`)

	// 	try {
	// 		await setDoc(
	// 			templateDoc,
	// 			{
	// 				...template,
	// 				status: ECustomFormTemplateStatus.Published,
	// 				fund: authUserFund
	// 			},
	// 			{ merge: true }
	// 		)

	// 		await setDoc(
	// 			fundDoc,
	// 			{ companyFormTemplateId: template.id },
	// 			{ merge: true }
	// 		)
	// 		toast.success('Template published')
	// 	} catch {
	// 		toast.error('Failed to publish template')
	// 	} finally {
	// 		setIsPublishingTemplate('')
	// 	}
	// }

	function shareTemplate(templateId: string) {
		/**
		 * _f is non-readable  representation of fund, which holds fundId search query
		 * _t is non-readable  representation of template, which holds templateId search query
		 */
		navigator.clipboard.writeText(
			`${window.origin}/create-company?_f=${authFund?.id}&_t=${templateId}`
		)
		toast.success('Link copied')
	}

	if (loadingTemplates && !urlQuery.get('template')) {
		return (
			<div className='mx-auto mt-10 max-w-5xl'>
				<div className='mb-6 flex justify-between'>
					<Skeleton className=' h-10 w-[120px]' />
					<Skeleton className=' h-10 w-[120px]' />
				</div>
				<div className='flex flex-col gap-1'>
					<Skeleton className=' h-10 w-full' />
					<Skeleton className=' h-10 w-full' />
					<Skeleton className=' h-10 w-full' />
				</div>
			</div>
		)
	}

	if (loadingTemplates && urlQuery.get('template')) {
		return (
			<div className='mx-auto mt-24 flex max-w-5xl flex-col'>
				<Skeleton className=' h-[350px] w-full' />
				<Skeleton className='mt-8 h-[350px] w-full' />
			</div>
		)
	}

	if (
		!urlQuery.get('template') ||
		(urlQuery.get('template') && selectedTemplateData.index === -1)
	) {
		return (
			<>
				<div className={clsxm('mx-4 mt-10 max-w-5xl bg-white xl:mx-auto')}>
					<header className={clsxm('flex items-center justify-between')}>
						<h1 className={clsxm('text-xl font-semibold')}>Templates</h1>
						<div>
							<Button
								type='button'
								variant='dark'
								className='w-full justify-between'
								isLoading={isCreatingNewTemplate}
								disabled={isCreatingNewTemplate}
								onClick={() => setShowTemplateModal(true)}
							>
								New template
							</Button>
						</div>
					</header>
					<div className='mt-6 flex flex-col border border-gray-200'>
						{!loadingTemplates &&
							templates.map(templateItem => (
								<div
									key={templateItem.id}
									className={clsxm(
										'flex w-full items-center justify-between px-5 py-2 capitalize odd:bg-gray-50 '
									)}
								>
									<span className='flex items-center gap-2 text-sm'>
										{templateItem.name}
									</span>
									<div className='flex items-center gap-2'>
										{templateItem.status ===
											ECustomFormTemplateStatus.Published && (
											<Button
												type='button'
												variant='success'
												className='px-2 py-1 text-xs'
												onClick={() => shareTemplate(templateItem.id)}
											>
												<Share2 className='h-3 w-3' />
												Share
											</Button>
										)}
										{/* {authFund?.companyFormTemplateId !== templateItem.id && (
											<Button
												type='button'
												variant='dark'
												className='text-xs'
												isLoading={isPublishingTemplate === templateItem.id}
												onClick={() => makeDefault(templateItem)}
											>
												Make default
											</Button>
										)} */}
										<Button
											type='button'
											variant='ghost'
											className='text-xs'
											onClick={selectTemplate(templateItem.id)}
										>
											<EyeIcon className='h-3 w-3' />
											View
										</Button>
									</div>
								</div>
							))}
					</div>
				</div>

				<SideDrawer
					title='New template'
					setShow={setShowTemplateModal}
					show={showTemplateModal}
					dialogPanelClass='max-w-sm'
				>
					<div className='flex items-end gap-2 pr-4'>
						<Input
							wrapperClass='w-full'
							className=' flex-1'
							placeholder='Template name'
							value={newTemplateName}
							onChange={evt => setNewTemplateName(evt.target.value)}
						/>
						<Button
							disabled={isCreatingNewTemplate}
							isLoading={isCreatingNewTemplate}
							onClick={createNewTemplate}
							variant='dark'
							className='ml-auto px-3 py-1.5'
						>
							Create
						</Button>
					</div>
				</SideDrawer>
			</>
		)
	}

	function handleOptionsChange(change: IOptionChangeEvent) {
		setFieldUpdate(prev => ({ ...prev, options: change.options }))
		setOptionChangeEvent(change)
	}

	return (
		<>
			<div className={clsxm('flx gap-5 p-5')}>
				<div className='mx-auto flex max-w-5xl flex-1 flex-col gap-5'>
					<header className='mt-10 flex items-center justify-between'>
						<div className='flex flex-1 items-center'>
							<Link
								to={{ pathname, search: '?tab=company-form' }}
								className='font-semibold text-blue-500 hover:underline'
							>
								Templates
							</Link>
							&nbsp; &gt; &nbsp;
							<span className='capitalize'>
								{selectedTemplateData.template?.name}
							</span>
						</div>
						<Button
							type='button'
							variant='success'
							className='px-2 py-1 text-xs'
							onClick={() =>
								shareTemplate(selectedTemplateData.template?.id ?? '')
							}
						>
							<Share2 className='h-3 w-3' />
							Share
						</Button>
					</header>

					{!loadingTemplates && (
						<>
							{formSections.map((section, sectionIndex) => (
								<div
									key={section.id}
									className={clsxm(
										'relative rounded border border-gray-100 bg-gray-50 p-4'
									)}
								>
									{!section.isSystem && (
										<PopConfirm
											onConfirm={() => removeSectionAtIndex(sectionIndex)}
											title='Delete confirmation'
											message='Proceed to delete form section?'
											wrapperClass='absolute right-4 top-2'
											renderTrigger={trigger => (
												<TrashIcon
													onClick={trigger}
													className='h-4 w-4 cursor-pointer text-red-500'
												/>
											)}
										/>
									)}

									<Input
										label='Heading'
										className='border-gray-300 shadow-none'
										placeholder='section heading'
										value={section.name}
										onChange={onSectionHeadingChangeHandler(sectionIndex)}
									/>
									<div className='mt-4'>
										<Input
											label='Subheading'
											className='border-gray-300 shadow-none'
											placeholder='section subheading'
											value={section.description}
											onChange={onSubSectionHeadingChangeHandler(sectionIndex)}
										/>
									</div>

									<section className='mt-8'>
										<strong>
											{section.isSystem ? 'System fields' : 'Custom Fields'}
										</strong>

										<table className={clsxm('w-full table-auto')}>
											{!section.isSystem && (
												<thead>
													<tr className=' text-left text-sm'>
														<th className={clsxm('py-2 font-semibold')}>
															Label
														</th>
														<th className={clsxm('py-2 font-semibold')}>
															Content type
														</th>
														<th className={clsxm('py-2 font-semibold')}>
															Options
														</th>

														<th className={clsxm('py-2 font-semibold')}>
															Required
														</th>
														<th />
													</tr>
												</thead>
											)}
											<tbody>
												{section.fields.map((field, fieldIdx) => (
													<Fragment key={field.id}>
														<tr>
															<td
																className={clsxm(
																	'py-2 pr-8 text-sm capitalize'
																)}
															>
																{field.label}
															</td>
															<td
																className={clsxm(
																	'py-2 pr-8 text-sm capitalize'
																)}
															>
																{field.type}
															</td>
															<td
																className={clsxm(
																	' max-w-40 py-2 pr-8 text-sm capitalize'
																)}
															>
																<div
																	className='truncate'
																	// eslint-disable-next-line unicorn/no-array-reduce
																	title={(field.options ?? []).reduce(
																		(acc, option) => `${acc} ${option}, `,
																		''
																	)}
																>
																	{isArray(field.options)
																		? // eslint-disable-next-line unicorn/no-array-reduce
																			field.options.reduce(
																				(acc, option) => `${acc} ${option}, `,
																				''
																			)
																		: '-'}
																</div>
															</td>

															<td
																className={clsxm(
																	'flex items-center gap-1 py-2 pr-8 text-sm text-primary'
																)}
															>
																<CheckCircle className='h-4 w-4 text-primary' />
																{field.isRequired ? 'Yes' : 'No'}
															</td>
															<td>
																{!section.isSystem && (
																	<div className='flex items-center justify-center gap-x-2 '>
																		<motion.button
																			whileHover={{ scale: 1.2 }}
																			className=' cursor-pointer text-gray-500'
																			onClick={() =>
																				editCustomField(
																					sectionIndex,
																					fieldIdx,
																					field
																				)
																			}
																		>
																			<Edit size={12} />
																		</motion.button>
																		<PopConfirm
																			wrapperClass='flex items-center'
																			onConfirm={() =>
																				removeFieldAtIndex(
																					sectionIndex,
																					fieldIdx
																				)
																			}
																			title={
																				<strong className='text-sm font-semibold'>
																					Warning!!!
																				</strong>
																			}
																			message={
																				<>
																					Do you want to delete &nbsp;
																					<strong className='font-semibold'>
																						&quot;{field.label}&quot;
																					</strong>
																					&nbsp; field?
																				</>
																			}
																			renderTrigger={trigger => (
																				<motion.button
																					whileHover={{ scale: 1.2 }}
																					className=' flex cursor-pointer items-center text-red-500'
																					onClick={trigger}
																				>
																					<X size={16} />
																				</motion.button>
																			)}
																		/>
																	</div>
																)}
															</td>
														</tr>
													</Fragment>
												))}
											</tbody>
										</table>
										{!section.isSystem && (
											<Button
												variant='outline'
												className='mt-8 w-full border-dashed border-gray-500 py-2 text-sm text-gray-500 hover:bg-gray-100'
												onClick={() => toggleNewFieldModal(sectionIndex, true)}
											>
												<span>
													<PlusIcon className='h-5 w-5' strokeWidth={4} />
												</span>{' '}
												Add Field
											</Button>
										)}
									</section>
								</div>
							))}

							<Button
								variant='outline'
								className='mt-8 w-full border-dashed py-3 text-sm'
								onClick={addNewSection}
							>
								<span>
									<PlusIcon className='h-5 w-5' strokeWidth={4} />
								</span>{' '}
								Add section
							</Button>
							<Button
								variant='blue'
								className=' w-full py-3 text-sm'
								isLoading={isSavingTemplate}
								disabled={isSavingTemplate}
								onClick={saveTemplate}
							>
								<span>
									<PlusIcon className='h-5 w-5' strokeWidth={4} />
								</span>{' '}
								Save
							</Button>
						</>
					)}
				</div>
			</div>

			<AddCustomFieldModal
				sectionIndex={newCustomFieldDataIndex}
				template={selectedTemplateData.template}
				show={showNewCustomFieldModal}
				setShow={setShowNewCustomFieldModal}
			/>

			<SideDrawer
				slideFrom='right'
				show={showCustomFieldDrawer}
				title='Update field'
				setShow={setShowCustomFieldDrawer}
				dialogPanelClass={clsxm('max-w-md')}
			>
				<div className='flex flex-col gap-6'>
					<div className={clsxm('')}>
						<Input
							className={clsxm('border-gray-300 shadow-none')}
							value={fieldUpdate.label}
							label='Field name'
							required
							onChange={evt =>
								handleCustomFieldUpdateChange('label', evt.target.value)
							}
							placeholder='Field name'
						/>
					</div>
					<div className={clsxm('')}>
						<Field>
							<Label className='text-sm/6 font-medium text-black'>
								Input type
							</Label>

							<div className='relative'>
								<Select
									className={clsxm(
										'mt-1 block w-full appearance-none rounded border-gray-300 bg-white px-3 py-1.5 text-sm/6 text-black',
										'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
										// Make the text of each option black on Windows
										'capitalize *:text-black'
									)}
									value={fieldUpdate.type ?? ''}
									onChange={evt =>
										handleCustomFieldUpdateChange('type', evt.target.value)
									}
								>
									{[
										ECustomCompanyFormFieldType.Dropdown,
										ECustomCompanyFormFieldType.SingleLine,
										ECustomCompanyFormFieldType.Multiline,
										ECustomCompanyFormFieldType.RichEditor,
										// ECustomCompanyFormFieldType.File,
										ECustomCompanyFormFieldType.Link
										// ECustomCompanyFormFieldType.Tag
									].map(option => (
										<option value={option} key={option}>
											{option}
										</option>
									))}
								</Select>
								<ChevronDownIcon
									className='group pointer-events-none absolute right-2.5 top-2.5 size-4 fill-white/60'
									aria-hidden='true'
								/>
							</div>
						</Field>
						{fieldUpdate.type === ECustomCompanyFormFieldType.Dropdown && (
							<OptionListCreator
								defaultValues={fieldUpdate.options}
								onChange={handleOptionsChange}
							/>
						)}
					</div>

					<div className={clsxm('flex gap-2')}>
						<Switch
							checked={fieldUpdate.isRequired}
							onChange={enabled =>
								handleCustomFieldUpdateChange('isRequired', enabled)
							}
							className={clsxm(
								'group relative flex h-7 w-14 cursor-pointer rounded-full bg-white/10 p-1 transition-colors duration-200 ease-in-out focus:outline-none',
								' data-[checked]:bg-primary data-[focus]:outline-1 data-[focus]:outline-white',
								'border border-primary'
							)}
						>
							<span
								aria-hidden='true'
								className={clsxm(
									'pointer-events-none inline-block size-5 translate-x-0 rounded-full  ',
									'shadow-lg ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-7',
									fieldUpdate.isRequired ? 'bg-white' : 'bg-primary'
								)}
							/>
						</Switch>
						<span>Require field</span>
					</div>
					<div>
						<Button
							isLoading={updatingCustomField}
							disabled={updatingCustomField}
							onClick={handleCustomFieldUpdate}
							variant='dark'
							className='ml-auto px-3 py-1.5'
						>
							Update
						</Button>
					</div>
				</div>
			</SideDrawer>
		</>
	)
}

export default CompanyFormSettings
