/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-continue */
import type { FundData, UserData } from 'interfaces'
import type { ITask } from '../interafces/task.interface'
import { DEFAULT_TASK_FUNNELS } from '../data/columns.data'
import { toast } from 'sonner'
import { doc, setDoc, writeBatch } from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import { isPast, isToday } from 'date-fns'

interface SetupParams {
	onConfigurationStart: (message: string) => void
	onConfigurationEnd: () => void
}

class TaskFunnelConfiguration {
	private readonly tasks: ITask[]

	private readonly fund: FundData

	private readonly creator: UserData

	public constructor(tasks: ITask[], fund: FundData, creator: UserData) {
		this.tasks = tasks
		this.fund = fund
		this.creator = creator
	}

	private getDefaultFunnels() {
		return DEFAULT_TASK_FUNNELS.map(funnel => ({
			...funnel,
			creator: this.creator,
			fund: this.fund
		}))
	}


	public static promptStage(message: string, params?: SetupParams) {
		if (params) {
			params.onConfigurationStart(message)
		} else {
			toast.loading(message)
		}
	}

	public async setup(params?: SetupParams) {
		await this.setupDefaultFunnels(params)
		this.groupTasks(params)
	}

	private async setupDefaultFunnels(params?: SetupParams) {
		TaskFunnelConfiguration.promptStage('Setting up funnels...', params)

		const promises = []
		for (const funnel of this.getDefaultFunnels()) {
			const docRef = doc(firestore, `task_funnels/${funnel.id}`)
			promises.push(setDoc(docRef, funnel, { merge: true }))
		}

		await Promise.all(promises)
		toast.dismiss()
	}

	private async groupTasks(params?: SetupParams) {
		if (this.tasks.length === 0) return

		TaskFunnelConfiguration.promptStage('Grouping tasks...', params)

		/**
		 * Backlog: dueDate in the future
		 * In process: to be done today
		 * In review : dueDate has passed
		 * Done: completed field is set to true
		 */
		const tasksAtStages: Record<string, ITask[]> = {}

		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < this.tasks.length; i += 1) {
			const task = this.tasks[i]
			if (task.status === 'completed' && tasksAtStages.completed) {
				tasksAtStages.completed.push({
					...task,
					index: tasksAtStages.completed.length,
					status: 'completed'
				})
				continue
			}

			if (task.status === 'completed' && !tasksAtStages.completed) {
				tasksAtStages.completed = [{ ...task, index: 0, status: 'completed' }]
				continue
			}

			if (isToday(task.dueDate) && tasksAtStages['in-process']) {
				tasksAtStages['in-process'].push({
					...task,
					index: tasksAtStages['in-process'].length,
					status: 'in-process'
				})
				continue
			}

			if (isToday(task.dueDate) && !tasksAtStages['in-process']) {
				tasksAtStages['in-process'] = [
					{ ...task, index: 0, status: 'in-process' }
				]
				continue
			}

			if (isPast(task.dueDate) && tasksAtStages['in-review']) {
				tasksAtStages['in-review'].push({
					...task,
					index: tasksAtStages['in-review'].length,
					status: 'in-review'
				})
				continue
			}

			if (isPast(task.dueDate) && !tasksAtStages['in-review']) {
				tasksAtStages['in-review'] = [
					{ ...task, index: 0, status: 'in-review' }
				]
				continue
			}

			if (tasksAtStages.backlog) {
				tasksAtStages.backlog.push({
					...task,
					index: tasksAtStages.backlog.length,
					status: 'backlog'
				})
			} else {
				tasksAtStages.backlog = [{ ...task, index: 0, status: 'backlog' }]
			}
		}

		// Logger.info(Object.values(tasksAtStages).flat())

		const tasksBatch = writeBatch(firestore)
		for (const task of Object.values(tasksAtStages).flat()) {
			const taskRef = doc(firestore, `tasks/${task.id}`)
			tasksBatch.set(taskRef, task, { merge: true })
		}

		await tasksBatch.commit()
		toast.dismiss()
	}
}

export default TaskFunnelConfiguration
