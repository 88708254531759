/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppSelector } from 'app/hooks'
import { AuthContext } from 'context/AuthContext'
import { streamCompanyUpdates } from 'features/updates/api/comapany_updates.api'
import type {
	IAddUpdate,
	ICompanyUpdate
} from 'features/updates/interfaces/company_update.interface'
import { CompanyUpdateService } from 'features/updates/services/company_updates.service'
import Updates from 'features/updates/views/Updates'
import type { CompanyData, ICompany } from 'interfaces'
import { useContext, useEffect } from 'react'
import { updateCompany } from '../api/companies.api'
import { CompanyService } from '../service/company.service'

interface Props {
	company: ICompany
	isReadOnly?: boolean
}

export default function CompanyUpdates({ company, isReadOnly }: Props) {
	const { authUser } = useContext(AuthContext)
	const updates = useAppSelector(state => state.updates.data)
	const updatesLoading = useAppSelector(state => state.updates.isLoading)

	useEffect(() => {
		const unsubscribe = streamCompanyUpdates(company.id)
		return () => unsubscribe()
	}, [company.id])

	return (
		<Updates
			isReadOnly={isReadOnly}
			updates={updates}
			loadingUpdates={updatesLoading}
			onAddUpdate={(update: IAddUpdate, files: File[]) => {
				if (isReadOnly) return
				if (!authUser) return
				CompanyUpdateService.createCompanyUpdate(update, files, authUser, {
					id: company.id,
					name: company.name,
					website: company.website || ''
				})
				CompanyService.updateCompany({
					id: company.id || '',
					key: 'lastCompanyUpdate',
					value: new Date().toISOString(),
					company: company as CompanyData,
					shouldLogActivity: false
				})
			}}
			onDeleteUpdate={update => {
				CompanyUpdateService.deleteCompanyUpdate(update)
			}}
			onUpdateUpdate={(update: ICompanyUpdate, file: File[]) => {
				if (!authUser) return
				CompanyUpdateService.createCompanyUpdateUpdate(update, authUser, file)
				CompanyService.updateCompany({
					id: company.id || '',
					key: 'lastCompanyUpdate',
					value: new Date().toISOString(),
					company: company as CompanyData,
					shouldLogActivity: false
				})

			}}
			title='Company Updates'
		/>
	)
}
