import * as Yup from 'yup'

import type {
	ILimitedPartner,
	ITeamMember
} from '../interfaces/limitedPartner.interface'

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/promise-function-async */
import { AuthContext } from 'context/AuthContext'
import { Errorhandler } from 'lib/sentry'
import Logger from 'lib/logger'
import { isEmailTaken } from 'features/authentication/authentication.api'
import { toast } from 'sonner'
import { updateLP } from '../api/lps.api'
import { useContext } from 'react'
import { useFormik } from 'formik'

export default function useUpdateTeamMembers({
	onSuccess,
	limitedPartner,
	teamMember
}: {
	onSuccess?: () => void
	limitedPartner: ILimitedPartner
	teamMember?: ITeamMember
}) {
	const { authUser } = useContext(AuthContext)

	const validationSchema = Yup.object({
		name: Yup.string().required('Name is required'),
		email: Yup.string()
			.required('Email is required')
			.email('Invalid email address')
			.test('unique', 'Email already exists', async value => {
				try {
					if (!value) return false

					const result = await isEmailTaken(value)
					return !result.data.exists
				} catch (error: any) {
					Logger.error(error)
					Errorhandler.captureException(error)

					return false
				}
			})
	})

	const formik = useFormik({
		initialValues: {
			name: teamMember?.name ?? '',
			email: teamMember?.email ?? '',
			linkedIn: teamMember?.linkedIn ?? ''
		},
		validationSchema,
		onSubmit: async values => {
			if (!authUser) return
			try {
				formik.setSubmitting(true)
				if (teamMember) {
					const updatedTeamMembers = limitedPartner.teamMembers?.map(
						teamMemberItem => {
							if (teamMemberItem.email === teamMember.email) {
								return {
									...teamMemberItem,
									...values
								}
							}
							return teamMemberItem
						}
					)
					await updateLP({
						...limitedPartner,
						teamMembers: updatedTeamMembers,
						teamMemberEmails: updatedTeamMembers?.map(f => f.email)
					})
				} else {
					await updateLP({
						id: limitedPartner.id,
						teamMembers: [
							...(limitedPartner.teamMembers ?? []),
							{
								name: values.name,
								email: values.email,
								linkedIn: values.linkedIn
							}
						],
						teamMemberEmails: [
							...(limitedPartner.teamMemberEmails ?? []),
							values.email
						]
					})
				}
				toast.success(
					teamMember
						? 'TeamMember updated successfully'
						: 'TeamMember added successfully'
				)
				onSuccess?.()
			} catch (error: any) {
				toast.error(error.message ?? 'An error occurred')

				Logger.error(error)
				Errorhandler.captureException(error)
			} finally {
				formik.setSubmitting(false)
				formik.resetForm()
			}
		}
	})

	return { formik }
}
