import { collection, onSnapshot, query, where } from 'firebase/firestore'

import Errorhandler from 'lib/sentry'
import type { IOnboardingData } from 'interfaces/onboarding.interface'
import Logger from 'lib/logger'
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import axiosInstance from 'lib/axios'
import { firestore } from 'lib/firebase'
import { toast } from 'sonner'

export async function checkFundExists(
	fundName: string,
	slug: string
): Promise<boolean> {
	try {
		const { data } = await axiosInstance.post(`/funds/exists`, {
			name: fundName,
			slug
		})
		if (!data.success) return true
		return data.data.exists
	} catch (error: any) {
		Logger.error('Error checking fund exists', error)
		Errorhandler.captureException(error)

		return true
	}
}

// export async function checkFundInvitation(
// 	invitationId: string,
// 	email: string
// ): Promise<boolean> {
// 	try {
// 		const { data } = await axiosInstance.post(
// 			`/onboarding/invites/${invitationId}?email=${email}`
// 		)
// 		return data.data.exists
// 	} catch (error: any) {
// 		const message =
// 			error.response.data.message ?? error.message ?? 'An error occured'
// 		Logger.error(error)
// 		Errorhandler.captureException(error)

// 		toast.error(message)

// 		return false
// 	}
// }

export async function createOnboardingData(onboardingData: IOnboardingData) {
	try {
		const { data } = await axiosInstance.post(`/onboarding/process`, onboardingData)
		return data.data
	} catch (error: any) {
		const message =
			error.response.data.message ?? error.message ?? 'An error occured'
		Logger.error(error)
		Errorhandler.captureException(error)

		toast.error(message)

		return null
	}
}

export function listenToOnboardingData(
	invitationId: string,
	callback: (data: any) => void
) {
	try {
		const ref = collection(firestore, `onboarding`)
		const q = query(ref, where('invitationId', '==', invitationId))
		const unsubscribe = onSnapshot(q, snapshot => {
			const data = snapshot.docs.map(doc => doc.data())
			if (data.length === 0) return
			callback(data[0])
		})

		return unsubscribe
	} catch (error: any) {
		const message =
			error.response.data.message ?? error.message ?? 'An error occured'
		Logger.error(error)
		Errorhandler.captureException(error)

		toast.error(message)

		return () => {}
	}
}
