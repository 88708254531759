import { store } from 'app/store'
import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	limit,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	where,
	writeBatch
} from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import Errorhandler from 'lib/sentry'
import type { ICryptoAccount } from '../interface/cryptoAccount.interface'
import { setCryptoAccounts } from '../redux/cryptoAccounts.slice'

export const cryptoAccountsRef = collection(firestore, 'crypto_accounts')

export const addCryptoAccount = async (
	bankAccount: ICryptoAccount
): Promise<ICryptoAccount> => {
	const docRef = doc(cryptoAccountsRef, bankAccount.id)
	await setDoc(docRef, bankAccount, { merge: true })
	return bankAccount
}

export const updateCryptoAccount = async (
	bankAccount: Partial<ICryptoAccount>
): Promise<Partial<ICryptoAccount>> => {
	const docRef = doc(cryptoAccountsRef, bankAccount.id)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	await updateDoc(docRef, bankAccount as any, { merge: true })
	return bankAccount
}

export const getFirstCryptoAccount =
	async (): Promise<ICryptoAccount | null> => {
		const q = query(cryptoAccountsRef, limit(1))

		const querySnapshot = await getDocs(q)

		const data = querySnapshot.docs.map(x => x.data() as ICryptoAccount)

		return data.length > 0 ? data[0] : null
	}

export const hasDefaultCryptoAccount = async (): Promise<boolean> => {
	const q = query(cryptoAccountsRef, where('isDefault', '==', true))
	const docs = await getDocs(q)

	return docs.size > 0
}

export const listenToCryptoAccounts = (fundId: string) => {
	const q = query(cryptoAccountsRef, where('fund.id', '==', fundId));
	
	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const bankAccount = querySnapshot.docs.map(
				d => d.data() as ICryptoAccount
			)
			store.dispatch(setCryptoAccounts(bankAccount))
		},
		error => {
			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}

export const updateDefaultCryptoAccount = async (id: string) => {
	const batch = writeBatch(firestore)
	const snapshot = await getDocs(query(cryptoAccountsRef))

	for (const docData of snapshot.docs) {
		const docRef = doc(cryptoAccountsRef, docData.id)

		if (id === docData.id) {
			batch.update(docRef, { isDefault: true })
		} else {
			batch.update(docRef, { isDefault: false })
		}
	}

	await batch.commit()
}

export const deleteCryptoAccount = async (id: string) => {
	const docRef = doc(cryptoAccountsRef, id)
	await deleteDoc(docRef)
}
