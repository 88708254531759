/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { CaretSortIcon } from '@radix-ui/react-icons'
import type { ColumnDef } from '@tanstack/react-table'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import Button from 'components/Buttons/Button'
import DeleteModal from 'components/DeleteModal'
import { AuthContext } from 'context/AuthContext'
import { DataTable } from 'features/dataTable/DataTable'
import { listenToInvitations } from 'features/invitations/invitations.api'
import {
	selectInvitationStatus,
	selectInvitations
} from 'features/invitations/redux/invitations.slice'
import type { Invitation } from 'interfaces'
import { useContext, useEffect } from 'react'
import { mapRoleToName } from 'utils/roles'
import { InvitationService } from '../services/invitation.service'
import InviteNewUser from './InviteNewUser'

const columns: ColumnDef<Invitation>[] = [
	// Will be brought back once we find a use for the multi selector

	// {
	// 	id: 'select',
	// 	header: ({ table }) => (
	// 		<Checkbox
	// 			checked={
	// 				table.getIsAllPageRowsSelected() ||
	// 				(table.getIsSomePageRowsSelected() && 'indeterminate')
	// 			}
	// 			onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
	// 			aria-label='Select all'
	// 		/>
	// 	),
	// 	cell: ({ row }) => (
	// 		<Checkbox
	// 			checked={row.getIsSelected()}
	// 			onCheckedChange={value => row.toggleSelected(!!value)}
	// 			aria-label='Select row'
	// 		/>
	// 	),
	// 	enableSorting: false,
	// 	enableHiding: false
	// },
	{
		accessorKey: 'name',
		header: ({ column }) => (
			<Button
				className='px-0'
				variant='ghost'
				onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
			>
				Name
				<CaretSortIcon className='ml-2 h-4 w-4' />
			</Button>
		),
		cell: ({ row }) => (
			<div className='capitalize'>{row.original.invitee.name}</div>
		)
	},
	{
		accessorKey: 'email',
		header: ({ column }) => (
			<Button
				className='px-0'
				variant='ghost'
				onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
			>
				Email
				<CaretSortIcon className='ml-2 h-4 w-4' />
			</Button>
		),
		cell: ({ row }) => (
			<div className='lowercase'>{row.original.invitee.email}</div>
		)
	},
	{
		accessorKey: 'role',
		header: ({ column }) => (
			<Button
				className='px-0'
				variant='ghost'
				onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
			>
				Role
				<CaretSortIcon className='ml-2 h-4 w-4' />
			</Button>
		),
		cell: ({ row }) => (
			<div className=''>{mapRoleToName(row.original.invitee.role)}</div>
		)
	},
	{
		accessorKey: 'status',
		header: ({ column }) => (
			<Button
				className='px-0'
				variant='ghost'
				onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
			>
				Status
				<CaretSortIcon className='ml-2 h-4 w-4' />
			</Button>
		),
		cell: ({ row }) => (
			<div className='capitalize'>{row.getValue('status')}</div>
		)
	},
	{
		accessorKey: 'actions',
		header: ({ column }) => (
			<Button
				className='px-0'
				variant='ghost'
				onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
			>
				Actions
			</Button>
		),
		cell: ({ row }) => (
			<div className='flex items-center gap-0'>
				<DeleteModal
					deleteButtonId={`delete-invite-${row.original.invitee.email}`}
					title='Delete Invitation'
					description={`Are you sure you want to delete the invitation for ${row.original.invitee.email}?`}
					trigger={
						<Button
							id={`delete-invite-${row.original.invitee.email}`}
							variant='ghost'
							className='px-2 text-red-500'
						>
							Delete
						</Button>
					}
					onDelete={() => InvitationService.delete(row.original.id)}
				/>
			</div>
		),
		enableHiding: false,
		enableSorting: false
	}
]

export default function Invitations(): JSX.Element {
	const { authUser } = useContext(AuthContext)

	const dispatch = useAppDispatch()
	const invitations = useAppSelector(selectInvitations)
	const invitationsLoading =
		useAppSelector(selectInvitationStatus) === 'loading'

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubscribe = dispatch(listenToInvitations(authUser.fund.id))
		return () => unsubscribe()
	}, [authUser?.fund.id])

	return (
		<main className='h-full w-full px-5'>
			<div className='relative w-full'>
				<div className='w-full  pt-5 text-left text-lg font-semibold'>
					<div className='flex justify-between'>
						Invited Partners <InviteNewUser />
					</div>
					<p className='text-sm font-normal text-gray-500'>
						Manage people who have been invited to join your fund.
					</p>
				</div>
				<DataTable
					loading={invitationsLoading}
					entity='invitation'
					columns={columns}
					data={invitations}
					filterPlaceholder='Search invited partners...'
					showColumnToggle={false}
					onColumnUpdate={updatedColumns => {
						// logger.info({ updatedColumns })
					}}
					onColumnVisibilityChange={(columnId: string, isVisible: boolean) => {
						// logger.info({ columnId, isVisible })
					}}
				/>
			</div>
		</main>
	)
}
