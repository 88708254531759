import CapitalCallpage from 'features/capital_call/views/CapitalCallPage'
import DashboardLayout from 'components/Dashboard/DashboardLayout'

export default function CapitalCalls(): JSX.Element {
	return (
		<DashboardLayout hideHeader={false} showSidebar title='Capital Calls'>
			<CapitalCallpage />
		</DashboardLayout>
	)
}
