import {
	addTasks,
	setTasks,
	setTasksError,
	setTasksLoading
} from 'features/tasks/redux/tasks.slice'
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	updateDoc,
	where
} from 'firebase/firestore'

import { Errorhandler } from 'lib/sentry'
import type { ITask } from './interafces/task.interface'
import Logger from 'lib/logger'
import { firestore } from 'lib/firebase'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'

const tasksRef = collection(firestore, 'tasks')

export const addTask = async (task: ITask): Promise<ITask> => {
	const docRef = doc(tasksRef, task.id)

	await setDoc(docRef, task, { merge: true })

	return task
}

export const getTask = async (id: string): Promise<ITask> => {
	const docRef = doc(tasksRef, id)
	const docSnap = await getDoc(docRef)
	return docSnap.data() as ITask
}

export const getTasks = async (fundId: string): Promise<ITask[]> => {
	const q = query(tasksRef, where('fund.id', '==', fundId))
	const querySnapshot = await getDocs(q)
	const tasks = querySnapshot.docs.map(d => d.data() as ITask)

	return tasks
}

export const updateTask = async (task: Partial<ITask>): Promise<ITask> => {
	const docRef = doc(tasksRef, task.id)
	await updateDoc(docRef, task as any, { merge: true })

	return task as ITask
}

export const updateTaskCompany = async (id: string, data: any) => {
	const docRef = doc(tasksRef, id)
	await updateDoc(docRef, data, { merge: true })
}

export const updateTaskExecutor = async (id: string, data: any) => {
	const docRef = doc(tasksRef, id)
	await updateDoc(docRef, data, { merge: true })
}

export const deleteTask = async (id: string): Promise<void> => {
	const docRef = doc(tasksRef, id)
	await deleteDoc(docRef)
}

export const listenToTasks = (fundId: string) => {
	store.dispatch(setTasksLoading(true))

	const q = query(
		tasksRef,
		where('fund.id', '==', fundId),
		orderBy('status', 'desc'),
		orderBy('lastUpdatedAt', 'desc')
	)
	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const tasks = querySnapshot.docs.map(d => d.data() as ITask)
			store.dispatch(setTasks(tasks))
		},
		error => {
			store.dispatch(setTasksError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}

export const listenToCompanyTasks = (companyId: string) => {
	store.dispatch(setTasksLoading(true))
	Logger.info('Listening to company tasks')
	const q = query(
		tasksRef,
		where('company.id', '==', companyId)
		// orderBy('status', 'desc'),
		// orderBy('lastUpdatedAt', 'desc')
	)

	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const tasks = querySnapshot.docs.map(d => d.data() as ITask)
			store.dispatch(addTasks(tasks))
		},
		error => {
			store.dispatch(setTasksError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}
