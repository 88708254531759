import { AnimatePresence, motion } from 'framer-motion'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import {
	selectEntityThreads,
	selectIsThreadsLoading,
	selectSelectedThread
} from 'features/mail/redux/threads.slice'
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import CompanySelectedEmailThread from './CompanySelectedThreadEmails'
import EmailThreads from 'features/mail/views/EmailThreads'
import type { ICompany } from 'interfaces'
import MailAuthorizeButton from 'features/mail/components/MailAuthorizeButton'
import ShowSignatureHint from 'features/mail/components/ShowSignatureHint'
import { ThreadService } from 'features/mail/service/thread.service'
import { listenToLabelCounts } from 'features/mail/api/threads.api'
import { listenToMessages } from 'features/mail/api/messages.api'

export default function CompanyEmails({ company }: { company: ICompany }) {
	const dispatch = useAppDispatch()
	const { inboxThreads, scheduledCallThreads } = useAppSelector(state =>
		selectEntityThreads(state, company.founderEmails || [])
	)
	const threadsLoading = useAppSelector(selectIsThreadsLoading)
	const selectedEmailThread = useAppSelector(selectSelectedThread)
	const { authUser } = useContext(AuthContext)

	useEffect(() => {
		if (!authUser) return

		const unsubscribeLabelCounts = listenToLabelCounts(authUser.id)
		const unsubscribeThreads = ThreadService.listenToEntityThreads(
			authUser.id,
			company.founderEmails || []
		)

		return () => {
			unsubscribeLabelCounts()
			unsubscribeThreads()
		}
	}, [authUser, company.founderEmails])

	useEffect(() => {
		if (!selectedEmailThread) return
		ThreadService.read(selectedEmailThread.id, true)
		const unsubscribe = dispatch(listenToMessages(selectedEmailThread.id))
		return () => unsubscribe()
	}, [selectedEmailThread, authUser?.id])

	return (
		<AnimatePresence>
			<div className='h-full w-full flex-1 px-3 pb-0 pt-2'>
				<div className='mb-2 text-sm font-semibold'>Emails</div>
				<div>
					<ShowSignatureHint />
					<MailAuthorizeButton />
				</div>
				<Tabs defaultValue='inbox' className='mt-2 w-full'>
					<TabsList className='grid w-full grid-cols-2'>
						<TabsTrigger value='inbox'>Inbox</TabsTrigger>
						<TabsTrigger value='schedule-call'>Schedule Call</TabsTrigger>
					</TabsList>
					<TabsContent value='inbox'>
						{(!selectedEmailThread || selectedEmailThread.hasScheduledCall) && (
							<motion.div
								initial={{ translateX: -100, opacity: 0 }}
								animate={{ translateX: 0, opacity: 1 }}
								exit={{ translateX: -100, opacity: 0 }}
							>
								<EmailThreads threads={inboxThreads} loading={threadsLoading} />
							</motion.div>
						)}
						{selectedEmailThread && !selectedEmailThread.hasScheduledCall && (
							<motion.div
								initial={{ translateX: 100, opacity: 0 }}
								animate={{ translateX: 0, opacity: 1 }}
								exit={{ translateX: 100, opacity: 0 }}
							>
								<CompanySelectedEmailThread />
							</motion.div>
						)}
					</TabsContent>
					<TabsContent value='schedule-call'>
						{(!selectedEmailThread ||
							!selectedEmailThread.hasScheduledCall) && (
							<motion.div
								initial={{ translateX: -100, opacity: 0 }}
								animate={{ translateX: 0, opacity: 1 }}
								exit={{ translateX: -100, opacity: 0 }}
							>
								<EmailThreads
									threads={scheduledCallThreads}
									loading={threadsLoading}
								/>
							</motion.div>
						)}
						{selectedEmailThread && selectedEmailThread.hasScheduledCall && (
							<motion.div
								initial={{ translateX: 100, opacity: 0 }}
								animate={{ translateX: 0, opacity: 1 }}
								exit={{ translateX: 100, opacity: 0 }}
							>
								<CompanySelectedEmailThread />
							</motion.div>
						)}
					</TabsContent>
				</Tabs>
			</div>
		</AnimatePresence>
	)
}
