import type { EmailEntityRelation } from '../interface/email_entity_relation'
import type { IContact } from 'interfaces'
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface ContactsSliceState {
	data: IContact | null
	isLoading: boolean
	error?: string | null
}

const initialState: ContactsSliceState = {
	data: null,
	isLoading: false,
	error: null
}

export const contactSlice = createAppSlice({
	name: 'contact',
	initialState,
	reducers: create => ({
		setContactIsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setContactError: create.reducer((state, action: PayloadAction<string>) => {
			state.error = action.payload
			state.isLoading = false
		}),
		setContact: create.reducer((state, action: PayloadAction<IContact>) => {
			state.data = action.payload
			state.isLoading = false
		}),
		setEmailEntityRelation: create.reducer(
			(state, action: PayloadAction<EmailEntityRelation>) => {
				const data = {
					...state.data,
					connectionStrength: action.payload.connectionStrength || 0,
					firstMeeting: action.payload.firstMeeting || '',
					lastMeeting: action.payload.lastMeeting || '',
					lastEmail: action.payload.lastEmail || '',
					firstEmail: action.payload.firstEmail || ''
				}
				state.data = data as unknown as IContact
			}
		)
	}),
	selectors: {
		selectContact: state => state
	}
})

export const {
	setContact,
	setEmailEntityRelation,
	setContactIsLoading,
	setContactError
} = contactSlice.actions

export const { selectContact } = contactSlice.selectors
