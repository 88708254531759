import { doc, setDoc, writeBatch } from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import type { ITask, ITaskFunnel } from '../interafces/task.interface'
import type { DropResult } from 'react-beautiful-dnd'
import Logger from 'lib/logger'
import { toast } from 'sonner'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class TaskFunnelsService {
	public static async updateFunnels(update: { index: number; id: string }[]) {
		const batch = writeBatch(firestore)

		for (const localUpdate of update) {
			batch.set(
				doc(firestore, `task_funnels/${localUpdate.id}`),
				{ index: localUpdate.index },
				{ merge: true }
			)
		}

		await batch.commit()
	}

	public static async handleTaskMoveToDifferentColumn(
		item: ITask,
		dropResult: DropResult,
		funnel: ITaskFunnel
	) {
		if (dropResult.destination == null) {
			Logger.error('No destination')
			return
		}
		try {
			const taskDoc = doc(firestore, `tasks/${item.id}`)
			await setDoc(
				taskDoc,
				{
					status: funnel.category,
					index: dropResult.destination.index
				},
				{ merge: true }
			)
		} catch (error: unknown) {
			toast.error('Failed to save task')
			Logger.error(error)
		}
	}

	public static async handleTaskMoveInSameColumn(items: ITask[]) {
		const batch = writeBatch(firestore)
		for (const task of items) {
			batch.set(
				doc(firestore, `tasks/${task.id}`),
				{ index: task.index },
				{ merge: true }
			)
		}

		await batch.commit()
	}
}

export default TaskFunnelsService
