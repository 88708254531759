import { Archive, ArrowLeftCircle } from 'lucide-react'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import {
	selectSelectedThread,
	setSelectedThread
} from 'features/mail/redux/threads.slice'

import Button from 'components/Buttons/Button'
import CompanyEmailItem from 'features/companies/components/CompanyEmailItem'
import type { IEmailMessage } from 'features/mail/interfaces/email.interface'
import Logger from 'lib/logger'
import { ThreadService } from '../service/thread.service'
import { selectMessagesState } from 'features/mail/redux/emails.slice'
import { store } from 'app/store'
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { useAppSelector } from 'app/hooks'

export default function SidebarEmailThreadItem() {
	const { data: emails, isLoading } = useAppSelector(selectMessagesState)
	const selectedEmailThread = useAppSelector(selectSelectedThread)

	Logger.info(selectedEmailThread)

	if (isLoading)
		return (
			<div className='p-8'>
				<div className='h-3 w-52 animate-pulse rounded bg-gray-200' />
				<div className='mt-5 h-3 w-96 animate-pulse rounded bg-gray-200' />
				<div className='mt-2 h-3 w-96 animate-pulse rounded bg-gray-200' />
			</div>
		)

	if (!selectedEmailThread) return <div>No thread found</div>

	return (
		<div className='flex'>
			<div className='relative z-[1] flex flex-1 flex-col'>
				<div className='flex items-center gap-2 rounded-md border px-3 py-2'>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								variant='ghost'
								onClick={() => {
									store.dispatch(setSelectedThread(null))
								}}
								className='px-1 py-1'
							>
								<ArrowLeftCircle />
							</Button>
						</TooltipTrigger>
						<TooltipContent>Close Thread</TooltipContent>
					</Tooltip>
					<div className='w-52 flex-1 truncate'>
						<div>{selectedEmailThread.latestEmail?.subject}</div>
						<div className='text-xs font-semibold'>
							{emails.length} message{emails.length === 1 ? '' : 's'}
						</div>
					</div>

					<div className='flex items-center gap-1 '>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									onClick={async () => {
										await ThreadService.archive(selectedEmailThread.id)
									}}
									className='px-1 py-1'
								>
									<Archive size={20} />
								</Button>
							</TooltipTrigger>
							<TooltipContent side='bottom'>Archive</TooltipContent>
						</Tooltip>
					</div>
				</div>
				<div className='mt-3 flex w-full flex-col gap-2'>
					{emails.map((email: IEmailMessage) => (
						<CompanyEmailItem
							email={{
								...email,
								companies: selectedEmailThread.companies,
								limitedPartners: selectedEmailThread.limitedPartners
							}}
							key={email.id}
						/>
					))}
				</div>
			</div>
		</div>
	)
}
