/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Check, Mail, MoveRight } from 'lucide-react'
import {
	disconnectGoogleAuthorization,
	handleGoogleAuthorization
} from '../helpers/gmail.helper'
import {
	handleDisconnectGoogleCalendar,
	handleGoogleCalendarAuthorization,
	verifyGoogleCalendarCode
} from '../helpers/google_calendar.helper'
import { useEffect, useState } from 'react'

import Button from 'components/Buttons/Button'
import { GoogleMailService } from '../service/google_mail.service'
import { ImSpinner10 } from 'react-icons/im'
import IntegrationCard from '../components/IntegrationCard'
import { handleGoogleDriveAuthorization } from '../helpers/google_drive.helper'
import { selectAuthState } from 'features/authentication/authentication.slice'
import { toast } from 'sonner'
import { useAppSelector } from 'app/hooks'
import { useLocation } from 'react-router-dom'
import { verifyGoogleDriveCode } from '../api/google_drive.api'

export default function IntegrationSettings(): JSX.Element {
	const { search } = useLocation()
	const searchParams = new URLSearchParams(search)
	const code = searchParams.get('code')

	const { authUser, authUserFund } = useAppSelector(selectAuthState)

	const [isGmailConnecting, setIsGmailConnecting] = useState(false)
	const isGmailConnected = !!authUser?.googleToken?.refreshToken

	const [isGoogleDriveConnecting, setIsGoogleDriveConnecting] = useState(false)
	const isGoogleDriveConnected = !!authUserFund?.googleToken?.refreshToken

	const [isGoogleCalendarConnecting, setIsGoogleCalendarConnecting] =
		useState(false)
	const isGoogleCalendarConnected = !!authUser?.integrations?.googleCalendar

	useEffect(() => {
		if (!code) return

		const authenticateGmail = async (code: string) => {
			try {
				setIsGmailConnecting(true)

				await GoogleMailService.authorize(code)
			} catch (error: any) {
				toast.error(error.message ?? 'Failed to connect Gmail')
			} finally {
				setIsGmailConnecting(false)
				window.history.replaceState(
					{},
					document.title,
					'/settings/integrations'
				)
			}
			localStorage.removeItem('googleAuth')
		}

		const authenticateGoogleDrive = async (code: string) => {
			try {
				setIsGoogleDriveConnecting(true)

				const result = await verifyGoogleDriveCode(code)
				if (result.error) throw new Error(result.error)

				toast.success('GoogleDrive connected successfully!')
			} catch (error: any) {
				toast.error(error.message ?? 'Failed to connect GoogleDrive')
			} finally {
				setIsGoogleDriveConnecting(false)
				window.history.replaceState(
					{},
					document.title,
					'/settings/integrations'
				)
				localStorage.removeItem('googleAuth')
			}
		}

		const authenticateGoogleCalendar = async (code: string) => {
			try {
				setIsGoogleCalendarConnecting(true)

				await verifyGoogleCalendarCode(code)
			} catch (error: any) {
				toast.error(error.message ?? 'Failed to connect GoogleCalendar')
			} finally {
				setIsGoogleCalendarConnecting(false)
			}
		}
		const action = localStorage.getItem('googleAuth')
		if (action === 'google_mail') authenticateGmail(code)
		if (action === 'google_drive') authenticateGoogleDrive(code)
		if (action === 'google_calendar') authenticateGoogleCalendar(code)
	}, [code])

	if (!authUser) return <div />

	return (
		<main className='h-full w-full px-5'>
			<div className='relative mb-10 w-full'>
				<div className='w-full  pt-5 text-left text-lg font-semibold'>
					<div className='flex justify-between'>Integrations Settings</div>
					<p className='text-sm font-normal text-gray-500'>
						Manage your integrations and platform access
					</p>
				</div>
			</div>

			<div className='flex flex-wrap gap-5'>
				<div className='h-52 w-52 rounded border border-blue-100 bg-blue-50'>
					<div className='flex items-center gap-2 px-3 pt-3'>
						<Mail />
						<div>Gmail</div>
					</div>
					<div className='mt-2 px-3 text-xs'>
						Connect your Gmail account to access your emails
					</div>

					{isGmailConnected && (
						<div className='mt-5 flex items-center gap-1 px-3 text-sm text-blue-700'>
							<Check size={15} />
							Connected
						</div>
					)}
					{isGmailConnecting && (
						<div className='mt-5 flex items-center gap-1 px-3 text-sm text-blue-700'>
							<ImSpinner10 className='animate-spin' />
							Connecting...
						</div>
					)}
					{!isGmailConnected && (
						<div className='absolute bottom-0 flex w-52 justify-end'>
							<Button
								variant='ghost'
								isLoading={isGmailConnecting}
								className='hover:bg-blue-100'
								onClick={handleGoogleAuthorization}
							>
								Connect <MoveRight size={12} />
							</Button>
						</div>
					)}
					{isGmailConnected && (
						<div className='absolute bottom-0 flex w-52 justify-end'>
							<Button
								variant='ghost'
								isLoading={isGmailConnecting}
								className='text-red-500 hover:bg-red-100'
								onClick={() => disconnectGoogleAuthorization(authUser.id)}
							>
								Disconnect <MoveRight size={12} />
							</Button>
						</div>
					)}
				</div>

				<IntegrationCard
					name='Google Calendar'
					description='Connect your Google Calendar account to access your events'
					icon={
						<img
							src='/images/google-calendar.png'
							alt='google calendar icon'
							className='h-5 w-5'
						/>
					}
					isConnected={isGoogleCalendarConnected}
					isConnecting={isGoogleCalendarConnecting}
					connect={handleGoogleCalendarAuthorization}
					disConnect={() => handleDisconnectGoogleCalendar()}
				/>

				<div
					aria-disabled
					className='h-52 w-52 rounded border border-blue-100 bg-blue-50 aria-disabled:cursor-not-allowed aria-disabled:opacity-50'
				>
					<div className='flex items-center gap-2 px-3 pt-3'>
						<img
							src='/images/google-drive.png'
							alt='dropbox icon'
							className='h-6 w-6'
						/>
						<div>Google Drive</div>
					</div>
					<div className='mt-2 px-3 text-xs'>
						Connect your Google Drive account to access your files
					</div>

					{isGoogleDriveConnected ? (
						<div className='mt-5 flex items-center gap-1 px-3 text-sm text-blue-700'>
							<Check size={15} />
							Connected
						</div>
					) : (
						<div className='absolute bottom-0 flex w-52 justify-end'>
							<Button
								disabled
								variant='ghost'
								isLoading={isGoogleDriveConnecting}
								className='hover:bg-blue-100'
								onClick={handleGoogleDriveAuthorization}
							>
								Connect <MoveRight size={12} />
							</Button>
						</div>
					)}
				</div>

				<div
					aria-disabled
					className='h-52 w-52 rounded border border-blue-100 bg-blue-50 aria-disabled:cursor-not-allowed aria-disabled:opacity-50'
				>
					<div className='flex items-center gap-2 px-3 pt-3'>
						<img
							src='/images/dropbox.png'
							alt='dropbox icon'
							className='h-6 w-6'
						/>
						<div>Dropbox</div>
					</div>
					<div className='mt-2 px-3 text-xs'>
						Connect your Dropbox account to access your files
					</div>

					<div className='absolute bottom-0 flex w-52 justify-end'>
						<Button disabled variant='ghost' className='hover:bg-blue-100'>
							Connect <MoveRight size={12} />
						</Button>
					</div>
				</div>

				{/* Wise */}
				<div
					aria-disabled
					className='h-52 w-52 rounded border border-blue-100 bg-blue-50 aria-disabled:cursor-not-allowed aria-disabled:opacity-50'
				>
					<div className='flex items-center gap-2 px-3 pt-3'>
						<img src='/images/wise.png' alt='wise icon' className='h-6 w-6' />
						<div>Wise</div>
					</div>
					<div className='mt-2 px-3 text-xs'>
						Connect your Wise account to sync your transactions
					</div>

					<div className='absolute bottom-0 flex w-52 justify-end'>
						<Button disabled variant='ghost' className='hover:bg-blue-100'>
							Connect <MoveRight size={12} />
						</Button>
					</div>
				</div>
			</div>
		</main>
	)
}
