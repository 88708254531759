/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { IDashboard } from './dashboard.interface'

export interface DashboardSliceState {
	data: IDashboard | null
	isLoading: boolean
	error?: string | null
}

const initialState: DashboardSliceState = {
	data: null,
	isLoading: false,
	error: null
}

export const dashboardSlice = createAppSlice({
	name: 'dashboard',
	initialState,
	reducers: create => ({
		setDashboard: create.reducer((state, action: PayloadAction<IDashboard>) => {
			state.data = action.payload
			state.isLoading = false
		})
	}),
	selectors: {
		selectDashboard: company => company.data
	}
})

export const { setDashboard } = dashboardSlice.actions

export const { selectDashboard } = dashboardSlice.selectors
