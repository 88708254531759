import type { AppThunk } from 'app/store'
import type { Unsubscribe } from 'firebase/firestore'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'
import type { IDashboard } from './dashboard.interface'
import { setDashboard } from './dashboard.slice'

export const dashboardRef = collection(firestore, 'dashboard_data')

export const listenToDashboard =
	(id: string): AppThunk<Unsubscribe> =>
	dispatch => {
		const docRef = doc(dashboardRef, id)

		const unsubscribe = onSnapshot(
			docRef,
			docSnap => {
				const data = docSnap.data() as IDashboard
				dispatch(setDashboard(data))
			},
			error => {
				Errorhandler.captureException(error)
				Logger.error(error)
			}
		)

		return unsubscribe
	}
