import Button from 'components/Buttons/Button'
import _ from 'lodash'
import { CopyPlus, Plus } from 'lucide-react'
import { MonthNameToNumber } from '../helpers/updates.helper'
import type {
	IAddUpdate,
	ICompanyUpdate
} from '../interfaces/company_update.interface'
import AddUpdate from './AddUpdate'
import UpdateItem from './UpdateItem'

interface Props {
	title?: string
	updates?: ICompanyUpdate[]
	loadingUpdates: boolean
	onAddUpdate: (update: IAddUpdate, files: File[]) => void
	onDeleteUpdate: (update: ICompanyUpdate) => void
	onUpdateUpdate: (update: ICompanyUpdate, files: File[]) => void
	isReadOnly?: boolean
}

export default function Updates({
	title = 'Updates',
	updates,
	onAddUpdate,
	loadingUpdates,
	onDeleteUpdate,
	onUpdateUpdate,
	isReadOnly
}: Props) {
	const groupedYearUpdates = _.groupBy(updates, 'year')

	if (loadingUpdates) {
		return (
			<div className='pb-0 pt-2'>
				<div className='flex items-center justify-between px-3'>
					<div className='mb-2 text-sm font-semibold'>{title}</div>
					{!isReadOnly && (
						<AddUpdate
							trigger={
								<Button id='open-add-update-modal' onClick={() => {}}>
									<Plus />
								</Button>
							}
							onAddUpdate={(update, files) => {
								onAddUpdate(update, files)
							}}
						/>
					)}
				</div>

				<div className='mt-5 flex w-full flex-col items-center gap-3'>
					<CopyPlus />
					<div>Loading Updates...</div>
				</div>
			</div>
		)
	}

	if (!updates || updates.length === 0) {
		return (
			<div className='pb-0 pt-2'>
				<div className='flex items-center justify-between px-3'>
					<div className='mb-2 text-sm font-semibold'>{title}</div>
					{!isReadOnly && (
						<AddUpdate
							trigger={
								<Button id='open-add-update-modal' onClick={() => {}}>
									<Plus />
								</Button>
							}
							onAddUpdate={(update, files) => {
								onAddUpdate(update, files)
							}}
						/>
					)}
				</div>

				<div className='mt-5 flex w-full flex-col items-center gap-3'>
					<CopyPlus />
					<div>No updates found</div>
				</div>
			</div>
		)
	}

	return (
		<main className='h-full w-full flex-1 pt-2'>
			<section>
				<div>
					<div className='flex items-center justify-between px-3'>
						<div className='mb-2 text-sm font-semibold'>{title}</div>
						{!isReadOnly && updates.length === 0 && (
							<AddUpdate
								trigger={
									<Button onClick={() => {}}>
										<Plus />
									</Button>
								}
								onAddUpdate={(update, files) => {
									onAddUpdate(update, files)
								}}
							/>
						)}
					</div>

					<div className='flex flex-col gap-5 '>
						{Object.keys(groupedYearUpdates)
							.sort((a, b) => Number(b) - Number(a))
							.map(year => {
								const groupedMonthUpdates = _.groupBy(
									groupedYearUpdates[year],
									'month'
								)
								return (
									<div key={year} className='border-bpb-3'>
										<div className=' sticky top-0 z-[2] bg-white pt-3'>
											<div className='mb-3 flex items-center justify-between border-b px-3 pb-3'>
												<div>
													<div className='text-lg font-semibold'>{year}</div>
													<div className='flex items-center gap-2'>
														<div className='text-sm font-semibold text-blue-700'>
															Total updates
														</div>
														<div className='font-semibold'>
															{groupedYearUpdates[year].length}
														</div>
													</div>
												</div>
												{!isReadOnly && (
													<AddUpdate
														trigger={
															<Button onClick={() => {}}>
																<Plus />
															</Button>
														}
														onAddUpdate={(update, files) => {
															onAddUpdate(update, files)
														}}
													/>
												)}
											</div>
										</div>
										<div className='flex flex-col gap-2 px-2'>
											{Object.keys(groupedMonthUpdates)
												.sort((a, b) => {
													return (
														Number(MonthNameToNumber(b)) -
														Number(MonthNameToNumber(a))
													)
												})
												.map(month => {
													return (
														<div key={month} className='flex flex-col gap-2'>
															<div className='flex w-full items-center gap-3 '>
																<div className='h-[0.1rem] flex-1 bg-gray-200' />
																<div className='text-sm font-semibold'>
																	{month}
																</div>
																<div className='h-[0.1rem] flex-1 bg-gray-200' />
															</div>
															{groupedMonthUpdates[month].map(update => {
																return (
																	<UpdateItem
																		isReadOnly={isReadOnly}
																		key={update.id}
																		update={update}
																		onEdit={(value, files) => {
																			onUpdateUpdate(
																				{
																					...update,
																					...value
																				},
																				files
																			)
																		}}
																		onDelete={value => {
																			onDeleteUpdate(value)
																		}}
																	/>
																)
															})}
														</div>
													)
												})}
										</div>
									</div>
								)
							})}
					</div>
				</div>
			</section>
		</main>
	)
}
