/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/self-closing-comp */
import Skeleton from 'components/Skeleton'
import CalendarEvents from 'features/calendar_events/views/CalendarEvents'
import _ from 'lodash'
import { CircleSlash, Hexagon, Link, MessageCircle, Trash2 } from 'lucide-react'
import { getMonthAndYear, renderDate } from 'utils/date.util'
import type { IActivity } from '../interfaces/actvitiy.interface'

interface Props {
	activities: IActivity[]
	loading: boolean
	totalEmails?: number
	totalNotes?: number
	eventEmails: string[]
}


function ActivityIcon({ label }: { label: string }) {
	switch (label) {
		case "created a share link": {
			return <Link size={20} color='gray' />
		}
		case 'added a new comment': {
			return <MessageCircle size={20} color='gray' />
		}
		case 'deleted file': {
			return <Trash2 size={20} color='gray' />
		}
		case 'deleted founder': {
			return <Trash2 size={20} color='gray' />
		}
		default: {
			return <Hexagon size={20} color='gray' />
		}
	}
}

export default function ActivityLogs({
	activities,
	loading,
	totalEmails = 0,
	totalNotes = 0,
	eventEmails
}: Props) {

	const groupedActivities = _.groupBy(activities, activity => {
		const [year, month] = activity.createdAt.split('T')[0].split('-');
		return `${year}-${month}`;
	});

	if (loading) {
		return (
			<div className='rounded border bg-white'>
				<div className='font border-b p-2 font-semibold text-blue-700'>
					Activity
				</div>
				<div className='mt-2 flex flex-col justify-center gap-2 p-2'>
					<Skeleton className='h-5 w-52 border' />
					<Skeleton className='h-5 w-96 border' />
					<Skeleton className='h-5 w-96 border' />
					<Skeleton className='h-5 w-96 border' />
				</div>
			</div>
		)
	}

	if (activities.length === 0) {
		return (
			<div className='rounded border bg-white pb-5'>
				<div className='font border-b p-2 font-semibold text-blue-700'>
					Activity
				</div>
				<section className='flex flex-wrap gap-6 px-3 py-3'>
					<div>
						<div className='font font-semibold text-blue-700'>Total Emails</div>
						<div>{totalEmails} Emails</div>
					</div>
					<div>
						<div className='font font-semibold text-blue-700'>Total Notes</div>
						<div>{totalNotes} notes</div>
					</div>
					<CalendarEvents emails={eventEmails} />
				</section>
				<div className='text mt-5 flex items-center gap-3 px-4 text-gray-500'>
					<CircleSlash />
					No activities found
				</div>
			</div>
		)
	}

	return (
		<div className='rounded border bg-white'>
			<div className='font border-b p-2 font-semibold text-blue-700'>
				Activity
			</div>
			<section className='flex flex-wrap gap-6 px-3 py-3'>
				<div>
					<div className='font font-semibold text-blue-700'>Total Emails</div>
					<div>{totalEmails} Emails</div>
				</div>
				<div>
					<div className='font font-semibold text-blue-700'>Total Notes</div>
					<div>{totalNotes} notes</div>
				</div>
				<CalendarEvents emails={eventEmails} />
			</section>
			<section className='flex flex-col gap-5 px-3 py-3'>
				{Object.keys(groupedActivities).map(date => (
					<div key={date}>

						<div className='flex items-center gap-2'>
							<div className='text-sm font-semibold text-gray-500'>
								{getMonthAndYear(date)}
							</div>
							<div className='h-[0.05rem] flex-1 bg-gray-300' />
						</div>

						<div className='mt-2 flex flex-col gap-5'>
							{groupedActivities[date].map(activity => (
								<div key={activity.id} className='flex gap-3'>
									<ActivityIcon label={activity.activity} />
									<div className='flex-1'>
										<div className='flex items-center gap-2'>
											<div className='text-sm font-semibold text-blue-700'>
												{activity.user.name}
											</div>
											<div className='text-sm font-semibold text-gray-700'>
												{activity.activity}
											</div>
											{/* <div className='font-semibold'>{activity.summary}</div> */}
										</div>
										<div className='text-sm text-gray-700'>
											{activity.description}
										</div>
										<div className='mt-1 text-xs text-gray-500'>
											{renderDate(activity.createdAt)}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				))}
			</section>
		</div>
	)
}
