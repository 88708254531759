/* eslint-disable consistent-return */
import { useAppSelector } from 'app/hooks'
import { AuthContext } from 'context/AuthContext'
import { selectCapitalCallRequests } from 'features/new-capital-call/redux/capitalCallRequests.slice'
import { CapitalCallRequestService } from 'features/new-capital-call/service/capital_call_request.service'
import { CalendarIcon } from 'lucide-react'
import { useContext, useEffect, useState } from 'react'
import { getAmount } from 'utils/currency'
import { currencyFormatter } from 'utils/currencyformatter.util'
import { percentangeFormatter } from 'utils/percentageFormatter.util'
import {
	capitalCallRequestsMapper,
	getCapitalCallMonthlySummary,
	getCapitalCallSummary,
	getCapitalCallYearlySummary
} from '../helpers/capitalCall.helper'
import type {
	ICapitalCallMonthlySummary,
	ICapitalCallRequestMap,
	ICapitalCallSummary,
	ICapitalCallYearlySummary
} from '../interfaces/capitalCall.interface'
import CapitalCallSummaryFilter from './CapitalCallSummaryFilter'
import CapitalCallSummaryTable from './CapitalCallSummaryTable'

export default function CapitalCallPage(): JSX.Element {
	const { authUser } = useContext(AuthContext)

	const capitalCallRequests = useAppSelector(selectCapitalCallRequests)

	const [capitalCallMap, setCapitalCallMap] =
		useState<ICapitalCallRequestMap>()
	const [capitalCallSummary, setCapitalCallSummary] =
		useState<ICapitalCallSummary>()
	const [capitalCallYearlySummary, setCapitalCallYearlySummary] =
		useState<Record<number, ICapitalCallYearlySummary>>()
	const [capitalCallMonthlySummary, setCapitalCallMonthlySummary] =
		useState<Record<number, Record<number, ICapitalCallMonthlySummary>>>()

	useEffect(() => {
		if (capitalCallMap) {
			setCapitalCallSummary(getCapitalCallSummary(capitalCallMap))
			setCapitalCallYearlySummary(getCapitalCallYearlySummary(capitalCallMap))
			setCapitalCallMonthlySummary(getCapitalCallMonthlySummary(capitalCallMap))
		}
	}, [capitalCallMap])

	useEffect(() => {
		const map = capitalCallRequestsMapper(capitalCallRequests)
		setCapitalCallMap(map)
	}, [capitalCallRequests])

	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribeRequests =
			CapitalCallRequestService.listenToCapitalCallRequests(authUser.fund.id)

		return () => {
			unsubscribeRequests()
		}
	}, [authUser?.fund.id])

	function filterSummaryDataByYear(years: number[]) {
		const map = capitalCallRequestsMapper(capitalCallRequests, years)
		setCapitalCallMap(map)
	}

	return (
		<div className='h-100vh min-h-[calc(100vh-3rem)] bg-white p-14 pb-0'>
			<div className='flex w-full flex-wrap gap-8'>
				<div className='min-w-[200px] basis-[200px]'>
					<div>
						<CapitalCallSummaryFilter
							data={capitalCallRequestsMapper(capitalCallRequests)}
							filter={filterSummaryDataByYear}
						/>
					</div>
				</div>
				<div className='w-full grow basis-[calc(100%-200px-2rem)] bg-white'>
					<div className='flex items-center pb-10'>
						<div className='ml-5 mr-6 rounded-lg bg-blue-700 p-3'>
							<CalendarIcon width='24' height='24' color='white' />
						</div>
						<span className='mr-5 text-3xl font-semibold'>Summary</span>
						<div className='mr-7'>
							<span className='mr-1 font-semibold text-blue-600'>Total</span>
							<span>
								{capitalCallSummary?.total &&
									capitalCallSummary.total.length > 0 &&
									currencyFormatter('USD').format(
										getAmount(capitalCallSummary.total, 'USD')
									)}
							</span>
						</div>
						<div className='mr-7'>
							<span className='mr-1 font-semibold text-blue-600'>
								Total Capital calls
							</span>
							<span>{capitalCallSummary?.totalCapitalCalls || 0}</span>
						</div>
						<div>
							<span className='mr-1 font-semibold text-blue-600'>
								Percent remaining
							</span>
							<span>
								{percentangeFormatter(
									capitalCallSummary?.percentRemaining || 0
								)}
							</span>
						</div>
					</div>

					<CapitalCallSummaryTable
						monthlySummary={capitalCallMonthlySummary}
						yearlySummary={capitalCallYearlySummary}
						data={capitalCallMap}
					/>
				</div>
			</div>
		</div>
	)
}
