/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { clsx } from "clsx";
import DeleteModal from "components/DeleteModal";
import Skeleton from "components/Skeleton";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";
import { AuthContext } from "context/AuthContext";
import useDataFilterHook from "features/filter/hooks/useDataFilterHook";
import type { IDataFilter } from "features/filter/interfaces/filter.interface";
import { FilterService } from "features/filter/service/filter.service";
import { Filter, Trash2 } from "lucide-react";
import { useContext, useEffect, useState } from "react";

interface Props {
  entity: string
  className?: string
}

export default function DataTableSavedFilters({ entity, className }: Props) {

  const { authUser } = useContext(AuthContext)

  const [filters, setFilters] = useState<IDataFilter[]>([])
  const [loading, setLoading] = useState(true)
  const { selectedFilters, addAllFilters } = useDataFilterHook()


  useEffect(() => {
    if (!authUser) return
    FilterService.listenToEntityFilters({
      authUser,
      feature: entity,
      callback: (_comments) => { setFilters(_comments) },
      loadingCallback: (_loading) => { setLoading(_loading) }
    })
  }, [entity, authUser])

  if (loading) {
    return (
      <div className='h-fit w-full flex gap-3 py-3'>
        <Skeleton className='h-6 w-52 border' />
        <Skeleton className='h-6 w-52 border' />
        <Skeleton className='h-6 w-52 border' />
      </div>
    )
  }

  if (filters.length === 0) {
    return null
  }

  return (
    <div
      className={
        clsx(
          `bg-white border rounded-md p-2 flex text-sm `,
          className
        )
      }
    >
      <div className='flex items-center justify-between gap-1 mr-3'>
        <Filter size={15} color="blue" />
        <div className='font-semibold'>Saved filters</div>
      </div>
      <div className='flex gap-2 flex-wrap'>
        {filters.map(filter => (
          <div

            key={filter.id}
            className={
              clsx(
                'flex items-center gap-1 border rounded-md px-1 cursor-pointer transition-all group',
                filter.filters.map((f) => (f.value)) === selectedFilters.map((ff) => (ff.value)) ? 'bg-blue-50 border-blue-200 text-blue-700 hover:bg-blue-600' : 'bg-gray-100 hover:bg-gray-200'
              )
            }
          >
            <div
              className='font-semibold '
              onClick={() => {
                addAllFilters(filter.filters)
              }}
            >
              {filter.name}
            </div>
            <div className="transition-all flex items-center justify-center -translate-x-2 opacity-0 pointer-events-none group-hover:translate-x-0 group-hover:opacity-100 group-hover:pointer-events-auto">
              <DeleteModal
                deleteButtonId={`deleteFilter-${filter.name}`}
                title='Delete File'
                description={`Are you sure you want to delete ${filter.name}?`}
                trigger={
                  <Tooltip>
                    <TooltipTrigger>
                      <Trash2 color="red" size={13} />
                    </TooltipTrigger>
                    <TooltipContent>
                      Delete {filter.name}
                    </TooltipContent>
                  </Tooltip>
                }
                onDelete={() => FilterService.deleteFilter(filter.id)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}