import { ArrowUp, GalleryThumbnails, Trash2 } from 'lucide-react'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { addFiles, listenToLimitedPartnerFiles } from 'features/files/files.api'
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect, useState } from 'react'

import Button from 'components/Buttons/Button'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import DeleteModal from 'components/DeleteModal'
import { FileService } from 'features/files/services/file.service'
import type { FileUploadResult } from 'features/storage/hooks/useFirebaseStorageUploader'
import FileUploader from 'features/fileviewers/views/FileUploader'
import FileViewer from 'features/fileviewers/views/FileViewer'
import type { IFile } from 'features/files/file.interface'
import type { ILimitedPartner } from 'interfaces'
import Logger from 'lib/logger'
import clsx from 'clsx'
import { selectAuthState } from 'features/authentication/authentication.slice'
import { selectFiles } from 'features/files/files.slice'
import { toast } from 'sonner'

interface Props {
  limitedPartner: ILimitedPartner
  isReadOnly?: boolean
}

const fileSortOptions = [
  { label: 'Name', value: 'name' },
  { label: 'Date', value: 'createdAt' }
]

export default function LimitedPartnerFiles({ limitedPartner, isReadOnly }: Props) {
  const dispatch = useAppDispatch()
  const { authUser } = useAppSelector(selectAuthState)

  const [view, setView] = useState<'grid' | 'list'>('grid')

  const files = useAppSelector(selectFiles)

  const [fileSort, setFileSort] = useState<{
    field: keyof IFile
    order: string
  }>({
    field: 'name',
    order: 'asc'
  })

  useEffect(() => {
    if (!limitedPartner.id) return

    const unsubscribe = dispatch(listenToLimitedPartnerFiles(limitedPartner.id))
    return () => unsubscribe()
  }, [limitedPartner.id])

  const saveFiles = async (results: FileUploadResult[]) => {
    if (!authUser) return
    const filesData = results.map((result: FileUploadResult) => {
      const data: IFile = {
        id: result.id,
        name: result.name,
        url: result.url,
        mimeType: result.type,
        size: result.size ?? 0,
        fund: authUser.fund,
        creator: {
          id: authUser.id,
          name: authUser.name,
          photoUrl: authUser.photoUrl || ''
        },
        limitedPartner: {
          id: limitedPartner.id,
          name: limitedPartner.name,
          photoUrl: limitedPartner.photoUrl || ''
        },
        source: 'spok',
        createdAt: new Date().toISOString(),
        lastUpdatedAt: new Date().toISOString(),
        fileType: 'limitedpartner_file'
      }

      return data
    })
    try {
      await addFiles(filesData)
    } catch (error: any) {
      Logger.error(error)
      toast.error(error?.message ?? 'Failed to upload files')
    }
  }

  return (
    <div>
      {!isReadOnly && (
        <div className='mb-2 flex w-fit gap-2 bg-white px-2 py-2'>
          <div className='pr-2'>
            <div className='mb-1 text-xs'>New Upload</div>
            <FileUploader id='limitedPartner-file-upload' onUpload={saveFiles} />
          </div>
        </div>
      )}

      <div className='flex items-center justify-between px-3'>
        <div className='flex cursor-pointer items-center gap-1'>
          <div className='text-lg font-semibold'>Files</div>
        </div>
        <div className='flex items-center gap-3'>
          <div className='flex items-center justify-between'>
            <div>
              <div className='flex w-full items-center'>
                <ComboSelect
                  onSelect={value => {
                    setView(value as 'grid' | 'list')
                  }}
                  content={
                    <div className='flex items-center gap-1 pr-3 text-xs font-semibold text-gray-700 shadow-none'>
                      <GalleryThumbnails size={13} />
                      View
                    </div>
                  }
                  defaultValue={view}
                  options={[
                    { label: 'Grid', value: 'grid' },
                    { label: 'List', value: 'list' }
                  ]}
                />
              </div>
            </div>
          </div>
          <div className='flex items-center gap-1'>
            <div
              className={clsx(
                'cursor-pointer transition-all',
                fileSort.order === 'asc' ? 'rotate-0' : 'rotate-180'
              )}
              onClick={() => {
                setFileSort({
                  ...fileSort,
                  order: fileSort.order === 'asc' ? 'desc' : 'asc'
                })
              }}
            >
              <ArrowUp size={15} color='blue' />
            </div>
            <ComboSelect
              onSelect={value => {
                setFileSort({
                  ...fileSort,
                  field: value as unknown as keyof IFile
                })
              }}
              defaultValue={fileSort.field}
              options={fileSortOptions}
              content={
                <div className='flex items-center gap-1 pr-3 text-xs font-semibold text-gray-700 shadow-none'>
                  {
                    fileSortOptions.find(
                      option => option.value === fileSort.field
                    )?.label
                  }
                </div>
              }
            />
          </div>
        </div>
      </div>

      {view === 'grid' && (
        <div className='mt-3 grid grid-cols-1 gap-2 px-2 xl:grid-cols-2 2xl:grid-cols-3'>
          {[...files]
            .sort((a, b) => {
              if (fileSort.order === 'asc') {
                return a[fileSort.field]! > b[fileSort.field]! ? 1 : -1
              }
              return a[fileSort.field]! < b[fileSort.field]! ? 1 : -1
            })
            .map(file => {
              return (
                <div
                  key={file.id}
                  data-testid={`file-${file.name}`}
                  className='flex items-center justify-between rounded border border-gray-200 bg-gray-100 p-1'
                >
                  <div className='w-full items-center gap-2'>
                    <FileViewer
                      readonly={isReadOnly}
                      file={file}
                      onDelete={file => {
                        FileService.deleteFile(file)
                      }}
                    />
                  </div>
                </div>
              )
            })}
        </div>
      )}
      <div className='mt-3 flex flex-col gap-2 px-2 pb-10'>
        {view === 'list' && (
          <div className=' flex flex-col gap-2'>
            {[...files]
              .sort((a, b) => {
                if (fileSort.order === 'asc') {
                  return a[fileSort.field]! > b[fileSort.field]! ? 1 : -1
                }
                return a[fileSort.field]! < b[fileSort.field]! ? 1 : -1
              })
              .map(file => (
                <div
                  key={file.id}
                  data-testid={`file-${file.name}`}
                  className='flex items-center justify-between rounded border border-gray-200 bg-gray-100'
                >
                  <FileViewer
                    file={file}
                    readonly={isReadOnly}
                    onDelete={file => FileService.deleteFile(file)}
                    content={
                      <div className='flex-1 cursor-pointer overflow-scroll whitespace-nowrap p-3 text-sm '>
                        {file.name}
                      </div>
                    }
                  />
                  {!isReadOnly && (
                    <div className=' flex items-center gap-2'>
                      <div className='flex w-fit justify-end '>
                        <DeleteModal
                          deleteButtonId={`deleteFile-${file.name}`}
                          title='Delete File'
                          description={`Are you sure you want to delete ${file.name}?`}
                          trigger={
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  id={`file-${file.name}-delete-button`}
                                  variant='ghost'
                                  className='px-1 py-1'
                                >
                                  <Trash2 color='red' size={15} />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>
                                <>Delete {file.name}</>
                              </TooltipContent>
                            </Tooltip>
                          }
                          onDelete={() => FileService.deleteFile(file)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>

      {files.length === 0 && (
        <div
          data-testid='no-files-label'
          className='flex h-20 items-center justify-center'
        >
          <div className='text-gray-500'>No files uploaded</div>
        </div>
      )}
    </div>
  )
}
