import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import type { ICapitalToBeCalledChart } from '../interfaces/NewCapitalCall.interface'
import { ILimitedPartner } from 'interfaces'
import { currencyFormatter } from 'utils/currencyformatter.util'

interface IProps {
	recipient: ILimitedPartner
	data: ICapitalToBeCalledChart[]
}

export default function CapitalCapitalToBeCalledChart({
	data,
	recipient
}: IProps) {
	return (
		<div className='relative'>
			<div className='absolute top-0 w-full'>
				<div className='text-center'>
					<b>Committed Capital</b>{' '}
					{currencyFormatter(
						recipient.totalCommitted?.currency || 'USD',
						'short'
					).format(data[0].committedCapitalCall)}
				</div>
				<svg height={30} width='100%'>
					<line
						x1='23'
						y1='10'
						x2='94%'
						y2='10'
						style={{ stroke: 'black', strokeWidth: 0.5 }}
					/>
					<line
						x1='23'
						y1='10'
						x2='23'
						y2='20'
						style={{ stroke: 'black', strokeWidth: 0.5 }}
					/>
					<line
						x1='94%'
						y1='10'
						x2='94%'
						y2='20'
						style={{ stroke: 'black', strokeWidth: 0.5 }}
					/>
				</svg>
			</div>
			<div className='relative top-7 h-[100px]'>
				<ResponsiveContainer>
					<BarChart
						layout='vertical'
						data={data}
						margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
						cy={100}
					>
						<XAxis
							type='number'
							domain={[0, data[0].committedCapitalCall]}
							tickCount={10}
							ticks={[
								0,
								data[0].committedCapitalCall * 0.1,
								data[0].committedCapitalCall * 0.2,
								data[0].committedCapitalCall * 0.3,
								data[0].committedCapitalCall * 0.4,
								data[0].committedCapitalCall * 0.5,
								data[0].committedCapitalCall * 0.6,
								data[0].committedCapitalCall * 0.7,
								data[0].committedCapitalCall * 0.8,
								data[0].committedCapitalCall * 0.9,
								data[0].committedCapitalCall
							]}
							tickFormatter={tick => {
								return `${Math.trunc(
									Math.abs((tick / data[0].committedCapitalCall) * 100)
								)}%`
							}}
						/>
						<YAxis hide dataKey='name' type='category' />

						<Bar
							stackId='a'
							background={{
								fill: 'white',
								radius: 5,
								stroke: 'black',
								strokeWidth: 1,
								height: 29,
								x: 19,
								y: 33
							}}
							maxBarSize={data[0].committedCapitalCall}
						/>
						<Bar
							dataKey='previouslyCalled'
							stackId='a'
							fill='#E4E5E8'
							radius={[5, 0, 0, 5]}
						/>
						<Bar
							dataKey='capitalToBeCalled'
							stackId='a'
							fill='#175DCD'
							radius={[0, 5, 5, 0]}
						/>
					</BarChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}
