/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Input } from 'components/ui/input'
import {
	ECustomCompanyFormFieldType,
	type ICustomCompanyFormField
} from '../interfaces/company.interface'
import type { useFormik } from 'formik'
import ReactQuill from 'react-quill'
import clsxm from 'utils/clsxm'
import { useEffect, type ComponentPropsWithoutRef } from 'react'
import { CustomDropDown } from 'components/ui/CustomDropDown'

interface ICompanyFormFieldProps {
	field: ICustomCompanyFormField
	form: ReturnType<typeof useFormik>
	wrapperProps?: ComponentPropsWithoutRef<'div'>
	isSystemField?: boolean
	errorMessage?: string
}
function CompanyFormField({
	field,
	form,

	errorMessage,
	wrapperProps
}: ICompanyFormFieldProps) {
	const { className: wrapperClassName, ...restOfWrapperProps } =
		wrapperProps ?? {}

	// function toggleTag(tag: ITag) {
	// 	if (form.values.tagIds.includes(tag.id)) {
	// 		let existingTags = (form.values.tags ?? []) as ITag[]
	// 		const ids: string[] = []
	// 		existingTags = existingTags.filter(localTag => {
	// 			if (localTag.id !== tag.id) {
	// 				ids.push(localTag.id)
	// 				return true
	// 			}

	// 			return false
	// 		})
	// 		form.setFieldValue('tagIds', ids)
	// 		form.setFieldValue('tags', existingTags)
	// 	} else {
	// 		form.setFieldValue('tagIds', [...(form.values.tagIds ?? []), tag.id])
	// 		form.setFieldValue('tags', [...(form.values.tags ?? []), tag])
	// 	}
	// }

	// const industryNames = industries.map(industry => industry.industry)

	useEffect(() => {
		if (field.type === ECustomCompanyFormFieldType.Dropdown) {
			form.setFieldValue(field.id, field.options ? field.options[0] : '')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={clsxm(wrapperClassName)} {...restOfWrapperProps}>
			{[
				ECustomCompanyFormFieldType.SingleLine,
				ECustomCompanyFormFieldType.Multiline,
				ECustomCompanyFormFieldType.Link
			].includes(field.type as ECustomCompanyFormFieldType) && (
				<Input
					label={field.label}
					type='text'
					value={form.values[field.id] ?? ''}
					isRequired={field.isRequired}
					isMultiline={field.type === ECustomCompanyFormFieldType.Multiline}
					className='border-gray-300 placeholder:text-sm placeholder:text-gray-400'
					placeholder={field.subText ?? ''}
					onChange={e => {
						form.setFieldValue(field.id, e.target.value)
					}}
					onBlur={form.handleBlur(field.id)}
					error={errorMessage}
				/>
			)}

			{/* {field.type === ECustomCompanyFormFieldType.Tag && (
				<div>
					<label className='pb-2 font-semibold' htmlFor='business-categories'>
						{field.label}{' '}
						{field.isRequired && <span className='text-red-600'>*</span>}
					</label>
					<p className='mt-1 text-sm leading-6 text-gray-500'>
						{field.subText}
					</p>
					<div className='-ml-2 flex flex-wrap gap-1'>
						{tags.map(tag => (
							<Button
								variant={
									(form.values.tagIds ?? []).includes(tag.id) ? 'dark' : 'ghost'
								}
								type='button'
								className={clsxm(
									'rounded-full px-2 py-1 text-sm font-normal',
									!(form.values.tagIds ?? []).includes(tag.id) && [
										'border border-gray-200 bg-gray-100'
									]
								)}
								key={tag.id}
								onClick={() => toggleTag(tag)}
							>
								{tag.name}
							</Button>
						))}
					</div>
					{errorMessage && (
						<small className='text-sm text-red-500'>{errorMessage}</small>
					)}
				</div>
			)} */}

			{field.type === ECustomCompanyFormFieldType.RichEditor && (
				<div className='flex flex-col'>
					<label htmlFor='description' className='text-sm font-semibold'>
						{field.label}
						{field.isRequired && <span className='text-red-600'>*</span>}
					</label>

					<ReactQuill
						theme='snow'
						value={form.values[field.id] ?? ''}
						className={clsxm(
							'mb-8 mt-2 w-full',
							'whitespace-pre-wrap break-normal',
							'snow-editor  !bg-white',
							'h-[100px]  pb-4 transition-all duration-100'
						)}
						placeholder={field.subText}
						onChange={e => {
							form.setFieldValue(field.id, e)
						}}
					/>
					{errorMessage && (
						<small className='text-sm text-red-500'>{errorMessage}</small>
					)}
				</div>
			)}

			{field.type === ECustomCompanyFormFieldType.Dropdown && (
				<div>
					<label className='mt-4 block font-semibold' htmlFor={field.id}>
						{field.label}
						{field.isRequired && <span className='text-red-600'>*</span>}
					</label>
					<CustomDropDown
						value={form.values[field.id]}
						triggerClass={clsxm('border-gray-200 w-40 bg-gray-100')}
						dropdownContentClass={clsxm('!w-fit')}
						onClick={option => form.setFieldValue(field.id, option)}
						options={field.options ?? []}
					/>
					{errorMessage && (
						<small className='text-sm text-red-500'>{errorMessage}</small>
					)}
				</div>
			)}

			{/* {field.type === ECustomCompanyFormFieldType.File && (
				<div className='mt-5'>
					File
					{selectedFile ? (
						<div className='mt-10 h-full'>
							<InvestmentFileViewer
								companyName=''
								isDeck
								rawFile={selectedFile}
								file={fileData as ICompanyFile}
							/>
						</div>
					) : (
						<div className=' flex h-full w-full flex-col items-center justify-center gap-5  border bg-white py-10'>
							<div className='scale-[2]'>
								<ion-icon name='document-outline' />
							</div>
							<FileUploader
								shouldUpload={false}
								multiple={false}
								buttonText='Upload Deck'
								onUpload={results => {
									handleSelected(results)
								}}
							/>
						</div>
					)}
					{errorMessage && (
						<small className='text-sm text-red-500'>{errorMessage}</small>
					)}
				</div>
			)} */}
		</div>
	)
}

export default CompanyFormField
