/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { CaretSortIcon, CheckCircledIcon } from '@radix-ui/react-icons'
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuTrigger
} from 'components/ui/dropdown-menu'
import { MoreVertical, Pencil, Timer, Trash2 } from 'lucide-react'
import {
	convertAmount,
	displayAmount,
	getConvertedAmount
} from 'utils/currency'
import { useContext, useEffect, useMemo, useState } from 'react'

import AddPreviousCapitalCallNew from './AddPreviousCapitalCallNew'
import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import type { ColumnDef } from '@tanstack/react-table'
import { CustomModal } from 'components/Modals/CustomModal'
import { DataTable } from 'features/dataTable/DataTable'
import DeleteModal2 from 'components/DeleteModal2'
import type { ICapitalCall } from 'features/capital_call/interfaces/capitalCall.interface'
import type { ILimitedPartner } from '../interfaces/limitedPartner.interface'
import { LPService } from '../service/lp.service'
import LimitedPartnerTotalCommittedChart from './LimitedPartnerTotalCommittedChart'
import Logger from 'lib/logger'
import UpdateCapitalCallRequestWithWiseTransaction from 'features/capital_call/views/UpdateCapitalCallRequestWithWiseTransaction'
import UpdateCapitalCallStatus from 'features/capital_call/views/UpdateCapitalCallStatus'
import ViewCapitalCallFiles from './ViewCapitalCallFiles'
import { currencyFormatter } from 'utils/currencyformatter.util'
import { onDeleteCapitalCall } from 'features/capital_call/service/capital_call.service'
import { renderDate } from 'utils/date.util'

export interface ILimitedPartnerTotalCommittedProps {
	limitedPartner: ILimitedPartner
}

export default function LimitedPartnerCapitalCalls({
	limitedPartner
}: ILimitedPartnerTotalCommittedProps): JSX.Element {
	// const { fundCurrency } = useCurrencyExchange()

	const [capitalCalls, setCapitalCalls] = useState<ICapitalCall[]>([])
	const [isLoading, setIsLoading] = useState(true)
	const { authUser } = useContext(AuthContext)

	const lastCall = useMemo(() => {
		return capitalCalls.length > 0 ? capitalCalls[0] : null
	}, [capitalCalls])

	const callToDate = useMemo(() => {
		// eslint-disable-next-line unicorn/no-array-reduce
		return capitalCalls.reduce((acc, curr) => {
			return (
				acc +
				convertAmount(curr.amount, limitedPartner.totalCommitted.currency)
					.amount
			)
		}, 0)
	}, [capitalCalls])

	// Update modal
	const [selectedCC, setSelectedCC] = useState<ICapitalCall>()
	const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false)

	// Capital Call Modal
	const [isCapitalCallModalOpen, setIsCapitalCallModalOpen] = useState(false)

	// Wise Transaction Modal
	const [openWiseModal, setOpenWiseModal] = useState(false)

	// Delete modal
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

	const amountRemaining = useMemo(() => {
		return (limitedPartner.totalCommitted.amount || 0) - callToDate
	}, [limitedPartner, callToDate])

	useEffect(() => {
		if (!authUser) return
		if (!limitedPartner) return
		LPService.listenToCapitalCalls({
			authUser,
			limitedPartner,
			callback: _capitalCalls => {
				setCapitalCalls(
					_capitalCalls.sort(
						(a, b) =>
							new Date(b.dateCalled).getTime() -
							new Date(a.dateCalled).getTime()
					)
				)
			},
			loadingCallback: _loading => {
				setIsLoading(_loading)
			}
		})
	}, [limitedPartner, authUser])

	useEffect(() => {
		if (!authUser) return
		if (!limitedPartner) return
		LPService.listenToCapitalCalls({
			authUser,
			limitedPartner,
			callback: _capitalCalls => {
				setCapitalCalls(
					_capitalCalls.sort(
						(a, b) =>
							new Date(b.dateCalled).getTime() -
							new Date(a.dateCalled).getTime()
					)
				)
			},
			loadingCallback: _loading => {
				setIsLoading(_loading)
			}
		})
	}, [limitedPartner, authUser])

	if (!limitedPartner) return <div>No limited partner found</div>

	if (!limitedPartner.totalCommitted)
		return <div>No total committed amount found</div>

	const columns: ColumnDef<ICapitalCall>[] = [
		{
			accessorKey: 'dateCalled',
			sortingFn: 'datetime',
			header: ({ column }) => (
				<Button
					className='px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Date Called
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='capitalize'>{renderDate(row.original.dateCalled)}</div>
			)
		},
		{
			accessorKey: 'amount',
			sortingFn: (rowA, rowB) => {
				const a = getConvertedAmount(rowA.original.amount).amount
				const b = getConvertedAmount(rowB.original.amount).amount
				// eslint-disable-next-line unicorn/no-nested-ternary
				return a > b ? 1 : a < b ? -1 : 0
			},
			header: ({ column }) => (
				<Button
					className='px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Amount
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className=''>
					{currencyFormatter(row.original.amount.currency).format(
						row.original.amount.amount
					)}
				</div>
			)
		},
		{
			accessorKey: 'percent',
			sortingFn: 'text',
			header: ({ column }) => (
				<Button
					className='px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					% Called
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='capitalize'>{row.original.percent}%</div>
			)
		},
		{
			accessorKey: 'status',
			sortingFn: 'text',
			header: ({ column }) => (
				<Button
					className='px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Status
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				// <div className='capitalize'>{row.original.status || '-'}</div>
				<div>
					<UpdateCapitalCallStatus capitalCall={row.original as any} />
				</div>
			)
		},
		{
			accessorKey: 'files',
			sortingFn: 'text',
			header: ({ column }) => (
				<Button
					className='px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Files
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div>
					<ViewCapitalCallFiles capitalCall={row.original as any} />
				</div>
			)
		},
		{
			accessorKey: 'wiseTransaction',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Wise
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div>
					{row.original.wiseTransactionId ? (
						<div className='flex items-start justify-center'>
							<CustomModal
								open={openWiseModal}
								setOpen={setOpenWiseModal}
								trigger={
									<div className='flex justify-center'>
										<div
											onClick={() => {
												setOpenWiseModal(true)
											}}
											className={`group flex  w-fit cursor-pointer items-center gap-1 text-ellipsis whitespace-nowrap rounded bg-blue-200 px-3 py-[1px]
        text-xs text-blue-800`}
										>
											View Txn
										</div>
									</div>
								}
							>
								<h3 className='mb-4 font-semibold'>Wise Transaction Details</h3>

								<div className='flex flex-col gap-2'>
									<p>
										Title:
										<span
											className='no-strong whitespace-nowrap capitalize'
											dangerouslySetInnerHTML={{
												__html: row.original.wiseTransaction?.title || ''
											}}
										/>
									</p>
									<p>
										Date:{' '}
										{renderDate(
											row.original.wiseTransaction?.createdAt || new Date()
										)}
									</p>
									<p className='flex gap-2'>
										Amount:{' '}
										<span className='capitalize'>
											{row.original.wiseTransaction?.amount
												.replaceAll(/<\/?[^>]+>/g, '')
												.replaceAll('+ ', '')}
										</span>
									</p>
									<p>Type: {row.original.wiseTransaction?.type}</p>
									<p>Status: {row.original.wiseTransaction?.status}</p>
								</div>
								<Button
									onClick={() => setOpenWiseModal(false)}
									className='ml-auto mt-4 px-4'
								>
									Close
								</Button>
							</CustomModal>

							<div
								onClick={() => {
									setSelectedCC(row.original)
									setIsUpdateModalOpen(true)
								}}
								className='cursor-pointer pl-2 opacity-30'
							>
								<Pencil size={14} />
							</div>
						</div>
					) : (
						<div className='flex justify-center'>
							<div
								onClick={() => {
									setSelectedCC(row.original)
									setIsUpdateModalOpen(true)
								}}
								className={`group mr-4 flex w-fit cursor-pointer items-center gap-1 text-ellipsis whitespace-nowrap rounded bg-blue-200 px-3 py-[1px]
              text-xs text-blue-800`}
							>
								Assign Txn
							</div>
						</div>
					)}
				</div>
			)
		},
		{
			accessorKey: 'actions',
			header: ({ column }) => (
				<Button
					className='px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					More
				</Button>
			),
			cell: ({ row }) => (
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button className='truncate p-2' variant='ghost'>
							<MoreVertical size={15} />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent className='w-56' align='end' forceMount>
						<DropdownMenuGroup>
							<DropdownMenuItem
								asChild
								onClick={() => {
									setSelectedCC(row.original)
									setIsCapitalCallModalOpen(true)
								}}
							>
								<div className='flex cursor-pointer items-center px-2 py-1 hover:bg-gray-100'>
									<Pencil size={15} />
									<span className='ml-3'>Edit</span>
								</div>
							</DropdownMenuItem>

							<DropdownMenuItem
								asChild
								onClick={() => {
									setSelectedCC(row.original)
									setIsUpdateModalOpen(true)
								}}
							>
								<div className='flex items-center'>
									<CheckCircledIcon width='16' height='24' color='green' />
									<span className='ml-3'>Assign Transaction</span>
								</div>
							</DropdownMenuItem>

							<DropdownMenuItem
								asChild
								onClick={() => {
									setSelectedCC(row.original)
									setIsDeleteModalOpen(true)
								}}
							>
								<div className='flex items-center px-2 py-1 text-red-500'>
									<Trash2 size={15} color='red' />
									<span className='ml-3'>Delete</span>
								</div>
							</DropdownMenuItem>
						</DropdownMenuGroup>
					</DropdownMenuContent>
				</DropdownMenu>
			)
		}
	]

	return (
		<div className='h-full w-full flex-1 overflow-y-scroll px-3 pb-0 pt-2'>
			<div className='mb-2 text-sm font-semibold'>Capital Calls</div>
			<div className='flex flex-row justify-around gap-5 rounded-md border border-[#E4E5E8] bg-white px-6 py-6'>
				{capitalCalls.length > 0 && (
					<LimitedPartnerTotalCommittedChart
						data={[
							{
								name: 'Amount remaining',
								value: amountRemaining,
								color: '#FFC107'
							},
							{
								name: 'Last Capital Call',
								value: getConvertedAmount(lastCall?.amount).amount,
								color: '#4CAF50'
							},
							{
								name: 'Called to date',
								value: callToDate,
								color: '#2196F3'
							}
						]}
					/>
				)}

				<div className='flex flex-1 flex-col gap-5'>
					<div className='flex flex-col border-[#E4E5E8] text-center'>
						<div className='flex-grow bg-gray-100 px-3 text-sm font-semibold'>
							Total Committed Amount
						</div>
						<div className='pt-1'>
							{displayAmount(limitedPartner.totalCommitted, false)}
						</div>
					</div>
					<div className='flex flex-col border-[#E4E5E8] text-center'>
						<div className='flex-grow bg-gray-50 px-3 text-sm font-semibold text-[#2196F3]'>
							Called to date
						</div>
						<div className='pt-1'>
							{displayAmount(
								{
									currency: limitedPartner.totalCommitted.currency,
									amount: callToDate
								},
								false
							)}{' '}
							{limitedPartner.totalCommitted.amount &&
								`(${(
									(callToDate / (limitedPartner.totalCommitted.amount || 1)) *
									100
								).toFixed(1)}%)`}
						</div>
					</div>
					<div className='flex flex-col border-[#E4E5E8] text-center'>
						<div className='flex-grow bg-gray-50 px-3 text-sm font-semibold text-[#FFC107]'>
							Amount Remaining
						</div>
						<div className='pt-1'>
							{displayAmount(
								{
									currency: limitedPartner.totalCommitted.currency,
									amount: amountRemaining
								},
								false
							)}{' '}
							{limitedPartner.totalCommitted.amount &&
								`(${(
									(amountRemaining /
										(limitedPartner.totalCommitted.amount || 1)) *
									100
								).toFixed(1)}%)`}
						</div>
					</div>
					<div className='flex flex-col border-[#E4E5E8] text-center'>
						<div className='flex-grow bg-gray-50 px-3 text-sm font-semibold text-[#4CAF50]'>
							Last Call
						</div>
						<div className='pt-1'>
							{displayAmount(lastCall?.amount, false)}{' '}
							{lastCall?.amount &&
								`(${(
									((lastCall.amount.amount || 1) /
										(limitedPartner.totalCommitted.amount || 1)) *
									100
								).toFixed(1)}%)`}
						</div>
					</div>
				</div>
			</div>

			{selectedCC && (
				<UpdateCapitalCallRequestWithWiseTransaction
					cc={selectedCC}
					isOpen={isUpdateModalOpen}
					setIsOpen={setIsUpdateModalOpen}
				/>
			)}

			<AddPreviousCapitalCallNew
				capitalCall={selectedCC}
				limitedPartner={limitedPartner}
				amountRemaining={amountRemaining}
				isOpen={isCapitalCallModalOpen}
				setIsOpen={setIsCapitalCallModalOpen}
			/>

			<DeleteModal2
				deleteButtonId={`delete-${selectedCC?.id}`}
				title='Delete Capital Call'
				description='Are you sure you want to delete this capital call?'
				trigger={<div />}
				isOpen={isDeleteModalOpen}
				setIsOpen={setIsDeleteModalOpen}
				onDelete={() => {
					onDeleteCapitalCall(selectedCC?.id || '')
					setSelectedCC(undefined)
					setIsDeleteModalOpen(false)
				}}
			/>

			<DataTable
				loading={isLoading}
				isGrouped={false}
				entity='capital-call'
				columns={columns}
				allowSearch={false}
				data={capitalCalls}
				filterPlaceholder='Search capital calls...'
				showColumnToggle={false}
				onColumnUpdate={(updatedColumns: any) => {
					Logger.info('Updated columns', updatedColumns)
				}}
				onColumnVisibilityChange={(columnId: string, isVisible: boolean) => {
					Logger.info('Column visibility changed', columnId, isVisible)
				}}
			/>

			<Button
				className='h-fit border border-dashed bg-gray-50 p-4 text-xs text-blue-700'
				variant='ghost'
				onClick={() => setIsCapitalCallModalOpen(true)}
			>
				<Timer size={15} color='blue' />
				Add previous capital
			</Button>
		</div>
	)
}
