/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-extraneous-class */
import { store } from 'app/store'
import {
	collection,
	deleteDoc,
	doc,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	where
} from 'firebase/firestore'
import type { FundData } from 'interfaces'
import { firestore } from 'lib/firebase'
import type { ICapitalCallSettings } from '../interface/capital_call_settings.interafce'
import type { ICapitalCallTemplate } from '../interface/capital_call_template.interface'
import {
	setCapitalCallSettings,
	setCapitalCallTemplates
} from '../redux/capital_call_template.slice'

export class CapitalCallTemplateService {
	private static readonly ref = collection(firestore, 'capital_call_templates')

	private static readonly capitalCallSettingsRef = collection(
		firestore,
		'capital_call_settings'
	)

	// Update a capital call template
	public static async updateCapitalCallTemplate(
		capitalCallTemplate: Partial<ICapitalCallTemplate>
	) {
		const res = doc(this.ref, capitalCallTemplate.id)
		await updateDoc(res, capitalCallTemplate as any, { merge: true })
		return capitalCallTemplate
	}

	// Listen to all capital call templates
	public static listenToCapitalCallTemplates({ fundId }: { fundId: string }) {
		const q = query(this.ref, where('fund.id', '==', fundId))

		return onSnapshot(q, querySnapshot => {
			const capitalCallTemplates = querySnapshot.docs.map(
				d => d.data() as ICapitalCallTemplate
			)
			store.dispatch(
				setCapitalCallTemplates(
					capitalCallTemplates.sort(
						(a, b) =>
							new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
					)
				)
			)
		})
	}

	// Add a new capital call template
	public static async addCapitalCallTemplate(
		capitalCallTemplate: ICapitalCallTemplate
	) {
		const res = doc(this.ref, capitalCallTemplate.id)
		await setDoc(res, capitalCallTemplate)
		return capitalCallTemplate
	}

	// Delete a capital call template
	public static async deleteCapitalCallTemplate(id: string) {
		const res = doc(this.ref, id)
		await deleteDoc(res)
	}

	// Settings
	// Save capital call template settings
	public static async saveCapitalCallTemplateSettings({
		settings,
		fund
	}: {
		settings: ICapitalCallSettings
		fund?: FundData
	}) {
		if (!fund) return

		const res = doc(this.capitalCallSettingsRef, fund.id)
		await setDoc(res, { ...settings, fund }, { merge: true })
	}

	// Listen to capital call template settings
	public static listenToCapitalCallTemplateSettings({
		fundId
	}: {
		fundId: string
	}) {
		const q = query(this.capitalCallSettingsRef, where('fund.id', '==', fundId))

		return onSnapshot(q, querySnapshot => {
			const settings = querySnapshot.docs.map(d => d.data())
			if (settings.length === 0) return
			store.dispatch(setCapitalCallSettings(settings[0] as any))
		})
	}
}
