import { doc, updateDoc } from 'firebase/firestore'

import { firestore } from 'lib/firebase'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'
import { toast } from 'sonner'

export const authorizeGoogleCalendar = () => {
	const GOOGLE_CALENDAR_SCOPES = [
		'https://www.googleapis.com/auth/calendar.readonly',
		'https://www.googleapis.com/auth/calendar.events.readonly'
	]

	const SCOPE_STRING = GOOGLE_CALENDAR_SCOPES.join(' ')

	const CLIENT_ID = import.meta.env.VITE_GMAIL_CLIENT_ID
	const REDIRECT_URI = `${
		import.meta.env.VITE_APP_URL
	}/settings?tab=integrations`

	const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE_STRING}&prompt=consent&access_type=offline&include_granted_scopes=true&openid=email`

	window.open(GOOGLE_AUTH_URL, '_self', 'noopener, noreferrer')
}

export const disconnectGoogleCalendar = async () => {
	try {
		const userId = store.getState().authentication.authUser?.id
		if (!userId) throw new Error('User not found')

		const ref = doc(firestore, 'users', userId)
		await updateDoc(ref, {
			'integrations.googleCalendar': false
		})
	} catch (error: any) {
		toast.error(error.message ?? 'Failed to disconnect GoogleCalendar')
	}
}
