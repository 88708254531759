/* eslint-disable @typescript-eslint/no-extraneous-class */
import { addCapitalCall, deleteCapitalCall, updateCapitalCall } from "../api/capital-call.api"
import { collection, doc, updateDoc } from "firebase/firestore"

import Errorhandler from "lib/sentry"
import type { ICapitalCall } from "../interfaces/capitalCall.interface"
/* eslint-disable @typescript-eslint/no-explicit-any */
import Logger from "lib/logger"
import { firestore } from "lib/firebase"
import { toast } from "sonner"

export async function onAddCapitalCall(capitalCall: ICapitalCall) {
  try {
    await addCapitalCall(capitalCall);

    toast.success('Added successfully')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    toast.error(error.message ?? 'Failed to Add capital call')

    Logger.error(error)
    Errorhandler.captureException(error)
  }
}

export async function onUpdateCapitalCall(id: string, capitalCall: Partial<ICapitalCall>) {
  try {
    await updateCapitalCall(id, capitalCall)

    toast.success('Updated successfully')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    toast.error(error.message ?? 'Failed to Update capital call')

    Logger.error(error)
    Errorhandler.captureException(error)
  }
}

// Delete Capital Call
export async function onDeleteCapitalCall(id: string) {
  try {
    await deleteCapitalCall(id)

    toast.success('Deleted successfully')
  } catch (error: any) {
    toast.error(error.message ?? 'Failed to Delete capital call')

    Logger.error(error)
    Errorhandler.captureException(error)
  }
}

export class CapitalCallService {
	private static readonly ref = collection(firestore, 'capital_calls')

	
	public static updateCapitalCall = async (
		id: string,
		capitalCall: Partial<ICapitalCall>
	): Promise<string> => {
		try {
		const docRef = doc(this.ref, id)
		await updateDoc(docRef, { ...capitalCall } as any, { merge: true })
		toast.success('Capital call updated successfully')
		return id
		} catch (error: any) {
			Logger.error('Error updating capital call', error)
			toast.error(error.message || 'Error updating capital call')
			Errorhandler.captureException(error)
			return ''
		}
	}
}