/* eslint-disable @typescript-eslint/no-explicit-any */
import { Trash2, Upload } from 'lucide-react'
import { useRef, useState } from 'react'
import Button from './Buttons/Button'
import { TableCell, TableRow } from './ui/table'

export interface SelectedFile {
	file: File
	name: string
	type: string
	size: number
}

export default function FileSelector({
	onSelect,
	className
}: {
	onSelect: (files: SelectedFile[]) => void
	className?: string
}) {
	const fileInputRef = useRef<any>(null)
	const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([])

	const handleButtonClick = () => {
		fileInputRef.current?.click()
	}

	const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = [...(e.target.files ?? [])]
		const updatedFiles = [...selectedFiles]

		for (const file of files) {
			updatedFiles.push({
				file,
				name: file.name,
				type: file.type,
				size: file.size
			})
		}

		setSelectedFiles(updatedFiles)
		onSelect(updatedFiles)
	}

	const handleDeleteFile = (index: number) => {
		const updatedFiles = [...selectedFiles]
		updatedFiles.splice(index, 1)
		setSelectedFiles(updatedFiles)
		onSelect(updatedFiles)
	}

	return (
		<div className={className}>
			<input
				type='file'
				ref={fileInputRef}
				style={{ display: 'none' }}
				onChange={handleFileSelect}
				multiple
			/>
			<Button
				variant='ghost'
				className='flex w-full gap-2 border-2 border-dashed bg-gray-50 py-4'
				onClick={handleButtonClick}
			>
				<Upload size={18} /> <div className='ml-2 text-blue-700'>Browse</div>{' '}
				files. Max file size 50MB
			</Button>

			<div className='flex w-full flex-col text-sm'>
				{selectedFiles.map(file => (
					<TableRow key={file.name} className='w-full'>
						<TableCell>
							<Button
								variant='ghost'
								className='px-2'
								onClick={() => handleDeleteFile(selectedFiles.indexOf(file))}
							>
								<Trash2 color='red' size={15} />
							</Button>
						</TableCell>
						<TableCell>
							<div className='w-full  flex-1 font-medium'>{file.name}</div>
						</TableCell>
					</TableRow>
				))}
			</div>
		</div>
	)
}
