import clsxm from 'utils/clsxm'

type LayoutProperties = React.ComponentPropsWithoutRef<'div'>

export default function Layout({
	children,
	className,
	title
}: LayoutProperties): React.ReactElement {
	return (
		<section className={clsxm('', className)}>
			<div className='w-screen border-b'>
				<div className=' container z-[2] mx-auto flex h-12 items-center justify-between bg-white px-5 md:pr-10 dark:border-b-gray-800 dark:bg-[#121212] dark:text-gray-100'>
					<div className='flex items-center gap-3 '>
						<img src='/images/logo/Logo-1.png' className='h-6 w-24' alt='' />
						{title && <div className='border-l pl-3'>{title}</div>}
					</div>
				</div>
			</div>
			<div className='mx-auto pt-0'>{children}</div>
		</section>
	)
}
