/* eslint-disable unicorn/no-array-reduce */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store';
import type { Amount } from 'interfaces';
import { currencyFormatter } from './currencyformatter.util';

export const getAmount = (
	amounts: { amount: number; currency: string }[],
	fundCurrency: string
) => {
	return amounts.find(item => item.currency === fundCurrency)?.amount || 0
}

export const getConvertedAmount = (amount?: Amount) => {
	const fund = store.getState().authentication.authUserFund
	const fundCurrency = fund?.currency || 'USD'
	const exchangeRates = fund?.fundExchangeRates || {}

	if (!amount) return { amount: 0, currency: fundCurrency }

	if (amount.currency === fundCurrency) {
		return { amount: amount.amount, currency: fundCurrency }
	}

	if (!exchangeRates[amount.currency]) return { amount: 0, currency: fundCurrency }

	const exchangeRate = exchangeRates[amount.currency][fundCurrency]
	if (!exchangeRate) return { amount: 0, currency: fundCurrency }

	const convertedAmount = amount.amount * exchangeRate
	return { amount: convertedAmount, currency: fundCurrency }
}

export const convertAmount = (amount: Amount, currency: string) => {
	const fund = store.getState().authentication.authUserFund
	const exchangeRates = fund?.fundExchangeRates || {}

	if (amount.currency === currency) {
		return amount
	}

	const exchangeRate = exchangeRates[amount.currency][currency]
	if (!exchangeRate) return { amount: 0, currency }

	const convertedAmount = amount.amount * exchangeRate
	return { amount: convertedAmount, currency }

}

export const getConvertedAmounts = (amounts?: Record<string, number>) => {
	// Should return an just one amount. all amounts should be in the same currency
	const fund = store.getState().authentication.authUserFund
	const fundCurrency = fund?.currency || 'USD'

	if (!amounts) return { amount: 0, currency: fundCurrency }

	const totalAmount = Object.entries(amounts).reduce(
		(acc, [currency, amount]) => {
			const exchangeRate = fund?.fundExchangeRates[currency]?.[fundCurrency]
			if (!exchangeRate) return acc

			return acc + amount * exchangeRate
		},
		0
	)

	return { amount: totalAmount, currency: fundCurrency }
}

export const displayAmount = (amount?: Amount, shouldConvert = true) => {
	const fund = store.getState().authentication.authUserFund
	const currency = fund?.currency || 'USD'

	if (!amount) return currencyFormatter(currency).format(0)
	if (shouldConvert) {
		return currencyFormatter(currency).format(getConvertedAmount(amount).amount)
	}
	return currencyFormatter(amount.currency).format(amount.amount)
}
