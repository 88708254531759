/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { format } from 'date-fns'
import { EmailPeopleHoverCard } from 'features/mail/components/EmailPeopleHoverCard'
import type { IEmailMessage } from 'features/mail/interfaces/email.interface'
import AllEmailComponent from 'features/mail/views/EmailDetails'
import { useState } from 'react'
import { purifyHtml } from 'utils/purifyHtml'


export default function EmailItem({
  email,
  defaultExpanded = false,
}: {
  email: IEmailMessage
  defaultExpanded?: boolean
}) {
  const [showDetails, setShowDetails] = useState(defaultExpanded)

  return (
    <div className='w-full '>
      <div className='flex flex-col border rounded-md p-2'>
        <div
          id='email-in-thread'
          onClick={() => setShowDetails(!showDetails)}
          className='flex w-full cursor-pointer items-center justify-between gap-5 '
        >
          <div className='flex items-center gap-2'>
            <div className='flex h-8 w-8 items-center justify-center rounded-full bg-blue-200 text-gray-500'>
              {(email.from.name || email.from.email).charAt(0)}
            </div>
            <div className=''>
              <div className='truncate text-xs text-gray-700'>
                <EmailPeopleHoverCard variant='sender' email={email} />
              </div>
              <div className=' text-xs text-gray-700'>{email.from.email}</div>
            </div>
          </div>

          <div className='text-xs'>
            {format(new Date(email.createdAt), 'MMM dd, yyyy hh:mm a')}
          </div>
        </div>
        {!showDetails && (
          <div
            dangerouslySetInnerHTML={{
              __html: purifyHtml(email.snippet || email.textPlain)
            }}
            className='w-full mt-3 px-2 py-0.5 text-xs text-gray-500'
          />
        )}
      </div>
      {showDetails && (
        <div className='w-full'>
          <AllEmailComponent
            data={email}
            wrapperClass='w-full rounded-none'
          />
        </div>
      )}
    </div>
  )
}
