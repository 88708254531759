import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import CustomColumnAPI from 'features/dataTable/api/columns.api'
import type { ILimitedPartner } from 'interfaces'
import { LPService } from '../service/lp.service'
import LimitedPartnerTeamMembers from './LimitedPartnerTeamMembers'
import SpokAmountInput from 'components/shadcn/SpokAmountInput'
import SpokInlineInputEditor from 'components/shadcn/SpokInlineInputEditor'
import TagSelector from 'features/tags/view/TagSelector'
import Tags from 'components/Tags'
import UpdateLocation from 'features/companies/view/UpdateLocation'
import { renderDate } from 'utils/date.util'
import useLimitedPartnerFunnels from '../hooks/useLPFunnels'

interface Props {
	limitedPartner: ILimitedPartner
	isReadOnly: boolean
}

function formatDate(dateString: Date) {
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'short',
		day: '2-digit'
	}
	const formattedDate = new Date(dateString)
		.toLocaleDateString('en-US', options)
		.replace(',', '')
	return `${formattedDate.slice(0, -4)}, ${formattedDate.slice(-4)}`
}

export default function LimitedPartnerDetailsInfo({
	limitedPartner,
	isReadOnly
}: Props) {
	const { listenToColumns, columns, upsertCustomField } = CustomColumnAPI()

	const { authUser } = useContext(AuthContext)
	const { funnels } = useLimitedPartnerFunnels()

	const [totalEmails, setTotalEmails] = useState(0)
	const [lastMeeting, setLastMeeting] = useState<string | null>(null)

	useEffect(() => {
		if (!authUser?.id) return

		const fetchEmails = async () => {
			const count = await LPService.getTotalEmails(authUser.id, limitedPartner)
			setTotalEmails(count)
		}

		fetchEmails()
	}, [limitedPartner, authUser?.id])

	useEffect(() => {
		const fetchLastMeeting = async () => {
			const meeting = await LPService.getLastMeeting(limitedPartner)
			setLastMeeting(meeting)
		}

		fetchLastMeeting()
	}, [limitedPartner])

	useEffect(() => {
		const unsubscribe = listenToColumns('prospect')
		return () => unsubscribe()
	}, [])

	return (
		<div data-testid='limited-partner-info-details'>
			<div className='rounded-md'>
				<div className='grid grid-cols-2 gap-5 bg-white p-5 xl:grid-cols-3  2xl:grid-cols-4'>
					{Object.entries({
						status: {
							label: 'Status',
							value: (
								<div>
									<ComboSelect
										onSelect={value => {
											LPService.updateLP({
												id: limitedPartner.id,
												key: 'totalCommitted',
												value: { amount: 0, currency: 'USD' },
												lp: limitedPartner,
												shouldLog: false
											})

											LPService.updateLP({
												id: limitedPartner.id,
												key: 'isApproved',
												value: value === 'limited-parter',
												lp: limitedPartner
											})
										}}
										defaultValue={
											limitedPartner.isApproved ? 'limited-parter' : 'prospect'
										}
										content={
											<Button className='flex items-center gap-2 bg-blue-500 pr-2'>
												<div className='text-sm'>
													{limitedPartner.isApproved
														? 'Limited Partner'
														: 'Prospect'}
												</div>
											</Button>
										}
										options={[
											{ value: 'limited-parter', label: 'Limited Partner' },
											{ value: 'prospect', label: 'Prospect' }
										]}
									/>
								</div>
							)
						},
						timeline: {
							label: 'Status',
							visible: !limitedPartner.isApproved,
							value: (
								<div>
									<ComboSelect
										onSelect={value => {
											const funnel = funnels.find(f => f.id === value)
											LPService.updateLP({
												id: limitedPartner.id,
												key: 'funnel',
												value: funnel,
												lp: limitedPartner
											})
										}}
										defaultValue={limitedPartner.funnel.id}
										content={
											<div className='flex items-center gap-2 pr-2'>
												<div className='text-sm'>
													{limitedPartner.funnel.name}
												</div>
											</div>
										}
										options={funnels.map(funnel => ({
											value: funnel.id,
											label: funnel.name
										}))}
									/>
								</div>
							)
						},
						tags: {
							label: 'Tags',
							value: (
								<div className='flex flex-col gap-2 border-b pb-2'>
									<Tags
										onTagDelete={tag =>
											LPService.removeTag(limitedPartner, tag)
										}
										tags={limitedPartner.tags ?? []}
										variant='default'
										maxNumber={2}
										wrap
									/>
									<TagSelector
										tags={limitedPartner.tags ?? []}
										onSave={tags => {
											LPService.updateLP({
												id: limitedPartner.id,
												key: 'tags',
												value: tags,
												lp: limitedPartner
											})
											LPService.updateLP({
												id: limitedPartner.id,
												key: 'tagIds',
												value: tags.map(t => t.id),
												lp: limitedPartner,
												shouldLog: false
											})
										}}
									/>
								</div>
							)
						},
						location: {
							label: 'Location',
							value: (
								<UpdateLocation
									location={limitedPartner.location}
									onUpdate={location => {
										LPService.updateLP({
											id: limitedPartner.id,
											key: 'location',
											value: location,
											lp: limitedPartner
										})
									}}
									title='Location'
									description='Update limited partner location'
									id='lp-location'
								/>
							)
						},
						totalCommitted: {
							label: 'Total Committed',
							value: (
								<SpokAmountInput
									id='total-committed'
									title='Total Committed'
									onSave={value => {
										LPService.updateLP({
											id: limitedPartner.id,
											key: 'totalCommitted',
											value: {
												currency: value?.currency,
												amount: value?.amount
											},
											lp: limitedPartner,
											activityKey: 'updated total committed',
											activityDescription: `Updated total committed to ${value?.currency}${value?.amount}`
										})
									}}
									defaultValue={limitedPartner.totalCommitted}
								/>
							)
						},
						website: {
							label: 'Website',
							value: (
								<SpokInlineInputEditor
									id='website'
									onSave={value => {
										LPService.updateLP({
											id: limitedPartner.id,
											key: 'website',
											value,
											lp: limitedPartner
										})
									}}
									defaultValue={limitedPartner.website || '-'}
								/>
							)
						},
						totalEmails: {
							label: 'Total Emails',
							value: totalEmails
						},
						linkedIn: {
							label: 'LinkedIn',
							value: (
								<SpokInlineInputEditor
									id='linkedin'
									onSave={value => {
										LPService.updateLP({
											id: limitedPartner.id,
											key: 'linkedIn',
											value,
											lp: limitedPartner
										})
									}}
									defaultValue={limitedPartner.linkedIn || '-'}
								/>
							)
						},
						lastMeeting: {
							label: 'Last Meeting',
							value: lastMeeting
								? new Date(lastMeeting).toLocaleDateString()
								: '-'
						},
						source: {
							label: 'Source',
							value: (
								<SpokInlineInputEditor
									id='source'
									onSave={value => {
										LPService.updateLP({
											id: limitedPartner.id,
											key: 'source',
											value,
											lp: limitedPartner
										})
									}}
									defaultValue={limitedPartner.source || '-'}
								/>
							)
						},
						industry: {
							label: 'Industry',
							value: (
								<SpokInlineInputEditor
									id='industry'
									onSave={value => {
										LPService.updateLP({
											id: limitedPartner.id,
											key: 'industry',
											value,
											lp: limitedPartner
										})
									}}
									defaultValue={limitedPartner.industry || '-'}
								/>
							)
						},
						category: {
							label: 'Category',
							value: (
								<SpokInlineInputEditor
									id='category'
									onSave={value => {
										LPService.updateLP({
											id: limitedPartner.id,
											key: 'category',
											value,
											lp: limitedPartner
										})
									}}
									defaultValue={limitedPartner.category || '-'}
								/>
							)
						},
						dateCreated: {
							label: 'Date Added',
							value: (
								<div className='text-sm'>
									{renderDate(limitedPartner.createdAt) || '-'}
								</div>
							)
						}
					}).map(([field, { label, value, visible }]) => {
						if (visible === false) return null
						return (
							<div data-testid={`limited-details-${field}`} key={field}>
								<div className='text-sm font-semibold text-blue-700'>
									{label}
								</div>
								<div>{value}</div>
							</div>
						)
					})}

					{columns.map(col => {
						return (
							<div key={col.index}>
								<p className='text-sm font-semibold text-blue-700'>
									{col.name}
								</p>
								{(col.dataType === 'custom_dropdown' ||
									col.dataType === 'custom_label') && (
									<ComboSelect
										isReadOnly={isReadOnly}
										onSelect={async value => {
											await upsertCustomField(
												limitedPartner,
												col.name,
												value,
												'partners'
											)
										}}
										defaultValue={
											limitedPartner.custom_fields?.[col.key] || '-'
										}
										content={
											<div className='flex items-center gap-2 pr-2'>
												<div className='text-sm'>
													{limitedPartner.custom_fields?.[col.key] ?? '-'}
												</div>
											</div>
										}
										options={col.options.map(opt => ({
											label: opt,
											value: opt
										}))}
									/>
								)}

								{col.dataType === 'custom_text' && (
									<SpokInlineInputEditor
										isReadOnly={isReadOnly}
										title={col.key}
										id={col.key}
										onSave={async value => {
											await upsertCustomField(
												limitedPartner,
												col.key,
												value,
												'partners'
											)
										}}
										defaultValue={
											limitedPartner.custom_fields?.[col.key] || '-'
										}
									/>
								)}

								{col.dataType === 'custom_numbers' && (
									<SpokInlineInputEditor
										inputType='number'
										isReadOnly={isReadOnly}
										title={col.key}
										id={col.key}
										onSave={async value => {
											await upsertCustomField(
												limitedPartner,
												col.key,
												value,
												'partners'
											)
										}}
										defaultValue={
											limitedPartner.custom_fields?.[col.key] || '-'
										}
									/>
								)}

								{col.dataType === 'custom_date' && (
									<SpokInlineInputEditor
										inputType='date'
										title={col.key}
										id={col.key}
										onSave={async value => {
											await upsertCustomField(
												limitedPartner,
												col.key,
												value,
												'partners'
											)
										}}
										defaultValue={
											limitedPartner.custom_fields?.[col.key]
												? formatDate(limitedPartner.custom_fields[col.key])
												: '-'
										}
									/>
								)}

								{col.dataType === 'custom_textarea' && (
									<SpokInlineInputEditor
										isReadOnly={isReadOnly}
										multiLine
										title={col.key}
										id={col.key}
										onSave={async value => {
											await upsertCustomField(
												limitedPartner,
												col.key,
												value,
												'partners'
											)
										}}
										defaultValue={
											limitedPartner.custom_fields?.[col.key] || '-'
										}
									/>
								)}
							</div>
						)
					})}
				</div>
			</div>
			<LimitedPartnerTeamMembers limitedPartner={limitedPartner} />
		</div>
	)
}
