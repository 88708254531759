import { MailSearch } from "lucide-react";
import { ImSpinner10 } from "react-icons/im";

export default function LoadingEmails() {

  return (
    <div className='mt-20'>
      <div className='flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center gap-2'>
          <div className='text-3xl'>
            <MailSearch />
          </div>
          <div className='flex items-center gap-3 text-center text-xl text-gray-600'>
            <ImSpinner10 className='animate-spin' />
            Loading Emails...
          </div>
        </div>
      </div>
    </div>
  )
}