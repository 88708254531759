/* eslint-disable react-hooks/exhaustive-deps */
import Button from "components/Buttons/Button"
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip"
import { AuthContext } from "context/AuthContext"
import { Bell } from "lucide-react"
import { useContext, useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ENotificationStatus } from "../enums/notifications.enums"
import { selectNotifications } from "../redux/notifications.slice"
import { NotificationsService } from "../service/Notifications.service"

export default function NotificationsInProgressHeader() {

  const { authUser } = useContext(AuthContext)
  const { data: notifications } = useSelector(selectNotifications)
  const navigation = useNavigate()

  const notificationsInProgress = notifications.filter((notification) => notification.status === ENotificationStatus.PROGRESS)

  useEffect(() => {
    if (!authUser) return
    NotificationsService.listenToNotifications({ userId: authUser.id, fundId: authUser.fund.id })
  }, [authUser?.id, authUser?.fund.id])


  return (
    <div>
      {notificationsInProgress.length > 0 ? <div>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              className="px-2"
              onClick={() => {
                navigation('/notifications')
              }}
            >
              <Bell />
              <div className="rounded-full bg-blue-100 border font-semibold border-blue-900 text-blue-900 absolute top-1 right-1 text-xs w-4 h-4 flex items-center justify-center">
                {notificationsInProgress.length > 9 ? '9+' : notificationsInProgress.length}
              </div>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <>{notificationsInProgress.length} notification{notificationsInProgress.length > 1 ? 's' : ''} in progress</>
          </TooltipContent>
        </Tooltip>
      </div> : <Button
        variant="ghost"
        className="px-2"
        onClick={() => {
          navigation('/notifications')
        }}
      >
        <Bell size={20} />
      </Button>
      }
    </div>
  )

}