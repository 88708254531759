/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */

import { Kanban, ListTree } from 'lucide-react'
import {
	selectFunnelStatus,
	selectProspectFunnels
} from 'features/funnels/funnels.slice'
import { selectProspectStatus, selectProspects } from '../prospects.slice'
import { updateLP, updateLPs } from 'features/limited_partners/api/lps.api'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'
import type { Column } from 'features/kanban/views/KanbanView'
import CreateProspect from '../components/CreateProspect'
import KanbanView from 'features/kanban/views/KanbanView'
import LPCard from './LpCard'
import ProspectsList from './ProspectList'
import { listenToColumns } from 'features/columns/columns.api'
import { listenToFunnels } from 'features/funnels/funnels.api'
import { listenToProspects } from '../prospects.api'
import logger from 'lib/logger'
import { useAppSelector } from 'app/hooks'
import useTabs from 'components/hooks/useTabs'

const tabs = [
	{
		name: 'Board',
		value: 'board',
		icon: <Kanban size={15} />,
		disabled: false
	},
	{
		name: 'List',
		value: 'list',
		icon: <ListTree size={15} />,
		disabled: false
	}
]

export default function AllProspects(): JSX.Element {
	const { authUser } = useContext(AuthContext)
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const group = searchParams.get('space') || 'all'

	if (group === 'limited-partners') {
		navigate('/limited-partners', { replace: true })
	}

	if (group === 'capital-call') {
		navigate('/capital-call', { replace: true })
	}

	const { Tabs, activeTab } = useTabs({
		tabs,
		containerClassName: '',
		urlPathName: 'view'
	})

	const [boardColumns, setBoardColumns] = useState<Column[]>([])

	const prospects = useAppSelector(selectProspects)
	const isProspectsLoading = useAppSelector(selectProspectStatus)
	const funnels = useAppSelector(selectProspectFunnels)
	const isFunnelLoading = useAppSelector(selectFunnelStatus)

	useEffect(() => {
		const columns = funnels
			.filter(funnel => !funnel.isArchived)
			.filter(funnel => {
				return !group || group === 'all' ? true : funnel.category === group
			})
			.map(funnel => {
				const column: Column = {
					id: funnel.id,
					title: funnel.name,
					index: funnel.index,
					items: prospects.filter(prospect => prospect.funnel.id === funnel.id),
					pureItems: prospects.filter(
						prospect => prospect.funnel.id === funnel.id
					)
				}
				return column
			})
		setBoardColumns(columns)
	}, [group, prospects, isProspectsLoading, isFunnelLoading])

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubFunnels = listenToFunnels(authUser.fund.id)
		const unsubscribe = listenToProspects(authUser.fund.id)
		const unsubscribe2 = listenToColumns(authUser.fund.id)

		return () => {
			unsubscribe()
			unsubscribe2()
			unsubFunnels()
		}
	}, [authUser?.fund.id])

	return (
		<div>
			<div className='flex w-full items-center justify-between border-b pr-5'>
				<div>
					<div className='flex w-full items-center px-5'>
						<Tabs />
					</div>
				</div>
				<div className='flex items-center gap-2'>
					<div className='flex items-center gap-2 border-l pl-3'>
						<CreateProspect />
					</div>
				</div>
			</div>
			<div className='px-5'>
				{activeTab.value === 'list' && (
					<ProspectsList
						lps={prospects.filter(company => {
							return group === 'all'
								? true
								: funnels.find(funnel => funnel.id === company.funnel?.id)
										?.category === group
						})}
						loading={isProspectsLoading}
					/>
				)}
				{activeTab.value === 'board' && (
					<KanbanView
						allowSpaces
						workspaces={['prospects']}
						columns={boardColumns}
						loading={isFunnelLoading}
						itemsLoading={isProspectsLoading}
						allowSearch
						renderItem={item => {
							return <LPCard key={item.item.id} prospect={item.item} />
						}}
						onItemSameColumnMove={items => {
							updateLPs(items)
						}}
						onItemDifferentColumnMove={({ item, dropResult }) => {
							updateLP({
								...item,
								funnel: {
									id: funnels.find(
										funnel => funnel.id === dropResult.destination?.droppableId
									)?.id,
									name: funnels.find(
										funnel => funnel.id === dropResult.destination?.droppableId
									)?.name,
									category: funnels
										.find(
											funnel =>
												funnel.id === dropResult.destination?.droppableId
										)
										?.name.toLowerCase()
								},
								lastUpdatedAt: new Date().toString()
							})
						}}
						onColumnAdd={(column: Column) => {
							logger.info(column)
						}}
						entity='prospect'
						group={group || 'all'}
					/>
				)}
			</div>
		</div>
	)
}
