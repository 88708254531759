import {
	ECustomCompanyFormFieldType,
	type ICustomCompanyFormTemplate
} from '../interfaces/company.interface'
import * as Yup from 'yup'

const getValidationSchema = (template?: ICustomCompanyFormTemplate) => {
	const initialValues: Record<string, unknown> = {}
	const validationSchema: Record<string, Yup.ISchema<unknown>> = {}
	if (!template) {
		return { initialValues, validationSchema }
	}

	for (const section of template.sections) {
		for (const field of section.fields) {
			if (field.isRequired) {
				switch (field.type) {
					case ECustomCompanyFormFieldType.Link: {
						validationSchema[section.isSystem ? field.field : field.id] =
							Yup.string()
								.trim()
								.url('Invalid url format')
								.required(`${field.label} is required`)
						initialValues[section.isSystem ? field.field : field.id] = ''
						break
					}

					case ECustomCompanyFormFieldType.File: {
						validationSchema[section.isSystem ? field.field : field.id] =
							Yup.object().required('Upload file')
						initialValues[section.isSystem ? field.field : field.id] = null
						break
					}

					case ECustomCompanyFormFieldType.MultiSelect:
					case ECustomCompanyFormFieldType.Tag: {
						validationSchema[section.isSystem ? field.field : field.id] =
							Yup.array(Yup.string())
								.min(1, `Select at least one tag`)
								.required(`${field.label} is required`)
						initialValues[section.isSystem ? field.field : field.id] = []
						break
					}

					case ECustomCompanyFormFieldType.RichEditor: {
						validationSchema[section.isSystem ? field.field : field.id] =
							Yup.string()
								.test(
									'is-not-empty-html',
									`${field.label} is required`,
									value => {
										if (typeof value !== 'string') return false

										const strippedContent = value
											.replaceAll(/<\/?[^>]+(>|$)/g, '')
											.trim()

										return strippedContent.length > 0
									}
								)
								.required(`${field.label} is required`)

						initialValues[section.isSystem ? field.field : field.id] = []
						break
					}

					default: {
						validationSchema[section.isSystem ? field.field : field.id] =
							Yup.string().trim().required(`${field.label} is required`)
						initialValues[section.isSystem ? field.field : field.id] = ''
					}
				}
			} else {
				validationSchema[section.isSystem ? field.field : field.id] =
					Yup.string().optional()
			}
		}
	}

	return {
		initialValues,
		validationSchema
	}
}

export default getValidationSchema
