import type { CompanyData, IContact } from 'interfaces'

import { CircleSlash2 } from 'lucide-react'
import CompanyHoverCard from 'components/CompanyHoverCard'

function RelationList({
	companyList,
	title,
	description
}: {
	companyList: CompanyData[]
	title: string
	description?: string
}) {
	return (
		<div className=' flex flex-col gap-2 border rounded-md w-fit p-2'>
			<div className='flex flex-col'>
				<div className='text-blue-700 font-semibold'>{title}</div>
				<div className='text-gray-700 text-sm'>{description}</div>
			</div>
			<div className='flex items-center gap-2 text-sm'>
				{companyList.map(company => (
					<CompanyHoverCard key={company.id} company={company} />
				))}
			</div>
			{companyList.length === 0 && (
				<div className='flex w-fit items-center gap-1 rounded bg-gray-100 px-3 py-1 text-xs text-gray-800'>
					{' '}
					<CircleSlash2 size={12} /> No Relation
				</div>
			)}
		</div>
	)
}

export default function ContactRelations({ contact }: { contact: IContact }) {
	return (
		<div data-testid='contact-relations' className='borer rounded-md bg-white border'>
			<div className='font font-semibold text-blue-700 border-b p-2'>Relations</div>
			<div className='flex flex-wrap gap-2 px-2 py-2'>
				<RelationList
					title='Founder'
					description='in these companies'
					companyList={contact.relations?.foundedCompanies ?? []}
				/>
				<RelationList
					title='Relevant companies'
					description='by tags'
					companyList={contact.relations?.relevantCompanies ?? []}
				/>
				<RelationList
					title='Relevant companies'
					description='by countries'
					companyList={contact.relations?.companiesInCountry ?? []}
				/>
			</div>
		</div>
	)
}
