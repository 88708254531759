import { Checkbox } from "components/ui/checkbox";
import { useEffect, useState } from "react";
import { capitalCallRequestDataToFilterMapper } from "../helpers/capitalCall.helper";
import type { ICapitalCallRequestMap } from "../interfaces/capitalCall.interface";

interface Props {
  filter: (years: number[]) => void,
  data?: ICapitalCallRequestMap
}

type FilterProps = Record<number, {isChecked: boolean, total: number}>

export default function CapitalCallSummaryFilter(
  {
    data,
    filter
  }: Props
): JSX.Element {
  const [capitalCallFilter, setCapitalCallFilter] = useState<FilterProps>();

  useEffect(() => {
      if(data)
        setCapitalCallFilter({...capitalCallRequestDataToFilterMapper(data, capitalCallFilter)})
   }, [data, capitalCallFilter])

  function onYearCheckedChange(year: number): void {
    if(!capitalCallFilter) return;

    const yearItem = capitalCallFilter[year];

    yearItem.isChecked = !yearItem.isChecked;

    setCapitalCallFilter({...capitalCallFilter});
    
    filter(Object.entries(capitalCallFilter).filter(([,value]) => !value.isChecked).map(([index]) => Number(index)));
  }

  return (
    <div className='bg-white border border-blue-100 flex flex-col rounded-lg shadow-lg'>
      <div>
        
        {
          capitalCallFilter && Object.entries(capitalCallFilter).map(([key, value]) => (
            <div className="flex justify-between py-1 px-5 border-b-[1px]" key={key}>
              <div>
                <Checkbox
                  label={key}
                  checked={value.isChecked}
                  aria-label={key}
                  onCheckedChange={() => onYearCheckedChange(Number(key))}
                />
              </div>
              <div className="text-blue-400">
                {value.total}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
