/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
	Dialog,
	DialogPanel,
	Transition,
	TransitionChild
} from '@headlessui/react'
import { Fragment, useEffect } from 'react'

import { purifyHtml } from 'utils/purifyHtml'

interface Props {
	title?: string
	description?: string
	children: React.ReactNode
	trigger?: React.ReactNode
	open?: boolean
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>
	onClose?: () => void
	size?: string
	triggerClassName?: string
}

export function CustomModal({
	title,
	description,
	children,
	trigger,
	open = false,
	setOpen,
	onClose,
	size = 'sm:max-w-2xl',
	triggerClassName
}: Props) {
	useEffect(() => {
		if (!open) onClose?.()
	}, [open])

	const handleOpen = () => {
		setOpen?.(true)
	}

	const handleClose = () => {
		setOpen?.(false)
		onClose?.()
	}

	return (
		<>
			<div className={triggerClassName} onClick={handleOpen}>
				{trigger}
			</div>

			<Transition appear show={open} as={Fragment}>
				<Dialog as='div' className='relative z-20' onClose={handleClose}>
					<TransitionChild
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-black bg-opacity-50' />
					</TransitionChild>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<TransitionChild
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<DialogPanel
									className={`w-full transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all ${size}`}
								>
									{title && (
										<div className='mb-4 flex items-center justify-between'>
											<h2
												dangerouslySetInnerHTML={{ __html: purifyHtml(title) }}
												className='text-xl font-semibold'
											/>
											<button
												className='text-xl font-semibold'
												onClick={handleClose}
											>
												&times;
											</button>
										</div>
									)}
									{description && <p className='mb-4'>{description}</p>}
									<div>{children}</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}
