/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppSelector } from 'app/hooks'
import { store } from 'app/store'
import clsx from 'clsx'
import Skeleton from 'components/Skeleton'
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger
} from 'components/ui/dropdown-menu'
import { ScrollArea } from 'components/ui/scroll-area'
import _ from 'lodash'
import { useEffect } from 'react'
import { renderDateAndTime, renderTime } from 'utils/date.util'
import { listenToCalendarEvents } from '../api/calender_events.api'
import { getCalendarEventStatus } from '../helpers/calendar.helper'
import { setCalendarEvents } from '../redux/calendar_events.slice'

interface Props {
	emails: string[]
}

export default function CalendarEvents({ emails }: Props) {
	const { isLoading, data } = useAppSelector(state => state.calendarEvents)
	const userEvents = _.groupBy(data, 'user.id')

	useEffect(() => {
		if (emails.length === 0) {
			store.dispatch(setCalendarEvents([]))
			return
		}

		const unsubscribe = listenToCalendarEvents(emails)
		return () => unsubscribe()
	}, [])

	if (isLoading) {
		return (
			<div>
				<div className='font font-semibold text-blue-700'>Calendar Events</div>
				<div className='flex items-center gap-3'>
					<Skeleton className='h-8 w-8 rounded-full border' />
					<Skeleton className='h-5 w-20 border' />
				</div>
			</div>
		)
	}

	if (data.length === 0) {
		return (
			<div>
				<div className='font font-semibold text-blue-700'>Calendar Events</div>
				<div className='flex items-center gap-3 pb-2'>
					<div>No events found</div>
				</div>
			</div>
		)
	}

	return (
		<div>
			<div className='font font-semibold text-blue-700'>Calendar Events</div>
			<section className='flex flex-wrap items-center gap-3'>
				{Object.keys(userEvents).map(userId => (
					<DropdownMenu key={userId}>
						<DropdownMenuTrigger asChild>
							<div
								className={clsx(
									'flex cursor-pointer items-center gap-1',
									userEvents[userId].length > 1 && 'rounded-md '
								)}
							>
								<Avatar className='h-8 w-8 rounded-full border'>
									{userEvents[userId][0].user.photoUrl && (
										<AvatarImage
											src={
												userEvents[userId][0].user.photoUrl ||
												'/images/logo/Logo-4.png'
											}
										/>
									)}
									<AvatarFallback>
										{userEvents[userId][0].user.name[0]}
									</AvatarFallback>
								</Avatar>
								<div className='flex flex-wrap gap-5 text-sm font-semibold'>
									{userEvents[userId].length > 1
										? `x${userEvents[userId].length}`
										: ``}
								</div>
							</div>
						</DropdownMenuTrigger>
						<DropdownMenuContent align='end' className='ml-20 w-[450px]'>
							<DropdownMenuLabel>Calendar Events</DropdownMenuLabel>
							<DropdownMenuSeparator />
							<ScrollArea className='flex h-96 w-full flex-col gap-3'>
								{userEvents[userId].map(event => (
									<div
										key={event.id}
										className='mb-3 flex flex-col items-center gap-2 rounded-md border-b p-2'
									>
										<div className='flex w-full items-center justify-start gap-2'>
											<div
												className={clsx(
													'h-3 w-3 rounded-full',
													getCalendarEventStatus(event) === 'upcoming' &&
														'bg-green-500',
													getCalendarEventStatus(event) === 'past' &&
														'bg-gray-500',
													getCalendarEventStatus(event) === 'live' &&
														'bg-blue-500'
												)}
											/>
											<div className='line-clamp-2 flex-1 text-sm font-semibold text-blue-700'>
												{event.summary}
											</div>
										</div>
										<div className='flex w-full items-center gap-2'>
											<Avatar className='h-8 w-8 rounded-full border'>
												{event.user.photoUrl && (
													<AvatarImage
														src={
															event.user.photoUrl || '/images/logo/Logo-4.png'
														}
													/>
												)}
												<AvatarFallback>{event.user.name[0]}</AvatarFallback>
											</Avatar>
											<div className='text-xs font-semibold text-gray-600'>
												{renderDateAndTime(event.start.dateTime)} -{' '}
												{renderTime(event.end.dateTime)}
											</div>
										</div>
										<div className='w-full'>
											{event.attendees.map(attendee => (
												<div key={attendee.email} className='text-xs'>
													{attendee.email}
												</div>
											))}
										</div>
									</div>
								))}
							</ScrollArea>
						</DropdownMenuContent>
					</DropdownMenu>
				))}
			</section>
		</div>
	)
}
