import type {
	CompanyData,
	ContactData,
	FundData,
	LimitedPartnerData,
	UserData
} from 'interfaces'

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface INote {
	id: string
	title?: string
	creator: UserData
	fund: FundData
	type: string
	isPinned: boolean
	company?: CompanyData
	limitedPartner?: LimitedPartnerData
	contact?: ContactData
	note: string
	windowState?: NoteEditorState
	isDraft: boolean
	noteType: NoteType
	date?: string
	createdAt: string
	lastUpdatedAt: string
}

export interface IDraftNote {
	id: string
	title?: string
	fund: FundData
	creator: UserData
	note?: string
	windowState?: NoteEditorState
	company?: CompanyData
	isPinned?: boolean
	limitedPartner?: LimitedPartnerData
	contact?: ContactData
	noteType: NoteType
	date?: string
	createdAt?: string
}
export interface NotesSliceState {
	notes: INote[]
}

// export type WindowState = 'maximize' | 'minimize' | 'restore'

export enum NoteType {
	Company = 'company',
	Contact = 'contact',
	Meeting = 'meeting',
	LimitedPartner = 'limitedPartner'
}

export enum NoteEditorState {
	CLOSED = 'closed',
	MAXIMIZED = 'maximized',
	MINIMIZED = 'minimized',
	RESTORED = 'restored'
}

export enum NoteAction {
	Pin = 'pin',
	CreateTask = 'create-task',
	Delete = 'delete'
}
