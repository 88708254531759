/* eslint-disable react-hooks/exhaustive-deps */
import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import { Input } from 'components/ui/input'
import type { Founder, ICompany } from 'interfaces'
import { Pencil, PlusCircle } from 'lucide-react'
import { useEffect, useState } from 'react'
import useUpdateFounders from '../hooks/useUpdateFounders'

export default function AddFounder({
	company,
	founder
}: {
	company: ICompany
	founder?: Founder
}) {
	const [isOpen, setIsOpen] = useState(false)
	const { formik } = useUpdateFounders({
		company,
		founder,
		onSuccess: () => {
			setIsOpen(false)
		}
	})

	function openModal() {
		setIsOpen(true)
	}

	useEffect(() => {
		if (!isOpen) {
			formik.resetForm()
		}
	}, [isOpen])

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title={founder ? 'Edit Founder' : 'Add Founder'}
			description={
				founder
					? `Edit founder to this company`
					: `Add new founder to this company`
			}
			trigger={
				<Button onClick={openModal} variant='ghost' className=' p-0'>
					{founder ? (
						<div>
							<Pencil size={15} />
						</div>
					) : (
						<div className='flex cursor-pointer items-center rounded-full'>
							<PlusCircle size={15} />
						</div>
					)}
				</Button>
			}
		>
			<div className=''>
				<div className='overflow-hidden'>
					<div className='relative bg-white'>
						<div className='mb-4'>
							<div className='mb-2 block text-sm font-bold text-gray-700'>
								Name <span className='text-red-500'>*</span>
							</div>
							<Input
								id='founder-name'
								name='name'
								type='text'
								value={formik.values.name}
								error={formik.errors.name}
								onChange={formik.handleChange}
							/>
						</div>
						<div className='mb-4'>
							<div className='mb-2 block text-sm font-bold text-gray-700'>
								LinkedIn
							</div>
							<Input
								id='founder-linkedIn'
								name='linkedIn'
								type='text'
								value={formik.values.linkedIn}
								onChange={formik.handleChange}
							/>
						</div>
						<div className='mb-4'>
							<div className='mb-2 block text-sm font-bold text-gray-700'>
								Email <span className='text-red-500'>*</span>
							</div>
							<Input
								id='founder-email'
								name='email'
								type='email'
								value={formik.values.email}
								error={formik.errors.email}
								onChange={formik.handleChange}
							/>
						</div>
						<Button
							id='add-company-founder-submit'
							disabled={formik.isSubmitting || !formik.isValid}
							isLoading={formik.isSubmitting}
							onClick={() => {
								formik.handleSubmit()
							}}
							className='w-fit'
							type='submit'
						>
							{founder ? 'Edit' : 'Add'} Founder
						</Button>
					</div>
				</div>
			</div>
		</DialogModal>
	)
}
