import { useLayoutEffect, useState } from 'react'

import { parseInt } from 'lodash'

// eslint-disable-next-line import/prefer-default-export
export function useMediaQuery(query: string): boolean {
	const [matches, setMatches] = useState(() => matchMedia(query).matches)

	useLayoutEffect(() => {
		const mediaQuery = matchMedia(query)

		function onMediaQueryChange(): void {
			setMatches(mediaQuery.matches)
		}

		mediaQuery.addEventListener('change', onMediaQueryChange)

		return (): void => {
			mediaQuery.removeEventListener('change', onMediaQueryChange)
		}
	}, [query])

	return matches
}

export const readFile = (
	file: File,
	onFileRead: (evt: ProgressEvent<FileReader>) => void
) => {
	const reader = new FileReader()
	// eslint-disable-next-line unicorn/prefer-add-event-listener
	reader.onload = onFileRead
	reader.readAsDataURL(file)
}

export async function convertStringToNanoID(input: string): Promise<string> {
	const encoder = new TextEncoder()
	const data = encoder.encode(input)
	const hashBuffer = await crypto.subtle.digest('SHA-256', data)
	const hashArray = [...new Uint8Array(hashBuffer)] // Convert buffer to byte array
	const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
	const base64 = btoa(hashHex)
		.replaceAll('+', '-') // Replace '+' with '-'
		.replaceAll('/', '_') // Replace '/' with '_'
		.replace(/=+$/, '') // Remove trailing '='

	// Trim or pad the base64 string to make it a fixed size similar to nanoid (21 characters)
	return base64.slice(0, 21)
}

export function formatTime(seconds: number): string {
	const time = parseInt(seconds.toString())
	const hours = Math.floor(time / 3600) // Get total hours
	const minutes = Math.floor((time % 3600) / 60) // Get remaining minutes
	const remainingSeconds = time % 60 // Get remaining seconds

	// Prepare parts for the formatted time
	const parts: string[] = [
		hours > 0 ? hours.toString().padStart(2, '0') : null, // Include hours only if > 0
		minutes.toString().padStart(2, '0'), // Always include minutes, pad to 2 digits
		remainingSeconds.toString().padStart(2, '0') // Always include seconds, pad to 2 digits
	].filter(part => part !== null) // Filter out null values (no hours)

	// Join parts with a colon to form the final string
	return parts.join(':')
}


  export const secondsToHms = (duration: string) => {
    const d = Number(duration);
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);

    const hDisplay = h > 0 ? String(h).padStart(2, '0') : '00';
    const mDisplay = m > 0 ? String(m).padStart(2, '0') : '00';
    const sDisplay = s > 0 ? String(s).padStart(2, '0') : '00';
    return `${hDisplay}:${mDisplay}:${sDisplay}`;
  };