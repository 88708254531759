/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { store } from 'app/store'
import type { Unsubscribe } from 'firebase/firestore'
import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	limit,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	where
} from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'
import type { IInvestment } from '../interfaces/investment.interface'
import {
	setInvestments,
	setInvestmentsError,
	setInvetsmentsLoading
} from '../redux/investments.slice'

export const investmentsRef = collection(firestore, 'investments')

export const addInvestment = async (investment: IInvestment) => {
	const docRef = doc(investmentsRef, investment.id)
	await setDoc(docRef, investment, { merge: true })
}

export const updateInvestment = async (investment: IInvestment) => {
	const docRef = doc(investmentsRef, investment.id)
	await updateDoc(docRef, investment as any, { merge: true })
}

export const deleteInvestment = async (investmentId: string) => {
	const docRef = doc(investmentsRef, investmentId)
	await deleteDoc(docRef)
}

export const getInvestments = async (
	companyId: string,
	max = 50
): Promise<IInvestment[]> => {
	const q = query(
		investmentsRef,
		where('company.id', '==', companyId),
		limit(max)
	)
	const querySnapshot = await getDocs(q)
	const investments = querySnapshot.docs.map(d => d.data() as IInvestment)
	return investments
}

export const listenToInvestments = (fundId: string, max = 50): Unsubscribe => {
	store.dispatch(setInvetsmentsLoading(true))

	const q = query(investmentsRef, where('company.id', '==', fundId), limit(max))

	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const investments = querySnapshot.docs.map(d => d.data() as IInvestment)
			store.dispatch(setInvestments(investments))
		},
		error => {
			store.dispatch(setInvestmentsError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}
