import AllProspects from 'features/prospects/views/AllProspects'
import DashboardLayout from 'components/Dashboard/DashboardLayout'
import usePageTimeTracking from 'hooks/usePageTracking'

export default function Prospects(): JSX.Element {
	usePageTimeTracking({ pageName: 'Prospects' })

	return (
		<DashboardLayout hideHeader={false} showSidebar title='Prospects'>
			<AllProspects />
		</DashboardLayout>
	)
}
