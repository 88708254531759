import type { FieldValue } from 'firebase/firestore'
import type { FundData, ITag } from 'interfaces'

export interface ICustomCompanyFormField {
	id: string
	label: string
	field: string
	fundId: string
	templateId?: string
	subText?: string
	type?: ECustomCompanyFormFieldType
	options?: string[]
	tags?: Partial<ITag>[]
	isRequired: boolean
	isSystem?: boolean
	isSpecial?: boolean
	isDeleted?: boolean
	createdAt?: string
	updatedAt?: string
}

export enum ECustomCompanyFormFieldType {
	Tag = 'tag',
	Link = 'link',
	Industry = 'industry',
	SingleLine = 'single line',
	Multiline = 'multiline',
	RichEditor = 'rich text',
	Dropdown = 'dropdown',
	MultiSelect = 'multi select',
	File = 'file'
}

export interface ICustomCompanyFormTemplate {
	id: string
	name: string
	fund: FundData
	sections: ICompanyFormSection[]
	status: ECustomFormTemplateStatus
	createdAt?: FieldValue
	updatedAt?: FieldValue
}

export enum ECustomFormTemplateStatus {
	Draft = 'draft',
	Archived = 'archived',
	Published = 'published'
}

export interface ICompanyFormSection {
	id: string
	name: string
	fundId?: string
	description?: string
	createdAt?: string
	updatedAt?: string
	isSystem?: boolean
	index: number
	submissionInformation?: string
	fields: ICustomCompanyFormField[]
}
