import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import ContactList from 'features/contacts/view/ContactList'
import DashboardLayout from 'components/Dashboard/DashboardLayout'
import { EmailEntityService } from 'features/contacts/services/email_entity.service'
import { listenToContacts } from 'features/contacts/api/contacts.api'
import { selectContacts } from 'features/contacts/redux/contacts.slice'
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppSelector } from 'app/hooks'
import usePageTimeTracking from 'hooks/usePageTracking'

export default function Contacts(): JSX.Element {
	usePageTimeTracking({ pageName: 'Contacts' })

	const { authUser } = useContext(AuthContext)
	const { data, error, isLoading } = useAppSelector(selectContacts)

	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribe = listenToContacts(authUser.fund.id)
		const unsubscribe2 = EmailEntityService.listenToEmailEntityRelations(
			authUser.email
		)
		return () => {
			unsubscribe()
			unsubscribe2()
		}
	}, [authUser?.fund.id])

	if (error) {
		return (
			<DashboardLayout hideHeader={false} showSidebar title='Contacts'>
				<div data-testid='get-contacts-fail' className='p-10'>
					<div className='mt-3 font-semibold'>{error}</div>
				</div>
			</DashboardLayout>
		)
	}

	return (
		<DashboardLayout hideHeader={false} showSidebar title='Contacts'>
			<ContactList contacts={data} loading={isLoading} />
		</DashboardLayout>
	)
}
