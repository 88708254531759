/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { Calendar, Kanban, ListTree, Table2 } from 'lucide-react'
import {
	listenToCompanies,
	updateCompanies,
	updateCompany
} from '../api/companies.api'
import {
	selectCompanyFunnels,
	selectFunnelStatus
} from 'features/funnels/funnels.slice'
import { useContext, useEffect, useMemo, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import type { Column } from 'features/kanban/views/KanbanView'
import CompanyCard from './CompanyCard'
import CompanyGroupedList from './CompanyGroupList'
import CompanyList from './CompanyList'
import CreateCompany from './CreateCompany'
import KanbanView from 'features/kanban/views/KanbanView'
import Logger from 'lib/logger'
import { listenToFunnels } from 'features/funnels/funnels.api'
import { selectAuthUserFund } from 'features/authentication/authentication.slice'
import { selectSpaces } from 'features/spaces/redux/spaces.slice'
import { useAppSelector } from 'app/hooks'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useTabs from 'components/hooks/useTabs'

const tabs = [
	{
		name: 'Board',
		value: 'board',
		icon: <Kanban size={15} />,
		disabled: false
	},
	{
		name: 'List',
		value: 'list',
		icon: <ListTree size={15} />,
		disabled: false
	},
	{
		name: 'Table',
		value: 'table',
		icon: <Table2 size={15} />,
		disabled: false
	},
	{
		name: 'Calendar',
		value: 'calendar',
		icon: <Calendar size={15} />,
		disabled: true
	}
]

export default function AllCompanies(): JSX.Element {
	const { authUser } = useContext(AuthContext)
	const authFund = useSelector(selectAuthUserFund)
	const spaces = useAppSelector(selectSpaces).data
	const [searchParams] = useSearchParams()
	const group = searchParams.get('space') || 'all'

	const { Tabs, activeTab, setActiveTab } = useTabs({
		tabs,
		containerClassName: '',
		urlPathName: 'view'
	})
	const [boardColumns, setBoardColumns] = useState<Column[]>([])

	const { isLoading, data: companies } = useAppSelector(
		state => state.companies
	)
	const funnels = useAppSelector(selectCompanyFunnels)
	const isFunnelsLoading = useAppSelector(selectFunnelStatus)

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubFunnels = listenToFunnels(authUser.fund.id)
		const unsubscribe = listenToCompanies(authUser.fund.id)
		return () => {
			unsubscribe()
			unsubFunnels()
		}
	}, [authUser?.fund.id, authFund?.id])

	const columns = useMemo(() => {
		return funnels
			.filter(funnel => !funnel.isArchived)
			.filter(funnel => {
				return group === 'all' ? true : funnel.category === group
			})
			.map(funnel => {
				const _companies = companies
					.filter(company => company.funnel?.id === funnel.id)
					.sort((a, b) => (a.index || 0) - (b.index || 0))
					.sort((a, b) => (a.lastUpdatedAt || 0) - (b.lastUpdatedAt || 0))

				const column: Column = {
					id: funnel.id,
					title: funnel.name,
					isArchived: funnel.isArchived,
					index: funnel.index,
					category: funnel.category,
					items: _companies,
					pureItems: _companies
				}
				return column
			})
	}, [group, companies, funnels])

	useEffect(() => {
		setBoardColumns(columns)

		// If there's one column, set active tab to list
		if (columns.length === 1 && activeTab.value !== 'table') {
			setActiveTab(tabs.find(tab => tab.value === 'list') || tabs[0])
		}
	}, [columns])

	return (
		<div>
			<div className='flex w-full items-center justify-between border-b bg-white pr-5'>
				<Tabs />
				<div className='flex items-center gap-2'>
					<div className='flex items-center gap-2 border-l pl-3'>
						<CreateCompany space={spaces.find(i => i.slug === group)} />
					</div>
				</div>
			</div>

			<div className='-mt-2 px-5'>
				{activeTab.value === 'list' && (
					<CompanyList
						companies={companies
							.filter(company => {
								return group === 'all'
									? true
									: funnels.find(funnel => funnel.id === company.funnel?.id)
											?.category === group
							})
							.sort((a, b) => {
								if (group !== 'portfolio') return true
								return (
									(b?.totalInvestmentConverted?.amount || 0) -
									(a?.totalInvestmentConverted?.amount || 0)
								)
							})}
						loading={isLoading}
					/>
				)}
				{activeTab.value === 'board' && (
					<KanbanView
					
						allowSpaces
						columns={boardColumns}
						workspaces={['companies', 'company']}
						loading={isFunnelsLoading}
						itemsLoading={isLoading}
						group={group || 'funnel'}
						renderItem={item => {
							return <CompanyCard key={item.item.id} company={item.item} />
						}}
						onItemSameColumnMove={items => {
							updateCompanies(items)
						}}
						onItemDifferentColumnMove={({ item, dropResult }) => {
							updateCompany({
								...item,
								funnel: {
									id: funnels.find(
										funnel => funnel.id === dropResult.destination?.droppableId
									)?.id,
									name: funnels.find(
										funnel => funnel.id === dropResult.destination?.droppableId
									)?.name,
									category: funnels
										.find(
											funnel =>
												funnel.id === dropResult.destination?.droppableId
										)
										?.name.toLowerCase()
								},
								lastUpdatedAt: new Date().toString()
							})
						}}
						onColumnAdd={(column: Column) => {
							Logger.info(column)
						}}
						entity='company'
					/>
				)}
				{activeTab.value === 'table' && (
					<CompanyGroupedList companies={companies} kanbanColumns={columns} />
				)}
			</div>
		</div>
	)
}
