/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { CapitalCallRequest } from '../interfaces/CapitalCallRequest'

export interface NewCapitalCallRequstState {
	data: CapitalCallRequest[]
	isLoading: boolean
	error?: string | null
	selectedCapitalCallRequests: CapitalCallRequest[]
}

const initialState: NewCapitalCallRequstState = {
	data: [],
	isLoading: false,
	error: null,
	selectedCapitalCallRequests: []
}

export const capitalCallRequestsSlice = createAppSlice({
	name: 'capitalCallRequests',
	initialState,
	reducers: create => ({
		setCapitalCallRequestsError: create.reducer(
			(state, action: PayloadAction<string>) => {
				state.isLoading = false
				state.error = action.payload
			}
		),
		setCapitalCallRequestsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setCapitalCallRequests: create.reducer(
			(state, action: PayloadAction<CapitalCallRequest[]>) => {
				state.isLoading = false
				state.data = action.payload
			}
		),
		setSelectedCapitalCallRequests: create.reducer(
			(state, action: PayloadAction<CapitalCallRequest[]>) => {
				state.selectedCapitalCallRequests = action.payload
			}
		),
		addSelectedCapitalCallRequest: create.reducer(
			(state, action: PayloadAction<CapitalCallRequest>) => {
				state.selectedCapitalCallRequests.push(action.payload)
			}
		),
		removeSelectedCapitalCallRequest: create.reducer(
			(state, action: PayloadAction<CapitalCallRequest>) => {
				state.selectedCapitalCallRequests =
					state.selectedCapitalCallRequests.filter(
						request => request.id !== action.payload.id
					)
			}
		)
	}),
	selectors: {
		selectCapitalCallRequests: state => state.data,
		selectCapitalCallRequestsLoading: state => state.isLoading,
		selectSelectedCapitalCallRequests: state =>
			state.selectedCapitalCallRequests
	}
})

export const {
	setCapitalCallRequestsError,
	setCapitalCallRequestsLoading,
	setCapitalCallRequests,
	setSelectedCapitalCallRequests,
	addSelectedCapitalCallRequest,
	removeSelectedCapitalCallRequest
} = capitalCallRequestsSlice.actions

export const {
	selectCapitalCallRequests,
	selectCapitalCallRequestsLoading,
	selectSelectedCapitalCallRequests
} = capitalCallRequestsSlice.selectors
