import type { useFormik } from 'formik'
import type { ICompanyFormSection } from '../interfaces/company.interface'

import type { ComponentPropsWithoutRef } from 'react'
import clsxm from 'utils/clsxm'
import type { ITag } from 'interfaces'
import type { IIndustry } from 'features/industries/interfaces/industries.interface'
import SystemCompanyCustomField from './SystemCompanyFormField'

interface CompanyFormSectionProps {
	form: ReturnType<typeof useFormik>
	section: ICompanyFormSection
	wrapperProps?: ComponentPropsWithoutRef<'section'>
	tags: ITag[]
	industries: IIndustry[]
}

function SystemCompanyFormSection({
	form,
	tags,
	industries,
	section,
	wrapperProps
}: CompanyFormSectionProps) {
	const { className: wrapperClassName, ...restOfWrapperProps } =
		wrapperProps ?? {}
	return (
		<section className={clsxm(wrapperClassName)} {...restOfWrapperProps}>
			{section.name && <strong className='text-2xl'>{section.name}</strong>}
			{section.description && (
				<p className='text-sm text-gray-600'>{section.description}</p>
			)}

			{section.fields.map(field => (
				<SystemCompanyCustomField
					tags={tags}
					industries={industries}
					isSystemField={section.isSystem}
					wrapperProps={{
						className: clsxm(section.description ? 'my-8' : 'mt-4 mb-8')
					}}
					field={field}
					key={field.id}
					form={form}
				/>
			))}
		</section>
	)
}

export default SystemCompanyFormSection
