/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { IEmailMessage } from '../interfaces/email.interface'

export interface MessagesSliceState {
	data: IEmailMessage[]
	isLoading: boolean
	status: 'failed' | 'idle' | 'loading' | 'success'
	error?: string | null
}

const initialState: MessagesSliceState = {
	data: [],
	status: 'idle',
	error: null,
	isLoading: false
}

export const messagesSlice = createAppSlice({
	name: 'messages',
	initialState,
	reducers: create => ({
		setMessagesLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setMessages: create.reducer(
			(state, action: PayloadAction<IEmailMessage[]>) => {
				state.data = action.payload
			}
		)
	}),
	selectors: {
		selectMessagesState: state => state,
		selectMessages: emails => emails.data
	}
})

export const { setMessages, setMessagesLoading } = messagesSlice.actions

export const { selectMessages, selectMessagesState } = messagesSlice.selectors
