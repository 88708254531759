/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AppThunk } from 'app/store'
import { setPartners } from 'features/partners/partnerSlice'
import type { Unsubscribe } from 'firebase/firestore'
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	where
} from 'firebase/firestore'
import type { Partner } from 'interfaces'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'

const partnersRef = collection(firestore, 'partners')

export const addPartner = async (partner: Partner): Promise<Partner> => {
	const docRef = doc(partnersRef, partner.id)
	await setDoc(docRef, partner, { merge: true })

	return partner
}

export const getPartner = async (id: string): Promise<Partner> => {
	const docRef = doc(partnersRef, id)
	const docSnap = await getDoc(docRef)
	return docSnap.data() as Partner
}

export const getPartners = async (fundId: string): Promise<Partner[]> => {
	const q = query(partnersRef, where('fund.id', '==', fundId))
	const querySnapshot = await getDocs(q)
	const partners = querySnapshot.docs.map(d => d.data() as Partner)

	return partners
}

export const updatePartner = async (partner: Partner): Promise<Partner> => {
	const docRef = doc(partnersRef, partner.id)
	await updateDoc(docRef, partner as any, { merge: true })

	return partner
}

export const deletePartner = async (id: string): Promise<void> => {
	const docRef = doc(partnersRef, id)
	await deleteDoc(docRef)
}

export const listenToPartners =
	(fundId: string): AppThunk<Unsubscribe> =>
	dispatch => {
		const q = query(partnersRef, where('fund.id', '==', fundId))
		const unsubscribe = onSnapshot(
			q,
			querySnapshot => {
				const partners = querySnapshot.docs.map(d => d.data() as Partner)
				dispatch(setPartners(partners))
			},
			error => {
				Errorhandler.captureException(error)
				Logger.error(error)
			}
		)

		return unsubscribe
	}
