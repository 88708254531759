/* eslint-disable @typescript-eslint/no-unsafe-return */
import type { CompanyData, User } from 'interfaces'
import type {
	IAddUpdate,
	ICompanyUpdate
} from '../interfaces/company_update.interface'
import {
	addUpdate,
	deleteUpdate,
	updateUpdate
} from '../api/comapany_updates.api'

import { ActivityService } from 'features/activity/service/activity.service'
import { Errorhandler } from 'lib/sentry'
import { FileService } from 'features/files/services/file.service'
/* eslint-disable @typescript-eslint/max-params */
/* eslint-disable @typescript-eslint/no-extraneous-class */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IActivity } from 'features/activity/interfaces/actvitiy.interface'
import type { IEmailMessage } from 'features/mail/interfaces/email.interface'
import Logger from 'lib/logger'
import { nanoid } from 'nanoid'
import { toast } from 'sonner'

export class CompanyUpdateService {
	public static createCompanyUpdate = async (
		update: IAddUpdate,
		files: File[],
		authUser: User,
		company?: CompanyData,
		email?: IEmailMessage
	) => {
		try {
			toast.loading('Adding update...')

			let filesData: any[] = []

			if(files.length > 0) {

				const filesResults = await FileService.saveFiles({
					files,
					type: 'company_update',
					authUser,
					company
				})

				filesData = filesResults.map(file => ({
					id: file.id,
					url: file.url,
					name: file.name
				}))
			}

			const data: ICompanyUpdate = {
				id: update.id || nanoid(),
				year: update.year,
				month: update.month,
				note: update.note,
				fileIds: filesData.map(file => file.id),
				files: filesData,
				fund: authUser.fund,
				creator: {
					id: authUser.id,
					name: authUser.name,
					photoUrl: authUser.photoUrl || ''
				},
				createdAt: new Date().toISOString(),
				lastUpdatedAt: new Date().toISOString()
			}

			if (company) data.company = company
			if (email) data.email = email

			await CompanyUpdateService.addCompanyUpdate(data)
		} catch (error: any) {
			toast.dismiss()
			toast.error(error.message ?? 'An error occurred')

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	}

	public static createCompanyUpdateUpdate = async (
		update: ICompanyUpdate,
		authUser: User,
		files: File[]
	) => {
		try {
			toast.loading('Updating update...')

			const filesResults = await FileService.saveFiles({
				files,
				type: 'company_update',
				authUser,
				company: update.company
			})

			const filesData = filesResults.map(file => ({
				id: file.id,
				url: file.url,
				name: file.name
			}))

			const allFiles = [...update.files, ...filesData]

			const data: ICompanyUpdate = {
				...update,
				fileIds: allFiles.map(file => file.id),
				files: allFiles,
				lastUpdatedAt: new Date().toISOString()
			}

			await CompanyUpdateService.updateCompanyUpdate(data)
		} catch (error: any) {
			toast.dismiss()
			toast.error(error.message ?? 'An error occurred')

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	}

	public static addCompanyUpdate = async (update: ICompanyUpdate) => {
		try {
			await addUpdate(update)
			toast.dismiss()
			toast.success('Update added successfully')

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: update.creator,
				fund: update.fund,
				activity: 'added update',
				summary: '',
				description: update.note || '',
				icon: '',
				createdAt: new Date().toISOString()
			}
			if (update.company) {
				activity.summary = update.company.name
				activity.company = update.company
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to add update')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static updateCompanyUpdate = async (update: ICompanyUpdate) => {
		try {
			await updateUpdate(update)

			toast.success('Update updated successfully')

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: update.creator,
				fund: update.fund,
				activity: 'edited update',
				summary: '',
				description: update.note || '',
				icon: '',
				createdAt: new Date().toISOString()
			}

			if (update.company) {
				activity.summary = update.company.name
				activity.company = update.company
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to update company update')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static deleteCompanyUpdate = async (update: ICompanyUpdate) => {
		try {
			await deleteUpdate(update.id)

			toast.success('Update deleted successfully')

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: update.creator,
				fund: update.fund,
				activity: 'deleted update',
				summary: '',
				description: update.note,
				icon: '',
				createdAt: new Date().toISOString()
			}

			if (update.company) {
				activity.summary = update.company.name
				activity.company = update.company
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to delete update')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}
}
