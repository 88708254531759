/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useAppSelector } from "app/hooks";
import clsx from "clsx";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { Input } from "components/ui/input";
import { ScrollArea } from "components/ui/scroll-area";
import { AuthContext } from "context/AuthContext";
import { listenToCompanies } from "features/companies/api/companies.api";
import { selectCompanies } from "features/companies/redux/companies.slice";
import { listenToContacts } from "features/contacts/api/contacts.api";
import { selectContacts } from "features/contacts/redux/contacts.slice";
import useListenToPartners from "features/partners/hooks/usePartners.hook";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';

interface Props {
  suggestions: any[];
  position: { top: number; left: number } | null;
  handleSuggestionClick: (suggestion: any) => void;
}

const ITEMS_PER_PAGE = 10;

export default function QuillMention({ suggestions, position, handleSuggestionClick }: Props) {
  const { authUser } = useContext(AuthContext)

  const [selectedTab, setSelectedTab] = useState('partner');
  const [searchQuery, setSearchQuery] = useState('')
  const [data, setData] = useState<any[]>([])
  const [hasMore, setHasMore] = useState(true);
  const { partners } = useListenToPartners({})
  const companies = [...useAppSelector(selectCompanies)]
  const { data: contacts, error, isLoading } = useAppSelector(selectContacts)

  useEffect(() => {
    if (!authUser?.fund.id) return

    const unsubscribe = listenToCompanies(authUser.fund.id)
    const unsubscribe2 = listenToContacts(authUser.fund.id)

    return () => {
      unsubscribe()
      unsubscribe2()
    }
  }, [authUser?.fund.id])

  useEffect(() => {
    let filteredData = [];
    switch (selectedTab) {
      case 'partner': {
        filteredData = partners.filter(partner => partner.name.toLowerCase().includes(searchQuery.toLowerCase()));
        break;
      }
      case 'company': {
        filteredData = companies.filter(company => company.name.toLowerCase().includes(searchQuery.toLowerCase()));
        break;
      }
      case 'contact': {
        filteredData = contacts.filter(contact => contact.name.toLowerCase().includes(searchQuery.toLowerCase()));
        break;
      }
      default: {
        filteredData = [];
      }
    }
    setData(filteredData.slice(0, ITEMS_PER_PAGE));
    setHasMore(filteredData.length > ITEMS_PER_PAGE);
  }, [searchQuery, selectedTab]);

  const fetchMoreData = () => {
    let filteredData = [];
    switch (selectedTab) {
      case 'partner': {
        filteredData = partners.filter(partner => partner.name.toLowerCase().includes(searchQuery.toLowerCase()));
        break;
      }
      case 'company': {
        filteredData = companies.filter(company => company.name.toLowerCase().includes(searchQuery.toLowerCase()));
        break;
      }
      case 'contact': {
        filteredData = contacts.filter(contact => contact.name.toLowerCase().includes(searchQuery.toLowerCase()));
        break;
      }
      default: {
        filteredData = [];
      }
    }
    const newData = filteredData.slice(data.length, data.length + ITEMS_PER_PAGE);
    setData(prevData => [...prevData, ...newData]);
    setHasMore(filteredData.length > data.length + ITEMS_PER_PAGE);
  };

  return (
    <div>
      {suggestions.length > 0 && position && (
        <ul
          className="mention-dropdown px-5 shadow-2xl border"
          style={{
            position: 'absolute',
            top: position.top,
            left: position.left,
            backgroundColor: 'white',
            zIndex: 1000,
            listStyleType: 'none',
            padding: '0',
            margin: '0',
            borderRadius: '5px'
          }}
        >
          <div>
            <div className="p-2 flex gap-2 text-sm">
              <button
                className={clsx(
                  "rounded-md px-2 border",
                  selectedTab === "partner" ? "bg-blue-700 text-white" : 'bg-gray-200'
                )}
                onClick={() => {
                  setSelectedTab('partner');
                  setData(partners.slice(0, ITEMS_PER_PAGE));
                  setHasMore(partners.length > ITEMS_PER_PAGE);
                }}>
                Partners
              </button>
              <button
                className={clsx(
                  "rounded-md px-2 border",
                  selectedTab === "company" ? "bg-blue-700 text-white" : 'bg-gray-200'
                )}
                onClick={() => {
                  setSelectedTab('company');
                  setData(companies.slice(0, ITEMS_PER_PAGE));
                  setHasMore(companies.length > ITEMS_PER_PAGE);
                }}>
                Companies
              </button>
              <button
                className={clsx(
                  "rounded-md px-2 border",
                  selectedTab === "contact" ? "bg-blue-700 text-white" : 'bg-gray-200'
                )}
                onClick={() => {
                  setSelectedTab('contact');
                  setData(contacts.slice(0, ITEMS_PER_PAGE));
                  setHasMore(contacts.length > ITEMS_PER_PAGE);
                }}>
                Contacts
              </button>
            </div>

            <div className="px-1 mb-1">
              <Input
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
              />
            </div>
          </div>

          <InfiniteScroll
            dataLength={data.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<></>}
            scrollableTarget="scrollArea"
          >
            <ScrollArea id="scrollArea" className="max-h-72 overflow-auto">
              {data.map(suggestion => (
                <li
                  key={suggestion.id}
                  className='hover:bg-gray-200 flex items-center gap-2'
                  style={{
                    padding: '5px 10px',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleSuggestionClick({
                    value: suggestion.name || suggestion.email,
                    id: suggestion.id,
                    type: selectedTab
                  })}
                >
                  <Avatar className="w-8 h-8 border">
                    <AvatarImage
                      src={suggestion.photoUrl}
                    />
                    <AvatarFallback>{(suggestion.name || suggestion.email)?.charAt(0)}</AvatarFallback>
                  </Avatar>
                  {suggestion.name || suggestion.email}
                </li>
              ))}
            </ScrollArea>
          </InfiniteScroll>

          {data.length === 0 && (
            <div className="p-2 text-center text-gray-500">No results found</div>
          )}
        </ul>
      )}
    </div>
  )
}
