import DashboardLayout from 'components/Dashboard/DashboardLayout'
import ReportsPage from 'features/reports/views/ReportsPage'
import usePageTimeTracking from 'hooks/usePageTracking'

export default function Reports() {
	usePageTimeTracking({ pageName: 'Reports' })

	return (
		<DashboardLayout title='Reports' hideHeader={false} showSidebar>
			<ReportsPage />
		</DashboardLayout>
	)
}
