/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface AppStautsSliceState {
	data: Record<string, string> 
	isLoading: boolean
	error: string | null
}

const initialState: AppStautsSliceState = {
	data: {},
	isLoading: false,
	error: null,
}

export const appStatusSlice = createAppSlice({
	name: 'appStatus',
	initialState,
	reducers: create => ({
		setStatusData: create.reducer(
			(state, action: PayloadAction<Record<string, string>>) => {
				state.data = action.payload
				state.isLoading = false
			}
		),
		
	}),
	selectors: {
		selectAppStatus: state => state.data,
		selectItemStatus: (state, key: string) => { 
			if(!state.data) return null
			if (!state.data[key]) return null
			return state.data[key] 
		}
	}
})

export const {
	setStatusData,
} = appStatusSlice.actions

export const {
	selectAppStatus,
	selectItemStatus,
} = appStatusSlice.selectors
