/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthContext } from 'context/AuthContext'
import { useFormik } from 'formik'
import { nanoid } from 'nanoid'
import { useContext, useEffect } from 'react'
import type { ITask } from '../interafces/task.interface'
import * as Yup from 'yup'

export default function useUpdateTask({
	onUpdate,
	task
}: {
	onUpdate: (task: Partial<ITask>) => void
	task?: ITask
}) {
	const { authUser } = useContext(AuthContext)

	const formik = useFormik<Partial<ITask>>({
		initialValues: {
			id: '',
			description: '',
			taskRawPlate: [] as any[],
			executor: undefined,
			status: '',
			dueDate: new Date().toISOString(),
			creator: undefined,
			company: undefined,
			lastUpdatedAt: new Date().toISOString(),
			fund: task?.fund,
			mail: task?.mail
		},
		validationSchema: Yup.object({
			id: Yup.string().required('id is required'),
			description: Yup.string().required('describe task'),
			fund: Yup.object().required('fund is required'),
			taskRawPlate: Yup.array().required('Note is required')
		}),
		onSubmit: async values => {
			if (!authUser) return

			const data: Partial<ITask> = {
				id: task?.id ?? nanoid(),
				fund: values.fund ?? authUser.fund,
				description: values.description,
				status: values.status,
				dueDate: values.dueDate,
				taskRawPlate: values.taskRawPlate,
				executor: values.executor || {
					id: authUser.id,
					name: authUser.name,
					photoUrl: authUser.photoUrl ?? ''
				},
				lastUpdatedAt: new Date().toISOString(),
				creator: values.creator ?? {
					id: authUser.id,
					name: authUser.name,
					photoUrl: authUser.photoUrl ?? ''
				}
			}

			if (values.executor) data.executor = values.executor

			if (values.company) data.company = values.company

			if (values.mail) data.mail = values.mail

			onUpdate(data)
			formik.resetForm()
		}
	})

	useEffect(() => {
		formik.setValues({
			id: task?.id,
			description: task?.description || '',
			taskRawPlate: task?.taskRawPlate || [],
			executor: task?.executor,
			status: task?.status || 'backlog',
			dueDate: task?.dueDate,
			creator: task?.creator,
			company: task?.company,
			lastUpdatedAt: new Date().toISOString(),
			fund: task?.fund,
			mail: task?.mail
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [task])

	return { formik }
}
