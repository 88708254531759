/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { cn } from "@udecode/cn"
import Button from "components/Buttons/Button"
import { DialogModal } from "components/shadcn/DialogModal"
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import useCreateCompany from "features/companies/hooks/useCreateCompany"
import AllEmailComponent from "features/mail/views/EmailDetails"
import type { INotification } from "features/notifications/interface/notifications.interface"
import { NotificationsService } from "features/notifications/service/Notifications.service"
import { ExternalLink, FileText } from "lucide-react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

interface Props {
  extractedInformation: any,
  notification: INotification
}

export default function CompanyFromEmailExtractedInformation({ extractedInformation, notification }: Props) {

  const [isOpen, setIsOpen] = useState(false)
  const { formik } = useCreateCompany({
    onSuccess: (company) => {

      NotificationsService.updateNotification({
        id: notification.id,
        metadata: {
          ...notification.metadata,
          company: {
            id: company.id,
            name: company.name,
            website: company.website
          }
        }
      })

      setIsOpen(false)
    }
  })
  const navigate = useNavigate()


  useEffect(() => {

    if (!isOpen) return

    formik.setFieldValue('name', extractedInformation.name || '')
    formik.setFieldValue('website', extractedInformation.websiteUrl || '')
    formik.setFieldValue('traction', extractedInformation.traction || '')
    formik.setFieldValue('description', extractedInformation.summary || '')
    formik.setFieldValue('businessStage', extractedInformation.businessStage || '')
    formik.setFieldValue('businessTypes', extractedInformation.businessTypes || '')
    formik.setFieldValue(
      'founderName',
      extractedInformation.founders?.[0]?.name || ''
    )
    formik.setFieldValue(
      'founderEmail',
      extractedInformation.founders?.[0]?.email || ''
    )
    formik.setFieldValue(
      'founderLinkedIn',
      extractedInformation.founders?.[0]?.linkedIn || ''
    )

    formik.setFieldValue(
      'referralMethod',
      extractedInformation.referralMethod || 'Email'
    )
  }, [isOpen])

  return (
    <DialogModal
      size='sm:max-w-[80%]'
      open={isOpen}
      setOpen={setIsOpen}
      title='Create Company'
      description="Create a company from the extracted information from the email."
      trigger={
        <Button
          id='open-create-company-from-email-button'
          className="border border-blue-700 text-xs"
          onClick={() => {
            setIsOpen(true)
          }}
        >
          <FileText size={13} color='white' /> View Extracted Information
        </Button>
      }
    >
      <div className="flex gap-5">
        <div className="flex-1 h-[80vh] overflow-auto border rounded-md">
          <AllEmailComponent
            isReadOnly
            data={notification.metadata?.email}
            wrapperClass='w-full rounded-none' />
        </div>
        <div
          data-testid='create-company-from-email-form'
          className="flex-1"
        >

          {
            notification.metadata?.company &&
            <div className="border px-3 py-1 rounded-md bg-blue-50 border-blue-300 text-blue-700 text-sm gap-2 flex flex-col">
              A company has already been created from this email.
              <div
                className="flex items-center gap-1 cursor-pointer hover:underline"
                onClick={() => {
                  navigate(`/company/${notification.metadata?.company.id}`)
                }}
              >
                <Avatar className='h-6 w-6 border'>
                  <AvatarImage
                    src={
                      notification.metadata?.company.name
                        ? `https://www.google.com/s2/favicons?sz=128&domain_url=${notification.metadata?.company.website}`
                        : '/images/logo/Logo-4.png'
                    }
                  />
                  <AvatarFallback>{notification.metadata?.company.name.slice(0, 1)}</AvatarFallback>
                </Avatar>
                <div>
                  {notification.metadata?.company.name}
                </div>
                <Button
                  id={`create-company-from-email-trigger-${notification.metadata?.company.id}`}
                  variant='ghost'
                  className='px-1 py-1 text-sm'
                >
                  <ExternalLink size={15} />
                </Button>
              </div>

            </div>
          }

          <div className=' border-black pt-5'>
            <Label>Company Name</Label>
            <Input
              id='create-company-from-email-name'
              placeholder='Enter company name'
              error={formik.errors.name}
              onChange={e => {
                formik.setFieldValue('name', e.target.value)
              }}
              value={formik.values.name}
            />
          </div>
          <div className='mt-5 flex gap-5'>
            <div className='flex-1'>
              <Label>Website</Label>
              <Input
                placeholder='Enter website'
                onChange={e => {
                  formik.setFieldValue('website', e.target.value)
                }}
                value={formik.values.website}
              />
            </div>
            <div className='flex-1'>
              <Label>Traction</Label>
              <Input
                placeholder='Enter company traction'
                onChange={e => {
                  formik.setFieldValue('traction', e.target.value)
                }}
                value={formik.values.traction}
              />
            </div>
          </div>
          <div className='mt-5 flex flex-col'>
            <Label>Description</Label>
            <textarea
              className={cn(
                'mt-1 flex rounded-md border border-gray-300'
              )}
              placeholder='Enter company description'
              onChange={e => {
                formik.setFieldValue(
                  'description',
                  e.target.value
                )
              }}
              value={formik.values.description}
            />
          </div>

          <div className='mt-5 flex gap-5'>
            <div className='flex-1'>
              <Label>Company Stage</Label>
              <Input
                placeholder='Enter company stage'
                onChange={e => {
                  formik.setFieldValue('stage', e.target.value)
                }}
                value={formik.values.stage}
              />
            </div>
            <div className='flex-1'>
              <Label>Referral</Label>
              <Input
                placeholder='Enter referral'
                onChange={e => {
                  formik.setFieldValue(
                    'referralMethod',
                    e.target.value
                  )
                }}
                value={formik.values.referralMethod}
              />
            </div>
          </div>

          <div className='mt-5 flex gap-5'>
            <div className='flex-1'>
              <Label>Founder Name</Label>
              <Input
                placeholder='Enter founder name'
                onChange={e => {
                  formik.setFieldValue(
                    'founderName',
                    e.target.value
                  )
                }}
                value={formik.values.founderName}
              />
            </div>
            <div className='flex-1'>
              <Label>Founder Email</Label>
              <Input
                placeholder='Enter founder email'
                onChange={e => {
                  formik.setFieldValue(
                    'founderEmail',
                    e.target.value
                  )
                }}
                value={formik.values.founderEmail}
              />
            </div>
          </div>

          <div className="w-full flex mt-5">
            <div className="flex-1" />
            <Button
              id='create-company-from-email-submit'
              disabled={formik.isSubmitting || !formik.isValid}
              isLoading={formik.isSubmitting}
              type='button'
              onClick={() => {
                formik.handleSubmit()
              }}
            >
              Create Company
            </Button>
          </div>
        </div>
      </div>
    </DialogModal>
  )
}