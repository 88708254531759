import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
/* eslint-disable consistent-return */
import { CrossCircledIcon, DotsVerticalIcon } from '@radix-ui/react-icons'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuTrigger
} from 'components/ui/dropdown-menu'
import { HoverCard, HoverCardContent, HoverCardTrigger } from 'components/ui/hover-card'
import {
	addRecipients,
	removeRecipients,
	selectIsRecipientNextStepDisable,
	selectRecipients
} from '../redux/newCapitalCall.slice'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import type { ILimitedPartner } from 'interfaces'
import { PlusIcon } from 'lucide-react'
import { limitedPartnersToDropdown } from '../helper/capitalCall'
import { listenToLPs } from 'features/limited_partners/api/lps.api'
import { selectLPs } from 'features/limited_partners/redux/lps.slice'
import { store } from 'app/store'
import { useAppSelector } from 'app/hooks'
import { useWizard } from 'react-use-wizard'

function onClickDeleteRecipients(recipient: ILimitedPartner) {
	store.dispatch(removeRecipients(recipient))
}

export default function NewCapitalCallRepcientsStep(): JSX.Element {
	const { authUser } = useContext(AuthContext)
	const recipients = useAppSelector(selectRecipients)
	const limitedPartners = useAppSelector(selectLPs)
	const [onAddNewState, setOnAddNewState] = useState<boolean>(false)
	const dropDownData = limitedPartnersToDropdown(limitedPartners, recipients)
	const isRecipientNextStepDisable = useAppSelector(
		selectIsRecipientNextStepDisable
	)

	const { nextStep } = useWizard()

	function onClickNextStep() {
		nextStep()
	}

	function onClickAddRecipients(newRecipient: ILimitedPartner) {
		store.dispatch(addRecipients(newRecipient))
		setOnAddNewState(false)
	}

	function onClickAddNewRecipientState(state = true) {
		setOnAddNewState(state)
	}

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubscribe = listenToLPs(authUser.fund.id)

		return () => {
			unsubscribe()
		}
	}, [authUser?.fund.id])

	return (
		<div className='h-100vh mx-auto min-h-[calc(100vh-3rem)] gap-x-60 bg-white p-14 pb-0'>
			<div className='rounded-md border border-[#E4E5E8] bg-white p-5'>
				<div className='mb-6 text-center text-3xl font-semibold'>
					Recipients
				</div>
				<div className='flex flex-wrap items-center  gap-7'>
					{recipients.map(recipient => (
						<div
							key={recipient.id}
							className='relative flex w-[250px] flex-col items-center rounded-md border border-[#E4E5E8] p-5 px-20'
						>
							<div>
								<Avatar className='h-20 w-20 border'>
									<AvatarImage src={recipient.photoUrl} />
									<AvatarFallback>
										{recipient.name.slice(0, 1).toUpperCase()}
									</AvatarFallback>
								</Avatar>
							</div>
							<HoverCard openDelay={0} closeDelay={0}>
									<HoverCardTrigger asChild>
										<div className='mt-2 whitespace-nowrap text-ellipsis text-center overflow-hidden w-[200px]'>
											{recipient.name}
										</div>
									</HoverCardTrigger>
									<HoverCardContent className='w-fit'>
										<div>
											{recipient.name}
										</div>
									</HoverCardContent>
								</HoverCard>
							<div className='absolute right-0 top-0'>
								<DropdownMenu>
									<DropdownMenuTrigger asChild>
										<Button
											variant='ghost'
											className='px-2'
											onClick={() => onClickDeleteRecipients(recipient)}
										>
											<DotsVerticalIcon width={24} />
										</Button>
									</DropdownMenuTrigger>
									<DropdownMenuContent className='w-56' align='end' forceMount>
										<DropdownMenuGroup>
											<DropdownMenuItem
												onClick={() => onClickDeleteRecipients(recipient)}
											>
												<div className='flex items-center'>
													<CrossCircledIcon
														width='24'
														height='24'
														color='red'
													/>
													<span className='ml-3'>Delete</span>
												</div>
											</DropdownMenuItem>
										</DropdownMenuGroup>
									</DropdownMenuContent>
								</DropdownMenu>
							</div>
						</div>
					))}
					{!onAddNewState && dropDownData.length > 0 && (
						<div>
							<Button
								variant='ghost'
								className='p-5 px-20'
								onClick={() => onClickAddNewRecipientState(true)}
								id="add-recipient-button"
							>
								<PlusIcon />
								<span>Recipient</span>
							</Button>
						</div>
					)}
					{onAddNewState && dropDownData.length > 0 && (
						<div className='relative flex h-[178px] w-[250px] flex-col items-center rounded-md border border-[#E4E5E8] p-5'>
							<div>
								<Avatar className='h-20 w-20 border'>
									<AvatarImage src='' />
									<AvatarFallback>N/A</AvatarFallback>
								</Avatar>
							</div>
							<div className='mt-2'>
								<ComboSelect
									className='w-[200px]'
									placeholder='Select Recipient'
									allowSearch
									defaultValue='Select Recipient'
									options={dropDownData}
									onSelect={value => onClickAddRecipients({ ...value })}
								/>
							</div>
							<div className='absolute right-0 top-0'>
								<DropdownMenu>
									<DropdownMenuTrigger asChild>
										<Button
											variant='ghost'
											className='px-2'
											onClick={() => onClickDeleteRecipients(recipient)}
										>
											<DotsVerticalIcon width={24} />
										</Button>
									</DropdownMenuTrigger>
									<DropdownMenuContent className='w-56' align='end' forceMount>
										<DropdownMenuGroup>
											<DropdownMenuItem
												onClick={() => onClickAddNewRecipientState(false)}
											>
												<div className='flex items-center'>
													<CrossCircledIcon
														width='24'
														height='24'
														color='red'
													/>
													<span className='ml-3'>Delete</span>
												</div>
											</DropdownMenuItem>
										</DropdownMenuGroup>
									</DropdownMenuContent>
								</DropdownMenu>
							</div>
						</div>
					)}
				</div>
			</div>
			<Button
				className='right-0 ml-auto mt-5 px-10'
				variant='blue'
				id="continue-step-button"
				onClick={() => onClickNextStep()}
				disabled={isRecipientNextStepDisable}
			>
				Continue
			</Button>
		</div>
	)
}
