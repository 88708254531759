/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import { displayAmount, getConvertedAmount, getConvertedAmounts } from "utils/currency";
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "context/AuthContext";
import type { IPortfolioInvestmentChart } from "../interfaces/reports.interface";
import { InvestmentService } from "features/investments/service/investment.service";
import StackedBarChart from "../components/StackedBarChart";
import _ from "lodash";
import pluralize from "pluralize";
import { selectInvestments } from "features/investments/redux/investments.slice";
import { useAppSelector } from "app/hooks";

function getIndex(index: number): string {
  if (index === 1) return "1st";
  if (index === 2) return "2nd";
  if (index === 3) return "3rd";
  return `${index}th`;
}

const colorsList = ["#FFC107", "#FF5722", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50", "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800", "#FF5722", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50", "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800", "#FF5722", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50", "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800", "#FF5722", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50", "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800", "#FF5722", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50", "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800", "#FF5722", "#E91E63", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50"]


export default function FunnelChart(): JSX.Element {

  const {authUser} = useContext(AuthContext);

  const investments = useAppSelector(selectInvestments); 

  const [data, setData] = useState<IPortfolioInvestmentChart>();

  useEffect(() => {
    if(!authUser?.fund.id) return;

    const unsubscribe = InvestmentService.listenToInvestments(authUser.fund.id);

    // eslint-disable-next-line consistent-return
    return () => unsubscribe();
  }, [authUser?.fund.id]);
  
  useEffect(() => {
    const result: any = {};

    // Calculate total companies
    const groupByCompany = _.groupBy(investments, "company.id");
    const totalCompanies = Object.keys(groupByCompany).length;

    result.totalCompanies = totalCompanies;

    // Calculate total investment
    const totalInvestment = _.reduce(investments, (acc: any, investment) => {
      if (acc[investment.currency]) {
        acc[investment.currency] += investment.amount;
      } else {
        acc[investment.currency] = investment.amount;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return acc;
    }, {});
    result.totalInvestment = Object.keys(totalInvestment).map((currency) => ({ currency, amount: totalInvestment[currency] }));

    // Calculate sum of investments. convert array of investments to object
    const sumOfInvestments: any = {};
    for (const investment of result.totalInvestment) {
      sumOfInvestments[investment.currency] = investment.amount;
    }
    
    result.sumOfInvestments = sumOfInvestments;

    // Calculate chart data
    const chartData: any = {};
    
    // Get comapany with max number of investments
    const maxInvestments = _.maxBy(Object.values(groupByCompany), (investments) => investments.length) || []; 
    
    // Construct labels, 1st check, 2nd check, 3rd check etc
    const labels = _.range(1, maxInvestments.length + 1).map((index) => `${getIndex(index)} Check`);
    chartData.label = labels;

    chartData.colors = colorsList.slice(0, labels.length);

    const datasets: any = [];
    for (const companyId of Object.keys(groupByCompany)) {
      const companyInvestments = groupByCompany[companyId];
      const sortedInvestments = _.sortBy(companyInvestments, "date");
      const data = sortedInvestments.map((i) => getConvertedAmount({amount : i.amount || 0, currency: i.currency || "USD"}).amount);
      datasets.push({ label: companyInvestments[0].company.name, data });
    }

    // Sort datasets by total investment
    datasets.sort((a: { data: any[]; }, b: { data: any[]; }) => b.data.reduce((acc: number, val: number) => acc + val, 0) - a.data.reduce((acc: number, val: number) => acc + val, 0));
  
    chartData.datasets = datasets;
    
    result.chartData = chartData;

    setData(result);

  }, [investments]);

  if(!data) return (<div/>);
  
	return  (
    <div className="border bg-white border-[#E4E5E8] rounded-md px-4 py-5 min-w-[570px]">
      <div className="font-semibold">
        Portfolio Investment ({data.totalCompanies} {pluralize("Company", data.totalCompanies)}) ({ displayAmount(getConvertedAmounts(data.sumOfInvestments)) })
      </div>
      <div>
        <StackedBarChart data={data.chartData} />
      </div>
    </div>
  )
}
