import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { useEffect, useState } from 'react'

/* eslint-disable react-hooks/exhaustive-deps */
import Button from 'components/Buttons/Button'
import { DialogFooter } from 'components/ui/dialog'
import { DialogModal } from 'components/shadcn/DialogModal'
import type { IContact } from 'interfaces'
import { Input } from 'components/ui/input'
import { User } from 'lucide-react'
import useCreateLP from 'features/limited_partners/hooks/useCreateLp'

export function CreateLpFromContact({ contact }: { contact: IContact }) {
	const [isOpen, setIsOpen] = useState(false)
	const { formik } = useCreateLP({
		onSuccess: () => {
			setIsOpen(false)
		},
		type: 'lp'
	})

	useEffect(() => {
		if (!isOpen) formik.resetForm()
		formik.setFieldValue('name', contact.name)
	}, [contact, isOpen])

	return (
		<DialogModal
			title='Create Limited Partner'
			description='Create a new lmited partner this from this contact'
			trigger={
				<div className='flex items-center gap-2'>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button id='create-lp-from-contact-trigger' className='p-1' variant='outline'>
								<User color='blue' />
							</Button>
						</TooltipTrigger>
						<TooltipContent>
							<p>Create limited partner from contact</p>
						</TooltipContent>
					</Tooltip>
				</div>
			}
			open={isOpen}
			setOpen={setIsOpen}
		>
			<div className='grid-col-2 grid gap-2'>
				<Input
					id='name'
					label='Limited Partner Name'
					value={formik.values.name}
					error={formik.errors.name}
					className='col-span-3'
					onChange={e => {
						formik.setFieldValue('name', e.target.value)
					}}
				/>

				<Input
					id='email'
					label='Limited Partner Email'
					value={formik.values.email}
					error={formik.errors.email}
					className='col-span-3'
					onChange={e => {
						formik.setFieldValue('email', e.target.value)
					}}
				/>

				<Input
					id='linkeedIn'
					label='Limited Partner LinkedIn'
					value={formik.values.linkedIn}
					error={formik.errors.linkedIn}
					className='col-span-3'
					onChange={e => {
						formik.setFieldValue('linkedIn', e.target.value)
					}}
				/>

				<DialogFooter className='mt-5'>
					<Button
						id='create-company-from-contact-button'
						type='submit'
						onClick={() => formik.handleSubmit()}
						disabled={formik.isSubmitting || !formik.isValid}
						isLoading={formik.isSubmitting}
					>
						Create
					</Button>
				</DialogFooter>
			</div>
		</DialogModal>
	)
}
