import {
	selectIsActivitiesLoading,
	selectLimitedPartnerActivities
} from 'features/activity/redux/activities.slice'

import ActivityLogs from 'features/activity/views/ActivityLogs'
import type { ILimitedPartner } from '../interfaces/limitedPartner.interface'
import { listenToLimitedPartnerActivity } from 'features/activity/api/activity.api'
import { selectLimitedPartnerNotes } from 'features/notes/redux/notes.slice'
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppSelector } from 'app/hooks'
import { useEffect } from 'react'

interface Props {
	limitedPartner: ILimitedPartner
}

export default function LimitedPartnerActivityLogs({ limitedPartner }: Props) {
	const isLoading = useAppSelector(selectIsActivitiesLoading)
	const activities = useAppSelector(state =>
		selectLimitedPartnerActivities(state, limitedPartner.id)
	)

	const totalNotes = useAppSelector(
		state => selectLimitedPartnerNotes(state, limitedPartner.id).length
	)
	const totalEmails = 0

	useEffect(() => {
		const unsubscribe = listenToLimitedPartnerActivity(limitedPartner.id)
		return () => unsubscribe()
	}, [])

	return (
		<ActivityLogs
			activities={activities}
			loading={isLoading}
			totalEmails={totalEmails}
			totalNotes={totalNotes}
			eventEmails={[
				...(limitedPartner.teamMemberEmails ?? []),
				limitedPartner.email ?? ''
			]}
		/>
	)
}
