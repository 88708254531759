import { Bell, MailPlus } from 'lucide-react'
import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
/* eslint-disable react-hooks/exhaustive-deps */
import Button from 'components/Buttons/Button'
import CompanyFromEmailExtractedInformation from 'features/mail/modules/CompanyFromEmail/views/CompanyFromEmailExtractedinformation'
import { ENotificationStatus } from '../enums/notifications.enums'
import EmailDialog from 'features/mail/views/EmailDialog'
import type { INotification } from '../interface/notifications.interface'
import { ImSpinner10 } from 'react-icons/im'
import { NotificationsService } from '../service/Notifications.service'
import Skeleton from 'components/Skeleton'
import { renderDate } from 'utils/date.util'
import sanitize from 'sanitize-html'
import { selectNotifications } from '../redux/notifications.slice'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function Notifications() {
	const { authUser } = useContext(AuthContext)
	const navigate = useNavigate()
	const {
		data: notifications,
		isLoading,
		error
	} = useSelector(selectNotifications)

	useEffect(() => {
		if (!authUser) return
		NotificationsService.listenToNotifications({
			userId: authUser.id,
			fundId: authUser.fund.id
		})
	}, [authUser?.id, authUser?.fund.id])

	if (isLoading)
		return (
			<div className='flex h-full flex-col gap-3 px-5 py-2'>
				<div className='flex items-center gap-2'>
					<Bell size={18} />
					<h2 className='text-lg font-semibold text-gray-900'>Notifications</h2>
				</div>
				{Array.from({ length: 4 }).map((_, i) => (
					<Skeleton key={i} className='h-5 w-[40rem] border' />
				))}
			</div>
		)

	if (error) return <div className='text-red-500'>{error}</div>

	const renderNotification = (notification: INotification) => (
		<div
			key={notification.id}
			className='cursor-pointer rounded border bg-white hover:bg-gray-50'
		>
			<div className='flex items-center justify-between gap-5 px-5 py-2'>
				<div className='flex flex-1 items-center gap-2'>
					<div className='flex h-10 w-10 items-center justify-center rounded-full border border-[#1486C1] bg-[#1486C122] text-[#1486C1]'>
						{notification.status === ENotificationStatus.PROGRESS ? (
							<ImSpinner10 className='animate-spin' />
						) : (
							<Bell size={15} />
						)}
					</div>
					<div className='flex-1'>
						<div className='text-sm font-semibold text-gray-800'>
							{notification.message}
						</div>
						<div className='flex-1 text-xs text-gray-500'>
							{sanitize(notification.details)}
						</div>
						<div className='mt-2 flex items-center gap-3'>
							{notification.metadata?.email && (
								<EmailDialog
									isReadOnly
									title={notification.metadata?.email?.subject || 'Email'}
									email={notification.metadata?.email}
									trigger={
										<Button
											id='open-create-company-from-email-button'
											className='border-blue-500 text-xs text-blue-700'
											variant='outline'
										>
											<MailPlus size={13} color='blue' /> View Email
										</Button>
									}
								/>
							)}
							{notification.type === 'company_from_email' &&
								notification.metadata.extractedCompanyData && (
									<CompanyFromEmailExtractedInformation
										notification={notification}
										extractedInformation={
											notification.metadata.extractedCompanyData
										}
									/>
								)}
							{notification.type === 'company_from_deck' &&
								notification.metadata.extractedCompanyData && (
									<Button
										id='open-create-company-from-deck-button'
										className='border-blue-500 text-xs text-blue-700'
										variant='outline'
										onClick={() => {
											navigate(`/company/${notification.metadata.companyId}`)
										}}
									>
										<MailPlus size={13} color='blue' /> View Company
									</Button>
								)}
						</div>
					</div>
				</div>
				<div className='text-xs text-gray-500'>
					{renderDate(notification.createdAt)}
				</div>
			</div>
		</div>
	)

	return (
		<div>
			<div className='flex flex-col gap-3 p-5 py-2'>
				<div className='flex items-center gap-2'>
					<Bell size={18} />
					<h2 className='text-lg font-semibold text-gray-900'>Notifications</h2>
				</div>
				{notifications.length === 0 && (
					<div className='ml-10 w-fit rounded-md border bg-gray-50 px-5 py-2 text-gray-900'>
						No notifications
					</div>
				)}
				{notifications.some(
					notification => notification.status === ENotificationStatus.PROGRESS
				) && (
					<div className='flex flex-col gap-1'>
						<div className='text-sm font-semibold text-gray-900'>
							In Progress
						</div>
						<div className='flex flex-col gap-1'>
							{notifications
								.filter(
									notification =>
										notification.status === ENotificationStatus.PROGRESS
								)
								.map(element => renderNotification(element))}
						</div>
					</div>
				)}
				<div className='flex flex-col gap-1'>
					{notifications
						.filter(
							notification =>
								notification.status !== ENotificationStatus.PROGRESS
						)
						.map(element => renderNotification(element))}
				</div>
			</div>
		</div>
	)
}
