/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'
import {
	collection,
	deleteDoc,
	doc,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	where
} from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'
import type { ICompanyUpdate } from '../interfaces/company_update.interface'
import {
	setUpdates,
	setUpdatesError,
	setUpdatesLoading
} from '../redux/updates.slice'

const ref = collection(firestore, 'company_updates')

export const addUpdate = async (update: ICompanyUpdate) => {
	const docRef = doc(ref, update.id)
	await setDoc(docRef, update)
}

export const updateUpdate = async (update: ICompanyUpdate) => {
	const docRef = doc(ref, update.id)
	await updateDoc(docRef, update as any)
}

export const deleteUpdate = async (id: string) => {
	const docRef = doc(ref, id)
	await deleteDoc(docRef)
}

export const streamCompanyUpdates = (companyId: string) => {
	store.dispatch(setUpdatesLoading(true))
	const q = query(ref, where('company.id', '==', companyId))
	const unsubscribe = onSnapshot(
		q,
		snapshot => {
			const data = snapshot.docs.map(item => item.data() as ICompanyUpdate)
			store.dispatch(setUpdates(data))
		},
		error => {
			store.dispatch(setUpdatesError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}
