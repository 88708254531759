import { useContext } from 'react'

import { AuthContext } from 'context/AuthContext'
import CompanyCommentsFromSharedLinks from './CompanyCommentFromSharedLinks'
import type { ICompany } from 'interfaces'
import { NoteEditorState, NoteType } from 'features/notes/interfaces/notes.interface'
import NotesList from 'features/notes/components/NotesList'
import { addDraftNote } from 'features/notes/redux/draft_notes.slice'
import { listenToCompanyNotes, useCompanyNotes } from 'features/notes/api/notes.api'
import { nanoid } from 'nanoid'
import {
	selectCompanyNotes
} from 'features/notes/redux/notes.slice'
import { store } from 'app/store'
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useAppSelector } from 'app/hooks'

interface Props {
	company: ICompany,
	isReadOnly?: boolean
}

export default function CompanyNotes({ company, isReadOnly }: Props) {
	const { authUser } = useContext(AuthContext)
	// const { isLoading } = useAppSelector(state => state.notes)
	// const filteredNotes = useAppSelector(state =>
	// 	selectCompanyNotes(state, company.id)
	// )

	const { companyNotes, isLoading } = useCompanyNotes(company.id);


	// useEffect(() => {
	// 	const unsubscribe = listenToCompanyNotes(company.id)
	// 	return () => unsubscribe()
	// }, [])

	return (
		<div>
			<CompanyCommentsFromSharedLinks company={company} />
			<NotesList
				isReadOnly={isReadOnly}
				showTitle
				loadingNotes={isLoading}
				notes={companyNotes}
				onCreateDraftNote={() => {
					if (!authUser) return
					store.dispatch(
						addDraftNote({
							id: nanoid(),
							windowState: NoteEditorState.RESTORED,
							company: {
								id: company.id,
								name: company.name,
								website: company.website ?? ''
							},
							noteType: NoteType.Company,
							creator: { id: authUser.id, name: authUser.name, photoUrl: authUser.photoUrl ?? '' },
							fund: authUser.fund
						})
					)
				}}
			/>
		</div>
	)
}
