/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Button from 'components/Buttons/Button'
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue
} from 'components/ui/select'
import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetHeader,
	SheetTitle,
	SheetTrigger
} from 'components/ui/sheet'
import { useFormik } from 'formik'
import logger from 'lib/logger'
import { ListX, SlidersHorizontal, Trash2 } from 'lucide-react'
import * as Yup from 'yup'
import useDataFilterHook from '../hooks/useDataFilterHook'
import type { IFilterType } from '../interfaces/filter.interface'
import RenderFilterValueSelector from './RenderFilterValueSelector'

const filterKeyOptions = [
	{ filter: 'string', value: ['is', 'has any', 'is not'] },
	{ filter: 'funnel', value: ['is', 'is not'] },
	{ filter: 'text', value: ['is', 'has any', 'is not'] },
	{ filter: 'company', value: ['is', 'is not'] },
	{ filter: 'name', value: ['is', 'has any', 'is not'] },
	{ filter: 'email', value: ['is', 'has any', 'is not'] },
	{ filter: 'select', value: ['includes', 'does not include'] },
	{ filter: 'tags', value: ['includes', 'does not include'] },
	{ filter: 'date', value: ['date equals', 'before', 'after'] }
]

interface Props {
	filters: IFilterType[]
	trigger: React.ReactNode
	entity: string
}

export function DataFilter({ filters, trigger, entity }: Props) {
	const { selectedFilters, removeFilter, addFilter } = useDataFilterHook()

	const formik = useFormik({
		initialValues: {
			filter: '',
			operation: '',
			value: ''
		},
		validationSchema: Yup.object({
			filter: Yup.string().required('Filter is required'),
			operation: Yup.string().required('Operation is required'),
			value: Yup.mixed().required('Value is required')
		}),
		onSubmit: async values => {
			try {
				addFilter({
					filter: values.filter,
					operation: values.operation,
					value: values.value
				})

				formik.setValues({
					filter: 'Name',
					operation: 'is',
					value: ''
				})
			} catch (error) {
				logger.info(error)
			}
		}
	})

	return (
		<Sheet>
			<SheetTrigger asChild>
				{trigger || (
					<Button variant='ghost' className='flex items-center gap-1'>
						<SlidersHorizontal color='black' size={15} />
						Filters
					</Button>
				)}
			</SheetTrigger>
			<SheetContent className='w-[700px] sm:w-[740px]'>
				<SheetHeader>
					<SheetTitle className='flex items-center gap-2'>
						<SlidersHorizontal color='black' size={15} />
						Filters
					</SheetTitle>
					<SheetDescription>
						You can stack filters to create a complete filter group.
					</SheetDescription>
				</SheetHeader>

				<div className='mt-5 flex flex-col gap-2 border-b border-b-gray-400 pb-5'>
					<div className='flex w-full items-center gap-2'>
						<div>Selected Filters</div>
						<div className='h-[1px] flex-1 rounded bg-gray-400' />
					</div>
					{selectedFilters.map((filter, index) => (
						<div key={filter.filter} className='flex items-center gap-2'>
							<div
								className='cursor-pointer p-1 transition-all hover:scale-110'
								onClick={() => {
									removeFilter(index)
								}}
							>
								<Trash2 color='red' className='text-white' size={15} />
							</div>
							<div className='truncate rounded border border-red-400 bg-red-100 px-1 text-sm text-red-700'>
								{filters.find(item => item.filterKey === filter.filter)?.label}
							</div>
							<div className='text-sm'>{filter.operation}</div>
							<div className='truncate rounded border border-blue-400 bg-blue-100 px-1 text-sm text-blue-700'>
								{filter.value}
							</div>
						</div>
					))}

					{selectedFilters.length === 0 && (
						<div className='flex items-center gap-2'>
							<ListX color='black' />
							No filters selected
						</div>
					)}
				</div>

				<div className='mt-5 flex flex-col'>
					<div>
						<div>Filter</div>
						<Select
							value={formik.values.filter}
							onValueChange={e => {
								formik.setFieldValue('filter', e)
							}}
						>
							<SelectTrigger className='flex-1'>
								<SelectValue placeholder='Select a filter' />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									<SelectLabel>Filters</SelectLabel>
									{filters.map(filter => (
										<SelectItem key={filter.filterKey} value={filter.filterKey}>
											{filter.label}
										</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
					</div>
					<div className='mt-3'>
						<div>Operation</div>
						<Select
							value={formik.values.operation}
							onValueChange={e => {
								formik.setFieldValue('operation', e)
							}}
						>
							<SelectTrigger className='flex-1'>
								<SelectValue placeholder='Select an opearion' />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									<SelectLabel>Operations</SelectLabel>
									{filterKeyOptions
										.find(
											option =>
												option.filter ===
												filters.find(
													item => item.filterKey === formik.values.filter
												)?.dataType
										)
										?.value.map(filter => (
											<SelectItem
												key={filter}
												value={filter}
												onClick={() => {
													formik.setFieldValue('operation', filter)
												}}
											>
												{filter}
											</SelectItem>
										))}
								</SelectGroup>
							</SelectContent>
						</Select>
					</div>
					<div className='mt-3'>
						<div>Value</div>
						<RenderFilterValueSelector
							formik={formik}
							entity={entity}
							dataType={
								filters.find(item => item.filterKey === formik.values.filter)
									?.dataType || 'text'
							}
						/>
					</div>

					<Button
						className='mt-5'
						disabled={formik.isSubmitting || !formik.isValid}
						onClick={() => {
							formik.handleSubmit()
						}}
					>
						Add Filter
					</Button>
				</div>
			</SheetContent>
		</Sheet>
	)
}
