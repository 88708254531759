/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable react/function-component-definition */

import { CalendarDays, ChevronDown, ChevronUp, CirclePlus, Hash, SquareChevronDown, Tag, TextCursorInput, Type, X } from "lucide-react";
import { FC, useContext, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { selectSelectedButton, setSelectedButton } from "../hooks/customColumn.slice";

import { AuthContext } from 'context/AuthContext'
import Button from "components/Buttons/Button";
import CustomColumnAPI from "../api/columns.api";
import Logger from "lib/logger";
import { firestore } from "lib/firebase";
import { nanoid } from "nanoid";
import { toast } from "sonner";
import { useAppSelector } from "app/hooks";
import { useDispatch } from "react-redux";

interface PageProps {
  entity: string
}

const items = [
  { label: 'Dropdown', icon: SquareChevronDown, value: 'dropdown' },
  { label: 'Text', icon: Type, value: 'text' },
  { label: 'Date', icon: CalendarDays, value: 'date' },
  { label: 'Text area', icon: TextCursorInput, value: 'textarea' },
  { label: 'Labels', icon: Tag, value: 'label' },
  { label: 'Numbers', icon: Hash, value: 'numbers' },
];

const CreateColumn: FC<PageProps> = ({ entity }) => {
  const dispatch = useDispatch();
  const columnApi = CustomColumnAPI();
  const { authUser } = useContext(AuthContext)
  const [options, setOptions] = useState([{ index: 0, value: '' }]);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const selectedColumn = useAppSelector(selectSelectedButton)
  const [columnData, setColumnData] = useState({
    fieldName: '',
    description: '',
    options: []
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleOptionChange = (index: number, newValue: string) => {
    const updatedOptions = options.map((option, i) =>
      i === index ? { ...option, value: newValue } : option
    );
    setOptions(updatedOptions);
    setColumnData(prevData => ({
      ...prevData,
      options: updatedOptions
    }));
  };

  const handleAddOption = () => {
    const newOption = { index: options.length, value: '' };
    const updatedOptions = [...options, newOption];
    setOptions(updatedOptions);
    setColumnData(prevData => ({
      ...prevData,
      options: updatedOptions
    }));
  };


  const handleRemoveOption = (index: number) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
    setColumnData(prevData => ({
      ...prevData,
      options: updatedOptions
    }));
  };

  const handleClick = (value: string) => {
    dispatch(setSelectedButton(value));
    setShowOptions(false);
    setColumnData({
      fieldName: '',
      description: '',
      options: []
    });
    setOptions([{ index: 0, value: '' }]);
  }

  const handleColumnDataChange = (e: { target: { name: string; value: any; }; }) => {
    const { name, value } = e.target;
    setColumnData(prevData => ({
      ...prevData,
      [name]: value
    }));

    if (name === 'dropdown' || name === 'label') {
      setColumnData(prevData => ({
        ...prevData,
        [name]: value,
        options: options.map(option => ({ ...option, fieldName: value }))
      }));
    }
  };


  const addColumn = async () => {
    const columnType = `custom_${selectedColumn}`;
    const tableEntity = entity;

    try {
      // Query the existing columns with type equal to tableEntity
      const q = query(collection(firestore, 'columns'), where('type', '==', tableEntity));
      const querySnapshot = await getDocs(q);

      // Determine the highest current index among these columns
      let highestIndex = -1;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.index > highestIndex) {
          highestIndex = data.index;
        }
      });

      // Set the index for the new column to be one more than the highest current index
      const newIndex = highestIndex + 1;

      // Create the new column data
      const data = {
        id: nanoid(),
        name: columnData.fieldName,
        type: tableEntity,
        fund: {
          id: authUser?.fund.id,
          name: authUser?.fund.name,
          slug: authUser?.fund.slug
        },
        creator: authUser?.name,
        key: columnData.fieldName.toLowerCase(),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        dataType: columnType,
        options: columnData.options.map(option => option.value),
        color: '#4CAF50',
        index: newIndex,
        isVisible: true,
        isHidden: false,
        canBeHidden: true,
        canBeSorted: true,
        isPinned: false,
        canBeFiltered: true
      };

      // Add the new column to Firestore
      setIsLoading(true);
      await columnApi.createColumn(data);
      await columnApi.createCustomFields(data);
      toast.success('Column added successfully');
      setIsLoading(false);
    } catch (error) {
      Logger.error("Error adding document:", error);
    }
  };


  const capitalizeFirstLetter = (value: string) => {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  const getIconByValue = (value: string, size = 18) => {
    const item = items.find(item => item.value === value);
    const IconComponent = item ? item.icon : null;
    return IconComponent ? <IconComponent size={size} className="text-blue-600" /> : null;
  };

  const ClickableItem = ({ onClick, icon: Icon, label }) => (
    <div
      onClick={onClick}
      className="flex items-center gap-2 hover:cursor-pointer hover:bg-slate-100 p-2"
    >
      <Icon size={18} className="text-blue-600" />
      {label}
    </div>
  );

  const disableBtn = () => {
    return !columnData.fieldName ||
      ((columnData.fieldName === 'dropdown' || columnData.fieldName === 'label') &&
        (options.length === 0));
  };


  return (
    <div className="p-3">
      <div className="flex items-center justify-between w-full mb-3">
        <p className="text-blue-600 font-medium">Type</p>
        <X
          className="hover:cursor-pointer"
          onClick={() => handleClick(null)}
          size={20} />
      </div>

      <div className="relative">
        <div onClick={() => setShowOptions(!showOptions)} className="w-full border rounded-md p-2 mb-3 flex items-center justify-between hover:cursor-pointer">
          <div className="flex items-center gap-2">
            {getIconByValue(selectedColumn)}
            <p>{capitalizeFirstLetter(selectedColumn)}</p>
          </div>
          {showOptions ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
        {showOptions && (
          <div className="absolute z-50 shadow-lg border p-3 bg-white w-full rounded-md">
            {items.map((item) => (
              <ClickableItem
                key={item.value}
                onClick={() => handleClick(item.value)}
                icon={item.icon}
                label={item.label}
              />
            ))}
          </div>
        )}
      </div>

      <div className="mb-5">
        <p className="text-blue-600 font-medium mb-2">Field Name</p>
        <input type="text" name="fieldName" id="" value={columnData.fieldName} onChange={handleColumnDataChange} placeholder="Enter a name" className="bg-slate-100 rounded-md w-full h-7 border-none" />
      </div>

      <div className="mb-5">
        <p className="mb-2 text-blue-600">
          <span className=" font-medium mr-1">Description</span>
          <span>(optional)</span>
        </p>
        <input type="text" name="description" id="" value={columnData.description} onChange={handleColumnDataChange} className="bg-slate-100 rounded-md w-full h-7 border-none" />
        <span className="text-gray-600 text-xs">View descriptions when hovering over fields in tasks or views</span>
      </div>

      {(selectedColumn === 'label' || selectedColumn === 'dropdown') && (
        <div className="mb-5">
          <div className="flex items-center justify-between w-full text-blue-600  mb-2">
            <p className="font-medium">Options</p>
            <CirclePlus size={20} onClick={handleAddOption} className="hover:cursor-pointer" />
          </div>
          {options.map((option, index) => (
            <div key={option.index} className="flex items-center relative mb-2">
              <input
                type="text"
                name={option.value}
                value={option.value}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                placeholder="Option name"
                className="relative bg-slate-100 rounded-md w-full h-7 border-none"
              />
              <X
                size={18}
                onClick={() => handleRemoveOption(index)}
                className="absolute right-3 text-gray-600 hover:cursor-pointer"
              />
            </div>
          ))}
        </div>
      )}

      <div className="flex items-center gap-2">
        <Button
          disabled={isLoading || disableBtn()}
          isLoading={isLoading}
          onClick={addColumn} className="w-full">Create</Button>
        <Button
          disabled={isLoading}
          className="w-full" variant="danger" onClick={() => handleClick(null)}>Cancel</Button>
      </div>
    </div>
  )
}

export default CreateColumn;