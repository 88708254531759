import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import { DataTable } from 'features/dataTable/DataTable'
import type { ICompany } from 'interfaces'
import type { Column as KanbanViewColumns } from 'features/kanban/views/KanbanView'
import { listenToColumns } from 'features/columns/columns.api'
import { selectCompanyColumns } from 'features/columns/column.slice'
import { sortByFunnelName } from '../helpers/companies.helper'
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
// import { AuthContext } from 'context/AuthContext'
// import { listenToColumns } from 'features/columns/columns.api'
import { useAppSelector } from 'app/hooks'
import useDataTableHook from 'features/dataTable/hooks/useDataTableHook'

interface IAllCompaniesTableProps {
	className?: string
	companies: ICompany[]
	loading?: boolean,
	kanbanColumns: KanbanViewColumns[]
}

export default function CompanyGroupedList({
	className,
	companies,
	loading,
	kanbanColumns
}: IAllCompaniesTableProps): JSX.Element {
	const { authUser } = useContext(AuthContext)
	const columnsData = useAppSelector(selectCompanyColumns);
	const { columns, columnData } = useDataTableHook({
		columnData: columnsData,
		detailsRoute: 'company',
		meta: {
			type: 'group-list',
			columns: kanbanColumns
		}
	})

	const [columnVisibilityState, setColumnVisibilityState] =
		useState<Record<string, boolean>>()
		
	const [companyData, setCompanyData] =
		useState<ICompany[]>([])

		

	useEffect(() => {
		setCompanyData(sortByFunnelName(kanbanColumns, companies));
	}, [kanbanColumns, companies])

	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribe = listenToColumns(authUser.fund.id)
		return () => unsubscribe()
	}, [authUser?.fund.id])

	useEffect(() => {
		const state: Record<string, boolean> = {}

		for (const data of columnsData) {
			if (data.isHidden) state[data.key] = false
		}

		setColumnVisibilityState(state)
	}, [columnsData])

	const groupingState = ['funnel.name']

	return (
		<div className={`${className} flex flex-col`}>
			<div className='relative pb-2'>
				<DataTable
					columns={columns}
					data={companyData}
					columnVisibilityState={columnVisibilityState}
					groupingState={groupingState}
					isGrouped
					loading={loading}
					entity='company'
				/>
			</div>
		</div>
	)
}
