import type { ILimitedPartner } from 'interfaces'
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface LPSliceState {
	data: ILimitedPartner[]
	isLoading: boolean
	error?: string | null
}

const initialState: LPSliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const lpsSlice = createAppSlice({
	name: 'lps',
	initialState,
	reducers: create => ({
		setLPsError: create.reducer((state, action: PayloadAction<string>) => {
			state.isLoading = false
			state.error = action.payload
		}),
		setLPsLoading: create.reducer((state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload
		}),
		setLPs: create.reducer((state, action: PayloadAction<ILimitedPartner[]>) => {
			state.isLoading = false
			state.data = action.payload
		})
	}),
	selectors: {
		selectLPs: lp => lp.data,
		selectLPsLoading: lp => lp.isLoading
	}
})

export const { setLPsLoading, setLPsError, setLPs } = lpsSlice.actions

export const { selectLPs, selectLPsLoading } = lpsSlice.selectors
