/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "context/AuthContext";
import BarChart from "../components/BarChart";
import { ComboSelect } from "components/shadcn/ComboSelect";
import type { IBarChartData } from "../interfaces/reports.interface";
import { InvestmentService } from "features/investments/service/investment.service";
import { getConvertedAmount } from "utils/currency";
import { selectInvestments } from "features/investments/redux/investments.slice";
import { useAppSelector } from "app/hooks";

const years = Array.from(
  { length: 5 },
  (_, index) => new Date().getFullYear() - index
);


export default function InvestmentChart(): JSX.Element {

  const {authUser} = useContext(AuthContext);

  const investments = useAppSelector(selectInvestments); 

  const [data, setData] = useState<IBarChartData[]>();

  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );

  useEffect(() => {
    if(!authUser?.fund.id) return;

    const unsubscribe = InvestmentService.listenToInvestments(authUser.fund.id);

    // eslint-disable-next-line consistent-return
    return () => unsubscribe();
  }, [authUser?.fund.id]);

  useEffect(() => {
    // Filter investments by selected year
    const filteredInvestments = investments.filter(
      (investment) => new Date(investment.date).getFullYear() === selectedYear
    );
    
    // group investments by date into Jan, Fed, Mar, etc
    // eslint-disable-next-line unicorn/no-array-reduce, @typescript-eslint/no-explicit-any
    const groupByDate = filteredInvestments.reduce((acc: any, investment) => {
      const date = new Date(investment.date).toLocaleString('default', { month: 'short' });
      if(acc[date]) {
        acc[date] += getConvertedAmount({amount: investment.amount, currency: investment.currency}).amount;
      } else {
        acc[date] = getConvertedAmount({amount: investment.amount, currency: investment.currency}).amount;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return acc;
    }, {});

    const data = Object.keys(groupByDate).map((key) => ({
      name: key,
      value: groupByDate[key]
    }));

    setData(data as any);
  }, [investments, selectedYear]);

  if(!data) {
    return <div>Loading...</div>
  }
  
	return  (
    <div className="border bg-white border-[#E4E5E8] rounded-md px-4 py-5 min-w-[570px]">
      <div className="font-semibold flex justify-between">
        Investments


        <div className='flex items-center justify-center space-x-2 border px-2 rounded-md -mt-2'>
        <ComboSelect
          onSelect={value => {
            // eslint-disable-next-line radix
            setSelectedYear(Number.parseInt(value));
          }}
          defaultValue={selectedYear.toString()}
          content={
            <div className='flex items-center gap-2 pr-2'>
              <div className='text-sm'>{selectedYear}</div>
            </div>
          }
          options={years.map(year => ({
            value: year.toString(),
            label: year
          }))}
        />
          </div>
      </div>
      <div>
        <BarChart data={data} />
      </div>
    </div>
  )
}
