/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/promise-function-async */
import { AuthContext } from 'context/AuthContext'
import { useFormik } from 'formik'
import type { Founder, ICompany } from 'interfaces'
import { useContext } from 'react'
import { toast } from 'sonner'
import * as Yup from 'yup'
import { updateCompany } from '../api/companies.api'
import { nanoid } from 'nanoid'
import { ActivityService } from 'features/activity/service/activity.service'
import type { IActivity } from 'features/activity/interfaces/actvitiy.interface'

export default function useUpdateFounders({
	onSuccess,
	company,
	founder
}: {
	onSuccess?: () => void
	company: ICompany
	founder?: Founder
}) {
	const { authUser } = useContext(AuthContext)

	const validationSchema = Yup.object({
		name: Yup.string().required('Name is required'),
		email: Yup.string()
			.required('Email is required')
			.email('Invalid email address')
	})

	const formik = useFormik({
		initialValues: {
			name: founder?.name ?? '',
			email: founder?.email ?? '',
			linkedIn: founder?.linkedIn ?? ''
		},
		validationSchema,
		onSubmit: async values => {
			if (!authUser) return
			try {
				formik.setSubmitting(true)
				if (founder) {
					const updatedFounders = company.founders?.map(founderItem => {
						if (founderItem.email === founder.email) {
							return {
								...founderItem,
								...values
							}
						}
						return founderItem
					})
					await updateCompany({
						...company,
						founders: updatedFounders,
						founderEmails: updatedFounders?.map(f => f.email)
					})

					// Add the activity to the activity feed
					const activity: IActivity = {
						id: nanoid(),
						user: authUser,
						fund: authUser.fund,
						activity: 'updated founder',
						description: `${values.name} (${values.email}) updated`,
						icon: '',
						summary: company.name,
						company: {
							id: company.id,
							name: company.name
						},
						createdAt: new Date().toISOString()
					}

					ActivityService.add(activity)

				} else {
					await updateCompany({
						id: company.id,
						founders: [
							...(company.founders ?? []),
							{
								name: values.name,
								email: values.email,
								linkedIn: values.linkedIn
							}
						],
						founderEmails: [...(company.founderEmails ?? []), values.email]
					})

					// Add the activity to the activity feed
					const activity: IActivity = {
						id: nanoid(),
						user: authUser,
						fund: authUser.fund,
						activity: 'added founder',
						description: `${values.name} (${values.email}) added`,
						icon: '',
						summary: company.name,
						company: {
							id: company.id,
							name: company.name
						},
						createdAt: new Date().toISOString()
					}

					ActivityService.add(activity)

				}
				toast.success(
					founder
						? 'Founder updated successfully'
						: 'Founder added successfully'
				)
				onSuccess?.()
			} catch (error: any) {
				toast.error(error.message)
			} finally {
				formik.setSubmitting(false)
				formik.resetForm()
			}
		}
	})

	return { formik }
}
