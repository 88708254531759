import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

export function toCamelCase(input: string): string {
	return input
		.toLowerCase()
		.replaceAll(/^\w|[A-Z]|\b\w|\s+/g, (match, index) =>
			index === 0 ? match.toLowerCase() : match.toUpperCase()
		)
		.replaceAll(/[^\dA-Za-z]+/g, '')
}

export function isValidURL(url: string) {
	try {
		// eslint-disable-next-line no-new
		new URL(url)
		return true
	} catch {
		return false
	}
}
