/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { ICryptoAccount } from '../interface/cryptoAccount.interface'

export interface CryptoAccountSliceState {
	data: ICryptoAccount[]
	isLoading: boolean
	error?: string | null
}

const initialState: CryptoAccountSliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const cryptoAccountsSlice = createAppSlice({
	name: 'cryptoAccounts',
	initialState,
	reducers: create => ({
		setCryptoAccountsError: create.reducer((state, action: PayloadAction<string>) => {
			state.isLoading = false
			state.error = action.payload
		}),
		setCryptoAccountsLoading: create.reducer((state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload
		}),
		setCryptoAccounts: create.reducer((state, action: PayloadAction<ICryptoAccount[]>) => {
			state.isLoading = false
			state.data = action.payload
		})
	}),
	selectors: {
		selectCryptoAccounts: state => state.data,
		selectCryptoAccountsLoading: state => state.isLoading,
		selectDefaultCryptoAccount: state => state.data.find(x => x.isDefault)
	}
})

export const { setCryptoAccountsLoading, setCryptoAccountsError, setCryptoAccounts } = cryptoAccountsSlice.actions

export const { selectCryptoAccounts, selectCryptoAccountsLoading, selectDefaultCryptoAccount } = cryptoAccountsSlice.selectors
