/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as Yup from 'yup'

import { useAppDispatch, useAppSelector } from 'app/hooks'

import Button from 'components/Buttons/Button'
import CardWrapper from '../components/CardWrapper'
import { CustomInput } from 'components/Inputs/CustomInput'
import type { IOnboardingData } from 'interfaces/onboarding.interface'
import RoleSelector from '../components/RoleSelector'
import { UserRole } from 'interfaces'
import { addOnboardingUser } from '../redux/onboarding.slice'
import { auth } from 'lib/firebase'
import { createOnboardingData } from '../service/onboarding.service'
import { nanoid } from 'nanoid'
import { useFormik } from 'formik'

interface IInviteUsersProps {
	nextStep: () => void
	closeModal?: () => void
}

export default function InviteUser({
	nextStep,
	closeModal
}: IInviteUsersProps) {
	const onboardingData = useAppSelector(state => state.onboarding.data)

	const dispatch = useAppDispatch()

	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			role: UserRole.GeneralPartner
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Name is required'),
			email: Yup.string()
				.email()
				.required('Email is required')
				.test('email-unique', 'Email already exists', async value => {
					const exists = auth.currentUser?.email === value
					return !exists
				})
				.test('email-exists', 'Email already exists', async value => {
					const exists = onboardingData.invitedUsers.some(user => {
						return user.email === value
					})
					return !exists
				})
		}),
		onSubmit: values => {
			const user = {
				name: values.name,
				email: values.email,
				role: values.role
			}
			dispatch(addOnboardingUser(user))
		}
	})

	const handleSubmit = async () => {
		const payload: IOnboardingData = {
			id: nanoid(),
			fundName: onboardingData.fundName,
			fundSlug: onboardingData.slug,
			website: onboardingData.website,
			currency: onboardingData.currency,
			invitationId: auth.currentUser?.uid ?? '',
			dataSources: onboardingData.dataSources,
			partnerInvitations: onboardingData.invitedUsers
		}

		createOnboardingData(payload)

		nextStep()
	}

	return (
		<CardWrapper title='Invite User'>
			<div className='space-y-4'>
				<div className='flex space-x-4'>
					<RoleSelector
						roleName={UserRole.GeneralPartner}
						setRole={role => formik.setFieldValue('role', role)}
						isSelected={formik.values.role === UserRole.GeneralPartner}
					/>
					<RoleSelector
						roleName={UserRole.VenturePartner}
						setRole={role => formik.setFieldValue('role', role)}
						isSelected={formik.values.role === UserRole.VenturePartner}
					/>
				</div>
				<CustomInput
					type='text'
					label='Name'
					placeholder='Name'
					value={formik.values.name}
					onChange={(e: any) => formik.setFieldValue('name', e.target.value)}
					onBlur={formik.handleBlur('name')}
					error={formik.touched.name ? formik.errors.name : ''}
					className='w-full rounded border border-gray-300 p-2'
				/>
				<CustomInput
					type='email'
					label='Email'
					placeholder='Email'
					value={formik.values.email}
					onChange={(e: any) => formik.setFieldValue('email', e.target.value)}
					onBlur={formik.handleBlur('email')}
					error={formik.touched.email ? formik.errors.email : ''}
					className='w-full rounded border border-gray-300 p-2'
				/>

				<div className='mt-4 flex gap-4'>
					<Button
						variant='outline'
						onClick={handleSubmit}
						className='w-full rounded p-2'
					>
						Skip
					</Button>
					<Button
						onClick={() => {
							formik.handleSubmit()
							closeModal?.()
						}}
						className='w-full rounded p-2'
					>
						Add User
					</Button>
				</div>
			</div>
		</CardWrapper>
	)
}
