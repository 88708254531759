/* eslint-disable @typescript-eslint/no-explicit-any */
import { Check, Pencil, Trash } from 'lucide-react'
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-shadow */
import {
	Dialog,
	DialogPanel,
	DialogTitle,
	Transition,
	TransitionChild
} from '@headlessui/react'
import { Fragment, useContext, useEffect, useState } from 'react'
import {
	collection,
	deleteDoc,
	doc,
	onSnapshot,
	query,
	setDoc,
	where
} from 'firebase/firestore'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { ImSpinner10 } from 'react-icons/im'
import QuillEditor from 'features/quilleditor/views/QuillEditor'
import { firestore } from 'lib/firebase'
import logger from 'lib/logger'
import { nanoid } from 'nanoid'
import { toast } from 'sonner'

export default function CreateEmailSignature() {
	const [isOpen, setIsOpen] = useState(false)
	const [signatureContent, setSignatureContent] = useState('')
	const [signatureName, setSignatureName] = useState('')
	const [selectedSignature, setSelectedSignature] = useState<any>()
	const [isCreatingSignature, setIsCreatingSignature] = useState(false)
	const { authUser } = useContext(AuthContext)
	const [defaultSignatureId, setDefaultSignatureId] = useState(
		authUser?.defaultEmailSignature || ''
	)

	// Fetch Signatures
	const [isFetchingSignatures, setIsFetchingSignatures] = useState(true)
	const [emailSignatures, setEmailSignatures] = useState<any[]>([])

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	function getAllSignatures() {
		if (!authUser) return
		try {
			const q = query(
				collection(firestore, `email_signatures`),
				where('creator.id', '==', authUser.id)
			)
			const unsubscribe = onSnapshot(q, snapshot => {
				const data = snapshot.docs.map(doc => ({
					...doc.data()
				}))

				setEmailSignatures(data as any[])
				setIsFetchingSignatures(false)
			})

			// eslint-disable-next-line consistent-return
			return () => unsubscribe()
		} catch (error) {
			logger.error(error)
		}
	}

	useEffect(() => {
		getAllSignatures()
		setDefaultSignatureId(authUser?.defaultEmailSignature || '')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authUser?.defaultEmailSignature])

	async function setAsDefault(signatureId: string) {
		if (!authUser) return

		try {
			setDefaultSignatureId(signatureId)
			const d = doc(firestore, `users/${authUser.id}`)
			setDoc(d, { defaultEmailSignature: signatureId }, { merge: true })
			getAllSignatures()
			toast.dismiss()
			toast.success('Default signature set')
		} catch (error) {
			logger.error(error)
		}
	}

	async function addNewSignature() {
		if (!authUser) return
		if (!signatureContent && !signatureName) {
			toast.error('Please enter signature name and content')
			return
		}
		if (!signatureName) {
			toast.error('Please enter signature name')
			return
		}
		if (!signatureContent) {
			toast.error('Please enter signature content')
			return
		}

		try {
			setIsCreatingSignature(true)
			const id = selectedSignature?.id || nanoid()
			const d = doc(firestore, `email_signatures/${id}`)
			await setDoc(d, {
				name: signatureName,
				content: signatureContent,
				id,
				creator: {
					id: authUser.id,
					name: authUser.name,
					photoUrl: authUser.photoUrl ?? ''
				},
				fund: authUser.fund,
				createdAt: new Date().toISOString(),
				lastUpdatedAt: new Date().toISOString()
			})

			// Set as default
			setAsDefault(id)

			toast.success('Signature created')
			closeModal()
		} catch (error) {
			logger.error(error)
		} finally {
			setIsCreatingSignature(false)
		}
	}

	async function deleteSignature(signatureId: string) {
		if (!authUser) return
		const res = confirm('Are you sure you want to delete this signature?')
		if (!res) return
		const d = doc(firestore, `email_signatures/${signatureId}`)
		await deleteDoc(d)

		if (defaultSignatureId === signatureId) {
			setDefaultSignatureId('')
			const d2 = doc(firestore, `users/${authUser.id}`)
			await setDoc(d2, { defaultEmailSignature: '' }, { merge: true })
		}
		toast.dismiss()
		toast.success('Signature deleted')
	}

	return (
		<>
			<div className='mt-2 flex-col'>
				<Button
					variant='blue'
					onClick={() => {
						setSelectedSignature(null)
						setSignatureName('')
						setSignatureContent('')
						openModal()
					}}
				>
					<Pencil size={16} /> Create New
				</Button>
			</div>

			{isFetchingSignatures && <ImSpinner10 className='mt-5 animate-spin' />}

			<div className='mt-2 flex gap-2'>
				{emailSignatures.map(signature => (
					<div
						key={signature.id}
						className='flex w-96 cursor-pointer flex-col rounded-md border'
					>
						<div
							onClick={() => {
								setAsDefault(signature.id)
							}}
							className='flex items-center justify-between'
						>
							<div className='flex w-full items-center justify-between gap-2 border-b px-2 py-1'>
								{signature.name}
								{defaultSignatureId === signature.id && (
									<div className='ml-5 flex items-center justify-center text-green-700'>
										<Check size={16} className='text-green-700' />
									</div>
								)}
							</div>
						</div>
						<div
							onClick={() => {
								setAsDefault(signature.id)
							}}
							className='h-full px-2 py-2 text-sm  text-gray-700'
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: signature.content || ''
							}}
						/>

						<div className=' flex gap-2 border-t pl-2'>
							<div className='flex items-center gap-2'>
								<div
									className='flex items-center gap-2 p-1'
									onClick={() => {
										deleteSignature(signature.id)
									}}
								>
									<Trash size={16} />
								</div>
								<div
									onClick={() => {
										setSignatureName(signature.name)
										setSignatureContent(signature.content)
										setSelectedSignature(signature)
										openModal()
									}}
									className='flex items-center gap-2 p-1'
								>
									<Pencil size={16} />
								</div>
							</div>
						</div>
					</div>
				))}
			</div>

			{isOpen && (
				<Transition appear show={isOpen} as={Fragment}>
					<Dialog as='div' className='relative z-10' onClose={closeModal}>
						<TransitionChild
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0'
							enterTo='opacity-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<div className='fixed inset-0 bg-black/25' />
						</TransitionChild>

						<div className='fixed inset-0 overflow-y-auto'>
							<div className='flex min-h-full items-center justify-center p-4 text-center'>
								<TransitionChild
									as={Fragment}
									enter='ease-out duration-300'
									enterFrom='opacity-0 scale-95'
									enterTo='opacity-100 scale-100'
									leave='ease-in duration-200'
									leaveFrom='opacity-100 scale-100'
									leaveTo='opacity-0 scale-95'
								>
									<DialogPanel className='w-full max-w-3xl transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all'>
										<DialogTitle
											as='h3'
											className='text-lg font-medium leading-6 text-gray-900'
										>
											Name new signature
										</DialogTitle>
										<div className='mt-5'>
											<input
												className='w-full rounded border-gray-300'
												placeholder='Signature name'
												value={signatureName}
												onChange={e => {
													setSignatureName(e.target.value)
												}}
											/>

											<QuillEditor
												value={signatureContent}
												onChange={value => {
													setSignatureContent(value)
												}}
											/>
										</div>

										<div className='mt-4'>
											<Button
												isLoading={isCreatingSignature}
												disabled={isCreatingSignature}
												type='button'
												className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
												onClick={addNewSignature}
											>
												Save
											</Button>
										</div>
									</DialogPanel>
								</TransitionChild>
							</div>
						</div>
					</Dialog>
				</Transition>
			)}
		</>
	)
}
