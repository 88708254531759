import SpokDateRangePicker from "components/shadcn/SpokDateRangePicker";
import { SpokMultiSelect } from "components/shadcn/SpokMultiSelect";
import { useState } from "react";
import type { DateRange } from "react-day-picker";
import type { MultiValue } from "react-select";
import { VISUALIZATION_COMPANIES_MULTI_SELECT_SAMPLE_DATA } from "../data/reports.data";

export default function VisualReportsFilter(): JSX.Element {

   const [dateRangeFilter, setDateRangeFilter] = useState<DateRange | undefined>();
   const [companies, setCompanies] = useState<MultiValue<unknown>>();

   function onSelectDateRange(value: DateRange | undefined) {
      setDateRangeFilter(value);
   }

   function onSelectCompanies(value: MultiValue<unknown>) {
      setCompanies(value);
   }

	return  (
   <div className="flex">
      <div className="mr-5">
         <div className="font-semibold mb-2">Time Period</div>
         <SpokDateRangePicker onSelect={onSelectDateRange}/>
      </div>
      <div className="w-[500px]">
         <div className="font-semibold mb-2">Portfolio</div>
         <SpokMultiSelect onSelect={onSelectCompanies} placeholder="Choose Companies" data={VISUALIZATION_COMPANIES_MULTI_SELECT_SAMPLE_DATA}/>
      </div>
   </div>
  )
}
