import type { ICompany, ILimitedPartner } from 'interfaces'

import type { ICapitalCall } from '../interfaces/capitalcalls.interface'
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface LPSliceState {
	data: ILimitedPartner | null
	isLoading: boolean
	error: string | null
	relevantCompanies: {
		isLoading: boolean
		data: ICompany[]
		error: string | null
	}
	fundLPCapitalCalls: {
		isLoading: boolean
		data: ICapitalCall[]
		error: string | null
	}
}

const initialState: LPSliceState = {
	data: null,
	isLoading: false,
	error: null,
	relevantCompanies: {
		isLoading: false,
		data: [],
		error: null
	},
	fundLPCapitalCalls: {
		isLoading: false,
		data: [],
		error: null
	}
}

export const lpSlice = createAppSlice({
	name: 'lp',
	initialState,
	reducers: create => ({
		setLPError: create.reducer(
			(state, action: PayloadAction<string | null>) => {
				state.error = action.payload
				state.isLoading = false
			}
		),
		setLPLoading: create.reducer((state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload
		}),
		setLP: create.reducer((state, action: PayloadAction<ILimitedPartner>) => {
			state.data = action.payload
			state.isLoading = false
			state.error = null
		}),
		setIsRelevantCompaniesLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.relevantCompanies.isLoading = action.payload
			}
		),
		setRelevantCompanies: create.reducer(
			(state, action: PayloadAction<ICompany[]>) => {
				state.relevantCompanies.data = action.payload
				state.relevantCompanies.isLoading = false
			}
		),
		setFundLPCapitalCalls: create.reducer(
			(state, action: PayloadAction<ICapitalCall[]>) => {
				state.fundLPCapitalCalls.data = action.payload
				state.fundLPCapitalCalls.isLoading = false
			}
		)
	}),
	selectors: {
		selectLP: lp => lp.data,
		selectRelevantCompanies: lp => lp.relevantCompanies,
		selectFundLPCapitalCalls: lp => lp.fundLPCapitalCalls
	}
})

export const {
	setLPLoading,
	setLPError,
	setLP,
	setIsRelevantCompaniesLoading,
	setRelevantCompanies,
	setFundLPCapitalCalls
} = lpSlice.actions

export const {
	selectLP,
	selectRelevantCompanies,
	selectFundLPCapitalCalls
} = lpSlice.selectors
