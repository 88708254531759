import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import { ColumnsService } from 'features/columns/sevice/column.service'
import { DataTable } from 'features/dataTable/DataTable'
import type { ILimitedPartner } from 'interfaces'
import { listenToColumns } from 'features/columns/columns.api'
import { listenToRelevantCompanies } from '../api/lps.api'
import { selectCompanyColumns } from 'features/columns/column.slice'
import { selectRelevantCompanies } from '../redux/lp.slice'
/* eslint-disable consistent-return */
import { useAppSelector } from 'app/hooks'
import useDataTableHook from 'features/dataTable/hooks/useDataTableHook'

interface Props {
	limitedPartner: ILimitedPartner
}

export default function LimitedPartnerRelevantCompanies({
	limitedPartner
}: Props): JSX.Element {
	const { authUser } = useContext(AuthContext)
	const columnsData = useAppSelector(selectCompanyColumns)
	const { columns, columnData } = useDataTableHook({
		columnData: columnsData,
		detailsRoute: 'company'
	})

	const { data, isLoading } = useAppSelector(selectRelevantCompanies)

	useEffect(() => {
		if (!authUser?.fund.id) return
		if (!limitedPartner.tagIds || limitedPartner.tagIds.length <= 0) return

		const unsub1 = listenToColumns(authUser.fund.id)
		const unsub2 = listenToRelevantCompanies(
			limitedPartner.tagIds,
			authUser.fund.id
		)

		return () => {
			unsub1()
			unsub2()
		}
	}, [limitedPartner.tagIds, authUser?.fund.id])

	return (
		<div className='flex flex-col'>
			<div className='relative pb-2'>
				<DataTable
					entity='company'
					columns={columns}
					columnData={columnData}
					data={data}
					allowDownload
					filterPlaceholder='Search companies'
					loading={isLoading}
					onColumnUpdate={updatedColumns => {
						ColumnsService.updateColumns(updatedColumns)
					}}
					onColumnVisibilityChange={(columnId: string, isVisible: boolean) => {
						ColumnsService.editColumn(columnId, { isVisible })
					}}
				/>
			</div>
		</div>
	)
}
