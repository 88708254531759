/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-extraneous-class */
import { store } from "app/store"
import { collection, deleteDoc, doc, onSnapshot, query, setDoc, where } from "firebase/firestore"
import type { User } from "interfaces"
import { firestore } from "lib/firebase"
import { nanoid } from "nanoid"
import type { IDataFilter, IFilter } from "../interfaces/filter.interface"

export class FilterService {

  private static readonly ref = collection(firestore, 'data_filters')

  public static async saveFilter(
    feature: string,
    name: string,
    filters: IFilter[]
  ) {

    const user = store.getState().authentication.authUser
    if (!user) throw new Error('Not authorized to save filter')

    const data: IDataFilter = {
      id: nanoid(),
      name,
      feature,
      filters,
      creator: {
        id: user.id,
        name: user.name,
      },
      fund: {
        id: user.fund.id,
        name: user.fund.name,
        slug: user.fund.slug
      },
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    }

    const docRef = doc(this.ref, data.id)
    await setDoc(docRef, data, { merge: true })
    return data
  }


  // Update Filter
  public static async updateFilter(
    filter: IDataFilter
  ) {
    const docRef = doc(this.ref, filter.id)
    await setDoc(docRef, filter, { merge: true })
    return filter
  }

  // Delete Filter
  public static async deleteFilter(
    filterId: string
  ) {
    const docRef = doc(this.ref, filterId)
    await deleteDoc(docRef)
    return filterId
  }

  // Listen to feature filters
  public static listenToEntityFilters(
    { authUser, feature, callback, loadingCallback }: {
      authUser?: User
      feature: string,
      callback: (filters: IDataFilter[]) => void,
      loadingCallback: (loading: boolean) => void
    }
  ) {

    if (!authUser) throw new Error('Not authorized to read filter')

    loadingCallback(true)
    const q = query(this.ref, where('fund.id', '==', authUser.fund.id), where('feature', '==', feature))
    return onSnapshot(q, (querySnapshot) => {
      const filters = querySnapshot.docs.map(d => d.data() as IDataFilter)
      callback(filters)
      loadingCallback(false)
    })
  }

}