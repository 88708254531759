import type { CompanyData, ICompany } from 'interfaces'
import { doc, getDoc } from 'firebase/firestore'

import { ActivityService } from 'features/activity/service/activity.service'
import Errorhandler from 'lib/sentry'
import type { IActivity } from 'features/activity/interfaces/actvitiy.interface'
import Logger from 'lib/logger'
import { firestore } from 'lib/firebase'
import { getEmailCount } from 'features/mail/api/threads.api'
import { nanoid } from 'nanoid'
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-extraneous-class */
import { store } from 'app/store'
import { toast } from 'sonner'
import { updateCompany } from '../api/companies.api'

function getDescription(value: any) {
	if (Array.isArray(value)) {
		return value.map(v => v.name).join(', ')
	}
	if (typeof value === 'object') {
		return value.name
	}
	return value || ''
}

export class CompanyService {
	public static getCompanyById = async (id: string): Promise<ICompany | null> => {
		try {
			const ref = doc(firestore, 'companies', id)
			const comapnyDoc = await getDoc(ref)
			if (!comapnyDoc.exists()) return null
			const company = comapnyDoc.data() as ICompany
			return company
		} catch (error: any) {
			Logger.error(error)
			Errorhandler.captureException(error)
			return null
		}
	}
	
	public static updateCompany = async ({
		id,
		key,
		value,
		company,
		shouldLogActivity = true,
		shouldShowToast = true
	}: {
		id: string
		key: string
		value: any
		company: CompanyData
		shouldLogActivity?: boolean
		shouldShowToast?: boolean
	}) => {
		try {
			await updateCompany({ id, [key]: value })
			if(shouldShowToast) toast.success('Company updated successfully')

			if (!shouldLogActivity) return

			const { authUserData, authUserFundData } = store.getState().authentication
			if (!authUserData || !authUserFundData) return

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUserData,
				fund: authUserFundData,
				activity: `updated ${key}`,
				description: getDescription(value),
				icon: '',
				summary: company.name,
				company: {
					id: company.id,
					name: company.name
				},
				createdAt: new Date().toISOString()
			}
			await ActivityService.add(activity)
		} catch (error: any) {
			toast.error(error.mesage ?? 'Failed to update company')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static async getTotalEmails(
		userId: string,
		company: ICompany
	): Promise<number> {
		try {
			if (!company.founderEmails) return 0
			const count = await getEmailCount(userId, company.founderEmails)
			return count
		} catch (error: any) {
			Logger.error(error)
			Errorhandler.captureException(error)

			return 0
		}
	}
}
