import {
	collection,
	doc,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	where
} from 'firebase/firestore'

import Errorhandler from 'lib/sentry'
import type { IChat } from '../interfaces/chat.interface'
import Logger from 'lib/logger'
import axiosInstance from 'lib/axios'
import { firestore } from 'lib/firebase'
import { setMessages } from '../redux/conversation.slice'
import { store } from 'app/store'

export const listenToConversation = (userId: string, companyId: string) => {
	const q = query(
		collection(firestore, 'oracle_chat'),
		where('user.id', '==', userId),
		where('company.id', '==', companyId),
		orderBy('createdAt', 'asc')
	)

	const unsubscribe = onSnapshot(q, snapshot => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const data = snapshot.docs.map(doc => doc.data() as IChat)
		store.dispatch(setMessages(data))
	})

	return unsubscribe
}

export const sendMessage = async (message: IChat) => {
	try {
		const d = doc(firestore, 'oracle_chat', message.id)
		await setDoc(d, message)

		await axiosInstance.post('/oracle-chat', message)
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		const errorMessage = error.response?.data?.message || error.message
		Logger.error(errorMessage)
		Errorhandler.captureException(error)
	}
}
