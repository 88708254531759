import clsx from "clsx"
import Button from "components/Buttons/Button"
import { DialogModal } from "components/shadcn/DialogModal"
import { Input } from "components/ui/input"
import useDataFilterHook from "features/filter/hooks/useDataFilterHook"
import { FilterService } from "features/filter/service/filter.service"
import { useFormik } from "formik"
import { Save } from "lucide-react"
import { useState } from "react"
import { toast } from "sonner"
import * as Yup from 'yup'


interface Props {
  entity: string
}

export default function SaveCurrentList({ entity }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const { selectedFilters, renderSelectedFilters } = useDataFilterHook()


  const formik = useFormik({
    initialValues: {
      name: "",
      selectedFilters: selectedFilters || []

    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required')
    }),
    onSubmit: async values => {
      try {
        await FilterService.saveFilter(entity, values.name, selectedFilters)
        toast.success('List saved successfully')
        setIsOpen(false)
      } catch (error) {
        toast.error(error ?? 'Error saving list')
      } finally {
        formik.setSubmitting(false)
        formik.resetForm()
      }
    }
  })

  return (
    <DialogModal
      open={isOpen}
      setOpen={setIsOpen}
      title="Save list"
      description="Save current list"
      trigger={
        <Button
          variant='ghost'
          disabled={selectedFilters.length === 0}
          className={
            clsx(
              'ml-5 truncate',
              selectedFilters.length === 0 && 'opacity-30'
            )
          }
        >
          <Save className='h-4 w-4' color='blue' />
          Save list
        </Button>
      }>
      <Input
        label="List name"
        placeholder="Enter list name"
        error={formik.touched.name && formik.errors.name}
        {...formik.getFieldProps('name')}
      />

      <div className="mt-5">
        <div className="text-xs font-semibold">Selected Filters</div>
        {renderSelectedFilters({ variant: 'vertical', readonly: true })}
      </div>

      <div className="mt-10 flex justify-end">
        <Button
          isLoading={formik.isSubmitting}
          disabled={formik.isSubmitting || !formik.isValid}
          onClick={() => {
            formik.handleSubmit()
          }}
        >
          Save
        </Button>
      </div>
    </DialogModal>
  )
}