import { useContext, useEffect, useState } from 'react'

import ActivityLogs from 'features/activity/views/ActivityLogs'
import { AuthContext } from 'context/AuthContext'
import { ContactService } from '../services/contact.service'
import type { IContact } from 'interfaces'
import { listenToContactActivity } from 'features/activity/api/activity.api'
import { selectContactActivities } from 'features/activity/redux/activities.slice'
import { selectContactNotesCount } from 'features/notes/redux/notes.slice'
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppSelector } from 'app/hooks'

interface Props {
	contact: IContact
}

export default function ContactActivities({ contact }: Props) {
	const { authUser } = useContext(AuthContext)
	const isLoading = false
	const activities = useAppSelector(state =>
		selectContactActivities(state, contact.id)
	)

	const totalNotes = useAppSelector(state =>
		selectContactNotesCount(state, contact.id)
	)

	useEffect(() => {
		const unsubscribe = listenToContactActivity(contact.id)
		return () => unsubscribe()
	}, [contact.id])

	const [totalEmails, setTotalEmails] = useState(0)

	useEffect(() => {
		if (!authUser?.id) return

		const fetchEmails = async () => {
			const count = await ContactService.getTotalEmails(authUser.id, contact)
			setTotalEmails(count)
		}

		fetchEmails()
	}, [contact.id, authUser?.id])

	return (
		<ActivityLogs
			activities={activities}
			loading={isLoading}
			totalEmails={totalEmails}
			totalNotes={totalNotes}
			eventEmails={contact.email ? [contact.email] : []}
		/>
	)
}
