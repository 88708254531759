import type React from 'react'
import { useEffect, useRef, useState } from 'react'
import clsxm from 'utils/clsxm'
import { motion } from 'framer-motion'
import Button from './Buttons/Button'

const variants = {
	show: {
		y: -6,
		opacity: 1
	},
	hide: {
		y: 0,
		opacity: 0
	}
}

interface IPopConfirmProps {
	title?: React.ReactNode
	message?: React.ReactNode
	onConfirm: () => void
	confirmText?: string
	cancelText?: string
	wrapperClass?: string
	renderTrigger: (trigger: () => void) => React.ReactNode
}
function PopConfirm({
	title,
	message,
	onConfirm,
	confirmText = 'Yes',
	renderTrigger,
	cancelText = 'No',
	wrapperClass
}: IPopConfirmProps) {
	const [positionX, setPositionX] = useState<'center' | 'left' | 'right'>(
		'center'
	)
	const [positionY, setPositionY] = useState<'bottom' | 'top'>('bottom')
	const [showPopConfirm, setShowPopConfirm] = useState(false)
	const contentRef = useRef<HTMLDivElement>(null)
	const wrapperRef = useRef<HTMLDivElement>(null)

	function positionContent() {
		if (!contentRef.current || !wrapperRef.current) return

		const contentBoundary = contentRef.current.getBoundingClientRect()
		const wrapperBoundary = wrapperRef.current.getBoundingClientRect()
		const { clientWidth, clientHeight } = document.body

		setPositionY(
			contentBoundary.height + wrapperBoundary.bottom < clientHeight
				? 'bottom'
				: 'top'
		)

		setPositionX(
			contentBoundary.width + wrapperBoundary.right < clientWidth
				? 'right'
				: 'left'
		)
	}

	useEffect(() => {
		window.addEventListener('resize', positionContent)

		return () => {
			window.removeEventListener('resize', positionContent)
		}
	}, [])

	useEffect(() => {
		const handleClickOutside = (evt: Event) => {
			if (
				wrapperRef.current &&
				!wrapperRef.current.contains(evt.target as Node)
			) {
				setShowPopConfirm(false)
			}
		}
		document.addEventListener('click', handleClickOutside)
		positionContent()

		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	function handleConfirmation() {
		onConfirm()
		document.body.click()
	}

	return (
		<div
			className={clsxm('relative z-20 h-fit w-fit ', wrapperClass)}
			ref={wrapperRef}
		>
			{renderTrigger(() => setShowPopConfirm(!showPopConfirm))}

			<motion.div
				className={clsxm(
					'absolute w-max rounded border border-gray-200 bg-white p-4 shadow-md',
					'items-start',
					!showPopConfirm && 'pointer-events-none',
					{
						'left-full': positionX === 'right',
						'right-full': positionX === 'left',
						'bottom-full': positionY === 'top',
						'top-full': positionY === 'bottom'
					}
				)}
				initial={{ opacity: 0 }}
				animate={showPopConfirm ? 'show' : 'hide'}
				variants={variants}
				ref={contentRef}
			>
				{title && <strong className='text-xs'>{title}</strong>}
				{message && <p className='text-sm'>{message}</p>}

				<div className='mt-2 flex items-center justify-end gap-x-2'>
					<Button
						variant='ghost'
						type='button'
						className='px-3 text-xs'
						onClick={() => setShowPopConfirm(false)}
					>
						{cancelText}
					</Button>
					<Button
						className='text-xs'
						variant='dark'
						type='button'
						onClick={handleConfirmation}
					>
						{confirmText}
					</Button>
				</div>
			</motion.div>
		</div>
	)
}

export default PopConfirm
