import { store } from 'app/store'
import type { ICalendarEvent } from 'features/companies/interfaces/calendarEvent.interface'
import {
	collection,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	query,
	where
} from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'
import {
	setCalendarEvents,
	setCalendarEventsLoading
} from '../redux/calendar_events.slice'

const ref = collection(firestore, 'calendar_events')

export const listenToCalendarEvents = (emails: string[]) => {
	store.dispatch(setCalendarEventsLoading(true))
	const q = query(ref, where('allEmails', 'array-contains-any', emails))

	const unbsubscribe = onSnapshot(
		q,
		snapshot => {
			const events = snapshot.docs.map(doc => doc.data() as ICalendarEvent)
			store.dispatch(setCalendarEvents(events))
		},
		error => {
			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unbsubscribe
}

export const getLastEvent = async (
	email: string
): Promise<ICalendarEvent | null> => {
	const q = query(
		ref,
		where('allEmails', 'array-contains', email),
		limit(1),
		orderBy('start', 'desc')
	)
	const snapshot = await getDocs(q)
	if (snapshot.empty) return null

	const [event] = snapshot.docs.map(doc => doc.data() as ICalendarEvent)
	return event
}
