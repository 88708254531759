/* eslint-disable unicorn/no-array-callback-reference */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react/no-array-index-key */
import Skeleton from "components/Skeleton"
import { ChevronDown, ChevronUp, X } from "lucide-react"
import { useSelector } from "react-redux"
import { selectNoteStatus, selectNotes } from "../redux/dashboardHome.slice"
import { DashboardService } from "../services/dashboard.service"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "context/AuthContext"
import { renderDate } from "utils/date.util"
import { Link } from "react-router-dom"
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip"
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar"
import CreateTask from "features/tasks/view/CreateTask"
import Button from "components/Buttons/Button"
import type { INote } from "features/notes/interfaces/notes.interface"
import sanitize from "sanitize-html"

function NoteItem({ note }: { note: INote }) {

  const [expand, setExpand] = useState(false)

  return (
    <div>
      <div
        className='rounded-md border border-gray-200 bg-gray-100 p-3 text-xs'
        dangerouslySetInnerHTML={{ __html: sanitize(expand ? note.note : note.note.slice(0, 200)) }}
      />
      {
        note.note.length > 200 &&
        <Button
          variant="ghost"
          className="text-xs p-1 mt-1"
          onClick={() => {
            setExpand(!expand)
          }}
        >
          {expand ? <>View Less <ChevronUp size={12} /></> : <>View More <ChevronDown size={12} /></>}
        </Button>
      }
    </div>
  )
}

export default function DashboardNotes() {
  const { authUser } = useContext(AuthContext)
  const notes = useSelector(selectNotes)
  const loading = useSelector(selectNoteStatus)

  const hasNotesToday = notes.some(note => new Date(note.createdAt).toDateString() === new Date().toDateString())
  const hasNotesYesterday = notes.some(note => new Date(note.createdAt).toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString())
  const hasNotesFromLastMonthExcludingTodayAndYesterday = notes.some(note => new Date(note.createdAt).getTime() > new Date(new Date().setDate(new Date().getDate() - 30)).getTime() && new Date(note.createdAt).toDateString() !== new Date().toDateString() && new Date(note.createdAt).toDateString() !== new Date(new Date().setDate(new Date().getDate() - 1)).toDateString())

  const hasNoNotes = !hasNotesToday && !hasNotesYesterday && !hasNotesFromLastMonthExcludingTodayAndYesterday

  useEffect(() => {
    if (!authUser?.fund.id) return
    const unsubscribe = DashboardService.listenToFundDashboardNotes({ fundId: authUser.fund.id })
    return () => unsubscribe()
  }, [authUser?.fund.id])

  const renderNotes = (filterFn: (note: INote) => boolean, period: string) => (
    <div className="flex gap-3 border rounded-md p-3 flex-col">
      <div>{period}</div>
      <div>
        {notes.filter(filterFn).map((note, index) => (
          <div key={index} className="flex gap-5">
            <div className="mt-[0.6rem]">
              <div className='text-[0.65rem] whitespace-nowrap w-20'>{renderDate(note.createdAt)}</div>
            </div>
            <div className="flex flex-col gap-3 w-full">
              <div className="flex items-center gap-1">
                {note.company?.id && <Link to={`/company/${note.company?.id}`}>
                  <Tooltip key={note.id}>
                    <TooltipTrigger asChild>
                      <Avatar className='h-8 w-8 border cursor-pointer'>
                        <AvatarImage
                          src={note.company?.website
                            ? `https://www.google.com/s2/favicons?sz=128&domain_url=${note.company.website}`
                            : '/images/logo/Logo-4.png'}
                        />
                        <AvatarFallback>{note.company?.name.charAt(0)}</AvatarFallback>
                      </Avatar>
                    </TooltipTrigger>
                    <TooltipContent>View {note.company?.name}</TooltipContent>
                  </Tooltip>
                </Link>}
                {note.company?.id && <X size={13} color='gray' />}
                <Avatar className='h-8 w-8 border'>
                  <AvatarImage
                    src={note.creator?.photoUrl || '/images/logo/Logo-4.png'}
                  />
                  <AvatarFallback>{note.creator?.name.charAt(0)}</AvatarFallback>
                </Avatar>
                <div className="text-sm ml-2 font-semibold">{note.creator?.name}</div>
              </div>
              <NoteItem note={note} />
              <div className="w-full flex items-end justify-end">
                <CreateTask
                  task={{
                    description: note.note,
                    company: {
                      id: note.company?.id || '',
                      name: note.company?.name || '',
                      website: note.company?.website || ''
                    }
                  }}
                  trigger={
                    <Button id='open-add-company-task' variant='ghost' className="p-1 text-xs font-normal">
                      Add Task
                    </Button>
                  }
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  if (loading) {
    return (
      <div className="flex flex-col gap-3">
        {["Today", "Yesterday"].map(period => (
          <div key={period} className="flex gap-3 border rounded-md p-3 flex-col">
            <div>{period}</div>
            <div>
              {['', '', '', ''].map((_, index) => (
                <div key={index} className="flex gap-5">
                  <div className="mt-[0.6rem]">
                    <Skeleton className='h-3 w-16 rounded-md border' />
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="flex items-center gap-1">
                      <Skeleton className='h-8 w-8 rounded-full border' />
                      <X size={13} color='gray' />
                      <Skeleton className='h-8 w-8 rounded-full border' />
                      <Skeleton className='h-3 w-96 ml-5 rounded-md border' />
                    </div>
                    <Skeleton className='h-16 w-full rounded-md border' />
                    <div className="w-full flex items-end justify-end">
                      <Skeleton className='h-3 w-32 rounded-md border' />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-3">
      {hasNotesToday && renderNotes(note => new Date(note.createdAt).toDateString() === new Date().toDateString(), "Today")}
      {hasNotesYesterday && renderNotes(note => new Date(note.createdAt).toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString(), "Yesterday")}
      {(!hasNotesToday || !hasNotesYesterday) && hasNotesFromLastMonthExcludingTodayAndYesterday && renderNotes(note => new Date(note.createdAt).getTime() > new Date(new Date().setDate(new Date().getDate() - 30)).getTime() && new Date(note.createdAt).toDateString() !== new Date().toDateString() && new Date(note.createdAt).toDateString() !== new Date(new Date().setDate(new Date().getDate() - 1)).toDateString(), "Older")}

      {
        hasNoNotes &&
        <div className="flex flex-col gap-3">
          <div className="flex gap-3 border rounded-md p-3 flex-col">
            <div>
              <div className="flex gap-5">
                <div className="flex flex-col gap-3 w-full">
                  <div className="flex items-center gap-1">
                    <div className="text-sm ml-2 font-semibold">No notes for the past month</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

    </div>
  )
}