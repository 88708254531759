/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { Config } from 'app/config'

const showLogger = Config.SHOW_LOGS === 'true'

enum LogLevel {
	INFO = 'info',
	WARN = 'warn',
	ERROR = 'error'
}

function log(level: LogLevel, ...data: any) {
	if (!showLogger) return

	switch (level) {
		case LogLevel.INFO: {
			console.info(...data)
			break
		}
		case LogLevel.WARN: {
			console.warn(...data)
			break
		}
		case LogLevel.ERROR: {
			console.error(...data)
			break
		}
		default: {
			console.info(...data)
		}
	}
}

const Logger = {
	info: (...data: any) => log(LogLevel.INFO, ...data),
	warn: (...data: any) => log(LogLevel.WARN, ...data),
	error: (...data: any) => log(LogLevel.ERROR, ...data)
}

export default Logger

export { Logger }
