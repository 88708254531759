/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { UserRole } from 'interfaces'
import { createAppSlice } from 'app/createAppSlice'

interface OnboardingState {
	data: {
		fundName: string
		slug: string
		website: string
		fundType: string
		country: string
		currency: string
		dataSources: string[]
		invitedUsers: {
			name: string
			email: string
			role: UserRole
		}[]
	}
}

const initialState: OnboardingState = {
	data: {
		fundName: '',
		slug: '',
		website: '',
		fundType: '',
		country: '',
		currency: '',
		dataSources: [],
		invitedUsers: []
	}
}

export const onboardingSlice = createAppSlice({
	name: 'onboarding',
	initialState,
	reducers: create => ({
		setOnboardingData: create.reducer(
			(state, action: PayloadAction<Partial<OnboardingState['data']>>) => {
				// eslint-disable-next-line no-param-reassign
				state.data = { ...state.data, ...action.payload }
			}
		),
		addOnboardingUser: create.reducer(
			(
				state,
				action: PayloadAction<OnboardingState['data']['invitedUsers'][0]>
			) => {
				state.data.invitedUsers.push(action.payload)
			}
		),
		removeOnboardingUser: create.reducer(
			(state, action: PayloadAction<number>) => {
				const newDataSet = state.data.invitedUsers.filter(
					(_, i) => i !== action.payload
				)
				state.data.invitedUsers = newDataSet
			}
		)
	}),
	selectors: {
		selectOnboardingState: state => state.data
	}
})

export const { setOnboardingData, addOnboardingUser, removeOnboardingUser } =
	onboardingSlice.actions

export const { selectOnboardingState } = onboardingSlice.selectors
