import {
	addInvestment,
	deleteInvestment,
	updateInvestment
} from '../api/investments.api'
import { collection, onSnapshot, query, where } from 'firebase/firestore'

import { ActivityService } from 'features/activity/service/activity.service'
import { Errorhandler } from 'lib/sentry'
import type { IActivity } from 'features/activity/interfaces/actvitiy.interface'
import type { IInvestment } from '../interfaces/investment.interface'
import { Logger } from 'lib/logger'
import { currencyFormatter } from 'utils/currencyformatter.util'
import { firestore } from 'lib/firebase'
import { nanoid } from 'nanoid'
import { setInvestments } from '../redux/investments.slice'
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-extraneous-class */
/* eslint-disable unicorn/no-static-only-class */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'
import { toast } from 'sonner'

export class InvestmentService {
	public static addInvestment = async (investment: IInvestment) => {
		try {
			await addInvestment(investment)

			toast.success('Investment added successfully')

			const { authUserData, authUserFundData } = store.getState().authentication
			if (!authUserData || !authUserFundData) return

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUserData,
				fund: authUserFundData,
				activity: 'added investment',
				summary: '',
				description: currencyFormatter(investment.currency).format(
					investment.amount
				),
				icon: '',
				createdAt: new Date().toISOString()
			}
			if (investment.company) {
				activity.summary = investment.company.name
				activity.company = investment.company
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to add investment')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static updateInvestment = async (investment: IInvestment) => {
		try {
			await updateInvestment(investment)

			toast.success('Investment updated successfully')

			const { authUserData, authUserFundData } = store.getState().authentication
			if (!authUserData || !authUserFundData) return

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUserData,
				fund: authUserFundData,
				activity: 'updated investment',
				summary: '',
				description: currencyFormatter(investment.currency).format(
					investment.amount
				),
				icon: '',
				createdAt: new Date().toISOString()
			}

			if (investment.company) {
				activity.summary = investment.company.name
				activity.company = investment.company
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to update investment')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static deleteInvestment = async (investment: IInvestment) => {
		try {
			await deleteInvestment(investment.id)

			toast.success('Investment deleted successfully')

			const { authUserData, authUserFundData } = store.getState().authentication
			if (!authUserData || !authUserFundData) return

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUserData,
				fund: authUserFundData,
				activity: 'deleted investment',
				summary: '',
				description: currencyFormatter(investment.currency).format(
					investment.amount
				),
				icon: '',
				createdAt: new Date().toISOString()
			}

			if (investment.company) {
				activity.summary = investment.company.name
				activity.company = investment.company
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to delete investment')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static pinInvestment = async (
		investment: IInvestment,
		pinned: boolean
	) => {
		try {
			const newInvestment = { ...investment, isPinned: pinned }

			await updateInvestment(newInvestment)

			toast.success(pinned ? 'Investment pinned' : 'Investment unpinned')

			const { authUserData, authUserFundData } = store.getState().authentication
			if (!authUserData || !authUserFundData) return

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUserData,
				fund: authUserFundData,
				activity: pinned ? 'pinned investment' : 'unpinned investment',
				summary: '',
				description: currencyFormatter(investment.currency).format(
					investment.amount
				),
				icon: '',
				createdAt: new Date().toISOString()
			}

			if (investment.company) {
				activity.summary = investment.company.name
				activity.company = investment.company
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static listenToInvestments = (fundId: string) => {
		const q = query(collection(firestore, "investments"), where("fund.id", "==", fundId));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => doc.data() as IInvestment);
      store.dispatch(setInvestments(data));
    }, (error) => {
			Logger.error(error)
			Errorhandler.captureException(error)
		});

		return unsubscribe;
	}
}
