/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Dialog, Transition } from '@headlessui/react'
import type { IFile } from 'features/files/file.interface'
import { Fragment, useState } from 'react'
import DocFileViewer from 'react-file-viewer'
import { toast } from 'sonner'

export default function DocViewer({
	file,
	content,
	className
}: {
	file: IFile
	content: any
	className: string
}) {
	const [isOpen, setIsOpen] = useState(false)

	const mimeType = file.name.split('.').pop() || file.mimeType

	function closeModal() {
		setIsOpen(false)
	}

	return (
		<div className={className}>
			{file.url ? (
				<>
					<div
						onClick={() => {
							setIsOpen(true)
						}}
					>
						{content}
					</div>

					<Transition appear show={isOpen} as={Fragment}>
						<Dialog as='div' className='relative z-10' onClose={closeModal}>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0'
								enterTo='opacity-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100'
								leaveTo='opacity-0'
							>
								<div className='fixed inset-0 bg-black bg-opacity-25 ' />
							</Transition.Child>

							<div className='fixed inset-0 overflow-y-auto'>
								<div className='flex min-h-full items-center justify-center text-center'>
									<Transition.Child
										as={Fragment}
										enter='ease-out duration-300'
										enterFrom='opacity-0 scale-95'
										enterTo='opacity-100 scale-100'
										leave='ease-in duration-200'
										leaveFrom='opacity-100 scale-100'
										leaveTo='opacity-0 scale-95'
									>
										<Dialog.Panel className='transform  rounded bg-white text-left  transition-all'>
											<div className='flex items-center justify-between px-4'>
												<Dialog.Title
													as='h3'
													className='px-5 font-medium leading-6 '
												>
													<div className='flex-1 py-2 text-xs'>
														<div className='flex flex-col text-xs text-gray-500'>
															Document Viewer
														</div>
														{file.name}
													</div>
												</Dialog.Title>
											</div>

											<div className='h-[calc(100vh-120px)]  overflow-y-auto bg-gray-100 py-3'>
												<DocFileViewer
													fileType={mimeType}
													filePath={file.url}
												/>
											</div>

											<div className=' px-6 py-2'>
												<button
													type='button'
													className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
													onClick={closeModal}
												>
													Close
												</button>
											</div>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</Dialog>
					</Transition>
				</>
			) : (
				<div
					onClick={() => {
						toast.error('No pdf available')
					}}
				>
					{content}
				</div>
			)}
		</div>
	)
}
