/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthContext } from 'context/AuthContext'
import type { IFile } from 'features/files/file.interface'
import { FileService } from 'features/files/services/file.service'
import FileUploader from 'features/fileviewers/views/FileUploader'
import PDFView from 'features/fileviewers/views/PDFViewer'
import type { FileUploadResult } from 'features/storage/hooks/useFirebaseStorageUploader'
import type { ICompany } from 'interfaces'
import Logger from 'lib/logger'
import { Upload } from 'lucide-react'
import { useContext } from 'react'
import { toast } from 'sonner'
import { CompanyService } from '../service/company.service'

interface Props {
	company: ICompany
	url?: string
	thumbnail?: string
	name?: string
	isReadOnly?: boolean
}

export default function CompanyDeckPreview({
	url,
	thumbnail,
	name,
	company,
	isReadOnly
}: Props) {
	const { authUser } = useContext(AuthContext)

	if (!authUser) return null

	const saveFiles = async (results: FileUploadResult[]) => {
		try {
			// const result = await FileService.saveFiles({
			// 	files: results as any[],
			// 	type: 'company_deck',
			// 	authUser
			// })

			if (results.length === 0) return

			const deck = results[0] as any

			// Save the file to firestore
			const data: IFile = {
				id: deck.id,
				name: deck.name,
				fileType: 'company_deck',
				url: deck.url,
				mimeType: deck.type,
				size: deck.size ?? 0,
				fund: authUser.fund,
				creator: {
					id: authUser.id,
					name: authUser.name,
					photoUrl: authUser.photoUrl || ''
				},
				company: {
					id: company.id,
					name: company.name,
					website: company.website || ''
				},
				source: 'spok',
				createdAt: new Date().toISOString(),
				lastUpdatedAt: new Date().toISOString()
			}

			await FileService.addFile(data)

			// Update the company deck
			await CompanyService.updateCompany({
				id: company.id,
				key: 'deck',
				value: {
					id: deck.id,
					name: deck.name,
					url: deck.url
				},
				company
			})
		} catch (error: any) {
			Logger.error(error)
			toast.error(error?.message ?? 'Failed to upload files')
		}
	}

	if (!url && !isReadOnly) {
		return (
			<div className='group m-2 flex flex-col items-center gap-3 rounded-md border p-2 transition-all'>
				<div data-testid='upload-new-deck-label'>Upload new deck</div>
				<FileUploader
					id='company-deck-upload'
					onUpload={saveFiles}
					multiple={false}
				/>
			</div>
		)
	}

	return (
		<div className='mt-1'>
			{!isReadOnly && <div className='flex w-full items-end justify-end'>
				<FileUploader
					id='company-deck-upload'
					onUpload={saveFiles}
					multiple={false}
					child={
						<div className='flex w-full justify-end p-1'>
							<div className='group flex w-fit cursor-pointer items-center gap-2 rounded-full px-2 transition-all hover:border-blue-200 hover:bg-blue-100 hover:text-blue-700'>
								<div className='translate-x-2 opacity-0 transition-all group-hover:translate-x-0 group-hover:opacity-100'>
									Upload new
								</div>
								<Upload size={15} />
							</div>
						</div>
					}
				/>
			</div>}
			<PDFView
				file={
					{
						url,
						name: name || 'Company Deck'
					} as IFile
				}
				content={
					<div
						data-testid='company-deck-thumbnail'
						className='group m-2 rounded-md border p-2 transition-all'
					>
						<img
							src={thumbnail}
							alt='thumbnail'
							className='h-72 w-full cursor-pointer rounded-md object-cover transition-all group-hover:opacity-90'
						/>
					</div>
				}
			/>
		</div>
	)
}
