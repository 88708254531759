import { useEffect, useState } from 'react'

import CommentItem from 'features/companies/submodules/Analytics/components/CommentItem'
import type { ILimitedPartner } from 'interfaces'
import type { ISharedLinkCompanyComment } from 'features/sharedlinks/interfaces/sharedlink.interface'
import { SharedLinksService } from 'features/sharedlinks/services/sharedlinks.service'
/* eslint-disable react/jsx-no-useless-fragment */
import Skeleton from 'components/Skeleton'
import _ from 'lodash'

interface Props {
  limitedPartner: ILimitedPartner
}

export default function LimitedPartnerCompanyComments({ limitedPartner }: Props) {
  const [comments, setComments] = useState<ISharedLinkCompanyComment[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const groupedByCreatedAt = _.groupBy(comments, 'createdAt')

  useEffect(() => {

    if (!limitedPartner.email) {
      setIsLoading(false)
      return
    }

    SharedLinksService.listenToSharedLinkCompanyCommentsByEmail(
      limitedPartner.email || '',
      _comments => {
        setComments(_comments)
      },
      _loading => {
        setIsLoading(_loading)
      }
    )
    
  }, [limitedPartner.email])

  if (isLoading) {
    return (
      <div className='flex h-fit w-full flex-col gap-3 p-5'>
        <Skeleton className='h-6 w-96 border' />
        <Skeleton className='h-6 w-full border' />
        <Skeleton className='h-6 w-full border' />
        <div className='py-2' />
        <Skeleton className='h-6 w-96 border' />
        <Skeleton className='h-6 w-full border' />
        <Skeleton className='h-6 w-full border' />
        <div className='py-2' />
        <Skeleton className='h-6 w-96 border' />
        <Skeleton className='h-6 w-full border' />
        <Skeleton className='h-6 w-full border' />
      </div>
    )
  }

  if (comments.length === 0) {
    return (
      <></>
    )
  }

  return (
    <div className='flex w-full flex-col overflow-auto pb-10'>
      <div className='sticky top-0 z-[2] my-1 flex items-center justify-between bg-white px-4 py-2'>
        <div className='font-semibold text-blue-700'>Comments in companies</div>
      </div>
      <section
        id='company-notes'
        data-cy='notes-page'
        className='relative z-0 flex w-full flex-col gap-4'
      >
        <section className='flex w-full flex-1 flex-col gap-5'>
          {Object.keys(groupedByCreatedAt)
            .sort((a, b) => (a > b ? -1 : 1))
            .filter(key => {
              const yearNotes = groupedByCreatedAt[key]
              return yearNotes.some(note => !note.isPinned)
            })
            .map(key => {
              const monthNotes = groupedByCreatedAt[key]
              return (
                <div key={key} className='relative flex w-full'>
                  <section className='w-full overflow-auto px-2'>
                    <div className='mb-3 flex items-center gap-3 text-xs font-semibold text-gray-500'>
                      <div className='h-[0.05rem] flex-1 bg-gray-200' />
                    </div>
                    {monthNotes
                      .filter(
                        (_commentItem: ISharedLinkCompanyComment) =>
                          !_commentItem.isPinned
                      )
                      .map((_commentItem: ISharedLinkCompanyComment) => (
                        <CommentItem
                          commenterPhotoUrl={limitedPartner.photoUrl}
                          showEmail={false}
                          includeCompany
                          key={_commentItem.id}
                          comment={_commentItem}
                        />
                      ))}
                  </section>
                </div>
              )
            })}
        </section>
      </section>
    </div>
  )
}
