/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { AuthContext } from 'context/AuthContext'
import { listenToPartners } from 'features/partners/partner.api'
import { selectPartners } from 'features/partners/partnerSlice'
import type { Partner, PartnerData } from 'interfaces'
import { useContext, useEffect } from 'react'

interface Props {
	seed?: Partner[] | PartnerData[]
}

export default function useListenToPartners({ seed }: Props) {
	const { authUser } = useContext(AuthContext)
	const dispatch = useAppDispatch()
	const partners = useAppSelector(selectPartners)

	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribe = dispatch(listenToPartners(authUser.fund.id))
		return () => unsubscribe()
	}, [authUser?.fund.id])

	return {
		partners: (partners.length === 0 ? seed : partners) ?? []
	}
}
