import type {
	GeographicDistributionItem,
	IAiReports,
	IBarChartData,
	IFirstCheckAndFollowOns,
	ILineChartData,
	IPortfolioInvestmentChart
} from '../interfaces/reports.interface'

import type { SpokMultiSelectOption } from 'components/shadcn/SpokMultiSelect'
import { nanoid } from 'nanoid'

export const AI_REPORTS_SAMPLE_DATA: IAiReports[] = [
	{
		id: nanoid(),
		company: {
			creator: {
				photoUrl: '',
				name: 'New Guy',
				id: 'Rqaw7LQbtSgVDOaaFUypREZXipJ2'
			},
			createdAt: '2024-04-16T13:33:09.513Z',
			id: '0uD_V9XfHNt2nRbt23so8',
			isApproved: false,
			linkedIn: '',
			slug: 'hello',
			totalNotes: 0,
			funnel: {
				name: 'New List',
				category: 'new list',
				id: 'iSKc6a41P53P4hdPDREaY'
			},
			totalEmails: 0,
			fund: {
				slug: 'test-fund',
				website: 'https://google.com',
				name: 'Test Fund',
				id: '6mCfmYgr1rG_mUrq0KLSf'
			},
			lastUpdatedAt: 'Tue Apr 16 2024 21:30:01 GMT+0000 (Greenwich Mean Time)',
			name: 'Hello'
		},
		reports: [
			`<b>Social media</b>: Follower engagement increased by 20%.`,
			`<b>Website traffic</b>: Doubled organic traffic year-over-year.`,
			`<i>Challenge</i>: Need to improve website conversion rate.`
		],
		tags: ['Single Sentence', 'Single Sentence', 'Single Sentence'],
		creator: {
			id: nanoid(),
			name: 'Jeff'
		},
		createdAt: new Date().toISOString()
	},
	{
		id: nanoid(),
		company: {
			index: 2,
			slug: 'guy',
			funnel: {
				id: 'iSKc6a41P53P4hdPDREaY',
				name: 'New List',
				category: 'new list'
			},
			id: '25VGI-2t7O-v9jPk_NmdS',
			totalNotes: 0,
			fund: {
				slug: 'test-fund',
				website: 'https://google.com',
				id: '6mCfmYgr1rG_mUrq0KLSf',
				name: 'Test Fund'
			},
			lastUpdatedAt: 'Mon Apr 01 2024 10:11:21 GMT+0100 (British Summer Time)',
			creator: {
				photoUrl: '',
				name: 'New Guy',
				id: 'Rqaw7LQbtSgVDOaaFUypREZXipJ2'
			},
			createdAt: '2024-03-29T06:35:39.194Z',
			name: 'Guy'
		},
		reports: [
			'<i>Product development</i>: Successfully launched a new product line ahead of schedule.',
			`<b>Operations</b>: Improved production efficiency by <b>8%</b>.`,
			'<i>Challenges</i>: Minor supply chain disruptions have been identified.'
		],
		tags: ['1 Paragraph', 'Single Sentence', 'Single Sentence'],
		creator: {
			id: nanoid(),
			name: 'Jeff'
		},
		createdAt: new Date().toISOString()
	},

	{
		id: nanoid(),
		company: {
			location: {
				country: 'United States',
				city: 'Accra',
				state: 'American Samoa'
			},
			id: 'Tw9YLLxoLRTRvnhbEsjhw',
			teamMemberEmails: ['francis@mycreativitybox.coms', 'teameshun@gmail.com'],
			website: 'https://audi.com',
			tags: [
				{
					createdAt: '2024-03-18T12:11:52.997Z',
					name: 'Ghana',
					color: '#000000',
					lastUpdatedAt: '2024-03-18T12:11:52.997Z',
					creator: {
						id: 'lqsrPQ0oWqabnQGVXupKRqaJm713',
						photoUrl: '',
						name: 'Francis Eshun'
					},
					category: { name: 'Country' },
					id: 'i_a9x1YYS8Oi7InwJRNha',
					fund: {
						name: 'Dropbox',
						slug: 'test-fund',
						id: '6mCfmYgr1rG_mUrq0KLSf',
						website: 'https://dropbox.com'
					}
				},
				{
					isDeleted: false,
					category: { name: 'Country' },
					id: '2_NVXb5tX2tP6xCpyE60v',
					name: 'Zimbabwe',
					fund: {
						id: '6mCfmYgr1rG_mUrq0KLSf',
						slug: 'test-fund',
						website: 'https://google.com',
						name: 'Test Fund'
					},
					color: '#000000',
					lastUpdatedAt: '2024-03-13T08:40:09.144Z',
					creator: {
						name: 'Raymond Tetteh',
						photoUrl: '',
						id: 'YooNV2iTQdd7YZEZPGpYiLLmVbV2'
					},
					createdAt: '2024-03-13T08:40:09.144Z'
				}
			],
			lastUpdatedAt: '2024-04-29T10:54:52.024Z',
			createdAt: '2024-04-29T10:54:52.024Z',
			name: 'Francis Eshun Boateng',
			teamMembers: [
				{
					name: 'Team Francis Edoted',
					linkedIn: '',
					email: 'francis@mycreativitybox.coms'
				},
				{ name: 'LP Team Eshun', linkedIn: '', email: 'teameshun@gmail.com' }
			],
			category: 'Category',
			fund: {
				id: '6mCfmYgr1rG_mUrq0KLSf',
				slug: 'test-fund',
				website: 'https://www.rolls-royce.com/',
				name: 'Rolls Royce'
			},
			industry: 'Industry',
			isApproved: false,
			totalNotes: 1,
			linkedIn:
				'https://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlong',
			source:
				'https://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlonghttps://linkedin.com/franciseshunlong',
			tagIds: [
				'i_a9x1YYS8Oi7InwJRNha',
				'3VrgyftuumnYYqJkZHyp2',
				'2_NVXb5tX2tP6xCpyE60v'
			],
			totalEmails: 0,
			email: 'eshunfrancisboateng@gmail.com',
			funnel: {
				id: 'TBzCgnk-me-_uNPw3h3Zg',
				name: 'Inbound',
				category: 'inbound'
			},
			creator: {
				name: 'Francis Eshun',
				photoUrl: '',
				id: 'lqsrPQ0oWqabnQGVXupKRqaJm713'
			},
			slug: 'francis-eshun'
		},
		reports: [
			'A new marketing campaign targeting a specific demographic resulted in a significant increase in qualified leads (<b>+30%</b>).',
			'Patient satisfaction scores remain high, with an average rating of 4.8 out of 5 stars on online review platforms.',
			'<i>Areas for Improvement</i>: Streamline appointment scheduling process to reduce wait times.'
		],
		tags: ['Single Sentence', 'Single Sentence', 'Single Sentence'],
		creator: {
			id: nanoid(),
			name: 'Jeff'
		},
		createdAt: new Date().toISOString()
	},
	{
		id: nanoid(),
		company: {
			createdAt: '2024-05-02T09:09:51.397Z',
			lastUpdatedAt: '2024-05-02T09:09:51.397Z',
			funnel: {
				id: 'TBzCgnk-me-_uNPw3h3Zg',
				category: 'inbound',
				name: 'Inbound'
			},
			slug: 'eshun',
			totalEmails: 0,
			name: 'Eshun',
			isApproved: false,
			creator: {
				photoUrl: '',
				name: 'Francis Eshun',
				id: 'lqsrPQ0oWqabnQGVXupKRqaJm713'
			},
			email: '',
			fund: {
				slug: 'test-fund',
				id: '6mCfmYgr1rG_mUrq0KLSf',
				name: 'Rolls Royce',
				website: 'https://www.rolls-royce.com/'
			},
			linkedIn: '',
			id: 'ew81x0qi39eW43TUgwK-8',
			totalNotes: 0
		},
		reports: [
			'The sales team successfully closed <b>20% more deals</b> compared to the previous quarter.',
			'A new logistics management system has been implemented, leading to a <b>10% reduction</b> in delivery turnaround times.',
			'<i>Challenge</i>: Optimize warehouse layout to improve picking and packing efficiency.'
		],
		tags: ['Single Sentence', 'Single Sentence', 'Single Sentence'],
		creator: {
			id: nanoid(),
			name: 'Jeff'
		},
		createdAt: new Date().toISOString()
	},
	{
		id: nanoid(),
		company: {
			funnel: {
				id: 'TBzCgnk-me-_uNPw3h3Zg',
				name: 'Inbound',
				category: 'inbound'
			},
			lastUpdatedAt: '2024-05-01T09:59:10.751Z',
			email: '',
			creator: {
				photoUrl: '',
				id: 'VuY1cKww5wYPVtN4AFo78Ciwyzx2',
				name: 'Guy'
			},
			name: 'Guy 2',
			totalEmails: 0,
			id: 'uhQHUSItSYsoMQnABjXNN',
			linkedIn: '',
			totalNotes: 0,
			fund: {
				name: 'Test Fund',
				id: '6mCfmYgr1rG_mUrq0KLSf',
				slug: 'test-fund',
				website: 'https://google.com'
			},
			createdAt: '2024-05-01T09:59:10.751Z',
			slug: 'guy-2',
			isApproved: false
		},
		reports: [
			'A strategic partnership with a major retailer drove a surge in new customer acquisitions.',
			'The onboarding process has been revamped to ensure a smooth and positive customer experience.',
			'Customer churn rate remains below industry benchmarks thanks to ongoing loyalty programs and exceptional customer service.'
		],
		tags: ['1 Paragraph', 'Single Sentence', 'Single Sentence'],
		creator: {
			id: nanoid(),
			name: 'Jeff'
		},
		createdAt: new Date().toISOString()
	},
	{
		id: nanoid(),
		company: {
			email: '',
			slug: 'francis-here',
			fund: {
				slug: 'test-fund',
				name: 'Rolls Royce',
				id: '6mCfmYgr1rG_mUrq0KLSf',
				website: 'https://www.rolls-royce.com/'
			},
			linkedIn: '',
			totalEmails: 0,
			totalNotes: 0,
			id: 'kWrZ6zSUGUPPEUt5-kHu_',
			lastUpdatedAt: '2024-04-16T13:36:13.126Z',
			createdAt: '2024-04-16T13:36:13.126Z',
			funnel: {
				category: 'inbound',
				id: 'TBzCgnk-me-_uNPw3h3Zg',
				name: 'Inbound'
			},
			isApproved: false,
			name: 'Francis here'
		},
		reports: [
			'<i>Increased investment</i> in online advertising channels resulted in a significant boost in course registrations.',
			'A new student support program has been launched to improve student success rates.',
			'Faculty development initiatives are underway to enhance the overall quality of course instruction.'
		],
		tags: ['Single Sentence', 'Single Sentence', 'Single Sentence'],
		creator: {
			id: nanoid(),
			name: 'Jeff'
		},
		createdAt: new Date().toISOString()
	},
	{
		id: nanoid(),
		company: {
			funnel: {
				id: 'TBzCgnk-me-_uNPw3h3Zg',
				name: 'Inbound',
				category: 'inbound'
			},
			lastUpdatedAt: '2024-05-01T09:59:10.751Z',
			email: '',
			creator: {
				photoUrl: '',
				id: 'VuY1cKww5wYPVtN4AFo78Ciwyzx2',
				name: 'Guy'
			},
			name: 'Guy 2',
			totalEmails: 0,
			id: 'uhQHUSItSYsoMQnABjXNN',
			linkedIn: '',
			totalNotes: 0,
			fund: {
				name: 'Test Fund',
				id: '6mCfmYgr1rG_mUrq0KLSf',
				slug: 'test-fund',
				website: 'https://google.com'
			},
			createdAt: '2024-05-01T09:59:10.751Z',
			slug: 'guy-2',
			isApproved: false
		},
		reports: [
			'The development team delivered a major new software update on schedule and within budget.',
			'Rigorous testing procedures ensured a high-quality product launch with minimal bugs reported.',
			'The customer support team is actively addressing user feedback and providing timely assistance.'
		],
		tags: ['1 Paragraph', 'Single Sentence', 'Single Sentence'],
		creator: {
			id: nanoid(),
			name: 'Jeff'
		},
		createdAt: new Date().toISOString()
	}
]

export const AI_REPORTS_FUNNEL_SAMPLE_DATA = ['Inbound', 'New List']

export const VISUALIZATION_FIRST_CHECK_AND_FOLLOW_ONS_SAMPLE_DATA: IFirstCheckAndFollowOns =
	{
		firstCheck: {
			USD: 4500,
			AUD: 4500,
			NZD: 4500,
			EUR: 4500
		},
		firstCheckPercentage: 10,
		followOns: {
			USD: 4500,
			AUD: 4500,
			NZD: 4500,
			EUR: 4500
		},
		followOnsPercentage: 10
	}

export const VISUALIZATION_REPORT_FUNNEL_SAMPLE_DATA: IBarChartData[] = [
	{
		name: 'Inbound',
		value: 225
	},
	{
		name: 'Schedule Call',
		value: 200
	},
	{
		name: 'First Call',
		value: 175
	},
	{
		name: 'Conversion',
		value: 150
	},
	{
		name: 'Consideration',
		value: 125
	},
	{
		name: 'Payment',
		value: 110
	},
	{
		name: 'Fund',
		value: 100
	},
	{
		name: 'Portfolio',
		value: 95
	}
]

export const VISUALIZATION_REPORT_INVESTMENT_CHART_SAMPLE_DATA: IBarChartData[] =
	[
		{
			name: 'Jan',
			value: 3
		},
		{
			name: 'Feb',
			value: 6
		},
		{
			name: 'Mar',
			value: 1
		},
		{
			name: 'Apr',
			value: 0
		},
		{
			name: 'May',
			value: 0
		},
		{
			name: 'Jun',
			value: 0
		},
		{
			name: 'Jul',
			value: 0
		},
		{
			name: 'Aug',
			value: 0
		},
		{
			name: 'Sep',
			value: 0
		},
		{
			name: 'Oct',
			value: 0
		},
		{
			name: 'Nov',
			value: 0
		},
		{
			name: 'Dec',
			value: 0
		}
	]

export const VISUALIZATION_REPORT_AVERAGE_PERCENTAGE_CHART_DATA: ILineChartData[] =
	[
		{
			name: '7%',
			value: 7
		},
		{
			name: '6.5%',
			value: 6.5
		},
		{
			name: '6.2%',
			value: 6.2
		},
		{
			name: '6%',
			value: 6
		},
		{
			name: '5.8%',
			value: 5.8
		},
		{
			name: '5.6%',
			value: 5.6
		},
		{
			name: '5.5%',
			value: 5.5
		},
		{
			name: '5.4%',
			value: 5.4
		},
		{
			name: '5.3%',
			value: 5.3
		},
		{
			name: '5.2%',
			value: 5.2
		},
		{
			name: '5%',
			value: 5
		},
		{
			name: '4.7%',
			value: 4.7
		},
		{
			name: '4.5%',
			value: 4.5
		},
		{
			name: '4.3%',
			value: 4.3
		},
		{
			name: '4.2%',
			value: 4.2
		},
		{
			name: '4%',
			value: 4
		},
		{
			name: '3.8%',
			value: 3.8
		},
		{
			name: '3.5%',
			value: 3.5
		},
		{
			name: '3.3%',
			value: 3.3
		},
		{
			name: '3%',
			value: 3
		},
		{
			name: '2.8%',
			value: 2.8
		},
		{
			name: '2.5%',
			value: 2.5
		},
		{
			name: '2.2%',
			value: 2.2
		},
		{
			name: '2%',
			value: 2
		},
		{
			name: '1.8%',
			value: 1.8
		},
		{
			name: '1.5%',
			value: 1.5
		},
		{
			name: '1.2%',
			value: 1.2
		},
		{
			name: '1%',
			value: 1
		}
	]

export const VISUALIZATION_REPORT_GEOGRAPHIC_DISTRIBTION_SAMPLE_DATA: GeographicDistributionItem[] =
	[
		{
			id: nanoid(),
			coordinates: [174.886, -40.9006],
			country: 'New Zealand',
			totalCompany: 7
		},
		{
			id: nanoid(),
			coordinates: [174.886, -42.9006],
			country: 'New Zealand',
			totalCompany: 7
		}
	]

export const VISUALIZATION_COMPANIES_MULTI_SELECT_SAMPLE_DATA: SpokMultiSelectOption[] =
	[
		{ value: 'Company A', label: 'Company A' },
		{ value: 'Company B', label: 'Company B' },
		{ value: 'Company C', label: 'Company C' },
		{ value: 'Company D', label: 'Company D' },
		{ value: 'Company E', label: 'Company E' },
		{ value: 'Company F', label: 'Company F' },
		{ value: 'Company G', label: 'Company G' },
		{ value: 'Company H', label: 'Company H' },
		{ value: 'Company I', label: 'Company I' },
		{ value: 'Company J', label: 'Company J' }
	]

export const VISUALIZATION_PORTFOLIO_INVESTMENT_SAMPLE_DATA: IPortfolioInvestmentChart =
	{
		totalCompanies: 46,
		totalInvestment: [
			{ amount: 4_602_323, currency: 'USD' },
			{ amount: 4_602_323, currency: 'AUD' },
			{ amount: 4_602_323, currency: 'NZD' },
			{ amount: 4_602_323, currency: 'EUR' }
		],
		sumOfInvestments:{
			USD: 4500,
			AUD: 4500,
			NZD: 4500,
			EUR: 4500
		},
		chartData: {
			label: ['Company 1', 'Company 2', 'Company 3', 'Company 4'],
			colors: [
				'rgba(255, 206, 86, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(30, 100, 150, 1)',
				'rgba(30, 50, 100, 1)'
			],
			datasets: [
				{
					label: 'Upstock',
					data: [450_000, 15_000, 30_000, 20_000]
				},
				{
					label: 'Pyper Vision',
					data: [450_000, 35_000, 30_000, 20_000]
				},
				{
					label: 'Easy Crypto',
					data: [450_000, 45_000, 30_000, 20_000]
				},
				{
					label: 'Cupla',
					data: [120_000, 65_000, 30_000, 20_000]
				},
				{
					label: 'RiskAverse',
					data: [130_000, 75_000, 20_000, 25_000]
				},
				{
					label: 'Simfuni',
					data: [140_000, 25_000, 10_000, 37_000]
				},
				{
					label: 'CarbonCrop',
					data: [160_000, 35_000, 20_000, 72_000]
				},
				{
					label: 'VXT',
					data: [170_000, 55_000, 20_000, 52_000]
				},
				{
					label: 'ALEPH',
					data: [150_000, 75_000, 90_000, 34_000]
				},
				{
					label: 'Siren Biotechnology',
					data: [120_000, 25_000, 80_000, 52_000]
				},
				{
					label: 'Elo',
					data: [130_000, 15_000, 70_000, 62_000]
				},
				{
					label: 'Humanata AI',
					data: [170_000, 35_000, 60_000, 72_000]
				},
				{
					label: 'OVRCOME',
					data: [120_000, 85_000, 50_000, 32_000]
				},
				{
					label: 'Safe',
					data: [130_000, 75_000, 40_000, 42_000]
				},
				{
					label: 'JEUNEORA',
					data: [140_000, 25_000, 30_000, 52_000]
				}
			]
		}
	}
