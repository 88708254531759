/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-extraneous-class */
import { store } from "app/store"
import type { INote } from "features/notes/interfaces/notes.interface"
import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore"
import { firestore } from "lib/firebase"
import { setComments, setMeetings, setNotes, setUpdates } from "../redux/dashboardHome.slice"
import type { ISharedLinkCompanyComment } from "features/sharedlinks/interfaces/sharedlink.interface"
import type { ICompanyUpdate } from "features/updates/interfaces/company_update.interface"
import type { ICalendarEvent } from "features/companies/interfaces/calendarEvent.interface"

export class DashboardService {

  private static readonly notesRef = collection(firestore, 'notes')

  private static readonly sharedlinkcomments = collection(firestore, 'shared_link_comments')

  private static readonly updatesRef = collection(firestore, 'company_updates')

  private static readonly meetingsRef = collection(firestore, 'calendar_events')


  public static listenToFundDashboardNotes = ({ fundId }: { fundId: string }) => {
    const q = query(this.notesRef, where('fund.id', '==', fundId), limit(20), orderBy('createdAt', 'desc'))
    return onSnapshot(q, querySnapshot => {
      const notes = querySnapshot.docs.map(d => d.data() as INote)
      store.dispatch(setNotes(notes))
    })
  }

  public static listenToFundDashboardComments = ({ fundId }: { fundId: string }) => {
    const q = query(this.sharedlinkcomments, where('linkData.fund.id', '==', fundId), limit(20), orderBy('createdAt', 'desc'))
    return onSnapshot(q, querySnapshot => {
      const comments = querySnapshot.docs.map(d => d.data() as ISharedLinkCompanyComment)
      store.dispatch(setComments(comments))
    })
  }

  public static listenToFundDashboardUpdates = ({ fundId }: { fundId: string }) => {
    const q = query(this.updatesRef, where('fund.id', '==', fundId), limit(20), orderBy('createdAt', 'desc'))
    return onSnapshot(q, querySnapshot => {
      const updates = querySnapshot.docs.map(d => d.data()) as ICompanyUpdate[]
      store.dispatch(setUpdates(updates))
    })
  }

  public static listenToFundDashboardMeetings = ({ fundId }: { fundId: string }) => {
    const q = query(this.meetingsRef, where('fund.id', '==', fundId), limit(20), orderBy('start.dateTime', 'desc'))
    return onSnapshot(q, querySnapshot => {
      const meetings = querySnapshot.docs.map(d => d.data()) as ICalendarEvent[]
      store.dispatch(setMeetings(meetings))
    })
  }

}