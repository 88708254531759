/* eslint-disable unicorn/consistent-function-scoping */
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from 'lib/firebase'
import Logger from 'lib/logger'
import { nanoid } from 'nanoid'

const useFirebaseStorageUploader = () => {
	async function uploadFileToFirebaseStorage(
		storagePath: string,
		file: File
	): Promise<FileUploadResult> {
		let result: FileUploadResult
		const storageRef = ref(storage, storagePath)

		try {
			await uploadBytes(storageRef, file)
			const downloadURL = await getDownloadURL(storageRef)
			result = {
				id: nanoid(),
				name: file.name,
				url: downloadURL,
				type: file.type,
				size: file.size,
				createdAt: new Date().toISOString()
			}
		} catch (error) {
			Logger.info('Error uploading file to firebase storage', error)
			throw error
		}

		return result
	}

	return uploadFileToFirebaseStorage
}

export default useFirebaseStorageUploader

export interface FileUploadResult {
	id: string
	name: string
	url: string
	file?: File
	type: string
	size?: number
	folderId?: string | null
	createdAt?: string
}
