/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Mail, Search, X } from "lucide-react"
import { useContext, useEffect, useState } from "react"

import { AuthContext } from "context/AuthContext"
import type { IEmailMessage } from '../interfaces/email.interface'
import { SearchService } from "features/search/service/search.service"
import clsxm from "utils/clsxm"
import { motion } from "framer-motion"
import { renderDayAndMonth } from "utils/date.util"
import { setSelectedThread } from "../redux/threads.slice"
import { useAppDispatch } from "app/hooks"

export default function SearchEmail() {
  const dispatch = useAppDispatch()

  const {authUser} = useContext(AuthContext);
  
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState<IEmailMessage[]>([])
  const [showSearchResults, setShowSearchResults] = useState(false)

  // Perform actual search
  useEffect(() => {
    async function search() {
      const results = await SearchService.searchMail(searchQuery);
      setSearchResults(results);
      setShowSearchResults(true);
    }
  
    if (searchQuery.length > 0) {
      search();
    }
  }, [searchQuery]);

  // Handle on click outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (showSearchResults && event.target instanceof Element && !event.target.closest('.search-email')) {
          setShowSearchResults(false);
        }
    }
  
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showSearchResults]);

  // handle on click on search result
  const handleSearchResultClick = (email: IEmailMessage) => {
    dispatch(setSelectedThread({ id: email.threadId, emailIds: [] } as any))
    setShowSearchResults(false)
  }

  const getParticipants = (email: any) => {
    const names = [email.from, ...email.to, ...email.cc, ...email.bcc]

    if (names.map((receiver: any) => receiver.email).includes(authUser?.email)) {
      // names.push({ name: 'me' })
    }
    
    return names.map((receiver: any) => receiver.name).filter(Boolean).join(', ')
  }

  return (
    <div>
      <div className='group flex items-center gap-1'>
        <div className={clsxm(
          'group w-full cursor-pointer items-center gap-1 rounded bg-gray-50 px-4 text-gray-600 transition-all hover:bg-gray-100',
          'flex items-center',
          'justify-between gap-x-1 border'
        )}>
          <Search size={16} />
          <input
            onChange={(e) => { 
              dispatch(setSelectedThread(null))
              setSearchQuery(e.target.value) 
            }}
            type='text'
            className='w-full rounded-md bg-gray-50 border-none text-sm placeholder:text-sm focus:ring-0 py-0.5'
            placeholder="Search email"
            value={searchQuery}
          />
          <motion.button
            whileHover={{ scale: 1.1 }}
            className='flex items-center'
          >
            <X size={16} onClick={() => { 
              if(searchQuery) setSearchQuery('')
            }}/>
          </motion.button>
        </div>
      </div>
      {showSearchResults && (
        <div className='max-h-[30rem] w-[50rem] absolute z-[9990] bg-white overflow-y-scroll mb-4 mt-4 rounded-xl border-2 py-4'>
          <div className="flex flex-col">
            {searchResults.length === 0 && (
              <div className='flex items-start gap-4 px-4 py-2 cursor-pointer border-l-2 border-white text-sm'>
                <div>No results found</div>
              </div>
            )}
            {searchResults.map((result: any) => (
              <div 
                key={result.id} 
                className='flex items-start gap-4 hover:bg-slate-100 px-4 py-2 cursor-pointer border-l-2 border-white hover:border-blue-800'
                onClick={() => handleSearchResultClick(result)}
              >
                <div><Mail size={18}/></div>
                <div>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div className="text-md text-gray-500 font-normal text-sm" dangerouslySetInnerHTML={{__html: result.highlight.subject }}/>
                  <p className="text-xs">{getParticipants(result)}</p>
                </div>
                <p className="text-xs ml-auto text-gray-500 whitespace-nowrap">{renderDayAndMonth(new Date(result.createdAt))}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}