/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-misused-promises */
import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import { Input } from 'components/ui/input'
import useCreateLP from 'features/limited_partners/hooks/useCreateLp'
import { PlusCircle } from 'lucide-react'
import { useRef, useState } from 'react'
import { ImSpinner10 } from 'react-icons/im'

export default function CreateProspect() {
	const [isOpen, setIsOpen] = useState(false)
	const { formik } = useCreateLP({
		onSuccess: () => {
			setIsOpen(false)
		}
	})

	const buttonRef = useRef(null)

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	function handleEnterKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key === 'Enter') {
			formik.handleSubmit()
		}
	}

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title='Create Prospect'
			description='Enter the name of the prospect you want to create'
			trigger={
				<Button
					id='open-create-prospect-button'
					onClick={openModal}
					variant='blue'
					className='m-2'
				>
					<div className=''>Create Prospect</div>
					<PlusCircle size={15} />
				</Button>
			}
		>
			<div className=''>
				<div>
					<Input
						error={formik.errors.name}
						id='prospect-name'
						name='prospect-name'
						placeholder='Enter prospect name'
						className='mt-8'
						value={formik.values.name}
						onKeyDown={handleEnterKeyPress}
						onChange={async e => formik.setFieldValue('name', e.target.value)}
					/>
				</div>
				<div className='mt-10 flex w-full justify-end gap-5'>
					{!formik.isSubmitting && (
						<Button
							id='cancel-create-prospect-button'
							variant='danger'
							onClick={() => closeModal()}
						>
							Cancel
						</Button>
					)}
					<Button
						id='create-prospect-button'
						ref={buttonRef}
						disabled={formik.isSubmitting || !formik.isValid}
						onClick={() => formik.handleSubmit()}
					>
						{formik.isSubmitting ? (
							<div className='flex items-center gap-2'>
								<ImSpinner10 className='animate-spin' /> Creating...
							</div>
						) : (
							'Create'
						)}
					</Button>
				</div>
			</div>
		</DialogModal>
	)
}
