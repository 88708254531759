/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { ICapitalCall } from '../interfaces/capitalCall.interface'

export interface CapitalCallSliceState {
	data: ICapitalCall[]
	isLoading: boolean
	error?: string | null
}

const initialState: CapitalCallSliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const capitalCallSlice = createAppSlice({
	name: 'capitalCall',
	initialState,
	reducers: create => ({
		setCapitalCallsError: create.reducer((state, action: PayloadAction<string>) => {
			state.isLoading = false
			state.error = action.payload
		}),
		setCapitalCallsLoading: create.reducer((state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload
		}),
		setCapitalCalls: create.reducer((state, action: PayloadAction<ICapitalCall[]>) => {
			state.isLoading = false
			state.data = action.payload
		})
	}),
	selectors: {
		selectCapitalCalls: state => state.data,
		selectCapitalCallsLoading: state => state.isLoading
	}
})

export const { setCapitalCallsError, setCapitalCallsLoading, setCapitalCalls } = capitalCallSlice.actions

export const { selectCapitalCalls, selectCapitalCallsLoading } = capitalCallSlice.selectors
