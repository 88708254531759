/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import { getTags } from 'features/tags/tags.api'
import type { ITag } from 'interfaces'

export interface TagSliceState {
	data: ITag[]
	tag: {
		data: ITag | null
		status: 'failed' | 'idle' | 'loading' | 'success'
		error?: string | null
	}
	status: 'failed' | 'idle' | 'loading'
	error?: string | null
}

const initialState: TagSliceState = {
	data: [],
	tag: {
		data: null,
		status: 'idle'
	},
	status: 'idle',
	error: null
}

export const tagSlice = createAppSlice({
	name: 'tag',
	initialState,
	reducers: create => ({
		fetchTags: create.asyncThunk(
			async (fundId: string) => {
				const response = await getTags(fundId)
				return response
			},
			{
				pending: state => {
					state.status = 'loading'
				},
				fulfilled: (state, action) => {
					state.status = 'idle'
					state.data = action.payload
				},
				rejected: (state, action) => {
					state.status = 'failed'
					state.error = action.error.message
				}
			}
		),
		setTags: create.reducer((state, action: PayloadAction<ITag[]>) => {
			state.status = 'idle'
			state.data = action.payload
		}),
		setTag: create.reducer(
			(state, action: PayloadAction<typeof initialState.tag>) => {
				state.tag = action.payload
			}
		)
	}),
	selectors: {
		selectTags: tag => tag.data,
		selectTagCount: tag => tag.data.length,
		selectTagStatus: tag => tag.status,
		selectTag: tag => tag.tag
	}
})

export const { fetchTags, setTags, setTag } = tagSlice.actions

export const { selectTags, selectTagCount, selectTagStatus, selectTag } =
	tagSlice.selectors
