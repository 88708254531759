/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
import { useAppSelector } from 'app/hooks'
import { AuthContext } from 'context/AuthContext'
import { selectProspectColumns } from 'features/columns/column.slice'
import { listenToColumns } from 'features/columns/columns.api'
import { DataTable } from 'features/dataTable/DataTable'
import useDataTableHook from 'features/dataTable/hooks/useDataTableHook'
import type { ICompany } from 'interfaces'
import { useContext, useEffect } from 'react'
import { listenToRelevantPeople } from '../api/companies.api'
import { selectRelevantPeople } from '../redux/company.slice'

interface CompanyRelevantPeopleProps {
	className?: string
	company: ICompany
}

export default function CompanyRelevantPeople({
	className,
	company
}: CompanyRelevantPeopleProps): JSX.Element {
	const { authUser } = useContext(AuthContext)
	const columnsData = useAppSelector(selectProspectColumns)
	const { columns, columnData } = useDataTableHook({
		columnData: columnsData,
		detailsRoute: 'limited-partner'
	})
	const { data, isLoading } = useAppSelector(selectRelevantPeople)

	useEffect(() => {
		if (!authUser?.fund.id) return
		if (!company.tagIds || company.tagIds.length <= 0) return

		const unsub1 = listenToColumns(authUser.fund.id)
		const unsub2 = listenToRelevantPeople(company.tagIds, authUser.fund.id)

		return () => {
			unsub1()
			unsub2()
		}
	}, [company.tagIds, authUser?.fund.id])

	return (
		<div className={`${className} flex flex-col`}>
			<div className='relative pb-2'>
				<DataTable
					id='company-relevant-people'
					entity='lp'
					allowDownload
					columns={columns}
					data={data}
					filterPlaceholder='Search people'
					loading={isLoading}
				/>
			</div>
		</div>
	)
}
