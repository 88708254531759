import type { Fund, FundData, User, UserData } from 'interfaces'

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Workspace } from './interfaces/workspace.interface'
import { createAppSlice } from 'app/createAppSlice'

export interface AuthenticationSliceState {
	authUser: User | null
	authUserData: UserData | null
	workspaces: Workspace[]
	activeWorkspace: Workspace | null
	authUserFund: Fund | null
	authUserFundData: FundData | null
	fundCurrency: string
	currencyOptions: { label: string; value: string }[]
	authLoading: boolean
	error?: string | null
}

const initialState: AuthenticationSliceState = {
	authUser: null,
	authUserFund: null,
	authUserData: null,
	workspaces: [],
	activeWorkspace: null,
	fundCurrency: 'USD',
	authUserFundData: null,
	currencyOptions: [],
	authLoading: false,
	error: null
}

export const authenticationSlice = createAppSlice({
	name: 'authentication',
	initialState,
	reducers: create => ({
		setAuthUser: create.reducer((state, action: PayloadAction<User | null>) => {
			state.authUser = action.payload
			state.authUserData = action.payload
				? {
						id: action.payload.id,
						name: action.payload.name,
						photoUrl: action.payload.photoUrl || ''
					}
				: null
			state.authLoading = false
			state.error = null
		}),
		setAuthUserFund: create.reducer(
			(state, action: PayloadAction<Fund | null>) => {
				state.authUserFund = action.payload

				if (!action.payload) {
					state.authUserFundData = null
					return
				}

				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				state.currencyOptions = (action.payload.currencies ?? []).map(
					currency => {
						return {
							label: currency,
							value: currency
						} as any
					}
				)

				state.authUserFundData = {
					id: action.payload.id,
					name: action.payload.name,
					website: action.payload.website || '',
					slug: action.payload.slug || ''
				}

				state.fundCurrency = action.payload.currency || 'USD'
			}
		),
		setIsAuthLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.authLoading = action.payload
			}
		),
		setWorkspaces: create.reducer(
			(state, action: PayloadAction<Workspace[]>) => {
				state.workspaces = action.payload
			}
		),
		setActiveWorkspace: create.reducer(
			(state, action: PayloadAction<Workspace | null>) => {
				state.activeWorkspace = action.payload
			}
		)
	}),
	selectors: {
		selectAuthState: state => state,
		selectAuthUser: state => state.authUser,
		selectIsAuthLoading: state => state.authLoading,
		selectAuthUserFund: state => state.authUserFund,
		selectAuthUserFundData: state => state.authUserFundData,
		selectCurrencyOptions: state => state.currencyOptions,
		selectFundCurrency: state => state.fundCurrency,
		selectWorkspaces: state => state.workspaces,
		selectActiveWorkspace: state => state.activeWorkspace
	}
})

export const {
	setAuthUser,
	setAuthUserFund,
	setIsAuthLoading,
	setWorkspaces,
	setActiveWorkspace
} = authenticationSlice.actions

export const {
	selectAuthState,
	selectAuthUserFund,
	selectCurrencyOptions,
	selectFundCurrency,
	selectAuthUser,
	selectWorkspaces,
	selectActiveWorkspace
} = authenticationSlice.selectors
