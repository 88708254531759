import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import CreateEmailSignature from 'features/mail/components/CreateSignature'
import { Mail } from 'lucide-react'
import { listenToInvitations } from 'features/invitations/invitations.api'
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useAppDispatch } from 'app/hooks'

export default function ProfileSettings(): JSX.Element {
	const { authUser } = useContext(AuthContext)

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubscribe = dispatch(listenToInvitations(authUser.fund.id))
		return () => unsubscribe()
	}, [authUser?.fund.id])

	return (
		<main className='h-full w-full px-5'>
			<div className='relative w-full'>
				<div className='w-full pt-5 text-left text-lg font-semibold'>
					<div className='flex justify-between'>Profile Settings</div>
					<p className='text-sm font-normal text-gray-500'>
						Manage your profile and platform access
					</p>
				</div>

				{/* Manage Signature */}
				<div className='px-5 pt-5'>
					<div className='flex items-center gap-2'>
						<Mail size={16} />
						Email Signature
					</div>
					<div className='text-sm text-gray-700'>
						Manage your email signatures (appended at the end of all outgoing
						messages)
					</div>
					<CreateEmailSignature />
				</div>
			</div>
		</main>
	)
}
