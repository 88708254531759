/* eslint-disable consistent-return */
import { useAppSelector } from "app/hooks"
import { store } from "app/store"
import { AuthContext } from "context/AuthContext"
import { useContext, useEffect } from "react"
import { listenToTags } from "../tags.api"
import { selectTags } from "../tags.slice"

export default function useTags() {
  const { authUser } = useContext(AuthContext)
  const tags = useAppSelector(selectTags)

  useEffect(() => {
    if (!authUser?.fund.id) return

    const unsubscribe = store.dispatch(listenToTags(authUser.fund.id))
    return () => unsubscribe()
  }, [authUser?.fund.id])

  return {
    tags,
    authUser
  }
}