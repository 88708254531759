import type { ReactNode } from 'react'

interface Props {
	title: string
	children: ReactNode
}

export default function CardWrapper({ children, title }: Props) {
	return (
		<div className='flex h-full w-full flex-col items-center justify-center'>
			<div className='flex w-[40rem] flex-col rounded-xl border p-8'>
				<h2 className='mb-6 border-b pb-4 text-xl font-semibold text-primary'>
					{title}
				</h2>
				{children}
			</div>
		</div>
	)
}
