import {
	collection,
	doc,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	where
} from 'firebase/firestore'

import { ENotificationStatus } from '../enums/notifications.enums'
import type { INotification } from '../interface/notifications.interface'
import { firestore } from 'lib/firebase'
import { nanoid } from 'nanoid'
import { setNotifications } from '../redux/notifications.slice'
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-extraneous-class */
import { store } from 'app/store'

export class NotificationsService {
	private static readonly ref = collection(firestore, 'notifications')

	// Listen to notifications
	public static async listenToNotifications({
		userId,
		fundId
	}: {
		userId: string
		fundId: string
	}) {
		const q = query(
			this.ref,
			where('user.id', '==', userId),
			where('fund.id', '==', fundId),
			orderBy('createdAt', 'desc')
		)

		return onSnapshot(q, snapshot => {
			const notifications = snapshot.docs.map(doc => doc.data())
			store.dispatch(setNotifications(notifications as INotification[]))
		})
	}

	// Add notification
	public static async addCompanyFromEmailNotification(
		notification: Partial<INotification>
	) {
		const user = store.getState().authentication.authUser
		if (!user) return

		const data: INotification = {
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
			id: notification.id || nanoid(),
			status: notification.status || ENotificationStatus.SENT,
			message: notification.message || 'New notification',
			details: notification.details || 'New notification',
			fund: user.fund,
			user: {
				id: user.id,
				name: user.name
			},
			metadata: notification.metadata || {},
			type: notification.type || 'notification'
		}

		const docRef = doc(this.ref, data.id)
		await setDoc(docRef, data)
	}

	public static async addNotification(notification: INotification) {
		const docRef = doc(this.ref, notification.id)
		await setDoc(docRef, notification)
	}

	// Update notification
	public static async updateNotification(notification: Partial<INotification>) {
		const docRef = doc(this.ref, notification.id)

		const data: Partial<INotification> = {
			...notification,
			updatedAt: new Date().toISOString()
		}

		await setDoc(docRef, data, { merge: true })
	}
}
