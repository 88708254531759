/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IEmailTemplate {
	id: string
	subject: string
	type: string
	body: string
	dateCreated: string
	updatedAt: string
}

export interface IEmailThread {
	id: string
	name: string
	companyIds: string[]
	companiesCount: number
	latestMessage: Record<EmailLabel, IEmailMessage>
	latestEmail?: IEmailMessage
	companies?: {
		id: string
		name: string
		websiteUrl: string
	}[]
	hasUnreadEmails?: boolean
	partnersCount: number
	partners?: { id: string; name: string; profileImage: string }[]
	limitedPartnersCount: number
	limitedPartners?: { id: string; name: string; profileImage: string }[],
	prospectsCount: number
	prospects?: { id: string; name: string; profileImage: string }[]
	emailIds: string[]
	hasScheduledCall?: boolean
	emails: IEmailMessage[]
	tags: string[]
	emailAccounts: string[]
	latestTimeStamp: string
	isPinned?: boolean
	cretaedAt: string
	lastUpdatedAt: string
}

export interface IEmailMessage {
	id: string
	userId: string
	fundId: string
	threadId: string
	from: IReceiver
	to: IReceiver[]
	cc: IReceiver[]
	bcc: IReceiver[]
	subject: string
	snippet: string
	textHtml: string
	textPlain: string
	isAScheduledCall: boolean
	attachments: IAttachment[]
	attachmentURLs: string[]
	labelIds: string[]
	dateCreated: string
	createdAt: string
	lastUpdatedAt: string
	companies?: {
		id: string
		name: string
		websiteUrl: string
	}[]
	limitedPartners?: {
		id: string
		name: string
		profileImage: string
	}[]
}

export interface IReceiver {
	email: string
	name: string
}

export interface IAttachment {
	filename: string
	mimeType: string
	size: number
	attachmentId: string
	headers?: any
	/** data must be URLsafe base64 encoded */
	data?: string
	dataEncoding?: string
	/** url is the download link for the attachment */
	url?: string
	thumbnails?: {
		small: string
		medium: string
		large: string
	}
}

export enum EmailLabel {
	Inbox = 'inbox',
	Archive = 'archive',
	Draft = 'draft',
	Trash = 'trash',
	Spam = 'spam',
	Sent = 'sent',
	SpokInbox = 'spokInbox',
	Portfolio = 'portfolio',
	Diligence = 'diligence',
	Prospect = 'prospect',
	LimitedPartner = 'limitedPartner',
	AllMail = 'allMail'
}

export interface IGmailSummary {
	isSyncing: boolean
	totalThreads: number
	syncPercentage: number
	emailCount: Record<string, number>
	emailThreads: Record<EmailLabel, string[]>
	updatedAt: string
	threadDates: Record<string, number>
}
