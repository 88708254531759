import { NotebookPen, Pin, PinOff } from 'lucide-react'
import { addDraftNote, selectDraftNotes } from '../redux/draft_notes.slice'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { NoteEditorState, NoteType } from '../interfaces/notes.interface'
import type { NoteAction, INote } from '../interfaces/notes.interface'
import NoteItem, { DEFAULT_NOTE_ACTIONS } from './NoteItem'
import Skeleton from 'components/Skeleton'
import _ from 'lodash'
import { nanoid } from 'nanoid'
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { store } from 'app/store'
import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Logger from 'lib/logger'
import clsxm from 'utils/clsxm'

interface AppProps {
	notes: INote[]
	loadingNotes: boolean
	onCreateDraftNote?: () => void
	showTitle?: boolean
	isReadOnly?: boolean
	actions?: NoteAction[]
}

function hasPinnedNotes(notes: INote[]) {
	return notes.some(note => note.isPinned)
}

export default function NotesList({
	notes,
	loadingNotes,
	onCreateDraftNote,
	showTitle,
	actions = DEFAULT_NOTE_ACTIONS,
	isReadOnly
}: AppProps) {
	const { authUser } = useContext(AuthContext)
	const draftNotes = useSelector(selectDraftNotes)
	const groupedByCreatedAt = _.groupBy(notes, 'createdAt')

	useEffect(() => {
		Logger.warn('when isReadOnly is true, onCreateDraftNote prop is required')
	}, [onCreateDraftNote, isReadOnly])

	if (loadingNotes) {
		return (
			<div className='flex h-fit w-full flex-col gap-3 p-5'>
				<Skeleton className='h-6 w-96 border' />
				<Skeleton className='h-6 w-full border' />
				<Skeleton className='h-6 w-full border' />
				<div className='py-2' />
				<Skeleton className='h-6 w-96 border' />
				<Skeleton className='h-6 w-full border' />
				<Skeleton className='h-6 w-full border' />
				<div className='py-2' />
				<Skeleton className='h-6 w-96 border' />
				<Skeleton className='h-6 w-full border' />
				<Skeleton className='h-6 w-full border' />
			</div>
		)
	}

	if (notes.length === 0) {
		return (
			<div className='flex h-fit w-full items-center justify-center pb-5 pt-20'>
				<div className='flex flex-col items-center justify-center gap-2'>
					<div className='font-semibold'>No notes found</div>
					{!isReadOnly && (
						<Button
							onClick={() => {
								if (onCreateDraftNote) {
									onCreateDraftNote()
								}
							}}
						>
							<NotebookPen size={17} /> Add New Note
						</Button>
					)}
				</div>
			</div>
		)
	}

	const showCreateNewNoteButton =
		notes.length > 0 && !isReadOnly && onCreateDraftNote

	return (
		<>
			<div className='my-1 flex items-center justify-between px-2'>
				{showTitle && (
					<div
						className={clsxm('ml-2 font-semibold text-blue-700', {
							'mb-5': !showCreateNewNoteButton
						})}
					>
						From General Partners
					</div>
				)}
				{showCreateNewNoteButton && (
					<Button
						id='open-notebook'
						onClick={() => {
							onCreateDraftNote()
						}}
						className='mb-5 h-fit'
					>
						<NotebookPen size={17} /> Add New Note
					</Button>
				)}
			</div>
			<section
				id='company-notes'
				data-cy='notes-page'
				className='relative z-0 flex w-full flex-col gap-4'
			>
				{hasPinnedNotes(notes) && (
					<>
						<div className='flex items-center gap-2 px-3 text-xs font-semibold'>
							<Pin size={15} />
							Pinned Notes ({notes.filter(note => note.isPinned).length})
						</div>
						<section className='flex w-full flex-1 flex-col gap-5'>
							{Object.keys(groupedByCreatedAt)
								.sort((a, b) => (a > b ? -1 : 1))
								.filter(key => {
									const yearNotes = groupedByCreatedAt[key]
									return yearNotes.some(note => note.isPinned)
								})
								.map(key => {
									const monthNotes = groupedByCreatedAt[key]
									return (
										<div key={key} className='relative flex w-full'>
											<section className='w-full overflow-auto px-2'>
												<div className='mb-3 flex items-center gap-3 text-xs font-semibold text-gray-500'>
													{/* {getMonthAndYear(key)} */}
													<div className='h-[0.05rem] flex-1 bg-gray-200' />
												</div>
												{monthNotes
													.filter((noteItem: INote) => noteItem.isPinned)
													.map((noteItem: INote) => (
														<NoteItem
															isReadOnly={isReadOnly}
															key={noteItem.id}
															note={noteItem}
														/>
													))}
											</section>
										</div>
									)
								})}
						</section>
					</>
				)}
				{!hasPinnedNotes(notes) && (
					<div className='flex items-center gap-2 px-3 text-xs font-semibold'>
						<PinOff size={15} />
						Unpinned Notes ({notes.filter(note => !note.isPinned).length})
					</div>
				)}
				<section className='flex w-full flex-1 flex-col gap-5'>
					{Object.keys(groupedByCreatedAt)
						.sort((a, b) => (a > b ? -1 : 1))
						.filter(key => {
							const yearNotes = groupedByCreatedAt[key]
							return yearNotes.some(note => !note.isPinned)
						})
						.map(key => {
							const monthNotes = groupedByCreatedAt[key]
							return (
								<div key={key} className='relative flex w-full'>
									<section className='w-full overflow-auto px-2'>
										<div className='mb-3 flex items-center gap-3 text-xs font-semibold text-gray-500'>
											<div className='h-[0.05rem] flex-1 bg-gray-200' />
										</div>
										{monthNotes
											.filter((noteItem: INote) => !noteItem.isPinned)
											.map((noteItem: INote) => (
												<NoteItem
													isReadOnly={isReadOnly}
													key={noteItem.id}
													note={noteItem}
													actions={actions}
												/>
											))}
									</section>
								</div>
							)
						})}
				</section>

				{notes.length === 0 && (
					<div className='flex h-full w-full items-center justify-center pb-5'>
						<div className='flex flex-col items-center justify-center gap-2'>
							<div className='font-semibold'>No notes found</div>
							<Button
								onClick={() => {
									if (draftNotes.length > 1) return
									if (!authUser) return
									store.dispatch(
										addDraftNote({
											id: nanoid(),
											windowState: NoteEditorState.RESTORED,
											title: '',
											creator: {
												id: authUser.id,
												name: authUser.name,
												photoUrl: authUser.photoUrl ?? ''
											},
											fund: authUser.fund,
											noteType: NoteType.Company
										})
									)
								}}
								className=''
							>
								<NotebookPen size={17} /> Add New Note
							</Button>
						</div>
					</div>
				)}
			</section>
		</>
	)
}
