/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-floating-promises */
import type { AppThunk } from 'app/store'
import { setInvitations } from 'features/invitations/redux/invitations.slice'
import type { Unsubscribe } from 'firebase/firestore'
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	setDoc,
	where
} from 'firebase/firestore'
import { InvitationStatus, type Invitation } from 'interfaces'
import axiosInstance from 'lib/axios'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'

const invitationsRef = collection(firestore, 'invitations')

export const addInvitation = async (
	invitation: Invitation
): Promise<Invitation> => {
	const docRef = doc(invitationsRef, invitation.id)
	await setDoc(docRef, invitation, { merge: true })

	return invitation
}

export const deleteInvitation = async (id: string) => {
	const docRef = doc(invitationsRef, id)
	await deleteDoc(docRef)
}

export const getInvitation = async (id: string): Promise<Invitation> => {
	const docRef = doc(invitationsRef, id)
	const docSnap = await getDoc(docRef)
	return docSnap.data() as Invitation
}

export const getInvitations = async (fundId: string): Promise<Invitation[]> => {
	const q = query(
		invitationsRef,
		where('fund.id', '==', fundId),
		where('status', '!=', InvitationStatus.ACCEPTED)
	)
	const querySnapshot = await getDocs(q)
	const invitations = querySnapshot.docs.map(d => d.data() as Invitation)
	return invitations
}

export const listenToInvitations =
	(fundId: string): AppThunk<Unsubscribe> =>
	dispatch => {
		const q = query(
			invitationsRef,
			where('fund.id', '==', fundId),
			where('status', '!=', InvitationStatus.ACCEPTED)
		)
		const unsubscribe = onSnapshot(
			q,
			querySnapshot => {
				const invitations = querySnapshot.docs.map(d => d.data() as Invitation)
				dispatch(setInvitations(invitations))
			},
			error => {
				Errorhandler.captureException(error)
				Logger.error(error)
			}
		)

		return unsubscribe
	}

export const checkIfAlreadyInvited = async (email: string) => {
	try {
		const { data } = await axiosInstance.get(
			`/invitations/${email}/already-invited`
		)
		return data
	} catch (error: any) {
		throw new Error(error.response.data.message)
	}
}

export const processInvitation = async (
	id: string,
	decision: 'accept' | 'decline'
) => {
	try {
		const { data } = await axiosInstance.post(`/invitations/${id}/process`, {
			decision
		})
		return data
	} catch (error: any) {
		throw new Error(error.response.data.message)
	}
}
