import Errorhandler from 'lib/sentry'
import Logger from 'lib/logger'
import { nanoid } from 'nanoid'
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-extraneous-class */
// import { toast } from 'sonner'
import { addIndustry } from '../api/industries.api'

export class IndustryService {
  public static addIndustry = async(industry:string)=>{
    try {
      const payload = { id: nanoid(), industry, createdAt: new Date().toISOString(), lastUpdatedAt: new Date().toISOString() }
			await addIndustry(payload)
			// toast.success('Industry updated successfully')
		} catch (error: any) {
			// toast.error(error.mesage ?? 'Failed to update industry')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
  }
}
