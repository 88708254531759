import * as Yup from 'yup'

import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger
} from 'components/ui/hover-card'
import {
	capitalToBeCalledInitialValueMapper,
	capitalToBeCalledMapper,
	convertPercentToValue,
	convertValueToPercent,
	getRemainingCommitted
} from '../helper/capitalCall'
import {
	selectIsEmailAddressesNextStepDisable,
	selectIsRecipientNextStepDisable,
	selectRecipients,
	selectRecipientsCapitalToBeCalled,
	setRecipientCapitalToBeCalled
} from '../redux/newCapitalCall.slice'

import Button from 'components/Buttons/Button'
import CapitalCapitalToBeCalledChart from '../components/CapitalCapitalToBeCalledChart'
import { CapitalTobeCalledInput } from '../components/CalledToBeCalledInput'
import type { ILimitedPartner } from 'interfaces'
import { Input } from 'components/ui/input'
import { capitalToBeCalledPieChartMapper } from '../helper/chart'
import { currencyFormatter } from 'utils/currencyformatter.util'
import { store } from 'app/store'
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable logical-assignment-operators */
/* eslint-disable @typescript-eslint/max-params */
import { useAppSelector } from 'app/hooks'
import { useFormik } from 'formik'
import { useWizard } from 'react-use-wizard'

export default function NewCapitalCallCapitalTobeCalledStep(): JSX.Element {
	const recipients = useAppSelector(selectRecipients)
	const recipientsToBeCalled = useAppSelector(selectRecipientsCapitalToBeCalled)

	const { nextStep } = useWizard()

	const isRecipientNextStepDisable = useAppSelector(
		selectIsRecipientNextStepDisable
	)
	const isEmailAddressesNextStepDisable = useAppSelector(
		selectIsEmailAddressesNextStepDisable
	)

	const validationSchema = Yup.object({
		globalCapitalCallPercentage: Yup.number()
			.max(100, 'Percentage must be less than or equal to 100')
			.min(0, 'Percentage must be greater than or equal to 0')
	})

	const formik = useFormik({
		validationSchema,
		initialValues: {
			globalCapitalCallPercentage: 0,
			capitalCall: capitalToBeCalledInitialValueMapper(recipientsToBeCalled)
		},
		onSubmit: async () => {}
	})

	function onValidateForm() {
		if (Object.values(recipients).length <= 0) return false

		for (const value of Object.values(recipients)) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const element = formik.values.capitalCall[value.id as any]

			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			if (
				element?.value > (value.totalCommitted?.amount || 0) ||
				!formik.values.capitalCall[value.id as any] ||
				element?.value <= 0
			)
				return false
		}

		return true
	}

	function onChangeCapitalTobeCalledInput(
		value: number,
		inputType: string,
		lp: ILimitedPartner,
		changeType: 'input' | 'type'
	) {
		const globalCapitalCallPercentage =
			formik.values.globalCapitalCallPercentage || 0

		const percentValue = formik.values?.capitalCall?.[lp.id]?.percent || 0
		const inputValue = formik.values?.capitalCall?.[lp.id]?.value || 0

		if (changeType === 'input') {
			if (inputType === 'percent') {
				formik.setFieldValue(`capitalCall[${lp.id}]`, {
					percent: value,
					inputType,
					value: convertPercentToValue(value, lp),
					currency: lp.totalCommitted?.currency
				})
			} else {
				formik.setFieldValue(`capitalCall[${lp.id}]`, {
					percent: convertValueToPercent(value, lp),
					inputType,
					value,
					currency: lp.totalCommitted?.currency
				})
			}
		} else if (inputType === 'percent') {
			const percentage = percentValue || globalCapitalCallPercentage

			formik.setFieldValue(`capitalCall[${lp.id}]`, {
				percent: percentage,
				inputType,
				value: convertPercentToValue(percentage, lp),
				currency: lp.totalCommitted?.currency
			})
		} else {
			const val =
				inputValue || convertPercentToValue(globalCapitalCallPercentage, lp)

			formik.setFieldValue(`capitalCall[${lp.id}]`, {
				percent: convertValueToPercent(val, lp),
				inputType,
				value: val,
				currency: lp.totalCommitted?.currency
			})
		}
	}

	function applyPercentageCapitalCallToAll(percent: number) {
		for (const [, recipient] of recipients.entries()) {
			const inputType =
				formik.values?.capitalCall?.[recipient.id]?.inputType || 'dollar'

			formik.setFieldValue(`capitalCall[${recipient.id}]`, {
				inputType,
				percent,
				value: (percent / 100) * (recipient.totalCommitted?.amount || 0),
				currency: recipient.totalCommitted?.currency
			})
		}
		store.dispatch(
			setRecipientCapitalToBeCalled(
				capitalToBeCalledMapper(formik.values.capitalCall as any)
			)
		)

		formik.setFieldValue('globalCapitalCallPercentage', percent)
	}

	function onClickNextStep() {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		store.dispatch(
			setRecipientCapitalToBeCalled(
				capitalToBeCalledMapper(formik.values.capitalCall as any)
			)
		)
		nextStep()
	}

	return (
		<div className='h-100vh bg-white p-14 pb-0'>
			<div className='h-100vh rounded-md border  border-[#E4E5E8] bg-white px-5 py-3'>
				<div className='mb-6 text-center text-3xl font-semibold'>
					Capital To be Called
				</div>
				<div>
					<div
						className='mx-auto mb-5 flex flex-grow flex-col flex-wrap items-start rounded-md border border-[#E4E5E8] p-3 py-3'
						style={{ maxWidth: 500, width: '100%' }}
					>
						<div className='mb-2 text-2xl font-semibold text-blue-700'>
							Percentage of Capital Call
						</div>
						<div style={{ maxWidth: 500, width: '100%' }}>
							<Input
								type='number'
								id='global-percentage-input'
								placeholder='Global percentage'
								error={formik.errors.globalCapitalCallPercentage}
								value={formik.values.globalCapitalCallPercentage?.toString()}
								onChange={value => {
									if (value.target.value === '') {
										formik.setFieldValue(
											'globalCapitalCallPercentage',
											undefined
										)
										applyPercentageCapitalCallToAll(0)
									} else {
										applyPercentageCapitalCallToAll(Number(value.target.value))
									}
								}}
							/>
						</div>
					</div>
					{recipients.map(recipient => (
						<div
							key={recipient.id}
							className='mt-5 flex w-full flex-grow flex-wrap justify-center gap-5'
						>
							<div className='relative flex w-[250px] flex-col items-center rounded-md border border-[#E4E5E8] p-5 px-20'>
								<div>
									<Avatar className='h-20 w-20 border'>
										<AvatarImage src={recipient.photoUrl} />
										<AvatarFallback>
											{recipient.name.slice(0, 1).toUpperCase()}
										</AvatarFallback>
									</Avatar>
								</div>
								<HoverCard openDelay={0} closeDelay={0}>
									<HoverCardTrigger asChild>
										<div className='mt-2 w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-center'>
											{recipient.name}
										</div>
									</HoverCardTrigger>
									<HoverCardContent className='w-fit'>
										<div>{recipient.name}</div>
									</HoverCardContent>
								</HoverCard>
							</div>
							<div className='relative flex flex-1 basis-[calc(100%-350px-550px-3rem)] flex-row flex-wrap items-start rounded-md border border-[#E4E5E8] p-5 '>
								<div className='overflow-hidden text-ellipsis whitespace-nowrap'>
									<div>
										<CapitalTobeCalledInput
											id={recipient.id}
											error={
												formik.values.capitalCall[recipient.id]?.value >
													getRemainingCommitted(recipient) &&
												`Current Capital call must be less than or equal to ${currencyFormatter(
													recipient.totalCommitted?.currency || 'USD',
													'short'
												).format(getRemainingCommitted(recipient))}`
											}
											value={
												formik.values.capitalCall[recipient.id]?.inputType ===
												'percent'
													? formik.values.capitalCall[
															recipient.id
														]?.percent?.toString()
													: formik.values.capitalCall[
															recipient.id
														]?.value?.toString()
											}
											onChange={(value, inputType, type) => {
												onChangeCapitalTobeCalledInput(
													value,
													inputType,
													recipient,
													type
												)
											}}
										/>
									</div>
									<div className='mt-2 text-sm text-gray-600'>
										{currencyFormatter(
											recipient.totalCommitted?.currency || 'USD',
											'short'
										).format(
											((formik.values.capitalCall[recipient.id]?.percent || 0) /
												100) *
												(recipient.totalCommitted.amount || 0)
										)}{' '}
										to be called of{' '}
										{currencyFormatter(
											recipient.totalCommitted?.currency || 'USD',
											'short'
										).format(recipient.totalCommitted.amount || 0)}{' '}
									</div>
								</div>
							</div>
							<div className='flex-1 basis-[550px] rounded-md border border-[#E4E5E8] p-5'>
								<div>
									<CapitalCapitalToBeCalledChart
										recipient={recipient}
										data={capitalToBeCalledPieChartMapper(
											recipient.totalCalled?.amount || 0,
											Math.min(
												formik.values.capitalCall[recipient.id]?.value || 0,
												getRemainingCommitted(recipient)
											),
											recipient.totalCommitted?.amount
										)}
									/>
								</div>
								<div className='mx-auto mt-9 flex flex-row items-center justify-center gap-4'>
									<div className='flex flex-col'>
										<div className='flex items-center'>
											<div className='mr-2 h-4 w-4 rounded-full bg-[#E4E5E8]' />
											<span className='font-semibold'>Previously Called</span>
										</div>
										<span className='ml-6'>
											{currencyFormatter(
												recipient.totalCalled?.currency || 'USD',
												'short'
											).format(recipient.totalCalled?.amount || 0)}
										</span>
									</div>
									<div className='flex flex-col'>
										<div className='flex items-center'>
											<div className='mr-2 h-4 w-4 rounded-full bg-[#175DCD]' />
											<span className='font-semibold'>
												Current Capital Call
											</span>
										</div>
										<span className='ml-6'>
											{currencyFormatter(
												recipient.totalCommitted?.currency || 'USD',
												'short'
											).format(
												formik.values.capitalCall[recipient.id]?.value || 0
											)}
										</span>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className='mb-5 mt-5'>
				<Button
					className='ml-auto px-10'
					id='continue-step-button'
					disabled={
						isRecipientNextStepDisable ||
						isEmailAddressesNextStepDisable ||
						!formik.isValid ||
						!onValidateForm()
					}
					onClick={() => {
						onClickNextStep()
					}}
				>
					Continue
				</Button>
			</div>
		</div>
	)
}
