/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup'

import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useContext, useEffect } from 'react'
import type {
	ILimitedPartner,
	LimitedPartnerData
} from '../interfaces/limitedPartner.interface'
import {
	addCapitalCall,
	updateCapitalCall
} from 'features/capital_call/api/capital-call.api'
import {
	convertAmount,
	displayAmount,
	getConvertedAmount
} from 'utils/currency'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { CapitalCallStatus } from 'features/capital_call/interfaces/capitalCall.interface'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import FileSelector from 'components/FileSelector'
import { FileService } from 'features/files/services/file.service'
import FileViewer from 'features/fileviewers/views/FileViewer'
import type { ICapitalCall } from 'features/capital_call/interfaces/capitalCall.interface'
import { Input } from 'components/ui/input'
import type { SelectedFile } from 'components/FileSelector'
import SpokDatePicker from 'components/shadcn/SpokDatePicker'
import { isPreviousCapitalCalledAmountValid } from '../helper/limitedPartnerTotalCommitted.helper'
import logger from 'lib/logger'
import { nanoid } from 'nanoid'
import { selectCurrencyOptions } from 'features/authentication/authentication.slice'
import { toast } from 'sonner'
import { useAppSelector } from 'app/hooks'
import { useFormik } from 'formik'

interface Props {
	capitalCall?: ICapitalCall
	limitedPartner: ILimitedPartner
	amountRemaining: number
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function AddPreviousCapitalCallNew({
	capitalCall,
	limitedPartner,
	amountRemaining,
	isOpen,
	setIsOpen
}: Props) {
	const currencyOptions = useAppSelector(selectCurrencyOptions)
	const { authUser } = useContext(AuthContext)

	const formik = useFormik({
		initialValues: {
			dateCalled: capitalCall?.dateCalled || new Date().toISOString(),
			files: capitalCall?.files || [],
			newFiles: [],
			percent: capitalCall?.percent || 0,
			amount: capitalCall?.amount.amount || 0,
			currency: capitalCall?.amount.currency || 'USD'
		},
		validationSchema: Yup.object({
			dateCalled: Yup.string().required('Date is required'),
			amount: Yup.number().required('Amount is required'),
			percent: Yup.number()
				.required('Percent is required')
				.max(100, 'Must between 1 to 100')
		}),
		onSubmit: async values => {
			if (!authUser) return
			try {
				const lpData: LimitedPartnerData = {
					id: limitedPartner.id,
					name: limitedPartner.name,
					photoUrl: limitedPartner.photoUrl || ''
				}

				let allFiles = formik.values.files

				if (values.newFiles.length > 0) {
					const filesResults = await FileService.saveFiles({
						files: values.newFiles.map((f: SelectedFile) => f.file),
						type: 'capital_call',
						authUser,
						limitedPartner: lpData
					})

					const filesData = filesResults.map(file => ({
						id: file.id,
						url: file.url,
						name: file.name
					}))

					allFiles = [...values.files, ...filesData]
				}

				const data: ICapitalCall = {
					id: capitalCall?.id || nanoid(),
					fund: capitalCall?.fund || authUser.fund,
					limitedPartner: capitalCall?.limitedPartner || lpData,
					calledBy: capitalCall?.calledBy || {
						id: authUser.id,
						name: authUser.name
					},
					status: capitalCall?.status || CapitalCallStatus.PAID,
					percent: values.percent || 0,
					amount: { amount: values.amount, currency: values.currency },
					files: allFiles,
					fileIds: allFiles.map(f => f.id),
					dateCalled: values.dateCalled,
					dueDate: values.dateCalled,
					approvedBy: {
						id: authUser.id,
						name: authUser.name
					},
					requestId: capitalCall?.requestId || ''
				}

				await (capitalCall
					? updateCapitalCall(capitalCall.id, data as any)
					: addCapitalCall(data as any))

				formik.setSubmitting(true)

				toast.dismiss()
				toast.success('Capital created successfully')
			} catch (error: any) {
				logger.error(error)

				toast.dismiss()
				toast.error(error.message ?? 'Failed to create capital call')
			} finally {
				formik.setSubmitting(false)
				formik.resetForm()
				setIsOpen(false)
			}
		}
	})

	useEffect(() => {
		if (capitalCall) {
			formik.setValues({
				dateCalled: capitalCall.dateCalled,
				files: capitalCall.files || [],
				percent: capitalCall.percent,
				amount: capitalCall.amount.amount,
				currency: capitalCall.amount.currency,
				newFiles: []
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [capitalCall])

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog
				as='div'
				className='relative z-20'
				onClose={() => setIsOpen(false)}
			>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-25' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className='w-full max-w-2xl transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all'>
								<div className=''>
									<SpokDatePicker
										label='Date Called'
										defaultValue={new Date(formik.values.dateCalled)}
										onSelect={date => {
											if (!date) return
											formik.setFieldValue('dateCalled', date.toISOString())
										}}
									/>
									<div className='mt-5 flex items-center gap-5'>
										<div>
											<ComboSelect
												label='Currency'
												onSelect={value => {
													formik.setFieldValue('currency', value)

													// Set everything to 0 if currency changes
													formik.setFieldValue('amount', 0)

													formik.setFieldValue('percent', 0)
												}}
												defaultValue={formik.values.currency}
												options={currencyOptions}
												content={
													<div className='mr-3 text-base font-semibold text-blue-700'>
														{formik.values.currency}
													</div>
												}
											/>
										</div>
										<div>
											<Input
												id='input-amount'
												name='amount'
												style={{ borderColor: formik.errors.amount && 'red' }}
												label='Amount'
												placeholder='Amount'
												value={formik.values.amount.toString()}
												type='number'
												step='0.01'
												onChange={async e => {
													const val = Number.parseFloat(e.target.value) || 0

													const { currency } = formik.values

													const amountEntered = getConvertedAmount({
														currency,
														amount: val
													}).amount
													const totalCommitted = getConvertedAmount(
														limitedPartner.totalCommitted
													).amount

													if (amountEntered < 0) {
														formik.setFieldValue('amount', 0)
														formik.setFieldValue('percent', 0)
														return
													}

													if (amountEntered > totalCommitted) {
														formik.setFieldValue('amount', totalCommitted)
														formik.setFieldValue('percent', 100)
														return
													}

													const percent = (amountEntered / totalCommitted) * 100
													formik.setFieldValue('percent', percent.toFixed(5))
													formik.setFieldValue('amount', val)
												}}
											/>
											<div />
										</div>
										<div>
											<Input
												id='input-percent'
												name='percent'
												label='Percent'
												placeholder='Percent'
												type='number'
												value={formik.values.percent.toString()}
												onChange={async e => {
													const percent = Number.parseFloat(e.target.value) || 0
													formik.setFieldValue('percent', percent)

													const totalCommitted = convertAmount(
														limitedPartner.totalCommitted,
														formik.values.currency
													).amount

													if (percent < 0) {
														formik.setFieldValue('percent', 0)
														formik.setFieldValue('amount', 0)
														return
													}

													if (percent > 100) {
														formik.setFieldValue('percent', 100)

														const amountEntered = totalCommitted
														formik.setFieldValue('amount', amountEntered)
														return
													}

													const amountEntered = (percent / 100) * totalCommitted
													formik.setFieldValue('amount', amountEntered)
												}}
											/>
										</div>
									</div>

									<div className='mt-3 text-xs text-red-500'>
										{!capitalCall &&
											!isPreviousCapitalCalledAmountValid(
												{
													amountEntered: formik.values.amount,
													currency: formik.values.currency
												},
												{
													amount: amountRemaining,
													currency: limitedPartner.totalCommitted.currency
												}
											) &&
											`Amount must be less than or equal to ${displayAmount(
												convertAmount(
													{
														currency:
															limitedPartner.totalCommitted.currency || 'USD',
														amount: amountRemaining
													},
													formik.values.currency
												),
												false
											)}`}
									</div>
									<div className='mt-3 flex flex-col gap-2'>
										{formik.values.files.map(file => (
											<FileViewer
												readonly
												key={file.id}
												file={file}
												variant='list'
												onDelete={item => {
													formik.setFieldValue(
														'files',
														formik.values.files.filter(f => f.id !== item.id)
													)
												}}
											/>
										))}
									</div>

									<FileSelector
										className='mt-3'
										onSelect={files => {
											formik.setFieldValue('newFiles', files)
										}}
									/>

									<div className='mt-10 flex w-full justify-end gap-5'>
										{!formik.isSubmitting && (
											<Button
												id='cancel-create-investment'
												variant='danger'
												onClick={() => {
													setIsOpen(false)
												}}
											>
												Cancel
											</Button>
										)}
										<Button
											isLoading={formik.isSubmitting}
											id='create-investment'
											disabled={formik.isSubmitting || !formik.isValid}
											onClick={() => formik.handleSubmit()}
										>
											Save
										</Button>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
