/* eslint-disable react/no-unescaped-entities */

import Layout from 'components/Layout';
import { useEffect } from 'react';
import clsxm from 'utils/clsxm';

function PrivacyPolicyPage() {
  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const targetId = hash.slice(1); // Remove the #
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <main className='min-h-screen  '>
      <Layout>
        <section
          className={clsxm(
            'relative mx-4 mb-40 mt-20  max-w-7xl px-4 text-black lg:mx-auto',
            ' rounded-3xl',
            'before:absolute before:inset-[2px] before:rounded-[1.5rem] ',
            'after:absolute after:-inset-x-2 after:-bottom-2 after:h-[150px]',
            'to-transparent  after:bg-gradient-to-t '
          )}
        >
          <article
            className={clsxm(
              'relative z-20 mx-auto max-w-5xl px-4 py-32',
              'flex flex-col gap-8'
            )}
          >
            <h2
              className={clsxm(
                ' mx-auto w-fit -translate-y-1/2  px-8 text-center text-xl sm:text-2xl md:text-4xl'
              )}
            >
              Privacy Policy for Spok.vc
            </h2>
            <p>Effective Date: 01 June 2023</p>
            <p>
              This Privacy Policy ("Policy") describes how Spok.vc ("Spok.vc,"
              "we," or "us") collects, uses, and discloses personal
              information when you use our services. By accessing or using the
              Spok.vc platform, you agree to the terms and practices outlined
              in this Policy.
            </p>

            <div>
              <strong className='text-xl'>Information We Collect</strong>
              <ul>
                <li>
                  1.1 Personal Information: When you sign up for and use
                  Spok.vc's services, we may collect personal information such
                  as your name, email address, contact information, and
                  payment details. We require this information to provide you
                  with access to our platform and to process your
                  transactions.
                </li>
                <li>
                  1.2 User-Provided Content: We may collect and store the
                  content you upload, share, or transmit through the Spok.vc
                  platform, including documents, files, and other materials.
                  This content may contain personal information and other data
                  that you choose to share.
                </li>
                <li>
                  1.3 Usage Data: We automatically collect certain information
                  about your use of the Spok.vc platform, including your IP
                  address, device information, browser type, access times, and
                  pages visited. We may also collect information about your
                  interactions with our services, such as the features you use
                  and the actions you take.
                </li>
              </ul>
            </div>
            <div>
              <strong className='text-xl'>Use of Information</strong>
              <ul>
                <li>
                  2.1 Provision of Services: We use the collected information
                  to provide, personalize, and improve our services. This
                  includes enabling access to the Spok.vc platform, processing
                  transactions, and delivering customer support.
                </li>
                <li>
                  2.2 Communication: We may use your contact information to
                  send you administrative messages, service updates, and
                  promotional communications related to our services. You can
                  opt out of receiving promotional communications at any time.
                </li>
                <li>
                  2.3 Analytics and Improvements: We analyze the usage data to
                  monitor and improve the performance and functionality of the
                  Spok.vc platform. This helps us understand user preferences,
                  troubleshoot issues, and enhance the overall user
                  experience.
                </li>
                <li>
                  2.4 Legal Compliance: We may use your information to comply
                  with applicable laws, regulations, or legal processes,
                  including responding to requests from governmental or
                  regulatory authorities.
                </li>
              </ul>
            </div>

            <div id='data-processing'>
              <strong className='text-xl'>Data processing</strong>
              <ul>
                <li>
                  Within spok.vc, we leverage AI technologies to enhance our
                  services. Google Workspace APIs are <strong>not</strong>{' '}
                  utilized for developing, improving, or training generalized
                  AI or ML models. However certain data accessed or processed
                  through our platform undergoes analysis for various
                  purposes, including email, calendar events, and contacts.
                  Through AI-driven processes such as summarization,
                  classification, and data extraction, we identify and extract
                  relevant information such as company names, websites,
                  founders, and other pertinent details to improve the
                  functionality and usability of our services. Additionally,
                  our AI algorithms help find connections between contacts and
                  companies. It's essential to note that we prioritize user
                  privacy and data security, adhering to our Privacy Policy
                  and maintaining transparency regarding the use of AI
                  technologies within our platform. Furthermore, users have
                  the option to opt out of these data analysis processes.
                </li>
              </ul>
            </div>

            <div>
              <strong className='text-xl'>
                Information Sharing and Disclosure
              </strong>
              <ul>
                <li>
                  3.1 Third-Party Service Providers: We may engage trusted
                  third-party service providers to assist us in delivering our
                  services. These providers may have access to your personal
                  information for the purpose of performing specific tasks on
                  our behalf, such as data storage, payment processing, or
                  customer support. We ensure that these providers adhere to
                  appropriate data protection standards.
                </li>
                <li>
                  3.2 Linked Cloud Storage Accounts: If you authorize Spok.vc
                  to access your linked cloud storage accounts (such as Google
                  Drive or Dropbox), we may collect, store, and process the
                  data necessary to provide our services. We handle this data
                  in accordance with the terms and conditions of the
                  respective cloud storage services.
                </li>
                <li>
                  3.3 Legal Requirements: We may disclose your personal
                  information if required by law or in good faith belief that
                  such disclosure is necessary to comply with legal
                  obligations, protect our rights or safety, investigate
                  fraud, or respond to a government request.
                </li>
                <li>
                  3.4 Business Transfers: In the event of a merger,
                  acquisition, or sale of all or a portion of our assets, your
                  personal information may be transferred as part of the
                  transaction. We will notify you via email or prominent
                  notice on our website before your personal information
                  becomes subject to a different privacy policy.
                </li>
                <li>
                  3.5 Data Accuracy: The accuracy of information generated
                  from emails and company decks may vary. While we employ
                  advanced algorithms and techniques to ensure data accuracy,
                  errors or inaccuracies may still occur.
                </li>
                <li>
                  3.6 Use of Information: The information provided by Spok.VC
                  is intended for informational purposes only. Users are
                  encouraged to independently verify the accuracy of any data
                  obtained through our platform before making any business
                  decisions or relying on it for any purpose.
                </li>
                <li>
                  3.7 Limitation of Liability: Spok.VC shall not be liable for
                  any damages or losses arising from the use of our services
                  or reliance on the information provided. Users agree to use
                  our platform at their own risk.
                </li>
                <li>
                  3.8 Data Privacy: We are committed to protecting the privacy
                  and security of user data. Information processed through our
                  platform is handled in accordance with our privacy policy.
                </li>

                <li className='mt-5'>
                  By using Spok.VC's services, you acknowledge and agree to
                  the terms outlined in this disclaimer. If you have any
                  questions or concerns, please contact us.
                </li>
              </ul>
            </div>

            <div>
              <strong className='text-xl'>Limited Use Disclosure</strong>
              <ul>
                <li>
                  3.9 Spok.vc's use and transfer of information received from
                  Google APIs to any other app will adhere to
                  <a
                    href='https://developers.google.com/terms/api-services-user-data-policy'
                    className='text-blue-500 underline'
                  >
                    {' '}
                    Google API Services User Data Policy
                  </a>{' '}
                  , including the
                  <a
                    href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'
                    className='text-blue-500 underline'
                  >
                    {' '}
                    Limited Use Disclosure
                  </a>
                  .
                </li>
              </ul>
            </div>

            <div>
              <strong className='text-xl'>Data Storage and Privacy</strong>
              <ul>
                <li>
                  4.1 Spok.vc will store and process your data in accordance
                  with our Privacy Policy. By using our services, you consent
                  to the collection, storage, and processing of your data as
                  described in our Privacy Policy.
                </li>
                <li>
                  4.2 Spok.vc will take reasonable measures to ensure the
                  security and confidentiality of your data. However, we
                  cannot guarantee absolute security and will not be liable
                  for any unauthorized access, loss, or corruption of your
                  data.
                </li>
                <li>
                  4.3 You acknowledge and agree that Spok.vc may access and
                  use your linked cloud storage accounts solely for the
                  purpose of providing our services and that we will handle
                  such access in compliance with our Privacy Policy.
                </li>
              </ul>
            </div>
            <div>
              <strong className='text-xl'>Data Security</strong>
              <ul>
                <li>
                  4.1 Spok.vc implements reasonable security measures to
                  protect your personal information against unauthorized
                  access, loss, or alteration. However, no method of
                  transmission over the internet or electronic storage is 100%
                  secure, and we cannot guarantee absolute security.
                </li>
                <li>
                  4.2 If you have reason to believe that your interaction with
                  us is no longer secure, please contact us immediately.
                </li>
              </ul>
            </div>
            <div>
              <strong className='text-xl'>
                Google Gmail and Calendar Integration
              </strong>
              <ul>
                <li>
                  4.3 To enhance communication and collaboration features
                  within Spok.vc, we require access to certain Gmail and
                  Calendar scopes:
                  <br />
                  <br />
                  1. Spok reads your Gmail messages to provide contextual
                  information and facilitate seamless integration.
                  <br />
                  2. Spok sends emails on your behalf, ensuring efficient
                  communication between users.
                  <br />
                  3. Spok can compose and draft emails within the platform for
                  user convenience.
                  <br />
                  4. Spok accesses to your Gmail contacts to streamline
                  communication and collaboration efforts.
                  <br />
                  5. Spok accesses your Calendar, to prove scheduling and
                  event management features, enhancing productivity and
                  coordination among users.
                </li>
              </ul>
            </div>

            <div>
              <strong className='text-xl'>Data Retention</strong>
              <ul>
                <li>
                  5.1 We retain your personal information for as long as
                  necessary to fulfill the purposes outlined in this Privacy
                  Policy unless a longer retention period is required or
                  permitted by law.
                </li>
                <li>
                  5.2 You can request the deletion of your account and
                  associated personal information by contacting us. However,
                  please note that certain data may need to be retained for
                  legal or legitimate business purposes.
                </li>
              </ul>
            </div>
            <div>
              <strong className='text-xl'>Children's Privacy</strong>
              <ul>
                <li>
                  6.1 Spok.vc's services are not directed to individuals under
                  the age of 18. We do not knowingly collect or solicit
                  personal information from anyone under the age of 18. If we
                  become aware that we have collected personal information
                  from a child without parental consent, we will take steps to
                  delete that information.
                </li>
              </ul>
            </div>
            <div>
              <strong className='text-xl'>
                Changes to this Privacy Policy
              </strong>
              <ul>
                <li>
                  7.1 We reserve the right to modify or update this Privacy
                  Policy at any time. We will notify you of any material
                  changes by posting the updated Policy on our website or
                  through other communication channels. Your continued use of
                  our services after the effective date of the revised Privacy
                  Policy constitutes your acceptance of the changes.
                </li>
              </ul>
            </div>
            <div>
              <strong className='text-xl'>Contact Us</strong>
              <ul>
                <li>
                  If you have any questions, concerns, or requests regarding
                  this Privacy Policy or our privacy practices, please contact
                  us at admin@spok.vc. We will endeavor to respond to your
                  inquiries in a timely manner.
                </li>
              </ul>
            </div>
          </article>
        </section>
      </Layout>
    </main>
  );
}

export default PrivacyPolicyPage;
