import { isWithinInterval } from "date-fns";
import { useState } from "react";
import type { DateRange } from "react-day-picker";
import { AI_REPORTS_SAMPLE_DATA } from "../data/reports.data";
import AiReportsFilter from "./AIReportsFilter";
import AiReportsList from "./AiReportsList";

export default function AiReports() {

	const [dateRangeFilter, setDateRangeFilter] = useState<DateRange | undefined>()
	const [statusFilter, setStatusFilter] = useState<string>("none")
	const [mockData, setMockData] = useState(AI_REPORTS_SAMPLE_DATA)

  function filterReportsSampleData(dateRange: DateRange | undefined, status: string) {
    setDateRangeFilter(dateRange);
		setStatusFilter(status);
		let filteredData = AI_REPORTS_SAMPLE_DATA;

		if(dateRange?.from && dateRange.to) {
			filteredData = filteredData.filter(x => isWithinInterval(x.createdAt, {start: dateRange.from as Date, end: (dateRange.to || dateRange.from) as Date}))
		}
		
		if(status && status !== "none") {
			filteredData = filteredData.filter(x => {
				return x.company.funnel.name.toLowerCase() === status.toLowerCase()
			})
		}

		setMockData(filteredData);
  }

	return (
		<div className="mx-60">
			<AiReportsFilter onSelectDateRange={(value) => filterReportsSampleData(value, statusFilter)} onSelectFilter={(value) => filterReportsSampleData(dateRangeFilter, value)}/> 

			<AiReportsList data={mockData}/>
		</div>
	)
}
