import { clsx } from 'clsx';
import Button from 'components/Buttons/Button';
import { Calendar } from 'components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { addDays, endOfMonth, endOfWeek, endOfYear, format, startOfMonth, startOfWeek, startOfYear, subMonths, subWeeks, subYears } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { useState } from 'react';
import type { DateRange } from "react-day-picker";


interface Props {
  onSelect: (value: DateRange | undefined) => void,
  defaultValue?: DateRange | undefined,
  iconColor?: string,
  iconSize?: number
  showPresets?: boolean
}

const PresetFilterTypeEnum = {
  LastWeek: 'LastWeek',
  LastMonth: 'LastMonth',
  LastYear: 'LastYear',
  ThisYear: 'ThisYear',
  FirstQuarter: 'FirstQuarter',
  SecondQuarter: 'SecondQuarter',
  ThirdQuarter: 'ThirdQuarter',
  FourthQuarter: 'FourthQuarter',
} as const;

type PresetFilterType = typeof PresetFilterTypeEnum[keyof typeof PresetFilterTypeEnum];

const presets = {
  "FirstQuarter": (): DateRange => {
    const now = new Date();
    const start = startOfMonth(new Date(now.getFullYear(), 0, 1));
    const end = endOfMonth(new Date(now.getFullYear(), 2, 31));
    return { from: start, to: end };
  },
  "SecondQuarter": (): DateRange => {
    const now = new Date();
    const start = startOfMonth(new Date(now.getFullYear(), 3, 1));
    const end = endOfMonth(new Date(now.getFullYear(), 5, 30));
    return { from: start, to: end };
  },
  "ThirdQuarter": (): DateRange => {
    const now = new Date();
    const start = startOfMonth(new Date(now.getFullYear(), 6, 1));
    const end = endOfMonth(new Date(now.getFullYear(), 8, 30)); 
    return { from: start, to: end };
  },
  "FourthQuarter": (): DateRange => {
    const now = new Date();
    const start = startOfMonth(new Date(now.getFullYear(), 9, 1));
    const end = endOfMonth(new Date(now.getFullYear(), 11, 31));
    return { from: start, to: end };
  },
  "ThisYear": (): DateRange => {
    const now = new Date();
    const start = startOfYear(now); 
    const end = now; 
    return { from: start, to: end };
  },
  "LastYear": (): DateRange => {
    const now = new Date();
    const lastYear = subYears(now, 1);
    const start = startOfYear(lastYear); 
    const end = endOfYear(lastYear); 
    return { from: start, to: end };  
  },
  "LastMonth": (): DateRange => {
    const now = new Date();
    const start = startOfMonth(subMonths(now, 1));
    const end = endOfMonth(subMonths(now, 1));
    return { from: start, to: end };
  },
  "LastWeek": (): DateRange => {
    const now = new Date();
    const start = startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });
    let end = endOfWeek(subWeeks(now, 1), { weekStartsOn: 0 });
    end = addDays(end, 1)
    return { from: start, to: end };
  },
};

export default function SpokDateRangePicker(
  {
    onSelect,
    defaultValue,
    iconColor,
    iconSize,
    showPresets = true
  }: Props
) {

  const [date, setDate] = useState<DateRange | undefined>(defaultValue);


  function onSelectDateFilterPreset(presetFilter: PresetFilterType) {
    const preset = presets[presetFilter]();
    setDate(preset);
    onSelect(preset);
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
            id="date"
            variant="ghost"
            className={clsx(
              "w-[300px] justify-start text-left font-semibold",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon color={iconColor} size={iconSize} />
            <span>
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, "LLL dd, y")} -{" "}
                    {format(date.to, "LLL dd, y")}
                  </>
                ) : (
                  format(date.from, "LLL dd, y")
                )
              ) : (
                "Select a date"
              )}
            </span>
    
          </Button>
      </PopoverTrigger>
      <PopoverContent
        align='start'
        className='z-[999999] flex w-auto flex-col space-y-2 p-2'
      >   
          {showPresets && (
              <Select
                onValueChange={onSelectDateFilterPreset}
              >
                <SelectTrigger>
                  <SelectValue placeholder='Presets' />
                </SelectTrigger>
                <SelectContent position='popper' className='z-[999999]'>
                  <SelectItem value={PresetFilterTypeEnum.LastWeek}>Last Week</SelectItem>
                  <SelectItem value={PresetFilterTypeEnum.LastMonth}>Last Month</SelectItem>
                  <SelectItem value={PresetFilterTypeEnum.LastYear}>Last Year</SelectItem>
                  <SelectItem value={PresetFilterTypeEnum.ThisYear}>This Year</SelectItem>
                  <SelectItem value={PresetFilterTypeEnum.FirstQuarter}>First Quarter</SelectItem>
                  <SelectItem value={PresetFilterTypeEnum.SecondQuarter}>Second Quarter</SelectItem>
                  <SelectItem value={PresetFilterTypeEnum.ThirdQuarter}>Third Quarter</SelectItem>
                  <SelectItem value={PresetFilterTypeEnum.FourthQuarter}>Fourth Quarter</SelectItem>
                </SelectContent>
              </Select>
            )}

          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={(e: DateRange | undefined) => {
              setDate(e);
              onSelect(e);
            }}
            numberOfMonths={2}
          />
      </PopoverContent>
    </Popover>
  );
}
