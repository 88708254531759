import { Navigate, useLocation } from 'react-router-dom'
import { useContext, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import GeneralFundInfo from './GeneralFundInfo'
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import InviteUser from './InviteUser'
import InviteUsers from './InviteUsers'
import ProcessingOnboarding from './ProcessingOnboarding'
import SyncGoogleDrive from './AddLink'
import { auth } from 'lib/firebase'
import { useAppSelector } from 'app/hooks'

// Main Onboarding Component
function OnboardingPage() {
	const location = useLocation()
	const { authUser } = useContext(AuthContext)
	const onboardingData = useAppSelector(state => state.onboarding.data)
	const [step, setStep] = useState(1)

	// eslint-disable-next-line @typescript-eslint/prefer-optional-chain, @typescript-eslint/no-unnecessary-condition
	if (authUser && authUser.fund) {
		return <Navigate to='/dashboard' state={{ from: location }} replace />
		
	}

	if (!auth.currentUser) {
		return <Navigate to='/auth' state={{ from: location }} replace />
	}

	return (
		<div className='flex h-screen flex-col'>
			{/* Header */}
			<div className='bg-primary p-2 text-center text-lg text-white'>
				spok.vc
			</div>

			{/* Content */}
			<div className='flex flex-1 flex-col items-center justify-center'>
				{step === 1 && <GeneralFundInfo nextStep={() => setStep(2)} />}
				{step === 2 && (
					<SyncGoogleDrive
						nextStep={() => setStep(3)}
						prevStep={() => setStep(1)}
					/>
				)}
				{step === 3 && (
					<div className='w-full'>
						{onboardingData.invitedUsers.length > 0 ? (
							<InviteUsers nextStep={() => setStep(4)} />
						) : (
							<InviteUser nextStep={() => setStep(4)} />
						)}
					</div>
				)}
				{step === 4 && <ProcessingOnboarding />}
			</div>

			{/* Footer with Dots Navigation */}
			<div className='flex items-center justify-center p-4'>
				<div className='flex space-x-2'>
					<div
						className={`h-3 w-3 rounded-full ${
							step === 1 ? 'bg-blue-600' : 'bg-gray-300'
						}`}
						onClick={() => setStep(1)}
					/>
					<div
						className={`h-3 w-3 rounded-full ${
							step === 2 ? 'bg-blue-600' : 'bg-gray-300'
						}`}
						onClick={() => {
							if (step >= 2) setStep(2)
						}}
					/>
					<div
						className={`h-3 w-3 rounded-full ${
							step === 3 ? 'bg-blue-600' : 'bg-gray-300'
						}`}
						onClick={() => {
							if (step >= 3) setStep(3)
						}}
					/>
					<div
						className={`h-3 w-3 rounded-full ${
							step === 4 ? 'bg-blue-600' : 'bg-gray-300'
						}`}
					/>
				</div>
			</div>
		</div>
	)
}

export default OnboardingPage
