/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { store } from 'app/store'
import {
	addNotes,
	setNotes,
	setNotesError,
	setNotesIsLoading
} from 'features/notes/redux/notes.slice'
import {
	and,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	where
} from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'
import { NoteType, type INote } from '../interfaces/notes.interface'
import React from 'react'

const notesRef = collection(firestore, 'notes')

export const addNote = async (note: INote): Promise<INote> => {
	const docRef = doc(notesRef, note.id)
	await setDoc(docRef, note, { merge: true })

	return note
}

export const getNote = async (id: string): Promise<INote> => {
	const docRef = doc(notesRef, id)
	const docSnap = await getDoc(docRef)
	return docSnap.data() as INote
}

export const getNotes = async (fundId: string): Promise<INote[]> => {
	const q = query(notesRef, where('fund.id', '==', fundId))
	const querySnapshot = await getDocs(q)
	const notes = querySnapshot.docs.map(d => d.data() as INote)
	return notes
}

export const updateNote = async (note: INote): Promise<INote> => {
	const docRef = doc(notesRef, note.id)
	await updateDoc(docRef, note as any, { merge: true })
	return note
}

export const deleteNote = async (id: string): Promise<void> => {
	const docRef = doc(notesRef, id)
	await deleteDoc(docRef)
}

export const listenToNotes = (fundId: string) => {
	store.dispatch(setNotesIsLoading(true))

	const q = query(notesRef, where('fund.id', '==', fundId))
	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const notes = querySnapshot.docs.map(d => d.data() as INote)
			store.dispatch(setNotes(notes))
		},
		error => {
			store.dispatch(setNotesError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}

export const ListenToCompanyNotes = (companyId: string) => {
	store.dispatch(setNotesIsLoading(true))

	const q = query(notesRef, where('company.id', '==', companyId))
	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const notes = querySnapshot.docs.map(d => d.data() as INote)
			store.dispatch(addNotes(notes))
			store.dispatch(setNotesIsLoading(false))
		},
		error => {
			Errorhandler.captureException(error)
			Logger.error(error)
			store.dispatch(setNotesIsLoading(false))
		}
	)

	return unsubscribe
}

export const useCompanyNotes = (companyId: string) => {
	const [companyNotes, setCompanyNotes] = React.useState<INote[]>([])
	const [isLoading, setIsLoading] = React.useState(true)

	React.useEffect(() => {
		const q = query(notesRef, where('company.id', '==', companyId))

		const unsubscribe = onSnapshot(
			q,
			querySnapshot => {
				const notes = querySnapshot.docs.map(d => d.data() as INote)
				store.dispatch(addNotes(notes))
				setCompanyNotes(notes)
				setIsLoading(false)
			},
			error => {
				Errorhandler.captureException(error)
				Logger.error(error)
				setIsLoading(false)
			}
		)

		return () => {
			unsubscribe()
		}
	}, [companyId])

	return { companyNotes, isLoading }
}

export const listenToContactNotes = (contactId: string) => {
	store.dispatch(setNotesIsLoading(true))

	const q = query(notesRef, where('contact.id', '==', contactId))
	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const notes = querySnapshot.docs.map(d => d.data() as INote)
			store.dispatch(addNotes(notes))
		},
		error => {
			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}
export const listenToMeetingNotes = (fundId: string) => {
	store.dispatch(setNotesIsLoading(true))

	const q = query(
		notesRef,
		and(
			where('fund.id', '==', fundId),
			where('noteType', '==', NoteType.Meeting)
		)
	)
	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const notes = querySnapshot.docs.map(d => d.data() as INote)
			store.dispatch(addNotes(notes))
		},
		error => {
			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}
