import {
	Dialog,
	DialogPanel,
	Transition,
	TransitionChild
} from '@headlessui/react'

import { Fragment } from 'react'
import type { ReactNode, Dispatch, SetStateAction } from 'react'
import clsxm from 'utils/clsxm'

interface ISideDrawerProps {
	title?: string
	subTitle?: string
	children: ReactNode
	show?: boolean
	dialogPanelClass?: string
	slideFrom?: 'bottom' | 'center' | 'left' | 'right' | 'top'
	setShow?: Dispatch<SetStateAction<boolean>>
}

function SideDrawer({
	title,
	subTitle,
	children,
	show,
	dialogPanelClass,
	slideFrom = 'center',
	setShow
}: ISideDrawerProps) {
	function handleClose() {
		if (!setShow) return

		setShow(false)
	}

	const dialogAnimationClasses = (() => {
		const classes = {
			start: '',
			end: ''
		}

		switch (slideFrom) {
			case 'left': {
				classes.start = '-translate-x-6'
				classes.end = 'translate-x-0'

				break
			}

			case 'right': {
				classes.start = 'translate-x-6'
				classes.end = 'translate-x-0'
				break
			}
			case 'top': {
				classes.start = '-translate-y-6'
				classes.end = 'translate-y-0'
				break
			}
			case 'bottom': {
				classes.start = 'translate-y-6'
				classes.end = 'translate-y-0'
				break
			}

			default: {
				classes.start = 'opacity-0 scale-95'
				classes.end = 'opacity-100 scale-100'
			}
		}

		return classes
	})()

	return (
		<Transition appear show={show} as={Fragment}>
			<Dialog as='div' className='relative z-20' onClose={handleClose}>
				<TransitionChild
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-25' />
				</TransitionChild>

				<div className='fixed inset-0 overflow-y-auto'>
					<div
						className={clsxm(
							'flex min-h-full',
							slideFrom === 'center' && [' items-center justify-center'],
							slideFrom === 'right' && [' justify-end'],
							slideFrom === 'left' && [' justify-start'],
							slideFrom === 'top' && [' items-start'],
							slideFrom === 'bottom' && [' items-end']
						)}
					>
						<TransitionChild
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom={dialogAnimationClasses.start}
							enterTo={dialogAnimationClasses.end}
							leave='ease-in duration-200'
							leaveFrom={dialogAnimationClasses.end}
							leaveTo={dialogAnimationClasses.start}
						>
							<DialogPanel
								className={clsxm(
									`mx-auto w-full transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all`,
									['right', 'left'].includes(slideFrom) && ['mx-0 max-w-xl'],
									dialogPanelClass
								)}
							>
								<div className='mb-4 flex items-center justify-between'>
									{title && <h2 className='text-xl font-semibold'>{title}</h2>}

									<button
										type='button'
										className='text-xl font-semibold'
										onClick={handleClose}
									>
										&times;
									</button>
								</div>
								{subTitle && <p className='mb-4'>{subTitle}</p>}
								<div>{children}</div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default SideDrawer
