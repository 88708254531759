/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Fragment, useState } from "react";

import { Check, EditIcon } from "lucide-react";
import { CapitalCallStatus, type ICapitalCall } from "../interfaces/capitalCall.interface";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "components/ui/button";
import { onUpdateCapitalCall } from "../service/capital_call.service";

interface AppProps {
  capitalCall: ICapitalCall;
}

export default function UpdateCapitalCallStatus({ capitalCall }: AppProps) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const [selectedStatus, setSelectedStatus] = useState<CapitalCallStatus>(
    capitalCall.status ?? CapitalCallStatus.PENDING
  );

  const handleUpdate = async () => {
    const data = {
      status: selectedStatus,
    };

    await onUpdateCapitalCall(capitalCall.id, data)

    closeModal();
  };

  return (
    <>
      <span
        onClick={openModal}
        className='flex cursor-pointer flex-wrap gap-2 capitalize'
      >
        <p>{capitalCall.status}</p>
        <EditIcon className='-mr-1 h-4 w-4  ' />
      </span>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-20' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-4xl transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    Capital Call Status
                  </Dialog.Title>

                  <div className='mt-2'>
                    <p className='text-sm text-gray-500'>
                      Set the status of the Capital Call
                    </p>
                  </div>

                  <div className='mb-10 mt-5'>
                    {Object.values(CapitalCallStatus).map((item, index) => (
                      <div
                        key={item}
                        onClick={() => {
                          setSelectedStatus(item);
                        }}
                        className='flex w-fit cursor-pointer items-center gap-2 rounded py-1 pr-5 text-sm capitalize transition-all hover:bg-gray-100'
                      >
                        <div />
                        {index + 1}. {item}
                        {selectedStatus === item && (
                          <Check size={20} /> 
                        )}
                      </div>
                    ))}
                  </div>

                  <div className='mt-4'>
                    <Button
                      disabled={!selectedStatus}
                      type='button'
                      className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      onClick={handleUpdate}
                    >
                      Save
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}