/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "context/AuthContext";
import { COUNTRY_COORDINATES } from "../utils/country_corrtinates";
import GeoData from "../data/topology.data.json";
import type { GeographicDistributionItem } from "../interfaces/reports.interface";
import { VISUALIZATION_REPORT_GEOGRAPHIC_DISTRIBTION_SAMPLE_DATA } from "../data/reports.data";
import _ from "lodash";
import { listenToCompanies } from "features/companies/api/companies.api";
import pluralize from "pluralize";
import { selectCompanies } from "features/companies/redux/companies.slice";
import { useAppSelector } from "app/hooks";

export default function GeographicDistributionChart(): JSX.Element {

  const {authUser} = useContext(AuthContext);

  const companies = useAppSelector(selectCompanies)

  const [data, setData] = useState<GeographicDistributionItem[]>(VISUALIZATION_REPORT_GEOGRAPHIC_DISTRIBTION_SAMPLE_DATA);

  useEffect(() => {
    if(!authUser?.fund.id) return; 
    
    const unsubscribe = listenToCompanies(authUser.fund.id);

    // eslint-disable-next-line consistent-return
    return () => unsubscribe();
  }, [authUser?.fund.id]);


  useEffect(() => {
    const filteredCompanies = companies.filter(company => company.location?.country);
    const groupByCountry = _.groupBy(filteredCompanies, "location.country");

    const data = Object.keys(groupByCountry).map((key, index) => ({
      id: index,
      country: key,
      totalCompany: groupByCountry[key].length,
      coordinates: (COUNTRY_COORDINATES as any)[key] || [0, 0],
    }));
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setData(data as any);
  }, [companies]);

	return  (
    <div className="border bg-white border-[#E4E5E8] rounded-md px-4 py-5 min-w-[570px] min-h-[670px]">
      <div className="font-semibold">
        Geographic Distribution
      </div>
      <div>
        <div>
          <ComposableMap      
            projectionConfig={{
              scale: 180,
              center: [0, 0],
            }}
            height={670}
          >
              <Geographies geography={GeoData}>
                {({ geographies }) =>
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  geographies.map((geo) => (
                    <Geography key={geo.rsmKey} geography={geo} fill="#30D988"/>
                  ))
                }
              </Geographies>
              {
                data.map(distribution => 
                  (
                      <Marker key={distribution.id} coordinates={distribution.coordinates}>
                        <circle r={10} fill="#F00" stroke="#fff" strokeWidth={2} />
                        <text
                          textAnchor="middle"
                          y={5}
                          fontSize={12}
                          fontWeight="bold"
                          style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                        >
                          {distribution.totalCompany}
                        </text>
                      </Marker>
                  )
                )
              }
          </ComposableMap>
        </div>
      </div>
      <div className="px-6">
        {data.map((distribution) => (
          <div key={distribution.id} className="flex">
            <div className="font-semibold w-[150px]">
              {distribution.country}
            </div>
            <div>
              {distribution.totalCompany} {pluralize("Company", distribution.totalCompany)} 
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
