'use client'

import { AuthContext } from 'context/AuthContext'
import { useContext, type ReactElement } from 'react'
import { ImSpinner10 } from 'react-icons/im'
import GoogleSignInButton from './ContinueWithGoogle'

export default function UserAuthForm(): ReactElement {
	const { authLoading } = useContext(AuthContext)
	return (
		<>
			<div className='relative mt-3'>
				<div className='relative flex justify-center text-xs uppercase'>
					<span className=' text-muted-foreground px-2'>
						Connect with email linked to your fund
					</span>
				</div>
			</div>
			{authLoading ? (
				<div className='bg-primary-500 flex items-center justify-center rounded-full gap-2'>
					<ImSpinner10 className='animate-spin' />	loading...
				</div>
			) : (
				<GoogleSignInButton />
			)}
		</>
	)
}
