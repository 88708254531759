import '../css/DocTipCss.css'

import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { getAiResult, getIcon, getWidth, isPDF } from '../services/DocTipService'

import type { IFile } from 'features/files/file.interface'

export default function DocTipView({ file}: { file: IFile}) {
	const data = getAiResult(file)

	const pdfDocument = (
		<Tooltip>
			<TooltipTrigger asChild>
				<div className="overflow-scroll whitespace-nowrap font-medium text-xs truncate flex">
					<div className="flex-1">{file.name}</div>
				</div>
			</TooltipTrigger>
			<TooltipContent className="doctip" style={{width: getWidth()}}>
				<div className="content">
					<div className="header">
						{getIcon()} <span className="float-start subject">{data.type}</span>
					</div>
					<div className="body">{data.content}</div>
				</div>
			</TooltipContent>
		</Tooltip>
	)

	const nonPdfDocument = (
		<Tooltip>
			<TooltipTrigger asChild>
				<div className='overflow-scroll whitespace-nowrap font-medium text-xs truncate flex'><div className='flex-1'>{file.name}</div></div>
			</TooltipTrigger>
			<TooltipContent>{file.name}</TooltipContent>
		</Tooltip>
)
	return isPDF(file) ? pdfDocument : nonPdfDocument;
}
