/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { ICompany, IContact } from "interfaces";

import type { IProspect } from "features/prospects/interfaces/prospect.interface";

export function searchCompanies(companies: ICompany[], searchQuery: string) {
  const searchQueryLowerCase = searchQuery.toLowerCase();

  const nameHits: ICompany[] = [];
  const founderNameHits: ICompany[] = [];
  const founderEmailHits: ICompany[] = [];
  const tagsHits: ICompany[] = [];

  for (const company of companies) {
    if (
      company.name?.toString().toLowerCase().includes(searchQueryLowerCase)
    ) {
      nameHits.push(company);
    }

    if (company.founders) {
      for (const founder of company.founders) {
        if (
          founder.name?.toString().toLowerCase().includes(searchQueryLowerCase)
        ) {
          founderNameHits.push(company);
        }

        if (
          founder.email
            ?.toString()
            .toLowerCase()
            .includes(searchQueryLowerCase)
        ) {
          founderEmailHits.push(company);
        }
      }
    }

    if (company.tags) {
      for (const tag of company.tags) {
        if (tag.name?.toLowerCase().includes(searchQueryLowerCase)) {
          tagsHits.push(company);
        }
      }
    }
  }

  return { nameHits, founderNameHits, founderEmailHits, tagsHits };
}

export function searchProspects(lpFunnels: IProspect[], searchQuery: string) {
  const searchQueryLowerCase = searchQuery.toLowerCase();

  const nameHits: IProspect[] = [];
  const founderNameHits: IProspect[] = [];
  const founderEmailHits: IProspect[] = [];
  const tagsHits: IProspect[] = [];

  for (const prospect of lpFunnels) {
    if (
      prospect.name?.toString().toLowerCase().includes(searchQueryLowerCase)
    ) {
      nameHits.push(prospect);
    }

    if (prospect.teamMembers) {
      for (const founder of prospect.teamMembers) {
        if (
          founder.name?.toString().toLowerCase().includes(searchQueryLowerCase)
        ) {
          founderNameHits.push(prospect);
        }

        if (
          founder.email
            ?.toString()
            .toLowerCase()
            .includes(searchQueryLowerCase)
        ) {
          founderEmailHits.push(prospect);
        }
      }
    }

    if (prospect.tags) {
      for (const tag of prospect.tags) {
        if (tag.name?.toString().toLowerCase().includes(searchQueryLowerCase)) {
          tagsHits.push(prospect);
        }
      }
    }
  }

  return { nameHits, founderNameHits, founderEmailHits, tagsHits };
}


export function searchContacts(contacts: IContact[], searchQuery: string) {
  const searchQueryLowerCase = searchQuery.toLowerCase();

  const nameHits: IContact[] = [];
  const emailHits: IContact[] = [];
  const tagsHits: IContact[] = [];

  for (const contact of contacts) {
    if (contact.name?.toString().toLowerCase().includes(searchQueryLowerCase)) {
      nameHits.push(contact);
    }

    if (contact.email?.toString().toLowerCase().includes(searchQueryLowerCase)) {
      emailHits.push(contact);
    }

    if (contact.tags) {
      for (const tag of contact.tags) {
        if (tag.name?.toString().toLowerCase().includes(searchQueryLowerCase)) {
          tagsHits.push(contact);
        }
      }
    }
  }


  return { nameHits, emailHits, tagsHits };
}