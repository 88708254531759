import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuTrigger
} from './dropdown-menu'

import { ChevronDown } from 'lucide-react'
import clsx from 'clsx'
import clsxm from 'utils/clsxm'

export interface Props {
	label?: string
	isRequired?: boolean
	options: string[]
	value: string
	onClick: (value: string) => void
	error?: string
	subText?: string
	wrapperClass?: string
	triggerClass?: string
	dropdownContentClass?: string
}

function CustomDropDown({
	options,
	value,
	onClick,
	label,
	isRequired,
	error,
	subText,
	wrapperClass,
	triggerClass,
	dropdownContentClass
}: Props): JSX.Element {
	return (
		<div className={clsx('flex flex-1 flex-col', wrapperClass)}>
			{label && (
				// eslint-disable-next-line jsx-a11y/label-has-associated-control
				<label className='mb-2 block font-semibold text-primary'>
					{label}
					{isRequired && <span className='text-red-500'>*</span>}
				</label>
			)}

			<div className='w-full'>
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<div
							className={clsxm(
								'flex justify-between rounded-md border border-spok-gray px-2 py-2 capitalize',
								triggerClass
							)}
						>
							{value || '-'}
							<ChevronDown className='text-spok-gray' />
						</div>
					</DropdownMenuTrigger>
					<DropdownMenuContent
						className={clsxm('w-72', dropdownContentClass)}
						align='start'
					>
						<DropdownMenuGroup>
							{options.map(option => (
								<DropdownMenuItem
									key={option}
									onClick={() => onClick(option)}
									className={clsxm('hover:bg-gray-100')}
								>
									<div className='flex items-center'>
										<span className='capitalize'>{option}</span>
									</div>
								</DropdownMenuItem>
							))}
						</DropdownMenuGroup>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>

			<div>
				{error && <div className='mt-1 text-xs text-red-500'>{error}</div>}
			</div>
			<div>
				{subText && (
					<div className='text-slate-500 ml-3 mt-1 text-xs'>{subText}</div>
				)}
			</div>
		</div>
	)
}

export { CustomDropDown }
