/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useAppSelector } from 'app/hooks'
import { store } from 'app/store'
import { addDays } from 'date-fns'
import { selectCapitalCallSettings } from 'features/settings/CapitalCallTemplates/redux/capital_call_template.slice'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { emailAddressesMapToArray } from '../helper/capitalCall'
import type { IEmailEditor } from '../interfaces/NewCapitalCall.interface'
import {
	selectRecipientsEmailAddress,
	setRecipientsEmailEditor
} from '../redux/newCapitalCall.slice'

export default function useCreateEmailEditor() {
	const emailAddresses = useAppSelector(selectRecipientsEmailAddress)

	const { data } = useAppSelector(selectCapitalCallSettings)
	let hasInitialValue = false;

	const formik = useFormik<IEmailEditor>({
		initialValues: {
			from: data?.emailFrom || '',
			replyTo: data?.emailReplyToAddress || '',
			to: emailAddressesMapToArray(emailAddresses),
			testEmailRecipient: data?.testEmailRecipient || '',
			subject: data?.emailSubject || '',
			previewText: data?.emailPreviewText || '',
			emailContent: data?.emailBody || '',
			dueDate: addDays(new Date(), 10).toISOString()
		},
		onSubmit: values => {
			store.dispatch(setRecipientsEmailEditor(values))
		}
	})

	useEffect(() => {
		if(data && !hasInitialValue ) {
			formik.setValues({
				from: data.emailFrom || '',
				replyTo: data.emailReplyToAddress || '',
				to: emailAddressesMapToArray(emailAddresses),
				testEmailRecipient: data.testEmailRecipient || '',
				subject: data.emailSubject || '',
				previewText: data.emailPreviewText || '',
				emailContent: data.emailBody || '',
				dueDate:  addDays(new Date(), 10).toISOString()
			})

			hasInitialValue  = true;
		}
	}, [data])

	return { formik }
}
