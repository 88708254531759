/* eslint-disable no-param-reassign */
import type { IEmailSignature } from 'interfaces/emailSignature.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface EmailSignaturesSliceState {
	data: IEmailSignature[]
	isLoading: boolean
	status: 'failed' | 'idle' | 'loading' | 'success'
	error?: string | null
}

const initialState: EmailSignaturesSliceState = {
	data: [],
	status: 'idle',
	error: null,
	isLoading: false
}

export const emailSignaturesSlice = createAppSlice({
	name: 'emailSignatures',
	initialState,
	reducers: create => ({
		setEmailSignaturesLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setEmailSignatures: create.reducer(
			(state, action: PayloadAction<IEmailSignature[]>) => {
				state.data = action.payload
			}
		)
	}),
	selectors: {
		selectEmailSignaturesState: state => state,
		selectEmailSignatures: signatures => signatures.data,
		selectDefaultEmailSignature: (signatures, userId) =>
			signatures.data.find(signature => signature.id === userId)
	}
})

export const {
	selectEmailSignatures,
	selectEmailSignaturesState,
	selectDefaultEmailSignature
} = emailSignaturesSlice.selectors

export const { setEmailSignatures, setEmailSignaturesLoading } =
	emailSignaturesSlice.actions
