/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { NoteType, type INote } from '../interfaces/notes.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { createAppSlice } from 'app/createAppSlice'

export interface NoteSliceState {
	data: INote[]
	isLoading: boolean
	error?: string | null
}

const initialState: NoteSliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const notesSlice = createAppSlice({
	name: 'notes',
	initialState,
	reducers: create => ({
		setNotesIsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setNotesError: create.reducer(
			(state, action: PayloadAction<string | null>) => {
				state.error = action.payload
				state.isLoading = false
			}
		),
		setNotes: create.reducer((state, action: PayloadAction<INote[]>) => {
			state.isLoading = false
			state.data = action.payload
		}),
		addNotes: create.reducer((state, action: PayloadAction<INote[]>) => {
			// Merge old notes with new notes and remove duplicates
			const newData = [...action.payload, ...state.data]
			state.data = _.uniqBy(newData, 'id')
			state.isLoading = false
		})
	}),
	selectors: {
		selectNotes: note => note.data,
		selectNotesCount: note => note.data.length,
		selectNoteStatus: note => note.isLoading,
		selectCompanyNotes: (note, companyId: string) =>
			note.data.filter(n => n.company?.id === companyId),
		selectMeetingNotes: state =>
			state.data.filter(n => (n.noteType as NoteType) === NoteType.Meeting),
		selectLimitedPartnerNotes: (note, lpId: string) =>
			note.data.filter(n => n.limitedPartner?.id === lpId),
		selectContactNotes: (note, contactId: string) =>
			note.data.filter(n => n.contact?.id === contactId),
		selectContactNotesCount: (note, contactId: string) =>
			note.data.filter(n => n.contact?.id === contactId).length
	}
})

export const { setNotes, setNotesIsLoading, setNotesError, addNotes } =
	notesSlice.actions

export const {
	selectNotes,
	selectNotesCount,
	selectMeetingNotes,
	selectNoteStatus,
	selectCompanyNotes,
	selectLimitedPartnerNotes,
	selectContactNotes,
	selectContactNotesCount
} = notesSlice.selectors
