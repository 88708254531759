import { Link, useLocation } from 'react-router-dom'
import {
	fetchInvitation,
	selectInvitationState
} from '../redux/invitation.slice'
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect, useState } from 'react'

import Button from 'components/Buttons/Button'
import { ImSpinner10 } from 'react-icons/im'
import LoadingOrError from 'components/LoadingOrError'
import Logger from 'lib/logger'
import { processInvitation } from '../invitations.api'
import { toast } from 'sonner'

export default function InvitationPage(): JSX.Element {
	const dispatch = useAppDispatch()
	const location = useLocation()
	const invitationId = location.pathname.split('/').pop()

	const [decision, setDecision] = useState<'accept' | 'decline'>()
	const [isMakingDecision, setIsMakingDecision] = useState(false)

	const { isLoading, error, data } = useAppSelector(selectInvitationState)

	useEffect(() => {
		if (invitationId) {
			dispatch(fetchInvitation(invitationId))
		}
	}, [dispatch, invitationId])

	async function onDecisionMade(value: 'accept' | 'decline') {
		if (!invitationId) return

		try {
			setIsMakingDecision(true)
			const result = await processInvitation(invitationId, value)
			if (!result.success) throw new Error(result.message)
			toast.success(result.message ?? 'Invitation processed successfully')
			setDecision(value)

			if (value === 'accept') {
				setTimeout(() => {
					window.location.href = '/login'
				}, 5000)
			}
		} catch (error: any) {
			Logger.error(error)
			toast.error(error.message ?? 'An error occurred')
		} finally {
			setIsMakingDecision(false)
		}
	}

	if (isLoading) {
		return <LoadingOrError />
	}

	if (error) {
		return (
			<div className='flex h-screen w-screen flex-col items-center gap-4 pt-20'>
				<img
					src='/images/logo/Logo-4.png'
					className='h-20 w-20 rounded-full'
					alt=''
				/>
				<div>Invalid Invite {error}</div>
			</div>
		)
	}

	if (!data) {
		return (
			<div className='flex h-screen w-screen flex-col items-center gap-4 pt-20'>
				<img
					src='/images/logo/Logo-4.png'
					className='h-20 w-20 rounded-full'
					alt=''
				/>
			</div>
		)
	}

	if (decision === 'accept') {
		return (
			<div className='flex h-screen w-screen flex-col items-center gap-4 pt-20'>
				<img
					src='/images/logo/Logo-4.png'
					className='h-20 w-20 rounded-full'
					alt=''
				/>
				<div>Invitation Accepted</div>
				<div className='mt-10'>
					You will be redirected to the fund page in 5 seconds
				</div>
				<Button className='mt-3'>
					<Link to='/login'>Login</Link>
				</Button>
			</div>
		)
	}

	if (decision === 'decline') {
		return (
			<div className='flex h-screen w-screen flex-col items-center gap-4 pt-20'>
				<img
					src='/images/logo/Logo-4.png'
					className='h-20 w-20 rounded-full'
					alt=''
				/>
				<div>Invitation Declined</div>
			</div>
		)
	}

	return (
		<div className='flex w-full flex-col items-center pt-10'>
			<img
				src='/images/logo/Logo-4.png'
				className='h-20 w-fit rounded-full border'
				alt=''
			/>
			<div className='mt-2 text-xl'>
				{data.fund.name} has invited you to join their fund on Spok.vc.
			</div>

			{!isMakingDecision && (
				<div className='mt-10 flex gap-5'>
					<Button
						id='button-accept-invitation'
						disabled={isMakingDecision}
						isLoading={isMakingDecision}
						onClick={() => {
							onDecisionMade('accept')
						}}
					>
						Accept Invitation
					</Button>

					<Button
						className='bg-red-500'
						disabled={isMakingDecision}
						isLoading={isMakingDecision}
						onClick={() => {
							onDecisionMade('decline')
						}}
					>
						Decline Invitation
					</Button>
				</div>
			)}

			{isMakingDecision ? <ImSpinner10 className='mt-5 animate-spin' /> : null}
		</div>
	)
}
