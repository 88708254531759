/* eslint-disable @typescript-eslint/no-explicit-any */
import { disconnectGmail } from 'features/authentication/authentication.api'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'
import { toast } from 'sonner'
import { authorizeGmail } from '../api/gmail.api'

export const handleGoogleAuthorization = () => {
	try {
		localStorage.setItem('googleAuth', 'google_mail')

		authorizeGmail()
	} catch (error: any) {
		toast.error(error.message ?? 'Failed to connect Gmail')
		localStorage.removeItem('googleAuth')
	}
}

export const disconnectGoogleAuthorization = async (userId: string) => {
	localStorage.removeItem('googleAuth')

	try {
		await disconnectGmail(userId)

		toast.success('Gmail disconnected successfully!')
	} catch (error: any) {
		toast.error(error.message ?? 'Failed to disconnect Gmail')

		Logger.error(error)
		Errorhandler.captureException(error)
	}
}
