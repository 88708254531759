import * as yup from 'yup'

export const COMPANY_ONBOARDING_VALIDATION_SCHEMA = yup.object({
	name: yup.string().trim().required(),
	description: yup.string().trim().required(),
	// tagIds: yup
	// 	.array(yup.string())
	// 	.min(1, 'select at least one tag to identify company'),
	tags: yup
		.array(yup.object())
		.min(1, 'select at least one tag to identify company'),
	industries: yup.array(yup.object()).min(1, 'select at least one industry')
})
