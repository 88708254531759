import type { FundData } from './fund.interface'

export interface User {
	id: string
	email: string
	name: string
	photoUrl?: string
	role: UserRole
	fund: FundData
	googleToken?: {
		refreshToken: string
		tokenType: string
		expiryDate: string
	} | null
	googleMail?: {
		historyId?: string
		expirationDate?: string
	}
	defaultEmailSignature?: string
	createdAt: string
	lastUpdatedAt: string
	integrations?: {
		googleCalendar?: boolean
		googleMail?: boolean
		googleDrive?: boolean
		dropbox?: boolean
	}
}

export interface UserData {
	id: string
	name: string
	photoUrl?: string
}

export enum UserRole {
	Admin = 'admin',
	OpsManager = 'ops-manager',
	VenturePartner = 'vp',
	LimitedPartner = 'lp',
	GeneralPartner = 'gp',
	TeamMember = 'team-member',
	SuperAdmin = 'super-admin'
}
