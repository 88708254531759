import { Expand, MinusIcon, Shrink, X } from 'lucide-react'
import { Fragment, useEffect } from 'react'
import {
	selectMailComposeState,
	setMailWindowState,
	setMessage
} from '../redux/mailcompose.slice'

import Button from 'components/Buttons/Button'
import { Input } from 'components/ui/input'
import MailAuthorizeButton from './MailAuthorizeButton'
import QuillEditor from 'features/quilleditor/views/QuillEditor'
import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import clsxm from 'utils/clsxm'
import { selectAuthState } from 'features/authentication/authentication.slice'
import { selectDefaultEmailSignature } from '../redux/emailSignatures.slice'
import { store } from 'app/store'
import { useAppSelector } from 'app/hooks'
import useEmailCompose from '../hooks/useEmailCompose'

export default function EmailComposer() {
	const { authUser } = useAppSelector(selectAuthState)

	const { formik } = useEmailCompose()
	const { windowState } = useAppSelector(selectMailComposeState)

	const defaultEmailSignature = useAppSelector(state =>
		selectDefaultEmailSignature(state, authUser?.defaultEmailSignature)
	)

	useEffect(() => {
		formik.setFieldValue('windowState', windowState)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowState])

	useEffect(() => {
		if (defaultEmailSignature) {
			const content = `<p><br></p><p><br></p>${defaultEmailSignature.content}`
			store.dispatch(setMessage(content))
			formik.setFieldValue('message', defaultEmailSignature.content)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultEmailSignature])

	if (!authUser?.googleToken?.refreshToken) {
		return (
			<div>
				<div className={clsxm('z-[9999] w-fit sm:bottom-5 sm:right-5')}>
					<Transition
						show={windowState === 'maximize'}
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className={clsxm('fixed inset-0 bg-black bg-opacity-25')} />
					</Transition>

					<div
						className={clsxm(
							'relative  transition-all duration-200',
							'w-screen ',
							windowState === 'maximize' && 'sm:w-[60vw]',
							windowState === 'restore' && 'w-[700px]',
							windowState === 'minimize' && 'w-[20vw]',
							windowState === 'close' && 'hidden'
						)}
					>
						<Transition
							as='div'
							show
							enter='ease-in-out duration-600'
							enterFrom='opacity-0 translate-y-4'
							enterTo='opacity-100 translate-y-0'
							leave='ease-in-out duration-200'
							leaveFrom='opacity-100 translate-y-0'
							leaveTo='opacity-0 translate-y-2'
							className={clsxm(
								'absolute bottom-0 right-0 z-20 w-full rounded-md bg-white shadow-lg',
								'border border-gray-100',
								'overflow-auto sm:max-h-[95vh]'
							)}
						>
							<header
								className={clsxm(
									'flex items-center justify-between',
									'px-7 py-2',
									'sticky top-0 z-20 bg-gray-50'
								)}
							>
								<h1 className='flex items-center gap-2 text-base font-semibold text-gray-700'>
									New Message
								</h1>
								<div />
								<div className='flex items-center gap-x-4'>
									<Button
										variant='ghost'
										className='px-0 py-0'
										onClick={() => {
											store.dispatch(setMailWindowState('minimize'))
										}}
									>
										<MinusIcon className='h-5 w-5' />
									</Button>
									{windowState === 'maximize' ? (
										<Button
											variant='ghost'
											className='px-0 py-0'
											onClick={() => {
												store.dispatch(setMailWindowState('restore'))
											}}
										>
											<Shrink size={16} />
										</Button>
									) : (
										<Button
											variant='ghost'
											className='px-0 py-0'
											onClick={() => {
												if (windowState === 'minimize') {
													store.dispatch(setMailWindowState('restore'))
													return
												}
												store.dispatch(setMailWindowState('maximize'))
											}}
										>
											<Expand size={16} />
										</Button>
									)}

									<Button
										variant='ghost'
										className='px-0 py-0'
										onClick={() => {
											store.dispatch(setMailWindowState('close'))
										}}
									>
										<X className='h-5 w-5 rounded-full bg-red-500 text-white' />
									</Button>
								</div>
							</header>
							<div
								className={clsx(
									'h-96 flex-col gap-3 px-5 pb-4 pt-5',
									windowState === 'minimize' ? 'hidden' : 'flex'
								)}
							>
								<MailAuthorizeButton />

								<div>
									<p className='text-sm text-gray-500'>
										You need to authorize your google account to send emails
									</p>
								</div>
							</div>
						</Transition>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div>
			<div className={clsxm('z-[9999] w-fit sm:bottom-5 sm:right-5')}>
				<Transition
					show={formik.values.windowState === 'maximize'}
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className={clsxm('fixed inset-0 bg-black bg-opacity-25')} />
				</Transition>

				<div
					className={clsxm(
						'relative transition-all duration-200',
						'w-screen',
						formik.values.windowState === 'maximize' && 'sm:w-[60vw]',
						formik.values.windowState === 'restore' && 'w-[700px]',
						formik.values.windowState === 'minimize' && 'w-[20vw]',
						formik.values.windowState === 'close' && 'hidden'
					)}
				>
					<Transition
						as='div'
						show
						enter='ease-in-out duration-600'
						enterFrom='opacity-0 translate-y-4'
						enterTo='opacity-100 translate-y-0'
						leave='ease-in-out duration-200'
						leaveFrom='opacity-100 translate-y-0'
						leaveTo='opacity-0 translate-y-2'
						className={clsxm(
							'absolute bottom-0 right-0 z-20 w-full rounded-md bg-white shadow-lg',
							'border border-gray-100',
							'overflow-auto sm:max-h-[95vh]'
						)}
					>
						<header
							className={clsxm(
								'flex items-center justify-between',
								'px-7 py-2',
								'sticky top-0 z-20 bg-gray-50'
							)}
						>
							<h1 className='flex items-center gap-2 text-base font-semibold text-gray-700'>
								New Message
							</h1>
							<div />
							<div className='flex items-center gap-x-4'>
								<Button
									variant='ghost'
									className='px-0 py-0'
									onClick={() => {
										store.dispatch(setMailWindowState('minimize'))
									}}
								>
									<MinusIcon className='h-5 w-5' />
								</Button>
								{formik.values.windowState === 'maximize' ? (
									<Button
										variant='ghost'
										className='px-0 py-0'
										onClick={() => {
											store.dispatch(setMailWindowState('restore'))
										}}
									>
										<Shrink size={16} />
									</Button>
								) : (
									<Button
										variant='ghost'
										className='px-0 py-0'
										onClick={() => {
											if (formik.values.windowState === 'minimize') {
												store.dispatch(setMailWindowState('restore'))
												return
											}
											store.dispatch(setMailWindowState('maximize'))
										}}
									>
										<Expand size={16} />
									</Button>
								)}

								<Button
									variant='ghost'
									className='px-0 py-0'
									onClick={() => {
										store.dispatch(setMailWindowState('close'))
									}}
								>
									<X className='h-5 w-5 rounded-full bg-red-500 text-white' />
								</Button>
							</div>
						</header>
						<div
							className={clsx(
								'flex-col gap-3 px-5 pb-4 pt-5',
								formik.values.windowState === 'minimize' ? 'hidden' : 'flex'
							)}
						>
							<Input
								id='to'
								name='to'
								placeholder='Recipient'
								value={formik.values.to}
								onChange={e => {
									const values = e.target.value.split(',')
									formik.setFieldValue('to', values)
								}}
								error={formik.touched.to && formik.errors.to}
							/>
							<Input
								id='subject'
								name='subject'
								placeholder='Subject'
								value={formik.values.subject}
								onChange={e => {
									formik.setFieldValue('subject', e.target.value)
								}}
								error={formik.touched.subject && formik.errors.subject}
							/>
							<QuillEditor
								className='h-96'
								value={formik.values.message}
								onChange={value => {
									formik.setFieldValue('message', value)
								}}
							/>
							<div className='mt-20 flex justify-end'>
								<Button
									disabled={formik.isSubmitting || !formik.isValid}
									isLoading={formik.isSubmitting}
									onClick={() => formik.handleSubmit()}
								>
									Send Email
								</Button>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</div>
	)
}
