/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Input } from 'components/ui/input'
import {
	ECustomCompanyFormFieldType,
	type ICustomCompanyFormField
} from '../interfaces/company.interface'
import type { useFormik } from 'formik'
import ReactQuill from 'react-quill'
import clsxm from 'utils/clsxm'
import type { ComponentPropsWithoutRef } from 'react'

import type { ITag } from 'interfaces'
import Button from 'components/Buttons/Button'
import type { IIndustry } from 'features/industries/interfaces/industries.interface'

interface ICompanyFormFieldProps {
	field: ICustomCompanyFormField
	form: ReturnType<typeof useFormik>
	wrapperProps?: ComponentPropsWithoutRef<'div'>
	isSystemField?: boolean
	tags: ITag[]
	industries: IIndustry[]
}

function SystemCompanyFormField({
	field,
	form,
	tags,
	industries,
	wrapperProps
}: ICompanyFormFieldProps) {
	const { className: wrapperClassName, ...restOfWrapperProps } =
		wrapperProps ?? {}

	function toggleTag(tag: ITag) {
		if (form.values.tagIds.includes(tag.id)) {
			let existingTags = (form.values.tags ?? []) as ITag[]
			const ids: string[] = []
			existingTags = existingTags.filter(localTag => {
				if (localTag.id !== tag.id) {
					ids.push(localTag.id)
					return true
				}

				return false
			})
			form.setFieldValue('tagIds', ids)
			form.setFieldValue('tags', existingTags)
		} else {
			form.setFieldValue('tagIds', [...(form.values.tagIds ?? []), tag.id])
			form.setFieldValue('tags', [...(form.values.tags ?? []), tag])
		}
	}

	function toggleIndustry(industry: IIndustry) {
		if (form.values.industryIds.includes(industry.id)) {
			let existingIndustries = (form.values.industries ?? []) as IIndustry[]
			const ids: string[] = []
			existingIndustries = existingIndustries.filter(localIndustry => {
				if (localIndustry.id !== industry.id) {
					ids.push(localIndustry.id)
					return true
				}

				return false
			})
			form.setFieldValue('industryIds', ids)
			form.setFieldValue('industries', existingIndustries)
		} else {
			form.setFieldValue('industryIds', [
				...(form.values.industryIds ?? []),
				industry.id
			])
			form.setFieldValue('industries', [
				...(form.values.industries ?? []),
				industry
			])
		}
	}

	return (
		<div className={clsxm(wrapperClassName)} {...restOfWrapperProps}>
			{[
				ECustomCompanyFormFieldType.SingleLine,
				ECustomCompanyFormFieldType.Multiline,
				field.type === ECustomCompanyFormFieldType.Link
			].includes(field.type as ECustomCompanyFormFieldType) && (
				<Input
					label={field.label}
					type='text'
					isRequired={field.isRequired}
					isMultiline={field.type === ECustomCompanyFormFieldType.Multiline}
					value={form.values[field.field]}
					className='border-gray-300 placeholder:text-sm placeholder:text-gray-400'
					placeholder={field.subText ?? ''}
					onChange={e => {
						form.setFieldValue(field.field, e.target.value)
					}}
					onBlur={form.handleBlur(field.field)}
					error={form.errors[field.field]}
				/>
			)}

			{field.type === ECustomCompanyFormFieldType.Tag && (
				<div>
					<label
						className='pb-2 text-sm font-semibold'
						htmlFor='business-categories'
					>
						{field.label}{' '}
						{field.isRequired && <span className='text-red-600'>*</span>}
					</label>
					<p className='mt-1 text-sm leading-6 text-gray-500'>
						{field.subText}
					</p>
					<div className='-ml-2 flex flex-wrap gap-1'>
						{tags.map(tag => (
							<Button
								variant={
									(form.values.tagIds ?? []).includes(tag.id) ? 'dark' : 'ghost'
								}
								type='button'
								className={clsxm(
									'rounded-full px-2 py-1 text-sm font-normal',
									!(form.values.tagIds ?? []).includes(tag.id) && [
										'border border-gray-200 bg-gray-100'
									]
								)}
								key={tag.id}
								onClick={() => {
									toggleTag(tag)
									form.handleBlur(field.field)
								}}
							>
								{tag.name}
							</Button>
						))}
					</div>

					{form.touched[field.field] && form.errors[field.field] && (
						<small className='translate-y-10 text-sm text-red-500'>
							{form.errors[field.field] as string}
						</small>
					)}
				</div>
			)}
			{field.type === ECustomCompanyFormFieldType.Industry && (
				<div>
					<label
						className='pb-2 text-sm font-semibold'
						htmlFor='business-categories'
					>
						{field.label}{' '}
						{field.isRequired && <span className='text-red-600'>*</span>}
					</label>
					<p className='mt-1 text-sm leading-6 text-gray-500'>
						{field.subText}
					</p>
					<div className='-ml-2 flex flex-wrap gap-1'>
						{industries.map(industry => (
							<Button
								variant={
									(form.values.industryIds ?? []).includes(industry.id)
										? 'dark'
										: 'ghost'
								}
								type='button'
								className={clsxm(
									'rounded-full px-2 py-1 text-sm font-normal',
									!(form.values.industryIds ?? []).includes(industry.id) && [
										'border border-gray-200 bg-gray-100'
									]
								)}
								key={industry.id}
								onClick={() => {
									toggleIndustry(industry)
									form.handleBlur(field.field)
								}}
							>
								{industry.industry}
							</Button>
						))}
					</div>

					{form.touched[field.field] && form.errors[field.field] && (
						<small className='translate-y-10 text-sm text-red-500'>
							{form.errors[field.field] as string}
						</small>
					)}
				</div>
			)}

			{field.type === ECustomCompanyFormFieldType.RichEditor && (
				<div className='flex flex-col'>
					<label htmlFor='description' className='text-xs font-semibold'>
						{field.label}
						{field.isRequired && <span className='text-red-600'>*</span>}
					</label>

					<ReactQuill
						theme='snow'
						value={form.values[field.field]}
						className={clsxm(
							'mb-8 mt-2 w-full',
							'whitespace-pre-wrap break-normal',
							'snow-editor  !bg-white',
							'h-[100px]  pb-4 transition-all duration-100'
						)}
						placeholder={field.subText}
						onChange={e => {
							form.setFieldValue(field.field, e)
						}}
						onBlur={form.handleBlur(field.field)}
					/>
					{form.touched[field.field] && form.errors[field.field] && (
						<small className='text-sm text-red-500'>
							{form.errors[field.field] as string}
						</small>
					)}
				</div>
			)}

			{/* {field.type === ECustomCompanyFormFieldType.Dropdown && (
				<div>
					<label className='mt-4 block font-semibold' htmlFor={field.field}>
						{field.label}
						{field.isRequired && <span className='text-red-600'>*</span>}
					</label>
					<CustomDropDown
						value={form.values[field.field]}
						triggerClass={clsxm('border-gray-200 w-40 bg-gray-100')}
						dropdownContentClass={clsxm('!w-fit')}
						onClick={option => form.setFieldValue(field.field, option)}
						options={
							field.field === 'industry' ? industryNames : field.options ?? []
						}
					/>
				</div>
			)} */}
			{/* {field.type === ECustomCompanyFormFieldType.Dropdown && (
				<div>
					<label className='mt-4 block font-semibold' htmlFor='hqCountry'>
						{field.label}
						{field.isRequired && <span className='text-red-600'>*</span>}
					</label>
					<CountryDropdown
						whitelist={[]}
						priorityOptions={['US', 'NZ', 'CA']}
						classes={clsxm(
							'w-full  border-gray-300'
							// formik.errors[field.field] &&
							// formik.touched[field.field] &&
							// 'border-red-500'
						)}
						value={form.values[field.field]}
						onChange={e => {
							form.setFieldValue(field.field, e)
						}}
					/>
				</div>
			)} */}

			{/* {field.type === 'hqRegion' && (
				<div>
					<label className='mt-2 block font-semibold' htmlFor='hqRegion'>
						{field.name}
						{field.isRequired && <span className='text-red-600'>*</span>}
					</label>

					<RegionDropdown
						classes='w-full border-gray-300'
						disableWhenEmpty
						country={form.values.hqCountry}
						value={form.values[field.field]}
						onChange={e => {
							form.setFieldValue(field.field, e)
						}}
					/>
				</div>
			)} */}

			{/* {field.type === ECustomCompanyFormFieldType.File && (
				<div className='mt-5'>
					File
					{selectedFile ? (
						<div className='mt-10 h-full'>
							<InvestmentFileViewer
								companyName=''
								isDeck
								rawFile={selectedFile}
								file={fileData as ICompanyFile}
							/>
						</div>
					) : (
						<div className=' flex h-full w-full flex-col items-center justify-center gap-5  border bg-white py-10'>
							<div className='scale-[2]'>
								<ion-icon name='document-outline' />
							</div>
							<FileUploader
								shouldUpload={false}
								multiple={false}
								buttonText='Upload Deck'
								onUpload={results => {
									handleSelected(results)
								}}
							/>
						</div>
					)}
				</div>
			)} */}
		</div>
	)
}

export default SystemCompanyFormField
