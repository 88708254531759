import {
	authorizeGoogleDrive,
	verifyGoogleDriveCode
} from '../api/google_drive.api'

/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'sonner'

export const handleGoogleDriveAuthorization = () => {
	try {
		localStorage.setItem('googleAuth', 'google_drive')
		authorizeGoogleDrive()
	} catch (error: any) {
		toast.error(error.message ?? 'Failed to connect Gmail')
		localStorage.removeItem('googleAuth')
	}
}

export const authenticateGoogleDrive = async (code: string) => {
	try {
		const result = await verifyGoogleDriveCode(code)
		if (result.error) throw new Error(result.error)

		toast.success('GoogleDrive connected successfully!')
	} catch (error: any) {
		toast.error(error.message ?? 'Failed to connect GoogleDrive')
	}
}

export const getFilesFromGoogleDrive = async () => {}
