import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger
} from 'components/ui/hover-card'
import {
	addRecipientsPaymentDetails,
	clearRecipientPaymentDetails,
	removeRecipientsPaymentDetails,
	selectIsEmailAddressesNextStepDisable,
	selectIsPaymentDetailsNextStepDisable,
	selectIsRecipientNextStepDisable,
	selectIsRecipientsCapitalToBeCalledStepDisable,
	selectRecipients,
	selectRecipientsPaymentDetails
} from '../redux/newCapitalCall.slice'
import {
	selectBankAccounts,
	selectDefaultBankAccount
} from 'features/bank_accounts/redux/bankAccounts.slice'
import {
	selectCryptoAccounts,
	selectDefaultCryptoAccount
} from 'features/crypto_accounts/redux/cryptoAccounts.slice'
import { useEffect, useState } from 'react'

import AddPaymentTag from 'features/payment_account_tags/component/AddPaymentTag'
import Button from 'components/Buttons/Button'
import type { IBankAccount } from 'features/bank_accounts/interface/BankAccount.interface'
import type { ICryptoAccount } from 'features/crypto_accounts/interface/cryptoAccount.interface'
import type { ILimitedPartner } from 'interfaces'
import PaymentAccountTags from 'features/payment_account_tags/component/PaymentAccountTags'
import type { PaymentMethod } from '../interfaces/NewCapitalCall.interface'
import PaymentMethodDropdown from '../components/PaymentMethodSelect'
import { capitalCallPaymentAccountDropdown } from '../helper/capitalCall'
import { isBankAccount } from 'features/payment_account_tags/interface/bankAccountTags.interface'
import { store } from 'app/store'
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useAppSelector } from 'app/hooks'
import { useWizard } from 'react-use-wizard'

function onSelectPaymentTag(value: IBankAccount | ICryptoAccount, id: string) {
	store.dispatch(addRecipientsPaymentDetails({ id, value }))
}

function onRemovePaymentTag(id: string, index: number) {
	store.dispatch(removeRecipientsPaymentDetails({ id, index }))
}

export default function NewCapitalCallAccountForPaymentStep(): JSX.Element {
	const recipientsPaymentDetailsMap = useAppSelector(
		selectRecipientsPaymentDetails
	)
	const recipients = useAppSelector(selectRecipients)
	const [paymentType, setPaymentType] = useState<Record<string, PaymentMethod>>(
		{}
	)
	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(false)

	const { nextStep } = useWizard()

	const bankAccounts = useAppSelector(selectBankAccounts)
	const defaultBankAccount = useAppSelector(selectDefaultBankAccount)

	const cryptoAccounts = useAppSelector(selectCryptoAccounts)
	const defaultCryptoAccount = useAppSelector(selectDefaultCryptoAccount)

	const isRecipientNextStepDisable = useAppSelector(
		selectIsRecipientNextStepDisable
	)
	const isEmailAddressesNextStepDisable = useAppSelector(
		selectIsEmailAddressesNextStepDisable
	)
	const isPaymentDetailsNextStepDisable = useAppSelector(
		selectIsPaymentDetailsNextStepDisable
	)
	const isRecipientsCapitalToBeCalledStepDisable = useAppSelector(
		selectIsRecipientsCapitalToBeCalledStepDisable
	)

	useEffect(() => {
		if (isFirstLoad) return

		if (defaultBankAccount) {
			const paymentMethod: Record<string, PaymentMethod> = {}
			for (const lp of recipients) {
				if (recipientsPaymentDetailsMap[lp.id].length <= 0)
					onSelectPaymentTag(defaultBankAccount, lp.id)
				else {
					paymentMethod[lp.id] = isBankAccount(
						recipientsPaymentDetailsMap[lp.id][0]
					)
						? 'Bank Account'
						: 'Crypto Account'
				}
			}
			setPaymentType(paymentMethod)
		}

		setIsFirstLoad(true)
	}, [defaultBankAccount, recipients, recipientsPaymentDetailsMap, isFirstLoad])

	function paymentMethodDropdownChange(
		value: PaymentMethod,
		lp: ILimitedPartner
	) {
		if (
			value === paymentType[lp.id] &&
			(value === 'Bank Account' || !paymentType[lp.id])
		)
			return

		paymentType[lp.id] = value
		setPaymentType({ ...paymentType })

		store.dispatch(clearRecipientPaymentDetails({ id: lp.id }))

		if (value === 'Bank Account') {
			if (defaultBankAccount) onSelectPaymentTag(defaultBankAccount, lp.id)
		} else if (defaultCryptoAccount)
			onSelectPaymentTag(defaultCryptoAccount, lp.id)
	}

	return (
		<div className='h-100vh mx-auto min-h-[calc(100vh-3rem)] gap-x-60 bg-white p-14 pb-0'>
			<div className='rounded-md border border-[#E4E5E8] bg-white p-5'>
				<div className='mb-6 text-center text-3xl font-semibold'>
					Choose account for payment
				</div>
				<div className='flex flex-col items-center gap-7'>
					{recipients.map(recipient => (
						<div
							key={recipient.id}
							className='flex w-full flex-grow flex-wrap justify-center gap-5'
						>
							<div className='relative flex w-[250px] flex-col items-center rounded-md border border-[#E4E5E8] p-5 px-20'>
								<div>
									<Avatar className='h-20 w-20 border'>
										<AvatarImage src={recipient.photoUrl} />
										<AvatarFallback>
											{recipient.name.slice(0, 1).toUpperCase()}
										</AvatarFallback>
									</Avatar>
								</div>
								<HoverCard openDelay={0} closeDelay={0}>
									<HoverCardTrigger asChild>
										<div className='mt-2 w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-center'>
											{recipient.name}
										</div>
									</HoverCardTrigger>
									<HoverCardContent className='w-fit'>
										<div>{recipient.name}</div>
									</HoverCardContent>
								</HoverCard>
							</div>
							<div className='flex-1 basis-[calc(100%-300px-3rem)] flex-wrap items-center rounded-md border border-[#E4E5E8] p-5 px-2'>
								<PaymentMethodDropdown
									initialSelected={
										recipientsPaymentDetailsMap[recipient.id].length > 0
											? isBankAccount(
													recipientsPaymentDetailsMap[recipient.id][0]
												)
												? 'Bank Account'
												: 'Crypto Account'
											: 'Bank Account'
									}
									onChange={value =>
										paymentMethodDropdownChange(value, recipient)
									}
								/>
								<div className='mt-2 flex flex-wrap items-start gap-2 self-start'>
									{recipientsPaymentDetailsMap[recipient.id] &&
										recipientsPaymentDetailsMap[recipient.id].length > 0 && (
											<PaymentAccountTags
												data={recipientsPaymentDetailsMap[recipient.id]}
												onDelete={tagIndex =>
													onRemovePaymentTag(recipient.id, tagIndex)
												}
											/>
										)}
									{paymentType[recipient.id] === 'Crypto Account' ? (
										<AddPaymentTag
											onSelect={value =>
												onSelectPaymentTag(value, recipient.id)
											}
											options={capitalCallPaymentAccountDropdown(
												cryptoAccounts,
												recipientsPaymentDetailsMap[recipient.id]
											)}
										/>
									) : (
										<AddPaymentTag
											onSelect={value =>
												onSelectPaymentTag(value, recipient.id)
											}
											options={capitalCallPaymentAccountDropdown(
												bankAccounts,
												recipientsPaymentDetailsMap[recipient.id]
											)}
										/>
									)}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<Button
				className='right-0 ml-auto mt-5 px-10'
				variant='blue'
				onClick={() => nextStep()}
				id='continue-step-button'
				disabled={
					isRecipientNextStepDisable ||
					isEmailAddressesNextStepDisable ||
					isPaymentDetailsNextStepDisable ||
					isRecipientsCapitalToBeCalledStepDisable
				}
			>
				Continue
			</Button>
		</div>
	)
}
