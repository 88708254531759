import clsx from 'clsx'
import DeleteModal from 'components/DeleteModal'
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger
} from 'components/ui/hover-card'
import { XIcon } from 'lucide-react'
import { nanoid } from 'nanoid'
import {
	isBankAccount,
	isCryptoAccount,
	type IBankAccount,
	type ICryptoAccount
} from '../interface/bankAccountTags.interface'

interface Props {
	data: IBankAccount[] | ICryptoAccount[] | undefined
	className?: string
	onDelete?: (index: number) => void
}

export default function PaymentAccountTags({
	data,
	className,
	onDelete
}: Props) {
	return (
		<div className={clsx(`${className} flex flex-wrap gap-2`)}>
			{data?.map((account, index) => (
				<HoverCard key={nanoid()} openDelay={100} closeDelay={100}>
					<HoverCardTrigger asChild>
						<div className='rounded-smd flex cursor-pointer items-center justify-center rounded bg-[#D9EAFF] p-2 text-sm font-semibold text-blue-700'>
							<span className='px-2'>
								{isBankAccount(account) && account.title}
								{isCryptoAccount(account) && account.walletAddress}
							</span>

							{onDelete && (
								<DeleteModal
									deleteButtonId={clsx(
										isBankAccount(account) && `delete-${account.title}`,
										isCryptoAccount(account) &&
											`delete-${account.walletAddress}`
									)}
									title='Delete'
									description={`Are you sure you want to delete ${
										isBankAccount(account)
											? account.title
											: account.walletAddress
									}?`}
									trigger={
										<XIcon
											data-testid={clsx(
												isBankAccount(account) &&
													`delete-${account.accountNumber}`,
												isCryptoAccount(account) &&
													`delete-${account.walletAddress}`
											)}
											size={12}
											className='transform cursor-pointer opacity-30 transition-all duration-200 hover:scale-110 hover:text-red-500 group-hover:opacity-100'
										/>
									}
									onDelete={() => onDelete(index)}
								/>
							)}
						</div>
					</HoverCardTrigger>
					<HoverCardContent className='w-fit'>
						<div className='flex justify-between space-x-4'>
							<div className='space'>
								<div className='text-sm'>
									{isBankAccount(account) && (
										<div className='p-1 px-3'>
											<div className='mb-2'>
												<div className='font-bold text-blue-600'>Bank Name</div>
												<div>{account.bankName}</div>
											</div>
											<div className='mb-2'>
												<div className='font-bold text-blue-600'>
													Account Name
												</div>
												<div>{account.accountName}</div>
											</div>
											<div className='mb-2'>
												<div className='font-bold text-blue-600'>
													Account Number
												</div>
												<div>{account.accountNumber}</div>
											</div>
											<div>
												<div className='font-bold text-blue-600'>
													Account Address
												</div>
												<div>{account.accountAddress}</div>
											</div>
										</div>
									)}
									{isCryptoAccount(account) && (
										<div className='p-1 px-3'>
											<div className='mb-2'>
												<div className='font-bold text-blue-600'>
													Account Name
												</div>
												<div>{account.accountName}</div>
											</div>
											<div className='mb-2'>
												<div className='font-bold text-blue-600'>
													Description
												</div>
												<div>{account.description}</div>
											</div>
											<div>
												<div className='font-bold text-blue-600'>
													Wallet Address
												</div>
												<div>{account.walletAddress}</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</HoverCardContent>
				</HoverCard>
			))}
		</div>
	)
}
