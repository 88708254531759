/* eslint-disable react/function-component-definition */
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { AuthContext } from "context/AuthContext";
import type React from "react";
import { useContext } from "react";

interface AuthProps {
  notAllowedRoles: string[];
}

const RoleGuard: React.FC<AuthProps> = ({ notAllowedRoles }) => {
  const { authUser } = useContext(AuthContext);
  const location = useLocation();

  return notAllowedRoles.some((role) => authUser?.role.includes(role)) ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (authUser?.name ? (
    <Outlet />
  ) : (
    <Navigate to="/register" state={{ from: location }} replace />
  ));
};

export default RoleGuard;
