/* eslint-disable unicorn/no-array-callback-reference */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react/no-array-index-key */
import Button from "components/Buttons/Button";
import Skeleton from "components/Skeleton";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";
import { AuthContext } from "context/AuthContext";
import CreateTask from "features/tasks/view/CreateTask";
import type { ICompanyUpdate } from "features/updates/interfaces/company_update.interface";
import { ChevronDown, ChevronUp, FileText, Paperclip, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import sanitize from "sanitize-html";
import { renderDate } from "utils/date.util";
import { selectUpdateStatus, selectUpdates } from "../redux/dashboardHome.slice";
import { DashboardService } from "../services/dashboard.service";
import FileViewer from "features/fileviewers/views/FileViewer";
import logger from "lib/logger";

function UpdateItem({ update }: { update: ICompanyUpdate }) {
  const [expand, setExpand] = useState(false);

  return (
    <div>
      <div
        className="rounded-md border border-gray-200 bg-gray-100 p-3 text-xs"
        dangerouslySetInnerHTML={{
          __html: sanitize(expand ? update.note : update.note.slice(0, 200)),
        }}
      />
      {update.note.length > 200 && (
        <Button
          variant="ghost"
          className="text-xs p-1 mt-1"
          onClick={() => {
            setExpand(!expand);
          }}
        >
          {expand ? (
            <>
              View Less <ChevronUp size={12} />
            </>
          ) : (
            <>
              View More <ChevronDown size={12} />
            </>
          )}
        </Button>
      )}
      {update.files?.length > 0 && (
        <div className="flex gap-2 text-sm rounded-md w-fit mt-1 items-center ml-3">
          <Paperclip size={15} color="blue" /> added {update.files.length} files
        </div>
      )}
      {update.files?.length > 0 && (
        <div className="flex items-center gap-2 mt-2 flex-wrap">
          {update.files.map((file) => (
            <FileViewer
              key={file.id}
              file={file}
              content={
                <div className="flex gap-2 items-center cursor-pointer border rounded-full px-3 py-1 bg-gray-50 hover:bg-gray-100">
                  <FileText color="blue" size={13} />
                  <div className="text-xs truncate">{file.name}</div>
                </div>
              }
              onDelete={() => {
                logger.info("delete file");
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default function DashboardUpdates() {
  const { authUser } = useContext(AuthContext);
  const updates = useSelector(selectUpdates);
  const loading = useSelector(selectUpdateStatus);

  const hasUpdatesToday = updates.some(
    (update) =>
      new Date(update.createdAt).toDateString() === new Date().toDateString()
  );
  const hasUpdatesYesterday = updates.some(
    (update) =>
      new Date(update.createdAt).toDateString() ===
      new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
  );
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const hasUpdatesFromLastMonthExcludingTodayAndYesterday = updates.some(
    (update) =>
      new Date(update.createdAt).getTime() > oneMonthAgo.getTime() &&
      new Date(update.createdAt).toDateString() !== new Date().toDateString() &&
      new Date(update.createdAt).toDateString() !==
      new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
  );

  const hasNoUpdates =
    !hasUpdatesToday &&
    !hasUpdatesYesterday &&
    !hasUpdatesFromLastMonthExcludingTodayAndYesterday;

  useEffect(() => {
    if (!authUser?.fund.id) return;
    const unsubscribe = DashboardService.listenToFundDashboardUpdates({
      fundId: authUser.fund.id,
    });
    return () => unsubscribe();
  }, [authUser?.fund.id]);

  const renderNotes = (
    filterFn: (update: ICompanyUpdate) => boolean,
    period: string
  ) => (
    <div className="flex gap-3 border rounded-md p-3 flex-col">
      <div>{period}</div>
      <div>
        {updates.filter(filterFn).map((update, index) => (
          <div key={index} className="flex gap-5">
            <div className="mt-[0.6rem]">
              <div className="text-[0.65rem] whitespace-nowrap w-20">
                {renderDate(update.createdAt)}
              </div>
            </div>
            <div className="flex flex-col gap-3 w-full">
              <div className="flex items-center gap-1">
                {update.company?.id && (
                  <Link to={`/company/${update.company?.id}`}>
                    <Tooltip key={update.id}>
                      <TooltipTrigger asChild>
                        <Avatar className="h-8 w-8 border cursor-pointer">
                          <AvatarImage
                            src={
                              update.company?.website
                                ? `https://www.google.com/s2/favicons?sz=128&domain_url=${update.company.website}`
                                : "/images/logo/Logo-4.png"
                            }
                          />
                          <AvatarFallback>
                            {update.company?.name.charAt(0)}
                          </AvatarFallback>
                        </Avatar>
                      </TooltipTrigger>
                      <TooltipContent>
                        View {update.company?.name}
                      </TooltipContent>
                    </Tooltip>
                  </Link>
                )}
                {update.company?.id && <X size={13} color="gray" />}
                <Avatar className="h-8 w-8 border">
                  <AvatarImage
                    src={
                      update.creator?.photoUrl || "/images/logo/Logo-4.png"
                    }
                  />
                  <AvatarFallback>{update.creator?.name.charAt(0)}</AvatarFallback>
                </Avatar>
                <div className="text-sm ml-2 font-semibold">
                  {update.creator?.name}
                </div>
              </div>
              <UpdateItem update={update} />
              <div className="w-full flex items-end justify-end">
                <CreateTask
                  task={{
                    description: update.note,
                    company: {
                      id: update.company?.id || "",
                      name: update.company?.name || "",
                      website: update.company?.website || "",
                    },
                  }}
                  trigger={
                    <Button
                      id="open-add-company-task"
                      variant="ghost"
                      className="p-1 text-xs font-normal"
                    >
                      Add Task
                    </Button>
                  }
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex flex-col gap-3">
        {["Today", "Yesterday"].map((period) => (
          <div key={period} className="flex gap-3 border rounded-md p-3 flex-col">
            <div>{period}</div>
            <div>
              {["", "", "", ""].map((_, index) => (
                <div key={index} className="flex gap-5">
                  <div className="mt-[0.6rem]">
                    <Skeleton className="h-3 w-16 rounded-md border" />
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="flex items-center gap-1">
                      <Skeleton className="h-8 w-8 rounded-full border" />
                      <X size={13} color="gray" />
                      <Skeleton className="h-8 w-8 rounded-full border" />
                      <Skeleton className="h-3 w-96 ml-5 rounded-md border" />
                    </div>
                    <Skeleton className="h-16 w-full rounded-md border" />
                    <div className="w-full flex items-end justify-end">
                      <Skeleton className="h-3 w-32 rounded-md border" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3">
      {hasUpdatesToday &&
        renderNotes(
          (note) => new Date(note.createdAt).toDateString() === new Date().toDateString(),
          "Today"
        )}
      {hasUpdatesYesterday &&
        renderNotes(
          (note) =>
            new Date(note.createdAt).toDateString() ===
            new Date(new Date().setDate(new Date().getDate() - 1)).toDateString(),
          "Yesterday"
        )}
      {hasUpdatesFromLastMonthExcludingTodayAndYesterday &&
        renderNotes(
          (note) =>
            new Date(note.createdAt).getTime() > oneMonthAgo.getTime() &&
            new Date(note.createdAt).toDateString() !== new Date().toDateString() &&
            new Date(note.createdAt).toDateString() !==
            new Date(new Date().setDate(new Date().getDate() - 1)).toDateString(),
          "Last Month"
        )}
      {hasNoUpdates && (
        <div className="flex flex-col gap-3">
          <div className="flex gap-3 border rounded-md p-3 flex-col">
            <div>
              <div className="flex gap-5">
                <div className="flex flex-col gap-3 w-full">
                  <div className="flex items-center gap-1">
                    <div className="text-sm ml-2 font-semibold">No update for the past month</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
