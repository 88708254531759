/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Computer, Trash2 } from 'lucide-react'
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger
} from 'components/ui/hover-card'

import DeleteModal from 'components/DeleteModal'
import type { ICompany } from 'interfaces'
import { getColorCode } from 'utils/colors'
import { useNavigate } from 'react-router-dom'

export default function Competitors({
	competitors,
	wrap = false,
	className,
	maxNumber = 3,
	variant = 'compact',
	slice = true,
	onCompetitorDelete
}: {
	competitors: any[]
	wrap?: boolean
	className?: string
	maxNumber?: number
	variant?: 'compact' | 'default'
	slice?: boolean
	onCompetitorDelete?: (competitor: ICompany) => void
}) {
	const navigate = useNavigate()
	
	if (competitors.length === 0)
		return (
			<div>
				{variant === 'compact' ? (
					<div className='text-xs' />
				) : (
					<div
						data-testid='no-competitor-added-label'
						className='flex items-center gap-1 truncate rounded border bg-gray-50 px-3 text-sm'
					>
						<Computer size={15} />
						No competitor added
					</div>
				)}
			</div>
		)
	if (typeof competitors === 'string')
		return <div className='flex gap-1 truncate'>-</div>

	return (
		<div
			className={`flex items-center gap-1 truncate
      ${wrap ? 'flex-wrap' : ''}
      ${className}
    `}
		>
			{competitors
				.filter((_, index) => index < maxNumber)
				.map(competitor => (
					<HoverCard key={competitor.id} openDelay={100} closeDelay={100}>
						<HoverCardTrigger asChild>
							<div className='group flex cursor-pointer items-center gap-1 rounded px-1 py-[1px] text-[0.7rem] text-blue-700 border border-blue-200'>
								<div 		
									onClick={() => {
										// if (isReadOnly) return
										navigate(`/company/${competitor.id}`)
									}} 
									data-testid={`competitor-${competitor.name}`}
								>
									{competitor.name && competitor.name.length > 8 && slice
										? `${competitor.name.slice(0, 8)}...`
										: competitor.name || ''}
								</div>

								{onCompetitorDelete && (
									<DeleteModal
										deleteButtonId={`deleteCompetitor-${competitor.name}`}
										title='Delete Competitor'
										description={`Are you sure you want to delete ${competitor.name}?`}
										trigger={
											<Trash2
												data-testid={`deleteCompetitor-${competitor.name}`}
												size={12}
												className='transform cursor-pointer opacity-30 transition-all duration-200 hover:scale-110 hover:text-red-500 group-hover:opacity-100'
											/>
										}
										onDelete={() => onCompetitorDelete(competitor)}
									/>
								)}
							</div>
						</HoverCardTrigger>
						<HoverCardContent className='w-fit'>
							<div className='flex justify-between space-x-4'>
								<div className='space'>
									<p className='text-sm'>{competitor.name}</p>
								</div>
							</div>
						</HoverCardContent>
					</HoverCard>
				))}

			{competitors.length === 0 && <div className='text-xs' />}

			{competitors.length > maxNumber && (
				<HoverCard openDelay={100} closeDelay={100}>
					<HoverCardTrigger asChild>
						<div className='w-5 max-w-10 text-ellipsis text-xs'>
							+{competitors.length - maxNumber}
						</div>
					</HoverCardTrigger>
					<HoverCardContent className=' flex w-96 flex-wrap gap-2'>
						{competitors
							.filter(item => item.name && item.name.length > 0)
							.map(competitor => (
								<div
									key={competitor.id}
									style={{
										backgroundColor: `#${getColorCode(competitor.name)}22`,
										color: `#${getColorCode(competitor.name)}`,
										borderWidth: 1,
										borderColor: `#${getColorCode(competitor.name)}33`
									}}
									className=' w-fit text-ellipsis rounded px-1 py-[1px] text-xs'
								>
									{competitor.name}
								</div>
							))}
					</HoverCardContent>
				</HoverCard>
			)}
		</div>
	)
}
