import AiReports from 'features/reports/views/AiReports'
import Button from 'components/Buttons/Button'
import ReportsSummary from './ReportsSummary'
import VisualReports from 'features/reports/views/VisualReports'
import { useState } from 'react'

const _tabs = ['visualization', 'ai-reports']

export default function ReportsPage() {
  const [tabs, setTabs] = useState(_tabs)
  const [selectedTab, setSelectedTab] = useState<
    'ai-reports' | 'visualization'
  >('visualization')

  return (
    <div className='bg-white h-100vh gap-x-60 pb-0 min-h-[calc(100vh-3rem)]'>
      <ReportsSummary />
      <div className='sticky top-0 z-10 bg-white pt-5'>
        <div className='flex border-b pl-5'>
          {tabs.map(item => (
            <Button
              variant='ghost'
              onClick={() => {
                setSelectedTab(item as typeof selectedTab)
              }}
              className={`rounded-none border-b-2 px-10 pb-2 capitalize transition-all ${selectedTab === item
                ? 'border-b-[#1486C1] text-black'
                : 'border-b-transparent text-gray-500'
                }`}
              key={item}
            >
              {item.replaceAll('-', ' ')}
            </Button>
          ))}
        </div>
      </div>

      <div className=' p-4'>
        {selectedTab === 'ai-reports' && <AiReports />}
        {selectedTab === 'visualization' && <VisualReports />}
      </div>
    </div>
  )
}

