/* eslint-disable consistent-return */
import { useAppSelector } from "app/hooks";
import { AuthContext } from "context/AuthContext";
import { listenToCompanyFunnels } from "features/funnels/funnels.api";
import { selectCompanyFunnels } from "features/funnels/funnels.slice";
import { useContext, useEffect } from "react";

export default function useCompanyFunnels() {
  const { authUser } = useContext(AuthContext)
  const funnels = useAppSelector(selectCompanyFunnels)

  useEffect(() => {
    if (!authUser?.fund.id) return
    const unsubscribe = listenToCompanyFunnels(authUser.fund.id);
    return () => {
      unsubscribe()
    }

  }, [authUser?.fund.id])


  return {
    funnels
  }

}