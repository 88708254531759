/* eslint-disable react-hooks/rules-of-hooks */
import { TooltipContent, TooltipTrigger } from "@radix-ui/react-tooltip"

import { Tooltip } from "components/ui/tooltip"
import { TriangleAlert } from "lucide-react"
import { selectItemStatus } from "../status.slice"
import { useAppSelector } from "app/hooks"

interface Props {
  name: string
}

export default function StatusBadge({ name }: Props) {
  const status = useAppSelector((state) => selectItemStatus(state, name))


  if (!status) {
    return <div />
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <TriangleAlert color="#FFBF00" />
      </TooltipTrigger>
      <TooltipContent>
        <div className="bg-white shadow-lg p-4 rounded-lg">{status}</div>
      </TooltipContent>
    </Tooltip>
  )
}