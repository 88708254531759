import type { IContact } from "interfaces";

export function contactsToDropdown(
	contacts: IContact[]
): {
	label: string
	value: string
}[] {
  return contacts
  .filter(contact => (contact.name || contact.email) && contact.email) 
  .map(contact => {
      const labelValue = contact.name ? `${contact.name} | ${contact.email}` : contact.email;
      return {
          label: labelValue ?? '',
          value: contact.email ?? ""
      };
  });
}