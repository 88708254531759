/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import { getCustomFields } from 'features/customfields/customFields.api'
import type { ICustomField } from './interfaces/customfields.interface'

export interface CustomFieldSliceState {
	customFields: {
		data: ICustomField[]
		status: 'failed' | 'idle' | 'loading'
		error?: string | null
	}
	customField: {
		data: ICustomField | null
		status: 'failed' | 'idle' | 'loading'
		error?: string | null
	}
}

const initialState: CustomFieldSliceState = {
	customFields: {
		data: [],
		status: 'idle',
		error: null
	},
	customField: {
		data: null,
		status: 'idle',
		error: null
	}
}

export const customFieldSlice = createAppSlice({
	name: 'customField',
	initialState,
	reducers: create => ({
		fetchCustomFields: create.asyncThunk(
			async (fundId: string) => {
				const response = await getCustomFields(fundId)
				return response
			},
			{
				pending: state => {
					state.customFields.status = 'loading'
				},
				fulfilled: (state, action) => {
					state.customFields.status = 'idle'
					state.customFields.data = action.payload
				},
				rejected: (state, action) => {
					state.customFields.status = 'failed'
					state.customFields.error = action.error.message
				}
			}
		),
		setCustomFields: create.reducer(
			(state, action: PayloadAction<ICustomField[]>) => {
				state.customFields.status = 'idle'
				state.customFields.data = action.payload
			}
		),
		setCustomFieldsStatus: create.reducer(
			(state, action: PayloadAction<'failed' | 'idle' | 'loading'>) => {
				state.customFields.status = action.payload
			}
		)
	}),
	selectors: {
		selectCustomFields: customField => customField.customFields,
		selectCustomField: customField => customField.customField
	}
})

export const { fetchCustomFields, setCustomFields, setCustomFieldsStatus } =
	customFieldSlice.actions

export const { selectCustomFields, selectCustomField } =
	customFieldSlice.selectors
