import 'quill-mention/autoregister'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Mention, MentionBlot } from 'quill-mention'

import ArchivedFunnels from 'features/companies/submodules/ArchivedFunnels/views/ArchivedFunnels'
import AuthPage from 'features/authentication/view/AuthPage'
import { AuthProvider } from 'context/AuthContext'
import CapitalCalls from 'pages/CapitalCall'
import Companies from 'pages/Companies'
import CompanyDetails from 'features/companies/view/CompanyDetails'
import ContactDetails from 'features/contacts/view/ContactDetails'
import Contacts from 'pages/Contacts'
import DashboardPage from 'features/dashboard/view/DashboardPage'
import Dev from 'features/dev/view/dev'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Home from 'pages/Home'
import InvitationPage from 'features/invitations/view/InvitationPage'
import LimitedPartnerDetails from 'pages/LimitedPartnerDetails'
import LimitedPartners from 'pages/LimitedPartners'
import Login from 'features/authentication/view/Login'
import Mail from 'pages/Mail'
import NewCapitalCallPage from 'features/new-capital-call/views/NewCapitalCallPage'
import NotificationsPage from 'features/notifications/view/NotificationsPage'
import OnboardingPage from 'features/onboarding/view/OnboardingPage'
import PrivacyPolicyPage from 'pages/PrivacyPolicy'
import Prospects from 'pages/Prospects'
import ProtectedRoute from 'routes/Protected'
import Quill from 'quill'
import type { ReactElement } from 'react'
import Reports from 'pages/Reports'
import RoleGuard from 'features/guards/RoleGuard'
import Settings from 'features/settings/view/Settings'
import SharedLink from 'pages/SharedLinkDetails'
import SuperAdmin from 'pages/SuperAdmin'
import { Suspense } from 'react'
import Tasks from 'pages/Tasks'
import TermsAndConditions from 'pages/TermsAndConditions'
import { Toaster } from 'sonner'
import { TooltipProvider } from 'components/ui/tooltip'
import { UserRole } from 'interfaces'
import OnboardCompany from 'pages/OnboardCompany'

Quill.register(
	{ 'blots/mention': MentionBlot, 'modules/mention': Mention },
	true
)

export default function App(): ReactElement {
	return (
		<BrowserRouter>
			<DndProvider backend={HTML5Backend}>
				<AuthProvider>
					<TooltipProvider delayDuration={50}>
						<Suspense fallback={<div />}>
							<Toaster />
							<Routes>
								<Route path='/' element={<Home />} />
								<Route path='/auth' element={<AuthPage />} />
								<Route path='/login' element={<Login />} />
								<Route path='/onboarding' element={<OnboardingPage />} />
								<Route
									path='/create-company'
									element={
										<ProtectedRoute>
											<OnboardCompany />
										</ProtectedRoute>
									}
								/>
								<Route path='/shared/:id' element={<SharedLink />} />
								<Route
									path='/terms-and-conditions'
									element={<TermsAndConditions />}
								/>
								<Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
								<Route
									path='/mail'
									element={
										<ProtectedRoute>
											<Mail />
										</ProtectedRoute>
									}
								/>
								<Route
									path='/prospects'
									element={
										<ProtectedRoute>
											<Prospects />
										</ProtectedRoute>
									}
								/>
								<Route
									path='/prospects/archived'
									element={
										<ProtectedRoute>
											<ArchivedFunnels entities={['prospect']} />
										</ProtectedRoute>
									}
								/>
								<Route
									path='/prospects/:id'
									element={
										<ProtectedRoute>
											<LimitedPartnerDetails />
										</ProtectedRoute>
									}
								/>
								<Route
									path='/notifications'
									element={
										<ProtectedRoute>
											<NotificationsPage />
										</ProtectedRoute>
									}
								/>
								<Route
									path='/companies'
									element={
										<ProtectedRoute>
											<Companies />
										</ProtectedRoute>
									}
								/>
								<Route
									path='/companies/archived'
									element={
										<ProtectedRoute>
											<ArchivedFunnels entities={['company']} />
										</ProtectedRoute>
									}
								/>

								<Route
									path='/dev/franciseshun'
									element={
										<ProtectedRoute>
											<Dev />
										</ProtectedRoute>
									}
								/>
								<Route
									path='/company/:id'
									element={
										<ProtectedRoute>
											<CompanyDetails />
										</ProtectedRoute>
									}
								/>
								<Route
									path='/contacts'
									element={
										<ProtectedRoute>
											<Contacts />
										</ProtectedRoute>
									}
								/>
								<Route
									path='/contact/:id'
									element={
										<ProtectedRoute>
											<ContactDetails />
										</ProtectedRoute>
									}
								/>
								<Route
									path='/tasks'
									element={
										<ProtectedRoute>
											<Tasks />
										</ProtectedRoute>
									}
								/>
								<Route
									element={
										<RoleGuard notAllowedRoles={[UserRole.VenturePartner]} />
									}
								>
									<Route
										path='/limited-partners'
										element={
											<ProtectedRoute>
												<LimitedPartners />
											</ProtectedRoute>
										}
									/>
								</Route>
								<Route
									element={
										<RoleGuard notAllowedRoles={[UserRole.VenturePartner]} />
									}
								>
									<Route
										path='/limited-partner/:id'
										element={
											<ProtectedRoute>
												<LimitedPartnerDetails />
											</ProtectedRoute>
										}
									/>
								</Route>
								<Route
									path='/reports'
									element={
										<ProtectedRoute>
											<Reports />
										</ProtectedRoute>
									}
								/>
								<Route
									path='/god'
									element={
										<ProtectedRoute>
											<SuperAdmin />
										</ProtectedRoute>
									}
								/>

								<Route
									element={
										<RoleGuard notAllowedRoles={[UserRole.VenturePartner]} />
									}
								>
									<Route
										path='/capital-call'
										element={
											<ProtectedRoute>
												<CapitalCalls />
											</ProtectedRoute>
										}
									/>
								</Route>
								<Route
									element={
										<RoleGuard notAllowedRoles={[UserRole.VenturePartner]} />
									}
								>
									<Route
										path='/capital-call/new'
										element={
											<ProtectedRoute>
												<NewCapitalCallPage />
											</ProtectedRoute>
										}
									/>
								</Route>
								<Route
									path='/settings'
									element={
										<ProtectedRoute>
											<Settings />
										</ProtectedRoute>
									}
								/>
								<Route path='/invitations/:id' element={<InvitationPage />} />
								<Route
									path='/dashboard'
									element={
										<ProtectedRoute>
											<DashboardPage />
										</ProtectedRoute>
									}
								/>
							</Routes>
						</Suspense>
					</TooltipProvider>
				</AuthProvider>
			</DndProvider>
		</BrowserRouter>
	)
}
