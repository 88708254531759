/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as Yup from 'yup'

import Button from 'components/Buttons/Button'
import CardWrapper from '../components/CardWrapper'
import { CustomInput } from 'components/Inputs/CustomInput'
import { setOnboardingData } from '../redux/onboarding.slice'
import { useAppDispatch } from 'app/hooks'
import { useFormik } from 'formik'

interface ISyncGoogleDrive {
	prevStep: () => void
	nextStep: () => void
}

export default function SyncGoogleDrive({
	nextStep,
	prevStep
}: ISyncGoogleDrive) {
	const dispatch = useAppDispatch()

	const formik = useFormik({
		initialValues: {
			link: ''
		},
		validationSchema: Yup.object({
			link: Yup.string().url()
		}),
		onSubmit: values => {
			dispatch(setOnboardingData({ dataSources: [values.link] }))
			nextStep()
		}
	})

	return (
		<CardWrapper title='Connect your drive database'>
			<div className='space-y-4'>
				<p>
					You can obtain the link by right-clicking on the folder and selecting
					“Get link”. Ensure that the link settings are configured to “Anyone
					with the link can view”.
				</p>

				<CustomInput
					type='text'
					label='Link'
					placeholder='Paste link'
					className='w-full rounded border border-gray-300 p-2'
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						formik.setFieldValue('link', e.target.value)
					}
					value={formik.values.link}
					onBlur={formik.handleBlur('link')}
					error={formik.touched.link ? formik.errors.link : ''}
				/>

				<div className='flex gap-4'>
					<Button
						variant='outline'
						className='w-full py-2'
						onClick={() => prevStep()}
					>
						Back
					</Button>
					<Button className='w-full py-2' onClick={() => formik.handleSubmit()}>
						Continue
					</Button>
				</div>
			</div>
		</CardWrapper>
	)
}
