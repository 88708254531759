/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger
} from 'components/ui/select'

import { nanoid } from 'nanoid'

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
	onSelect: (value?: any) => void
	label?: string
	defaultValue?: any
	options: IOption[]
}

interface IOption {
	label: string
	value: string
}

export default function TaskFunnelSelector({
	onSelect,
	defaultValue,
	label = 'Status',
	options
}: Props) {
	// useEffect(() => {
	// 	const options = companies.map(company => ({
	// 		label: company.name,
	// 		value: company
	// 	}))
	// 	setOptionsResult(options)
	// }, [companies])

	return (
		<div>
			<label className='mb-2 block text-xs font-bold text-gray-700'>
				{label}
			</label>
			<Select
				defaultValue={defaultValue}
				onValueChange={e => {
					onSelect(e)
				}}
			>
				<div className='flex items-center gap-2'>
					{defaultValue ? (
						<SelectTrigger
							defaultValue='onlyContent'
							className='border border-gray-200 capitalize shadow-none'
						>
							{defaultValue.replaceAll('-', ' ')}
						</SelectTrigger>
					) : (
						<SelectTrigger className='border border-gray-200 shadow-none'>
							Select status
						</SelectTrigger>
					)}
				</div>

				<SelectContent className='z-[999999] min-h-8'>
					<SelectGroup>
						{options.map(option => (
							<SelectItem key={nanoid()} value={option.value}>
								{option.label}
							</SelectItem>
						))}
					</SelectGroup>
				</SelectContent>
			</Select>
		</div>
	)
}
