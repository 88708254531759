/* eslint-disable @typescript-eslint/no-extraneous-class */
import {
	collection,
	doc,
	onSnapshot,
	query,
	setDoc,
	where
} from 'firebase/firestore'

import type { FundData } from 'interfaces'
import type { INoteSettings } from '../interface/note_settings.interface'
import { firestore } from 'lib/firebase'
import { setNoteSettings } from '../redux/note_settings.slice'
import { store } from 'app/store'

export class NoteSettingsService {

	private static readonly ref = collection(
		firestore,
		'note_settings'
	)
	
	public static async saveNoteSettings({
		settings,
		fund
	}: {
		settings: INoteSettings
		fund?: FundData
	}) {
		if (!fund) return

		const res = doc(this.ref, fund.id)
		await setDoc(res, { ...settings, fund }, { merge: true })
	}

	// Listen to capital call template settings
	public static listenToNoteSettings({
		fundId
	}: {
		fundId: string
	}) {
		const q = query(this.ref, where('fund.id', '==', fundId))

		return onSnapshot(q, querySnapshot => {
			const settings = querySnapshot.docs.map(d => d.data())
			if (settings.length === 0) return
			store.dispatch(setNoteSettings(settings[0] as INoteSettings))
		})
	}
}
