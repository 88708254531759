import { useEffect, useState } from 'react'

import type { ICompany } from 'interfaces'
import type { ISharedLink } from 'features/companies/interfaces/sharedLink.interface'
import type { ISharedLinkView } from '../interfaces/shared_link_view.interace'
import { SharedLinksService } from '../services/sharedlinks.service'
import type { TabItem } from './HorizontalTabs'
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { convertStringToNanoID } from 'utils'
import { getSidebarIcon } from 'features/companies/view/CompanyDetails'
import { toast } from 'sonner'
import { useLocation } from 'react-router-dom'

interface TabsProps {
	tabs: TabItem[]
	linkData: ISharedLink
	company: ICompany
	userData: { name?: string; email: string }
	children?: (selectedTabId: string) => JSX.Element
}

export default function VertcialTabs({
	tabs,
	linkData,
	company,
	userData,
	children
}: TabsProps) {
	const location = useLocation()
	// Get the session id
	const [selectedTab, setSelectedTab] = useState(tabs[0].id)
	const [startTime, setStartTime] = useState<Date>(new Date())
	const [tabTimes, setTabTimes] = useState<Record<string, number>>({})

	const recordTime = async () => {
		const endTime = new Date()
		const elapsed = (endTime.getTime() - startTime.getTime()) / 1000
		setTabTimes(prevTimes => ({
			...prevTimes,
			[selectedTab]: (prevTimes[selectedTab] || 0) + elapsed
		}))
		setStartTime(new Date())

		// Save the time to the database
		const data: ISharedLinkView = {
			id: await convertStringToNanoID(
				linkData.id + userData.email + selectedTab
			),
			name: selectedTab,
			time: tabTimes[selectedTab] || 0 + elapsed,
			user: { name: userData.name || '', email: userData.email },
			startTime: startTime.toISOString(),
			endTime: endTime.toISOString(),
			linkData: {
				id: linkData.id,
				access: linkData.shareOption,
				url: location.pathname
			},
			company: {
				name: company.name,
				id: company.id,
				website: company.website || ''
			},
			fund: company.fund,
			createdAt: new Date().toISOString()
		}
		SharedLinksService.logSharedLinkView(data)
	}

	const handleTabChange = (tabId: string) => {
		if (tabId !== selectedTab) {
			recordTime()
			setSelectedTab(tabId)
		}
	}

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === 'hidden') {
				recordTime()
			} else {
				setStartTime(new Date()) // Restart timing when the tab gains focus again
			}
		}

		const handleBeforeUnload = (e: BeforeUnloadEvent) => {
			recordTime()
			e.preventDefault()
			// e.returnValue = '' // This will ask the user to confirm leaving the page
		}

		document.addEventListener('visibilitychange', handleVisibilityChange)
		window.addEventListener('beforeunload', handleBeforeUnload)

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange)
			window.removeEventListener('beforeunload', handleBeforeUnload)
			recordTime() // Record the final time on component unmount
		}
	}, [selectedTab])

	const filteredTabs = tabs.filter(
		tab =>
			tab.includeOptionLabel === 'all' ||
			linkData.includeOptions.includes(tab.includeOptionLabel)
	)

	return (
		<div>
			<div className='flex h-full rounded-md lg:w-[35vw] '>
				<div className='h-full flex-1 overflow-auto rounded-t-md border bg-white'>
					{children?.(selectedTab)}
				</div>
				<div className='flex flex-col'>
					{filteredTabs.map(item => (
						<div
							data-testid={item.id}
							key={item.id}
							className={clsx(
								'group flex cursor-pointer flex-col items-center justify-center gap-2 px-4 py-2 font-semibold',
								item.disabled && 'opacity-40'
							)}
							onClick={() => {
								if (item.disabled) {
									toast('This feature is not available yet')
								} else {
									handleTabChange(item.id)
								}
							}}
						>
							<div
								className={clsx(
									'flex h-10 w-10 items-center justify-center rounded-md border',
									selectedTab === item.id
										? 'border-blue-500 bg-blue-500 text-white'
										: 'border-gray-200'
								)}
							>
								{getSidebarIcon(item.id)}
							</div>
							<div className='text-center text-xs'>{item.label}</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
