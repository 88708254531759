/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from 'components/Buttons/Button'
import { Input } from 'components/ui/input'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import useCreateTag from '../hooks/useCreateTag'

export default function AddNewTag() {
	const [showCreateNew, setShowCreateNew] = useState(false)

	const { formik } = useCreateTag({ onSuccess: () => setShowCreateNew(false) })

	if (!showCreateNew) {
		return (
			<Button
				id='open-create-tag-button'
				variant='ghost'
				className='rounded-md bg-blue-100 px-2 py-2 text-blue-700'
				onClick={() => {
					setShowCreateNew(true)
				}}
			>
				<Plus size={15} />
				<div>Create New</div>
			</Button>
		)
	}

	return (
		<div>
			<div className='flex flex-col gap-2 rounded-md border bg-gray-50 p-2'>
				<Input
					error={formik.errors.category}
					id='category'
					name='tag-category'
					className='bg-white'
					value={formik.values.category}
					label='Tag category'
					onChange={async e => formik.setFieldValue('category', e.target.value)}
				/>
				<Input
					error={formik.errors.name}
					id='name'
					name='tag-name'
					className='bg-white'
					value={formik.values.name}
					label='Tag Name'
					onChange={async e => formik.setFieldValue('name', e.target.value)}
				/>
				<div className='mt-5 flex w-full justify-end gap-5'>
					<Button
						variant='ghost'
						className='px-2 py-1 text-red-500'
						onClick={() => setShowCreateNew(false)}
					>
						Cancel
					</Button>
					<Button
						id='create-tag-button'
						isLoading={formik.isSubmitting}
						disabled={formik.isSubmitting || !formik.isValid}
						onClick={() => formik.handleSubmit()}
					>
						Create
					</Button>
				</div>
			</div>
		</div>
	)
}
