import { Check, CircleHelp } from 'lucide-react'

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { UserRole } from 'interfaces'
import { mapRoleToName } from 'utils/roles'

interface Props {
	roleName: UserRole
	setRole: (role: string) => void
	isSelected: boolean
}

export default function RoleSelector({ roleName, setRole, isSelected }: Props) {
	return (
		<div
			className='flex flex-1 cursor-pointer items-center justify-between space-x-4 rounded border border-primary p-2'
			onClick={() => setRole(roleName)}
		>
			<Check
				className={isSelected ? 'text-green-500' : 'text-white'}
				size={18}
			/>
			{mapRoleToName(roleName)}
			<CircleHelp size={18} className='text-gray-300' />
		</div>
	)
}
