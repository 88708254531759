/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import {
	collection,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	setDoc,
	where
} from 'firebase/firestore'

import Errorhandler from 'lib/sentry'
import type { ISharedLinkCompanyComment } from '../interfaces/sharedlink.interface'
import type { ISharedLinkView } from '../interfaces/shared_link_view.interace'
import Logger from 'lib/logger'
import { firestore } from 'lib/firebase'

/* eslint-disable @typescript-eslint/no-extraneous-class */
export class SharedLinksService {
	private static readonly ref = collection(firestore, 'shared_link_comments')

	public static sharedLinkLogsRef = collection(firestore, 'shared_link_logs')

	// Add shared link company comment
	public static async addSharedLinkCompanyComment(
		comment: ISharedLinkCompanyComment
	) {
		const docRef = doc(this.ref, comment.id)
		await setDoc(docRef, comment, { merge: true })
		return comment
}

	// Update shared link company comment
	public static async updateSharedLinkCompanyComment(
		comment: ISharedLinkCompanyComment
	) {
		const docRef = doc(this.ref, comment.id)
		await setDoc(docRef, comment, { merge: true })
		return comment
	}

	// Get shared link company comment
	public static async getSharedLinkCompanyComment(id: string) {
		const docRef = doc(this.ref, id)
		const docSnap = await getDoc(docRef)
		if (docSnap.exists()) {
			return docSnap.data() as ISharedLinkCompanyComment
		}
		return null
	}

	// Get all shared link company comments
	public static async getAllSharedLinkCompanyComments(linkId: string) {
		const q = query(this.ref, where('linkData.id', '==', linkId))
		const querySnapshot = await getDocs(q)
		return querySnapshot.docs.map(d => d.data() as ISharedLinkCompanyComment)
	}

	// Listen to shared link company comments
	public static listenToSharedLinkCompanyComments(
		companyId: string,
		callback: (comments: ISharedLinkCompanyComment[]) => void,
		loadingCallback: (loading: boolean) => void
	) {
		loadingCallback(true)
		const q = query(this.ref, where('company.id', '==', companyId))
		return onSnapshot(q, querySnapshot => {
			const comments = querySnapshot.docs.map(
				d => d.data() as ISharedLinkCompanyComment
			)
			callback(comments)
			loadingCallback(false)
		})
	}

	// Listen to comments by email
	public static listenToSharedLinkCompanyCommentsByEmail(
		email: string,
		callback: (comments: ISharedLinkCompanyComment[]) => void,
		loadingCallback: (loading: boolean) => void
	) {

		loadingCallback(true)
		const q = query(this.ref, where('user.email', '==', email))
		return onSnapshot(q, querySnapshot => {
			const comments = querySnapshot.docs.map(
				d => d.data() as ISharedLinkCompanyComment
			)
			callback(comments)
			loadingCallback(false)
		})
	}

	// Listen to comments by company id
	public static listenToSharedLinkCompanyCommentsByCompanyId(
		companyId: string,
		callback: (comments: ISharedLinkCompanyComment[]) => void,
		loadingCallback: (loading: boolean) => void
	) {
		loadingCallback(true)
		const q = query(this.ref, where('company.id', '==', companyId))
		return onSnapshot(q, querySnapshot => {
			const comments = querySnapshot.docs.map(
				d => d.data() as ISharedLinkCompanyComment
			)
			callback(comments)
			loadingCallback(false)
		})
	}

	// Log an activity
	// Listens to shared link views
	public static listenToSharedLinkViews(
		companyId: string,
		callback: (activities: ISharedLinkView[]) => void,
		loadingCallback: (loading: boolean) => void
	) {
		loadingCallback(true)
		const q = query(
			this.sharedLinkLogsRef,
			where('company.id', '==', companyId)
		)
		return onSnapshot(
			q,
			querySnapshot => {
				const activities = querySnapshot.docs.map(
					d => d.data() as ISharedLinkView
				)
				callback(activities)
				loadingCallback(false)
			},
			error => {
				Logger.error('Error listening to shared link views', error)
				Errorhandler.captureException(error)
			}
		)
	}

	// Add shared link view
	public static async logSharedLinkView(activity: ISharedLinkView) {
		try {
			const docRef = doc(this.sharedLinkLogsRef, activity.id)
			await setDoc(docRef, activity, { merge: true })
		} catch (error: any) {
			Logger.error('Error logging shared link view', error)
			Errorhandler.captureException(error)
		}
	}
}
