/* eslint-disable @typescript-eslint/max-params */
import { auth, firestore } from 'lib/firebase'
import { doc, setDoc } from 'firebase/firestore'
import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import type { FundData } from 'interfaces'
import Logger from 'lib/logger'
import { nanoid } from 'nanoid'

const saveTimeToFirestore = async (
	page: string,
	timeSpent: number,
	parent?: string,
	fundData?: FundData
) => {
	try {
		// If timeSpent is less than 1 second, don't save it
		if (timeSpent < 1) return
		if (!fundData) return

		const id = nanoid()
		await setDoc(doc(firestore, 'page_tracking', id), {
			id,
			page,
			user: {
				id: auth.currentUser?.uid || '',
				name: auth.currentUser?.displayName || ''
			},
			fund: fundData,
			parent: parent || '',
			timeSpent,
			timestamp: new Date().toISOString()
		})
		// Logger.info(`Time spent on ${page} saved: ${timeSpent} seconds`)
	} catch (error) {
		Logger.error('Error saving time spent to Firestore:', error)
	}
}

interface UsePageTimeTrackingProps {
	pageName: string
	parent?: string
}

const usePageTimeTracking = ({
	pageName,
	parent
}: UsePageTimeTrackingProps): void => {
	const { authUser } = useContext(AuthContext)
	const startTime = Date.now()

	useEffect(() => {
		const handleBeforeUnload = () => {
			if (startTime) {
				const endTime = Date.now()
				const timeSpent = (endTime - startTime) / 1000 // Time spent in seconds
				saveTimeToFirestore(pageName, timeSpent, parent, authUser?.fund)
			}
		}

		// Add the event listener for beforeunload
		window.addEventListener('beforeunload', handleBeforeUnload)

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)

			if (startTime) {
				const endTime = Date.now()
				const timeSpent = (endTime - startTime) / 1000 // Time spent in seconds
				saveTimeToFirestore(pageName, timeSpent, parent, authUser?.fund)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageName, parent, authUser])
}

export default usePageTimeTracking
