/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/button-has-type */
import { Link, Navigate, useLocation } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { ReactTyped } from 'react-typed'
import { loginWithGoogle } from '../authentication.api'
import { toast } from 'sonner'

export default function AuthPage() {
	const location = useLocation()

	const signupClicked = localStorage.getItem('signupClicked')

	// Redirect to dashboard if user is already logged in
	const { authUser, authError, setAuthError, authLoading } =
		useContext(AuthContext)

	const [processingAuth, setProcessingAuth] = useState(false)

	const onLoginWithGoogle = async (): Promise<void> => {
		try {
			setProcessingAuth(true)
			localStorage.setItem('loginClicked', 'yes')
			await loginWithGoogle()
		} catch (error: any) {
			toast.error(error.message ?? 'An error occured')
			localStorage.removeItem('loginClicked')
		} finally {
			setProcessingAuth(false)
		}
	}

	const onSignUpWithGoogle = async (): Promise<void> => {
		try {
			setProcessingAuth(true)
			localStorage.setItem('signupClicked', 'yes')
			await loginWithGoogle()
		} catch (error: any) {
			toast.error(error.message ?? 'An error occured')
			localStorage.removeItem('signupClicked')
		} finally {
			setProcessingAuth(false)
		}
	}

	useEffect(() => {
		if (!authError) return

		toast.dismiss()
		toast.error(authError)

		setTimeout(() => {
			setAuthError(null)
		}, 1000)
	}, [authError])

	if (!authLoading && authUser && !signupClicked) {
		return <Navigate to='/dashboard' state={{ from: location }} replace />
	}

	return (
		<div className='flex h-screen flex-col'>
			{/* Header */}
			<div className='bg-primary p-2 text-center text-lg text-white'>
				spok.vc
			</div>
			<div className='flex flex-1'>
				{/* Left Side - Blue Background */}
				<div className='flex w-1/2 flex-col items-start justify-center bg-primary p-12'>
					<h1 className='mb-8 text-7xl text-white'>
						Hello Spok, prepare summary for today
					</h1>
					<ul className='list-disc space-y-4 pl-4 text-lg text-white'>
						<li>
							<ReactTyped
								strings={['Alex noted 3 different companies']}
								typeSpeed={40}
								backSpeed={50}
								showCursor={false}
							/>
						</li>
						<li>
							<ReactTyped
								strings={['You received 7 new comments']}
								typeSpeed={40}
								backSpeed={50}
								startDelay={2000}
								showCursor={false}
							/>
						</li>
						<li>
							<ReactTyped
								strings={['Michael created 1 new task for you']}
								typeSpeed={40}
								backSpeed={50}
								startDelay={4000}
								showCursor={false}
							/>
						</li>
						<li>
							<ReactTyped
								strings={['Julia added new document to']}
								typeSpeed={40}
								backSpeed={50}
								startDelay={6000}
							/>
						</li>
					</ul>
				</div>

				{/* Right Side - White Background with Login/Sign Up */}
				<div className='flex w-1/2 flex-col items-center justify-center bg-white'>
					<div className='flex h-full w-1/2 flex-1 items-center justify-center'>
						<div className='flex h-min w-full flex-col items-center justify-center rounded-xl border p-8'>
							<h2 className='mb-6 text-3xl font-semibold'>Get Started</h2>
							<Button
								onClick={onLoginWithGoogle}
								className='mb-4 w-full rounded px-4 py-2 text-lg font-bold text-white'
								isLoading={authLoading || processingAuth}
							>
								Login
							</Button>
							<Button
								variant='outline'
								onClick={onSignUpWithGoogle}
								isLoading={authLoading || processingAuth}
								className='w-full rounded border  px-4 py-2 text-lg font-bold text-primary'
							>
								Sign up
							</Button>
						</div>
					</div>

					<p className='text-muted-foreground mb-8 mt-auto flex gap-4 text-center text-sm'>
						<Link
							to='/terms-and-conditions'
							className='text-primary underline underline-offset-4 hover:text-primary'
						>
							Terms of Service
						</Link>{' '}
						<Link
							to='/privacy-policy'
							className='text-primary underline underline-offset-4 hover:text-primary'
						>
							Privacy Policy
						</Link>
						.
					</p>
				</div>
			</div>
		</div>
	)
}
