export const SPACES_COLORS = [
  { name: "Black", value: "black" },
  { name: "Red", value: "red" },
  { name: "Blue", value: "blue" },
  { name: "Green", value: "green" },
  { name: "Yellow", value: "yellow" },
  { name: "Purple", value: "purple" },
  { name: "Pink", value: "pink" },
  { name: "Gray", value: "gray" },
  { name: "Orange", value: "orange" },
  { name: "Teal", value: "teal" },
  { name: "Indigo", value: "indigo" },
  { name: "Cyan", value: "cyan" },
  { name: "Fuchsia", value: "fuchsia" },
  { name: "Olive", value: "olive" },
  { name: "Gold", value: "gold" },
  { name: "Silver", value: "silver" },
]