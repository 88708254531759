import { useAppSelector } from 'app/hooks';
import { ComboSelect } from 'components/shadcn/ComboSelect';
import { selectAuthUserFund, selectCurrencyOptions } from 'features/authentication/authentication.slice';
import React from 'react';
import { CurrencyExchangeService } from '../service/currency_exchange.service';

const ExchangerUI: React.FC = () => {
  const fund = useAppSelector(selectAuthUserFund)
  const currencyOptions = useAppSelector(selectCurrencyOptions);

  if (!fund) return null

  return (
    <div>
      <ComboSelect
        label=''
        onSelect={value => {
          CurrencyExchangeService.updateFundCurrency(value);
        }}
        defaultValue={fund.currency}
        options={currencyOptions}
        content={
          <div className='mr-3 text-base font-semibold text-blue-700'>
            {fund.currency}
          </div>
        }
      />
    </div>
  );
};

export default ExchangerUI;