import type {
	ICapitalCallMonthlySummary,
	ICapitalCallRequestMap,
	ICapitalCallSummary,
	ICapitalCallYearlySummary
} from '../interfaces/capitalCall.interface'

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { CapitalCallRequest } from 'features/new-capital-call/interfaces/CapitalCallRequest'
import { nanoid } from 'nanoid'

export function capitalCallRequestsMapper(
	capitalCalls: CapitalCallRequest[],
	excludesYears?: number[]
): ICapitalCallRequestMap {
	const summary: ICapitalCallRequestMap = {}

	for (const capitalCall of Object.values(capitalCalls)) {
		const year = new Date(capitalCall.createdAt).getFullYear()
		const month = new Date(capitalCall.createdAt).getMonth() + 1

		if (!excludesYears?.includes(year)) {
			if (!summary[year]) summary[year] = {}

			if (summary[year][month] && summary[year][month].length > 0) {
				summary[year][month].push(capitalCall)
			} else {
				summary[year][month] = []
				summary[year][month].push(capitalCall)
			}
		}
	}

	return summary
}

export function capitalCallRequestDataToFilterMapper(
	data: ICapitalCallRequestMap,
	merge?: Record<number, { isChecked: boolean; total: number }>
): Record<number, { isChecked: boolean; total: number }> {
	const res: Record<number, { isChecked: boolean; total: number }> = {}

	for (const key of Object.keys(data)) {
		res[Number(key)] = {
			isChecked: merge?.[Number(key)] ? merge[Number(key)].isChecked : true,
			total: Object.values(data[Number(key)]).reduce(
				(total, array) => total + array.length,
				0
			)
		}
	}

	return res
}

export function getCapitalCallSummary(
	map: ICapitalCallRequestMap
): ICapitalCallSummary {
	const data: ICapitalCallSummary = {
		id: nanoid(),
		percentRemaining: 0,
		total: [],
		totalCapitalCalls: 0
	}

	let total = 0
	let totalPending = 0
	let totalCapitalCall = 0

	for (const [, year] of Object.entries(map)) {
		for (const [, monthly] of Object.entries(year)) {
			for (const [, value] of Object.entries(monthly)) {
				if (
					(value.isApproved !== undefined && value.isApproved) ||
					value.isApproved === undefined
				)
					total += value?.amount?.amount || 0

				if (value.isApproved === undefined)
					totalPending += value?.amount?.amount || 0

				totalCapitalCall += 1
			}
		}
	}

	data.total = [{ amount: total, currency: 'USD' }]
	data.totalCapitalCalls = totalCapitalCall
	data.percentRemaining = (totalPending / total) * 100

	return data
}

export function getCapitalCallYearlySummary(
	map: ICapitalCallRequestMap
): Record<number, ICapitalCallYearlySummary> {
	const data: Record<number, ICapitalCallYearlySummary> = {}

	for (const [yearKey, year] of Object.entries(map)) {
		let total = 0
		let totalCapitalCall = 0
		let totalPending = 0

		for (const [, monthly] of Object.entries(year)) {
			for (const [, value] of Object.entries(monthly)) {
				if (
					(value.isApproved !== undefined && value.isApproved) ||
					value.isApproved === undefined
				)
					total += value?.amount?.amount || 0

				if (value.isApproved === undefined)
					totalPending += value?.amount?.amount || 0

				totalCapitalCall += 1
			}
		}

		data[Number(yearKey)] = {
			id: nanoid(),
			percentRemaining: (totalPending / total) * 100,
			total: [{ amount: total, currency: 'USD' }],
			totalCapitalCall
		}
	}

	return data
}

export function getCapitalCallMonthlySummary(
	map: ICapitalCallRequestMap
): Record<number, Record<number, ICapitalCallMonthlySummary>> {
	const data: Record<number, Record<number, ICapitalCallMonthlySummary>> = {}

	for (const [yearKey, year] of Object.entries(map)) {
		data[Number(yearKey)] = {}
		for (const [monthlyKey, monthly] of Object.entries(year)) {
			let total = 0
			let totalCapitalCall = 0
			let totalPending = 0

			for (const [, value] of Object.entries(monthly)) {
				if (
					(value.isApproved !== undefined && value.isApproved) ||
					value.isApproved === undefined
				)
					total += value?.amount?.amount || 0

				if (value.isApproved === undefined)
					totalPending += value?.amount?.amount || 0

				totalCapitalCall += 1
			}

			data[Number(yearKey)][Number(monthlyKey)] = {
				id: nanoid(),
				percentRemaining: (totalPending / total) * 100,
				total: [{ amount: total, currency: 'USD' }],
				totalCapitalCall,
				percentCalled: 100 - (totalPending / total) * 100
			}
		}
	}
	return data
}
