/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useContext, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import { INVITE_ROLE_PERMISSIONS } from '../data/invitations.data'
import { ImSpinner10 } from 'react-icons/im'
import { Input } from 'components/ui/input'
import { UserRoundPlus } from 'lucide-react'
import clsx from 'clsx'
import useCreateInvitation from '../hooks/useCreateInvitation'

function RoleBox({
	role,
	onSelect,
	isSelected
}: {
	role: (typeof INVITE_ROLE_PERMISSIONS)[0]
	onSelect: (role: (typeof INVITE_ROLE_PERMISSIONS)[0]) => void
	isSelected: boolean
}) {
	return (
		<div
			data-testid={`role-box-${role.role}`}
			className={clsx(
				'flex flex-1 cursor-pointer flex-col gap-2 rounded-md border p-2',
				{ 'border-blue-500': isSelected }
			)}
			onClick={() => {
				onSelect(role)
			}}
		>
			<div>{role.name}</div>
			<div className='text-sm text-gray-500'>{role.subTitle}</div>
			<ul className='w-full list-disc px-5'>
				{role.permissions.map(permission => (
					<li key={permission}>{permission}</li>
				))}
			</ul>
		</div>
	)
}

export default function InviteNewUser(): JSX.Element {
	const { authUser } = useContext(AuthContext)
	const [isOpen, setIsOpen] = useState(false)
	const { formik } = useCreateInvitation({
		partner: authUser!,
		onSuccess: () => {
			setIsOpen(false)
		}
	})

	function onCloseModal(): void {
		setIsOpen(false)
	}

	function onOpenModal(): void {
		setIsOpen(true)
	}

	return (
		<DialogModal
			size='3xl'
			open={isOpen}
			setOpen={setIsOpen}
			title='Invite User'
			description=''
			trigger={
				<Button
					id='open-invite-partner-button'
					variant='blue'
					onClick={onOpenModal}
				>
					<UserRoundPlus size={15} />
					Invite partner
				</Button>
			}
		>
			<div>
				<div id='manualFields'>
					<div className='mb-4 w-full'>
						<Input
							id='name'
							label='Name'
							type='text'
							isRequired
							value={formik.values.name}
							error={formik.errors.name}
							onInput={e => {
								formik.setFieldValue(
									'name',
									(e.target as HTMLInputElement).value
								)
							}}
						/>
					</div>
					<div className='mb-4 w-full'>
						<Input
							id='email'
							type='email'
							label='Email'
							isRequired
							value={formik.values.email}
							error={formik.errors.email}
							onInput={e => {
								formik.setFieldValue(
									'email',
									(e.target as HTMLInputElement).value
								)
							}}
						/>
					</div>
				</div>

				<div>
					Role
					<span className='text-red-500'>*</span>
				</div>
				<div className='mt-2 flex gap-5'>
					{INVITE_ROLE_PERMISSIONS.map(role => (
						<RoleBox
							key={role.name}
							role={role}
							isSelected={formik.values.role === role.role}
							onSelect={value => {
								formik.setFieldValue('role', value.role)
							}}
						/>
					))}
				</div>
				<div className='mt-3 flex w-full justify-end'>
					{formik.isSubmitting ? (
						<ImSpinner10 className='animate-spin' />
					) : (
						<div className='flex items-center '>
							<Button variant='ghost' className='px-10' onClick={onCloseModal}>
								Cancel
							</Button>
							<Button
								id='invite-partner-button'
								variant='blue'
								disabled={!formik.isValid}
								className='px-10'
								onClick={() => {
									formik.handleSubmit()
								}}
							>
								Next
							</Button>
						</div>
					)}
				</div>
			</div>
		</DialogModal>
	)
}
