import { Cell, Pie, PieChart, Sector } from 'recharts'
import { useCallback, useState } from 'react'

import type { ILimitedPartnerChart } from '../interfaces/limitedPartner.interface'
import type { SetStateAction } from 'react'
import { currencyFormatter } from 'utils/currencyformatter.util'

interface ILimitedPartnerTotalCommitChartProps {
	data: ILimitedPartnerChart[]
}

const renderActiveShape = (props: any) => {
	const {
		cx,
		cy,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload
	} = props

	return (
		<g>
			<text
				x={cx}
				y={cy}
				dy={8}
				textAnchor='middle'
				fill='#0088FE'
				fontWeight='500'
			>
				{currencyFormatter('USD').format(payload.value)}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
		</g>
	)
}

export default function LimitedPartnerTotalCommittedChart({
	data
}: ILimitedPartnerTotalCommitChartProps): JSX.Element {
	const [activeIndex, setActiveIndex] = useState(0)
	const onPieEnter = useCallback(
		(_: unknown, index: SetStateAction<number>) => {
			setActiveIndex(index)
		},
		[setActiveIndex]
	)

	return (
		<PieChart width={300} height={260}>
			<Pie
				activeIndex={activeIndex}
				activeShape={renderActiveShape}
				data={data}
				cx='50%'
				cy='50%'
				innerRadius={100}
				outerRadius={120}
				fill='#8884d8'
				dataKey='value'
				onMouseEnter={onPieEnter}
				scale={2}
			>
				{data.map((entry, index) => (
					<Cell key={entry.name} fill={entry.color} />
				))}
			</Pie>
		</PieChart>
	)
}
