export const authorizeGmail = () => {
	const GMAIL_SCOPES = [
		'https://www.googleapis.com/auth/gmail.modify',
		'https://www.googleapis.com/auth/gmail.readonly',
		'https://www.googleapis.com/auth/gmail.send',
		'https://www.googleapis.com/auth/contacts.readonly'
	]
	const SCOPE_STRING = GMAIL_SCOPES.join(' ')

	const CLIENT_ID = import.meta.env.VITE_GMAIL_CLIENT_ID
	const REDIRECT_URI = `${
		import.meta.env.VITE_APP_URL
	}/settings?tab=integrations`

	const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE_STRING}&prompt=consent&access_type=offline&include_granted_scopes=true&openid=email`

	window.open(GOOGLE_AUTH_URL, '_self', 'noopener, noreferrer')
}
