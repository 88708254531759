import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	limit,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	where,
	writeBatch
} from 'firebase/firestore'

import Errorhandler from 'lib/sentry'
import type { IBankAccount } from '../interface/BankAccount.interface'
import Logger from 'lib/logger'
import { firestore } from 'lib/firebase'
import { setBankAccounts } from '../redux/bankAccounts.slice'
import { store } from 'app/store'

export const bankAccountsRef = collection(firestore, 'bank_accounts')

export const addBankAccount = async (
	bankAccount: IBankAccount
): Promise<IBankAccount> => {
	const docRef = doc(bankAccountsRef, bankAccount.id)

	await setDoc(docRef, bankAccount, { merge: true })
	return bankAccount
}

export const hasDefaultBankAccount = async (): Promise<boolean> => {
	const q = query(bankAccountsRef, where('isDefault', '==', true))
	const docs = await getDocs(q)

	return docs.size > 0
}

export const getFirstBankAccount = async (): Promise<IBankAccount | null> => {
	const q = query(bankAccountsRef, limit(1))

	const querySnapshot = await getDocs(q)

	const data = querySnapshot.docs.map(x => x.data() as IBankAccount)

	return data.length > 0 ? data[0] : null
}

export const updateBankAccount = async (
	bankAccount: Partial<IBankAccount>
): Promise<Partial<IBankAccount>> => {
	const docRef = doc(bankAccountsRef, bankAccount.id)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	await updateDoc(docRef, bankAccount as any, { merge: true })
	return bankAccount
}

export const updateDefaultBankAccount = async (id: string) => {
	const batch = writeBatch(firestore)
	const snapshot = await getDocs(query(bankAccountsRef))

	for (const docData of snapshot.docs) {
		const docRef = doc(bankAccountsRef, docData.id)

		if (id === docData.id) {
			batch.update(docRef, { isDefault: true })
		} else {
			batch.update(docRef, { isDefault: false })
		}
	}

	await batch.commit()
}

export const listenToBankAccounts = (fundId: string) => {
	const q = query(bankAccountsRef, where('fund.id', '==', fundId))

	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const bankAccount = querySnapshot.docs.map(d => d.data() as IBankAccount)
			store.dispatch(setBankAccounts(bankAccount))
		},
		error => {
			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}

export const deleteBankAccount = async (id: string) => {
	const docRef = doc(bankAccountsRef, id)
	await deleteDoc(docRef)
}
