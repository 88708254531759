/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import { getPrivateSharedLink } from '../api/sharedLinks.api'
import type { ISharedLink } from '../interfaces/sharedLink.interface'

export function getCompanyShareLink(id: string) {
	return `${import.meta.env.VITE_APP_URL}/shared/${id}`
}

export async function userHasAccessToLink(
	linkData: ISharedLink | null | undefined,
	email: string
) {
	if (linkData?.access === 'public' && email) return true

	const data = await getPrivateSharedLink(linkData?.id || '')
	if (!data) return false

	return data.accessEmails.includes(email)
}
