/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */

import type { IDraftNote } from '../interfaces/notes.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface DefaultNotesSliceState {
	data: IDraftNote[]
	isLoading: boolean
	error?: string | null
}

const initialState: DefaultNotesSliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const draftNotesSlice = createAppSlice({
	name: 'daraftNotes',
	initialState,
	reducers: create => ({
		setDraftNotes: create.reducer(
			(state, action: PayloadAction<IDraftNote[]>) => {
				state.data = action.payload
			}
		),
		addDraftNote: create.reducer((state, action: PayloadAction<IDraftNote>) => {
			state.data.push(action.payload)
		}),
		deleteDraftNoteById: create.reducer(
			(state, action: PayloadAction<string>) => {
				const index = state.data.findIndex(note => note.id === action.payload)
				if (index !== -1) {
					const newDraftNotes = [...state.data]
					newDraftNotes.splice(index, 1)

					state.data = newDraftNotes
				}
			}
		)
	}),
	selectors: {
		selectDraftNotes: note => note.data
	}
})

export const { setDraftNotes, addDraftNote, deleteDraftNoteById } =
	draftNotesSlice.actions

export const { selectDraftNotes } = draftNotesSlice.selectors
