export default function ThreadEmailCounter({ length }: { length: number }) {

  return (
    <div
      className={`flex font-bold bg-blue-500 p-1 h-4 items-center justify-center rounded-full text-xs text-white ${length > 1
        ? 'opacity-100'
        : 'opacity-0'
        }`}
    >
      {length}

    </div>
  )
}