export const currencyFormatter = (
  currency = 'USD',
  compact?: 'long' | 'short'
) => {
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
    compactDisplay: compact,
    minimumFractionDigits: 0,
    currencyDisplay: 'symbol',
  };

  return new Intl.NumberFormat('en-UK', options);
};

export function formatCurrency(value: number, currencyCode = 'usd'): string {
  let symbol: string;

  switch (currencyCode.toLowerCase()) {
    case 'usd': {
      symbol = '$ ';
      break;
    }
    case 'nzd': {
      symbol = 'NZ$ ';
      break;
    }
    case 'aud': {
      symbol = 'AU$ ';
      break;
    }
    // Add more currencies as needed
    default: {
      symbol = '';
      break;
    }
  }

  return new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    currencyDisplay: 'symbol',
  })
    .format(value)
    .replace(currencyCode, symbol);
}
