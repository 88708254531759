import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import Autocomplete from '@mui/material/Autocomplete'
import CompanyHoverCard from './CompanyHoverCard'
import type { ICompany } from 'interfaces'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import clsx from 'clsx'
import { listenToCompanies } from 'features/companies/api/companies.api'
import { selectCompanies } from 'features/companies/redux/companies.slice'
import { useAppSelector } from 'app/hooks'

interface Props {
	onSelect: (value?: Partial<ICompany>) => void
	label?: string
	defaultValue?: Partial<ICompany>
	className?: string
	type?: string
	isRequired?: boolean
	variant?: 'interactive' | 'static'
	disabled?: boolean
	trigger?: React.ReactNode
}

export default function CompanySelectorOld({
	onSelect,
	label,
	defaultValue,
	className,
	isRequired,
	type,
	disabled,
	variant = 'interactive',
	trigger
}: Props) {
	const { authUser } = useContext(AuthContext)
	const companies = [...useAppSelector(selectCompanies)]

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubscribe = listenToCompanies(authUser.fund.id)
		return () => unsubscribe()
	}, [authUser?.fund.id])

	function renderItem() {
		if (!defaultValue) {
			return trigger || <div className='px-2'>Select Company</div>
		}
		if (variant === 'interactive') {
			return <CompanyHoverCard isReadOnly={disabled} company={defaultValue} />
		}

		return <div className='px-2'>{defaultValue.name}</div>
	}


	// Use default html selector

	return (
		<div>
			{/* <label htmlFor={type} className='mb-2 block text-xs font-semibold'>
				{label}
				{isRequired && <span className='text-red-500'>*</span>}
			</label> */}
			<div className={clsx(`${className} w-fit`)}>
				{/* <select
					id={type}
					className='w-full p-2  rounded border-gray-200'
					onChange={e => {
						const company = companies.find(
							_company => _company.id === e.target.value
						)
						if (!company) return
						const companyData: CompanyData = {
							id: company.id,
							name: company.name,
							website: company.website
						}
						onSelect(companyData as ICompany)
					}}
				>
					<option value=''>Select Company</option>
					{companies.map(company => (
						<option
							key={company.id}
							value={company.id}
							selected={company.id === defaultValue?.id}
						>
							{company.name}
						</option>
					))}
				</select> */}

				<Stack spacing={2} sx={{ width: 300 }}>
					<Autocomplete
						id="grouped-companies"
						options={companies.sort((a, b) => -b.name.localeCompare(a.name))}
						groupBy={(option) => option.name?.[0] || ''}
						getOptionLabel={(option) => option.name || ''}
						value={defaultValue}
						sx={{ width: 300 }}
						onChange={(_, value) => {
							if (!value) return
							onSelect(value)
						}}
						renderInput={(params) =>
							<TextField
								{...params}
								label="Companies"
							/>
						}
					/>
				</Stack>
			</div>
		</div>
	)

}
