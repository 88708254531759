/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */

import {
	AlertCircle,
	Archive,
	File,
	Inbox,
	LoaderCircle,
	Mail,
	MessagesSquare,
	Pencil,
	RefreshCcw,
	Send,
	ShoppingCart,
	Trash2,
	Users2
} from 'lucide-react'
import { AnimatePresence, motion } from 'framer-motion'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { fetchThreads, listenToLabelCounts } from '../api/threads.api'
import { generateNavLink, updateLabelFilter } from '../helpers/email.helper'
import {
	selectSelectedThread,
	selectSelectedThreadWindowState,
	selectThreads,
	selectThreadsState,
	setSelectedThread,
	setSelectedThreadWindowState,
	setThreads
} from '../redux/threads.slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect, useRef, useState } from 'react'

import { AccountSwitcher } from '../components/AccountSwitcher'
import Button from 'components/Buttons/Button'
import { EmailLabel } from '../interfaces/email.interface'
import EmailThreads from './EmailThreads'
import type { IEmailSignature } from 'interfaces/emailSignature.interface'
import LoadingEmails from '../components/LoadingEmails'
import MailAuthorizeButton from '../components/MailAuthorizeButton'
import { Nav } from '../components/Nav'
import NoEmailsFound from '../components/NoEmailsFound'
import { ScrollArea } from 'components/ui/scroll-area'
import SearchEmail from '../components/SearchEmail'
import SelectedEmailThread from './SelectedEmailThread'
import { Separator } from 'components/ui/separator'
import ShowSignatureHint from '../components/ShowSignatureHint'
import { ThreadService } from '../service/thread.service'
import clsx from 'clsx'
import { cn } from 'lib/utils'
import { firestore } from 'lib/firebase'
import { listenToMessages } from '../api/messages.api'
import logger from 'lib/logger'
import { setEmailSignatures } from '../redux/emailSignatures.slice'
import { setMailWindowState } from '../redux/mailcompose.slice'
import { setMessages } from '../redux/emails.slice'
import { store } from 'app/store'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useLocation } from 'react-router-dom'
import { useWindowSize } from '@uidotdev/usehooks'

function Loading() {
	return (
		<div className='flex items-center justify-center space-x-2'>
			<LoaderCircle size={16} className='animate-spin' />
			<p>Loading...</p>
		</div>
	)
}

export default function MailPage() {
	const scrollRef = useRef<HTMLDivElement>(null)
	const size = useWindowSize()

	const location = useLocation()
	const label =
		location.search.split('?label=')[1]?.split('&')[0] || EmailLabel.Inbox

	const dispatch = useAppDispatch()
	const { authUser } = useAppSelector(state => state.authentication)

	const selectedEmailThread = useAppSelector(selectSelectedThread)

	const isCollapsed = (size?.width || 0) < 1500

	const { labelCounts, isLoading } = useAppSelector(selectThreadsState)
	const threads = useAppSelector(state => selectThreads(state, label))
	const threadsLoading = useAppSelector(selectThreadsState).isLoading
	const selectedThreadWindowState = useAppSelector(
		selectSelectedThreadWindowState
	)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [lastDoc, setLastDoc] = useState<any>(null)
	const [loading, setLoading] = useState<boolean>(false)

	const [signatureStatus, setSignatureStatus] = useState({
		isSet: true,
		shouldShowAgain: false
	})

	useEffect(() => {
		const getAllSignatures = () => {
			if (!authUser?.id) return
			if (authUser.googleToken?.refreshToken.length === 0) return
			try {
				const q = query(
					collection(firestore, `email_signatures`),
					where('creator.id', '==', authUser.id)
				)
				const unsubscribe = onSnapshot(q, snapshot => {
					const data = snapshot.docs.map(doc => ({
						...doc.data()
					}))

					dispatch(setEmailSignatures(data as IEmailSignature[]))

					const shouldShowSignature = localStorage.getItem(
						'shouldShowSignature'
					)

					setSignatureStatus({
						shouldShowAgain:
							shouldShowSignature !== 'false' && data.length === 0,
						// isSet: data.length > 0
						isSet: true
					})
				})

				return () => unsubscribe()
			} catch (error) {
				logger.error(error)
			}
		}

		getAllSignatures()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authUser?.id, authUser?.googleToken?.refreshToken])

	useEffect(() => {
		if (!authUser?.id) return

		const unsubscribe = listenToLabelCounts(authUser.id)

		return () => {
			unsubscribe()
		}
	}, [authUser?.id])

	useEffect(() => {
		if (!authUser?.id) return
		;(async () => {
			const result = await fetchThreads({ pageSize: 20, userId: authUser.id })
			dispatch(setThreads(result.data))
			setLastDoc(result.lastDoc)
		})()
	}, [authUser?.id])

	useEffect(() => {
		store.dispatch(setSelectedThread(null))
		store.dispatch(setSelectedThreadWindowState('restore'))
	}, [label])

	useEffect(() => {
		if (!label) return
		updateLabelFilter(label)
	}, [label])

	useEffect(() => {
		if (!selectedEmailThread) {
			store.dispatch(setSelectedThreadWindowState('restore'))
			return
		}

		if (selectedEmailThread.latestEmail) {
			dispatch(setMessages([selectedEmailThread.latestEmail]))
		}
		const unsubscribe = dispatch(
			listenToMessages(selectedEmailThread.id, label === 'draft')
		)

		return () => unsubscribe()
	}, [selectedEmailThread])

	const searchCompanyEmails = () => {
		return threads
	}

	async function fetchData() {
		if (!authUser?.id) return
		setLoading(true)
		const result = await fetchThreads({
			lastDoc,
			pageSize: 10,
			userId: authUser.id
		})
		const allThreads = [...threads, ...result.data]
		dispatch(setThreads(allThreads))
		setLastDoc(result.lastDoc)
		setLoading(false)
	}

	const navLinks = [
		generateNavLink({
			title: 'Inbox',
			label: labelCounts?.inbox?.length || 0,
			icon: Inbox,
			selectedFilter: label,
			labelFilter: EmailLabel.Inbox
		}),
		generateNavLink({
			title: 'Drafts',
			label: labelCounts?.draft?.length || 0,
			icon: File,
			selectedFilter: label,
			labelFilter: EmailLabel.Draft
		}),
		generateNavLink({
			title: 'Sent',
			label: labelCounts?.sent?.length || 0,
			icon: Send,
			selectedFilter: label,
			labelFilter: EmailLabel.Sent
		}),
		generateNavLink({
			title: 'Trash',
			label: labelCounts?.trash?.length || 0,
			icon: Trash2,
			selectedFilter: label,
			labelFilter: EmailLabel.Trash
		}),
		generateNavLink({
			title: 'Archive',
			label: labelCounts?.archive?.length || 0,
			icon: Mail,
			selectedFilter: label,
			labelFilter: EmailLabel.Archive
		}),
		generateNavLink({
			title: 'All Mail',
			label: labelCounts?.allMail?.length || 0,
			icon: Inbox,
			selectedFilter: label,
			labelFilter: EmailLabel.AllMail
		})
	]

	const additionalNavLinks = [
		generateNavLink({
			title: 'Spok Inbox',
			label: labelCounts?.spokInbox?.length || 0,
			icon: Users2,
			selectedFilter: label,
			labelFilter: EmailLabel.SpokInbox
		}),
		generateNavLink({
			title: 'Portfolio',
			label: labelCounts?.portfolio?.length || 0,
			icon: AlertCircle,
			selectedFilter: label,
			labelFilter: EmailLabel.Portfolio
		}),
		generateNavLink({
			title: 'Diligence',
			label: labelCounts?.diligence?.length || 0,
			icon: MessagesSquare,
			selectedFilter: label,
			labelFilter: EmailLabel.Diligence
		}),
		generateNavLink({
			title: 'Limited Partners',
			label: labelCounts?.limitedPartner?.length || 0,
			icon: ShoppingCart,
			selectedFilter: label,
			labelFilter: EmailLabel.LimitedPartner
		}),
		generateNavLink({
			title: 'LP Prospects',
			label: labelCounts?.prospect?.length || 0,
			icon: Archive,
			selectedFilter: label,
			labelFilter: EmailLabel.Prospect
		})
	]

	const [sentryRef] = useInfiniteScroll({
		loading,
		hasNextPage: true,
		onLoadMore: fetchData,
		disabled: false,
		rootMargin: '0px 0px 400px 0px'
	})

	return (
		<AnimatePresence>
			<div className='h-[calc(100vh-3rem)] bg-white'>
				<div className='flex w-full'>
					{/* Left navigation */}
					<div className='h-screen overflow-auto border-r'>
						<div
							className={cn(
								'flex h-[52px] items-center justify-center',
								isCollapsed ? 'h-[52px]' : 'px-2'
							)}
						>
							<AccountSwitcher
								isCollapsed={isCollapsed}
								accounts={[
									{
										label: authUser?.name || '0',
										email: authUser?.email || '0',
										icon: (
											<svg
												role='img'
												viewBox='0 0 24 24'
												xmlns='http://www.w3.org/2000/svg'
											>
												<title>Gmail</title>
												<path
													d='M24 5.457v13.909c0 .904-.732 1.636-1.636 1.636h-3.819V11.73L12 16.64l-6.545-4.91v9.273H1.636A1.636 1.636 0 0 1 0 19.366V5.457c0-2.023 2.309-3.178 3.927-1.964L5.455 4.64 12 9.548l6.545-4.91 1.528-1.145C21.69 2.28 24 3.434 24 5.457z'
													fill='currentColor'
												/>
											</svg>
										)
									}
								]}
							/>
						</div>
						<Separator />
						<Nav
							isCollapsed={isCollapsed}
							links={navLinks}
							selectedFilter={label}
						/>
						<Separator />
						<Nav
							isCollapsed={isCollapsed}
							links={additionalNavLinks}
							selectedFilter={label}
						/>
					</div>

					{/* Middle Section */}
					<motion.div
						className={clsx(
							'flex-1 overflow-auto transition-all',
							selectedThreadWindowState === 'maximize' && 'hidden'
						)}
					>
						<div className='flex '>
							<div className='flex h-full  w-full flex-col  gap-5'>
								<div className='flex h-full flex-col gap-1 '>
									<div className='flex items-center  justify-between gap-3 px-5 pt-5 text-xl font-semibold text-gray-800'>
										<div className='flex w-full items-center gap-3'>
											<div>Mail</div>
											<Button
												onClick={() => {
													store.dispatch(setMailWindowState('restore'))
												}}
												className='text-xs'
											>
												<Pencil size={15} />
												Compose
											</Button>
											{authUser?.googleToken?.refreshToken && (
												<Button
													className='flex items-center gap-2 truncate px-5 text-xs'
													variant='blue'
													onClick={() => ThreadService.syncThreads()}
												>
													<RefreshCcw
														size={15}
														className={threadsLoading ? 'animate-spin' : ''}
													/>
													Sync threads
												</Button>
											)}
											<SearchEmail />
										</div>
									</div>
									<div className='px-3'>
										<ShowSignatureHint />
										<MailAuthorizeButton />
									</div>
									<div className=''>
										<ScrollArea
											ref={scrollRef}
											className={cn(
												'w-full flex-1 px-3 pb-10',
												signatureStatus.shouldShowAgain
													? 'h-[calc(100vh-14rem)]'
													: 'h-[calc(100vh-6rem)]'
											)}
										>
											<EmailThreads
												threads={threads}
												loading={threadsLoading}
											/>

											{(loading || lastDoc) && (
												<div ref={sentryRef}>
													<Loading />
												</div>
											)}
										</ScrollArea>

										{isLoading && threads.length === 0 && <LoadingEmails />}

										{!isLoading && searchCompanyEmails().length === 0 && (
											<NoEmailsFound />
										)}
									</div>
								</div>
							</div>
						</div>
					</motion.div>

					{/* View Email */}
					{selectedEmailThread && (
						<div className='h-screen flex-1 overflow-auto'>
							<motion.div
								initial={{ translateX: 100, opacity: 0 }}
								animate={{ translateX: 0, opacity: 1 }}
								exit={{ translateX: 100, opacity: 0 }}
							>
								<SelectedEmailThread />
							</motion.div>
						</div>
					)}
				</div>
			</div>
		</AnimatePresence>
	)
}
