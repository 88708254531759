import * as Yup from 'yup'

import {
	selectMailComposeState,
	setAction,
	setMailWindowState,
	setThreadId
} from '../redux/mailcompose.slice'
import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import { MessageService } from '../service/message.service'
import logger from 'lib/logger'
import { reply } from '../api/messages.api'
import { store } from 'app/store'
import { toast } from 'sonner'
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppSelector } from 'app/hooks'
import { useFormik } from 'formik'

export default function useEmailCompose() {
	const { authUser } = useContext(AuthContext)
	const mailComposeState = useAppSelector(selectMailComposeState)

	const formik = useFormik({
		initialValues: {
			to: mailComposeState.to,
			cc: mailComposeState.cc,
			bcc: mailComposeState.bcc,
			subject: mailComposeState.subject,
			message: mailComposeState.message,
			windowState: mailComposeState.windowState
		},
		validationSchema: Yup.object({
			to: Yup.array().required('Recipients are required'),
			subject: Yup.string().required('Subject is required')
		}),
		onSubmit: async values => {
			if (!authUser) return
			try {
				formik.setSubmitting(true)

				store.dispatch(setMailWindowState('close'))

				const data = {
					to: values.to,
					cc: values.cc,
					bcc: values.bcc,
					subject: values.subject,
					message: values.message,
					creator: {
						id: authUser.id,
						name: authUser.name,
						photoUrl: authUser.photoUrl ?? ''
					},
					fund: authUser.fund,
					isDraft: false,
					createdAt: new Date().toISOString(),
					lastUpdatedAt: new Date().toISOString()
				}

				if (mailComposeState.action === 'send') {
					await MessageService.send(data)
				}

				if (mailComposeState.action === 'forward') {
					await MessageService.send(data)
					store.dispatch(setAction('send'))
				}

				if (mailComposeState.action === 'reply') {
					const result = await reply({
						...data,
						threadId: mailComposeState.threadId,
						messageId: mailComposeState.messageId
					})
					if (!result.success) throw new Error(result.error)
					store.dispatch(setThreadId(''))
					store.dispatch(setAction('send'))
				}

				formik.resetForm()
			} catch (error: any) {
				logger.error(error)
				toast.dismiss()
				toast.error(error.message ?? 'Failed to send email')

				store.dispatch(setMailWindowState('restore'))
			} finally {
				formik.setSubmitting(false)
			}
		}
	})

	// function resetForm() {
	// 	formik.setFieldValue('to', [])
	// 	formik.setFieldValue('cc', [])
	// 	formik.setFieldValue('bcc', [])
	// 	formik.setFieldValue('subject', '')
	// 	formik.setFieldValue('message', '')
	// }

	// useEffect(() => {
	// 	if (mailComposeState.windowState === 'close') {
	// 		resetForm()
	// 	}
	// }, [mailComposeState.windowState])

	useEffect(() => {
		formik.setFieldValue('to', mailComposeState.to)
		formik.setFieldValue('cc', mailComposeState.cc)
		formik.setFieldValue('bcc', mailComposeState.bcc)
		formik.setFieldValue('subject', mailComposeState.subject)
		formik.setFieldValue('message', mailComposeState.message)
	}, [
		mailComposeState.to,
		mailComposeState.cc,
		mailComposeState.bcc,
		mailComposeState.subject,
		mailComposeState.message
	])

	return {
		formik
	}
}
