import type { User } from 'interfaces'
import type { ITask } from '../interafces/task.interface'
import type { INote } from 'features/notes/interfaces/notes.interface'

export function generateTaskFromNote(
	note: INote,
	authUser: User | null
): Omit<ITask, 'id'> {
	const now = new Date().toISOString()
	const userInfo = {
		id: authUser?.id ?? '',
		name: authUser?.name ?? '',
		photoUrl: authUser?.photoUrl ?? ''
	}

	return {
		description: `${note.title}\n\n${note.note}`,
		company: note.company,
		createdAt: now,
		lastUpdatedAt: now,
		fund: note.fund,
		flagged: false,
		status: 'backlog',
		dueDate: now,
		executor: userInfo,
		creator: userInfo,
		noteId: note.id,
		taskRawPlate: [],
		index: Number.MAX_VALUE
	}
}
