import { httpsCallable } from 'firebase/functions'
import { functions } from 'lib/firebase'

interface ApiResponse {
	success: boolean
	message: string
	error?: string
}

//  Send OTP
interface SendOTPData {
	email: string
}

const snedOTPFunction = httpsCallable<SendOTPData, ApiResponse>(
	functions,
	'sendOTP'
)

export const sendOTP = async (email: string): Promise<ApiResponse> => {
	const result = await snedOTPFunction({ email })
	return result.data
}

// Validate OTP
interface ValidateOTPData {
	email: string
	code: string
}

const validateOTPFunction = httpsCallable<ValidateOTPData, ApiResponse>(
	functions,
	'validateOTP'
)

export const validateOTP = async (
	email: string,
	code: string
): Promise<ApiResponse> => {
	const result = await validateOTPFunction({ email, code })
	return result.data
}
