import type { EmailEntityRelation } from '../interface/email_entity_relation'
import type { IContact } from 'interfaces'
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface ContactsSliceState {
	data: IContact[]
	isLoading: boolean
	error?: string | null
}

const initialState: ContactsSliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const contactsSlice = createAppSlice({
	name: 'contacts',
	initialState,
	reducers: create => ({
		setContacts: create.reducer((state, action: PayloadAction<IContact[]>) => {
			state.data = action.payload
			state.isLoading = false
		}),
		setEmailEntityRelations: create.reducer(
			(state, action: PayloadAction<EmailEntityRelation[]>) => {
				const data = [...state.data].map(contact => {
					const relation = action.payload.find(
						s => s.entityEmail === contact.email
					)
					return {
						...contact,
						connectionStrength: relation?.connectionStrength || 0,
						firstMeeting: relation?.firstMeeting || '',
						lastMeeting: relation?.lastMeeting || '',
						lastEmail: relation?.lastEmail || '',
						firstEmail: relation?.firstEmail || ''
					}
				})

				state.data = data as IContact[]
			}
		)
	}),
	selectors: {
		selectContacts: state => state,
		selectContactCount: state => state.data.length
	}
})

export const { setContacts, setEmailEntityRelations } = contactsSlice.actions

export const { selectContacts, selectContactCount } = contactsSlice.selectors
