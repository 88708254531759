import type {
	EmailLabel,
	IEmailThread,
	IGmailSummary
} from '../interfaces/email.interface'

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { createAppSlice } from 'app/createAppSlice'

export interface ThreadsSliceState {
	data: IEmailThread[]
	isLoading: boolean
	error?: string | null
	labelCounts: Record<EmailLabel, string[]> | null
	selectedThread: IEmailThread | null
	selectedThreadWindowState: 'maximize' | 'restore'
}

const initialState: ThreadsSliceState = {
	data: [],
	error: null,
	isLoading: false,
	labelCounts: null,
	selectedThread: null,
	selectedThreadWindowState: 'restore'
}

export const threadsSlice = createAppSlice({
	name: 'threads',
	initialState,
	reducers: create => ({
		setThreadsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setThreadsError: create.reducer(
			(state, action: PayloadAction<string | null>) => {
				state.error = action.payload
				state.isLoading = false
			}
		),
		setThreads: create.reducer(
			(state, action: PayloadAction<IEmailThread[]>) => {
				state.data = action.payload
				state.isLoading = false
			}
		),
		addThreads: create.reducer(
			(state, action: PayloadAction<IEmailThread[]>) => {
				const oldThreads = state.data
				const newThreads = action.payload

				const data = _.uniqBy([...newThreads, ...oldThreads], 'id')

				state.data = data
				state.isLoading = false
			}
		),
		setUpdatedThread: create.reducer(
			(state, action: PayloadAction<Partial<IEmailThread>>) => {
				const thread = state.data.find(t => t.id === action.payload.id)

				if (!thread) return

				// Replace the thread with the updated thread
				const updatedThreads = state.data.map(t =>
					t.id === thread.id ? { ...t, ...action.payload } : t
				)

				state.data = updatedThreads
			}
		),
		setLabelCounts: create.reducer(
			(state, action: PayloadAction<IGmailSummary>) => {
				state.labelCounts = action.payload.emailThreads
			}
		),
		setSelectedThread: create.reducer(
			(state, action: PayloadAction<IEmailThread | null>) => {
				state.selectedThread = action.payload
			}
		),
		setSelectedThreadWindowState: create.reducer(
			(state, action: PayloadAction<'maximize' | 'restore'>) => {
				state.selectedThreadWindowState = action.payload
			}
		)
	}),
	selectors: {
		selectThreadsState: state => state,
		selectSelectedThread: state => state.selectedThread,
		selectSelectedThreadWindowState: state => state.selectedThreadWindowState,
		selectAllThreads: state => state.data,
		selectThreads: (threads, label) => {
			if (!threads.labelCounts) return []

			const threadIds = threads.labelCounts[label as EmailLabel]

			if (!threadIds || threadIds.length === 0) return []

			return threads.data
				.filter(thread => threadIds.includes(thread.id))
				.sort(a => {
					return a.isPinned ? -1 : 1
				})
		},
		selectLabelCounts: state => state.labelCounts,
		selectEntityThreads: (state, founderEmails) => {
			if (!state.labelCounts)
				return { inboxThreads: [], scheduledCallThreads: [] }

			const threads = state.data
				.filter(item => item.emailAccounts?.some(e => founderEmails.includes(e)))
				.sort(a => {
					return a.isPinned ? -1 : 1
				})

			const inbox = threads.filter(thread => !thread.hasScheduledCall)
			const scheduledCalls = threads.filter(thread => thread.hasScheduledCall)

			return {
				inboxThreads: inbox,
				scheduledCallThreads: scheduledCalls
			}
		},
		selectIsThreadsLoading: state => state.isLoading
	}
})

export const {
	setThreads,
	addThreads,
	setLabelCounts,
	setThreadsLoading,
	setSelectedThread,
	setSelectedThreadWindowState,
	setThreadsError,
	setUpdatedThread
} = threadsSlice.actions

export const {
	selectThreads,
	selectAllThreads,
	selectIsThreadsLoading,
	selectThreadsState,
	selectLabelCounts,
	selectSelectedThread,
	selectSelectedThreadWindowState,
	selectEntityThreads,
} = threadsSlice.selectors
