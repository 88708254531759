/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { AuthContext } from 'context/AuthContext'
import { useFormik } from 'formik'
import { nanoid } from 'nanoid'
import { useContext } from 'react'
import { toast } from 'sonner'
import * as Yup from 'yup'
import {
	addCryptoAccount,
	hasDefaultCryptoAccount,
	updateCryptoAccount
} from '../api/crypto-accounts.api'
import type { ICryptoAccount } from '../interface/cryptoAccount.interface'

interface IProps {
	initialValue: Partial<ICryptoAccount>
	editMode?: boolean
	onSuccess?: () => void
}

export function useCreateCryptoAccount({
	initialValue,
	editMode = false,
	onSuccess
}: IProps) {
	const {authUser} = useContext(AuthContext)
	
	const validationSchema = Yup.object({
		accountName: Yup.string().required('Account Name is required'),
		description: Yup.string(),
		walletAddress: Yup.string().required('Wallet Address is required'),
		network: Yup.string().required('Network is required'),
		stableCoin: Yup.string().required('Stable Coin is required')
	})

	const initialValues = {
		accountName: initialValue?.accountName || '',
		description: initialValue?.description || '',
		walletAddress: initialValue?.walletAddress || '',
		network: 'ERC-20',
		stableCoin: 'USDC'
	}

	const formik = useFormik({
		initialValues,
		validationSchema,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onSubmit: async (value: any) => {
			try {
				if (!authUser) return

				formik.setSubmitting(true)

				const hasDefaultAccount = await hasDefaultCryptoAccount()

				const bankAccount: ICryptoAccount = {
					id: initialValue?.id || nanoid(),
					paymentType: 'crypto',
					createdAt: initialValue?.createdAt || new Date().toISOString(),
					lastUpdatedAt: new Date().toISOString(),
					accountName: value?.accountName,
					description: value?.description,
					walletAddress: value?.walletAddress,
					network: value?.network,
					stableCoin: value?.stableCoin,
					fund: authUser?.fund
				}

				await (editMode
					? updateCryptoAccount(bankAccount)
					: addCryptoAccount({ ...bankAccount, isDefault: !hasDefaultAccount }))
			} catch (error: any) {
				toast.error(error.message)
			} finally {
				formik.setSubmitting(false)
				formik.resetForm()

				if (onSuccess) onSuccess()
			}
		}
	})

	return { formik }
}
