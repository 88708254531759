// export const INVITE_ROLE_PERMISSIONS = [
//   {
//     role: 'gp',
//     name: 'General Partner',
//     permissions: [
//       'Dashboard',
//       'Limited Partners',
//       'Companies',
//       'Fund expenses',
//       'User Roles',
//       'Meeting Notes',
//       'Contacts',
//     ]
//   },
//   {
//     role: 'vp',
//     name: 'Venture Partner',
//     permissions: [
//       'Dashboard',
//       'Companies',
//       'Meeting Notes',
//       'Contacts',
//     ]
//   }
// ]


export const INVITE_ROLE_PERMISSIONS = [
  {
    role: 'gp',
    name: 'General Partner',
    subTitle: 'Full access',
    permissions: [
    ]
  },
  {
    role: 'vp',
    name: 'Venture Partner',
    subTitle: 'Everything excluding:',
    permissions: [
      'Limited Partners',
      'Capital Calls',
    ]
  }
]