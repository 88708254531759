/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { WiseTransaction } from 'interfaces/wise.interface'
import { createAppSlice } from 'app/createAppSlice'

export interface WiseTransactionsSliceState {
	data: WiseTransaction[]
	isLoading: boolean
	error?: string | null
}

const initialState: WiseTransactionsSliceState = {
	data: [],
	isLoading: true,
	error: null,
}

export const wiseTransactionsSlice = createAppSlice({
	name: 'wiseTransactions',
	initialState,
	reducers: create => ({
		setWiseTransactionsIsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setWiseTransactionsError: create.reducer(
			(state, action: PayloadAction<string | null>) => {
				state.error = action.payload
				state.isLoading = false
			}
		),
		setWiseTransactions: create.reducer((state, action: PayloadAction<WiseTransaction[]>) => {
			state.data = action.payload
			state.isLoading = false
		}),
	}),
	selectors: {
		selectWiseTransactionsState: state => state
	}
})

export const {
	setWiseTransactions,
	setWiseTransactionsIsLoading,
	setWiseTransactionsError
} = wiseTransactionsSlice.actions

export const {
	selectWiseTransactionsState,
} = wiseTransactionsSlice.selectors
