/* eslint-disable @typescript-eslint/require-array-sort-compare */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable unicorn/no-new-array */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */

import { selectLPs, selectLPsLoading } from '../redux/lps.slice'
import { useContext, useEffect, useMemo } from 'react'

import { AuthContext } from 'context/AuthContext'
import { LPService } from '../service/lp.service'
import LimitedPartnerList from './LimitedPartnerList'
import LimitedPartnerTotalCommitted from './LimitedPartnerTotalCommitted'
import LimitedPartnerTotalCommittedStackedArea from '../components/LimitedPartnerTotalCommittedStackedArea'
import _ from 'lodash'
import { fetchFunnels } from 'features/funnels/funnels.slice'
import { listenToColumns } from 'features/columns/columns.api'
import { listenToLPs } from '../api/lps.api'
import { selectCapitalCalls } from 'features/capital_call/redux/capital_calls.slice'
import { store } from 'app/store'
import { useAppSelector } from 'app/hooks'

const getQuarterFromDate = (date: string): string => {
	const dateObj = new Date(date)
	const year = dateObj.getFullYear()
	const quarter = Math.floor((dateObj.getMonth() + 3) / 3) // Quarters are 1-4
	return `Q${quarter} ${year}`
}

const getQuarterOrder = (capitalCalls: any[]): string[] => {
	const years = _.uniq(
		capitalCalls.map(call => new Date(call.dateCalled).getFullYear())
	).sort()
	const quarters = ['Q1', 'Q2', 'Q3', 'Q4']
	return years.flatMap(year => quarters.map(quarter => `${quarter} ${year}`))
}

export default function LimitedPartnerPage(): JSX.Element {
	const { authUser } = useContext(AuthContext)

	const limitedPartners = [...useAppSelector(selectLPs)].sort((a, b) => {
		const aVal = a?.totalCommittedConverted?.amount || 0
		const bVal = b?.totalCommittedConverted?.amount || 0
		return bVal - aVal
	})
	const isLoading = useAppSelector(selectLPsLoading)
	const capitalCalls = useAppSelector(selectCapitalCalls)

	const groupedCapitalCalls = useMemo(
		() => _.groupBy(capitalCalls, call => getQuarterFromDate(call.dateCalled)),
		[capitalCalls]
	)

	const quarterOrder = useMemo(
		() => getQuarterOrder(capitalCalls),
		[capitalCalls]
	)

	const cumulativeAmounts: Record<string, number> = {}

	// Unique callers
	const callers = _.uniq(capitalCalls.map(call => call.limitedPartner?.name))

	const result = quarterOrder.map(quarter => {
		const calls = groupedCapitalCalls[quarter] || []
		const userAmounts: Record<string, number> = {}

		// Calculate current quarter's amounts for each user
		for (const call of calls) {
			const userId = call.limitedPartner.name
			userAmounts[userId] =
				(userAmounts[userId] || 0) + (call.amount?.amount || 0)
		}

		// Ensure all users have an entry even if their amount is 0
		for (const userId of callers) {
			if (!userAmounts[userId]) {
				userAmounts[userId] = 0
			}
			userAmounts[userId] += cumulativeAmounts[userId] || 0
		}

		// Update cumulative amounts for each user
		for (const userId in userAmounts) {
			cumulativeAmounts[userId] = userAmounts[userId]
		}

		return {
			name: quarter,
			...userAmounts
		}
	})

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubscribe = listenToLPs(authUser.fund.id)
		const unsubscribe2 = listenToColumns(authUser.fund.id)
		LPService.listenToAllCapitalCalls({ authUser })

		store.dispatch(fetchFunnels(authUser.fund.id))

		return () => {
			unsubscribe()
			unsubscribe2()
		}
	}, [authUser?.fund.id])

	return (
		<div className='flex flex-col-reverse 2xl:flex-row'>
			<LimitedPartnerList
				data={limitedPartners}
				loading={isLoading}
				className='grow'
			/>

			<div className='mt-5 flex flex-1 flex-col gap-3 px-5'>
				<LimitedPartnerTotalCommitted />
				<LimitedPartnerTotalCommittedStackedArea
					data={result}
					callers={callers}
				/>
			</div>
		</div>
	)
}
