/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import type { Founder, ICompany, ILimitedPartner, User } from 'interfaces'
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	startAfter,
	updateDoc,
	where,
	writeBatch
} from 'firebase/firestore'
import {
	setCompanies,
	setCompaniesError,
} from 'features/companies/redux/companies.slice'
import {
	setCompany,
	setCompanyError,
	setCompanyIsLoading,
	setRelevantPeople,
	setRelevantPeopleIsLoading
} from '../redux/company.slice'

import { Errorhandler } from 'lib/sentry'
import Logger from 'lib/logger'
import axiosInstance from 'lib/axios'
import { firestore } from 'lib/firebase'
import { getConvertedAmounts } from 'utils/currency'
import { lpsRef } from 'features/limited_partners/api/lps.api'
import { store } from 'app/store'

export const companiesRef = collection(firestore, 'companies')

export const addCompany = async (company: ICompany): Promise<ICompany> => {
	const docRef = doc(companiesRef, company.id)
	await setDoc(docRef, company, { merge: true })

	return company
}

export const getCompany = async (id: string): Promise<ICompany> => {
	const docRef = doc(companiesRef, id)
	const docSnap = await getDoc(docRef)
	return docSnap.data() as ICompany
}

export const getCompanies = async (
	fundId: string,
	max = 50
): Promise<ICompany[]> => {
	const q = query(companiesRef, where('fund.id', '==', fundId), limit(max))
	const querySnapshot = await getDocs(q)
	const companies = querySnapshot.docs.map(d => d.data() as ICompany)
	return companies
}

export const deleteCompany = async (id: string): Promise<void> => {
	const docRef = doc(companiesRef, id)
	await deleteDoc(docRef)
}

export const getNextCompanies = async (
	fundId: string,
	lastCompany?: ICompany,
	max = 100
): Promise<ICompany[]> => {
	const companies = []
	const q = query(
		companiesRef,
		where('fund.id', '==', fundId),
		orderBy('lastUpdated'),
		startAfter(lastCompany),
		limit(max)
	)
	const snapshot = await getDocs(q)
	for (const document of snapshot.docs) {
		companies.push(document.data() as ICompany)
	}
	return companies
}

export const updateCompany = async (
	company: Partial<ICompany>
): Promise<Partial<ICompany>> => {
	const docRef = doc(companiesRef, company.id)
	await updateDoc(docRef, company as any, { merge: true })
	return company
}

export const updateCompanies = async (
	companies: Partial<ICompany>[]
): Promise<Partial<ICompany>[]> => {
	// Group into chunks of 500
	const batch = writeBatch(firestore)
	for (const company of companies) {
		const docRef = doc(companiesRef, company.id)
		batch.update(docRef, company as any)
	}
	await batch.commit()
	return companies
}

export const deleteCompanyFounder = async ({
	company,
	founderEmail
}: {
	company: ICompany
	founderEmail: string
}): Promise<Founder[]> => {
	const updatedFounders = company.founders?.filter(
		founder => founder.email !== founderEmail
	)
	await updateCompany({ id: company.id, founders: updatedFounders })
	return updatedFounders ?? []
}

export const streamCompany = (id: string, authUser: User) => {
	store.dispatch(setCompanyIsLoading(true))

	const docRef = query(companiesRef, where('id', '==', id), where('fund.id', '==', authUser.fund.id))

	const unsubscribe = onSnapshot(
		docRef,
		docSnap => {
			if (docSnap.empty) {
				store.dispatch(setCompanyError('Company not found'))
			}
			const company = docSnap.docs[0].data() as ICompany
			store.dispatch(setCompany(company))
		},
		error => {
			store.dispatch(setCompanyError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}
export const listenToCompanies = (fundId: string) => {
	const q = query(companiesRef, where('fund.id', '==', fundId), orderBy('createdAt', 'desc'))

	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const companies = querySnapshot.docs.map(d => d.data() as any)
			const data: ICompany[] = companies.map(company => {
				return {
					...company,
					totalInvestmentConverted: getConvertedAmounts(
						company.totalInvestmentAmounts
					)
				}
			})
			store.dispatch(setCompanies(data))
		},
		error => {
			store.dispatch(setCompaniesError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}

export const listenToRelevantPeople = (tagIds: string[], fundId: string) => {
	if(tagIds.length === 0) return () => {}
	
	store.dispatch(setRelevantPeopleIsLoading(true))

	const q = query(
		lpsRef,
		where('fund.id', '==', fundId),
		where('tagIds', 'array-contains-any', tagIds)
	)
	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const data = querySnapshot.docs.map(d => d.data() as ILimitedPartner)
			store.dispatch(setRelevantPeople(data))
		},
		error => {
			Logger.error(error)
			Errorhandler.captureException(error)
		}
	)

	return unsubscribe
}

/**
 * Checks if a company exists based on its slug.
 *
 * @param slug - The slug of the company.
 *
 * @returns A Promise that resolves to a boolean indicating whether the company exists or not.
 */
export const checkIfCompanyExists = async (slug: string): Promise<any> => {
	const { data } = await axiosInstance.get(`/companies/${slug}/exists`)
	if (data.success) return data.data
	throw new Error(data.message)
}
