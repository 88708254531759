/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios'
import emlformat from 'eml-format'
import logger from 'lib/logger'
import { Fragment, useEffect, useState } from 'react'
import { ImSpinner10 } from 'react-icons/im'
import { purifyHtml } from 'utils/purifyHtml'

export default function EMLViewer({
	emlFile,
	content
}: {
	emlFile: any
	content: any
}) {
	const [isOpen, setIsOpen] = useState(false)
	const [email, setEmail] = useState<any>()
	const [isLoading, setIsLoading] = useState(true)

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	async function parseEmlFromUrl(emlUrl: string) {
		setIsLoading(true)
		try {
			const response = await axios.get(emlUrl)

			const emlData = response.data
			emlformat.read(emlData, (error: any, data: any) => {
				if (error) { logger.info(error); return; }

				setEmail(data)
			})
		} catch (error) {
			logger.error('Error parsing EML from URL:', error)
			return null
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		parseEmlFromUrl(emlFile.url)
	}, [emlFile])

	return (
		<>
			<div
				onClick={() => {
					openModal()
				}}
			>
				{content}
			</div>

			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-black bg-opacity-25 ' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<Dialog.Panel className='w-full max-w-7xl transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all'>
									<div className='flex items-start justify-between text-xs'>
										<div className='text-xs text-gray-500'>EML Viewer</div>
									</div>
									<div className='mb-2 text-xl font-semibold'>
										{email?.subject}
									</div>

									{isLoading && (
										<div className='h-52'>
											<ImSpinner10 className='animate-spin' />
										</div>
									)}

									{!isLoading && (
										<div className='my-4 flex h-[75vh] flex-col overflow-y-auto rounded border bg-white p-2'>
											<div className='mb-2 text-gray-500'>
												<span className='font-semibold'>From:</span>{' '}
												{email?.from?.name} ({email?.from?.email})
											</div>
											<div className='mb-2 text-gray-500'>
												<span className='font-semibold'>To:</span>{' '}
												{email?.to?.name} ({email?.to?.email})
											</div>
											<div className='mb-4 text-gray-500'>
												<span className='font-semibold'>Date:</span>{' '}
												{email?.headers?.Date}
											</div>
											<div
												className='prose'
												dangerouslySetInnerHTML={{
													__html: purifyHtml(email?.html || '')
												}}
											/>
											<div className='mt-4'>
												{email?.attachments?.map((attachment: any) => (
													<div key={attachment.name}>
														<span className='font-semibold'>Attachment:</span>{' '}
														{attachment?.name}
													</div>
												))}
											</div>
										</div>
									)}

									<div className='mt-4'>
										<button
											type='button'
											className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
											onClick={closeModal}
										>
											Close
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}
