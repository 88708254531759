/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-object-type-as-default-prop */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

'use client'

import * as React from 'react'

import {
	ChevronDown,
	ChevronLeft,
	ChevronRight,
	CirclePlus,
	Download,
	Filter,
	SlidersHorizontal,
	Trash2
} from 'lucide-react'
import type {
	ColumnDef,
	ColumnFiltersState,
	GroupingState,
	SortingState,
	VisibilityState
} from '@tanstack/react-table'
import {
	Popover,
	PopoverContent,
	PopoverTrigger
} from '@radix-ui/react-popover'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow
} from 'components/ui/table'
import {
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getGroupedRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable
} from '@tanstack/react-table'
import {
	getShareableCompanies,
	setShareableCompanies
} from 'features/companies/redux/companies.slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useContext, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { CSVLink } from 'react-csv'
import CreateColumn from './components/CreateColumn'
import CustomizeColumn from './components/CustomizeColumn'
import { DataFilter } from 'features/filter/views/DataFilter'
import DataTableSavedFilters from './components/DataTableSavedFilters'
import { DropdownMenu } from 'components/ui/dropdown-menu'
import type { ICompany } from 'interfaces'
import type { ITableColumn } from './interfaces/datatable.interface'
import { Input } from 'components/ui/input'
import ManageDataTableColumns from './components/ManageColumns'
import SaveCurrentList from './components/SaveCurrentList'
import { ShareCompany } from 'features/companies/view/ShareCompany'
import clsx from 'clsx'
import { selectSelectedButton } from './hooks/customColumn.slice'
import useDataFilterHook from 'features/filter/hooks/useDataFilterHook'

export interface Payment {
	id: string
	amount: number
	status: 'failed' | 'pending' | 'processing' | 'success'
	email: string
}

interface Props {
	id?: string
	columns: ColumnDef<any>[]
	columnData?: ITableColumn[]
	data: any[]
	filterPlaceholder?: string
	showColumnToggle?: boolean
	allowDownload?: boolean
	allowFilter?: boolean
	onSaveList?: (config: any) => void
	searchKey?: string
	onColumnUpdate: (updatedColumns: any) => void
	onClickNewCapitalCall?: () => void
	isCompanyList?: boolean
	className?: string
	allowSearch?: boolean
	endItem?: React.ReactNode
	loading?: boolean
	pageSize?: number
	entity:
		| 'bank account'
		| 'capital-call-request'
		| 'capital-call'
		| 'company'
		| 'competitor'
		| 'contact'
		| 'crypto'
		| 'invitation'
		| 'lp'
		| 'none'
		| 'partner'
		| 'prospect'
		| 'task'
	onColumnVisibilityChange: (columnId: string, isVisible: boolean) => void
	customRow?: any
	isGrouped?: boolean
	columnVisibilityState?: VisibilityState
	groupingState?: GroupingState
}

export function DataTable({
	columns,
	columnData = [],
	data,
	filterPlaceholder,
	allowDownload = false,
	showColumnToggle = false,
	searchKey = 'name',
	isCompanyList = false,
	allowFilter = false,
	isGrouped = false,
	className = '',
	onColumnUpdate,
	allowSearch = true,
	endItem,
	onSaveList,
	onClickNewCapitalCall,
	loading = false,
	pageSize = 50,
	entity,
	onColumnVisibilityChange,
	id,
	customRow,
	columnVisibilityState = {},
	groupingState = []
}: Readonly<Props>) {
	const [tableData, setTableData] = useState(data)

	const { selectedFilters, removeFilter } = useDataFilterHook()

	const { authUser } = useContext(AuthContext)
	const [sorting, setSorting] = React.useState<SortingState>([])
	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
		[]
	)
	const [savedColumns, setSavedColumns] = useState<ITableColumn[]>(columnData)
	const [columnVisibility, setColumnVisibility] =
		React.useState<VisibilityState>(columnVisibilityState)
	const [rowSelection, setRowSelection] = React.useState({})
	const dispatch = useAppDispatch()
	const shareableCompanies = useAppSelector(getShareableCompanies)
	const itemLength = data.length > pageSize ? pageSize : data.length
	const [grouping, setGrouping] = useState<GroupingState>(groupingState)
	const selectedColumn = useAppSelector(selectSelectedButton)
	const [isSelected, setIsSelected] = useState(true)

	const table = isGrouped
		? useReactTable({
				data: tableData,
				columns,
				onSortingChange: setSorting,
				onColumnFiltersChange: setColumnFilters,
				getCoreRowModel: getCoreRowModel(),
				getPaginationRowModel: getPaginationRowModel(),
				getSortedRowModel: getSortedRowModel(),
				getFilteredRowModel: getFilteredRowModel(),
				onColumnVisibilityChange: setColumnVisibility,
				onRowSelectionChange: setRowSelection,
				state: {
					sorting,
					columnFilters,
					columnVisibility,
					rowSelection,
					grouping,
					columnOrder: savedColumns.map(column => column.id)
				},
				initialState: {
					pagination: {
						pageSize
					},
					expanded: true
				},
				onGroupingChange: setGrouping,
				getExpandedRowModel: getExpandedRowModel(),
				getGroupedRowModel: getGroupedRowModel()
			})
		: useReactTable({
				data: tableData,
				columns,
				onSortingChange: setSorting,
				onColumnFiltersChange: setColumnFilters,
				getCoreRowModel: getCoreRowModel(),
				getPaginationRowModel: getPaginationRowModel(),
				getSortedRowModel: getSortedRowModel(),
				getFilteredRowModel: getFilteredRowModel(),
				onColumnVisibilityChange: setColumnVisibility,
				onRowSelectionChange: setRowSelection,
				state: {
					sorting,
					columnFilters,
					columnVisibility,
					rowSelection,
					columnOrder: savedColumns.map(column => column.id)
				},
				initialState: {
					pagination: {
						pageSize
					}
				}
			})

	const headers = columnData.map(column => {
		if (column.dataType === 'tags') {
			return {
				label: column.name,
				key: column.key
			}
		}

		return {
			label: column.name,
			key: column.key
		}
	})

	React.useEffect(() => {
		if (selectedFilters.length === 0) {
			setTableData(data)
			return
		}

		const dataCopyFiltered = [...data].filter(dataItem => {
			let isAMatch = false
			for (const filter of selectedFilters) {
				if (
					filter.filter === 'funnel' &&
					dataItem[filter.filter]?.name === filter.value
				) {
					isAMatch = true
				}

				if (
					filter.operation === 'has any' &&
					dataItem[filter.filter]
						?.toString()
						.toLowerCase()
						.includes(filter.value.toLowerCase())
				) {
					isAMatch = true
				}

				if (
					filter.operation === 'is' &&
					dataItem[filter.filter]?.toString().toLowerCase() ===
						filter.value.toLowerCase()
				) {
					isAMatch = true
				}

				if (
					filter.operation === 'is not' &&
					dataItem[filter.filter]?.toString().toLowerCase() !==
						filter.value.toLowerCase()
				) {
					isAMatch = true
				}

				if (
					filter.operation === 'includes' &&
					filter.value.includes(dataItem[filter.filter])
				) {
					isAMatch = true
				}

				if (
					filter.operation === 'does not include' &&
					!filter.value.includes(dataItem[filter.filter])
				) {
					isAMatch = true
				}

				if (
					filter.operation === 'has none' &&
					!dataItem[filter.filter]
						?.toString()
						.toLowerCase()
						.includes(filter.value.toLowerCase())
				) {
					isAMatch = true
				}

				if (
					filter.operation === 'date equals' &&
					new Date(dataItem[filter.filter]).toISOString().split('T')[0] ===
						new Date(filter.value).toISOString().split('T')[0]
				) {
					isAMatch = true
				}

				if (
					filter.operation === 'before' &&
					new Date(dataItem[filter.filter]) < new Date(filter.value)
				) {
					isAMatch = true
				}

				if (
					filter.operation === 'after' &&
					new Date(dataItem[filter.filter]) > new Date(filter.value)
				) {
					isAMatch = true
				}
			}
			return isAMatch
		})

		setTableData(dataCopyFiltered)
	}, [data, selectedFilters])

	React.useEffect(() => {
		if (!isCompanyList) return
		const listFromArray = Object.keys(rowSelection).map(Number)
		const companies: ICompany[] = []

		for (const index of listFromArray) {
			const company = data[index] as unknown as ICompany
			companies.push(company)
		}

		dispatch(setShareableCompanies(companies))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowSelection])

	React.useEffect(() => {
		if (columnData.length > 0) setSavedColumns(columnData)
	}, [columnData])

	React.useEffect(() => {
		setGrouping(groupingState)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [table])

	React.useEffect(() => {
		setColumnVisibility(columnVisibilityState)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [table])

	return (
		<div className={clsx('w-full', className)}>
			<div className='mt-5 flex w-full items-center overflow-auto rounded-md border bg-gray-50 px-2 py-1'>
				<div className='mr-10 flex h-full items-center justify-center whitespace-nowrap text-sm font-semibold'>
					{table.getFilteredRowModel().rows.length}&nbsp;
					{/* {entity}s */} items
				</div>

				{allowSearch && (
					<div className='flex items-end gap-3'>
						<Input
							placeholder={filterPlaceholder ?? 'Filter'}
							value={table.getColumn(searchKey)?.getFilterValue() as string}
							onChange={event =>
								table.getColumn(searchKey)?.setFilterValue(event.target.value)
							}
							className='max-w-sm rounded-md bg-white'
						/>
					</div>
				)}
				{allowFilter && (
					<DataFilter
						trigger={
							<Button variant='ghost' className='ml-5 truncate'>
								<Filter color='blue' size={15} />
								Filters
								<ChevronDown size={12} />
							</Button>
						}
						filters={columnData.map(item => {
							return {
								label: item.name,
								filterKey: item.key,
								dataType: item.dataType
							}
						})}
						entity=''
					/>
				)}
				{allowFilter && <SaveCurrentList entity={entity} />}
				{showColumnToggle && (
					<ManageDataTableColumns
						columns={savedColumns}
						onColumnUpdate={onColumnUpdate}
						onColumnVisibilityChange={onColumnVisibilityChange}
					/>
				)}
				{allowDownload && (
					<DropdownMenu>
						<CSVLink
							data={data}
							headers={headers}
							filename={`Spok_${authUser?.fund
								.name}_${entity}_${new Date().toLocaleString()}`}
						>
							<Button variant='ghost' className='truncate'>
								<Download className='h-4 w-4' color='blue' />
								Download
							</Button>
						</CSVLink>
					</DropdownMenu>
				)}

				{/* <div className="bg-gray-100 font-medium text-sm truncate overflow-ellipsis">
					<Switch size='sm' isSelected={isSelected} onValueChange={setIsSelected}>
						Add column
					</Switch>
				</div> */}

				<div className='flex-1' />

				{customRow}

				{entity === 'lp' && (
					<div className='mr-4'>
						<Button
							type='button'
							onClick={onClickNewCapitalCall}
							id='new-capical-call-button'
							className='whitespace-nowrap'
						>
							New Capital Call
						</Button>
					</div>
				)}
				<div className='flex items-center gap-2 truncate'>
					<div className='text-xs'>
						{(table.getState().pagination.pageIndex + 1) * itemLength -
							(itemLength - 1)}{' '}
						- {(table.getState().pagination.pageIndex + 1) * itemLength} of{' '}
						{table.getFilteredRowModel().rows.length}
					</div>
					<div className='flex space-x-2'>
						<Button
							variant='ghost'
							className='px-1 py-1'
							onClick={() => table.previousPage()}
							disabled={!table.getCanPreviousPage()}
						>
							<ChevronLeft />
						</Button>
						<Button
							variant='ghost'
							className='px-1 py-1'
							onClick={() => table.nextPage()}
							disabled={!table.getCanNextPage()}
						>
							<ChevronRight />
						</Button>
					</div>
					{endItem}
					{shareableCompanies.length > 0 && (
						<ShareCompany companies={shareableCompanies} />
					)}
				</div>
			</div>
			<DataTableSavedFilters entity={entity} className='my-2' />

			{selectedFilters.length > 0 && (
				<div className='flex w-full items-center gap-1 px-2'>
					<div className='flex items-center gap-1 pt-3 text-xs'>
						<SlidersHorizontal size={15} />
						Filters ({selectedFilters.length})
					</div>
					<div className='flex gap-2 overflow-x-auto pt-3'>
						{selectedFilters.map((filter, index) => (
							<div key={filter.filter} className='flex items-center gap-2'>
								<div className='flex items-center gap-2 rounded border bg-gray-50 px-1'>
									<div className='truncate rounded border border-red-400 bg-red-100 px-1 text-xs text-red-700'>
										{filter.filter}
									</div>
									<div className='truncate text-xs'>{filter.operation}</div>
									<div className='truncate rounded border border-blue-400 bg-blue-100 px-1 text-xs text-blue-700'>
										{filter.value}
									</div>
									<div
										className='cursor-pointer p-1 transition-all hover:scale-110'
										onClick={() => {
											removeFilter(index)
										}}
									>
										<Trash2 color='red' className='text-white' size={15} />
									</div>
								</div>
								{index !== selectedFilters.length - 1 && (
									<div className='text-sm'>or</div>
								)}
							</div>
						))}
					</div>
				</div>
			)}

			<div className='mt-1 rounded-md border'>
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map(headerGroup => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map(header => (
									<TableHead
										key={header.id}
										className='truncate border-r'
										style={{ width: `${header.getSize()}px` }}
										colSpan={header.colSpan}
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
									</TableHead>
								))}

								{isSelected && (
									<div className='flex h-10 w-24 items-center justify-center'>
										<Popover>
											<PopoverTrigger>
												<CirclePlus className='text-gray-500' size={20} />
											</PopoverTrigger>
											<PopoverContent
												className={`${
													selectedColumn ? 'w-72' : 'w-52'
												} PopoverContent z-50 mr-5 h-auto rounded-md border bg-white shadow-xl`}
												sideOffset={8}
											>
												{selectedColumn === null ? (
													<CustomizeColumn />
												) : (
													<CreateColumn entity={entity} />
												)}
											</PopoverContent>
										</Popover>
									</div>
								)}
							</TableRow>
						))}
					</TableHeader>

					{loading && (
						<TableBody>
							<TableRow>
								<TableCell
									colSpan={columns.length}
									className='h-24 text-center'
								>
									Loading...
								</TableCell>
							</TableRow>
						</TableBody>
					)}

					{!loading && (
						<TableBody>
							{table.getRowModel().rows.length > 0 ? (
								table.getRowModel().rows.map(row => (
									<TableRow
										key={row.id}
										data-state={row.getIsSelected() && 'selected'}
									>
										{row.getVisibleCells().map(cell => {
											return (
												<TableCell key={cell.id} className='border-r '>
													{flexRender(
														cell.column.columnDef.cell,
														cell.getContext()
													)}
												</TableCell>
											)
										})}
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell
										colSpan={columns.length}
										className='h-24 text-center'
									>
										<div data-testid={`${id}-no-results`}>No results.</div>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					)}
				</Table>
			</div>
			<div className='flex items-center justify-end space-x-2 py-4'>
				<div className='text-muted-foreground flex-1 text-sm'>
					{table.getFilteredSelectedRowModel().rows.length} of{' '}
					{table.getFilteredRowModel().rows.length} row(s) selected.
				</div>
			</div>
		</div>
	)
}
