import { addThreads, setThreadsLoading, setUpdatedThread } from '../redux/threads.slice'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'

import Errorhandler from 'lib/sentry'
import type { IEmailThread } from '../interfaces/email.interface'
import Logger from 'lib/logger'
import axiosInstance from 'lib/axios'
import { firestore } from 'lib/firebase'
/* eslint-disable @typescript-eslint/no-extraneous-class */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'
import { toast } from 'sonner'
import { updateThread } from '../api/threads.api'

export class ThreadService {
	private static readonly threadsRef = collection(firestore, 'threads')

	public static read = async (id: string, silent = true) => {
		try {
			store.dispatch(setUpdatedThread({ id, hasUnreadEmails: false }))
			await axiosInstance.post(`threads/${id}/read`)

			if (!silent) toast.success('Thread marked as read')
		} catch (error: any) {
			const message = error.response?.data?.message ?? error.message
			if (!silent) toast.error(message ?? 'An error occurred')

			store.dispatch(setUpdatedThread({ id, hasUnreadEmails: true }))

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static unread = async (id: string) => {
		try {
			store.dispatch(setUpdatedThread({ id, hasUnreadEmails: true }))
			await axiosInstance.post(`threads/${id}/unread`)

			toast.success('Thread marked as unread')
		} catch (error: any) {
			const message = error.response?.data?.message ?? error.message

			toast.error(message ?? 'An error occurred')
			store.dispatch(setUpdatedThread({ id, hasUnreadEmails: false }))

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static archive = async (id: string) => {
		try {
			toast.loading('Archiving thread...')

			await axiosInstance.post(`threads/${id}/archive`)

			toast.dismiss()
			toast.success('Thread archived')
		} catch (error: any) {
			const message = error.response?.data?.message ?? error.message

			toast.dismiss()
			toast.error(message ?? 'An error occurred')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static pin = async (id: string, pinned = true) => {
		try {
			toast.loading(`Pinning thread...`)

			await updateThread(id, { isPinned: pinned })

			toast.dismiss()
			toast.success(`Thread ${pinned ? 'pinned' : 'unpinned'}`)
		} catch (error: any) {
			toast.dismiss()
			toast.error(error.message ?? 'An error occurred')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static syncThreads = async () => {
		try {
			await axiosInstance.post('threads/sync')

			store.dispatch(setThreadsLoading(true))

			toast.success('Threads syncing...')
		} catch (error: any) {
			const message = error.response?.data?.message ?? error.message

			toast.error(message ?? 'An error occurred')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	// Listen to company threads
	public static listenToEntityThreads = (userId: string, emailList: string[]) => {
		// If no founder emails, return empty function
		if(emailList.length === 0) return () => {}
		
		// Limit to first 30 emails
		const emails = emailList.slice(0, 30)

		const q = query(
			this.threadsRef,
			where("userId", "==", userId), 
			where('emailAccounts', 'array-contains-any', emails),		
			orderBy('latestTimeStamp', 'desc')
		)
		
		const unsubscribe  = onSnapshot(q, (snapshot) => {
			const threads = snapshot.docs.map(doc => doc.data() as IEmailThread)
			store.dispatch(addThreads(threads))
		}, (error) => {
			Logger.error(error)
			Errorhandler.captureException(error)
		})

		return unsubscribe
	}
}
