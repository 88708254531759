import { useEffect, useState } from 'react'

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'

interface Props {
	tabs: {
		name: string
		value: string
		icon: React.ReactNode
		disabled?: boolean
		suffixIcon?: React.ReactNode
	}[]
	variant?: 'default' | 'sidebar'
	containerClassName?: string
	urlPathName?: string
	activeTabClassName?: string
	inactiveTabClassName?: string
  showSuffixIcon?: boolean
}

export default function useTabs({
	tabs,
	variant = 'default',
	containerClassName = 'border-b-2',
	urlPathName = 'tab',
	activeTabClassName = '',
	inactiveTabClassName = '',
	showSuffixIcon = false
}: Props) {
	const { search } = useLocation()
	const searchParams = new URLSearchParams(search)
	const param = searchParams.get(urlPathName)

	const [activeTab, setActiveTab] = useState(
		tabs.find(tab => tab.value === param) || tabs[0]
	)

	function handleTabChange(tab: { name: string; value: string }) {
		setActiveTab(tab)
	}

	useEffect(() => {
		// Add active tab to the url
		const params = new URLSearchParams(location.search)
		params.set(urlPathName, activeTab.value)
		window.history.replaceState({}, '', `${location.pathname}?${params}`)
	}, [activeTab.value])

	// Listen to url changes
	useEffect(() => {
		const aT = tabs.find(tab => tab.value === param)
		if (aT) {
			setActiveTab(aT)
		}
	}, [param])

	function Tabs() {
		return (
			<div className='flex h-fit items-center gap-1'>
				{tabs.map(item => (
					<div
						key={item.value}
						data-testid={item.value}
						onClick={() => {
							if (item.disabled) {
								return
							}
							setActiveTab(item)
						}}
						className={clsx(
							'flex cursor-pointer items-center gap-2 whitespace-nowrap px-4 py-2 text-sm font-semibold',
							activeTab.value === item.value &&
								'border-b-blue-500 text-blue-700',
							item.disabled && 'cursor-not-allowed text-gray-400',
							containerClassName,
							activeTab.value === item.value
								? activeTabClassName
								: inactiveTabClassName
						)}
					>
						{item.icon}
						{item.name}
						{showSuffixIcon && item.suffixIcon}
					</div>
				))}
			</div>
		)
	}

	return { activeTab, handleTabChange, Tabs, setActiveTab }
}
