import * as Yup from 'yup'

import { AuthContext } from 'context/AuthContext'
import type { ILimitedPartner } from 'interfaces'
import { addLP } from '../api/lps.api'
import { isEmailTaken } from 'features/authentication/authentication.api'
import { nanoid } from 'nanoid'
import { selectDefaultProspectFunnel } from 'features/funnels/funnels.slice'
import { toast } from 'sonner'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppSelector } from 'app/hooks'
import { useContext } from 'react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'

export default function useCreateLP({
	onSuccess,
	type
}: {
	onSuccess?: () => void
	type?: 'lp' | 'prospect'
}) {
	const { authUser } = useContext(AuthContext)
	const navigate = useNavigate()
	const defaultLpFunnel = useAppSelector(selectDefaultProspectFunnel)

	const validationSchema = Yup.object({
		name: Yup.string().required('Name is required'),
		email: Yup.string()
			.email('Invalid email')
			.test('unique', 'Email is taken', async value => {
				try {
					if (!value) return true
					const result = await isEmailTaken(value.toLowerCase().trim())
					if (!result.success) return false
					return !result.data.exists
				} catch {
					return false
				}
			}),
		linkedIn: Yup.string().url('Invalid URL')
	})

	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			linkedIn: '',
			website: '',
			description: '',
			referralMethod: '',
		},
		validationSchema,
		onSubmit: async values => {
			if (!authUser) return
			try {
				formik.setSubmitting(true)

				const lp: ILimitedPartner = {
					id: nanoid(),
					name: values.name,
					slug: values.name.toLowerCase().replaceAll(' ', '-'),
					linkedIn: values.linkedIn,
					email: values.email.toLowerCase().trim(),
					funnel: {
						id: defaultLpFunnel?.id || '',
						name: defaultLpFunnel?.name || '',
						category: defaultLpFunnel?.category || ''
					},
					creator: {
						id: authUser.id,
						name: authUser.name,
						photoUrl: authUser.photoUrl ?? ''
					},
					isApproved: type === 'lp',
					createdAt: new Date().toISOString(),
					lastUpdatedAt: new Date().toISOString(),
					fund: authUser.fund,
					website: values.website,
					description: values.description,
					referralMethod: values.referralMethod
				}

				await addLP(lp)
				toast.success(`${values.name} created`, {
					description: `You can view ${values.name} now`,
					action: {
						label: 'View',
						onClick: () => {
							navigate(`/limited-partner/${lp.id}`)
						}
					}
				})
				onSuccess?.()
			} catch (error: any) {
				toast.error(error.message)
			} finally {
				formik.setSubmitting(false)
				formik.resetForm()
			}
		}
	})

	return { formik }
}
