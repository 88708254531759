import { ChevronRight, Folder, Home } from 'lucide-react'
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { fetchFolders, getFundAccessToken } from '../api/google_drive.api'

import Button from 'components/Buttons/Button'
import { ImGoogleDrive } from 'react-icons/im'
import Logger from 'lib/logger'
import clsxm from 'utils/clsxm'
import { motion } from 'framer-motion'
import { selectAuthState } from 'features/authentication/authentication.slice'
import { toast } from 'sonner'
import { updateCompany } from 'features/companies/api/companies.api'
import { useAppSelector } from 'app/hooks'

export interface Trail {
	id: string
	name: string
}

export interface IFolder {
	id: string
	name: string
}

interface Props {
	companyId: string
}

function GoogleDriveSync({ companyId }: Props) {
	const { authUserFund } = useAppSelector(selectAuthState)

	const [isOpen, setIsOpen] = useState(false)
	const [folders, setFolders] = useState<IFolder[]>([])
	const [selectedFolder, setSelectedFolder] = useState<IFolder>()
	const [folderTrail, setFolderTrail] = useState<Trail[]>([
		{ id: 'root', name: 'Root' }
	])

	const [accessToken, setAccessToken] = useState('')

	useEffect(() => {
		if (!isOpen) return
		if (!authUserFund?.googleToken) return

		const getAccessToken = async () => {
			try {
				const result = await getFundAccessToken()
				setAccessToken(result)
			} catch (error: any) {
				Logger.error(error)
				toast.error(error.message ?? 'Failed to get access token')
			}
		}

		getAccessToken()
	}, [isOpen, authUserFund?.googleToken])

	const getFolders = async (folderId?: string) => {
		try {
			if (!accessToken) return
			const result = await fetchFolders(accessToken, folderId)
			Logger.info(result)

			setFolders(result)
		} catch (error: any) {
			Logger.error(error)
			toast.error(error.message ?? 'Failed to get files')
		}
	}

	useEffect(() => {
		getFolders('root')
	}, [accessToken])

	const selectFolder = async (folder: IFolder) => {
		try {
			toast.loading('Selecting folder...')
			await updateCompany({
				id: companyId,
				googleDrive: { folderId: folder.id }
			})

			toast.dismiss()
			toast.success('Folder selected successfully')
		} catch (error: any) {
			Logger.error(error)

			toast.dismiss()
			toast.error(error.message ?? 'Failed to select folder')
		} finally {
			setSelectedFolder(undefined)
		}
	}

	if (!authUserFund?.googleToken) {
		return <div />
	}

	return (
		<div>
			<div className='mb-2 flex w-fit gap-2 bg-white'>
				<Button type='button' onClick={() => setIsOpen(true)}>
					<div className='flex items-center gap-2 p-0.5 text-sm'>
						<ImGoogleDrive size={16} className='text-md text-white' />
						Import Files
					</div>
				</Button>
			</div>

			{isOpen && (
				<Transition appear show={isOpen} as={Fragment}>
					<Dialog
						as='div'
						className='relative z-10'
						onClose={() => setIsOpen(false)}
					>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0'
							enterTo='opacity-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<div className='fixed inset-0 bg-black/25' />
						</Transition.Child>

						<div className='fixed inset-0 overflow-y-auto'>
							<div className='flex min-h-full items-center justify-center p-4 text-center'>
								<Transition.Child
									as={Fragment}
									enter='ease-out duration-300'
									enterFrom='opacity-0 scale-95'
									enterTo='opacity-100 scale-100'
									leave='ease-in duration-200'
									leaveFrom='opacity-100 scale-100'
									leaveTo='opacity-0 scale-95'
								>
									<Dialog.Panel className='w-full max-w-7xl transform overflow-hidden rounded bg-white p-6 text-left shadow-xl transition-all'>
										<div>Import Files from google drive - Select a folder</div>

										{/* Trail */}
										<div className='mt-8 flex items-center'>
											{folderTrail.map((trail, index) => (
												<motion.div
													key={trail.id}
													className='flex items-center'
													onClick={async () => {
														const newTrail = folderTrail.filter(
															(_, i) => i <= index
														)
														setFolderTrail(newTrail)

														getFolders(trail.id)

														setSelectedFolder(undefined)
													}}
												>
													{index !== 0 && (
														<div className='flex items-center px-1'>
															<ChevronRight size={16} />
														</div>
													)}
													<div
														className='items-center text-sm font-normal capitalize text-[#093E45] hover:underline dark:font-normal dark:text-blue-400'
														key={trail.id}
													>
														{index === 0 ? (
															<div className='flex items-center justify-center'>
																<Home size={18} />
															</div>
														) : (
															trail.name
														)}
													</div>
												</motion.div>
											))}
										</div>

										{/* Folders */}
										<div className='my-8'>
											{folders.length === 0 && (
												<div>No folders found in this directory. </div>
											)}
											{folders.map(folder => (
												<div key={folder.id}>
													<motion.div
														onClick={() => {
															setSelectedFolder(folder)
														}}
														onDoubleClick={() => {
															getFolders(folder.id)

															const newTrail = folderTrail
															newTrail.push({
																id: folder.id,
																name: folder.name
															})
															setFolderTrail(newTrail)

															setSelectedFolder(undefined)
														}}
														whileHover={{ scale: 1.02 }}
														className={clsxm(
															'group flex h-[7rem] w-[6rem] cursor-pointer flex-col items-center justify-center rounded-md border',
															selectedFolder?.id === folder.id
																? 'border-blue-500'
																: 'border-gray-200'
														)}
													>
														<div className='flex flex-col items-center justify-center gap-1 px-2 text-center '>
															<div className='mb-2 transition-all xl:scale-[2]'>
																<Folder size={20} />
															</div>
															<span className='whitespace-nowrap text-sm'>
																{folder.name}
															</span>
														</div>
													</motion.div>
												</div>
											))}
										</div>

										<div className='mt-4 flex gap-2'>
											<Button
												disabled={!selectedFolder}
												type='button'
												onClick={() => {
													if (!selectedFolder) return
													selectFolder(selectedFolder)
													setIsOpen(false)
												}}
											>
												Import Files
											</Button>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition>
			)}
		</div>
	)
}

export default GoogleDriveSync
