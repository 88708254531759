/* eslint-disable jsx-a11y/no-static-element-interactions */

import {
	AlarmClock,
	Banknote,
	BarChart2,
	CalendarCheck,
	CopyPlus,
	Files,
	Info,
	Mail,
	MessageCircle,
	Notebook
} from 'lucide-react'
import {
	COMPANY_DETAILS_INFO_TABS,
	COMPANY_DETAILS_RIGHT_TABS
} from '../data/companies.data'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'
import ChatPanel from './ChatPanel'
import CompanyActivities from './CompanyActivities'
import CompanyAnalytics from '../submodules/Analytics/view/CompanyAnalytics'
import CompanyDeckPreview from './CompanyDeckPreview'
import CompanyDetailsHeader from './CompanyDetailsHeader'
import CompanyDetailsInfo from './CompanyDetailsInfo'
import CompanyEmails from './CompanyEmail'
import CompanyFiles from './CompanyFiles'
import CompanyNotes from './CompanyNotes'
import CompanyRelevantPeople from './CompanyRelevantPeople'
import CompanyTasks from './CompanyTasks'
import CompanyUpdates from './CompanyUpdates'
import DashboardLayout from 'components/Dashboard/DashboardLayout'
import InvestmentMemo from '../submodules/InvetsmentMemo/view/InvestmentMemo'
import InvestmentTerms from '../../investments/view/InvestmentTerms'
import { ObservableStoreProvider } from 'features/folders/hooks/observable.store'
import Skeleton from 'components/Skeleton'
import clsx from 'clsx'
import { streamCompany } from '../api/companies.api'
import { toast } from 'sonner'
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppSelector } from 'app/hooks'
import useTabs from 'components/hooks/useTabs'

const tabs = [
	{
		name: 'Main Info',
		value: 'main-info',
		icon: <Info size={15} />,
		disabled: false
	},
	{
		name: 'Investment Memo',
		value: 'investment-memo',
		icon: <Banknote size={15} />,
		disabled: false,
		suffixIcon: <Info size={14} className='text-green-600' />
	},
	{
		name: 'Analytics',
		value: 'analytics',
		icon: <BarChart2 size={15} />,
		disabled: false
	}
]

export function getSidebarIcon(value: string) {
	if (value === 'notes') return <Notebook size={20} />
	if (value === 'email') return <Mail size={20} />
	if (value === 'files') return <Files size={20} />
	if (value === 'updates') return <CopyPlus size={20} />
	if (value === 'logAction') return <CalendarCheck size={20} />
	if (value === 'reminder') return <AlarmClock size={20} />
	if (value === 'chat') return <MessageCircle size={20} />
}

export default function CompanyDetails(): JSX.Element {
	const location = useLocation()
	const navigate = useNavigate()
	const companyId = location.pathname.split('/').pop()
	const searchParams = new URLSearchParams(location.search)
	const rightTab = searchParams.get('rightTab') ?? 'files'

	const { data, isLoading, error } = useAppSelector(state => state.company)
	const { Tabs, activeTab } = useTabs({
		tabs,
		containerClassName: '',
		urlPathName: 'page',
		showSuffixIcon: data?.hasInvestmentMemo
	})

	const { authUser } = useContext(AuthContext)

	const [selectedDetailsInfo, setSelectedDetailsInfo] = useState(
		COMPANY_DETAILS_INFO_TABS[0]
	)
	// const [selectedRightTab, setSelectedRightTab] = useState(
	// 	COMPANY_DETAILS_RIGHT_TABS.find(tab => tab.id === rightTab) ||
	// 		COMPANY_DETAILS_RIGHT_TABS[0]
	// )

	// Listen to company changes
	useEffect(() => {
		if (!companyId || !authUser?.id) return
		const unsubscribe = streamCompany(companyId, authUser)

		return () => {
			unsubscribe()
		}
	}, [companyId, authUser])

	function handleRightDockedTabClicked(tabId: string, isDisabled?: boolean) {
		if (isDisabled) {
			toast('This feature is not available yet')
		} else {
			const search = `${
				location.search.split('&rightTab')[0]
			}&rightTab=${tabId}`
			navigate({
				pathname: location.pathname,
				search
			})
		}
	}

	if (isLoading) {
		return (
			<DashboardLayout
				showSidebar={false}
				title='Company Details'
				hideHeader={false}
			>
				<div className='ml-5 mt-5 flex flex-col gap-2'>
					<Skeleton className='h-5 w-96 rounded-md' />
					<Skeleton className='h-5 w-[30rem] rounded-md' />
					<Skeleton className='h-5 w-[30rem] rounded-md' />
				</div>
			</DashboardLayout>
		)
	}

	if (error) {
		return (
			<DashboardLayout
				showSidebar={false}
				title='Company Details'
				hideHeader={false}
			>
				<div className='ml-5 mt-5 flex flex-col gap-2'>
					<div className='text-red-500'>{error}</div>
				</div>
			</DashboardLayout>
		)
	}

	if (!data) {
		return (
			<DashboardLayout
				showSidebar={false}
				title='Company Details'
				hideHeader={false}
			>
				<div className='ml-5 mt-5 flex flex-col gap-2'>
					<div className='text-red-500'>Company not found</div>
				</div>
			</DashboardLayout>
		)
	}

	return (
		<DashboardLayout
			showSidebar={false}
			title='Company Details'
			hideHeader={false}
			headerComponent={<Tabs />}
		>
			{activeTab.value === 'main-info' && (
				<div>
					<div className='sticky top-0 flex h-[calc(100vh-3rem)] flex-wrap justify-between gap-2 bg-gray-50 px-2 pb-0 pr-0'>
						<div className='flex h-full flex-1 flex-col gap-5 overflow-auto py-2'>
							<CompanyDetailsHeader company={data} />
							{/* <CompanyDetailsQuickActions company={data} /> */}
							<div className='flex flex-col'>
								<div className='flex items-center'>
									{COMPANY_DETAILS_INFO_TABS.map(item => (
										<button
											type='button'
											key={item.id}
											data-testid={`details-tab-${item.id}`}
											onClick={() => setSelectedDetailsInfo(item)}
											className={clsx(
												'cursor-pointer whitespace-nowrap border-b-2 px-4 py-2 font-semibold',
												selectedDetailsInfo.id === item.id &&
													'border-b-blue-500 text-blue-700'
											)}
										>
											{item.label}
										</button>
									))}
								</div>
								{selectedDetailsInfo.id === 'details' && (
									<CompanyDetailsInfo company={data} />
								)}
								{selectedDetailsInfo.id === 'investment' && (
									<InvestmentTerms company={data} />
								)}
								{selectedDetailsInfo.id === 'relevantpeople' && (
									<CompanyRelevantPeople company={data} />
								)}
								{selectedDetailsInfo.id === 'tasks' && (
									<CompanyTasks company={data} />
								)}
							</div>
							{selectedDetailsInfo.id === 'details' && (
								<CompanyActivities company={data} />
							)}
						</div>
						<div className='h-full w-[0.1rem] bg-gray-200' />
						{/* Right Sidebar */}
						<div className='flex h-full rounded-md py-2 lg:w-[35vw]'>
							<div className='h-full flex-1 overflow-auto rounded-t-md border bg-white'>
								{rightTab === 'notes' && <CompanyNotes company={data} />}
								{rightTab === 'email' && <CompanyEmails company={data} />}
								{rightTab === 'files' && (
									<div>
										<CompanyDeckPreview
											company={data}
											url={data.deck?.url}
											thumbnail={data.deck?.thumbnail}
											name={data.deck?.name}
										/>
										<ObservableStoreProvider>
											<CompanyFiles company={data} />
										</ObservableStoreProvider>
									</div>
								)}
								{rightTab === 'updates' && <CompanyUpdates company={data} />}

								{rightTab === 'chat' && data.hasInvestmentMemo ? (
									<ChatPanel company={data} />
								) : (
									<div />
								)}
							</div>
							<div className='h-full'>
								{COMPANY_DETAILS_RIGHT_TABS.map(item => (
									<div
										data-testid={item.id}
										key={item.id}
										className={clsx(
											'group flex cursor-pointer flex-col items-center justify-center gap-2 px-4 py-2 font-semibold',
											item.disabled && 'opacity-40'
										)}
										onClick={() =>
											handleRightDockedTabClicked(item.id, item.disabled)
										}
									>
										<div
											className={clsx(
												'boder flex h-10 w-10 items-center justify-center rounded-md',
												rightTab === item.id
													? 'border-blue-500 bg-blue-500 text-white'
													: 'border-gray-200'
											)}
										>
											{getSidebarIcon(item.id)}
										</div>
										<div className='text-center text-xs'>{item.label}</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			)}
			{activeTab.value === 'analytics' && <CompanyAnalytics company={data} />}
			{activeTab.value === 'investment-memo' && (
				<InvestmentMemo company={data} />
			)}
		</DashboardLayout>
	)
}
