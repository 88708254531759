/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-extraneous-class */
import { store } from 'app/store'
import type { IActivity } from 'features/activity/interfaces/actvitiy.interface'
import { ActivityService } from 'features/activity/service/activity.service'
import type { FileUploadResult } from 'features/storage/hooks/useFirebaseStorageUploader'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import type { CompanyData, LimitedPartnerData, User } from 'interfaces'
import { storage } from 'lib/firebase'
import Logger from 'lib/logger'
import Errorhandler from 'lib/sentry'
import { nanoid } from 'nanoid'
import { toast } from 'sonner'
import type { IFile } from '../file.interface'
import { addFile, addFiles, deleteFile } from '../files.api'

export class FileService {
	public static addFile = async (file: IFile) => {
		try {
			await addFile(file)

			toast.success('File uploaded successfully')

			const { authUser } = store.getState().authentication
			if (!authUser) return

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: {
					id: authUser.id,
					name: authUser.name,
					photoUrl: authUser.photoUrl || ''
				},
				fund: authUser.fund,
				activity: 'added file',
				summary: '',
				description: file.name,
				files: [
					{
						id: file.id,
						name: file.name,
						url: file.url
					}
				],
				icon: '',
				createdAt: new Date().toISOString()
			}

			if (file.company) {
				activity.summary = file.company.name
				activity.company = file.company
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			toast.error(error?.message ?? 'Failed to upload file')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static addFiles = async (files: IFile[]) => {
		try {
			await addFiles(files)

			toast.success('Files uploaded successfully')
			const { authUserData, authUserFundData } = store.getState().authentication
			if (!authUserData || !authUserFundData) return

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUserData,
				fund: authUserFundData,
				activity: 'added file',
				summary: '',
				description: '',
				files: files.map(file => ({
					id: file.id,
					name: file.name,
					url: file.url
				})),
				icon: '',
				createdAt: new Date().toISOString()
			}

			if (files[0].company) {
				activity.summary = files[0].company.name
				activity.company = files[0].company
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			toast.error(error?.message ?? 'Failed to upload files')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static deleteFile = async (file: IFile) => {
		try {
			await deleteFile(file.id)

			toast.success('File deleted successfully')

			const { authUserData, authUserFundData } = store.getState().authentication
			if (!authUserData || !authUserFundData) return

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUserData,
				fund: authUserFundData,
				activity: 'deleted file',
				summary: '',
				description: file.name,
				files: [
					{
						id: file.id,
						name: file.name,
						url: file.url,
						thumbnails: file.thumbnails
					}
				],
				icon: '',
				createdAt: new Date().toISOString()
			}

			if (file.company) {
				activity.summary = file.company.name
				activity.company = file.company
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			toast.error(error?.message ?? 'Failed to delete file')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static saveFiles = async ({
		files,
		type,
		authUser,
		company,
		limitedPartner
	}: {
		files: File[]
		type:
		| 'capital_call'
		| 'company_deck'
		| 'company_file'
		| 'company_investment'
		| 'company_note'
		| 'company_update'
		authUser: User
		company?: CompanyData
		limitedPartner?: LimitedPartnerData
	}) => {
		const storagePath = `funds/${authUser.fund.id}/documents`

		const results = await FileService.uploadFiles(files, storagePath)

		const filesData = results.map((result: FileUploadResult) => {
			const data: IFile = {
				id: result.id,
				name: result.name,
				fileType: type,
				url: result.url,
				mimeType: result.type,
				size: result.size ?? 0,
				fund: authUser.fund,
				creator: {
					id: authUser.id,
					name: authUser.name,
					photoUrl: authUser.photoUrl ?? ''
				},
				source: 'spok',
				createdAt: new Date().toISOString(),
				lastUpdatedAt: new Date().toISOString()
			}

			if (company) data.company = company

			if (limitedPartner) data.limitedPartner = limitedPartner

			return data
		})

		await FileService.addFiles(filesData)

		return filesData
	}

	public static uploadFiles = async (
		files: File[],
		storagePath: string
	): Promise<FileUploadResult[]> => {
		const data: FileUploadResult[] = []

		const uploadPromises = files.map(async document => {
			const file = document
			const fileName = `${nanoid()}_${file.name}`

			const path = `${storagePath}/${fileName}`
			const storageRef = ref(storage, path)

			await uploadBytes(storageRef, file)
			const downloadURL = await getDownloadURL(storageRef)

			const result = {
				id: nanoid(),
				name: file.name,
				url: downloadURL,
				type: file.type,
				size: file.size,
				createdAt: new Date().toISOString()
			}

			data.push(result)
		})

		await Promise.all(uploadPromises)

		return data
	}
}
