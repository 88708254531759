import type { ICompany, ILimitedPartner } from 'interfaces'

/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import { getCompany } from 'features/companies/api/companies.api'

export interface CompaniesSliceState {
	data: ICompany | null
	isLoading: boolean
	error?: string | null
	relevantPeople: {
		data: ILimitedPartner[]
		isLoading: boolean
		error?: string | null
	}
}

const initialState: CompaniesSliceState = {
	data: null,
	isLoading: false,
	error: null,
	relevantPeople: {
		data: [],
		isLoading: false,
		error: null
	}
}

export const companySlice = createAppSlice({
	name: 'company',
	initialState,
	reducers: create => ({
		fetchCompany: create.asyncThunk(
			async (companyId: string) => {
				const response = await getCompany(companyId)
				return response
			},
			{
				pending: state => {
					state.isLoading = state.data === null
				},
				fulfilled: (state, action) => {
					state.data = action.payload
					state.isLoading = false
				},
				rejected: (state, action) => {
					state.error = action.error.message
					state.isLoading = false
				}
			}
		),
		setCompanyIsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setCompanyError: create.reducer(
			(state, action: PayloadAction<string | null>) => {
				state.error = action.payload
				state.isLoading = false
			}
		),
		setCompany: create.reducer(
			(state, action: PayloadAction<ICompany | null>) => {
				state.data = action.payload
				state.isLoading = false
			}
		),
		setRelevantPeople: create.reducer(
			(state, action: PayloadAction<ILimitedPartner[]>) => {
				state.relevantPeople.data = action.payload
				state.relevantPeople.isLoading = false
			}
		),
		setRelevantPeopleIsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.relevantPeople.isLoading = action.payload
			}
		)
	}),
	selectors: {
		selectCompanyState: state => state,
		selectCompany: state => state.data,
		selectCompanyLoading: state => state.isLoading,
		selectRelevantPeople: state => state.relevantPeople
	}
})

export const {
	fetchCompany,
	setCompany,
	setCompanyError,
	setCompanyIsLoading,
	setRelevantPeople,
	setRelevantPeopleIsLoading
} = companySlice.actions

export const { selectCompanyState, selectRelevantPeople } =
	companySlice.selectors
