import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	where
} from 'firebase/firestore'
import { firestore, functions } from 'lib/firebase'
import {
	setContact,
	setContactError,
	setContactIsLoading
} from '../redux/contact.slice'

import { Errorhandler } from 'lib/sentry'
import type { IContact } from 'interfaces'
import Logger from 'lib/logger'
import { httpsCallable } from 'firebase/functions'
import { setContacts } from 'features/contacts/redux/contacts.slice'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'

const contactsRef = collection(firestore, 'contacts')

export const addContact = async (contact: IContact): Promise<IContact> => {
	const docRef = doc(contactsRef, contact.id)
	await setDoc(docRef, contact, { merge: true })

	return contact
}

export const getContact = async (id: string): Promise<IContact> => {
	const docRef = doc(contactsRef, id)
	const docSnap = await getDoc(docRef)
	return docSnap.data() as IContact
}

export const deleteContact = async (id: string): Promise<void> => {
	const docRef = doc(contactsRef, id)
	await deleteDoc(docRef)
}

export const listenToContact = (id: string) => {
	store.dispatch(setContactIsLoading(true))

	const docRef = doc(contactsRef, id)
	const unsubscribe = onSnapshot(
		docRef,
		docSnap => {
			if (!docSnap.exists()) {
				store.dispatch(setContactError('Contact not found'))
				return
			}

			const contact = docSnap.data() as IContact
			store.dispatch(setContact(contact))
		},
		error => {
			store.dispatch(setContactError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}

export const getContacts = async (fundId: string): Promise<IContact[]> => {
	const q = query(contactsRef, where('fund.id', '==', fundId))
	const querySnapshot = await getDocs(q)
	const contacts = querySnapshot.docs.map(d => d.data() as IContact)
	return contacts
}

export const updateContact = async (
	contact: Partial<IContact>
): Promise<Partial<IContact>> => {
	const docRef = doc(contactsRef, contact.id)
	await updateDoc(docRef, contact as any, { merge: true })
	return contact
}

export const listenToContacts = (fundId: string) => {
	const hasContacts = store.getState().contacts.data.length > 0
	if (!hasContacts) store.dispatch(setContactIsLoading(true))

	const q = query(contactsRef, where('fund.id', '==', fundId))
	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const contacts = querySnapshot.docs.map(d => d.data() as IContact)
			store.dispatch(setContacts(contacts))
		},
		error => {
			store.dispatch(setContactError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}

interface CheckIfContactExistsData {
	email: string
	fundId: string
}

interface CheckIfContactExistsResult {
	exists: boolean
}

const checkIfContactExistsFunction = httpsCallable<
	CheckIfContactExistsData,
	CheckIfContactExistsResult
>(functions, 'checkIfContactExists')

/**
 * Checks if a user exists.
 *
 * @param email - The email of the user.
 * @param fundId - The fund ID.
 *
 * @returns {Promise<CheckIfContactExistsResult>} A Promise that resolves to the result of the check.
 */
export const checkIfContactExists = async (
	email: string,
	fundId: string
): Promise<boolean> => {
	const result = await checkIfContactExistsFunction({ email, fundId })
	return result.data.exists
}
