/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { Dialog, Transition } from '@headlessui/react'
import type { IFile } from 'features/files/file.interface'
import { Fragment, useEffect, useState } from 'react'

/* eslint-disable jsx-a11y/click-events-have-key-events */
export default function PictureView({
	file,
	content
}: {
	file: IFile
	content: any
}) {
	const [isOpen, setIsOpen] = useState(false)

	function closeModal() {
		setIsOpen(false)
	}

	// When I press the escape key, close the modal
	useEffect(() => {
		function handleEsc(e: KeyboardEvent) {
			if (e.key === 'Escape') {
				closeModal()
			}
		}

		window.addEventListener('keydown', handleEsc)

		return () => {
			window.removeEventListener('keydown', handleEsc)
		}
	}, [])

	return (
		<>
			<div
				onClick={() => {
					setIsOpen(true)
				}}
			>
				{content}
			</div>

			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-black bg-opacity-25 ' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<Dialog.Panel className='w-full max-w-2xl transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all'>
									<div className='flex items-start justify-between'>
										<Dialog.Title
											as='h3'
											className='flex flex-col  font-medium leading-6 text-gray-900'
										>
											<small className='text-xs  text-gray-400'>
												Image viewer
											</small>
											<span className='text-base'>{file.name}</span>
										</Dialog.Title>
									</div>
									<div className='mt-2  flex min-h-[30rem] items-center justify-center'>
										<img
											src={file.url || file.thumbnails?.small}
											className='h-auto w-full'
											alt=''
										/>
									</div>

									<div className='mt-4'>
										<button
											type='button'
											className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
											onClick={closeModal}
										>
											Close
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}
