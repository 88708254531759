import type { IActivity } from '../interfaces/actvitiy.interface'
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface ActivitySliceState {
	data: IActivity[]
	isLoading: boolean
	error?: string | null
}

const initialState: ActivitySliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const activitySlice = createAppSlice({
	name: 'activities',
	initialState,
	reducers: create => ({
		setActivitiesIsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setActivitiesError: create.reducer(
			(state, action: PayloadAction<string | null>) => {
				state.error = action.payload
				state.isLoading = false
			}
		),
		setActivities: create.reducer(
			(state, action: PayloadAction<IActivity[]>) => {
				state.data = action.payload
				state.isLoading = false
			}
		)
	}),
	selectors: {
		selectIsActivitiesLoading: state => state.isLoading,
		selectCompanyActivities: (state, companyId: string) =>
			state.data.filter(activity => activity.company?.id === companyId),
		selectLimitedPartnerActivities: (state, limitedPartnerId: string) =>
			state.data.filter(
				activity => activity.limitedPartner?.id === limitedPartnerId
			),
		selectContactActivities: (state, contactId: string) =>
			state.data.filter(activity => activity.contact?.id === contactId),
		selectCompetitorActivities: (state, competitorId: string) =>
			state.data.filter(activity => activity.competitor?.id === competitorId)
	}
})

export const { setActivitiesIsLoading, setActivitiesError, setActivities } =
	activitySlice.actions

export const {
	selectIsActivitiesLoading,
	selectLimitedPartnerActivities,
	selectCompanyActivities,
	selectContactActivities,
	selectCompetitorActivities
} = activitySlice.selectors
