/* eslint-disable @typescript-eslint/no-shadow */
import { Dialog, Transition } from "@headlessui/react";
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Fragment, useState } from "react";

import { FileService } from "features/files/services/file.service";
import FileViewer from "features/fileviewers/views/FileViewer";
import type { ICapitalCall } from "../interfaces/capitalcalls.interface";
import { X } from "lucide-react";

interface AppProps {
  capitalCall: ICapitalCall;
}

export default function ViewCapitalCallFiles({ capitalCall }: AppProps) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
        <div
          onClick={() => {
            if (!capitalCall.files || capitalCall.files.length === 0) return;
            openModal();
          }}
          className='flex justify-center flex-wrap gap-2 capitalize'
        >
          <div
            className={`group flex w-fit  items-center gap-1 text-ellipsis rounded px-3 py-[1px] text-xs text-blue-800 whitespace-nowrap ${(capitalCall.files?.length || 0) > 0 ? ' bg-blue-200 cursor-pointer' : ' bg-gray-200 cursor-not-allowed'
              }`}
          >
            {capitalCall.files?.length ? (
              <div className='flex items-center gap-1'>
                <div className='text-xs text-blue-800'>View Files</div>
                <div className='text-xs text-blue-800'>
                  ({capitalCall.files.length})
                </div>
              </div>
            ) : (
              <div className='text-xs text-gray-800'>No Files</div>
            )}
          </div>
        </div>


      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-6xl transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
										as='h3'
										className='flex items-start justify-between border-b py-3 pb-2 text-lg font-medium leading-6 text-gray-900'
									>
										<div className='flex-1 text-sm'>
											<div className='flex flex-col text-xs text-gray-700'>
												Capital Call Files
											</div>
                      {capitalCall.limitedPartner.name}
										</div>
										<div
											onClick={closeModal}
											className='flex cursor-pointer items-center justify-center rounded-md border p-1'
										>
											<X size={18} />
										</div>
									</Dialog.Title>
                

                  <div className='mt-3 grid grid-cols-1 gap-2 px-2 xl:grid-cols-2 2xl:grid-cols-3'>
                    {[...(capitalCall.files || [])]
                      // .sort((a, b) => {
                      //   a.name > b.name ? 1 : -1
                      //   // if (fileSort.order === 'asc') {
                      //   // 	return a[fileSort.field]! > b[fileSort.field]! ? 1 : -1
                      //   // }
                      //   // return a[fileSort.field]! < b[fileSort.field]! ? 1 : -1
                      // })
                      .map(file => {
                        return (
                          <div
                            key={file.id}
                            data-testid={`file-${file.name}`}
                            className='flex items-center justify-between rounded border border-gray-200 bg-gray-100 p-1'
                          >
                            <div className='w-full items-center gap-2'>
                              <FileViewer
                                readonly
                                file={file}
                                onDelete={file => {
                                  FileService.deleteFile(file)
                                }}
                              />
                            </div>
                          </div>
                        )
                      })}
				            </div>         
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}