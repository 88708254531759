import type { Column } from "features/kanban/views/KanbanView";
import type { ICompany } from "interfaces";

export function sortByFunnelName(kanbanViewColumns: Column[], company: ICompany[]) {
  let result:ICompany[] = [];


  kanbanViewColumns.sort((a,b) => a.index - b.index);
  
  for (const column of kanbanViewColumns) {
    result = [...result, ...company.filter(x => x.funnel?.id === column.id)]
  }

  return result;
}