/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	selectFunnelStatus,
	selectTaskFunnels
} from 'features/funnels/funnels.slice'
import {
	selectMeetingNotes,
	selectNoteStatus
} from 'features/notes/redux/notes.slice'
import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { DEFAULT_NOTE_ACTIONS } from 'features/notes/components/NoteItem'
import DashboardLayout from 'components/Dashboard/DashboardLayout'
import Logger from 'lib/logger'
import { NoteAction } from 'features/notes/interfaces/notes.interface'
import NotesList from 'features/notes/components/NotesList'
import TaskFunnelConfiguration from 'features/tasks/services/task-funnels-configuration.service'
import TasksBoard from 'features/tasks/view/TasksBoard'
import { listenToFunnels } from 'features/funnels/funnels.api'
import { listenToMeetingNotes } from 'features/notes/api/notes.api'
import { listenToTasks } from 'features/tasks/tasks.api'
import { useAppSelector } from 'app/hooks'
import usePageTimeTracking from 'hooks/usePageTracking'
import { useSelector } from 'react-redux'

export default function Tasks(): JSX.Element {
	usePageTimeTracking({ pageName: 'Tasks' })

	const { authUser } = useContext(AuthContext)

	const { data, isLoading } = useAppSelector(state => state.tasks)
	const taskFunnelsLoading = useSelector(selectFunnelStatus)
	const taskFunnels = useSelector(selectTaskFunnels)
	const meetingNotes = useSelector(selectMeetingNotes)
	const loadingNotes = useSelector(selectNoteStatus)

	useEffect(() => {
		const unsubscribe = listenToMeetingNotes(authUser?.fund.id as string)
		return () => unsubscribe()
	}, [])

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubscribe = listenToTasks(authUser.fund.id)
		const unsub2 = listenToFunnels(authUser.fund.id)
		return () => {
			unsubscribe()
			unsub2()
		}
	}, [authUser?.fund.id])

	function setupBoardConfiguration() {
		if (!authUser) {
			// toast.error('authUser is undefined or null')
			Logger.error('authUser is undefined or null')
			return
		}

		const funnelConfiguration = new TaskFunnelConfiguration(
			data,
			authUser.fund,
			{
				id: authUser.id,
				name: authUser.name,
				photoUrl: authUser.photoUrl
			}
		)

		funnelConfiguration.setup()
	}

	return (
		<DashboardLayout
			hideHeader={false}
			showSidebar
			headerComponent={
				!taskFunnelsLoading &&
				taskFunnels.length === 0 && (
					<div className='flex h-full items-center gap-x-4 pl-5'>
						<Button variant='dark' onClick={setupBoardConfiguration}>
							Setup Board
						</Button>
					</div>
				)
			}
			title='Tasks'
		>
			<section className='grid-cols-3 divide-x divide-gray-100 xl:grid'>
				<div className='col-span-2'>
					<TasksBoard tasks={data} isLoading={isLoading} />
				</div>
				<div className='relative z-10 hidden bg-white px-4 pt-5 xl:block'>
					<NotesList
						loadingNotes={loadingNotes}
						notes={meetingNotes}
						actions={[...DEFAULT_NOTE_ACTIONS, NoteAction.CreateTask]}
					/>
				</div>
			</section>
			{/* <TasksList tasks={data} isLoading={isLoading} /> */}
		</DashboardLayout>
	)
}
