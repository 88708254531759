import {
	collection,
	deleteDoc,
	doc,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	writeBatch
} from 'firebase/firestore'

import Errorhandler from 'lib/sentry'
import type { ICapitalCall } from '../interfaces/capitalCall.interface'
import Logger from 'lib/logger'
import { firestore } from 'lib/firebase'
// eslint-disable-next-line import/no-cycle
import { setCapitalCalls } from '../redux/capital_calls.slice'
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { store } from 'app/store'

export const capitalCallRef = collection(firestore, 'capital_calls')

export const addCapitalCall = async (
	capitalCall: ICapitalCall
): Promise<ICapitalCall> => {
	const docRef = doc(capitalCallRef, capitalCall.id)
	await setDoc(docRef, capitalCall, { merge: true })
	return capitalCall
}

// Delete Capital Call
export const deleteCapitalCall = async (id: string): Promise<void> => {
	const docRef = doc(capitalCallRef, id)
	await deleteDoc(docRef)
}

export const addCapitalCalls = async (
	capitalCall: ICapitalCall[]
): Promise<ICapitalCall[]> => {
	const batch = writeBatch(firestore)

	for (const data of capitalCall) {
		const docRef = doc(capitalCallRef, data.id)
		batch.set(docRef, data)
	}

	await batch.commit()

	return capitalCall
}

export const updateCapitalCall = async (
	id: string,
	capitalCall: Partial<ICapitalCall>
): Promise<string> => {
	const docRef = doc(capitalCallRef, id)
	await updateDoc(docRef, { ...capitalCall } as any, { merge: true })
	return id
}

export const listenToCapitalCalls = () => {
	const q = query(capitalCallRef)
	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const capitalCalls = querySnapshot.docs.map(d => d.data() as ICapitalCall)
			store.dispatch(setCapitalCalls(capitalCalls))
		},
		error => {
			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}
