/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "context/AuthContext";
import type { IBarChartData } from "../interfaces/reports.interface";
import { VerticalBarChart } from "../components/VerticalBarChart";
import _ from "lodash";
import { listenToCompanies } from "features/companies/api/companies.api";
import { selectCompanies } from "features/companies/redux/companies.slice";
import { useAppSelector } from "app/hooks";

export default function FunnelChart(): JSX.Element {
  const {authUser} = useContext(AuthContext);

  const companies = useAppSelector(selectCompanies)

  const [data, setData] = useState<IBarChartData[]>();

  useEffect(() => {
    if(!authUser?.fund.id) return; 
    
    const unsubscribe = listenToCompanies(authUser.fund.id);

    // eslint-disable-next-line consistent-return
    return () => unsubscribe();
  }, [authUser?.fund.id]);


  useEffect(() => {
    // group companies by stage
    // eslint-disable-next-line unicorn/no-array-reduce, @typescript-eslint/no-explicit-any
    const groupByStage = _.groupBy(companies, "funnel.name");

    const data = Object.keys(groupByStage).map((key) => ({
      name: key,
      value: groupByStage[key].length
    }));

    setData(data as any);
  }, [companies]);

  if(!data) {
    return <div>Loading...</div>
  }
  
	return  (
    <div className="border bg-white border-[#E4E5E8] rounded-md px-4 py-5 min-w-[570px]">
      <div className="font-semibold">
        Funnel
      </div>
      <div>
        <VerticalBarChart data={data} />
      </div>
    </div>
  )
}
