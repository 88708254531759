import { Button } from "components/ui/button";
import { ComboSelect } from "components/shadcn/ComboSelect";
import { DialogModal } from "components/shadcn/DialogModal";
import { Plus } from "lucide-react";
import clsx from "clsx";
import { useState } from "react";

interface Props {
	disabled?: boolean
	title?: string,
	className?: string,
	options?: {label: string, value: string}[],
	onSelect: (value: string) => void
}

export default function AddEmailTag({
	disabled = false,
	title,
	className,
	options,
	onSelect
}: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedPayment, setSelectedPayment] = useState<string>();

	function onClickSelect() {
		if(selectedPayment) {
			onSelect(selectedPayment);
			setSelectedPayment(undefined)
		}

		setIsOpen(false);
	}

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title={title || 'Select Email'}
			description=''
			trigger={
				disabled ? (
					<div />
				) : (
					<Button
						id='open-tag-selector-button'
						variant='ghost'
						className= {clsx(`${className} rounded-md bg-blue-100 px-2 py-0 text-blue-700 text-sm`)} 
						disabled={disabled}
						onClick={() => {
							setIsOpen(true)
						}}
					>
						<Plus size={15} />
						<div>Email</div>
					</Button>
				)
			}
		>
			<div>
			<div className='flex flex-col gap-2 rounded-md border bg-gray-50 p-5'>
				<div className='text-gray-700 block text-xs font-bold mb-2'>Email</div>
				<ComboSelect
					placeholder="Select Email"
					allowSearch
					options={options || []}
					onSelect={(value) => { setSelectedPayment(value); } } 
					defaultValue=""				
				/>
				<div className='mt-5 flex w-full justify-end gap-5'>
					<Button
						variant='ghost'
						className='px-2 py-1 text-red-500'
						onClick={() => setIsOpen(false)}
					>
						Cancel
					</Button>
					<Button
						id='create-tag-button'
						onClick={() => onClickSelect()}
						disabled={!selectedPayment}
					>
						Select
					</Button>
				</div>
			</div>
			</div>
		</DialogModal>
	)
}
