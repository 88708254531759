/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react-hooks/exhaustive-deps */
import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import { Input } from 'components/ui/input'
import { AuthContext } from 'context/AuthContext'
import { UserRole, type ITeamMember, type ILimitedPartner } from 'interfaces'
import { Pencil, PlusCircle } from 'lucide-react'
import { useContext, useEffect, useState } from 'react'
import useUpdateTeamMembers from '../hooks/useUpdateTeamMembers'

export default function AddTeamMember({
	limitedPartner,
	teamMember
}: {
	limitedPartner: ILimitedPartner
	teamMember?: ITeamMember
}) {
	const { authUser } = useContext(AuthContext)

	const [isOpen, setIsOpen] = useState(false)
	const { formik } = useUpdateTeamMembers({
		limitedPartner,
		teamMember,
		onSuccess: () => {
			setIsOpen(false)
		}
	})

	function openModal() {
		setIsOpen(true)
	}

	useEffect(() => {
		if (!isOpen) {
			formik.resetForm()
		}
	}, [isOpen])

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title={teamMember ? 'Edit Team Member' : 'Add Team Member'}
			description={
				teamMember
					? `Edit team member to this limited partner`
					: `Add new team member to this limited partner`
			}
			trigger={
				<Button onClick={openModal} variant='ghost' className=' p-0'>
					{teamMember ? (
						<div>
							<Pencil size={15} />
						</div>
					) : (
						<div
							data-testid='add-company-teamMember-trigger'
							className='group flex cursor-pointer items-center gap-2 rounded-full px-2 transition-all hover:border-blue-200 hover:bg-blue-100 hover:text-blue-700'
						>
							<PlusCircle size={15} />
							<div className='-translate-x-2 opacity-0 transition-all group-hover:translate-x-0 group-hover:opacity-100'>
								Add
							</div>
						</div>
					)}
				</Button>
			}
		>
			<div className=''>
				<div className='overflow-hidden'>
					<div className='relative bg-white'>
						<div className='mb-4'>
							<div className='mb-2 block text-sm font-bold text-gray-700'>
								Name <span className='text-red-500'>*</span>
							</div>
							<Input
								id='teamMember-name'
								name='name'
								type='text'
								value={formik.values.name}
								error={formik.errors.name}
								onChange={formik.handleChange}
							/>
						</div>
						<div className='mb-4'>
							<div className='mb-2 block text-sm font-bold text-gray-700'>
								LinkedIn
							</div>
							<Input
								id='teamMember-linkedIn'
								name='linkedIn'
								type='text'
								value={formik.values.linkedIn}
								onChange={formik.handleChange}
							/>
						</div>
						<div className='mb-4'>
							<div className='mb-2 block text-sm font-bold text-gray-700'>
								Email <span className='text-red-500'>*</span>
							</div>
							<Input
								id='teamMember-email'
								name='email'
								disabled={authUser?.role !== UserRole.GeneralPartner}
								type='email'
								value={
									authUser?.role === UserRole.GeneralPartner
										? formik.values.email
										: "You can't view or edit this"
								}
								error={formik.errors.email}
								onChange={e =>
									formik.setFieldValue(
										'email',
										e.target.value.trim().toLowerCase()
									)
								}
							/>
						</div>
						<Button
							id='add-company-teamMember-submit'
							disabled={formik.isSubmitting || !formik.isValid}
							isLoading={formik.isSubmitting}
							onClick={() => {
								formik.handleSubmit()
							}}
							className='w-fit'
							type='submit'
						>
							{teamMember ? 'Edit' : 'Add'} team member
						</Button>
					</div>
				</div>
			</div>
		</DialogModal>
	)
}
