/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { INotification } from '../interface/notifications.interface'

export interface State {
  data: INotification[]
  isLoading: boolean
  error?: string | null
}

const initialState: State = {
  data: [],
  isLoading: true,
  error: null
}

export const notificationsSlice = createAppSlice({
  name: 'notifications',
  initialState,
  reducers: create => ({
    setNotifications: create.reducer((state, action: PayloadAction<INotification[]>) => {
      state.data = action.payload
      state.isLoading = false
      state.error = null
    })
  }),
  selectors: {
    selectNotifications: notifications => notifications,
  }
})

export const { setNotifications } = notificationsSlice.actions

export const { selectNotifications } = notificationsSlice.selectors
