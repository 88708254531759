import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import CreateNote from 'features/notes/components/CreateNote'
import CreateNoteButton from 'features/notes/components/CreateNoteButton'
import CreateTask from 'features/tasks/view/CreateTask'
import DashboardHeader from './Header'
import { Dialog } from 'components/ui/dialog'
import { DraftNoteService } from 'features/notes/services/draft_notes.service'
import EmailComposer from 'features/mail/components/EmailComposer'
import Iframe from 'react-iframe'
import Sidebar from 'components/Dashboard/Sidebar'
import clsxm from 'utils/clsxm'
import { selectDraftNotes } from 'features/notes/redux/draft_notes.slice'
import { selectMailComposeState } from 'features/mail/redux/mailcompose.slice'
import { selectSelectedFile } from 'features/files/files.slice'
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppSelector } from 'app/hooks'
import { useSelector } from 'react-redux'

interface IProperties {
	children: React.ReactNode
	showSidebar: boolean
	title: string
	hideHeader: boolean
	hideNoteCreation?: boolean
	headerComponent?: React.ReactNode
}

export default function DashboardLayout({
	children,
	hideHeader = false,
	showSidebar = false,
	title,
	hideNoteCreation = false,
	headerComponent
}: IProperties): JSX.Element {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [sidebarState, setSidebarState] = useState<
		'close' | 'minimize' | 'open'
	>('open')
	const { authUser } = useContext(AuthContext)
	const draftNotes = useSelector(selectDraftNotes)

	const { windowState } = useAppSelector(selectMailComposeState)

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubscribe = DraftNoteService.listenToDraftNotes(
			authUser.id,
			authUser.fund.id
		)

		// eslint-disable-next-line consistent-return
		return () => unsubscribe()
	}, [authUser?.fund.id])

	useEffect(() => {}, [windowState])

	const selectedFile = useAppSelector(selectSelectedFile)

	return (
		<>
			{!hideHeader && (
				<DashboardHeader title={title} headerComponent={headerComponent} />
			)}

			{selectedFile && (
				<Dialog>
					<Iframe
						url={selectedFile.url}
						position='absolute'
						width='100%'
						id='myId'
						className='myClassname'
						height='100%'
						styles={{ height: '80%', width: '80%' }}
					/>
				</Dialog>
				// <Iframe
				// 	url={selectedFile.url}
				// 	position='absolute'
				// 	width='100%'
				// 	id='myId'
				// 	className='myClassname'
				// 	height='100%'
				// 	styles={{ height: '80%', width: '80%' }}
				// />
			)}

			<div
				className={`flex w-screen overflow-hidden ${
					hideHeader ? 'pt-0' : 'pt-12'
				}`}
			>
				<Sidebar />

				<div
					className={clsxm([
						`relative z-[1] flex-1 !overflow-x-hidden overflow-y-clip bg-white`,
						!showSidebar && 'pl-0',
						sidebarState === 'minimize' && 'ml-[3.8rem]',
						sidebarState === 'open' && 'ml-[12.2rem]'
					])}
				>
					{children}
				</div>
			</div>

			{!hideNoteCreation && (
				<div className='fixed bottom-0 right-0 z-[9] flex gap-5 pb-5 pr-0'>
					<div className='flex gap-5 pr-14'>
						{draftNotes.map(note => (
							<CreateNote key={note.id} note={note} />
						))}
					</div>
					<div>
						<div className='fixed bottom-0 right-0 flex flex-col gap-2 p-5'>
							<CreateTask />
							<CreateNoteButton />
						</div>
					</div>
				</div>
			)}

			<div className='fixed bottom-0 right-0 z-[9] flex gap-5 pb-5 pr-0'>
				<div className='pr-20'>
					{windowState !== 'close' && <EmailComposer />}
				</div>
			</div>
		</>
	)
}
