import { Link, useNavigate } from 'react-router-dom'

/* eslint-disable react/jsx-handler-names */
import Button from 'components/Buttons/Button'
import Layout from 'components/Layout'
import type { ReactElement } from 'react'
import clsxm from 'utils/clsxm'
import usePageTimeTracking from 'hooks/usePageTracking'

export default function HomePage(): ReactElement {
	const navigate = useNavigate()
	usePageTimeTracking({ pageName: 'Home' })

	return (
		<main className={clsxm('min-h-screen', 'max-w-screen ')}>
			<Layout>
				<section
					className={clsxm(
						'mx-auto max-w-7xl px-4 lg:py-32',
						'items-center md:flex'
					)}
				>
					<div className='flex-1 py-8 xl:py-0'>
						<h1
							className={clsxm(
								'max-w-[479px] text-3xl font-semibold  sm:text-5xl md:text-7xl  lg:max-w-[679px]',
								'mx-auto text-center md:mx-0 md:text-left'
							)}
						>
							<span className='font-normal'>
								Simplify Your VC Fund Management with
							</span>
							<span className=' font-bold text-black'> - Spok.vc</span>
						</h1>
						<Button
							data-testid='get-started'
							onClick={() => {
								navigate('/login')
							}}
							className={clsxm(
								'mx-auto  flex  items-center gap-x-2 rounded-lg px-4 py-2 md:mx-0',
								'mt-6'
							)}
						>
							<span>Get Started</span>
						</Button>
					</div>

					<div className='flex-1'>
						{/* <Image
          src='/images/landing-page/globe.webp'
          alt='clock with puzzle blocks'
          className='relative z-20 mx-auto'
          width={483}
          height={466}
        /> */}
					</div>
				</section>
				<section
					className={clsxm(
						'relative mx-4 mb-40 mt-20 max-w-7xl lg:mx-auto',
						'rounded-3xl',
						'before:absolute before:inset-[2px] before:rounded-[1.5rem]',
						'after:absolute after:-inset-x-2 after:-bottom-2 after:h-[150px]',
						'to-transparent  '
					)}
				>
					<div className='relative z-10'>
						<h2 className='mx-auto w-fit -translate-y-1/2 rounded-full bg-gray-50 px-8 text-center text-xl sm:text-2xl md:text-4xl'>
							Automated Portfolio Tracking
						</h2>
						<ul className='grid gap-8 px-8 py-16 md:grid-cols-2 xl:grid-cols-3'>
							<li className='flex flex-col items-center'>
								<div
									className={clsxm(
										'relative ',
										'before:absolute before:-bottom-1/4 before:left-0 before:rounded-full before:bg-red-300',
										'before_blue-green-gradient mb-[90px] before:z-10 before:h-1/2 before:w-full'
										// 'after:h-[70px] after:w-[197px]  after:rotate-[-14.53deg] after:rounded-full after:bg-red-300',
										// 'after:absolute after:z-20 after:skew-y-6'
									)}
								>
									<img
										src='/images/landing-page/clock.webp'
										alt='clock with puzzle blocks'
										className='relative z-20'
									/>
								</div>
								<p className='text-center text-xl font-normal'>
									Let AI handle the tedious tasks for you
								</p>
							</li>
							<li className='flex flex-col items-center'>
								<div
									className={clsxm(
										'relative ',
										'before:absolute before:-bottom-1/4 before:left-0 before:rounded-full before:bg-red-300',
										'before_blue-green-gradient mb-[90px] before:z-10 before:h-1/2 before:w-full'
										// 'after:h-[70px] after:w-[197px]  after:rotate-[-14.53deg] after:rounded-full after:bg-red-300',
										// 'after:absolute after:z-20 after:skew-y-6'
									)}
								>
									<img
										src='/images/landing-page/magnifying-glass.webp'
										alt='clock with puzzle blocks'
										className='relative z-20'
									/>
								</div>
								<p className='text-center text-xl font-normal'>
									Stay updated on your portfolio companies effortlessly
								</p>
							</li>
							<li className='flex flex-col items-center'>
								<div
									className={clsxm(
										'relative ',
										'before:absolute before:-bottom-1/4 before:left-0 before:rounded-full before:bg-red-300',
										'before_blue-green-gradient mb-[90px] before:z-10 before:h-1/2 before:w-full'
										// 'after:h-[70px] after:w-[197px]  after:rotate-[-14.53deg] after:rounded-full after:bg-red-300',
										// 'after:absolute after:z-20 after:skew-y-6'
									)}
								>
									<img
										src='/images/landing-page/bulb.webp'
										alt='clock with puzzle blocks'
										className='relative z-20'
									/>
								</div>
								<p className='text-center text-xl font-normal'>
									Log in with google to get access to your emails, calendar
									events, and dealflow working together in realtime.
								</p>
							</li>
						</ul>
					</div>
				</section>
				<section>
					<p className='text-muted-foreground mb-20 px-8 text-center text-sm'>
						<Link
							to='/terms-and-conditions'
							className='underline underline-offset-4 hover:text-primary'
						>
							Terms of Service
						</Link>{' '}
						and{' '}
						<Link
							to='/privacy-policy'
							className='underline underline-offset-4 hover:text-primary'
						>
							Privacy Policy
						</Link>
						.
					</p>
				</section>
			</Layout>
		</main>
	)
}
