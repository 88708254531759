/* eslint-disable @typescript-eslint/prefer-destructuring */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	NoteEditorState,
	NoteType,
	type IDraftNote,
	type INote
} from '../interfaces/notes.interface';

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { NotebookPen } from 'lucide-react'
import clsxm from 'utils/clsxm'
import { nanoid } from 'nanoid';
import { useContext } from 'react'
import { store } from 'app/store';
import { addDraftNote, selectDraftNotes } from '../redux/draft_notes.slice';
import { useAppSelector } from 'app/hooks';
import { useLocation } from 'react-router-dom';

export interface Props {
	note?: IDraftNote | INote | { id: string }
}

export default function CreateNoteButton() {
	const { authUser } = useContext(AuthContext)
	const draftNotes = useAppSelector(selectDraftNotes)

	const location = useLocation()

	const getDefaultType = () => {
		const {pathname} = location
		const page = pathname.split('/')[1]

		switch (page) {
			case 'company': {
				return NoteType.Company
			}
			case 'compaies': {
				return NoteType.Company
			}
			case 'contact': {
				return NoteType.Contact
			}
			case 'contacts': {
				return NoteType.Contact
			}
			case 'limited-partner': {
				return NoteType.LimitedPartner
			}
			case 'prospects': {
				return NoteType.LimitedPartner
			}
			case 'limited-partners': {
				return NoteType.LimitedPartner
			}
			default: {
				return NoteType.Company
			}
		}
	}

	return (
		<div className='z-[99]'>
			<Button
				disabled={draftNotes.length === 2}
				
				onClick={() => {
					if (!authUser) return
					const draftNote: IDraftNote = {
						id: nanoid(),
						note: '',
						title: '',
						date: new Date().toISOString(),
						noteType: getDefaultType(),
						creator: {
							id: authUser.id,
							name: authUser.name,
							photoUrl: authUser.photoUrl ?? '',
						},
						windowState: NoteEditorState.RESTORED,
						fund: authUser.fund
					}
					store.dispatch(addDraftNote(draftNote))
				}}
				className={clsxm(
					'group ml-auto flex h-12 w-12 items-center justify-center gap-x-3 rounded-full hover:w-36',
					'transition-all duration-150 ease-in-out',
					'z-50 !shadow-md'
				)}
			>
				<NotebookPen size={17} />{' '}
				{draftNotes.length === 0 ? '' : draftNotes.length}
				<span
					className={clsxm(
						'absolute truncate opacity-0 transition-all delay-0 duration-100 ease-linear group-hover:static',
						'group-hover:opacity-100 group-hover:delay-100'
					)}
				>
					{draftNotes.length < 2 ? 'New Note' : 'Add New Note'}
				</span>
			</Button>

		</div>
	)
}
