import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import type { IBarChartData } from "../interfaces/reports.interface";

interface VerticalBarChartProps {
  data: IBarChartData[],
  height?: number | string,
  width?: number | string
}

export function VerticalBarChart(
  {
    data,
    height = 340,
    width = "100%"
  }: VerticalBarChartProps
) {
  return (
    <div style={{width, height}}>
      <ResponsiveContainer>
        <BarChart
          layout="vertical"
          data={data}
          margin={{
            top: 30,
            right: 20,
            bottom: 10,
            left: 70,
          }}
          barSize={25}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" tick={{fontSize: 14}} tickSize={0} tickMargin={10} />
          <YAxis dataKey="name" type="category" tick={{fontSize: 14}} tickSize={0} tickMargin={10} className="whitespace-nowrap" />
          <Tooltip  />
          <Bar dataKey="value" fill="#2466DD"  />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}