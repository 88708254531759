import ExchangerUI from 'features/currency_exchange/components/ExchangerUI'
import NotificationsInProgressHeader from 'features/notifications/components/NotificationsInProgressHeader'
import SearchEverything from 'features/search/view/SearchEverything'
import { UserNav } from 'components/UserNav'
import WorkSpaceSelector from '../../features/authentication/view/WorkSpaceSelector'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function DashboardHeader({ headerComponent }: any): JSX.Element {
	return (
		<div className='fixed z-[9] flex h-12 w-screen items-center justify-between border-b bg-white'>
			<div className='flex items-center'>
				<WorkSpaceSelector />
				{headerComponent && <div>{headerComponent}</div>}
			</div>
			<div className='flex items-center gap-4'>
				<ExchangerUI />
				<SearchEverything />
				<NotificationsInProgressHeader />
				<UserNav />
			</div>
		</div>
	)
}
