import Button from 'components/Buttons/Button'
import type { FoldersInterface } from '../interface/folders.interface';
import type { ICompany } from 'interfaces';
import FoldersApi from '../api/folders.api';
import { toast } from 'sonner';
import { useState } from 'react';

interface DeleteComponentProps {
	data: FoldersInterface;
	company: ICompany;
	onClose: () => void;
	// getFolders: (parentId: null) => void;
	// lastFolder: FoldersInterface
}

export default function DeleteComponent({ data, company, onClose }: DeleteComponentProps) {
	const foldersApi = FoldersApi();
	const [loading, setLoading] = useState(false);

	// const { folders, getFolders, files, getFiles } = FoldersApi();

	const deleteProcess = async () => {
		try {
			setLoading(true);
			await foldersApi.deleteFolderContents(data.id);
			toast.success('Folder deleted successfully')
			// await foldersApi.getFolders(data.parentId == null ? null : lastFolder.id, company.id);
			// getFolders(data.parentId == null ? null : lastFolder.id, company.id);

			onClose();
			setLoading(false);
		} catch (error) {
			toast.error(error);
			setLoading(false);
		}
	}

	return (
		<div className='p-5 rounded-md'>
			<div className="mb-3">
				<strong>Delete</strong>
			</div>
			<div>Are you sure you want to delete this <span className='font-bold'>{data.name}</span>?</div>
			<div className="mt-5 flex w-full justify-end gap-3">
				<Button
					disabled={loading}
					onClick={() => onClose()}
					variant="danger">Cancel
				</Button>
				<Button
					disabled={loading}
					onClick={deleteProcess}
					isLoading={loading}
				>
					Yes
				</Button>
			</div>
		</div >
	)
}