/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useAppSelector } from 'app/hooks'
import { handleGoogleAuthorization } from 'features/settings/Integrations/helpers/gmail.helper'

export default function MailAuthorizeButton() {
	const { authLoading, authUser } = useAppSelector(
		state => state.authentication
	)

	return (
		<div className='sticky top-0 z-10 bg-white'>
			{!authUser?.googleToken?.refreshToken && !authLoading && (
				<div className=' mt-2 rounded-md border border-gray-200 bg-gray-50 px-5 py-2 text-sm'>
					Emails are out of sync. Authorize spok to access your email to get the
					latest emails.
					<div className='mt-2 flex flex-col'>
						<div
							data-testid='authorize-button'
							className='flex w-fit cursor-pointer items-center justify-center rounded-full border bg-white px-4 py-2 text-gray-800'
							onClick={handleGoogleAuthorization}
						>
							<img
								className='mr-2'
								src='https://developers.google.com/identity/images/g-logo.png'
								alt='Google Logo'
								height='20'
								width='20'
							/>
							<span className='text-sm'>Continue with Google</span>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
