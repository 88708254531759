import { Check, MoveRight } from 'lucide-react'

import Button from 'components/Buttons/Button'
import { ImSpinner10 } from 'react-icons/im'

interface IntegrationCardProps {
	name: string
	description: string
	icon: React.ReactNode
	isConnected: boolean
  isConnecting?: boolean
	connect: () => Promise<void>
	disConnect: () => Promise<void>
}

function IntegrationCard({
	name,
	description,
	icon,
	isConnected,
	isConnecting,
	connect,
	disConnect
}: IntegrationCardProps) {

	return (
		<div className='h-52 w-52 rounded border border-blue-100 bg-blue-50'>
			<div className='flex items-center gap-2 px-3 pt-3'>
				{icon}
				<div>{name}</div>
			</div>
			<div className='mt-2 px-3 text-xs'>{description}</div>

			{isConnected && (
				<div className='mt-5 flex items-center gap-1 px-3 text-sm text-blue-700'>
					<Check size={15} />
					Connected
				</div>
			)}
			{isConnecting && (
				<div className='mt-5 flex items-center gap-1 px-3 text-sm text-blue-700'>
					<ImSpinner10 className='animate-spin' />
					Connecting...
				</div>
			)}
			{!isConnected && (
				<div className='absolute bottom-0 flex w-52 justify-end'>
					<Button
						variant='ghost'
						isLoading={isConnecting}
						className='hover:bg-blue-100'
						onClick={connect}
					>
						Connect <MoveRight size={12} />
					</Button>
				</div>
			)}
			{isConnected && (
				<div className='absolute bottom-0 flex w-52 justify-end'>
					<Button
						variant='ghost'
						isLoading={isConnecting}
						className='text-red-500 hover:bg-red-100'
						onClick={disConnect}
					>
						Disconnect <MoveRight size={12} />
					</Button>
				</div>
			)}
		</div>
	)
}

export default IntegrationCard
