
import type { MultiValue, PropsValue } from 'react-select';
import Select from 'react-select';

export interface SpokMultiSelectOption {
  value: string;
  label: string;
  isDisabled?: boolean
  isFixed?: boolean;
}

interface IProps {
  placeholder?: string,
  defaultValue?: PropsValue<unknown>,
  data: SpokMultiSelectOption[],
  onSelect: (value: MultiValue<unknown>) => void
}
export function SpokMultiSelect(
  {
    placeholder,
    defaultValue,
    data,
    onSelect
  }: IProps
) {

  return (
    <div>
       <Select
          defaultValue={defaultValue}
          isMulti
          options={data}
          placeholder={placeholder}
          onChange={(value) => onSelect(value)}
        />
    </div>
  );
}
