/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CodeMirror from '@uiw/react-codemirror'

import Button from 'components/Buttons/Button'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import { DialogModal } from 'components/shadcn/DialogModal'
import { Input } from 'components/ui/input'
import { AuthContext } from 'context/AuthContext'
import type {
	CapitalCallTemplateData,
	TemplateBankAccount
} from 'features/new-capital-call/interfaces/CapitalCallRequest'
import { useFormik } from 'formik'
import { nanoid } from 'nanoid'
import { useContext, useEffect, useState } from 'react'
import { toast } from 'sonner'
import clsxm from 'utils/clsxm'
import * as Yup from 'yup'
import { CapitalCallTemplateType, type ICapitalCallTemplate } from '../interface/capital_call_template.interface'
import { CapitalCallTemplateService } from '../service/CapitalCallTemplate.service'
import { purifyHtml } from 'utils/purifyHtml'

const bankAccounts: TemplateBankAccount[] = [
	{
		bankName: 'Name of Bank 1',
		bankAddress: 'Address of Bank 1',
		routingNumber: 'Routing Number of Bank 1',
		accountNumber: 'Accouint Number of Bank 1',
		accountName: 'Account name of Bank 1',
		swiftCode: 'Swift Code of Bank 1',
		accountAddress: 'Account Address of Bank 1'
	},
	{
		bankName: 'Name of Bank 2',
		bankAddress: 'Address of Bank 2',
		routingNumber: 'Routing Number of Bank 2',
		accountNumber: 'Accouint Number of Bank 2',
		accountName: 'Account name of Bank 2',
		swiftCode: 'Swift Code of Bank 2',
		accountAddress: 'Account Address of Bank 2'
	}
]

const testData: CapitalCallTemplateData = {
	name: 'Test LP',
	amount: 'US$100,000',
	percentCalled: 10,
	totalAmountCalled: 'US$500,000',
	totalPercentCalled: 50,
	date: new Date().toLocaleDateString(),
	bankAccounts,
	cryptoAccounts: []
}

export default function AddCapitalCallTemplate({
	template,
	trigger,
	onSuccess
}: {
	template?: ICapitalCallTemplate
	trigger?: React.ReactNode
	onSuccess?: (template: ICapitalCallTemplate) => void
}) {
	const [isOpen, setIsOpen] = useState(false)
	const { authUser } = useContext(AuthContext)

	const formik = useFormik({
		initialValues: {
			id: '',
			name: template?.name || '',
			fund: template?.fund || '',
			description: template?.description || '',
			html: template?.html || '',
			testData: template?.testData || testData,
			pdfUrl: template?.pdfUrl || '',
			type: template?.type || CapitalCallTemplateType.BankAcccount,
			thumbnailUrl: template?.thumbnailUrl || ''
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Name is required'),
			html: Yup.string().required('Html is required')
		}),
		onSubmit: async values => {
			if (!authUser?.fund) return

			try {
				formik.setSubmitting(true)

				const templateData: ICapitalCallTemplate = {
					id: template?.id || nanoid(),
					type: values.type,
					fund: authUser.fund,
					name: values.name,
					description: values.description,
					html: values.html,
					testData: values.testData,
					createdAt: new Date().toISOString(),
					lastUpdatedAt: new Date().toISOString()
				}

				await (template
					? CapitalCallTemplateService.updateCapitalCallTemplate(templateData)
					: CapitalCallTemplateService.addCapitalCallTemplate(templateData))

				toast.success(template ? 'Template updated' : 'Template added')

				if (onSuccess) onSuccess(templateData)
			} catch (error: any) {
				toast.error(error.message)
			} finally {
				formik.setSubmitting(false)
				formik.resetForm()
				setIsOpen(false)
			}
		}
	})

	useEffect(() => {
		if (!isOpen) {
			formik.setFieldValue('id', '')
			formik.setFieldValue('name', '')
			formik.setFieldValue('html', '')
			formik.setFieldValue('description', '')
			formik.setFieldValue('testData', {})
			formik.setFieldValue('pdfUrl', '')
			formik.setFieldValue('thumbnailUrl', '')
			formik.resetForm()
		}
	}, [isOpen])

	return (
		<DialogModal
			open={isOpen}
			size='sm:max-w-[80%]'
			setOpen={setIsOpen}
			title={`${template ? 'Edit' : 'Add'} Template`}
			description=''
			trigger={
				trigger ?? (
					<Button
						data-testid='add-contact-button'
						onClick={() => setIsOpen(true)}
						className='whitespace-nowrap'
					>
						{template ? 'Edit Template' : 'Add Template'}
					</Button>
				)
			}
		>
			<div className=''>
				<div className='flex gap-4 w-full'>
					<Input
						onChange={formik.handleChange}
						value={formik.values.name}
						className='flex-1'
						label='Name'
						name='name'
						side="left"
						error={formik.errors.name}
					/>
					<div className='flex gap-4 flex-1'>
						<div>Type</div>
						<div className='flex items-centers'>

							<ComboSelect
								onSelect={(value) => {
									formik.setFieldValue('type', value as CapitalCallTemplateType)
								}}
								defaultValue={formik.values.type}
								options={
									Object.values(CapitalCallTemplateType).map((option) => ({
										value: option,
										label: option
									})) as any
								}
							/>
						</div>
					</div>
				</div>
				<div className='mt-8 grid w-full grid-cols-2 gap-4'>
					<div className=''>
						<p className='mb-2'>Code Edior</p>
						<CodeMirror
							value={formik.values.html}
							height='600px'
							className={clsxm(
								'mt-2 w-full border',
								'whitespace-pre-wrap break-normal',
								'snow-editor rounded-lg !bg-white',
								'minimized-scrollbar flex flex-col',
								'transition-all duration-100'
							)}
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							onChange={(e: any) => {
								formik.setFieldValue('html', e)
							}}
						/>

						<Button
							disabled={formik.isSubmitting || !formik.isValid}
							isLoading={formik.isSubmitting}
							onClick={() => {
								formik.handleSubmit()
							}}
							variant='blue'
							className='mt-8 px-3'
						>
							{template ? 'Update' : 'Save'}
						</Button>
					</div>

					<div className=''>
						<p className='mb-2'>Preview Html</p>
						<div
							className='max-h-[80vh] overflow-y-scroll border p-20'
							dangerouslySetInnerHTML={{ __html: purifyHtml(formik.values.html )}}
						/>
					</div>
				</div>
			</div>
		</DialogModal>
	)
}
