import { File, PlusCircle, Upload } from 'lucide-react'
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'

/* eslint-disable @typescript-eslint/no-misused-promises */
import Button from 'components/Buttons/Button'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import { DialogModal } from 'components/shadcn/DialogModal'
import { FileService } from 'features/files/services/file.service'
import FileUploader from 'features/fileviewers/views/FileUploader'
import type { FundData } from 'interfaces'
import type { IFile } from 'features/files/file.interface'
import type { ISpace } from 'features/spaces/interface/spaces.interface'
import { ImSpinner10 } from 'react-icons/im'
import { Input } from 'components/ui/input'
import Logger from 'lib/logger'
import clsxm from 'utils/clsxm'
import { nanoid } from 'nanoid'
import { selectAuthUser } from 'features/authentication/authentication.slice'
import useCompanyFunnels from '../hooks/useCompanyFunnels'
import useCreateCompany from '../hooks/useCreateCompany'
import useFirebaseStorageUploader from 'features/storage/hooks/useFirebaseStorageUploader'
import { useSelector } from 'react-redux'

interface Props {
	space?: ISpace
}

export default function CreateCompany({ space }: Props) {
	const [isOpen, setIsOpen] = useState(false)
	const { funnels } = useCompanyFunnels()
	const [selectedDeck, setSelectedDeck] = useState<File | null>(null)
	const [uploadingDeck, setUploadingDeck] = useState(false)

	const authUser = useSelector(selectAuthUser)

	const uploadToStorage = useFirebaseStorageUploader()

	const { formik } = useCreateCompany({
		onSuccess: () => {
			setUploadingDeck(false)
			setSelectedDeck(null)
			setIsOpen(false)
		}
	})

	const buttonRef = useRef(null)

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	async function handleSubmit() {
		// let uploadedDeck: FileUploadResult | null = null
		if (selectedDeck !== null) {
			setUploadingDeck(true)

			const fileId = nanoid()

			const uploadedDeck = await uploadToStorage(
				`funds/${authUser?.fund.id}/documents/${fileId}_${selectedDeck.name}`,
				selectedDeck
			)

			const companyId = nanoid()
			
			formik.setFieldValue('id', companyId)

			const fileData: IFile = {
				id: fileId,
				name: selectedDeck.name,
				fileType: 'company_deck',
				url: uploadedDeck.url,
				mimeType: uploadedDeck.type,
				size: uploadedDeck.size ?? 0,
				fund: authUser?.fund as FundData,
				creator: {
					id: authUser?.id as string,
					name: authUser?.name as string,
					photoUrl: authUser?.photoUrl ?? ''
				},
				company: {
					id: companyId,
					name: formik.values.name,
					website: formik.values.website || ''
				},
				source: 'spok',
				createdAt: new Date().toISOString(),
				lastUpdatedAt: new Date().toISOString()
			}

			await FileService.addFile(fileData)

			formik.setFieldValue('deck', {
				id: fileId,
				url: uploadedDeck.url,
				thumbnail: '',
				name: uploadedDeck.name
			})

			formik.setFieldValue('source', 'deck')

			Logger.info(formik.values.deck)
		}

		formik.handleSubmit()
	}

	function handleDeckSelected(files: File[]) {
		setSelectedDeck(files[0])
	}

	function handleEnterKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key === 'Enter') {
			handleSubmit()
		}
	}

	useEffect(() => {
		if (!space) return

		const funnel = funnels.find(f => f.category === space.slug)
		if (!funnel) return

		const funnelData = {
			id: funnel.id,
			name: funnel.name,
			category: funnel.category
		}
		formik.setFieldValue('funnel', funnelData)
	}, [space, funnels])

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title='Create Company'
			description='Enter the name of the Company you want to create'
			dialogClass=''
			trigger={
				<Button
					id='open-create-company-button'
					onClick={openModal}
					variant='blue'
					className='m-2'
				>
					<div className=''>Create Company</div>
					<PlusCircle size={15} />
				</Button>
			}
		>
			<div className=''>
				<div className='flex gap-4'>
					<div className='flex-1'>
						<label
							htmlFor='name'
							className='mb-2 block text-sm font-semibold text-blue-700'
						>
							Name
						</label>
						<Input
							error={formik.errors.name}
							id='name'
							name='company-name'
							placeholder='Enter Company name'
							// className='mt-3'
							value={formik.values.name}
							onKeyDown={handleEnterKeyPress}
							onChange={async e => formik.setFieldValue('name', e.target.value)}
						/>
					</div>
					<div className='flex-1'>
						<div className='mb-2 text-sm font-semibold text-blue-700'>
							Funnel
						</div>
						<ComboSelect
							isReadOnly={false}
							triggerClassName={clsxm(
								'w-full bg-gray-100 px-2 !border border-gray-400'
							)}
							onSelect={value => {
								const funnel = funnels.find(f => f.id === value)
								if (!funnel) return

								const funnelData = {
									id: funnel.id,
									name: funnel.name,
									category: funnel.category
								}
								formik.setFieldValue('funnel', funnelData)
							}}
							defaultValue={formik.values.funnel.id}
							content={
								<div className='flex items-center gap-2 pr-2'>
									<div className='text-sm'>{formik.values.funnel.name}</div>
								</div>
							}
							options={funnels.map(funnel => ({
								value: funnel.id,
								label: funnel.name
							}))}
						/>
					</div>
				</div>
				<div className='mt-4'>
					<label
						htmlFor='company-deck-upload'
						className='mb-2 block  text-sm font-semibold text-blue-700'
					>
						Company deck
					</label>
					<FileUploader
						id='company-deck-upload'
						// onUpload={saveFiles}
						onFilesSelected={handleDeckSelected}
						multiple={false}
						options={
							selectedDeck && (
								<div className='mt-2 flex flex-wrap items-center gap-1 text-xs '>
									<File size={15} className='mt-0.5' />
									{selectedDeck.name}
								</div>
							)
						}
						childPropClass='block'
						child={
							<div
								className={clsxm(
									'flex w-56 flex-col items-center justify-center gap-2 px-2 py-4',
									'rounded border border-dashed border-gray-300',
									'text-gray-500'
								)}
							>
								<Upload size={20} />
								<span className='text-xs'>
									{selectedDeck ? 'Change file' : 'Choose file'}
								</span>
							</div>
						}
					/>
				</div>
				<div className='mt-10 flex w-full justify-end gap-5'>
					{!formik.isSubmitting ||
						(!uploadingDeck && (
							<Button
								id='cancel-create-company-button'
								variant='danger'
								onClick={() => closeModal()}
							>
								Cancel
							</Button>
						))}
					<Button
						id='create-company-button'
						ref={buttonRef}
						disabled={formik.isSubmitting || uploadingDeck || !formik.isValid}
						onClick={handleSubmit}
					>
						{formik.isSubmitting || uploadingDeck ? (
							<div className='flex items-center gap-2'>
								<ImSpinner10 className='animate-spin' /> Creating...
							</div>
						) : (
							'Create'
						)}
					</Button>
				</div>
			</div>
		</DialogModal>
	)
}
