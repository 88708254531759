import { setMailWindowState, setTo } from 'features/mail/redux/mailcompose.slice'

import Button from 'components/Buttons/Button'
import { CompanyService } from '../service/company.service'
import type { ICompany } from 'interfaces'
import { LinkPreview } from 'components/ui/linkpreview'
import { Mail } from 'lucide-react'
import { ShareCompany } from './ShareCompany'
import SpokInlineInputEditor from 'components/shadcn/SpokInlineInputEditor'
import { useAppDispatch } from 'app/hooks'

interface Props {
	company: ICompany
}

export default function CompanyDetailsHeader({ company }: Props) {

	const dispatch = useAppDispatch()

	return (
		<div className='flex items-center p-3 border rounded-md bg-white h-fit'>
			<div className='flex items-center'>
				<img
					src={
						company.website
							? `https://www.google.com/s2/favicons?sz=128&domain_url=${company.website}`
							: '../../images/logo/Logo-4.png'
					}
					alt='profile'
					className='h-[6vw] max-h-[3rem] w-[6vw] max-w-[3rem] rounded-full border'
				/>
				<div className='ml-5'>
					<SpokInlineInputEditor
						id='company-name'
						title='Company Name'
						onSave={value => {
							CompanyService.updateCompany({
								id: company.id,
								key: 'name',
								value,
								company
							})
						}}
						defaultValue={company.name || ''}
						content={
							<div
								data-testid="content-company-name"
								className='text-lg font-bold'
							>
								{company.name}
							</div>
						}
					/>

					<SpokInlineInputEditor
						title='Website'
						id='company-website'
						onSave={value => {
							CompanyService.updateCompany({
								id: company.id,
								key: 'website',
								value,
								company
							})
						}}
						defaultValue={company.website ?? ''}
						content={
							<LinkPreview url={company.website || ''}>
								<div>
									{company.website ? (
										<div className='text-sm text-blue-700'>{company.website}</div>
									) : (
										<div className='flex items-center gap-1 text-sm text-gray-500'>
											Add website{' '}
										</div>
									)}
								</div>
							</LinkPreview>
						}
					/>

				</div>
			</div>

			<div className='ml-5 line-clamp-3 flex-1 text-sm'>
				{company.description}
			</div>
			<div className='flex items-center justify-end px-10 gap-3'>
				<ShareCompany companies={[company]} />
				<Button
					id='open-mail-company-button'
					className='h-10 w-10 rounded-md'
					variant='blue'
					onClick={() => {
						dispatch(setTo(company.founderEmails || []))
						dispatch(setMailWindowState('restore'))
					}}
				>
					<Mail size={20} />
				</Button>
			</div>
		</div>
	)
}
