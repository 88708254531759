/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useAppSelector } from 'app/hooks'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import { Input } from 'components/ui/input'
import { Plus } from 'lucide-react'
import SpokInlineInputEditor from 'components/shadcn/SpokInlineInputEditor'
import clsx from 'clsx'
import { selectIndustries } from '../redux/industries.slice'
import { listenToIndustries } from '../api/industries.api'
import type { IIndustry } from '../interfaces/industries.interface'
import { IndustryService } from '../services/industries.services'

interface Props {
	industries?: any[]
	disabled?: boolean
	onSave?: (value: IIndustry[]) => void
	// onTagDelete?: (value: IIndustry) => void
}

export default function IndustrySelector({
	industries,
	disabled = false,
	onSave
	// onTagDelete
}: Props) {
	const { authUser } = useContext(AuthContext)

	const allIndustries = useAppSelector(selectIndustries)

	const [isOpen, setIsOpen] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')
	const [filteredIndustries, setFilteredIndustries] = useState(allIndustries)
	const [selectedIndustries, setSelectedIndustries] = useState<IIndustry[]>(
		industries ?? []
	)

	function onIndustryClicked(tag: IIndustry) {
		if (selectedIndustries.map(item => item.id).includes(tag.id)) {
			setSelectedIndustries(selectedIndustries.filter(t => t.id !== tag.id))
		} else {
			setSelectedIndustries([...selectedIndustries, tag])
		}
	}

	useEffect(() => {
		if (isOpen) {
			setSelectedIndustries(industries ?? [])
		}
	}, [isOpen])

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubscribe = listenToIndustries()
		return () => {
			unsubscribe()
		}
	}, [])

	useEffect(() => {
		const filtered = allIndustries.filter(
			(industry: IIndustry) =>
				industry.industry &&
				industry.industry.toLowerCase().includes(searchQuery.toLowerCase())
		)
		setFilteredIndustries(filtered)
	}, [searchQuery, allIndustries])

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title='Select Industries'
			description=''
			trigger={
				disabled ? (
					<div />
				) : (
					<Button
						id='open-tag-selector-button'
						variant='ghost'
						className='rounded-md bg-blue-100 px-2 py-0 text-blue-700'
						disabled={disabled}
						onClick={() => {
							setIsOpen(true)
						}}
					>
						<Plus size={15} />
						<div>Add</div>
					</Button>
				)
			}
		>
			<div>
				{/* <AddIndustry /> */}
				<Input
					id='search-tag-input'
					placeholder='Search Industries'
					className='mt-5'
					value={searchQuery}
					onChange={e => setSearchQuery(e.target.value)}
				/>
				<div className='mt-1 flex flex-col gap-2'>
					<div className='flex flex-col gap-2'>
						<div className='mt-5 flex items-center gap-2'>
							<SpokInlineInputEditor
								showEditButton
								isReadOnly={false}
								title='Add new Industry'
								onSave={value => {
									if (!authUser?.fund) return
									if (!value) return

									IndustryService.addIndustry(value)
								}}
								defaultValue=''
								content={<div />}
								editButton={
									<Button variant='blue' className='px-1 py-0 text-xs'>
										<Plus size={12} />
										Add
									</Button>
								}
							/>
						</div>
						<div className='flex flex-wrap gap-2'>
							{filteredIndustries.map(industry => {
								return (
									<div
										data-testid={`industry-option-${industry.industry}`}
										key={industry.id}
										className={clsx(
											'group flex cursor-pointer items-center gap-1  rounded-md border px-2 py-1 text-sm transition-all',
											selectedIndustries
												.map(item => item.id)
												.includes(industry.id) &&
												'border-blue-200 bg-blue-700 text-white hover:bg-opacity-90 hover:text-white',
											!selectedIndustries
												.map(item => item.id)
												.includes(industry.id) &&
												' hover:border-blue-200 hover:bg-blue-100'
										)}
										onClick={() => onIndustryClicked(industry)}
									>
										{industry.industry}
									</div>
								)
							})}
						</div>
					</div>
				</div>
				{allIndustries.length === 0 && (
					<div className='mt-2 text-sm text-gray-500'>No industries added</div>
				)}
				<div className='mt-10 flex w-full justify-end gap-5'>
					<Button
						id='cancel-create-tag-button'
						variant='danger'
						onClick={() => setIsOpen(false)}
					>
						Cancel
					</Button>
					<Button
						id='save-tag-button'
						onClick={() => {
							onSave?.(selectedIndustries)
							setIsOpen(false)
						}}
					>
						Save
					</Button>
				</div>
			</div>
		</DialogModal>
	)
}
