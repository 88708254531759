import type * as React from 'react'

import { Label } from 'components/ui/label'
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'
import { cn } from 'lib/utils'
import { DollarSignIcon, PercentIcon } from 'lucide-react'
import { useState } from 'react'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>

function CapitalTobeCalledInput({
	className,
	error,
	onChange,
	...props
}: InputProps & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error?: any
	isRequired?: boolean
	onChange?: (
		value: number,
		inputType: string,
		changeType: 'input' | 'type'
	) => void
}) {
	const [inputType, setInputType] = useState('dollar')
	const [inputValue, setInputValue] = useState(0)

	function onRadioButtonChange(value: string) {
		setInputType(value)

		if (onChange) onChange(inputValue, value, 'type')
	}

	return (
		<div>
			<RadioGroup
				className='mb-4 flex gap-4'
				defaultValue='dollar'
				onValueChange={onRadioButtonChange}
			>
				<div className='flex items-center space-x-2'>
					<RadioGroupItem value='dollar' id={`"dollar-"${props.id}`} />
					<Label htmlFor={`"dollar-"${props.id}`}>Dollar Amount</Label>
				</div>
				<div className='flex items-center space-x-2'>
					<RadioGroupItem value='percent' id={`"percent-"${props.id}`} />
					<Label htmlFor={`"percent-"${props.id}`}>
						Percent of Total Commitments
					</Label>
				</div>
			</RadioGroup>

			<div className='flex items-center justify-center '>
				<div
					className={cn(
						'h-[36px] border border-r-0 border-inherit px-2',
						error && 'border-red-500'
					)}
				>
					{inputType === 'dollar' ? (
						<DollarSignIcon width={20} height={20} className='mt-[6px]' />
					) : (
						<PercentIcon width={20} height={20} className='mt-[6px]' />
					)}
				</div>

				<input
					type='number'
					defaultValue={inputValue}
					data-testid={props.id}
					className={cn(
						'flex h-9 w-full rounded-md border border-slate-200 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300',
						className,
						error && 'border-red-500',
						'rounded-l-none'
					)}
					{...props}
					onChange={value => {
						setInputValue(Number(value.target.value))
						onChange?.(Number(value.target.value), inputType, 'input')
					}}
				/>
			</div>

			<div>
				{error && <div className='mt-1 text-xs text-red-500'>{error}</div>}
			</div>
		</div>
	)
}

CapitalTobeCalledInput.displayName = 'CapitalTobeCalledInput'

export { CapitalTobeCalledInput }
