import { useEffect, useState } from 'react'

import type { ICompany } from 'interfaces'
import type { ISharedLink } from 'features/companies/interfaces/sharedLink.interface'
import type { ISharedLinkView } from '../interfaces/shared_link_view.interace'
import { SharedLinksService } from '../services/sharedlinks.service'
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { convertStringToNanoID } from 'utils'
import { useLocation } from 'react-router-dom'

export interface TabItem {
	id: string
	label: string
	includeOptionLabel: string
	disabled?: boolean
}

interface TabsProps {
	tabs: TabItem[]
	linkData: ISharedLink
	company: ICompany
	userData: { name?: string; email: string }
	children?: (selectedTabId: string) => JSX.Element
}

export default function HorizontalTabs({
	tabs,
	linkData,
	company,
	userData,
	children
}: TabsProps) {
	const location = useLocation()

	const [selectedTab, setSelectedTab] = useState(tabs[0].id)
	const [startTime, setStartTime] = useState<Date>(new Date())
	const [tabTimes, setTabTimes] = useState<Record<string, number>>({})

	const recordTime = async () => {
		const endTime = new Date()
		const elapsed = (endTime.getTime() - startTime.getTime()) / 1000
		setTabTimes(prevTimes => ({
			...prevTimes,
			[selectedTab]: (prevTimes[selectedTab] || 0) + elapsed
		}))
		setStartTime(new Date())

		// Save the time to the database
		const data: ISharedLinkView = {
			id: await convertStringToNanoID(
				linkData.id + userData.email + selectedTab
			),
			name: selectedTab,
			time: tabTimes[selectedTab] || 0 + elapsed,
			user: { name: userData.name || '', email: userData.email },
			startTime: startTime.toISOString(),
			endTime: endTime.toISOString(),
			linkData: {
				id: linkData.id,
				access: linkData.shareOption,
				url: location.pathname
			},
			company: {
				name: company.name,
				id: company.id,
				website: company.website || ''
			},
			fund: company.fund,
			createdAt: new Date().toISOString()
		}

		SharedLinksService.logSharedLinkView(data)
	}

	const handleTabChange = (tabId: string) => {
		if (tabId !== selectedTab) {
			recordTime()
			setSelectedTab(tabId)
		}
	}

	useEffect(() => {
		const handleVisibilityChange = async () => {
			if (document.visibilityState === 'hidden') {
				await recordTime()
			} else {
				setStartTime(new Date()) // Restart timing when the tab gains focus again
			}
		}

		const handleBeforeUnload = async (e: BeforeUnloadEvent) => {
			await recordTime()
			e.preventDefault()
			// e.returnValue = '' // This will ask the user to confirm leaving the page
		}

		document.addEventListener('visibilitychange', handleVisibilityChange)
		window.addEventListener('beforeunload', handleBeforeUnload)

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange)
			window.removeEventListener('beforeunload', handleBeforeUnload)
			recordTime() // Record the final time on component unmount
		}
	}, [selectedTab])

	useEffect(() => {
		return () => {
			recordTime()
		}
	}, [])

	const filteredTabs = tabs.filter(
		tab =>
			tab.includeOptionLabel === 'all' ||
			linkData.includeOptions.includes(tab.includeOptionLabel)
	)

	return (
		<div>
			<div className='flex items-center'>
				{filteredTabs.map(tab => (
					<div
						key={tab.id}
						onClick={() => handleTabChange(tab.id)}
						className={clsx(
							'cursor-pointer whitespace-nowrap border-b-2 px-4 py-2 font-semibold',
							selectedTab === tab.id && 'border-b-blue-500 text-blue-700'
						)}
						data-testid={`details-tab-${tab.id}`}
					>
						{tab.label}
					</div>
				))}
			</div>
			{children?.(selectedTab)}
		</div>
	)
}
