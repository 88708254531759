/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AppThunk } from 'app/store'
import {
	setCustomFields,
	setCustomFieldsStatus
} from 'features/customfields/customFields.slice'
import type { Unsubscribe } from 'firebase/firestore'
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	where
} from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'
import type { ICustomField } from './interfaces/customfields.interface'

const customFieldsRef = collection(firestore, 'custom_fields')

export const addCustomField = async (
	customField: ICustomField
): Promise<ICustomField> => {
	const docRef = doc(customFieldsRef, customField.id)
	await setDoc(docRef, customField, { merge: true })

	return customField
}

export const getCustomField = async (id: string): Promise<ICustomField> => {
	const docRef = doc(customFieldsRef, id)
	const docSnap = await getDoc(docRef)
	return docSnap.data() as ICustomField
}

export const getCustomFields = async (
	fundId: string
): Promise<ICustomField[]> => {
	const q = query(
		customFieldsRef,
		where('fund.id', '==', fundId),
		where('entity', '==', 'company')
	)
	const querySnapshot = await getDocs(q)
	const customFields = querySnapshot.docs.map(d => d.data() as ICustomField)

	return customFields
}

export const updateCustomField = async (
	customField: ICustomField
): Promise<ICustomField> => {
	const docRef = doc(customFieldsRef, customField.id)
	await updateDoc(docRef, customField as any, { merge: true })

	return customField
}

export const deleteCustomField = async (id: string): Promise<void> => {
	const docRef = doc(customFieldsRef, id)
	await deleteDoc(docRef)
}

export const listenToCustomFields =
	(fundId: string): AppThunk<Unsubscribe> =>
	dispatch => {
		dispatch(setCustomFieldsStatus('loading'))

		const q = query(customFieldsRef, where('fund.id', '==', fundId))
		const unsubscribe = onSnapshot(
			q,
			querySnapshot => {
				const customFields = querySnapshot.docs.map(
					d => d.data() as ICustomField
				)
				dispatch(setCustomFields(customFields))
				dispatch(setCustomFieldsStatus('idle'))
			},
			error => {
				Errorhandler.captureException(error)
				Logger.error(error)
			}
		)

		return unsubscribe
	}
