/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useVirtualizer } from '@tanstack/react-virtual'
import { useAppSelector } from 'app/hooks'
import { AuthContext } from 'context/AuthContext'
import { listenToCompanies } from 'features/companies/api/companies.api'
import {
	getCompanyNames,
	selectCompanies
} from 'features/companies/redux/companies.slice'
import type { ICompany } from 'interfaces'
import { Check, ChevronsUpDown } from 'lucide-react'
import * as React from 'react'
import { useState } from 'react'
import clsxm from 'utils/clsxm'
import { Button } from './ui/button'
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem
} from './ui/command'
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover'
import Logger from 'lib/logger'

interface Option {
	value: string
	label: string
}

interface VirtualizedCommandProps {
	height: string
	options: Option[]
	placeholder: string
	selectedOption: string
	onSelectOption?: (option: string) => void
}

function VirtualizedCommand({
	height,
	options,
	placeholder,
	selectedOption,
	onSelectOption
}: VirtualizedCommandProps) {
	const [filteredOptions, setFilteredOptions] = useState<Option[]>(options)
	const parentRef = React.useRef(null)

	React.useEffect(() => {}, [filteredOptions])

	const virtualizer = useVirtualizer({
		count: filteredOptions.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 35,
		overscan: 5
	})

	const virtualOptions = virtualizer.getVirtualItems()

	Logger.info({ virtualOptions: virtualOptions.length })

	const handleSearch = (search: string) => {
		const newOptions = options.filter(option =>
			option.value.toLowerCase().includes(search.toLowerCase())
		)

		setFilteredOptions(newOptions)
	}

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
			event.preventDefault()
		}
	}

	return (
		<Command shouldFilter onKeyDown={handleKeyDown}>
			<CommandInput onValueChange={handleSearch} placeholder={placeholder} />

			{virtualOptions.length === 0 && (
				<CommandEmpty>No item found.</CommandEmpty>
			)}

			{virtualOptions.length > 0 && (
				<CommandGroup
					ref={parentRef}
					style={{
						height,
						width: '100%',
						overflow: 'auto'
					}}
				>
					<div
						style={{
							height: `${virtualizer.getTotalSize()}px`,
							width: '100%',
							position: 'relative'
						}}
					>
						{virtualOptions.map(virtualOption => (
							<CommandItem
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									height: `${virtualOption.size}px`,
									transform: `translateY(${virtualOption.start}px)`
								}}
								key={filteredOptions[virtualOption.index].value}
								value={filteredOptions[virtualOption.index].value}
								onSelect={onSelectOption}
							>
								<Check
									className={clsxm(
										'mr-2 h-4 w-4',
										selectedOption ===
											filteredOptions[virtualOption.index].value
											? 'opacity-100'
											: 'opacity-0'
									)}
								/>
								{filteredOptions[virtualOption.index].label}
							</CommandItem>
						))}
					</div>
				</CommandGroup>
			)}
		</Command>
	)
}

interface VirtualizedComboboxProps {
	defaultValue?: string
	onSelect: (value?: ICompany) => void
	searchPlaceholder?: string
	width?: string
	height?: string
}

export function VirtualizedCompanySelector({
	defaultValue,
	onSelect,
	searchPlaceholder = 'Search items...',
	width = '400px',
	height = '400px'
}: VirtualizedComboboxProps) {
	const { authUser } = React.useContext(AuthContext)
	const companies = useAppSelector(selectCompanies)
	const options = useAppSelector(getCompanyNames)

	const [open, setOpen] = useState<boolean>(false)
	const [selectedOption, setSelectedOption] = useState<string>(
		defaultValue ?? ''
	)

	React.useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubscribe = listenToCompanies(authUser.fund.id)
		return () => unsubscribe()
	}, [authUser?.fund.id])

	React.useEffect(() => {
		if (!selectedOption) return

		onSelect(companies.find(item => item.name === selectedOption))
	}, [selectedOption])

	if (options.length === 0) return <div />

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant='outline'
					role='combobox'
					aria-expanded={open}
					className='justify-between'
					style={{
						width
					}}
				>
					{selectedOption
						? options.find(option => option === selectedOption)
						: searchPlaceholder}
					<ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
				</Button>
			</PopoverTrigger>
			<PopoverContent className='p-0' style={{ width }}>
				<VirtualizedCommand
					height={height}
					options={options.map(option => ({ value: option, label: option }))}
					placeholder={searchPlaceholder}
					selectedOption={selectedOption}
					onSelectOption={currentValue => {
						setSelectedOption(
							currentValue === selectedOption ? '' : currentValue
						)
						onSelect(companies.find(company => company.name === currentValue))
						setOpen(false)
					}}
				/>
			</PopoverContent>
		</Popover>
	)
}
