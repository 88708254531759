/* eslint-disable @typescript-eslint/prefer-destructuring */
import { store } from 'app/store'
import { collection, limit, onSnapshot, query, where } from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import Errorhandler from 'lib/sentry'
import type { EmailEntityRelation } from '../interface/email_entity_relation'
import { setContact, setEmailEntityRelation } from '../redux/contact.slice'
import { setEmailEntityRelations } from '../redux/contacts.slice'

/* eslint-disable @typescript-eslint/no-extraneous-class */
export class EmailEntityService {
	private static readonly ref = collection(firestore, 'email_entity_relations')

	// Listen to email entity relations
	public static listenToEmailEntityRelations(userEmail: string) {
		const q = query(this.ref, where('user.email', '==', userEmail))

		const unsubscribe = onSnapshot(
			q,
			querySnapshot => {
				const data = querySnapshot.docs.map(
					doc => doc.data() as EmailEntityRelation
				)
				store.dispatch(setEmailEntityRelations(data))
			},
			error => {
				Logger.error('Error getting email entity relations: ', error)
				Errorhandler.captureException(error)
			}
		)

		return unsubscribe
	}

	// Listen to email entity relation
	public static listenToUserEntityRelation(
		userEmail: string,
		entityEmail: string
	) {
		// Find local user entity relation
		const contacts = store.getState().contacts.data
		const contact = contacts.find(item => item.email === entityEmail)
		if (contact) store.dispatch(setContact(contact))

		const q = query(
			this.ref,
			where('user.email', '==', userEmail),
			where('entityEmail', '==', entityEmail),
			limit(1)
		)

		const unsubscribe = onSnapshot(
			q,
			querySnapshot => {
				const docs = querySnapshot.docs.map(
					doc => doc.data() as EmailEntityRelation
				)
				const data = docs[0]
				store.dispatch(setEmailEntityRelation(data))
			},
			error => {
				Logger.error('Error getting shared link entity relation: ', error)
				Errorhandler.captureException(error)
			}
		)

		return unsubscribe
	}
}
