import { doc, getDoc } from 'firebase/firestore'

import { ActivityService } from 'features/activity/service/activity.service'
import Errorhandler from 'lib/sentry'
import type { IActivity } from 'features/activity/interfaces/actvitiy.interface'
import type { IContact } from 'interfaces'
import Logger from 'lib/logger'
import axiosInstance from 'lib/axios'
import { firestore } from 'lib/firebase'
import { getEmailCount } from 'features/mail/api/threads.api'
import { nanoid } from 'nanoid'
/* eslint-disable @typescript-eslint/no-extraneous-class */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'
import { toast } from 'sonner'
import { updateContact } from '../api/contacts.api'

export class ContactService {
	public static getContactById = async (id: string): Promise<IContact | null> => {
		try {
			const ref = doc(firestore, 'contacts', id)
			const contactDoc = await getDoc(ref)
			if (!contactDoc.exists()) return null
			const contact = contactDoc.data() as IContact
			return contact
		} catch (error: any) {
			Logger.error(error)
			Errorhandler.captureException(error)
			return null
		}
	}
	
	public static updateContact = async ({
		id,
		key,
		value,
		contact
	}: {
		id: string
		key: string
		value: any
		contact: IContact
	}): Promise<void> => {
		try {
			await updateContact({ id, [key]: value })

			toast.success('Contact updated successfully')

			const { authUserData, authUserFundData } = store.getState().authentication
			if (!authUserData || !authUserFundData) return

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUserData,
				fund: authUserFundData,
				activity: `updated ${key}`,
				description: `${value}`,
				icon: '',
				summary: contact.name,
				contact: {
					id: contact.id,
					name: contact.name
				},
				createdAt: new Date().toISOString()
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			toast.error(error.mesage ?? 'Failed to update contact')
			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static async getTotalEmails(
		userId: string,
		conatct: IContact
	): Promise<number> {
		try {
			if (!conatct.email) return 0
			const count = await getEmailCount(userId, [conatct.email])
			return count
		} catch (error: any) {
			Logger.error(error)
			Errorhandler.captureException(error)

			return 0
		}
	}

	public static contactExists = async (
		fundId: string,
		email: string
	): Promise<boolean> => {
		// Check if the contact exists
		const { data } = await axiosInstance.get(
			`funds/${fundId}/contacts/${email}/exists`
		)
		return data.data as boolean
	}

	public static syncContactsFromEmail = async (fundId: string): Promise<void> => {
		try {
			toast.loading('Syncing contacts from email')

			await axiosInstance.get(`funds/${fundId}/contacts/extract`)

			toast.dismiss()
			toast.success('Contacts synced successfully')

		} catch (error: any) {
			toast.dismiss()
			toast.error(error.message ?? 'Failed to sync contacts')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}
}
