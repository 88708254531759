import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	onSnapshot,
	query,
	setDoc,
} from 'firebase/firestore'
import { firestore } from 'lib/firebase'

import { Errorhandler } from 'lib/sentry'
import Logger from 'lib/logger'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'
import type { IIndustry } from '../interfaces/industries.interface'
import { setIndustries, setIndustriesError, setIndustriesIsLoading } from '../redux/industries.slice'

const industriesRef = collection(firestore, 'industries')

export const addIndustry = async (industry: IIndustry): Promise<IIndustry> => {
	const docRef = doc(industriesRef, industry.id)
	await setDoc(docRef, industry, { merge: true })

	return industry
}

export const getIndustry = async (id: string): Promise<IIndustry> => {
	const docRef = doc(industriesRef, id)
	const docSnap = await getDoc(docRef)
	return docSnap.data() as IIndustry
}

export const deleteIndustry = async (id: string): Promise<void> => {
	const docRef = doc(industriesRef, id)
	await deleteDoc(docRef)
}

export const listenToIndustries = () => {
	const hasIndustries = store.getState().industries.data.length > 0
	if (!hasIndustries) store.dispatch(setIndustriesIsLoading(true))

	const q = query(industriesRef)
	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const industries = querySnapshot.docs.map(d => d.data() as IIndustry)
			store.dispatch(setIndustries(industries))
		},
		error => {
			store.dispatch(setIndustriesError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}