import {
	AlignVerticalSpaceAround,
	Archive,
	Bell,
	CheckSquare,
	Contact,
	Home,
	Mail,
	MoreVertical,
	Pencil,
	PieChart,
	Trash2
} from 'lucide-react'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger
} from 'components/ui/dropdown-menu'
import { Link, useLocation } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'

import AddNewSpace from 'features/spaces/views/AddNewSpace'
import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import DeleteModal from 'components/DeleteModal'
import type { ISpace } from 'features/spaces/interface/spaces.interface'
import NewSpaceModal from 'features/spaces/views/NewSpaceModal'
import type { ReactNode } from 'react'
import Skeleton from 'components/Skeleton'
import { SpacesService } from 'features/spaces/service/spaces.service'
import type { UserRole } from 'interfaces'
import clsxm from 'utils/clsxm'
import { selectSpaces } from 'features/spaces/redux/spaces.slice'
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useAppSelector } from 'app/hooks'

function Separator() {
	return <div className='h-[0.05rem] w-full bg-gray-200' />
}

function SidebarItem({
	name,
	url,
	icon,
	space,
	notAllowedRoles
}: {
	name: string
	url: string
	icon: ReactNode
	space?: ISpace
	notAllowedRoles?: UserRole[]
}): JSX.Element {
	const { authUser } = useContext(AuthContext)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [sidebarState, setSidebarState] = useState<
		'close' | 'minimize' | 'open'
	>('open')
	const location = useLocation()
	const { pathname } = location
	const [showSpaceUpdateModal, setShowSpaceUpdateModal] = useState(false)

	if (!authUser) return <div />

	if (notAllowedRoles?.includes(authUser.role)) return <div />

	return (
		<div
			className={clsxm(
				'flex cursor-pointer items-center justify-between gap-2',
				'rounded text-sm transition-all hover:bg-[#F3F7FAaa]',
				{
					'bg-gray-200 text-gray-900 hover:bg-gray-300': pathname === url
				}
			)}
		>
			<Link
				to={url}
				className={clsxm(
					'my-1 flex flex-1 cursor-pointer items-center gap-2 px-3 py-1'
				)}
				id='dashboard'
			>
				<div className='flex items-center justify-center duration-200'>
					{icon}
				</div>
				{sidebarState === 'open' && <span className='truncate'>{name}</span>}
			</Link>

			{space && (
				<div className=''>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant='ghost' className='mr-1 p-1'>
								<MoreVertical size={13} color='gray' />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent
							className='w-56'
							align='end'
							side='right'
							forceMount
						>
							<div className='mb-2 ml-1 flex items-center gap-3 border-b pb-2 text-xs font-semibold'>
								<AlignVerticalSpaceAround size={12} />
								{space.name}
							</div>
							<DropdownMenuItem className='cursor-pointer' asChild>
								<button
									type='button'
									onClick={() => setShowSpaceUpdateModal(true)}
								>
									<div className='flex cursor-pointer items-center gap-3 p-1 text-sm hover:bg-gray-100'>
										<Pencil size={13} /> Update Space
									</div>
								</button>
							</DropdownMenuItem>
							{!space.isDefault && (
								<DropdownMenuItem className=' cursor-pointer' asChild>
									<DeleteModal
										deleteButtonId={`deleteSpace-${space.name}`}
										title='Archive Space'
										description={`Are you sure you want to archive ${space.name}?`}
										trigger={
											<div className='text-red-500 flex w-full cursor-pointer items-center gap-3 p-1 text-sm disabled:text-gray-500'>
												<Trash2 color='red' size={13} /> Archive Space
											</div>
										}
										onDelete={() => {
											SpacesService.archiveSpace(space.id)
										}}
									/>
								</DropdownMenuItem>
							)}
						</DropdownMenuContent>
					</DropdownMenu>

					<NewSpaceModal
						workspace={space.workspace}
						space={space}
						show={showSpaceUpdateModal}
						setShow={setShowSpaceUpdateModal}
						trigger={null}
					/>
				</div>
			)}
		</div>
	)
}

function GroupedSidebarItem({
	name,
	children,
	workspace = 'companies',
	canAddNewSpace = false
}: {
	name: string
	children?: ReactNode
	canAddNewSpace?: boolean
	workspace: 'companies' | 'limited-partners' | 'prospects'
}): JSX.Element {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [sidebarState, setSidebarState] = useState<
		'close' | 'minimize' | 'open'
	>('open')

	return (
		<div className='py-1'>
			<Separator />
			<div
				className={clsxm(
					'group my-1 flex items-center gap-2  px-3 py-1.5',
					'rounded-md  text-sm transition-all '
				)}
				id='dashboard'
			>
				{sidebarState === 'open' && (
					<span className='flex flex-1 items-center justify-between text-[0.7rem] uppercase text-gray-600'>
						{name}
					</span>
				)}
			</div>
			{children && <div>{children}</div>}
			{canAddNewSpace && (
				<div className='my-2 px-2'>
					<AddNewSpace workspace={workspace} />
				</div>
			)}
		</div>
	)
}

function ColorDot({ color }: { color: string }): JSX.Element {
	return (
		<div className='h-2 w-2 rounded-full' style={{ backgroundColor: color }} />
	)
}

export default function Sidebar(): JSX.Element {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [sidebarState, setSidebarState] = useState<
		'close' | 'minimize' | 'open'
	>('open')
	const { authUser } = useContext(AuthContext)
	const { data: spaces, isLoading: loadingSpaces } =
		useAppSelector(selectSpaces)

	useEffect(() => {
		if (!authUser?.fund.id) return
		SpacesService.listenToSpaces({ fundId: authUser.fund.id })
	}, [authUser?.fund.id])

	return (
		<div
			data-cy='sidebar'
			className={clsxm(
				'fixed h-[calc(100vh-3rem)]',
				`z-[8] flex flex-col justify-between overflow-y-auto overflow-x-clip border-r`,
				sidebarState === 'minimize' ? 'w-[3.8rem]' : 'w-[12.2rem]'
			)}
		>
			<div className='flex flex-col pt-5'>
				<SidebarItem name='Home' url='/dashboard' icon={<Home size={15} />} />
				<SidebarItem name='Mail' url='/mail' icon={<Mail size={15} />} />
				<SidebarItem
					name='Tasks'
					url='/tasks'
					icon={<CheckSquare size={15} />}
				/>
				<SidebarItem
					name='Notifications'
					url='/notifications'
					icon={<Bell size={15} />}
				/>
				<GroupedSidebarItem
					workspace='prospects'
					name='Investor Workspace'
					canAddNewSpace
				>
					{loadingSpaces && (
						<div className='mb-3 flex flex-col gap-1 px-3'>
							<Skeleton className='h-5 w-96 rounded-md border' />
							<Skeleton className='h-5 w-96 rounded-md border' />
						</div>
					)}
					{spaces
						.filter(space => space.workspace === 'prospects')
						.filter(space => !space.isArchived)
						.map(space => (
							<SidebarItem
								key={space.id}
								name={space.name}
								url={`/${space.workspace}?space=${space.slug}`}
								icon={<ColorDot color={space.color || 'gray'} />}
								space={space}
							/>
						))}
					<SidebarItem
						name='Archived'
						url='/prospects/archived'
						icon={
							<div className='-ml-0.5'>
								<Archive size={14} />
							</div>
						}
					/>
				</GroupedSidebarItem>
				<GroupedSidebarItem
					workspace='companies'
					name='Company Workspace'
					canAddNewSpace
				>
					{loadingSpaces && (
						<div className='mb-3 flex flex-col gap-1 px-3'>
							<Skeleton className='h-5 w-96 rounded-md border' />
							<Skeleton className='h-5 w-96 rounded-md border' />
						</div>
					)}
					{spaces
						.filter(space => space.workspace === 'companies')
						.filter(space => !space.isArchived)
						.map(space => (
							<SidebarItem
								key={space.id}
								name={space.name}
								url={`/${space.workspace}?space=${space.slug}`}
								icon={<ColorDot color={space.color || 'gray'} />}
								space={space}
							/>
						))}
					<SidebarItem
						name='Archived'
						url='/companies/archived'
						icon={
							<div className='-ml-0.5'>
								<Archive size={14} />
							</div>
						}
					/>
				</GroupedSidebarItem>
				<Separator />
				<SidebarItem
					name='Contacts'
					url='/contacts'
					icon={<Contact size={15} />}
				/>
				<SidebarItem
					name='Reports'
					url='/reports'
					icon={<PieChart size={15} />}
				/>
			</div>
		</div>
	)
}
