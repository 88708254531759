import type { IBankAccount } from 'features/bank_accounts/interface/BankAccount.interface'
import type { ICryptoAccount } from 'features/crypto_accounts/interface/cryptoAccount.interface'

export function isCryptoAccount(
	object: IBankAccount | ICryptoAccount
): object is ICryptoAccount {
	return (
		'id' in object &&
		typeof object.id === 'string' &&
		'accountName' in object &&
		typeof object.accountName === 'string' &&
		'description' in object &&
		typeof object.description === 'string' &&
		'walletAddress' in object &&
		typeof object.walletAddress === 'string' &&
		'network' in object &&
		typeof object.network === 'string' &&
		'stableCoin' in object &&
		typeof object.stableCoin === 'string' &&
		'createdAt' in object &&
		typeof object.createdAt === 'string' &&
		'lastUpdatedAt' in object &&
		typeof object.lastUpdatedAt === 'string'
	)
}

export function isBankAccount(
	object: IBankAccount | ICryptoAccount
): object is IBankAccount {
	return (
		'id' in object &&
		typeof object.id === 'string' &&
		'title' in object &&
		typeof object.title === 'string' &&
		'accountName' in object &&
		typeof object.accountName === 'string' &&
		'accountNumber' in object &&
		typeof object.accountNumber === 'string' &&
		'accountAddress' in object &&
		typeof object.accountAddress === 'string' &&
		'bankName' in object &&
		typeof object.bankName === 'string' &&
		'routingNumber' in object &&
		typeof object.routingNumber === 'string' &&
		'swiftCode' in object &&
		typeof object.swiftCode === 'string' &&
		'bankAddress' in object &&
		typeof object.bankAddress === 'string' &&
		'createdAt' in object &&
		typeof object.createdAt === 'string' &&
		'lastUpdatedAt' in object &&
		typeof object.lastUpdatedAt === 'string'
	)
}
