/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { IIndustry } from '../interfaces/industries.interface'

export interface IndustriesSliceState {
	data: IIndustry[]
	isLoading: boolean
	error?: string | null
}

const initialState: IndustriesSliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const industriesSlice = createAppSlice({
	name: 'industries',
	initialState,
	reducers: create => ({
		setIndustries: create.reducer((state, action: PayloadAction<IIndustry[]>) => {
			state.data = action.payload
			state.isLoading = false
		}),
    setIndustriesIsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setIndustriesError: create.reducer((state, action: PayloadAction<string>) => {
			state.error = action.payload
			state.isLoading = false
		}),
	}),
	selectors: {
		selectIndustries: state => state.data,
	}
})

export const { setIndustries, setIndustriesIsLoading, setIndustriesError } = industriesSlice.actions

export const { selectIndustries } = industriesSlice.selectors
