import Button from "components/Buttons/Button";
import { ComboSelect } from "components/shadcn/ComboSelect";
import SpokDateRangePicker from "components/shadcn/SpokDateRangePicker";
import { CircleFadingPlus, DownloadIcon, FilterIcon, MailIcon } from "lucide-react";
import type { DateRange } from "react-day-picker";
import { AI_REPORTS_FUNNEL_SAMPLE_DATA } from "../data/reports.data";
import { funnelToComboSelectOptionsMapper } from "../helper/reports.utils";

interface AiReportsFilterProps {
  onSelectFilter: (value : string) => void
  onSelectDateRange: (value : DateRange | undefined) => void
}

export default function AiReportsFilter(
  {
    onSelectDateRange,
    onSelectFilter
  } : AiReportsFilterProps
): JSX.Element {
	return  (
    <div className="mt-5 flex items-center overflow-auto border bg-[#F5F6F9] border-[#E4E5E8] px-2 py-1 mb-5">
        <div>
          <SpokDateRangePicker iconColor="blue" iconSize={16} onSelect={onSelectDateRange}/>
        </div>
        <div>
          <ComboSelect
                onSelect={onSelectFilter}
                content={
                  <div className='flex items-center gap-1 pr-3 text-gray-700 shadow-none font-semibold'>
                    <FilterIcon size={16} color="blue" />
                    Status
                  </div>
                }
                options={[
                  {label: "none", value: "none"},
                  ...funnelToComboSelectOptionsMapper(AI_REPORTS_FUNNEL_SAMPLE_DATA)
                ]}
          />
        </div>
        <div>
            <Button variant="ghost">
              <CircleFadingPlus size={16} color="blue" />
              Generate New Report
            </Button>
        </div>
        <div>
            <Button variant="ghost">
              <DownloadIcon size={16} color="blue" />

              Download as PDF
            </Button>
        </div>
        <div>
            <Button variant="ghost">
              <MailIcon size={16} color="blue" />

              Share via Email
            </Button>
        </div>
    </div>
  )
}
