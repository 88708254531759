import * as Yup from 'yup'

import type { Dispatch, SetStateAction } from 'react'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { ChevronDown } from 'lucide-react'
import { DialogModal } from 'components/shadcn/DialogModal'
import type { ISpace } from '../interface/spaces.interface'
import { ImSpinner10 } from 'react-icons/im'
import { Input } from 'components/ui/input'
import Logger from 'lib/logger'
import { SPACES_COLORS } from '../data/spaces.data'
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-Logger */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { Sketch } from '@uiw/react-color'
import { SpacesService } from '../service/spaces.service'
import clsx from 'clsx'
import { toast } from 'sonner'
import { useFormik } from 'formik'

interface Props {
	workspace: 'companies' | 'limited-partners' | 'prospects'
	onAddSpace?: (space: ISpace) => void
	space?: ISpace
	trigger?: React.ReactNode
	show?: boolean
	setShow?: Dispatch<SetStateAction<boolean>>
}

export default function NewSpaceModal({
	onAddSpace,
	workspace,
	space,
	trigger,
  show,
  setShow
}: Props) {

	const [showSketch, setShowSketch] = useState(false)
	const { authUser } = useContext(AuthContext)

	function closeModal() {
    if (!setShow) return;

		setShow(false)
	}

	const formik = useFormik({
		initialValues: {
			id: space?.id || '',
			name: space?.name || '',
			funnelCategoryMatch: space?.funnelCategoryMatch || 'all',
			workspace: space?.workspace || workspace,
			slug: space?.slug || '',
			fund: space?.fund || {
				id: authUser?.fund.id || '',
				name: authUser?.fund.name || '',
				slug: authUser?.fund.slug || ''
			},
			description: space?.description || '',
			creator: space?.creator || {
				id: authUser?.id || '',
				name: authUser?.name || '',
				photoUrl: authUser?.photoUrl || ''
			},
			color: space?.color || 'black',
			createdAt: space?.createdAt || '',
			updatedAt: space?.updatedAt || '',
			restrictedEmails: space?.restrictedEmails || [],
			restrictedDomains: space?.restrictedDomains || [],
			whitelistedEmails: space?.whitelistedEmails || [],
			restrictedRoles: space?.restrictedRoles || [],
			whitelistedRoles: space?.whitelistedRoles || [],
			canBeArchived: space?.canBeArchived || false,
			isArchived: space?.isArchived || false,
			canCollapse: space?.canCollapse || false
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Name is required'),
			funnelCategoryMatch: Yup.string().required(
				'Funnel category match is required'
			),
			workspace: Yup.string().required('Workspace is required'),
			description: Yup.string().required('Description is required')
		}),
		onSubmit: async values => {
			try {
				const data: ISpace = {
					id: values.id,
					name: values.name,
					funnelCategoryMatch: values.funnelCategoryMatch,
					workspace: values.workspace,
					slug: values.slug,
					description: values.description,
					creator: values.creator,
					fund: values.fund,
					createdAt: values.createdAt,
					updatedAt: values.updatedAt,
					color: values.color,
					restrictedEmails: values.restrictedEmails,
					restrictedDomains: values.restrictedDomains,
					whitelistedEmails: values.whitelistedEmails,
					restrictedRoles: values.restrictedRoles,
					whitelistedRoles: values.whitelistedRoles,
					canBeArchived: values.canBeArchived,
					isArchived: values.isArchived,
					canCollapse: values.canCollapse
				}

				await (space
					? SpacesService.updateSpace(data)
					: SpacesService.addSpace(data))
				onAddSpace?.(data)
				toast.success('Space added successfully')
				formik.resetForm()
			} catch (error) {
				Logger.error(error)
				toast.error('Failed to add space')
			} finally {
				closeModal()
			}
		}
	})

	function handleEnterKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key === 'Enter') {
			formik.handleSubmit()
		}
	}

	useEffect(() => {
		if (!show) {
			setTimeout(() => {
				formik.resetForm()
				setShowSketch(false)
			}, 100)
		}
	}, [show])

	return (
		<DialogModal
			open={show}
			setOpen={setShow}
			title={
				space
					? 'Edit space'
					: `Add new space for ${workspace.split('-').join(' ')}`
			}
			description='Enter the details of the space you want to create'
			trigger={trigger}
		>
			<div className=''>
				<div className='flex flex-col gap-5'>
					<Input
						label='Name'
						error={formik.errors.name}
						id='name'
						name='space-name'
						placeholder='Enter space name'
						subText='Limit to 15 characters'
						maxLength={15}
						className='mt-0'
						value={formik.values.name}
						onKeyDown={handleEnterKeyPress}
						onChange={async e => formik.setFieldValue('name', e.target.value)}
					/>
					<Input
						label='Description'
						error={formik.errors.description}
						id='description'
						name='space-description'
						placeholder='Enter space description'
						className='mt-0'
						value={formik.values.description}
						onKeyDown={handleEnterKeyPress}
						onChange={async e =>
							formik.setFieldValue('description', e.target.value)
						}
					/>
					{/* Color selector */}
					<div className='flex flex-col gap-2'>
						<div className='text-xs font-semibold'>
							Choose a color for the space
						</div>
						<div className='flex flex-wrap items-center gap-1'>
							{!showSketch &&
								SPACES_COLORS.map(color => (
									<div
										key={color.value}
										className={clsx(
											'h-6 w-6 cursor-pointer rounded-full border transition-all',
											formik.values.color === color.value &&
												'border-2 border-blue-600 p-2'
										)}
										style={{ backgroundColor: color.value }}
										onClick={() => formik.setFieldValue('color', color.value)}
									>
										{formik.values.color === color.value && (
											<div className='h-4 w-4 rounded-full border-2 border-white' />
										)}
									</div>
								))}
							{!showSketch && (
								<Button
									variant='ghost'
									className={clsx(
										'ml-3 h-6 w-6 cursor-pointer rounded-full border p-3 transition-all'
									)}
									onClick={() => {
										setShowSketch(!showSketch)
									}}
								>
									<ChevronDown />
								</Button>
							)}
							{showSketch && (
								<div>
									<Sketch
										disableAlpha
										color={formik.values.color}
										onChange={color => {
											formik.setFieldValue('color', color.hex)
										}}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className='mt-10 flex w-full justify-end gap-5'>
					{!formik.isSubmitting && (
						<Button
							id='cancel-create-space-button'
							variant='danger'
							onClick={() => closeModal()}
						>
							Cancel
						</Button>
					)}
					<Button
						id='create-space-button'
						disabled={formik.isSubmitting || !formik.isValid}
						onClick={() => formik.handleSubmit()}
					>
						{formik.isSubmitting ? (
							<div className='flex items-center gap-2'>
								<ImSpinner10 className='animate-spin' /> Saving...
							</div>
						) : (
							'Save'
						)}
					</Button>
				</div>
			</div>
		</DialogModal>
	)
}
