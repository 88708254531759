import AveragePercentChart from "./AveragePercentChart";
import FunnelChart from "./FunnelChart";
import GeographicDistributionChart from "./GeograpicDistributionChart";
import InvestmentChart from "./InvestmentChart";
import PortfolioInvestmentChart from "./PortfolioInvestmentChart";
import ReportsFirstCheckAndFollowOn from "./ReportsFirstCheckAndFollowOn";
import VisualReportsFilter from "./VisualReportsFilter";

export default function VisualReports(): JSX.Element {
	return  (
    <div>
      <div className="mb-5">
        <VisualReportsFilter/>
      </div>

      <div className="flex flex-wrap gap-5">
        <div className="flex-1 w-[570px]">
          <FunnelChart/>
        </div>
        <div className="grow w-[570px]">
          <PortfolioInvestmentChart/>
        </div>
      </div>
      <div className="flex mt-5 gap-5 flex-wrap">
        <div>
          <GeographicDistributionChart/>
        </div>
        <div className="flex-1 flex flex-col">
          <div className="flex flex-wrap gap-5">
            <div className="flex-1">
              <AveragePercentChart/>
            </div>
            <div className="flex-1">
              <InvestmentChart/>
            </div>
          </div>
          <div className="mt-5 flex-shrink-1 w">
            <ReportsFirstCheckAndFollowOn/>
          </div>
        </div>
      </div>
    </div>
  )
}
