/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Errorhandler from "lib/sentry"
import Logger from "lib/logger"
import axiosInstance from "lib/axios"
import { toast } from "sonner"

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class SearchService {
	public static search = async (query: string) => {
		try {
			const {data} = await axiosInstance.get(`/search?query=${query}`)
      return data.data 
		} catch (error: any) {
			toast.error(error.mesage ?? 'Failed to Run Search Query')

			Logger.error(error)
			Errorhandler.captureException(error)

      return {
				companies: [],
				limitedPartners: [],
				contacts: [],
				prospects: [],
				tags: []
			}
		}
	}

	public static searchMail = async (query: string) => {
		try {
			const {data} = await axiosInstance.get(`/search?resource=emails&query=${query}`)
      return data.data 
		} catch (error: any) {
			toast.error(error.mesage ?? 'Failed to Run Search Query')

			Logger.error(error)
			Errorhandler.captureException(error)

      return { emails: [] }
		}
	}


}
