/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import { getInvitation } from 'features/invitations/invitations.api'
import type { Invitation } from 'interfaces'

export interface InvitationSliceState {
	data: Invitation | null
	isLoading: boolean
	status: 'failed' | 'idle' | 'loading'
	error?: string | null
}

const initialState: InvitationSliceState = {
	data: null,
	status: 'idle',
	error: null,
	isLoading: false
}

export const invitationSlice = createAppSlice({
	name: 'invitation',
	initialState,
	reducers: create => ({
		fetchInvitation: create.asyncThunk(
			async (id: string) => {
				const response = await getInvitation(id)
				return response
			},
			{
				pending: state => {
					state.status = 'loading'
				},
				fulfilled: (state, action) => {
					state.status = 'idle'
					state.data = action.payload
				},
				rejected: (state, action) => {
					state.status = 'failed'
					state.error = action.error.message
				}
			}
		),
		setInvitation: create.reducer(
			(state, action: PayloadAction<Invitation>) => {
				state.status = 'idle'
				state.data = action.payload
			}
		)
	}),
	selectors: {
		selectInvitationState: invitation => invitation
	}
})

export const { fetchInvitation, setInvitation } = invitationSlice.actions

export const { selectInvitationState } = invitationSlice.selectors
