/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-explicit-any */

import 'react-quill/dist/quill.snow.css'

import ReactQuill, { Quill } from 'react-quill'
import { useEffect, useRef, useState } from 'react'

import { ImageActions } from '@xeger/quill-image-actions'
import { ImageFormats } from '@xeger/quill-image-formats'
import Logger from 'lib/logger'
import QuillMention from './QuillMention'
import type React from 'react'

Quill.register('modules/imageActions', ImageActions)
Quill.register('modules/imageFormats', ImageFormats)
interface Props {
	value: string
	onChange: (value: string) => void
	className?: string
	id?: string
}
const formats = [
	'align',
	'background',
	'blockquote',
	'bold',
	'code-block',
	'color',
	'float',
	'font',
	'header',
	'height',
	'image',
	'italic',
	'link',
	'script',
	'strike',
	'size',
	'underline',
	'width'
]
const toolbar = [
	['bold', 'italic', 'underline', 'strike'],
	['blockquote', 'code-block'],
	['link', 'image', 'video', 'formula'],
	[{ header: 1 }, { header: 2 }],
	[{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
	[{ script: 'sub' }, { script: 'super' }],
	[{ indent: '-1' }, { indent: '+1' }],
	[{ direction: 'rtl' }],
	[{ size: ['small', false, 'large', 'huge'] }],
	[{ header: [1, 2, 3, 4, 5, 6, false] }],
	[{ color: [] }, { background: [] }],
	[{ font: [] }],
	[{ align: [] }],
	['clean']
]

const QuillEditor: React.FC<Props> = ({ value, onChange, className, id }) => {
	const quillRef = useRef<any>(null)
	const [suggestions, setSuggestions] = useState<any[]>([])
	const [position, setPosition] = useState<{
		top: number
		left: number
	} | null>(null)

	const atValues = [
		{ id: 1, value: 'Fredrik Sundqvist' },
		{ id: 2, value: 'Patrik Sjölin' }
	]
	const hashValues = [
		{ id: 3, value: 'Fredrik Sundqvist 2' },
		{ id: 4, value: 'Patrik Sjölin 2' }
	]

	useEffect(() => {
		if (quillRef.current) {
			const quill = quillRef.current.getEditor()
			quill.on('text-change', (delta: any, oldDelta: any, source: any) => {
				if (source === 'user') {
					const text = quill.getText()
					const cursorPosition = quill.getSelection()?.index || 0
					const triggerCharacter = text.charAt(cursorPosition - 1)

					if (triggerCharacter === '@' || triggerCharacter === '#') {
						const values = triggerCharacter === '@' ? atValues : hashValues
						showSuggestions(values, cursorPosition - 1)
					} else {
						setSuggestions([])
					}
				}
			})
		}
	}, [])

	const showSuggestions = (values: any[], cursorPosition: number) => {
		const quill = quillRef.current.getEditor()
		const bounds = quill.getBounds(cursorPosition)
		setSuggestions(values)
		setPosition({ top: bounds.top + bounds.height, left: bounds.left })
	}

	const handleSuggestionClick = (suggestion: any) => {
		Logger.info(suggestion)
		const suggestionId = suggestion.id
		const suggestionValue = suggestion.value
		const suggestionType = suggestion.type

		const quill = quillRef.current.getEditor()
		const cursorPosition = quill.getText().length - 2
		const insertValue = `${suggestion.value} `
		const startIndex = cursorPosition + 1

		// Insert the mention value with the desired styling
		quill.insertText(startIndex, insertValue, {
			'background-color': '#0047B3',
			color: 'white'
		})

		// Insert an empty space with default background and text color
		quill.insertText(startIndex + insertValue.length, ' ', {
			'background-color': 'white',
			color: 'black'
		})

		// Set cursor position after the empty space
		quill.setSelection(startIndex + insertValue.length + 1)

		setSuggestions([])
	}

	return (
		<div className='text-editor' style={{ position: 'relative' }}>
			<ReactQuill
				ref={quillRef}
				theme='snow'
				placeholder='Write something...'
				id={id}
				data-testid={id}
				value={value}
				onChange={quillValue => {
					onChange(quillValue)
				}}
				formats={formats}
				modules={{
					imageActions: {},
					imageFormats: {},
					toolbar
				}}
				className={className}
			/>
			<QuillMention
				suggestions={suggestions}
				position={position}
				handleSuggestionClick={handleSuggestionClick}
			/>
		</div>
	)
}

export default QuillEditor
