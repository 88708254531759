/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-danger */
import { CaretSortIcon } from '@radix-ui/react-icons'
import type { ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import Button from 'components/Buttons/Button'
import DeleteModal from 'components/DeleteModal'
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import { Checkbox } from 'components/ui/checkbox'
import { DataTable } from 'features/dataTable/DataTable'
import type { ITableColumn } from 'features/dataTable/interfaces/datatable.interface'
import EmailDialog from 'features/mail/views/EmailDialog'
import Logger from 'lib/logger'
import { MailPlus } from 'lucide-react'
import type { ITask } from '../interafces/task.interface'
import {
	onDeleteTask,
	onUpdateTask,
	onUpdateTaskCompany,
	onUpdateTaskExecutor
} from '../services/tasks.service'
import { updateTask } from '../tasks.api'
import CreateTask from './CreateTask'
import { purifyHtml } from 'utils/purifyHtml'
import SpokInlineInputEditor from 'components/shadcn/SpokInlineInputEditor'
import SpokDatePicker from 'components/shadcn/SpokDatePicker'
import useListenToPartners from 'features/partners/hooks/usePartners.hook'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import CompanySearchSelector from 'features/companies/view/CompanySearchSelector'

interface Props {
	className?: string
	tasks: ITask[]
	isLoading: boolean
}

export default function TasksList({
	className,
	tasks,
	isLoading
}: Props): JSX.Element {
	const { partners } = useListenToPartners({})

	const columns: ColumnDef<ITask>[] = [
		{
			id: 'select',
			header: ({ table }) => (
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && 'indeterminate')
					}
					onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
					aria-label='Select all'
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					className='mr-2'
					checked={row.original.status === 'completed'}
					onCheckedChange={async value => {
						await updateTask({
							id: row.original.id,
							status: value ? 'completed' : 'pending',
							lastUpdatedAt: new Date().toISOString()
						})
					}}
					aria-label='Select row'
				/>
			),
			enableSorting: false,
			enableHiding: false
		},
		{
			accessorKey: 'description',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Task
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div
					className={`flex min-w-[30vw] max-w-[40vw] flex-1 items-center text-ellipsis pr-10 ${
						row.original.status === 'completed' ? 'line-through' : ''
					}`}
				>
					<EmailDialog
						title={row.original.description}
						email={row.original.mail}
						trigger={
							<div
								className={clsx(
									'pointer-events-none mr-2 opacity-0',
									row.original.mail &&
										'pointer-events-auto cursor-pointer opacity-100'
								)}
							>
								<MailPlus size={15} color='blue' />
							</div>
						}
					/>

					<SpokInlineInputEditor
						multiLine
						content={
							<div
								className='line-clamp-3 max-w-[26rem]'
								dangerouslySetInnerHTML={{
									__html: purifyHtml(row.original.description)
								}}
							/>
						}
						onSave={value => {
							onUpdateTask({
								...row.original,
								id: row.original.id,
								description: value || '',
								lastUpdatedAt: new Date().toISOString()
							})
						}}
						defaultValue={row.original.description}
					/>
				</div>
			)
		},
		{
			accessorKey: 'company',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Company
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate lowercase'>
					{row.original.company?.id ? (
						<div>
							<CompanySearchSelector
								label=''
								companyValue
								companyHoverData={row.original.company}
								onSelect={value => {
									const companyData = {
										id: value.id,
										name: value.name,
										website: value.website ?? ''
									}
									onUpdateTaskCompany(row.original.id, {
										company: {
											...companyData,
											lastUpdatedAt: new Date().toISOString()
										}
									})
								}}
							/>
							{/* <CompanyHoverCard company={row.original.company} /> */}
						</div>
					) : (
						<div className='flex items-center gap-2'>
							<div>-</div>
							<CompanySearchSelector
								label=''
								companyValue
								companyHoverData={row.original.company}
								onSelect={value => {
									const companyData = {
										id: value.id,
										name: value.name,
										website: value.website ?? ''
									}
									onUpdateTaskCompany(row.original.id, {
										company: {
											...companyData,
											lastUpdatedAt: new Date().toISOString()
										}
									})
								}}
							/>
						</div>
					)}
				</div>
			)
		},
		{
			accessorKey: 'executor',
			header: ({ column }) => (
				<Button
					className='px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Executor
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div>
					{row.original.executor?.id ? (
						<div>
							<ComboSelect
								isReadOnly={false}
								onSelect={value => {
									const partner = partners.find(f => f.id === value)
									onUpdateTaskExecutor(row.original.id, {
										executor: {
											...partner,
											lastUpdatedAt: new Date().toISOString()
										}
									})
								}}
								defaultValue=''
								content={
									<div className='flex items-center gap-2'>
										<Avatar className='h-6 w-6'>
											<AvatarImage
												src={
													row.original.executor.photoUrl
														? `https://www.google.com/s2/favicons?sz=128&domain_url=${row.original.executor.photoUrl}`
														: '/images/logo/Logo-4.png'
												}
											/>
											<AvatarFallback>C</AvatarFallback>
										</Avatar>
										<div className='truncate'>{row.original.executor.name}</div>
									</div>
								}
								options={partners.map(partner => ({
									value: partner.id,
									label: partner.name
								}))}
							/>
						</div>
					) : (
						<ComboSelect
							isReadOnly={false}
							onSelect={value => {
								const partner = partners.find(f => f.id === value)
								onUpdateTaskExecutor(row.original.id, {
									executor: {
										...partner,
										lastUpdatedAt: new Date().toISOString()
									}
								})
							}}
							defaultValue=''
							content={<div className='flex items-center gap-2'>-</div>}
							options={partners.map(partner => ({
								value: partner.id,
								label: partner.name
							}))}
						/>
					)}
				</div>
			)
		},
		{
			accessorKey: 'dueDate',
			header: ({ column }) => (
				<Button
					variant='ghost'
					className='truncate px-0'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Due Date
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='capitalize'>
					<SpokDatePicker
						defaultValue={new Date(row.original.dueDate)}
						onSelect={value => {
							onUpdateTask({
								...row.original,
								id: row.original.id,
								dueDate: value?.toISOString() || new Date().toISOString(),
								lastUpdatedAt: new Date().toISOString()
							})
						}}
					/>
				</div>
			)
		},
		{
			accessorKey: 'actions',
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			header: ({ column }) => (
				<Button variant='ghost' className='truncate px-0'>
					Actions
				</Button>
			),
			cell: ({ row }) => (
				<div className='flex items-center capitalize'>
					<CreateTask
						task={row.original}
						trigger={
							<Button
								id={`open-edit-task-${row.original.description}`}
								variant='ghost'
								className='truncate px-2 text-blue-700'
							>
								Edit
							</Button>
						}
					/>

					<DeleteModal
						deleteButtonId={`delete-task=${row.original.id}`}
						title='Delete Founder'
						description={`Are you sure you want to delete ${row.original.description}?`}
						trigger={
							<Button
								id={`delete-task-${row.original.id}`}
								data-testid={`delete-task-${row.original.id}`}
								variant='ghost'
								className='cursor-pointer px-2 text-red-500'
							>
								Delete
							</Button>
						}
						onDelete={async () => {
							await onDeleteTask(row.original.id)
						}}
					/>
				</div>
			)
		}
	]

	return (
		<div className={`${className} flex flex-col`}>
			<div className='relative pb-2'>
				<DataTable
					entity='task'
					className='px-5'
					columns={columns}
					data={tasks}
					loading={isLoading}
					filterPlaceholder='Search tasks'
					searchKey='description'
					onColumnUpdate={(updatedColumns: ITableColumn) => {
						Logger.info('Updated columns', updatedColumns)
					}}
					onColumnVisibilityChange={(columnId: string, isVisible: boolean) => {
						Logger.info('Updated columns', columnId, isVisible)
					}}
					isGrouped={false}
				/>
			</div>
		</div>
	)
}
