/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/prefer-destructuring */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */

import type { CompanyData, ContactData, LimitedPartnerData } from 'interfaces'
import { Expand, MinusIcon, NotebookPen, Shrink, X } from 'lucide-react'
import { Fragment, useEffect, useState } from 'react'
import type { IDraftNote, INote } from '../interfaces/notes.interface'
import { NoteEditorState, NoteType } from '../interfaces/notes.interface'

import Button from 'components/Buttons/Button'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import CompanySearchSelector from 'features/companies/view/CompanySearchSelector'
import { CompanyService } from 'features/companies/service/company.service'
import ContactSearchSelector from 'features/contacts/view/ContactSearchSelector'
import { ContactService } from 'features/contacts/services/contact.service'
import { DraftNoteService } from '../services/draft_notes.service'
import { Input } from 'components/ui/input'
import { LPService } from 'features/limited_partners/service/lp.service'
import LimitedPartnerSearchSelector from 'features/limited_partners/views/LmitedPartnerSearchSelector'
import Logger from 'lib/logger'
import QuillEditor from 'features/quilleditor/views/QuillEditor'
import SpokDatePicker from 'components/shadcn/SpokDatePicker'
import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import clsxm from 'utils/clsxm'
import { deleteDraftNoteById } from '../redux/draft_notes.slice'
import { useAppDispatch } from 'app/hooks'
import { useCreateNote } from '../hooks/useCreateNote'
import { useLocation } from 'react-router-dom'

const noteTypes = [
	{ label: 'Company', value: NoteType.Company },
	{ label: 'Limited Partner', value: NoteType.LimitedPartner },
	{ label: 'Contact', value: NoteType.Contact },
	{ label: 'Meeting', value: NoteType.Meeting }
]

export interface Props {
	note: IDraftNote | INote | { id: string }
}

export default function CreateNote({ note }: Props) {
	const dispatch = useAppDispatch()

	// Get the current url and path
	const location = useLocation()

	const [typingTimeout, setTypingTimeout] = useState<number | undefined>()

	function onWindowClose() {
		dispatch(deleteDraftNoteById(note.id))
		if (note.id) DraftNoteService.deleteDratNote(note.id)
	}

	const { formik } = useCreateNote({
		note: note as any,
		onSuccess: () => onWindowClose()
	})

	function handleOnClose() {
		formik.resetForm()
		onWindowClose()
	}

	function onWindowStateChange(state: NoteEditorState) {
		formik.setFieldValue('windowState', state)
		DraftNoteService.updateDraftNote(note.id, {
			windowState: state
		})
	}

	const handleSaveDraft = () => {
		if (!note.id) {
			Logger.error('Note ID is missing')
			return
		}
		if (!formik.values.note) return
		DraftNoteService.updateDraftNote(note.id, {
			id: note.id,
			creator: formik.values.creator,
			fund: formik.values.fund,
			note: formik.values.note,
			title: formik.values.title,
			date: formik.values.date,
			company: formik.values.company ?? ({} as CompanyData),
			limitedPartner:
				formik.values.limitedPartner ?? ({} as LimitedPartnerData),
			contact: formik.values.contact ?? ({} as ContactData),
			windowState: formik.values.windowState,
			noteType: formik.values.noteType
		})
	}

	const handleChange = (value: string) => {
		formik.setFieldValue('note', value)
		// if (typingTimeout) {
		// 	clearTimeout(typingTimeout)
		// }
		// setTypingTimeout(window.setTimeout(handleSaveDraft, 100)) // Save 1/10 second after user stops typing
	}

	useEffect(() => {
		if (typingTimeout) {
			clearTimeout(typingTimeout)
		}
		setTypingTimeout(window.setTimeout(handleSaveDraft, 50)) // Save 1/10 second after user stops typing
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.note, formik.values.title])

	useEffect(() => {
		const getItems = async () => {
			const { pathname } = location
			const page = pathname.split('/')[1]
			const id = pathname.split('/')[2]

			switch (page) {
				case 'company': {
					formik.setFieldValue('noteType', NoteType.Company)
					const company = await CompanyService.getCompanyById(id)

					if (!company) break
					const companyData: CompanyData = {
						id: company.id,
						name: company.name,
						website: company.website ?? ''
					}
					formik.setFieldValue('company', companyData)
					break
				}
				case 'contact': {
					formik.setFieldValue('noteType', NoteType.Contact)

					const contact = await ContactService.getContactById(id)
					if (!contact) break
					const contactData: ContactData = {
						id: contact.id,
						name: contact.name ?? contact.email ?? '',
						email: contact.email ?? ''
					}
					formik.setFieldValue('contact', contactData)
					break
				}
				case 'limited-partner': {
					formik.setFieldValue('noteType', NoteType.LimitedPartner)

					const limitedPartner = await LPService.getLimitedPartnerById(id)
					if (!limitedPartner) break

					const lpData: LimitedPartnerData = {
						id: limitedPartner.id,
						name: limitedPartner.name,
						photoUrl: limitedPartner.photoUrl ?? ''
					}
					formik.setFieldValue('limitedPartner', lpData)
					break
				}

				case 'tasks': {
					formik.setFieldValue('noteType', NoteType.Meeting)
					break
				}

				default: {
					break
				}
			}
		}

		getItems()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleValidation = () => {
		const { noteType, company, limitedPartner, contact } = formik.values

		if (noteType === NoteType.Company && !company) {
			alert('Please select a company')
			return false
		}
		if (noteType === NoteType.LimitedPartner && !limitedPartner) {
			alert('Please select a limited partner')
			return false
		}
		if (noteType === NoteType.Contact && !contact) {
			alert('Please select a contact')
			return false
		}

		return true
	}

	return (
		<div className='z-[99]'>
			{formik.values.windowState === NoteEditorState.CLOSED ? null : (
				<div className={clsxm('z-[999] w-fit sm:bottom-5 sm:right-5')}>
					<Transition
						show={formik.values.windowState === NoteEditorState.MAXIMIZED}
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className={clsxm('fixed inset-0 bg-black bg-opacity-25')} />
					</Transition>

					<div
						className={clsxm(
							'relative  transition-all duration-200',
							'w-screen ',
							formik.values.windowState === NoteEditorState.MAXIMIZED &&
								'sm:w-[60vw]',
							formik.values.windowState === NoteEditorState.RESTORED &&
								'w-[700px]',
							formik.values.windowState === NoteEditorState.MINIMIZED &&
								'w-[20vw]'
						)}
					>
						<Transition
							as='div'
							show
							enter='ease-in-out duration-600'
							enterFrom='opacity-0 translate-y-4'
							enterTo='opacity-100 translate-y-0'
							leave='ease-in-out duration-200'
							leaveFrom='opacity-100 translate-y-0'
							leaveTo='opacity-0 translate-y-2'
							className={clsxm(
								'absolute bottom-0 right-0 z-20 w-full rounded-md bg-white shadow-lg',
								'border border-gray-100',
								'overflow-auto sm:max-h-[95vh]'
							)}
						>
							<header
								className={clsxm(
									'mt-2 flex items-center justify-between',
									'px-4 py-2',
									'sticky top-0 z-20 bg-white'
								)}
							>
								<h1 className='flex items-center gap-2 text-base font-semibold text-blue-700'>
									<NotebookPen className='h-5 w-5' />
									<div className='truncate'>New Note</div>
								</h1>
								<div />
								<div className='flex items-center gap-x-4'>
									{/* Minimize Button */}
									<Button
										variant='ghost'
										className='px-0 py-0'
										onClick={() =>
											onWindowStateChange(NoteEditorState.MINIMIZED)
										}
									>
										<MinusIcon className='h-5 w-5' />
									</Button>

									{/* Maximize / Restore  */}
									{formik.values.windowState === NoteEditorState.MAXIMIZED ? (
										<Button
											variant='ghost'
											className='px-0 py-0'
											onClick={() =>
												onWindowStateChange(NoteEditorState.RESTORED)
											}
										>
											<Shrink size={16} />
										</Button>
									) : (
										<Button
											variant='ghost'
											className='px-0 py-0'
											onClick={() => {
												if (
													formik.values.windowState ===
													NoteEditorState.MINIMIZED
												) {
													onWindowStateChange(NoteEditorState.RESTORED)
													return
												}
												onWindowStateChange(NoteEditorState.MAXIMIZED)
											}}
										>
											<Expand size={16} />
										</Button>
									)}

									{/* Close Button */}
									<Button
										variant='ghost'
										className='px-0 py-0'
										onClick={handleOnClose}
									>
										<X className='h-5 w-5 rounded-full' />
									</Button>
								</div>
							</header>
							<div
								className={clsx(
									'flex-col gap-5 px-5 pb-4 pt-5',
									formik.values.windowState === NoteEditorState.MINIMIZED
										? 'hidden'
										: 'flex'
								)}
							>
								<div className=''>
									<div className='flex items-start gap-4'>
										<div className='flex-1'>
											<Input
												id='note-title'
												label='Title'
												name='title'
												value={formik.values.title}
												onChange={formik.handleChange}
												error={formik.errors.title}
											/>
										</div>
										<div>
											<label className='mb-2 block text-sm font-semibold text-gray-700'>
												Type
											</label>
											<ComboSelect
												className='w-[280px]'
												placeholder='-'
												allowSearch={false}
												defaultValue={formik.values.noteType.toString()}
												options={noteTypes}
												onSelect={value => {
													formik.setFieldValue('noteType', value)
												}}
											/>
										</div>
									</div>

									<div className='mt-4 grid grid-cols-2 gap-4'>
										<div>
											<label className='mb-1 block text-sm font-semibold text-gray-700'>
												Date
											</label>

											<SpokDatePicker
												className='w-full rounded-md border px-2 py-1'
												defaultValue={new Date(formik.values.date)}
												onSelect={date => {
													if (!date) return
													formik.setFieldValue('date', date.toISOString())
												}}
											/>
										</div>

										{formik.values.noteType === NoteType.LimitedPartner && (
											<LimitedPartnerSearchSelector
												label='Select Limited Partner'
												defaultValue={formik.values.limitedPartner?.name}
												onSelect={val => {
													const lpData: LimitedPartnerData = {
														id: val.id,
														name: val.name,
														photoUrl: val.photoUrl ?? ''
													}
													formik.setFieldValue('limitedPartner', lpData)
												}}
											/>
										)}

										{formik.values.noteType === NoteType.Company && (
											<CompanySearchSelector
												label='Select Company'
												defaultValue={formik.values.company?.name}
												onSelect={val => {
													const companyData: CompanyData = {
														id: val.id,
														name: val.name,
														website: val.website ?? ''
													}
													formik.setFieldValue('company', companyData)
												}}
											/>
										)}

										{formik.values.noteType === NoteType.Contact && (
											<ContactSearchSelector
												label='Select Contact'
												defaultValue={formik.values.contact?.name}
												onSelect={val => {
													const contactData: ContactData = {
														id: val.id,
														name: val.name,
														email: val.email ?? ''
													}
													formik.setFieldValue('contact', contactData)
												}}
											/>
										)}
									</div>
								</div>
								<QuillEditor
									id='note-editor'
									className={
										formik.values.windowState === NoteEditorState.MAXIMIZED
											? 'h-[50vh]'
											: 'h-[30vh]'
									}
									value={formik.values.note || ''}
									onChange={handleChange}
								/>
								<div className='mt-16 flex justify-end'>
									<Button
										id='create-note-button'
										disabled={formik.isSubmitting || !formik.isValid}
										isLoading={formik.isSubmitting}
										onClick={() => {
											const shouldProceed = handleValidation()
											if (shouldProceed) formik.handleSubmit()
										}}
										className='py-2'
									>
										Create Note
									</Button>
								</div>
							</div>
						</Transition>
					</div>
				</div>
			)}
		</div>
	)
}
