/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import {
	collection,
	doc,
	getDoc,
	setDoc,
	updateDoc,
	writeBatch
} from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import type {
	ISharedLink,
	ISharedLinkView
} from '../interfaces/sharedLink.interface'

export const sharedLinksRef = collection(firestore, 'shared_links')
export const privateSharedLinksRef = collection(
	firestore,
	'private_shared_links'
)

export const addSharedLink = async (
	data: ISharedLink,
	accessEmails: string[]
): Promise<ISharedLink> => {
	const batch = writeBatch(firestore)

	const docRef = doc(sharedLinksRef, data.id)
	batch.set(docRef, data, { merge: true })

	if (data.access === 'private' && accessEmails.length > 0) {
		const privateDocRef = doc(privateSharedLinksRef, data.id)
		batch.set(
			privateDocRef,
			{ id: data.id, accessEmails, createdAt: data.createdAt },
			{ merge: true }
		)
	}

	await batch.commit()

	return data
}

export const getSharedLink = async (
	id: string
): Promise<ISharedLink | null> => {
	const docRef = doc(sharedLinksRef, id)
	const docSnap = await getDoc(docRef)
	if (!docSnap.exists()) return null
	return docSnap.data() as ISharedLink
}

export const getPrivateSharedLink = async (
	id: string
): Promise<(ISharedLink & { accessEmails: string[] }) | null> => {
	const docRef = doc(privateSharedLinksRef, id)
	const docSnap = await getDoc(docRef)
	if (!docSnap.exists()) return null
	return docSnap.data() as ISharedLink & { accessEmails: string[] }
}

export const addSharedLinkView = async (
	data: ISharedLinkView
): Promise<ISharedLinkView> => {
	const ref = collection(firestore, `shared_links/${data.sharedLinkId}/views`)
	const docRef = doc(ref, data.id)
	await setDoc(docRef, data, { merge: true })

	return data
}

export const updateSharedLinkView = async (
	data: Partial<ISharedLinkView>
): Promise<void> => {
	const ref = collection(firestore, `shared_links/${data.sharedLinkId}/views`)
	const docRef = doc(ref, data.id)
	await updateDoc(docRef, data as any, { merge: true })
}
