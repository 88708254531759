/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useMemo, useState } from 'react'

import Button from 'components/Buttons/Button'
import { Check, Pen } from 'lucide-react'
import CompanyDetailsHeader from 'features/companies/view/CompanyDetailsHeader'
import type { ICompany } from 'interfaces'
import { InvestmentMemoService } from '../service/investment_memo.service'
import ReactMarkdown from 'react-markdown'
import clsxm from 'utils/clsxm'
import SideDrawer from 'components/SideDrawer'
import { Input } from 'components/ui/input'
import ReactQuill from 'react-quill'
import type { IMemo } from '../interfaces/memo.interface'
import { motion } from 'framer-motion'
import markdownToHtml from 'markdown-to-htm'
import { nanoid } from 'nanoid'
import { doc, setDoc } from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import { toast } from 'sonner'
import { useSelector } from 'react-redux'
import { selectAuthUser } from 'features/authentication/authentication.slice'

interface Props {
	company: ICompany
}

export default function InvestmentMemo({ company }: Props): JSX.Element {
	const authUser = useSelector(selectAuthUser)

	const [data, setData] = useState<IMemo[]>([])
	const [investmentMemos, setInvestmentMemos] = useState<IMemo[]>([])

	const [percentage, setPercentage] = useState<number>(0)
	const [isUpdatingMemo, setIsUpdatingMemo] = useState(false)
	const [selectedMemo, setSelectedMemo] = useState<IMemo | null>(null)
	const [showMemoEditorDrawer, setShowMemoEditorDrawer] = useState(false)

	useEffect(() => {
		const callback = (data: any) => {
			if (data !== null) {
				if (data.percentage) {
					setPercentage(data.percentage * 100)
				}
				setData((data.data?.sections ?? []) as IMemo[])
			}
		}

		const unsbscribe = InvestmentMemoService.listenToInvestmentMemoSections(
			company.id,
			callback
		)

		return () => unsbscribe()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [company.id, company.oracleData])

	useEffect(() => {
		const unsubscribeFromCompanyMemos =
			InvestmentMemoService.listenToCompanyInvestmentMemos(company.id, data => {
				setInvestmentMemos(data)
			})

		return () => {
			unsubscribeFromCompanyMemos()
		}
	}, [company.id])

	async function setupMemoForEditing(memo: IMemo) {
		const temp = structuredClone(memo)

		if (temp.contentType === 'html') {
			temp.content = temp.content
				.replaceAll('<p><br></p>', '<br/><br/>')
				.replaceAll(/<\/p>(?!<br\/?>)/gi, '</p><br/>')
		} else {
			temp.contentType = 'html'

			temp.content = markdownToHtml(
				memo.content.replaceAll(/#{4,}/gi, '###').replaceAll(/-\s/g, '')
			).replaceAll(/\*+/g, '')
		}

		setSelectedMemo(temp)
		setShowMemoEditorDrawer(true)
	}

	function handleMemoChange(field: keyof IMemo, update: string) {
		if (selectedMemo === null) return
		setSelectedMemo(prev => ({ ...prev, [field]: update }) as IMemo)
	}

	async function updateMemo() {
		setIsUpdatingMemo(true)
		const toastId = toast.loading('Updating...')

		try {
			const MEMO_ID = selectedMemo?.id ?? nanoid()
			const memoDoc = doc(firestore, `investment_memos/${MEMO_ID}`)

			await setDoc(
				memoDoc,
				{
					...selectedMemo,
					company: {
						id: company.id,
						name: company.name,
						website: company.website ?? '',
						funnel: company.funnel
					},
					fund: authUser?.fund,
					id: MEMO_ID
				},
				{ merge: true }
			)
			setShowMemoEditorDrawer(false)
			setSelectedMemo(null)
			toast.success('Memo updated')
		} catch {
			toast.error('Failed to update memo')
		} finally {
			toast.dismiss(toastId)
			setIsUpdatingMemo(false)
		}
	}

	const memos = useMemo(() => {
		return data.map(memo => {
			const memoIndex = investmentMemos.findIndex(
				iMemo => iMemo.key === memo.key
			)

			return memoIndex > -1 ? investmentMemos[memoIndex] : memo
		})
	}, [investmentMemos, data])

	return (
		<div className='sticky top-0 flex h-[calc(100vh-3rem)] flex-wrap justify-between gap-2 bg-gray-50 px-2 pb-0 pr-0'>
			{/* Left Sidebar */}
			<div className='flex h-full flex-1 flex-col gap-5 overflow-auto bg-gray-50 py-2'>
				<CompanyDetailsHeader company={company} />
				<div className='rounded-md border bg-white'>
					<div className='flex justify-between  px-6 pb-2 pt-4'>
						<p className='text-xl font-semibold'>Memorandum</p>

						{percentage > 0 && percentage !== 100 && (
							<div className='flex items-center gap-2 text-sm'>
								<div>Processing:</div>
								<div className='font-semibold text-green-500'>
									{percentage.toFixed(2)}%
								</div>
							</div>
						)}

						{!company.oracleData && (
							// eslint-disable-next-line @typescript-eslint/no-unsafe-return
							<Button
								className='py-2 text-sm'
								onClick={() => {
									if (!company.deck) alert('Please upload a deck first')
									InvestmentMemoService.generateInvestmentMemo(
										company.id,
										company.name
									)
								}}
							>
								Generate Memo
							</Button>
						)}
					</div>

					<div className='grid  gap-5 p-5 xl:grid-cols-2'>
						{/* {investmentMemos.map(memo => {
							return (
								<div
									key={memo.title}
									className={clsxm('rounded border p-4', {
										// blur: selectedMemo
									})}
								>
									<div
										className={clsxm(
											'flex justify-between gap-2 text-sm',
											' font-semibold text-green-500'
										)}
									>
										<div className={clsxm('flex gap-2')}>
											<div className='flex h-5 w-5 items-center justify-center rounded bg-green-500'>
												<Check size={16} className='text-white' />
											</div>
											{memo.title}
										</div>
										<motion.button
											type='button'
											aria-label='edit'
											whileHover={{ scale: 1.3 }}
											onClick={() => setupMemoForEditing(memo)}
										>
											<Pen size={12} />
										</motion.button>
									</div>

									<div className='mt-4'>
										{memo.contentType === 'markdown' || !memo.contentType ? (
											<ReactMarkdown>{memo.content}</ReactMarkdown>
										) : (
											<div dangerouslySetInnerHTML={{ __html: memo.content }} />
										)}
									</div>
								</div>
							)
						})} */}
						{memos.map(memo => {
							return (
								<div
									key={memo.title}
									className={clsxm('rounded border p-4', {
										// blur: selectedMemo
									})}
								>
									<div
										className={clsxm(
											'flex justify-between gap-2 text-sm',
											' font-semibold text-green-500'
										)}
									>
										<div className={clsxm('flex gap-2')}>
											<div className='flex h-5 w-5 items-center justify-center rounded bg-green-500'>
												<Check size={16} className='text-white' />
											</div>
											{memo.title}
										</div>
										<motion.button
											type='button'
											aria-label='edit'
											whileHover={{ scale: 1.3 }}
											onClick={() => setupMemoForEditing(memo)}
										>
											<Pen size={12} />
										</motion.button>
									</div>

									<div className='mt-4'>
										{memo.contentType === 'markdown' || !memo.contentType ? (
											<ReactMarkdown>{memo.content}</ReactMarkdown>
										) : (
											<div dangerouslySetInnerHTML={{ __html: memo.content }} />
										)}
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>

			<SideDrawer
				show={showMemoEditorDrawer}
				title='Update memo'
				dialogPanelClass='max-w-lg'
				setShow={setShowMemoEditorDrawer}
				slideFrom='right'
			>
				<div className='grid gap-4'>
					<div>
						<label htmlFor='memo-title'>Title</label>
						<Input
							placeholder='Title'
							id='memo-title'
							value={selectedMemo?.title}
							onChange={e => handleMemoChange('title', e.target.value.trim())}
							className='border-gray-300 placeholder:text-xs placeholder:italic'
						/>
					</div>

					<ReactQuill
						placeholder='memorandum content'
						defaultValue={selectedMemo?.content}
						onChange={e => handleMemoChange('content', e)}
					/>

					<Button
						type='button'
						isLoading={isUpdatingMemo}
						className='ml-auto mt-10 w-fit'
						variant='dark'
						onClick={updateMemo}
					>
						Update
					</Button>
				</div>
			</SideDrawer>
			{/* Middle Spacer */}
			<div className='h-full w-[0.1rem] bg-gray-200' />
			{/* Right Sidebar */}
			{/* <div className='flex h-full rounded-md py-2 lg:w-[35vw]'>Hello</div> */}
		</div>
	)
}
