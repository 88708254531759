import { ROUNDS, STAGE } from 'features/investments/data/data'
import { useEffect, useState } from 'react'

import Button from 'components/Buttons/Button'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import type { CompanyData } from 'interfaces'
import { DialogModal } from 'components/shadcn/DialogModal'
import FileSelector from 'components/FileSelector'
import FileViewer from 'features/fileviewers/views/FileViewer'
import type { IInvestment } from '../interfaces/investment.interface'
import { Input } from 'components/ui/input'
import { Plus } from 'lucide-react'
import SpokDatePicker from 'components/shadcn/SpokDatePicker'
import { selectCurrencyOptions } from 'features/authentication/authentication.slice'
import { toast } from 'sonner'
/* eslint-disable radix */
import { useAppSelector } from 'app/hooks'
import useCreateInvestment from '../hooks/useCreateInvestment'

interface Props {
	investment?: IInvestment
	trigger: React.ReactNode
	company: CompanyData
}

export default function NewInvestment({ investment, trigger, company }: Props) {
	const currencyOptions = useAppSelector(selectCurrencyOptions)
	const [isOpen, setIsOpen] = useState(false)

	const { formik } = useCreateInvestment({
		investment,
		company,
		onSuccess: () => setIsOpen(false)
	})

	useEffect(() => {
		if(formik.values.amountRaised < formik.values.investmentAmount){
			formik.setFieldError('amountRaised', 'Amount raised must be greater than or equal to investment amount')
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values, formik])

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title={investment ? 'Edit Investment' : 'Create Investment'}
			description={
				investment
					? `Edit Investment for ${company.name}`
					: `Create Investment for ${company.name}`
			}
			trigger={
				trigger || (
					<Button className='h-fit text-xs' onClick={() => setIsOpen(true)}>
						<Plus size={14} />
						New Round
					</Button>
				)
			}
		>
			<div className=''>
				<div>
					<SpokDatePicker
						label='Date'
						defaultValue={new Date(formik.values.date)}
						onSelect={date => {
							if (!date) return
							formik.setFieldValue('date', date.toISOString())
						}}
					/>

					<div className='mt-5 flex flex-wrap items-center gap-4'>
						<ComboSelect
							label='Currency'
							onSelect={value => {
								formik.setFieldValue('currency', value)
							}}
							defaultValue={formik.values.currency}
							options={currencyOptions}
							content={
								<div className='mr-3 text-base font-semibold text-blue-700'>
									{formik.values.currency}
								</div>
							}
						/>

						<ComboSelect
							label='Stage'
							onSelect={value => {
								formik.setFieldValue('stage', value)
							}}
							defaultValue={formik.values.currency}
							options={STAGE.map(stage => ({
								label: stage,
								value: stage
							}))}
							content={
								<div className='mr-3 text-base font-semibold text-blue-700'>
									{formik.values.stage}
								</div>
							}
						/>

						<ComboSelect
							label='Convertible Security'
							onSelect={value => {
								formik.setFieldValue('round', value)
							}}
							defaultValue={formik.values.round}
							options={ROUNDS.map(round => ({
								label: round,
								value: round
							}))}
							content={
								<div className='mr-3 text-base font-semibold text-blue-700'>
									{formik.values.round}
								</div>
							}
						/>
						<ComboSelect
							label='Level'
							onSelect={value => {
								formik.setFieldValue('level', value)
							}}
							defaultValue={formik.values.level}
							options={['pre', 'post'].map(level => ({
								label: level,
								value: level
							}))}
							content={
								<div className='mr-3 text-base font-semibold capitalize text-blue-700'>
									{formik.values.level}
								</div>
							}
						/>
					</div>

					<div className='mt-5 flex items-center gap-5'>
						<Input
							error={formik.touched.valuation && formik.errors.valuation}
							id='input-valuation'
							name='valuation'
							label='Valuation'
							placeholder='Amount'
							value={formik.values.valuation.toString()}
							type='number'
							onChange={async e =>
								formik.setFieldValue(
									'valuation',
									Number.parseInt(e.target.value)
								)
							}
						/>
						<Input
							error={
								formik.touched.investmentAmount &&
								formik.errors.investmentAmount
							}
							id='input-investmentAmount'
							name='investmentAmount'
							label='Investment Amount'
							placeholder='Amount'
							type='number'
							value={formik.values.investmentAmount.toString()}
							onChange={async e => {
								const val = Number.parseInt(e.target.value)
								formik.setFieldValue('investmentAmount', val)

								if((formik.values.amountRaised || 0) < val){
									formik.setFieldValue('amountRaised', val)
								}
							}}
							
						
						/>
						<Input
							error={formik.touched.amountRaised && formik.errors.amountRaised}
							id='input-amountRaised'
							name='amountRaised'
							label='Amount Raised'
							placeholder='Amount'
							type='number'
							value={formik.values.amountRaised.toString()}
							onChange={e =>
								formik.setFieldValue(
									'amountRaised',
									Number.parseInt(e.target.value)
								)
							}
						/>
						
						<Input
							error={formik.touched.discount && formik.errors.discount}
							id='input-discount'
							name='discount'
							label='Discount'
							placeholder='Discount'
							type='number'
							value={formik.values.discount.toString()}
							onChange={async e =>
								formik.setFieldValue(
									'discount',
									Number.parseInt(e.target.value)
								)
							}
						/>
					</div>
				</div>

				<div className='mt-3 flex flex-col gap-2'>
					{formik.values.files.map(file => (
						<FileViewer
							readonly
							key={file.id}
							file={file}
							variant='list'
							onDelete={item => {
								formik.setFieldValue(
									'files',
									formik.values.files.filter(f => f.id !== item.id)
								)
							}}
						/>
					))}
				</div>
				<FileSelector
					className='mt-3'
					onSelect={files => {
						formik.setFieldValue('newFiles', files)
					}}
				/>

				<div className='mt-10 flex w-full justify-end gap-5'>
					{!formik.isSubmitting && (
						<Button
							id='cancel-create-investment'
							variant='danger'
							onClick={() => {
								setIsOpen(false)
							}}
						>
							Cancel
						</Button>
					)}
					<Button
						isLoading={formik.isSubmitting}
						id='create-investment'
						disabled={formik.isSubmitting}
						onClick={() => {
							if(!formik.isValid) {
								const errors = Object.keys(formik.errors)
								alert(formik.errors[errors[0]] as string)
								return;
							}
							formik.handleSubmit()}}
					>
						Save
					</Button>
				</div>
			</div>
		</DialogModal>
	)
}
