/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger
} from 'components/ui/hover-card'
import { Tags as TagIcon, Trash2 } from 'lucide-react'
import { getColorCode } from 'utils/colors'
import DeleteModal from 'components/DeleteModal'
import type { IndustryData } from '../interfaces/industries.interface'

export default function Industries({
	industries,
	wrap = false,
	className,
	maxNumber = 3,
	variant = 'compact',
	slice = true,
	onIndustryDelete
}: {
	industries: IndustryData[]
	wrap?: boolean
	className?: string
	maxNumber?: number
	variant?: 'compact' | 'default'
	slice?: boolean
	onIndustryDelete?: (industry: IndustryData) => void
}) {
	if (industries.length === 0)
		return (
			<div>
				{variant === 'compact' ? (
					<div className='text-xs' />
				) : (
					<div
						data-testid='no-tag-added-label'
						className='flex items-center gap-1 truncate rounded border bg-gray-50 px-3 text-sm'
					>
						<TagIcon size={15} />
						No Industry added
					</div>
				)}
			</div>
		)
	if (typeof industries === 'string')
		return <div className='flex gap-1 truncate'>-</div>

	return (
		<div
			className={`flex items-center gap-1 truncate
      ${wrap ? 'flex-wrap' : ''}
      ${className}
    `}
		>
			{industries
				.filter((_, index) => index < maxNumber)
				.map(industry => (
					<HoverCard key={industry.id} openDelay={100} closeDelay={100}>
						<HoverCardTrigger asChild>
							<div
								style={{
									backgroundColor: `#${getColorCode(
										industry.industry || ''
									)}22`,
									color: `#${getColorCode(industry.industry || '')}`,
									borderWidth: 1,
									borderColor: `#${getColorCode(industry.industry || '')}33`
								}}
								className='group flex cursor-pointer items-center gap-1 text-ellipsis rounded px-1 py-[1px] text-[0.7rem]'
							>
								<div data-testid={`industry-${industry.industry}`}>
									{industry.industry && industry.industry.length > 8 && slice
										? `${industry.industry.slice(0, 8)}...`
										: industry.industry || ''}
								</div>

								{onIndustryDelete && (
									<DeleteModal
										deleteButtonId={`deleteindustry-${industry.industry}`}
										title='Delete Industry'
										description={`Are you sure you want to delete ${industry.industry}?`}
										trigger={
											<Trash2
												data-testid={`deleteIndustry-${industry.industry}`}
												size={12}
												className='transform cursor-pointer opacity-30 transition-all duration-200 hover:scale-110 hover:text-red-500 group-hover:opacity-100'
											/>
										}
										onDelete={() => onIndustryDelete(industry)}
									/>
								)}
							</div>
						</HoverCardTrigger>
						<HoverCardContent className='w-fit'>
							<div className='flex justify-between space-x-4'>
								<div className='space'>
									<p className='text-sm'>{industry.industry}</p>
								</div>
							</div>
						</HoverCardContent>
					</HoverCard>
				))}

			{industries.length === 0 && <div className='text-xs' />}

			{industries.length > maxNumber && (
				<HoverCard openDelay={100} closeDelay={100}>
					<HoverCardTrigger asChild>
						<div className='w-5 max-w-10 text-ellipsis text-xs'>
							+{industries.length - maxNumber}
						</div>
					</HoverCardTrigger>
					<HoverCardContent className=' flex w-96 flex-wrap gap-2'>
						{industries
							.filter(item => item.industry && item.industry.length > 0)
							.map(industry => (
								<div
									key={industry.id}
									style={{
										backgroundColor: `#${getColorCode(industry.industry)}22`,
										color: `#${getColorCode(industry.industry)}`,
										borderWidth: 1,
										borderColor: `#${getColorCode(industry.industry)}33`
									}}
									className=' w-fit text-ellipsis rounded px-1 py-[1px] text-xs'
								>
									{industry.industry}
								</div>
							))}
					</HoverCardContent>
				</HoverCard>
			)}
		</div>
	)
}
