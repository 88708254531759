import { useContext, useEffect } from 'react'

import AddEditContact from '../components/AddEditContacts'
import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { ContactService } from '../services/contact.service'
import { DataTable } from 'features/dataTable/DataTable'
import type { IContact } from 'interfaces'
import type { ITableColumn } from 'features/dataTable/interfaces/datatable.interface'
import Logger from 'lib/logger'
import { listenToColumns } from 'features/columns/columns.api'
import { selectContactColumns } from 'features/columns/column.slice'
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useAppSelector } from 'app/hooks'
import useDataTableHook from 'features/dataTable/hooks/useDataTableHook'

interface IAllContactsTableProps {
	className?: string
	contacts: IContact[]
	loading?: boolean
}

export default function ContactList({
	className,
	contacts,
	loading
}: IAllContactsTableProps): JSX.Element {
	const { authUser } = useContext(AuthContext)
	const columnsData = useAppSelector(selectContactColumns)
	const { columns, columnData } = useDataTableHook({
		columnData: columnsData,
		detailsRoute: 'contact'
	})

	useEffect(() => {
		if (!authUser?.fund) return
		const unsubscribe = listenToColumns(authUser.fund.id)
		return () => unsubscribe()
	}, [authUser?.fund])

	function CustomHeaders() {
		return (
			<div className='mx-4 flex gap-4'>
				<Button
					className='truncate px-5'
					variant="blue"
					onClick={() => ContactService.syncContactsFromEmail(authUser?.fund.id as string)}
				>
					Sync Contacts from email
				</Button>

			</div>
		);
	}

	return (
		<div className={`${className} flex flex-col `}>
			<div className='relative pb-2'>
				<DataTable
					entity='contact'
					loading={loading}
					className='px-5'
					columns={columns}
					allowFilter
					customRow={<CustomHeaders/>}
					columnData={columnData}
					onColumnUpdate={(updatedColumns: ITableColumn[]) => {
						Logger.info({ updatedColumns })
					} }
					data={contacts}
					onSaveList={() => Logger.info('Save list')}
					showColumnToggle
					allowDownload
					filterPlaceholder='Search contacts'
					endItem={<AddEditContact />}
					onColumnVisibilityChange={(columnId: string, isVisible: boolean) => {
						Logger.info({ columnId, isVisible })
					} } 
					isGrouped={false}
					/>
			</div>
		</div>
	)
}
