/* eslint-disable unicorn/no-array-callback-reference */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react/no-array-index-key */
import Button from "components/Buttons/Button"
import Skeleton from "components/Skeleton"
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar"
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip"
import { AuthContext } from "context/AuthContext"
import CreateTask from "features/tasks/view/CreateTask"
import { ChevronDown, ChevronUp, X } from "lucide-react"
import { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import sanitize from "sanitize-html"
import { renderDate } from "utils/date.util"
import { selectCommentStatus, selectComments } from "../redux/dashboardHome.slice"
import { DashboardService } from "../services/dashboard.service"
import type { ISharedLinkCompanyComment } from "features/sharedlinks/interfaces/sharedlink.interface"

function CommentItem({ comment }: { comment: ISharedLinkCompanyComment }) {

  const [expand, setExpand] = useState(false)

  return (
    <div>
      <div
        className='rounded-md border border-gray-200 bg-gray-100 p-3 text-xs'
        dangerouslySetInnerHTML={{ __html: sanitize(expand ? comment.comment : comment.comment.slice(0, 200)) }}
      />
      {
        comment.comment.length > 200 &&
        <Button
          variant="ghost"
          className="text-xs p-1 mt-1"
          onClick={() => {
            setExpand(!expand)
          }}
        >
          {expand ? <>View Less <ChevronUp size={12} /></> : <>View More <ChevronDown size={12} /></>}
        </Button>
      }
    </div>
  )
}

export default function DashboardComments() {
  const { authUser } = useContext(AuthContext)
  const comments = useSelector(selectComments)
  const loading = useSelector(selectCommentStatus)

  const now = new Date();
  const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));

  const hasCommentsToday = comments.some(comment => new Date(comment.createdAt).toDateString() === new Date().toDateString())
  const hasCommentsYesterday = comments.some(comment => new Date(comment.createdAt).toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString())
  const hasCommentsFromLastMonthExcludingTodayAndYesterday = comments.some(comment => new Date(comment.createdAt).getTime() >= oneMonthAgo.getTime() && new Date(comment.createdAt).toDateString() !== new Date().toDateString() && new Date(comment.createdAt).toDateString() !== new Date(new Date().setDate(new Date().getDate() - 1)).toDateString())

  const hasNoComments = !hasCommentsToday && !hasCommentsYesterday && !hasCommentsFromLastMonthExcludingTodayAndYesterday

  useEffect(() => {
    if (!authUser?.fund.id) return
    const unsubscribe = DashboardService.listenToFundDashboardComments({ fundId: authUser.fund.id })
    return () => unsubscribe()
  }, [authUser?.fund.id])

  const renderComments = (filterFn: (comment: ISharedLinkCompanyComment) => boolean, period: string) => (
    <div className="flex gap-3 border rounded-md p-3 flex-col">
      <div>{period}</div>
      <div>
        {comments.filter(filterFn).map((comment, index) => (
          <div key={index} className="flex gap-5">
            <div className="mt-[0.6rem]">
              <div className='text-[0.65rem] whitespace-nowrap w-20'>{renderDate(comment.createdAt)}</div>
            </div>
            <div className="flex flex-col gap-3 w-full">
              <div className="flex items-center gap-1">
                {comment.company?.id && <Link to={`/company/${comment.company?.id}`}>
                  <Tooltip key={comment.id}>
                    <TooltipTrigger asChild>
                      <Avatar className='h-8 w-8 border cursor-pointer'>
                        <AvatarImage
                          src={comment.company?.website
                            ? `https://www.google.com/s2/favicons?sz=128&domain_url=${comment.company.website}`
                            : '/images/logo/Logo-4.png'}
                        />
                        <AvatarFallback>{comment.company?.name.charAt(0)}</AvatarFallback>
                      </Avatar>
                    </TooltipTrigger>
                    <TooltipContent>View {comment.company?.name}</TooltipContent>
                  </Tooltip>
                </Link>}
                {comment.company?.id && <X size={13} color='gray' />}
                <Avatar className='h-8 w-8 border'>
                  <AvatarFallback>{comment.user?.email.charAt(0)}</AvatarFallback>
                </Avatar>
                <div className="text-sm ml-2 font-semibold">{comment.user?.email}</div>
              </div>
              <CommentItem comment={comment} />
              <div className="w-full flex items-end justify-end">
                <CreateTask
                  task={{
                    description: comment.comment,
                    company: {
                      id: comment.company?.id || '',
                      name: comment.company?.name || '',
                      website: comment.company?.website || ''
                    }
                  }}
                  trigger={
                    <Button id='open-add-company-task' variant='ghost' className="p-1 text-xs font-normal">
                      Add Task
                    </Button>
                  }
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  if (loading) {
    return (
      <div className="flex flex-col gap-3">
        {["Today", "Yesterday"].map(period => (
          <div key={period} className="flex gap-3 border rounded-md p-3 flex-col">
            <div>{period}</div>
            <div>
              {['', '', '', ''].map((_, index) => (
                <div key={index} className="flex gap-5">
                  <div className="mt-[0.6rem]">
                    <Skeleton className='h-3 w-16 rounded-md border' />
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="flex items-center gap-1">
                      <Skeleton className='h-8 w-8 rounded-full border' />
                      <X size={13} color='gray' />
                      <Skeleton className='h-8 w-8 rounded-full border' />
                      <Skeleton className='h-3 w-96 ml-5 rounded-md border' />
                    </div>
                    <Skeleton className='h-16 w-full rounded-md border' />
                    <div className="w-full flex items-end justify-end">
                      <Skeleton className='h-3 w-32 rounded-md border' />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-3">
      {hasCommentsToday && renderComments(comment => new Date(comment.createdAt).toDateString() === new Date().toDateString(), "Today")}
      {hasCommentsYesterday && renderComments(comment => new Date(comment.createdAt).toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString(), "Yesterday")}
      {(!hasCommentsToday || !hasCommentsYesterday) && hasCommentsFromLastMonthExcludingTodayAndYesterday && renderComments(comment => new Date(comment.createdAt).getTime() >= oneMonthAgo.getTime() && new Date(comment.createdAt).toDateString() !== new Date().toDateString() && new Date(comment.createdAt).toDateString() !== new Date(new Date().setDate(new Date().getDate() - 1)).toDateString(), "Older")}

      {
        hasNoComments &&
        <div className="flex flex-col gap-3">
          <div className="flex gap-3 border rounded-md p-3 flex-col">
            <div>
              <div className="flex gap-5">
                <div className="flex flex-col gap-3 w-full">
                  <div className="flex items-center gap-1">
                    <div className="text-sm ml-2 font-semibold">No comments for the past month</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

    </div>
  )
}
