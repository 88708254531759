/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import { getPartners } from 'features/partners/partner.api'
import type { Partner } from 'interfaces'

export interface PartnerSliceState {
	data: Partner[]
	status: 'failed' | 'idle' | 'loading'
	error?: string | null
}

const initialState: PartnerSliceState = {
	data: [],
	status: 'idle',
	error: null
}

export const partnerSlice = createAppSlice({
	name: 'partner',
	initialState,
	reducers: create => ({
		fetchPartners: create.asyncThunk(
			async (fundId: string) => {
				const response = await getPartners(fundId)
				return response
			},
			{
				pending: state => {
					state.status = 'loading'
				},
				fulfilled: (state, action) => {
					state.status = 'idle'
					state.data = action.payload
				},
				rejected: (state, action) => {
					state.status = 'failed'
					state.error = action.error.message
				}
			}
		),
		setPartners: create.reducer((state, action: PayloadAction<Partner[]>) => {
			state.status = 'idle'
			state.data = action.payload
		})
	}),
	selectors: {
		selectPartners: partner => partner.data,
		selectInvitatonCount: partner => partner.data.length,
		selectPartnerStatus: partner => partner.status
	}
})

export const { fetchPartners, setPartners } = partnerSlice.actions

export const { selectPartners, selectInvitatonCount, selectPartnerStatus } =
	partnerSlice.selectors
