/* eslint-disable @typescript-eslint/no-extraneous-class */

import { Logger } from 'lib/logger'
import { Errorhandler } from 'lib/sentry'
import { addActivity } from '../api/activity.api'
import type { IActivity } from '../interfaces/actvitiy.interface'

/* eslint-disable @typescript-eslint/no-explicit-any */
export class ActivityService {
	public static add = async (activity: IActivity) => {
		try {
			await addActivity(activity)
		} catch (error: any) {
			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}
}
