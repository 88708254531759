import {
	collection,
	deleteDoc,
	doc,
	onSnapshot,
	orderBy,
	query,
	updateDoc,
	where,
	writeBatch
} from 'firebase/firestore'

import type { CapitalCallRequest } from '../interfaces/CapitalCallRequest'
import Errorhandler from 'lib/sentry'
import Logger from 'lib/logger'
import axiosInstance from 'lib/axios'
import { firestore } from 'lib/firebase'
import { setCapitalCallRequests } from '../redux/capitalCallRequests.slice'
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-extraneous-class */
import { store } from 'app/store'
import { toast } from 'sonner'

export class CapitalCallRequestService {
	private static readonly ref = collection(firestore, 'capital_call_requests')

	public static addCapitalCallRequests = async (
		capitalCallRequests: CapitalCallRequest[]
	) => {
		try {
			// Use  a batch write to add multiple documents
			const batch = writeBatch(firestore)

			for (const capitalCallRequest of capitalCallRequests) {
				const docRef = doc(this.ref, capitalCallRequest.id)
				batch.set(docRef, capitalCallRequest)
			}

			await batch.commit()
		} catch (error: any) {
			Logger.error('Error adding capital call requests', error)

			toast.error(error.message || 'Error adding capital call requests')

			Errorhandler.captureException(error)
		}
	}

	public static updateCapitalCallRequests = async (
		capitalCallRequests: CapitalCallRequest[]
	) => {
		try {
			// Use  a batch write to add multiple documents
			const batch = writeBatch(firestore)

			for (const capitalCallRequest of capitalCallRequests) {
				const docRef = doc(this.ref, capitalCallRequest.id)
				batch.set(docRef, capitalCallRequest)
			}

			await batch.commit()
		} catch (error: any) {
			Logger.error('Error adding capital call requests', error)

			toast.error(error.message || 'Error adding capital call requests')

			Errorhandler.captureException(error)
		}
	}

	public static updateCapitalCallRequest = async (
		id: string,
		capitalCall: Partial<CapitalCallRequest>
	): Promise<string> => {
		try {
		const docRef = doc(this.ref, id)
		await updateDoc(docRef, { ...capitalCall } as any, { merge: true })
		toast.success('Capital call request updated successfully')
		return id
		} catch (error: any) {
			Logger.error('Error updating capital call request', error)
			toast.error(error.message || 'Error updating capital call request')
			Errorhandler.captureException(error)
			return ''
		}
	}

	public static listenToCapitalCallRequests = (fundId: string) => {
		const q = query(
			this.ref,
			where('fund.id', '==', fundId),
			orderBy('createdAt', 'desc')
		)
		const unsubscribe = onSnapshot(
			q,
			querySnapshot => {
				const capitalCalls = querySnapshot.docs.map(
					d => d.data() as CapitalCallRequest
				)
				store.dispatch(setCapitalCallRequests(capitalCalls))
			},
			error => {
				Errorhandler.captureException(error)
				Logger.error(error)
			}
		)

		return unsubscribe
	}

	public static deleteCapitalCallRequest = async (id: string) => {
		const docRef = doc(this.ref, id)
		await deleteDoc(docRef)
	}

	public static sendTestMail = async (request: CapitalCallRequest) => {
		try {
			const { data } = await axiosInstance.post(
				'/capital-call-requests?test=true',
				{ ...request }
			)

			Logger.info('Test email sent', data)
			// Send test email
			toast.success('Test email sent')
		} catch (error: any) {
			toast.error(
				error.reponse?.data?.message ||
					error.message ||
					'Error sending test email'
			)

			Logger.error('Error sending test email', error)
			Errorhandler.captureException(error)
		}
	}
}
