/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { ISpace } from '../interface/spaces.interface'

export interface State {
  data: ISpace[]
  isLoading: boolean
  error?: string | null
}

const initialState: State = {
  data: [],
  isLoading: true,
  error: null
}

export const spacesSlice = createAppSlice({
  name: 'spaces',
  initialState,
  reducers: create => ({
    setSpaces: create.reducer((state, action: PayloadAction<ISpace[]>) => {
      state.data = action.payload
      state.isLoading = false
    })
  }),
  selectors: {
    selectSpaces: spaces => spaces,
  }
})

export const { setSpaces } = spacesSlice.actions

export const { selectSpaces } = spacesSlice.selectors
