import DashboardLayout from 'components/Dashboard/DashboardLayout'
import LimitedPartnerPage from 'features/limited_partners/views/LimitedPartnerPage'
import usePageTimeTracking from 'hooks/usePageTracking'

export default function LimitedPartners(): JSX.Element {
	usePageTimeTracking({ pageName: 'Limited Partners' })

	return (
		<DashboardLayout hideHeader={false} showSidebar title='Limited Partners'>
			<LimitedPartnerPage />
		</DashboardLayout>
	)
}
