import type { IEmailThread, IGmailSummary } from '../interfaces/email.interface'
import {
	collection,
	doc,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	query,
	startAfter,
	updateDoc,
	where
} from 'firebase/firestore'
import { setLabelCounts, setThreadsLoading } from '../redux/threads.slice'

import { Errorhandler } from 'lib/sentry'
import Logger from 'lib/logger'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import { firestore } from 'lib/firebase'
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'

const threadsRef = collection(firestore, 'threads')

export const updateThread = (threadId: string, data: Partial<IEmailThread>) => {
	const ref = doc(threadsRef, threadId)
	return updateDoc(ref, data)
}
export const listenToLabelCounts = (userId: string) => {
	store.dispatch(setThreadsLoading(true))

	const ref = doc(firestore, 'gmail_summaries', userId)

	const unsubscribe = onSnapshot(
		ref,
		snapshot => {
			if (!snapshot.exists()) return

			const data = snapshot.data() as IGmailSummary
			store.dispatch(setLabelCounts(data))
		},
		error => {
			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}

export const getEmailCount = async (userId: string, emails: string[]) => {
	const q = query(
		threadsRef,
		where('userId', '==', userId),
		where('emailAccounts', 'array-contains-any', emails)
	)

	const snapshot = await getDocs(q)
	return snapshot.size
}

interface FetchNextDataParams {
	userId: string
	pageSize: number
	lastDoc?: QueryDocumentSnapshot
}

interface FetchNextDataResult {
	data: IEmailThread[]
	lastDoc?: QueryDocumentSnapshot
}

export async function fetchThreads({
	userId,
	lastDoc,
	pageSize
}: FetchNextDataParams): Promise<FetchNextDataResult> {
	try {
		const colRef = collection(firestore, 'threads')
		const q = lastDoc
			? query(
					colRef,
					where('userId', '==', userId),
					orderBy('createdAt', 'desc'),
					startAfter(lastDoc),
					limit(pageSize)
				)
			: query(
					colRef,
					where('userId', '==', userId),
					orderBy('createdAt', 'desc'),
					limit(pageSize)
				)

		const querySnapshot = await getDocs(q)
		const data = querySnapshot.docs.map(
			document => document.data() as IEmailThread
		)
		const newLastDoc = querySnapshot.docs.at(-1)

		return {
			data,
			lastDoc: newLastDoc
		}
	} catch (error: any) {
		Logger.error('Error fetching data:', error)
		Errorhandler.captureException(error)
		throw error
	}
}
