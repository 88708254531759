/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'
import type { ITableColumn } from 'features/dataTable/interfaces/datatable.interface'
import {
	collection,
	doc,
	onSnapshot,
	query,
	updateDoc,
	where,
	writeBatch
} from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'
import { setColumns } from './column.slice'

const columnsRef = collection(firestore, 'columns')

export const updateColumns = async (columns: ITableColumn[]) => {
	const batch = writeBatch(firestore)
	for (const company of columns) {
		const docRef = doc(columnsRef, company.id)
		batch.update(docRef, company as any)
	}
	await batch.commit()
}

export const updateColumn = async (id: string, data: Partial<ITableColumn>) => {
	const ref = doc(columnsRef, id)
	await updateDoc(ref, data)
}

export const listenToColumns = (fundId: string) => {
	const q = query(columnsRef, where('fund.id', '==', fundId))

	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const columns = querySnapshot.docs.map(d => d.data() as ITableColumn)
			store.dispatch(setColumns(columns))
		},
		error => {
			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}
