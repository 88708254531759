/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useAppSelector } from 'app/hooks'
import DashboardLayout from 'components/Dashboard/DashboardLayout'
import { AuthContext } from 'context/AuthContext'
import { listenToCompanies, updateCompanies, updateCompany } from 'features/companies/api/companies.api'
import CompanyCard from 'features/companies/view/CompanyCard'
import { listenToFunnels } from 'features/funnels/funnels.api'
import { selectFunnelStatus, selectFunnels } from 'features/funnels/funnels.slice'
import type { Column } from 'features/kanban/views/KanbanView'
import KanbanView from 'features/kanban/views/KanbanView'
import { updateLP, updateLPs } from 'features/limited_partners/api/lps.api'
import { listenToProspects } from 'features/prospects/prospects.api'
import { selectProspectStatus, selectProspects } from 'features/prospects/prospects.slice'
import LPCard from 'features/prospects/views/LpCard'
import { useContext, useEffect, useMemo, useState } from 'react'


interface Props {
  entities: ('company' | 'prospect')[]
}

export default function ArchivedFunnels({ entities }: Props): JSX.Element {
  const { authUser } = useContext(AuthContext)

  const [boardColumns, setBoardColumns] = useState<Column[]>([])
  const funnels = useAppSelector(selectFunnels).filter(funnel => {
    return entities.includes(funnel.type) && funnel.isArchived
  })
  const isFunnelsLoading = useAppSelector(selectFunnelStatus)
  const { isLoading, data: companies } = useAppSelector(
    state => state.companies
  )

  const prospects = useAppSelector(selectProspects)
  const isProspectsLoading = useAppSelector(selectProspectStatus)

  useEffect(() => {
    if (!authUser?.fund.id) return

    const unsubFunnels = listenToFunnels(authUser.fund.id)
    const unsubscribe = listenToCompanies(authUser.fund.id)
    const unsubscribeProspects = listenToProspects(authUser.fund.id)

    return () => {
      unsubscribe()
      unsubFunnels()
      unsubscribeProspects()
    }
  }, [authUser?.fund.id])

  const columns = useMemo(() => {
    return funnels
      .filter(funnel => {
        return funnel.isArchived
      })
      .map(funnel => {
        const column: Column = {
          id: funnel.id,
          title: funnel.name,
          index: funnel.index,
          isArchived: funnel.isArchived,
          category: funnel.category,
          items: (entities.includes("prospect") ? prospects : companies).filter(item => item.funnel?.id === funnel.id)
        };
        return column;
      });
  }, [companies, funnels]);

  useEffect(() => {
    setBoardColumns(columns);
  }, [columns]);

  return (
    <DashboardLayout hideHeader={false} showSidebar title='Companies'>
      {
        entities.includes("company") && <div className='px-5'>
          <KanbanView
            columns={boardColumns}
            loading={isFunnelsLoading}
            itemsLoading={isLoading}
            group='archived'
            renderItem={item => {
              return <CompanyCard key={item.item.id} company={item.item} />
            }}
            onItemSameColumnMove={items => {
              updateCompanies(items)
            }}
            onItemDifferentColumnMove={({ item, dropResult }) => {
              updateCompany({
                ...item,
                funnel: {
                  id: funnels.find(
                    funnel => funnel.id === dropResult.destination?.droppableId
                  )?.id,
                  name: funnels.find(
                    funnel => funnel.id === dropResult.destination?.droppableId
                  )?.name,
                  category: funnels
                    .find(
                      funnel => funnel.id === dropResult.destination?.droppableId
                    )
                    ?.name.toLowerCase()
                },
                lastUpdatedAt: new Date().toString()
              })
            }}
            entity='company'
            workspaces={[]}
          />
        </div>
      }

      {
        entities.includes('prospect') && (
          <div className='px-5'>
            <KanbanView
              workspaces={[]}
              columns={boardColumns}
              loading={isFunnelsLoading}
              itemsLoading={isProspectsLoading}
              renderItem={item => {
                return <LPCard key={item.item.id} prospect={item.item} />
              }}
              onItemSameColumnMove={items => {
                updateLPs(items)
              }}
              onItemDifferentColumnMove={({ item, dropResult }) => {
                updateLP({
                  ...item,
                  funnel: {
                    id: funnels.find(
                      funnel => funnel.id === dropResult.destination?.droppableId
                    )?.id,
                    name: funnels.find(
                      funnel => funnel.id === dropResult.destination?.droppableId
                    )?.name,
                    category: funnels
                      .find(
                        funnel =>
                          funnel.id === dropResult.destination?.droppableId
                      )
                      ?.name.toLowerCase()
                  },
                  lastUpdatedAt: new Date().toString()
                })
              }}
              entity='prospect'
              group="archived"
            />
          </div>
        )
      }
    </DashboardLayout>
  )
}
