import { FilePen, X } from 'lucide-react'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Button from 'components/Buttons/Button'
import type { IEmailSignature } from 'interfaces/emailSignature.interface'
import { firestore } from 'lib/firebase'
import logger from 'lib/logger'
import { setEmailSignatures } from '../redux/emailSignatures.slice'
import { useAppDispatch } from 'app/hooks'
import { useNavigate } from 'react-router-dom'

export default function ShowSignatureHint() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { authUser } = useContext(AuthContext)
	const [signatureStatus, setSignatureStatus] = useState({
		isSet: true,
		shouldShowAgain: false
	})

	useEffect(() => {
		const getAllSignatures = () => {
			if (!authUser?.id) return
			if (authUser.googleToken?.refreshToken.length === 0) return
			try {
				const q = query(
					collection(firestore, `email_signatures`),
					where('creator.id', '==', authUser.id)
				)
				const unsubscribe = onSnapshot(q, snapshot => {
					const data = snapshot.docs.map(doc => ({
						...doc.data()
					}))

					dispatch(setEmailSignatures(data as IEmailSignature[]))

					const shouldShowSignature = localStorage.getItem(
						'shouldShowSignature'
					)

					setSignatureStatus({
						shouldShowAgain:
							shouldShowSignature !== 'false' && data.length === 0,
						isSet: data.length > 0
					})
				})

				return () => unsubscribe()
			} catch (error) {
				logger.error(error)
			}
		}

		getAllSignatures()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authUser?.id, authUser?.googleToken?.refreshToken])

	return (
		<div className=''>
			{signatureStatus.shouldShowAgain && (
				<div className='mt-3 rounded border border-blue-100  p-3'>
					<div className='mb-3 flex items-center justify-between'>
						<div className=''>Complete your setup</div>

						<Button
							id='close-signature-hint'
							variant='ghost'
							className='cursor-pointer p-1'
							onClick={() => {
								setSignatureStatus(prev => {
									return {
										...prev,
										shouldShowAgain: false
									}
								})

								localStorage.setItem('shouldShowSignature', 'false')
							}}
						>
							<X color='gray' />
						</Button>
					</div>

					<div
						onClick={() => {
							navigate('/settings?tab=profile')
						}}
						className='flex w-fit cursor-pointer items-center gap-2 rounded transition-all hover:bg-gray-100'
					>
						<div className='flex h-8 w-8 items-center justify-center rounded-full bg-blue-200 p-2'>
							<FilePen className='text-blue-200' color='blue' />
						</div>
						<div className='text-sm'>Set a signature</div>
					</div>
				</div>
			)}
		</div>
	)
}
