import type { ILimitedPartner } from 'interfaces'
import type { IProspect } from './interfaces/prospect.interface'
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface ProspectsSliceState {
	data: IProspect[]
	isLoading: boolean
	error?: string | null
}

const initialState: ProspectsSliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const prospectsSlice = createAppSlice({
	name: 'prospects',
	initialState,
	reducers: create => ({
		setProspectsError: create.reducer(
			(state, action: PayloadAction<string>) => {
				state.error = action.payload
				state.isLoading = false
			}
		),
		setProspectsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setProspects: create.reducer(
			(state, action: PayloadAction<ILimitedPartner[]>) => {
				state.data = action.payload
				state.isLoading = false
			}
		)
	}),
	selectors: {
		selectProspects: prospect => prospect.data.filter(lp => !lp.isApproved),
		selectedLimitedPartners: prospect =>
			prospect.data.filter(lp => lp.isApproved),
		selectProspectCount: prospect =>
			prospect.data.filter(lp => !lp.isApproved).length,
		selectProspectStatus: prospect => prospect.isLoading
	}
})

export const { setProspects, setProspectsError, setProspectsLoading } =
	prospectsSlice.actions

export const {
	selectProspects,
	selectProspectCount,
	selectProspectStatus,
	selectedLimitedPartners
} = prospectsSlice.selectors
