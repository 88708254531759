/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppSelector } from 'app/hooks'
import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger
} from 'components/ui/dropdown-menu'
import { Input } from 'components/ui/input'
import { FunnelsService } from 'features/funnels/service/funnels.service'
import { selectSpaces } from 'features/spaces/redux/spaces.slice'
import { useFormik } from 'formik'
import Logger from 'lib/logger'
import {
	AlignVerticalSpaceAround,
	Archive,
	Edit,
	MoreVertical
} from 'lucide-react'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'
import * as Yup from 'yup'
import type { Column } from '../views/DnDColumn'

interface Props {
	column?: Column
	allowSpaces?: boolean
	entity?: string
	workspaces?: string[]
}

const DEFAULT_FUNNELS = [
	{ name: 'Funnels', id: 'funnel', slug: 'funnel', workspace: 'companies' },
	{
		name: 'Portfolio',
		id: 'portfolio',
		slug: 'portfolio',
		workspace: 'companies'
	},
	{ name: 'Holding Pattern', id: 'hold', slug: 'hold', workspace: 'companies' },
	{ name: 'Pass', id: 'pass', slug: 'pass', workspace: 'companies' }
]

export default function FunnelOptions({
	column,
	allowSpaces,
	entity,
	workspaces
}: Props) {
	const [isOpen, setIsOpen] = useState(false)

	const { data: spaces } = useAppSelector(selectSpaces)
	if (!column) return null

	const formik = useFormik({
		initialValues: {
			name: column.title
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Name is required')
		}),
		onSubmit: async value => {
			try {
				FunnelsService.updateFunnel({
					id: column.id,
					name: value.name
				})

				formik.resetForm()
				setIsOpen(false)
				toast.success('Funnel renamed')
			} catch (error) {
				toast.error('An error occurred')
				Logger.error(error)
			}
		}
	})

	useEffect(() => {
		if (isOpen) {
			formik.setFieldValue('name', column.title)
		}
	}, [isOpen])

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant='ghost' className='p-2'>
					<MoreVertical size={15} />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className='w-56'>
				<DropdownMenuItem className='flex items-center gap-3' asChild>
					<DialogModal
						open={isOpen}
						setOpen={setIsOpen}
						title='Rename funnel'
						description=''
						trigger={
							<div className='flex cursor-pointer items-center gap-3 px-2 py-2 text-sm hover:bg-gray-100'>
								<Edit size={17} /> Rename funnel
							</div>
						}
					>
						<div>
							<div className='flex flex-col gap-2'>
								<Input
									id='name'
									error={formik.errors.name}
									name='funnel-name'
									className='bg-white'
									value={formik.values.name}
									label='Name'
									onChange={async e => {
										formik.setFieldValue('name', e.target.value)
									}}
								/>
								<div className='mt-5 flex w-full justify-end gap-5'>
									<Button
										variant='ghost'
										className='px-2 py-1 text-red-500'
										onClick={() => setIsOpen(false)}
									>
										Cancel
									</Button>
									<Button
										id='create-tag-button'
										onClick={() => {
											formik.handleSubmit()
										}}
									>
										Rename
									</Button>
								</div>
							</div>
						</div>
					</DialogModal>
				</DropdownMenuItem>

				<DropdownMenuItem
					className='flex items-center gap-3'
					onClick={() => {
						FunnelsService.updateFunnel({
							id: column.id,
							isArchived: !column.isArchived
						})
					}}
				>
					<Archive size={17} />{' '}
					{column.isArchived ? 'Unarchive funnel' : 'Archive funnel'}
				</DropdownMenuItem>

				{allowSpaces && (
					<DropdownMenuSub>
						<DropdownMenuSubTrigger className='flex items-center gap-3'>
							<AlignVerticalSpaceAround size={17} />
							Move to Space
						</DropdownMenuSubTrigger>
						<DropdownMenuPortal>
							<DropdownMenuSubContent>
								<div className='w-full'>
									{[...DEFAULT_FUNNELS, ...spaces]
										.filter(space => workspaces?.includes(space.workspace))
										.filter(space => space.slug !== column.funnel?.category)
										.map(space => (
											<DropdownMenuItem
												key={space.id}
												onClick={async () => {
													FunnelsService.updateFunnel({
														id: column.id,
														category: space.slug
													})
												}}
											>
												{space.name}
											</DropdownMenuItem>
										))}
								</div>
								{[...DEFAULT_FUNNELS, ...spaces]
									.filter(space => workspaces?.includes(space.workspace))
									.filter(space => space.slug !== column.category).length ===
									0 && <DropdownMenuItem>No spaces available</DropdownMenuItem>}
							</DropdownMenuSubContent>
						</DropdownMenuPortal>
					</DropdownMenuSub>
				)}
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
