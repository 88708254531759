import {
	AlarmClock,
	CalendarCheck,
	CopyPlus,
	Files,
	Mail,
	Notebook,
	PieChart
} from 'lucide-react'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import DashboardLayout from 'components/Dashboard/DashboardLayout'
import { LIMITED_PARTNER_DETAILS_INFO_TABS } from 'features/limited_partners/data/limitedpartner.data'
import LimitedPartnerActivityLogs from 'features/limited_partners/views/LimitedPartnerActivityLogs'
import LimitedPartnerCapitalCalls from 'features/limited_partners/components/LimitedPartnerCapitalCalls'
import LimitedPartnerDetailsHeader from 'features/limited_partners/views/LimitedPartnerHeader'
import LimitedPartnerDetailsInfo from 'features/limited_partners/views/LimitedPartnerDetailsInfo'
import LimitedPartnerEmails from 'features/limited_partners/views/LimitedPartnerEmails'
import LimitedPartnerFiles from 'features/limited_partners/views/LimitedPartnerFiles'
import LimitedPartnerNotes from 'features/limited_partners/views/LimitedPartnerNotes'
import LimitedPartnerRelevantCompanies from 'features/limited_partners/views/LimitedPartnerRelevantCompanies'
import Skeleton from 'components/Skeleton'
import clsx from 'clsx'
import { listenToLP } from 'features/limited_partners/api/lps.api'
import { toast } from 'sonner'
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import { useAppSelector } from 'app/hooks'
import { useLocation } from 'react-router-dom'
import usePageTimeTracking from 'hooks/usePageTracking'

function getSidebarIcon(value: string) {
	if (value === 'notes') return <Notebook size={20} />
	if (value === 'email') return <Mail size={20} />
	if (value === 'files') return <Files size={20} />
	if (value === 'updates') return <CopyPlus size={20} />
	if (value === 'logAction') return <CalendarCheck size={20} />
	if (value === 'reminder') return <AlarmClock size={20} />
	if (value === 'log-interaction') return <Files size={20} />
	if (value === 'capital-calls') return <PieChart size={20} />
}

const RIGHT_TABS = [
	{ name: 'Calls', value: 'capital-calls', disabled: false, isLPOnly: true },
	{ name: 'Notes', value: 'notes' },
	{ name: 'Email', value: 'email', disabled: false },
	// { name: 'Log', value: 'log-interaction', disabled: true },
	{ name: 'Files', value: 'files', disabled: false }
]

export default function LimitedPartnerDetails() {
	usePageTimeTracking({ pageName: 'Limited Partner' })

	const location = useLocation()
	const lpId = location.pathname.split('/').pop()

	const { authUser } = useContext(AuthContext)

	const {
		data: limitedPartner,
		isLoading,
		error
	} = useAppSelector(state => state.lp)

	const [selectedDetailsInfo, setSelectedDetailsInfo] = useState(
		LIMITED_PARTNER_DETAILS_INFO_TABS[0]
	)

	const [selectedRightTab, setSelectedRightTab] = useState(RIGHT_TABS[0])

	useEffect(() => {
		if (!lpId || !authUser?.id) return
		const unsubscribe = listenToLP(lpId, authUser)
		return () => unsubscribe()
	}, [lpId, authUser])

	useEffect(() => {
		if (limitedPartner) {
			setSelectedRightTab(RIGHT_TABS[limitedPartner.isApproved ? 0 : 1])
		}
	}, [limitedPartner])

	if (isLoading) {
		return (
			<DashboardLayout
				showSidebar={false}
				title='Limited Partner Details'
				hideHeader={false}
			>
				<div className='ml-5 mt-5 flex flex-col gap-2'>
					<Skeleton className='h-5 w-96 rounded-md' />
					<Skeleton className='h-5 w-[30rem] rounded-md' />
					<Skeleton className='h-5 w-[30rem] rounded-md' />
				</div>
			</DashboardLayout>
		)
	}

	if (error) {
		return (
			<DashboardLayout
				showSidebar={false}
				title='Company Details'
				hideHeader={false}
			>
				<div className='ml-5 mt-5 flex flex-col gap-2'>
					<div className='text-red-500'>{error}</div>
				</div>
			</DashboardLayout>
		)
	}

	if (!limitedPartner) {
		return (
			<DashboardLayout
				showSidebar={false}
				title='Company Details'
				hideHeader={false}
			>
				<div className='ml-5 mt-5 flex flex-col gap-2'>
					<div className='text-red-500'>Limited Partner not found</div>
				</div>
			</DashboardLayout>
		)
	}

	return (
		<DashboardLayout
			hideHeader={false}
			showSidebar
			title='Limited Partner Details'
		>
			<div className='sticky top-0 flex h-[calc(100vh-3rem)] flex-wrap justify-between gap-5 bg-gray-50 p-4 pb-0'>
				<div className='flex h-full flex-1 flex-col gap-5 overflow-auto'>
					<LimitedPartnerDetailsHeader lp={limitedPartner} />
					<div className='flex flex-col'>
						<div className='flex items-center'>
							{LIMITED_PARTNER_DETAILS_INFO_TABS.map(item => (
								<div
									key={item.id}
									data-testid={item.id}
									onClick={() => setSelectedDetailsInfo(item)}
									className={clsx(
										'cursor-pointer whitespace-nowrap border-b-2 px-4 py-2 font-semibold',
										selectedDetailsInfo.id === item.id &&
											'border-b-blue-500 text-blue-700'
									)}
								>
									{item.label}
								</div>
							))}
						</div>
						{selectedDetailsInfo.id === 'details' && (
							<LimitedPartnerDetailsInfo
								limitedPartner={limitedPartner}
								isReadOnly={false}
							/>
						)}
						{selectedDetailsInfo.id === 'relevantcompanies' && (
							<LimitedPartnerRelevantCompanies
								limitedPartner={limitedPartner}
							/>
						)}
					</div>
					{selectedDetailsInfo.id === 'details' && (
						<LimitedPartnerActivityLogs limitedPartner={limitedPartner} />
					)}
				</div>
				<div className='flex h-full rounded-md lg:w-[35vw]'>
					<div className='h-full flex-1 overflow-auto rounded-t-md border bg-white'>
						{selectedRightTab.value === 'notes' && (
							<LimitedPartnerNotes limitedPartner={limitedPartner} />
						)}
						{selectedRightTab.value === 'email' && (
							<LimitedPartnerEmails limitedPartner={limitedPartner} />
						)}
						{selectedRightTab.value === 'capital-calls' &&
							limitedPartner.isApproved && (
								<LimitedPartnerCapitalCalls limitedPartner={limitedPartner} />
							)}
						{selectedRightTab.value === 'files' && (
							<LimitedPartnerFiles limitedPartner={limitedPartner} />
						)}
					</div>
					<div className='h-full'>
						{RIGHT_TABS.map(item => (
							<div
								data-testid={item.value}
								key={item.value}
								className={clsx(
									'group flex cursor-pointer flex-col items-center justify-center gap-2 py-2 pl-4 font-semibold',
									item.disabled && 'opacity-40'
								)}
								onClick={() => {
									if (item.disabled) {
										toast('This feature is not available yet')
									} else {
										setSelectedRightTab(item)
									}
								}}
							>
								{item.isLPOnly && !limitedPartner.isApproved ? null : (
									<div>
										<div
											className={clsx(
												'borde flex h-10 w-10 items-center justify-center rounded-md',
												selectedRightTab.value === item.value
													? 'border-blue-500 bg-blue-500 text-white'
													: 'border-gray-200'
											)}
										>
											{getSidebarIcon(item.value)}
										</div>
										<div className='text-center text-xs'>{item.name}</div>
									</div>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</DashboardLayout>
	)
}
