import { CaretSortIcon } from "@radix-ui/react-icons";
import Button from "components/Buttons/Button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "components/ui/dropdown-menu";
import { useState } from "react";
import type { PaymentMethod } from "../interfaces/NewCapitalCall.interface";

export interface IProps {
  initialSelected?: PaymentMethod
  onChange?: (value: PaymentMethod) => void
}

export default function PaymentMethodDropdown(
  {
    initialSelected,
    onChange
  }: IProps
) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(initialSelected || "Bank Account");

  function onSelectPaymentMethod(data: PaymentMethod) {
    setSelectedPaymentMethod(data);

    if(onChange)
      onChange(data);
  }

  return (
    <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className='truncate px-5 text-blue-700 font-semibold text-lg'
            variant='ghost'
          >
              
            { selectedPaymentMethod === "Crypto Account" && <svg width="20" height="28" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.85547 3.99935V1.66602M9.52214 3.99935V1.66602M9.52214 3.99935H1.35547M9.52214 3.99935C11.4552 3.99935 13.0221 5.56636 13.0221 7.49935C13.0221 9.4324 11.4552 10.9993 9.52214 10.9993M3.6888 17.9993V10.9993M3.6888 10.9993V3.99935M3.6888 10.9993H10.6888C12.6219 10.9993 14.1888 12.5663 14.1888 14.4993C14.1888 16.4324 12.6219 17.9993 10.6888 17.9993H1.35547M4.85547 20.3327V17.9993M9.52214 20.3327V17.9993" stroke="#175DCD" strokeWidth="2.33333" strokeLinecap="round" strokeLinejoin="round"/>
            </svg> }

            { selectedPaymentMethod === "Bank Account" && <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M23.4823 6.87542C23.5952 6.43249 23.3934 5.96935 22.9921 5.75047L12.6743 0.122522C12.3758 -0.0402852 12.0151 -0.0402857 11.7166 0.12252L1.39868 5.75046C0.997379 5.96935 0.795593 6.4325 0.908542 6.87544L1.00342 7.24751C1.11637 7.69045 1.51529 8.00042 1.97241 8.00042H2.19541C2.74769 8.00042 3.19541 8.44814 3.19541 9.00042V15.5005C3.19541 15.7766 2.97155 16.0005 2.69541 16.0005C2.41926 16.0005 2.19541 16.2243 2.19541 16.5005V17.0005C2.19541 17.5527 2.64312 18.0005 3.19541 18.0005H21.1954C21.7477 18.0005 22.1954 17.5527 22.1954 17.0005V16.5005C22.1954 16.2243 21.9716 16.0005 21.6954 16.0005C21.4193 16.0005 21.1954 15.7766 21.1954 15.5005V9.00042C21.1954 8.44814 21.6432 8.00042 22.1954 8.00042H22.4184C22.8755 8.00042 23.2745 7.69044 23.3874 7.24748L23.4823 6.87542ZM6.19541 16.0005C5.64312 16.0005 5.19541 15.5527 5.19541 15.0005V9.00042C5.19541 8.44814 5.64312 8.00042 6.19541 8.00042H7.19541C7.74769 8.00042 8.19541 8.44814 8.19541 9.00042V15.0005C8.19541 15.5527 7.74769 16.0005 7.19541 16.0005H6.19541ZM11.1954 16.0005C10.6432 16.0005 10.1954 15.5527 10.1954 15.0005V9.00042C10.1954 8.44814 10.6432 8.00042 11.1954 8.00042H13.1954C13.7477 8.00042 14.1954 8.44814 14.1954 9.00042V15.0005C14.1954 15.5527 13.7477 16.0005 13.1954 16.0005H11.1954ZM17.1954 16.0005C16.6432 16.0005 16.1954 15.5527 16.1954 15.0005V9.00042C16.1954 8.44814 16.6432 8.00042 17.1954 8.00042H18.1954C18.7477 8.00042 19.1954 8.44814 19.1954 9.00042V15.0005C19.1954 15.5527 18.7477 16.0005 18.1954 16.0005H17.1954ZM11.7166 2.40069C12.0151 2.23789 12.3758 2.23789 12.6743 2.4007L19.2737 6.00042H5.11706L11.7166 2.40069ZM0.195312 20.0005C0.195312 20.5527 0.643028 21.0005 1.19531 21.0005H23.1953C23.7476 21.0005 24.1953 20.5527 24.1953 20.0005C24.1953 19.4482 23.7476 19.0005 23.1953 19.0005H1.19531C0.643028 19.0005 0.195312 19.4482 0.195312 20.0005Z" fill="#175DCD"/>
            </svg> }


            <span className="ml-3">
              { selectedPaymentMethod }
            </span>
            <CaretSortIcon className='ml-2 h-4 w-4' />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className='w-56' align='end' forceMount>
          <DropdownMenuGroup>
            <DropdownMenuItem
              onClick={() => onSelectPaymentMethod("Bank Account")}
            >
              <div className='flex items-center'>
                <span className='ml-3'>
                  Bank Account
                </span>
              </div>
            </DropdownMenuItem>

            <DropdownMenuSeparator />
            <DropdownMenuItem
               onClick={() => onSelectPaymentMethod("Crypto Account")}
            > 
            <div className="flex items-center">
              <span className='ml-3'>
                Crypto Account
              </span>
            </div>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
    </DropdownMenu>
  );
}





