/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import _ from 'lodash'
import type { ICompanyUpdate } from '../interfaces/company_update.interface'

export interface UpdatesSliceState {
	data: ICompanyUpdate[]
	isLoading: boolean
	error?: string | null
}

const initialState: UpdatesSliceState = {
	data: [],
	error: null,
	isLoading: false
}

export const updatesSlice = createAppSlice({
	name: 'updates',
	initialState,
	reducers: create => ({
		setUpdatesLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setUpdatesError: create.reducer(
			(state, action: PayloadAction<string | null>) => {
				state.error = action.payload
				state.isLoading = false
			}
		),
		setUpdates: create.reducer(
			(state, action: PayloadAction<ICompanyUpdate[]>) => {
				state.data = action.payload
				state.isLoading = false
			}
		),
		addUpdates: create.reducer(
			(state, action: PayloadAction<ICompanyUpdate[]>) => {
				const oldUpdates = state.data
				const newUpdates = action.payload

				const data = _.uniqBy([...newUpdates, ...oldUpdates], 'id')

				state.data = data
				state.isLoading = false
			}
		)
	}),
	selectors: {
		selectCompanyUpdates: (state, companyId) => {
			const updates = state.data.filter(item => item.company?.id === companyId)
			return updates
		}
	}
})

export const { setUpdates, addUpdates, setUpdatesLoading, setUpdatesError } =
	updatesSlice.actions

export const { selectCompanyUpdates } = updatesSlice.selectors
