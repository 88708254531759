/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { HoverCard, HoverCardContent, HoverCardTrigger } from './ui/hover-card'

import type { ICompany } from 'interfaces'
import Tags from './Tags'
import { formatDistanceToNow } from 'date-fns'
import { useNavigate } from 'react-router-dom'

interface Props {
	company?: Partial<ICompany>
	isReadOnly?: boolean
}

export default function CompanyHoverCard({ company, isReadOnly }: Props) {
	const navigate = useNavigate()

	if (!company) return null

	return (
		<HoverCard openDelay={0} closeDelay={0}>
			<HoverCardTrigger asChild>
				<div
					onClick={() => {
						if (isReadOnly) return
						navigate(`/company/${company.id}`)
					}}
					className='w-fit cursor-pointer rounded-md bg-blue-100 px-2 py-0.5 font-semibold capitalize text-blue-700'
				>
					{company.name}
				</div>
			</HoverCardTrigger>
			<HoverCardContent className='w-fit'>
				<div className='flex justify-between space-x-4'>
					<Avatar>
						<AvatarImage
							src={
								company.website
									? `https://www.google.com/s2/favicons?sz=128&domain_url=${company.website}`
									: '/images/logo/Logo-4.png'
							}
							className='border rounded-full p-1'
						/>
						<AvatarFallback>C</AvatarFallback>
					</Avatar>
					<div className='space-y-1'>
						<div className='text-sm font-semibold'>{company.name}</div>
						<p className='text-sm'>{company.website}</p>
						<Tags
							tags={company.tags ?? []}
							wrap
							className='mt-4'
							maxNumber={4}
						/>
						{company.createdAt && (
							<div className='flex items-center pt-2'>
								<span className='text-muted-foreground text-xs'>
									Added {formatDistanceToNow(new Date(company.createdAt))} ago
								</span>
							</div>
						)}
					</div>
				</div>
			</HoverCardContent>
		</HoverCard>
	)
}
