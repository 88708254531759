/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useAppSelector } from 'app/hooks'
import DashboardLayout from 'components/Dashboard/DashboardLayout'
import Skeleton from 'components/Skeleton'
import { AuthContext } from 'context/AuthContext'
import { Contact } from 'lucide-react'
import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { listenToContact } from '../api/contacts.api'
import ContactRelations from '../components/ContactRelations'
import { selectContact } from '../redux/contact.slice'
import { EmailEntityService } from '../services/email_entity.service'
import ContactActivities from './ContactActivities'
import ContactDetailsHeader from './ContactDetailsHeader'
import ContactDetailsInfo from './ContactDetailsInfo'
import ContactNotes from './ContactNotes'

export default function ContactDetails(): JSX.Element {
	const { authUser } = useContext(AuthContext)
	const location = useLocation()
	const contactId = location.pathname.split('/').pop()

	const { data, isLoading, error } = useAppSelector(selectContact)

	useEffect(() => {
		if (!contactId) return
		const unsubscribe = listenToContact(contactId)

		return () => {
			unsubscribe()
		}
	}, [contactId])

	useEffect(() => {
		if (!(authUser?.email && data?.email)) return

		const unsubscribe = EmailEntityService.listenToUserEntityRelation(
			authUser.email,
			data.email
		)

		return () => unsubscribe()
	}, [authUser?.email, data?.email])

	if (isLoading)
		return (
			<DashboardLayout hideHeader={false} showSidebar title='Contact Details'>
				<div className='flex flex-col gap-2 p-8'>
					<Skeleton className='h-5 w-52' />
					<Skeleton className='h-5 w-96' />
					<Skeleton className='h-5 w-96' />
				</div>
			</DashboardLayout>
		)

	if (error)
		return (
			<DashboardLayout hideHeader={false} showSidebar title='Contact Details'>
				<div data-testid='get-contact-fail' className='p-10'>
					<Contact color='black' className='text-white' size={30} />
					<div className='mt-3 font-semibold'>{error}</div>
				</div>
			</DashboardLayout>
		)

	if (!data)
		return (
			<DashboardLayout hideHeader={false} showSidebar title='Contact Details'>
				<div data-testid='get-contact-fail' className='p-10'>
					<Contact color='black' className='text-white' size={30} />
					<div className='mt-3 font-semibold'>
						{error ?? 'Contact not found'}
					</div>
				</div>
			</DashboardLayout>
		)

	return (
		<DashboardLayout hideHeader={false} showSidebar title='Contact Details'>
			<div className='sticky top-0 flex h-[calc(100vh-3rem)] flex-wrap justify-between gap-5 bg-gray-50 p-4 pb-0'>
				<div className='flex h-full flex-1 flex-col gap-3 overflow-auto'>
					<ContactDetailsHeader contact={data} />
					<ContactDetailsInfo contact={data} />
					<ContactRelations contact={data} />
					<ContactActivities contact={data} />
				</div>
				<div className='flex h-full rounded-md border bg-white lg:w-[35vw]'>
					<div className='w-full'>
						<ContactNotes contact={data} />
					</div>
				</div>
			</div>
		</DashboardLayout>
	)
}
