import * as Yup from 'yup'

import {
	addBankAccount,
	hasDefaultBankAccount,
	updateBankAccount
} from '../api/bank-accounts.api'

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { AuthContext } from 'context/AuthContext'
import type { IBankAccount } from '../interface/BankAccount.interface'
import { nanoid } from 'nanoid'
import { toast } from 'sonner'
import { useContext } from 'react'
import { useFormik } from 'formik'

interface IProps {
	initialValue: Partial<IBankAccount>
	editMode?: boolean
	onSuccess?: () => void
}

export function useCreateBankAccount({
	initialValue,
	editMode = false,
	onSuccess
}: IProps) {
	const { authUser } = useContext(AuthContext)

	const validationSchema = Yup.object({
		title: Yup.string().required('Title is required'),
		accountName: Yup.string().required('Account Name is required'),
		accountNumber: Yup.string().required('Account Number is required'),
		accountAddress: Yup.string().required('Account Address is required'),
		bankName: Yup.string().required('Bank Name is required'),
		routingNumber: Yup.string(),
		swiftCode: Yup.string(),
		bankAddress: Yup.string().required('Bank Address is required')
	})

	const initialValues = {
		title: initialValue?.title || '',
		accountName: initialValue?.accountName || '',
		accountNumber: initialValue?.accountNumber || '',
		accountAddress: initialValue?.accountAddress || '',
		bankName: initialValue?.bankName || '',
		routingNumber: initialValue?.routingNumber || '',
		swiftCode: initialValue?.swiftCode || '',
		bankAddress: initialValue?.bankAddress || ''
	}

	const formik = useFormik({
		initialValues,
		validationSchema,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onSubmit: async (value: any) => {
			try {
				if (!authUser) return

				formik.setSubmitting(true)

				const hasDefaultAccount = await hasDefaultBankAccount()

				const bankAccount: IBankAccount = {
					id: initialValue?.id || nanoid(),
					paymentType: 'bank',
					createdAt: initialValue?.createdAt || new Date().toISOString(),
					lastUpdatedAt: new Date().toISOString(),
					accountAddress: value.accountAddress,
					accountName: value.accountName,
					accountNumber: value.accountNumber,
					bankAddress: value.bankAddress,
					bankName: value.bankName,
					routingNumber: value.routingNumber,
					swiftCode: value.swiftCode,
					title: value.title,
					fund: authUser?.fund
				}

				await (editMode
					? updateBankAccount(bankAccount)
					: addBankAccount({ ...bankAccount, isDefault: !hasDefaultAccount }))
			} catch (error: any) {
				toast.error(error.message)
			} finally {
				formik.setSubmitting(false)
				formik.resetForm()

				if (onSuccess) onSuccess()
			}
		}
	})

	return { formik }
}
