/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup'

import { useAppDispatch, useAppSelector } from 'app/hooks'

import Button from 'components/Buttons/Button'
import CardWrapper from '../components/CardWrapper'
import { CustomDropDown } from 'components/ui/CustomDropDown'
import { CustomInput } from 'components/Inputs/CustomInput'
import { checkFundExists } from '../service/onboarding.service'
import { setOnboardingData } from '../redux/onboarding.slice'
import { useFormik } from 'formik'

const COUNTRIES = [
	'United States',
	'United Kingdom',
	'Canada',
	'Australia',
	'New Zealand'
]

const CURRENCIES = ['USD', 'EUR', 'AUD', 'NZD']

// Custom validation method for URLs with or without protocol
const urlWithoutProtocolValidation = Yup.string().test(
	'is-url-or-domain',
	'Invalid URL',
	value => {
		if (!value) return true

		// Regex to check if the string starts with a protocol (http, https)
		const protocolRegex = /^(https?:\/\/)/

		// If the string has a protocol, we let YUP's built-in url validation handle it
		if (protocolRegex.test(value)) {
			return Yup.string().url().isValidSync(value)
		}

		// If there's no protocol, we assume it's a domain and do a basic validation
		const domainRegex = /^[\dA-Za-z-]{1,63}\.[A-Za-z]{2,}$/
		return domainRegex.test(value)
	}
)

interface IGeneralFundInfo {
	nextStep: () => void
}

// General Fund Information Component
export default function GeneralFundInfo({ nextStep }: IGeneralFundInfo) {
	const onboardingData = useAppSelector(state => state.onboarding.data)

	const dispatch = useAppDispatch()

	const formik = useFormik({
		initialValues: {
			fundName: onboardingData.fundName || '',
			website: onboardingData.website || '',
			country: onboardingData.country || COUNTRIES[0],
			currency: onboardingData.currency || CURRENCIES[0]
		},
		validateOnChange: true,
		validationSchema: Yup.object({
			fundName: Yup.string()
				.required('Fund Name is required')
				.test('unique', 'Fund already exists', async val => {
					const slug = val.toLowerCase().replaceAll(' ', '-')
					const response = await checkFundExists(val, slug)
					return !response
				}),
			website: urlWithoutProtocolValidation
		}),
		onSubmit: values => {
			dispatch(
				setOnboardingData({
					fundName: values.fundName,
					slug: values.fundName.toLowerCase().replaceAll(' ', '-'),
					website: values.website,
					country: values.country,
					currency: values.currency
				})
			)
			nextStep()
		}
	})

	return (
		<CardWrapper title='General Fund Information'>
			<div className='space-y-4'>
				<CustomInput
					label='Fund Name'
					placeholder='Fund Name'
					isRequired
					onChange={(e: any) =>
						formik.setFieldValue('fundName', e.target.value)
					}
					value={formik.values.fundName}
					onBlur={formik.handleBlur('fundName')}
					error={formik.touched.fundName ? formik.errors.fundName : ''}
				/>

				<CustomInput
					label='Website'
					placeholder='Website'
					onChange={(e: any) => formik.setFieldValue('website', e.target.value)}
					value={formik.values.website}
					onBlur={formik.handleBlur('website')}
					error={formik.touched.website ? formik.errors.website : ''}
				/>

				<div className='flex gap-4'>
					<CustomDropDown
						label='Country'
						options={COUNTRIES}
						value={formik.values.country}
						onClick={(val: string) => {
							formik.setFieldValue('country', val)
						}}
					/>

					<CustomDropDown
						label='Currency'
						isRequired
						options={CURRENCIES}
						value={formik.values.currency}
						onClick={(val: string) => {
							formik.setFieldValue('currency', val)
						}}
					/>
				</div>

				<Button className='w-full py-2' onClick={() => formik.handleSubmit()}>
					Continue
				</Button>
			</div>
		</CardWrapper>
	)
}
