import * as Yup from 'yup'

import { AuthContext } from 'context/AuthContext'
import type { CompanyData } from 'interfaces'
import { FileService } from 'features/files/services/file.service'
import type { IInvestment } from '../interfaces/investment.interface'
import { InvestmentService } from '../service/investment.service'
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import type { SelectedFile } from 'components/FileSelector'
import logger from 'lib/logger'
import { nanoid } from 'nanoid'
import { toast } from 'sonner'
import { useContext } from 'react'
import { useFormik } from 'formik'

export default function useCreateInvestment({
	investment,
	company,
	onSuccess
}: {
	investment?: IInvestment
	company: CompanyData
	onSuccess?: () => void
}) {
	const { authUser } = useContext(AuthContext)

	const formik = useFormik({
		initialValues: {
			date: investment?.date || new Date().toISOString(),
			investmentAmount: investment?.amount || 0,
			currency: investment?.currency || 'USD',
			stage: investment?.stage || 'Pre-Seed',
			round: investment?.round || 'Safe Note',
			level: investment?.level || 'Pre',
			valuation: investment?.valuation || 0,
			amountRaised: investment?.totalAmountRaised || 0,
			discount: investment?.discount || 0,
			files: investment?.files || [],
			newFiles: []
		},
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: Yup.object({
			date: Yup.string().required('Date is required'),
			investmentAmount: Yup.number().required('Required'),
			amountRaised: Yup.number()
				.required('Amount raised is required')
				.min(0, 'Amount raised must be greater than 0'),
			stage: Yup.string().required('Stage is required')
		}),
		onSubmit: async values => {
			if (!authUser) return
			try {
				toast.loading('Creating investment...')

				let allFiles = values.files

				if (values.newFiles.length > 0) {
					const filesResults = await FileService.saveFiles({
						files: values.newFiles.map((f: SelectedFile) => f.file),
						type: 'company_investment',
						authUser,
						company
					})

					const filesData = filesResults.map(file => ({
						id: file.id,
						url: file.url,
						name: file.name
					}))

					allFiles = [...values.files, ...filesData]
				}

				const data: IInvestment = {
					id: investment?.id || nanoid(),
					stage: values.stage,
					round: values.round,
					level: values.level,
					amount: values.investmentAmount,
					valuation: values.valuation,
					totalAmountRaised: values.amountRaised,
					company: {
						id: company.id,
						name: company.name,
						website: company.website ?? ''
					},
					creator: {
						id: authUser.id,
						name: authUser.name,
						photoUrl: authUser.photoUrl || ''
					},
					files: allFiles,
					fileIds: allFiles.map(file => file.id),
					fund: investment?.fund || authUser.fund,
					discount: 0,
					date: values.date,
					createdAt: investment?.createdAt || new Date().toISOString(),
					lastUpdatedAt: new Date().toISOString(),
					currency: values.currency,
					amounts: []
				}

				await (investment?.createdAt
					? InvestmentService.updateInvestment(data)
					: InvestmentService.addInvestment(data))

				formik.setSubmitting(true)

				onSuccess?.()

				toast.dismiss()
				toast.success('Investment created successfully')
			} catch (error: any) {
				logger.error(error)

				toast.dismiss()
				toast.error(error.message ?? 'Failed to create investment')
			} finally {
				formik.setSubmitting(false)
				formik.resetForm()
			}
		}
	})

	return {
		formik
	}
}
