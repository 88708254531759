/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx";
import { SlidersHorizontal, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type { IFilter } from "../interfaces/filter.interface";

export default function useDataFilterHook() {

  const location = useLocation()
  const navigate = useNavigate()
  const [selectedFilters, setSelectedFilters] = useState<IFilter[]>([])

  function removeFilter(index: number) {
    const newFilters = selectedFilters.filter((_, i) => i !== index)
    const formattedFilters = newFilters.map((item) => (
      `${item.filter}-${item.operation}-${item.value}`
    )).join(',')
    navigate(`${window.location.pathname}?filters=${formattedFilters}`)

    if (newFilters.length === 0) {
      navigate(`${window.location.pathname}`)
    }
  }

  function addFilter(data: IFilter) {
    const selectionExists = selectedFilters.find((item) => item.filter === data.filter && item.operation === data.operation && item.value === data.value)
    if (selectionExists) return

    const newFilters = [...selectedFilters, data]
    const formattedFilters = newFilters.map((item) => (
      `${item.filter}-${item.operation}-${item.value}`
    )).join(',')
    navigate(`${window.location.pathname}?filters=${formattedFilters}`)
  }

  function addAllFilters(filters: IFilter[]) {
    const formattedFilters = filters.map((item) => (
      `${item.filter}-${item.operation}-${item.value}`
    )).join(',')
    navigate(`${window.location.pathname}?filters=${formattedFilters}`)

  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filters = queryParams.get('filters');
    const selectedFiltersValues = filters?.split(',').map((item) => {
      const [filter, operation, value] = item.split('-')
      return {
        filter,
        operation,
        value
      }
    }) ?? []
    setSelectedFilters(selectedFiltersValues)
  }, [location])

  function renderSelectedFilters(
    {
      readonly = false,
      variant = 'horizontal'
    }: {
      readonly?: boolean,
      variant: 'horizontal' | 'vertical'
    }
  ) {
    return <div>
      {selectedFilters.length > 0 && (
        <div className='flex w-full items-center gap-1'>
          {
            variant === 'horizontal' && <div className='flex items-center gap-1 text-xs pt-3'>
              <SlidersHorizontal size={15} />
              Filters ({selectedFilters.length})
            </div>
          }
          <div className={
            clsx(
              'flex gap-2 overflow-x-auto pt-3',
              variant === 'vertical' && 'flex-col'
            )
          }>
            {selectedFilters.map((filter, index) => (
              <div
                key={filter.filter}
                className='flex items-center gap-2 rounded border bg-gray-50'
              >
                <div className='truncate rounded border border-red-400 bg-red-100 px-1 text-xs text-red-700'>
                  {filter.filter}
                </div>
                <div className='truncate text-xs'>{filter.operation}</div>
                <div className='truncate rounded border border-blue-400 bg-blue-100 px-1 text-xs text-blue-700'>
                  {filter.value}
                </div>
                {!readonly &&
                  <div
                    className='cursor-pointer p-1 transition-all hover:scale-110'
                    onClick={() => {
                      removeFilter(index);
                    }}
                  >
                    <Trash2 color='red' className='text-white' size={15} />
                  </div>
                }
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  }


  return {
    selectedFilters,
    removeFilter,
    addFilter,
    renderSelectedFilters,
    addAllFilters
  }
}