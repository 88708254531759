/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { AuthContext } from 'context/AuthContext'
import { selectFundCurrency } from 'features/authentication/authentication.slice'
import { listenToDashboard } from 'features/dashboard/dashboard.api'
import { selectDashboard } from 'features/dashboard/dashboard.slice'
import { listenToLabelCounts } from 'features/mail/api/threads.api'
import { selectLabelCounts } from 'features/mail/redux/threads.slice'
import axiosInstance from 'lib/axios'
import Logger from 'lib/logger'
import { RefreshCcw, TrendingDown, TrendingUp } from 'lucide-react'
import { useContext, useEffect, useState, type ReactElement } from 'react'
import { displayAmount, getConvertedAmounts } from 'utils/currency'

function SummaryBox({
	title,
	value,
	icon,
	subTitle = '',
	onRefresh
}: {
	title: string
	value: string
	icon: ReactElement
	subTitle: string
	onRefresh?: () => Promise<void>
}): ReactElement {
	const [refreshing, setRefreshing] = useState(false)

	const handleRefresh = async () => {
		try {
			setRefreshing(true)
			await onRefresh?.()
		} catch (error) {
			Logger.error('Error refreshing amount in bank', error)
		} finally {
			setRefreshing(false)
		}
	}
	return (
		<div>
			<div className='h-[7rem] min-w-[15rem] rounded-md border p-2 dark:border-gray-700 dark:bg-gray-900'>
				<div className='flex flex-row justify-between'>
					<div>{title}</div>
					{onRefresh && (
						<div onClick={handleRefresh} className='cursor-pointer'>
							<RefreshCcw
								size={16}
								className={refreshing ? 'animate-spin' : ''}
							/>
						</div>
					)}
				</div>
				<div className='text-2xl font-semibold'>{value}</div>
				<div className='mt-2 flex items-center gap-2 text-sm'>
					{icon}
					{subTitle}
				</div>
			</div>
		</div>
	)
}

const refreshAmountInBank = async () => {
	try {
		await axiosInstance.get('/wise/fetch-balances')
	} catch (error) {
		Logger.error('Error refreshing amount in bank', error)
	}
}

export default function ReportsSummary(): ReactElement {
	const dispatch = useAppDispatch()
	const { authUser } = useContext(AuthContext)

	const dashboardData = useAppSelector(selectDashboard)
	const labelCounts = useAppSelector(selectLabelCounts)
	const fundCurrency = useAppSelector(selectFundCurrency)

	const [companiesDifference, setCompaniesDifference] = useState(0)
	const [investmentsDifference, setInvestmentsDifference] = useState(0)
	const [tasksDifference, setTasksDifference] = useState(0)
	const [inboxDifference, setInboxDifference] = useState(0)

	useEffect(() => {
		if (!authUser) return

		const unsubscribe = dispatch(listenToDashboard(authUser.fund.id))
		const unsubscribeLabelCounts = listenToLabelCounts(authUser.id)

		return () => {
			unsubscribe()
			unsubscribeLabelCounts()
		}
	}, [authUser])

	useEffect(() => {
		if (!dashboardData) return

		setCompaniesDifference(
			(dashboardData.companies?.thisMonth ?? 0) -
				(dashboardData.companies?.lastMonth ?? 0)
		)

		setTasksDifference(
			(dashboardData.tasks?.thisMonth ?? 0) -
				(dashboardData.tasks?.lastMonth ?? 0)
		)

		setInboxDifference(labelCounts?.spokInbox?.length ?? 0)

		const thisMonth = getConvertedAmounts(
			dashboardData.totalInvestment?.thisMonth
		)
		const lastMonth = getConvertedAmounts(
			dashboardData.totalInvestment?.lastMonth
		)
		const difference = thisMonth.amount - lastMonth.amount
		setInvestmentsDifference(difference)
	}, [dashboardData])

	if (!authUser) return <div />

	if (!dashboardData) return <div />

	const renderTrendingIcon = (quantity: number) => {
		if (quantity > 0) {
			return <TrendingUp className='text-white' color='black' size={15} />
		}
		if (quantity === 0) {
			return <div>-</div>
		}
		return <TrendingDown className='text-white' color='black' size={15} />
	}

	return (
		<div className='flex flex-wrap gap-5 p-5'>
			<SummaryBox
				title='Companies'
				value={dashboardData.companies?.total.toString() ?? '0'}
				icon={renderTrendingIcon(companiesDifference)}
				subTitle={`${companiesDifference} ${
					companiesDifference === 1 ? 'company' : 'companies'
				} this month`}
			/>

			<SummaryBox
				title='Portfolio Investments'
				value={displayAmount(
					getConvertedAmounts(dashboardData.totalInvestment?.total)
				)}
				icon={renderTrendingIcon(investmentsDifference)}
				subTitle={`${displayAmount({
					currency: fundCurrency,
					amount: investmentsDifference
				})} this month`}
			/>

			<SummaryBox
				title='Spok Inbox'
				value={labelCounts?.spokInbox?.length.toString() ?? '0'}
				icon={renderTrendingIcon(inboxDifference)}
				subTitle={`${inboxDifference} new ${
					inboxDifference === 1 ? 'message' : 'messages'
				}`}
			/>

			<SummaryBox
				title='Tasks'
				value={dashboardData.tasks?.total.toString() ?? '0'}
				icon={renderTrendingIcon(tasksDifference)}
				subTitle={`${tasksDifference} ${
					tasksDifference === 1 ? 'task' : 'tasks'
				} this month`}
			/>

			<SummaryBox
				title='Amount In Bank'
				onRefresh={refreshAmountInBank}
				value={displayAmount(
					getConvertedAmounts(dashboardData.amountInBank?.total)
				)}
				icon={renderTrendingIcon(0)}
				subTitle={`${displayAmount({
					currency: fundCurrency,
					amount: 0
				})} this month`}
			/>
		</div>
	)
}
