/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthContext } from 'context/AuthContext'
import { useFormik } from 'formik'
import type { ITag } from 'interfaces'
import { nanoid } from 'nanoid'
import { useContext } from 'react'
import { toast } from 'sonner'
import { getColorCode } from 'utils/colors'
import * as Yup from 'yup'
import { addTag } from '../tags.api'

export default function useCreateTag({ onSuccess }: { onSuccess: () => void }) {
	const { authUser } = useContext(AuthContext)

	const validationSchema = Yup.object({
		name: Yup.string().required('Name is required'),
		category: Yup.string()
	})

	const formik = useFormik({
		initialValues: {
			name: '',
			category: ''
		},
		validationSchema,
		onSubmit: async values => {
			if (!authUser?.fund) return
			toast.loading('Creating tag')
			try {
				const tagData: ITag = {
					id: nanoid(),
					name: values.name,
					category: {
						name: values.category
					},
					color: `#${getColorCode(values.name)}`,
					createdAt: new Date().toISOString(),
					lastUpdatedAt: new Date().toISOString(),
					fund: authUser.fund,
					creator: {
						id: authUser.id,
						name: authUser.name,
						photoUrl: authUser.photoUrl ?? ''
					},
					isDeleted: false
				}

				await addTag(tagData)

				formik.resetForm()

				toast.dismiss()
				toast.success('Tag created')

				onSuccess()
			} catch (error: any) {
				toast.error(error.message ?? 'Failed to create tag')
			}
		}
	})

	return { formik }
}
