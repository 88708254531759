/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-extraneous-class */
import {
	collection,
	getDocs,
	onSnapshot,
	query,
	where
} from 'firebase/firestore'

import Errorhandler from 'lib/sentry'
import Logger from 'lib/logger'
import axiosInstance from 'lib/axios'
import { firestore } from 'lib/firebase'
import { toast } from 'sonner'
import type { IMemo } from '../interfaces/memo.interface'

export class InvestmentMemoService {
	private static readonly collection = collection(firestore, 'oracle_tasks')

	public static async getInvestmentMemo(companyId: string): Promise<any> {
		try {
			const q = query(this.collection, where('spokCompanyId', '==', companyId))

			const snapshot = await getDocs(q)

			if (snapshot.empty) {
				return null
			}

			return snapshot.docs[0].data()
		} catch (error: any) {
			toast.error(error?.message ?? 'Failed to delete file')

			Logger.error(error)
			Errorhandler.captureException(error)

			return null
		}
	}

	public static listenToInvestmentMemoSections(
		companyId: string,
		callback: (data: any) => void
	) {
		const q = query(this.collection, where('spokCompanyId', '==', companyId))

		const unsubscribe = onSnapshot(q, snapshot => {
			if (snapshot.empty) {
				callback(null)
			} else {
				callback(snapshot.docs[0].data())
			}
		})

		return unsubscribe
	}

	public static listenToCompanyInvestmentMemos(
		companyId: string,
		callback: (data: IMemo[]) => void
	) {
		const collectionQuery = query(
			collection(firestore, 'investment_memos'),
			where('company.id', '==', companyId)
		)

		const unsubscribe = onSnapshot(collectionQuery, snapshot => {
			if (snapshot.empty) {
				callback([])
			} else {
				callback(snapshot.docs.map(doc => doc.data() as IMemo))
			}
		})

		return unsubscribe
	}

	public static async generateInvestmentMemo(
		companyId: string,
		companyName: string
	): Promise<void> {
		try {
			toast.info('Generating Investment Memo')

			const { data } = await axiosInstance.post(
				`/oracle/generate-investment-memo`,
				{ companyId, companyName }
			)

			toast.dismiss()
			if (data?.success) {
				toast.success('Investment memo generated successfully')
			} else {
				toast.error('Failed to generate investment memo')
			}

			// Call the API to generate the investment memo
		} catch (error: any) {
			toast.dismiss()

			const errorMessage =
				error?.response?.data?.message ||
				error?.message ||
				'Failed to generate investment memo'
			toast.error(errorMessage)

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}
}
