/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react/no-danger */

import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'

import {
	Kanban,
	Maximize2,
	Minimize2,
	MoreVertical,
	Pencil,
	Pin,
	PinOff,
	Trash2
} from 'lucide-react'

import Button from 'components/Buttons/Button'
import DeleteModal from 'components/DeleteModal'
import {
	NoteAction,
	NoteEditorState,
	type INote
} from '../interfaces/notes.interface'
import { NoteService } from '../services/notes.service'
import { addDraftNote } from '../redux/draft_notes.slice'
import clsx from 'clsx'
import { purifyHtml } from 'utils/purifyHtml'
import { renderDate } from 'utils/date.util'
import { store } from 'app/store'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import {
	Popover,
	PopoverContent,
	PopoverTrigger
} from '@radix-ui/react-popover'
import { useSelector } from 'react-redux'
import { selectAuthUser } from 'features/authentication/authentication.slice'
import TaskManagerModal from 'features/tasks/view/TaskManagerModal'
import { generateTaskFromNote } from 'features/tasks/helpers/note_to_task.helper'

const noteLengthThreshold = 100

interface INoteItemProps {
	note: INote
	isReadOnly?: boolean
	actions?: NoteAction[]
}

export const DEFAULT_NOTE_ACTIONS = [NoteAction.Pin, NoteAction.Delete]

export default function NoteItem({
	note,
	isReadOnly,
	actions = DEFAULT_NOTE_ACTIONS
}: INoteItemProps) {
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const selectedNoteId = searchParams.get('selected')

	const [isTaskManagerOpen, setIsTaskManagerOpen] = useState(false)

	const authUser = useSelector(selectAuthUser)
	const newTask = generateTaskFromNote(note, authUser)

	const [showFullScreen, setShowFullScreen] = useState(
		selectedNoteId && note && note.id === selectedNoteId
	)

	return (
		<div>
			<div className='mb-2 flex w-full translate-x-2 items-center justify-end pr-3 opacity-100 transition-all group-hover:translate-x-0 group-hover:opacity-100'>
				<div className='flex items-center gap-1'>
					<Avatar className='h-6 w-6'>
						<AvatarImage src={note.creator?.photoUrl} alt='profile' />
						<AvatarFallback>{note.creator?.name.charAt(0)}</AvatarFallback>
					</Avatar>
					<div className='text-sm'>{note.creator?.name}</div>
				</div>
			</div>
			<div
				className={clsx(
					'flex justify-between border border-b border-gray-200 bg-gray-100 p-3',
					showFullScreen ? 'rounded-t-md' : 'rounded-md '
				)}
			>
				<div>
					{/* Title */}
					<div
						className=''
						dangerouslySetInnerHTML={{ __html: purifyHtml(note.title || '') }}
					/>
					<div className='items-center  gap-3 pr-3 opacity-100 transition-all group-hover:translate-x-0 group-hover:opacity-100'>
						<div className='text-xs'>{renderDate(note.createdAt)}</div>
					</div>
				</div>

				<div className='flex items-start gap-1'>
					{showFullScreen && (
						<Button
							id={`edit-note-${note.id}`}
							variant='ghost'
							className='flex w-full items-start justify-start px-2 py-2'
							onClick={() => {
								store.dispatch(
									addDraftNote({
										...note,
										windowState: NoteEditorState.RESTORED
									})
								)
							}}
						>
							<div className='flex items-center gap-2'>
								<Pencil size={18} />
							</div>
						</Button>
					)}

					{(note.title || note.note?.length > noteLengthThreshold) && (
						<button
							type='button'
							id={`maximize-note-${note.id}`}
							className='p-2'
							onClick={() => {
								setShowFullScreen(!showFullScreen)
							}}
						>
							{showFullScreen ? (
								<Minimize2 size={18} color='blue' />
							) : (
								<Maximize2 size={18} color='blue' />
							)}
						</button>
					)}

					{!isReadOnly && (
						<Popover>
							<PopoverTrigger>
								<button
									type='button'
									id={`more-vert-note-item-${note.id}`}
									className='p-2'
								>
									<MoreVertical size={18} />
								</button>
							</PopoverTrigger>
							<PopoverContent className='z-[9999] mr-14 w-[11rem] rounded-lg bg-white p-2 shadow-lg'>
								{actions.includes(NoteAction.Pin) && (
									<Button
										variant='ghost'
										className='flex w-full items-start justify-start px-2 py-2'
										onClick={() => {
											NoteService.pinNote(note, !note.isPinned)
										}}
									>
										<div className='flex items-center gap-2'>
											{note.isPinned ? <PinOff size={16} /> : <Pin size={18} />}{' '}
											{note.isPinned ? 'Unpin note' : 'Pin note'}
										</div>
									</Button>
								)}

								{actions.includes(NoteAction.CreateTask) && (
									<Button
										variant='ghost'
										id='open-add-company-task'
										className='flex w-full items-start justify-start px-2 py-2'
										onClick={() => setIsTaskManagerOpen(true)}
									>
										<div className='flex items-center gap-2'>
											<Kanban size={18} />
											Create task
										</div>
									</Button>
								)}

								{actions.includes(NoteAction.Delete) && (
									<DeleteModal
										deleteButtonId={`delete-note-${note.id}`}
										title='Delete Note'
										triggerClassName='flex w-full items-center justify-start gap-2 text-red-500'
										description='Are you sure you want to delete this note?'
										trigger={
											<>
												<Trash2 size={16} color='red' /> Delete note
											</>
										}
										onDelete={() => NoteService.deleteNote(note)}
									/>
								)}
							</PopoverContent>
						</Popover>
					)}
				</div>
			</div>

			<TaskManagerModal
				isOpen={isTaskManagerOpen}
				setIsOpen={setIsTaskManagerOpen}
				task={newTask}
			/>

			{showFullScreen && (
				<div
					className='rounded-b-md border border-gray-200 bg-gray-100 p-3 text-sm'
					dangerouslySetInnerHTML={{ __html: purifyHtml(note.note) }}
				/>
			)}
		</div>
	)
}
