/* eslint-disable no-param-reassign */
import type { INoteSettings } from '../interface/note_settings.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface NoteSettingsState {
	data: INoteSettings | null
	isLoading: boolean
	error?: string | null
}

const initialState: NoteSettingsState = {
	data: null,
	isLoading: false,
	error: null
}

export const noteSettingsSlice = createAppSlice({
	name: 'noteSettings',
	initialState,
	reducers: create => ({
		setNoteSettings: create.reducer(
			(state, action: PayloadAction<INoteSettings>) => {
				state.data = action.payload
				state.isLoading = false
			}
		),
	}),
	selectors: {
		selectNoteSettings: state => state,
	}
})

export const { setNoteSettings } = noteSettingsSlice.actions

export const { selectNoteSettings } = noteSettingsSlice.selectors
