import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import type { ILimitedPartner } from 'interfaces'
import { Link } from 'react-router-dom'
/* eslint-disable @typescript-eslint/no-explicit-any */
import Tags from 'components/Tags'
import clsxm from 'utils/clsxm'
import { renderDate } from 'utils/date.util'

interface Props {
	prospect: ILimitedPartner
	isDragging?: boolean
}

function LPCard({ prospect, isDragging }: Props) {
	return (
		<div
			data-testid={`prospect-${prospect.name}`}
			className={clsxm([
				'flex h-full gap-3 rounded  border border-white bg-white p-4  text-gray-800  transition-all hover:bg-gray-50 ',
				'hover:border-gray-200 dark:hover:border-gray-600',
				'hover:cursor-pointer dark:bg-[#374151] dark:text-white',
				{ ' border-green-500': isDragging }
			])}
		>
			<Link
				to={
					prospect.isApproved
						? `/limited-partner/${prospect.id}`
						: `/prospects/${prospect.id}`
				}
				className='flex  w-full items-start gap-3'
			>
				<div className='flex flex-col items-center justify-center gap-2'>
					<div>
						<div className=' flex h-8 w-8 items-start rounded-full'>
							<Avatar className='h-8 w-8 border'>
								<AvatarImage
									src={prospect.photoUrl || '/images/logo/Logo-4.png'}
								/>
								<AvatarFallback>{prospect.name[0]}</AvatarFallback>
							</Avatar>
						</div>

						{prospect.pointPerson && prospect.pointPerson.name.length > 0 && (
							<div className='mt-1 flex flex-col items-center gap-1'>
								<div className='h-5 w-[1px] rounded bg-gray-300' />
								<div className='h-6 w-6 rounded-full bg-gray-200'>
									<Tooltip>
										<TooltipTrigger asChild>
											<Avatar className='h-6 w-6'>
												<AvatarImage
													src={
														prospect.website
															? `https://www.google.com/s2/favicons?sz=128&domain_url=${prospect.pointPerson.photoUrl}`
															: '/images/logo/Logo-4.png'
													}
												/>
												<AvatarFallback>C</AvatarFallback>
											</Avatar>
										</TooltipTrigger>
										<TooltipContent>
											<div>{prospect.pointPerson.name}</div>
										</TooltipContent>
									</Tooltip>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='flex-1 gap-x-3'>
					<h2 className='line-clamp-1 whitespace-pre-wrap text-base font-semibold text-black'>
						{prospect.name}
					</h2>

					<div className=' py-2'>
						<Tags tags={prospect.tags || []} wrap maxNumber={2} />
					</div>
					<div className='mt-3 flex items-center gap-1 text-xs text-gray-500'>
						{renderDate(prospect.createdAt)}
					</div>
				</div>
			</Link>
			<div className={clsxm('flex flex-col items-center justify-between ')}>
				{/* <CompanyCardMenu company={prospect} funnels={funnels} /> */}
			</div>
		</div>
	)
}

export default LPCard
