import type { FundData, ICompany } from 'interfaces'
import type { ITask, ITaskFunnel } from '../interafces/task.interface'
import { doc, setDoc } from 'firebase/firestore'
import {
	selectFunnelStatus,
	selectTaskFunnels
} from 'features/funnels/funnels.slice'
import { useEffect, useMemo, useState } from 'react'

import Button from 'components/Buttons/Button'
import CKEditorView from 'features/ckeditor/view/ckeditor'
import type { Column } from 'features/kanban/views/KanbanView'
import CompanySearchSelector from 'features/companies/view/CompanySearchSelector'
import { ImSpinner10 } from 'react-icons/im'
import KanbanView from 'features/kanban/views/KanbanView'
import { PartnerSelector } from 'components/shadcn/PartnerSelector'
import SideDrawer from 'components/SideDrawer'
import SpokDatePicker from 'components/shadcn/SpokDatePicker'
import TaskCard from '../components/TaskCard'
import TaskFunnelSelector from '../components/TaskFunnelSelector'
import TaskFunnelsService from '../services/task-funnels.service'
import dashify from 'dashify'
import { firestore } from 'lib/firebase'
import { listenToTaskFunnels } from '../services/tasks.service'
import { nanoid } from 'nanoid'
import { selectAuthUser } from 'features/authentication/authentication.slice'
import { toast } from 'sonner'
import { updateTask } from '../tasks.api'
import { useSelector } from 'react-redux'
import useUpdateTask from '../hooks/useUpdateTask'

interface ITasksBoardProps {
	tasks: ITask[]
	isLoading: boolean
}

function TasksBoard({ tasks, isLoading }: Readonly<ITasksBoardProps>) {
	const authUser = useSelector(selectAuthUser)
	const taskFunnels = useSelector(selectTaskFunnels)
	const isTaskFunnelsLoading = useSelector(selectFunnelStatus)

	const [selectedTask, setSelectedTask] = useState<ITask>()
	const [showTaskModal, setShowTaskModal] = useState(false)

	const { formik: taskForm } = useUpdateTask({
		task: selectedTask,
		onUpdate: async (data: Partial<ITask>) => {
			updateTask(data)
			setShowTaskModal(false)
		}
	})

	useEffect(() => {
		if (!authUser?.fund) return

		const unsubscribeTaskFunnels = listenToTaskFunnels(authUser.fund.id)

		// eslint-disable-next-line consistent-return
		return () => unsubscribeTaskFunnels()
	}, [authUser?.fund])

	async function createNewColumn(column: Column, index: number) {
		const newFunnel: ITaskFunnel = {
			id: nanoid(),
			category: dashify(column.id),
			color: 'gray',
			creator: {
				id: authUser?.id as string,
				name: authUser?.name as string,
				photoUrl: authUser?.photoUrl
			},
			fund: authUser?.fund as FundData,
			index,
			isArchived: false,
			isDefault: false,
			name: column.id,
			slug: dashify(column.id),
			type: 'task',
			createdAt: new Date().toISOString(),
			lastUpdatedAt: new Date().toISOString()
		}

		await setDoc(doc(firestore, `task_funnels/${newFunnel.id}`), newFunnel, {
			merge: true
		})

		toast.success('Column added')
	}

	const boardColumns: Column[] = useMemo(() => {
		return taskFunnels.map(funnel => {
			const funnelTasks = tasks.filter(task => task.status === funnel.category)

			return {
				id: funnel.id,
				index: funnel.index,
				items: funnelTasks,
				pureItems: funnelTasks,
				title: funnel.name,
				color: funnel.color,
				category: funnel.category,
				isArchived: funnel.isArchived
			} as Column
		})
	}, [taskFunnels, tasks])

	function setupTaskModal(task: ITask) {
		setSelectedTask(task)
		setShowTaskModal(true)
	}

	return (
		<>
			<div className='px-5'>
				<KanbanView
					columns={boardColumns}
					workspaces={['companies', 'company']}
					loading={isTaskFunnelsLoading}
					itemsLoading={isLoading}
					group='funnel'
					renderItem={item => {
						return (
							<TaskCard
								key={item.item.id}
								task={item.item}
								onCardClick={task => setupTaskModal(task)}
							/>
						)
					}}
					onUpdateFunnels={update => TaskFunnelsService.updateFunnels(update)}
					onItemSameColumnMove={items =>
						TaskFunnelsService.handleTaskMoveInSameColumn(items)
					}
					onItemDifferentColumnMove={({ item, dropResult }) => {
						const taskFunnel = taskFunnels.find(
							funnel => funnel.id === dropResult.destination?.droppableId
						)

						TaskFunnelsService.handleTaskMoveToDifferentColumn(
							item,
							dropResult,
							taskFunnel as ITaskFunnel
						)
					}}
					onColumnAdd={(column: Column) => {
						createNewColumn(column, taskFunnels.length)
					}}
					entity='task'
				/>
			</div>

			<SideDrawer
				dialogPanelClass='max-w-xl'
				title='Update task'
				show={showTaskModal}
				setShow={setShowTaskModal}
			>
				<div className=''>
					<div className='mb-5 grid grid-cols-2 gap-5'>
						<PartnerSelector
							label='Executor'
							className='w-full'
							placeholder='Select executor'
							onSelect={value => {
								taskForm.setFieldValue('executor', value)
							}}
							defaultValue={selectedTask?.executor}
						/>

						<SpokDatePicker
							label='Due Date'
							onSelect={value => {
								taskForm.setFieldValue('dueDate', value?.toISOString())
							}}
							defaultValue={
								typeof taskForm.values.dueDate === 'string'
									? new Date(taskForm.values.dueDate)
									: taskForm.values.dueDate
							}
							className='w-full rounded-md border px-2 py-1'
						/>
					</div>
					<div className='relative z-[9999] grid grid-cols-2 gap-x-5'>
						<CompanySearchSelector
							label='Company'
							defaultValue={taskForm.values.company as ICompany}
							onSelect={value => {
								const companyData = {
									id: value.id,
									name: value.name,
									website: value.website ?? ''
								}
								taskForm.setFieldValue('company', companyData)
							}}
						/>
						<TaskFunnelSelector
							defaultValue={taskForm.values.status}
							options={taskFunnels.map(funnel => ({
								value: funnel.category,
								label: funnel.name
							}))}
							onSelect={value => {
								taskForm.setFieldValue('status', value)
							}}
						/>
					</div>
					<div className='mt-16'>
						<CKEditorView
							data={taskForm.values.description ?? ''}
							onChange={value => {
								taskForm.setFieldValue('description', value)
							}}
						/>
					</div>

					<div className='mt-10 flex w-full justify-end gap-5'>
						{!taskForm.isSubmitting && (
							<Button
								id='cancel-create-task-button'
								variant='danger'
								onClick={() => setShowTaskModal(false)}
							>
								Cancel
							</Button>
						)}
						<Button
							id='update-task-button'
							variant='dark'
							disabled={taskForm.isSubmitting || !taskForm.isValid}
							onClick={() => taskForm.handleSubmit()}
						>
							{taskForm.isSubmitting ? (
								<div className='flex items-center gap-2'>
									<ImSpinner10 className='animate-spin' /> Saving...
								</div>
							) : (
								<div> Save</div>
							)}
						</Button>
					</div>
				</div>
			</SideDrawer>
		</>
	)
}

export default TasksBoard
