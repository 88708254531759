import { useAppSelector } from 'app/hooks'
import { AuthContext } from 'context/AuthContext'
import { listenToCompanyActivity } from 'features/activity/api/activity.api'
import {
	selectCompanyActivities,
	selectIsActivitiesLoading
} from 'features/activity/redux/activities.slice'
import ActivityLogs from 'features/activity/views/ActivityLogs'
import type { ICompany } from 'interfaces'
import { useContext, useEffect, useState } from 'react'
import { CompanyService } from '../service/company.service'

interface Props {
	company: ICompany
	source?: string
}

export default function CompanyActivities({ company, source }: Props) {
	const { authUser } = useContext(AuthContext)
	const isLoading = useAppSelector(selectIsActivitiesLoading)
	const activities = useAppSelector(state =>
		selectCompanyActivities(state, company.id)
	).filter(activity => {
		if (!source) return true
		return activity.source === source
	})
	const [totalEmails, setTotalEmails] = useState(0)

	useEffect(() => {
		const unsubscribe = listenToCompanyActivity(company.id)
		return () => unsubscribe()
	}, [company.id])

	useEffect(() => {
		if (!authUser?.id) return

		const fetchEmails = async () => {
			const count = await CompanyService.getTotalEmails(authUser.id, company)
			setTotalEmails(count)
		}

		fetchEmails()
	}, [company, authUser?.id])

	return (
		<ActivityLogs
			activities={activities}
			loading={isLoading}
			totalEmails={totalEmails}
			totalNotes={company.totalNotes}
			eventEmails={company.founderEmails || []}
		/>
	)
}
