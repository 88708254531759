/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'
import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	updateDoc,
	where
} from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import type { IFunnel } from './funnels.interface'
import { setFunnelLoadingStatus, setFunnels } from './funnels.slice'

export const funnelsRef = collection(firestore, 'funnels')

export const getFunnels = async (fundId: string): Promise<IFunnel[]> => {
	const q = query(
		funnelsRef,
		where('fund.id', '==', fundId),
		where('isArchived', '==', false),
		orderBy('index', 'asc')
	)
	const querySnapshot = await getDocs(q)
	const funnels = querySnapshot.docs.map(d => d.data() as IFunnel)
	return funnels
}

export const addFunnel = async (funnel: IFunnel): Promise<IFunnel> => {
	const docRef = doc(funnelsRef, funnel.id)
	await setDoc(docRef, funnel, { merge: true })

	return funnel
}

export const deleteFunnel = async (id: string): Promise<void> => {
	const docRef = doc(funnelsRef, id)
	await deleteDoc(docRef)
}

export const updateFunnel = async (funnel: IFunnel): Promise<IFunnel> => {
	const docRef = doc(funnelsRef, funnel.id)
	await updateDoc(docRef, funnel as any, { merge: true })

	return funnel
}

export const listenToCompanyFunnels = (fundId: string) => {
	const q = query(
		funnelsRef,
		where('fund.id', '==', fundId),
		// where('type', '==', 'company'),
		orderBy('index', 'asc')
	)

	const unsubscribe = onSnapshot(q, querySnapshot => {
		const funnels = querySnapshot.docs.map(d => d.data() as IFunnel)
		store.dispatch(setFunnels(funnels))
		return funnels
	})

	return unsubscribe
}

// Liten to all funnels
export const listenToFunnels = (fundId: string) => {
	const q = query(
		funnelsRef,
		where('fund.id', '==', fundId),
		orderBy('index', 'asc')
	)

	store.dispatch(setFunnelLoadingStatus(true))
	const unsubscribe = onSnapshot(q, querySnapshot => {
		const funnels = querySnapshot.docs.map(d => d.data() as IFunnel)
		store.dispatch(setFunnels(funnels))
		store.dispatch(setFunnelLoadingStatus(false))
		return funnels
	})

	return unsubscribe
}
