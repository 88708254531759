import {
	addFunnel,
	deleteFunnel,
	getFunnels,
	updateFunnel
} from 'features/funnels/funnels.api'

import type { IFunnel } from './funnels.interface'
import Logger from 'lib/logger'
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface FunnelSliceState {
	data: IFunnel[]
	isLoading: boolean
	error?: string | null
}

const initialState: FunnelSliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const funnelSlice = createAppSlice({
	name: 'funnel',
	initialState,
	reducers: create => ({
		setFunnelLoadingStatus: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		fetchFunnels: create.asyncThunk(
			async (fundId: string) => {
				const response = await getFunnels(fundId)
				return response
			},
			{
				pending: state => {
					state.isLoading = true
				},
				fulfilled: (state, action) => {
					state.isLoading = false
					state.data = action.payload
				},
				rejected: (state, action) => {
					state.isLoading = false
					state.error = action.error.message
					// Errorhandler.captureException(action.error)
					Logger.error(action.error)
				}
			}
		),
		createFunnel: create.asyncThunk(
			async (funnel: IFunnel) => {
				const response = await addFunnel(funnel)
				return response
			},
			{
				pending: state => {
					state.isLoading = true
				},
				fulfilled: (state, action) => {
					state.isLoading = false
					state.data.push(action.payload)
				},
				rejected: (state, action) => {
					state.isLoading = false
					state.error = action.error.message
				}
			}
		),
		removeFunnel: create.asyncThunk(
			async (id: string) => {
				await deleteFunnel(id)
				return id
			},
			{
				pending: state => {
					state.isLoading = true
				},
				fulfilled: (state, action) => {
					state.isLoading = false
					state.data = state.data.filter(funnel => funnel.id !== action.payload)
				},
				rejected: (state, action) => {
					state.isLoading = false
					state.error = action.error.message
				}
			}
		),
		editFunnel: create.asyncThunk(
			async (funnel: IFunnel) => {
				await updateFunnel(funnel)
				return funnel
			},
			{
				pending: state => {
					state.isLoading = true
				},
				fulfilled: (state, action) => {
					state.isLoading = false
					state.data = state.data.map(f =>
						f.id === action.payload.id ? action.payload : f
					)
				},
				rejected: (state, action) => {
					state.isLoading = false
					state.error = action.error.message
				}
			}
		),
		setFunnels: create.reducer((state, action: PayloadAction<IFunnel[]>) => {
			state.isLoading = false
			state.data = action.payload
		}),
		setTaskFunnels: create.reducer(
			(state, action: PayloadAction<IFunnel[]>) => {
				state.isLoading = false
				state.data = [...state.data, ...action.payload]
			}
		)
	}),
	selectors: {
		selectFunnels: funnel => funnel.data,
		selectCompanyFunnels: funnel =>
			funnel.data.filter(f => f.type === 'company'),
		selectProspectFunnels: funnel =>
			funnel.data.filter(f => f.type === 'prospect'),
		selectTaskFunnels: funnel => funnel.data.filter(f => f.type === 'task'),
		selectFunnelCount: funnel => funnel.data.length,
		selectFunnelStatus: funnel => funnel.isLoading,
		selectDefaultCompanyFunnel: funnel =>
			funnel.data.find(f => f.type === 'company' && f.isDefault),
		selectDefaultProspectFunnel: funnel =>
			funnel.data.find(f => f.type === 'prospect' && f.isDefault)
	}
})

export const {
	fetchFunnels,
	editFunnel,
	createFunnel,
	removeFunnel,
	setTaskFunnels,
	setFunnelLoadingStatus,
	setFunnels
} = funnelSlice.actions

export const {
	selectCompanyFunnels,
	selectProspectFunnels,
	selectTaskFunnels,
	selectFunnelStatus,
	selectFunnels,
	selectFunnelCount,
	selectDefaultCompanyFunnel,
	selectDefaultProspectFunnel
} = funnelSlice.selectors
