/* eslint-disable react/no-danger */
import clsx from 'clsx'
import Button from 'components/Buttons/Button'
import DeleteModal from 'components/DeleteModal'
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger
} from 'components/ui/dropdown-menu'
import FileViewer from 'features/fileviewers/views/FileViewer'
import EmailDialog from 'features/mail/views/EmailDialog'
import logger from 'lib/logger'
import {
	Mail,
	Maximize2,
	Minimize2,
	MoreVertical,
	Pencil,
	Trash2
} from 'lucide-react'
import { useState } from 'react'
import { renderDate } from 'utils/date.util'
import type {
	IAddUpdate,
	ICompanyUpdate
} from '../interfaces/company_update.interface'
import AddUpdate from './AddUpdate'
import { purifyHtml } from 'utils/purifyHtml'

interface Props {
	isReadOnly?: boolean
	update: ICompanyUpdate
	onEdit: (update: IAddUpdate, files: File[]) => void
	onDelete: (update: ICompanyUpdate) => void
}

function Title(update: ICompanyUpdate) {
	let title = ''
	if (update.note) {
		title += 'note'
	}
	if (update.note && update.files.length > 0) {
		title += ' and '
	}
	if (update.files.length > 0) {
		title += `${update.files.length} file${update.files.length > 1 ? 's' : ''}`
	}

	return title
}

export default function UpdateItem({ update, onEdit, onDelete, isReadOnly }: Props) {
	const [expandUpdate, setExpandUpdate] = useState(false)

	return (
		<main
			data-testid={`update-item-${update.id}`}
			className='rounded-md border bg-gray-50 p-2'
		>
			<div className='flex items-center justify-between truncate'>
				<div className='flex items-center text-sm'>
					<Avatar className='h-8 w-8'>
						<AvatarImage src={update.creator.photoUrl} />
						<AvatarFallback className='bg-blue-200 '>
							{update.creator.name.slice(0, 1)}
						</AvatarFallback>
					</Avatar>
					<div className='px-1 font-semibold'>{update.creator.name}</div>
					<div className='text-gray-500'>added</div>
					<div className='px-1 font-semibold text-blue-700'>
						{Title(update)}
					</div>
				</div>
				<div className='flex-1' />

				<Button
					variant='ghost'
					className='px-2 text-blue-700'
					onClick={() => setExpandUpdate(!expandUpdate)}
				>
					{expandUpdate ? <Minimize2 size={15} /> : <Maximize2 size={15} />}
				</Button>
				{!isReadOnly && <DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button
							id={`more-vert-update-item-${update.id}`}
							variant='ghost'
							className='p-2'
						>
							<MoreVertical size={15} />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent className='w-56'>
						<DropdownMenuLabel>Update</DropdownMenuLabel>
						<DropdownMenuSeparator />
						<AddUpdate
							trigger={
								<Button
									id={`editUpdate-${update.id}`}
									variant='ghost'
									className='flex w-full items-center justify-start gap-2 p-2'
								>
									<Pencil className='mr-2 h-4 w-4' />
									<span>Edit Update</span>
								</Button>
							}
							update={update}
							onAddUpdate={(updateValue, files) => {
								onEdit(updateValue, files)
							}}
						/>
						<DropdownMenuItem asChild>
							<DeleteModal
								deleteButtonId={`update-${update.id}`}
								title='Delete Update'
								description='Are you sure you want to delete this update?'
								trigger={
									<Button
										id={`deleteUpdate-${update.id}`}
										variant='ghost'
										className='flex w-full items-center justify-start gap-2 p-2 text-red-500'
									>
										<Trash2 className='mr-2 h-4 w-4' />
										<span>Delete Update</span>
									</Button>
								}
								onDelete={() => {
									onDelete(update)
								}}
							/>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>}
			</div>


			{expandUpdate ? (
				<div
					data-testid={`update-item-note-${update.id}`}
					className='mt-2 px-2 text-sm text-gray-700'
					dangerouslySetInnerHTML={{ __html: purifyHtml(update.note) }}
				/>
			) : (
				<div
					data-testid={`update-item-note-${update.id}`}
					className='mt-2 px-2 text-sm text-gray-700'
					dangerouslySetInnerHTML={{ __html: purifyHtml(update.note.slice(0, 400)) }}
				/>
			)}

			{update.email && (
				<EmailDialog
					title={update.email.subject}
					email={update.email}
					trigger={
						<div
							className={clsx(
								'bg-blue-20 pointer-events-none mr-2 mt-3 flex w-fit items-center gap-2 rounded-md border border-blue-300 bg-blue-100 px-2 text-sm font-semibold text-blue-700 opacity-0',
								'pointer-events-auto cursor-pointer opacity-100'
							)}
						>
							<Mail size={15} color='blue' />
							View Email
						</div>
					}
				/>
			)}

			{update.files.length > 0 && (
				<div className='mt-3 flex flex-wrap gap-2'>
					{update.files.map(file => (
						<FileViewer
							key={file.id}
							file={file}
							onDelete={() => {
								logger.info('delete file')
							}}
						/>
					))}
				</div>
			)}

			<div className='mt-2 flex cursor-pointer items-center justify-end gap-2 text-blue-700'>
				<div className='text-xs text-gray-700'>
					{renderDate(update.createdAt)}
				</div>

			</div>
		</main>
	)
}
