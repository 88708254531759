/* eslint-disable @typescript-eslint/no-misused-promises */
import { useAppSelector } from 'app/hooks'
import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import { Input } from 'components/ui/input'
import { selectFunnelCount } from 'features/funnels/funnels.slice'
import useCreateFunnel from 'features/funnels/hooks/useCreateFunnels'
import { PlusIcon } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { ImSpinner10 } from 'react-icons/im'
import type { Column } from './KanbanView'

interface Props {
	type: 'company' | 'prospect' | 'task'
	onAddColumn: (column: Column) => void
	group: string
}

export default function CreateKanbanColumn({
	onAddColumn,
	type,
	group
}: Props) {
	const [isOpen, setIsOpen] = useState(false)
	const count = useAppSelector(selectFunnelCount)

	const buttonRef = useRef(null)

	function closeModal() {
		setIsOpen(false)
	}

	const { formik } = useCreateFunnel({
		type,
		group,
		onSuccess: () => {
			onAddColumn({
				id: formik.values.name,
				title: formik.values.name,
				index: count + 1,
				items: [],
				pureItems: []
			})
			closeModal()
		}
	})

	function handleEnterKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key === 'Enter') {
			formik.handleSubmit()
		}
	}

	useEffect(() => {
		if (isOpen) {
			formik.setFieldValue('type', type)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, isOpen])

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title='Add New Column'
			description='Enter the name of the column you want to create'
			trigger={
				<Button
					id='open-create-kanban-column-button'
					variant='ghost'
					className='h-fit px-2'
				>
					<PlusIcon />
				</Button>
			}
		>
			<div className=''>
				<div>
					<Input
						error={formik.errors.name}
						id='name'
						name='column-name'
						placeholder='Enter column name'
						className='mt-0'
						value={formik.values.name}
						onKeyDown={handleEnterKeyPress}
						onChange={async e => formik.setFieldValue('name', e.target.value)}
					/>
				</div>
				<div className='mt-10 flex w-full justify-end gap-5'>
					{!formik.isSubmitting && (
						<Button
							id='cancel-create-column-button'
							variant='danger'
							onClick={() => closeModal()}
						>
							Cancel
						</Button>
					)}
					<Button
						id='create-column-button'
						ref={buttonRef}
						disabled={formik.isSubmitting || !formik.isValid}
						onClick={() => formik.handleSubmit()}
					>
						{formik.isSubmitting ? (
							<div className='flex items-center gap-2'>
								<ImSpinner10 className='animate-spin' /> Creating...
							</div>
						) : (
							'Create'
						)}
					</Button>
				</div>
			</div>
		</DialogModal>
	)
}
