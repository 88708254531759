import { Archive, ChevronsRight, Fullscreen, Shrink } from 'lucide-react'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import {
	selectSelectedThread,
	selectSelectedThreadWindowState,
	setSelectedThread,
	setSelectedThreadWindowState
} from '../redux/threads.slice'

import Button from 'components/Buttons/Button'
import EmailInThread from './EmailInThread'
import type { IEmailMessage } from '../interfaces/email.interface'
import { ThreadService } from '../service/thread.service'
import { selectMessagesState } from '../redux/emails.slice'
import { store } from 'app/store'
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { useAppSelector } from 'app/hooks'
import { useEffect } from 'react'

export default function SelectedEmailThread() {
	const { data: emails, isLoading: emailsLoading } =
		useAppSelector(selectMessagesState)
	const selectedEmailThread = useAppSelector(selectSelectedThread)
	const threadWindowState = useAppSelector(selectSelectedThreadWindowState)

	useEffect(() => {
		if (selectedEmailThread) {
			ThreadService.read(selectedEmailThread.id, true)
		}
	}, [selectedEmailThread])

	if (!selectedEmailThread) return <div>No thread found</div>

	if (emailsLoading)
		return (
			<div className='p-8'>
				<div className='h-3 w-52 animate-pulse rounded bg-gray-200' />
				<div className='mt-5 h-3 w-96 animate-pulse rounded bg-gray-200' />
				<div className='mt-2 h-3 w-96 animate-pulse rounded bg-gray-200' />
			</div>
		)

	return (
		<div className='flex border-l pb-20'>
			<div className='relative z-[1] flex-1'>
				<div className='sticky top-0 flex items-center gap-1 border-b bg-white p-3 text-xl'>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								onClick={() => {
									if (threadWindowState === 'maximize') {
										store.dispatch(setSelectedThreadWindowState('restore'))
									} else {
										store.dispatch(setSelectedThreadWindowState('maximize'))
									}
								}}
								variant='ghost'
								className='px-1 py-1'
							>
								{threadWindowState === 'maximize' ? (
									<Shrink size={18} />
								) : (
									<Fullscreen size={18} />
								)}
							</Button>
						</TooltipTrigger>
						<TooltipContent side='bottom'>
							{threadWindowState === 'maximize'
								? 'Restore Thread '
								: 'Maximize Thread'}
						</TooltipContent>
					</Tooltip>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								onClick={() => {
									store.dispatch(setSelectedThread(null))
								}}
								variant='ghost'
								className='px-1 py-1'
							>
								<ChevronsRight size={18} />
							</Button>
						</TooltipTrigger>
						<TooltipContent side='bottom'>Close Thread</TooltipContent>
					</Tooltip>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								onClick={async () => {
									await ThreadService.archive(selectedEmailThread.id)
								}}
								variant='ghost'
								className='px-1 py-1'
							>
								<Archive size={18} />
							</Button>
						</TooltipTrigger>
						<TooltipContent side='bottom'>Archive</TooltipContent>
					</Tooltip>

					<div className='text-xs'>
						{selectedEmailThread.emailIds.length} email
						{selectedEmailThread.emailIds.length > 1 ? 's' : ''}
					</div>
				</div>

				<div className='flex flex-col gap-5'>
					<div className='flex w-full flex-col gap-2'>
						{emails.map((email: IEmailMessage, index: number) => (
							<EmailInThread
								email={{ ...email, companies: selectedEmailThread.companies }}
								key={email.id}
								defaultExpanded={index === 0}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}
