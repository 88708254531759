import type { INote } from '../interfaces/notes.interface'
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface NoteSliceState {
	data: INote | null
	isLoading: boolean
	error?: string | null
	mode: 'edit' | 'view'
}

const initialState: NoteSliceState = {
	data: null,
	isLoading: false,
	error: null,
	mode: 'view'
}

export const noteSlice = createAppSlice({
	name: 'note',
	initialState,
	reducers: create => ({
		setNoteIsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setNoteError: create.reducer(
			(state, action: PayloadAction<string | null>) => {
				state.error = action.payload
				state.isLoading = false
			}
		),
		setNote: create.reducer((state, action: PayloadAction<INote>) => {
			state.isLoading = false
			state.data = action.payload
		}),
		setNoteMode: create.reducer(
			(state, action: PayloadAction<'edit' | 'view'>) => {
				state.mode = action.payload
			}
		)
	}),
	selectors: {
		selectNoteState: state => state,
		selectNoteMode: state => state.mode
	}
})

export const { setNote, setNoteIsLoading, setNoteError, setNoteMode } =
	noteSlice.actions

export const { selectNoteMode, selectNoteState } = noteSlice.selectors
