import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import Button from 'components/Buttons/Button'
import EmailInThread from 'features/mail/views/EmailInThread'
import { Handshake } from 'lucide-react'
import type { IEmailMessage } from '../../../interfaces/email.interface'
import { ImSpinner10 } from 'react-icons/im'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import type { LimitedPartnerData } from 'interfaces'
import Logger from 'lib/logger'
import axiosInstance from 'lib/axios'
import useCreateLP from 'features/limited_partners/hooks/useCreateLp'

interface Props {
	email: IEmailMessage
	limitedPartners?: LimitedPartnerData[]
}

export default function LimitedPartnerFromEmail({
	email,
	limitedPartners
}: Props) {
	const [isOpen, setIsOpen] = useState(false)
	const [isExtractingDoc, setIsExtractingDoc] = useState(false)
	const [extractedDoc, setExtractedDoc] = useState<boolean>(false)
	const { formik } = useCreateLP({
		onSuccess: () => {
			setIsOpen(false)
		}
	})

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	const handleCreateLimitedPartner = async () => {
		try {
			setIsExtractingDoc(true)
			const response = await axiosInstance.get(
				`/messages/${email.id}/extract-prospect`
			)

			setExtractedDoc(response.data.data)

			if (!response.data.data)
				throw new Error('No limited partner info extracted')

			formik.setFieldValue('name', response.data.data.name)
			formik.setFieldValue('email', response.data.data.email)
			formik.setFieldValue('referralMethod', 'Email')
		} catch (error) {
			Logger.error(error)
			setExtractedDoc(true)
		} finally {
			setIsExtractingDoc(false)
		}
	}

	useEffect(() => {
		if (isOpen) return
		setExtractedDoc(false)
		setIsExtractingDoc(false)
	}, [isOpen])

	return (
		<>
			<Tooltip>
				<TooltipTrigger asChild>
					<Button
						variant='ghost'
						onClick={() => {
							openModal()
						}}
						className='px-1 py-1 text-lg disabled:opacity-50'
						disabled={(limitedPartners?.length || 0) > 0}
					>
						<Handshake size={15} />
					</Button>
				</TooltipTrigger>
				<TooltipContent>
					<p>{limitedPartners?.length ? 'LP Already Exists' : 'Create LP'}</p>
				</TooltipContent>
			</Tooltip>

			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={() => {}}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-black/25' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<Dialog.Panel className='w-full max-w-5xl transform overflow-hidden rounded  bg-white p-6 text-left align-middle shadow-xl transition-all'>
									<Dialog.Title
										as='h3'
										className='text-lg font-medium leading-6 text-gray-900'
									>
										Create limited partner from email
									</Dialog.Title>
									<div className='mt-5 max-h-[70vh] min-h-[20vh] overflow-auto'>
										<EmailInThread email={email} />

										{extractedDoc && (
											<>
												<div className=' border-black pt-5'>
													<Label>Limited Partner Name</Label>
													<Input
														placeholder='Enter limited partner name'
														onChange={e => {
															formik.setFieldValue('name', e.target.value)
														}}
														value={formik.values.name}
													/>
												</div>
												<div className='mt-5 flex gap-5'>
													<div className='flex-1'>
														<Label>Email</Label>
														<Input
															placeholder='Enter email'
															onChange={e => {
																formik.setFieldValue('email', e.target.value)
															}}
															value={formik.values.email}
														/>
													</div>
												</div>
											</>
										)}
									</div>

									<div className='mt-4 flex w-full items-center justify-between gap-3'>
										<div>
											{isExtractingDoc && (
												<div className='flex items-center gap-2 text-sm'>
													<ImSpinner10 className='animate-spin' />
													Extracting limited partner info. This can take up to a
													minute...
												</div>
											)}
										</div>
										<div className='flex gap-3'>
											<Button
												type='button'
												variant='ghost'
												onClick={closeModal}
											>
												Cancel
											</Button>
											{extractedDoc && (
												<Button
													id='create-limited-partner-from-email-submit'
													disabled={isExtractingDoc || formik.isSubmitting}
													isLoading={formik.isSubmitting}
													type='button'
													onClick={() => {
														formik.handleSubmit()
													}}
												>
													Create Limited Partner
												</Button>
											)}

											{!extractedDoc && (
												<Button
													id='extract-limited-partner-info-from-email'
													type='button'
													isLoading={isExtractingDoc}
													disabled={isExtractingDoc}
													onClick={() => {
														handleCreateLimitedPartner()
													}}
												>
													Extract Limited Partner Info
												</Button>
											)}
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}
