/* eslint-disable react/no-unescaped-entities */
import Layout from 'components/Layout'
import type { ReactElement } from 'react'
import clsxm from 'utils/clsxm'

function TermsAndConditionsPage(): ReactElement {
	return (
		<main className='min-h-screen '>
			<Layout>
				<section
					className={clsxm(
						'relative mx-4 mb-40 mt-20  max-w-7xl px-4 text-black lg:mx-auto',
						' rounded-3xl',
						'before:absolute before:inset-[2px] before:rounded-[1.5rem] ',
						'after:absolute after:-inset-x-2 after:-bottom-2 after:h-[150px]',
						'to-transparent  after:bg-gradient-to-t '
					)}
				>
					<article
						className={clsxm(
							'relative z-20 mx-auto max-w-5xl px-4 py-32',
							'flex flex-col gap-8'
						)}
					>
						<h2
							className={clsxm(
								' mx-auto w-fit -translate-y-1/2  px-8 text-center text-xl sm:text-2xl md:text-4xl'
							)}
						>
							Terms of Service (TOS) for Spok.vc
						</h2>
						<p>
							These Terms of Service ("Agreement") govern the use of the
							services provided by Spok.vc ("Spok.vc," "we," or "us") to venture
							capital managers ("Users" or "you"). By using our services, you
							agree to be bound by this Agreement. Please read this Agreement
							carefully before accessing or using our platform.
						</p>

						<div>
							<strong className=''>Acceptance of Terms</strong>
							<ul>
								<li>
									1.1 By using the Spok.vc platform, you acknowledge and agree
									to be bound by this Agreement. If you do not agree to these
									terms, you may not use our services.
								</li>
								<li>
									1.2 Spok.vc reserves the right to modify or update this
									Agreement at any time, and such modifications or updates will
									be effective upon their posting on the Spok.vc website. Your
									continued use of our services after any changes to this
									Agreement constitutes your acceptance of such changes.
								</li>
							</ul>
						</div>
						<div>
							<strong className=''>Services</strong>
							<ul>
								<li>
									2.1 Spok.vc provides a platform to help venture capital
									managers manage their funds. Our services include but are not
									limited to data storage, collaboration tools, and integration
									with third-party cloud storage services such as Google Drive
									and Dropbox.
								</li>
								<li>
									2.2 You understand and acknowledge that the use of third-party
									cloud storage services is subject to their respective terms
									and conditions, and you agree to comply with those terms and
									conditions in addition to this Agreement.
								</li>
								<li>
									2.3 Spok.vc may update, modify, or discontinue any features or
									functionalities of the platform at any time without prior
									notice.
								</li>
							</ul>
						</div>

						<div>
							<strong className=''>User Responsibilities</strong>
							<ul>
								<li>
									3.1 You are responsible for maintaining the confidentiality of
									your account credentials and ensuring the security of your
									data stored on our platform.
								</li>
								<li>
									3.2 You agree not to use the Spok.vc services for any illegal,
									unauthorized, or unethical purposes. You will comply with all
									applicable laws, regulations, and industry standards.
								</li>
								<li>
									3.3 You are solely responsible for the accuracy, completeness,
									and legality of the data you upload to the Spok.vc platform,
									including the data from your linked cloud storage accounts.
								</li>
							</ul>
						</div>

						<div>
							<strong className=''>Data Storage and Privacy</strong>
							<ul>
								<li>
									4.1 Spok.vc will store and process your data in accordance
									with our Privacy Policy. By using our services, you consent to
									the collection, storage, and processing of your data as
									described in our Privacy Policy.
								</li>
								<li>
									4.2 Spok.vc will take reasonable measures to ensure the
									security and confidentiality of your data. However, we cannot
									guarantee absolute security and will not be liable for any
									unauthorized access, loss, or corruption of your data.
								</li>
								<li>
									4.3 You acknowledge and agree that Spok.vc may access and use
									your linked cloud storage accounts solely for the purpose of
									providing our services and that we will handle such access in
									compliance with our Privacy Policy.
								</li>
							</ul>
						</div>
						<div>
							<strong className=''>
								Google Gmail and Calendar Integration
							</strong>
							<ul>
								<li>
									4.4 To enhance communication and collaboration features within
									Spok.vc, we require access to certain Gmail and Calendar
									scopes:
									<br />
									<br />
									1. Spok reads your Gmail messages to provide contextual
									information and facilitate seamless integration.
									<br />
									2. Spok sends emails on your behalf, ensuring efficient
									communication between users.
									<br />
									3. Spok can compose and draft emails within the platform for
									user convenience.
									<br />
									4. Spok accesses to your Gmail contacts to streamline
									communication and collaboration efforts.
									<br />
									5. Spok accesses your Calendar, to prove scheduling and event
									management features, enhancing productivity and coordination
									among users.
								</li>
							</ul>
						</div>
						<div>
							<strong className=''>Intellectual Property</strong>
							<ul>
								<li>
									5.1 All intellectual property rights, including but not
									limited to copyrights, trademarks, and patents, related to the
									Spok.vc platform and services are owned by Spok.vc. You agree
									not to copy, modify, distribute, or reverse engineer any part
									of our platform without our prior written consent.
								</li>
								<li>
									5.2 By uploading or providing any content or data to Spok.vc,
									you grant us a non-exclusive, worldwide, royalty-free license
									to use, reproduce, modify, and display the content for the
									purpose of providing our services.
								</li>
							</ul>
						</div>

						<div>
							<strong className=''>Limitation of Liability</strong>
							<ul>
								<li>
									6.1 Spok.vc and its affiliates will not be liable for any
									indirect, incidental, consequential, or punitive damages
									arising out of or in connection with the use of our services,
									even if we have been advised of the possibility of such
									damages.
								</li>
								<li>
									6.2 To the maximum extent permitted by applicable law,
									Spok.vc's total liability for any claims arising out of or in
									connection with this Agreement will not exceed the amount paid
								</li>
							</ul>
						</div>
					</article>
				</section>
			</Layout>
		</main>
	)
}

export default TermsAndConditionsPage
