import { AnimatePresence, motion } from 'framer-motion'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import {
	selectEntityThreads,
	selectIsThreadsLoading,
	selectSelectedThread,
	setSelectedThreadWindowState
} from 'features/mail/redux/threads.slice'
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import CompanySelectedEmailThread from 'features/companies/view/CompanySelectedThreadEmails'
import EmailThreads from 'features/mail/views/EmailThreads'
import type { ILimitedPartner } from '../interfaces/limitedPartner.interface'
import MailAuthorizeButton from 'features/mail/components/MailAuthorizeButton'
import ShowSignatureHint from 'features/mail/components/ShowSignatureHint'
import SidebarEmailThreadItem from 'features/mail/views/SidebarEmailThreadItem'
import { ThreadService } from 'features/mail/service/thread.service'
import { listenToLabelCounts } from 'features/mail/api/threads.api'
import { listenToMessages } from 'features/mail/api/messages.api'
import { setMessages } from 'features/mail/redux/emails.slice'

export default function LimitedPartnerEmails({
	limitedPartner
}: {
	limitedPartner: ILimitedPartner
}) {
	const { teamMemberEmails, email } = limitedPartner

	const dispatch = useAppDispatch()

	const isLoading = useAppSelector(selectIsThreadsLoading)
	const { inboxThreads, scheduledCallThreads } = useAppSelector(state =>
		selectEntityThreads(state, [email || '', ...(teamMemberEmails ?? [])])
	)

	const selectedEmailThread = useAppSelector(selectSelectedThread)
	const { authUser } = useContext(AuthContext)

	useEffect(() => {
		if (!authUser?.id) return

		const emails = [...(teamMemberEmails ?? [])]
		if (email) emails.push(email)

		const unsubscribeLabelCounts = listenToLabelCounts(authUser.id)
		const unsubscribeThreads = ThreadService.listenToEntityThreads(
			authUser.id,
			emails
		)

		return () => {
			unsubscribeLabelCounts()
			unsubscribeThreads()
		}
	}, [authUser?.id, email, teamMemberEmails])

	useEffect(() => {
		if (!selectedEmailThread) {
			dispatch(setSelectedThreadWindowState('restore'))
			return
		}

		if (selectedEmailThread.latestEmail) {
			dispatch(setMessages([selectedEmailThread.latestEmail]))
		}
		const unsubscribe = dispatch(listenToMessages(selectedEmailThread.id))

		return () => unsubscribe()
	}, [selectedEmailThread])

	return (
		<AnimatePresence>
			<div className='h-full w-full flex-1 overflow-y-scroll px-3 pb-0 pt-2'>
				<div className='mb-2 text-sm font-semibold'>Emails</div>
				<div>
					<ShowSignatureHint />
					<MailAuthorizeButton />
				</div>
				<Tabs defaultValue='inbox' className='mt-2 w-full'>
					<TabsList className='grid w-full grid-cols-2'>
						<TabsTrigger value='inbox'>Inbox</TabsTrigger>
						<TabsTrigger value='schedule-call'>Schedule Call</TabsTrigger>
					</TabsList>
					<TabsContent value='inbox'>
						{(!selectedEmailThread || selectedEmailThread.hasScheduledCall) && (
							<motion.div
								initial={{ translateX: -100, opacity: 0 }}
								animate={{ translateX: 0, opacity: 1 }}
								exit={{ translateX: -100, opacity: 0 }}
							>
								<EmailThreads threads={inboxThreads} loading={isLoading} />
							</motion.div>
						)}
						{selectedEmailThread && !selectedEmailThread.hasScheduledCall && (
							<motion.div
								initial={{ translateX: 100, opacity: 0 }}
								animate={{ translateX: 0, opacity: 1 }}
								exit={{ translateX: 100, opacity: 0 }}
							>
								<CompanySelectedEmailThread />
							</motion.div>
						)}
					</TabsContent>
					<TabsContent value='schedule-call'>
						{!selectedEmailThread?.hasScheduledCall && (
							<motion.div
								initial={{ translateX: -100, opacity: 0 }}
								animate={{ translateX: 0, opacity: 1 }}
								exit={{ translateX: -100, opacity: 0 }}
							>
								<EmailThreads
									threads={scheduledCallThreads}
									loading={isLoading}
								/>
							</motion.div>
						)}
						{selectedEmailThread?.hasScheduledCall && (
							<motion.div
								initial={{ translateX: 100, opacity: 0 }}
								animate={{ translateX: 0, opacity: 1 }}
								exit={{ translateX: 100, opacity: 0 }}
							>
								<SidebarEmailThreadItem />
							</motion.div>
						)}
					</TabsContent>
				</Tabs>
			</div>
		</AnimatePresence>
	)
}
