/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { addFiles, listenToFiles } from 'features/files/files.api'
import { useAppDispatch, useAppSelector } from 'app/hooks'

import DocSendUpload from 'features/files/view/DocSendUpload'
import type { FileUploadResult } from 'features/storage/hooks/useFirebaseStorageUploader'
import FileUploader from 'features/fileviewers/views/FileUploader'
import FoldersView from '../../folders/view/folders.view'
import GoogleDriveSync from 'features/settings/Integrations/views/GoogleDriveSync'
import type { ICompany } from 'interfaces'
import type { IFile } from 'features/files/file.interface'
import Logger from 'lib/logger'
// import { ObservableStoreProvider } from '../../folders/hooks/observable.store'
import { selectAuthState } from 'features/authentication/authentication.slice'
import { toast } from 'sonner'
import { useEffect } from 'react'

interface Props {
	company: ICompany
	isReadOnly?: boolean
}

export default function CompanyFiles({ company, isReadOnly }: Props) {
	const dispatch = useAppDispatch()
	const { authUser } = useAppSelector(selectAuthState)

	useEffect(() => {
		if (!company.id) return

		const unsubscribe = dispatch(listenToFiles(company.id))
		return () => unsubscribe()
	}, [company.id])

	const saveFiles = async (results: FileUploadResult[]) => {
		if (!authUser) return
		const filesData = results.map((result: FileUploadResult) => {
			const data: IFile = {
				id: result.id,
				name: result.name,
				url: result.url,
				mimeType: result.type,
				size: result.size ?? 0,
				fund: authUser.fund,
				creator: {
					id: authUser.id,
					name: authUser.name,
					photoUrl: authUser.photoUrl || ''
				},
				company: {
					id: company.id,
					name: company.name,
					website: company.website ?? ''
				},
				source: 'spok',
				createdAt: new Date().toISOString(),
				lastUpdatedAt: new Date().toISOString(),
				fileType: 'company_file',
				folderId: result.folderId || ''
			}

			return data
		})
		try {
			await addFiles(filesData)
		} catch (error: any) {
			Logger.error(error)
			toast.error(error?.message ?? 'Failed to upload files')
		}
	}

	return (
		<div>
			{!isReadOnly && (
				<div className='mb-2 flex w-fit gap-2 bg-white px-2 py-2'>
					<div className='pr-2'>
						<FileUploader id='company-file-upload' onUpload={saveFiles} />
					</div>

					<div>
						<GoogleDriveSync companyId={company.id} />
					</div>

					<div>
						<DocSendUpload company={company} />
					</div>
				</div>
			)}

			<FoldersView company={company} onUpload={saveFiles} />
		</div>
	)
}
