import { Edit, Mail, Trash2 } from 'lucide-react'
import {
	selectCapitalCallSettings,
	selectCapitalCallTemplates,
	setCapitalCallSettings
} from '../redux/capital_call_template.slice'
import { useContext, useEffect, useState } from 'react'

import AddCapitalCallTemplate from './AddCapitalCallTemplate'
import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { CapitalCallTemplateService } from '../service/CapitalCallTemplate.service'
import DeleteModal from 'components/DeleteModal'
import { DialogModal } from 'components/shadcn/DialogModal'
import FileViewer from 'features/fileviewers/views/FileViewer'
import QuillEditor from 'features/quilleditor/views/QuillEditor'
import SpokInlineInputEditor from 'components/shadcn/SpokInlineInputEditor'
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import { Switch } from '@headlessui/react'
import clsxm from 'utils/clsxm'
import { store } from 'app/store'
import { toast } from 'sonner'
import { useAppSelector } from 'app/hooks'

export default function CapitalCallSettings() {
	const { authUser } = useContext(AuthContext)
	const { data, isLoading, error } = useAppSelector(selectCapitalCallTemplates)
	const { data: settings } = useAppSelector(selectCapitalCallSettings)

	const [bodyComponentOpen, setBodyComponentOpen] = useState(false)

	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribe = CapitalCallTemplateService.listenToCapitalCallTemplates(
			{ fundId: authUser.fund.id }
		)
		CapitalCallTemplateService.listenToCapitalCallTemplateSettings({
			fundId: authUser.fund.id
		})
		return () => {
			unsubscribe()
		}
	}, [authUser?.fund.id])

	if (isLoading) return <div>Loading...</div>

	if (error) return <div>Error: {error}</div>

	if (data.length === 0)
		return (
			<div className='flex flex-col gap-3 p-5'>
				<AddCapitalCallTemplate />
				<div>No Capital Call Templates added</div>
			</div>
		)

	return (
		<div className='flex flex-col gap-3 p-5'>
			{/* Templates */}
			<div>PDF Templates</div>
			<AddCapitalCallTemplate />
			<div className='flex flex-wrap gap-5'>
				{data.map(item => (
					<div
						onClick={() => {}}
						key={item.id}
						className='flex w-fit flex-col items-center'
					>
						<FileViewer
							file={{
								url: item.pdfUrl,
								name: `${item.name}.pdf`
							}}
							content={
								<img
									src={item.thumbnailUrl}
									alt={item.name}
									className='h-[16rem] w-[13rem] rounded-xl border'
								/>
							}
							readonly
						/>

						<div className='mt-5 flex w-full justify-between font-semibold'>
							<p>{item.name}</p>
							<div className='flex items-center gap-2'>
								<AddCapitalCallTemplate
									trigger={
										<Edit size={15} color='blue' className='cursor-pointer' />
									}
									template={item}
								/>
								<DeleteModal
									deleteButtonId={`deleteCapitalCallTemplate-${item.name}`}
									title='Delete Template'
									description={`Are you sure you want to delete ${item.name}?`}
									trigger={
										<Trash2
											data-testid={`deleteTemplate-${item.name}`}
											size={15}
											color='red'
										/>
									}
									onDelete={async () => {
										await CapitalCallTemplateService.deleteCapitalCallTemplate(
											item.id
										)
										toast.success('Template deleted successfully')
									}}
								/>
							</div>
						</div>
					</div>
				))}
			</div>
			<div className='h-1 w-full border' />

			{/* Settings */}
			<div>Capital Call Settings</div>
			<div className='flex flex-col pb-2 pr-20 pt-5'>
				{/* Send CC Emails */}
				<div className='w-full max-w-[50vw]'>
					<div className='flex items-center justify-between px-5 pb-2 pt-5'>
						<div>
							<div className='flex items-center gap-2 font-semibold'>
								<div>
									<Mail />
								</div>
								Send Capital Call Emails
							</div>
							<div className='w-96 text-sm text-gray-700'>
								{settings?.shoudlSendCapitalCallEmails
									? 'LPs will receive caiptal call emails.\nThis is after the capital call has been approved.'
									: 'Enable to send capital call emails to LPs.\nThis is after the capital call has been approved.'}
							</div>
						</div>

						<div className=''>
							<Switch
								checked={settings?.shoudlSendCapitalCallEmails ?? false}
								onChange={checked => {
									store.dispatch(
										setCapitalCallSettings({
											...settings,
											shoudlSendCapitalCallEmails: checked
										})
									)

									CapitalCallTemplateService.saveCapitalCallTemplateSettings({
										settings: { shoudlSendCapitalCallEmails: checked },
										fund: authUser?.fund
									})
								}}
								className={clsxm(
									'relative inline-flex h-[28px] w-[64px] shrink-0 cursor-pointer rounded-full border',
									'focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75',
									'border-gray-300 shadow-inner transition-colors duration-200 ease-in-out',
									{
										'border-green-500 bg-green-500':
											settings?.shoudlSendCapitalCallEmails,
										'bg-white': !settings?.shoudlSendCapitalCallEmails
									}
								)}
							>
								<span className='sr-only'>Use setting</span>
								<span
									aria-hidden='true'
									className={clsxm(
										'pointer-events-none mt-[1px] inline-block h-[24px] w-[24px] transform rounded-full border',
										'shadow-lg ring-0 transition duration-200 ease-in-out',
										settings?.shoudlSendCapitalCallEmails ?? false
											? 'translate-x-9 bg-white'
											: 'translate-x-0 bg-gray-300'
									)}
								/>
							</Switch>
						</div>
					</div>
				</div>

				{/* Test Email */}
				<div className='flex justify-between px-5 pb-2 pt-5'>
					<div>
						<div className='flex items-center gap-2 font-semibold'>
							<div>
								<Mail />
							</div>
							Test Capital Call Email
						</div>
						<div className='w-96 text-sm text-gray-700'>
							An email account to send test capital call emails to
						</div>
					</div>

					<SpokInlineInputEditor
						defaultValue={settings?.testEmailRecipient ?? ''}
						onSave={value => {
							store.dispatch(
								setCapitalCallSettings({
									...settings,
									testEmailRecipient: value
								})
							)
							CapitalCallTemplateService.saveCapitalCallTemplateSettings({
								settings: { testEmailRecipient: value },
								fund: authUser?.fund
							})
						}}
					/>
				</div>

				<div className='flex justify-between px-5 pb-2 pt-5'>
					<div>
						<div className='flex items-center gap-2 font-semibold'>
							<div>
								<Mail />
							</div>
							Email Subject
						</div>
						<div className='w-96 text-sm text-gray-700'>
							The subject of the capital call email
						</div>
					</div>

					<SpokInlineInputEditor
						defaultValue={settings?.emailSubject ?? ''}
						onSave={value => {
							store.dispatch(
								setCapitalCallSettings({
									...settings,
									emailSubject: value
								})
							)
							CapitalCallTemplateService.saveCapitalCallTemplateSettings({
								settings: { emailSubject: value },
								fund: authUser?.fund
							})
						}}
					/>
				</div>

				<div className='flex justify-between px-5 pb-2 pt-5'>
					<div>
						<div className='flex items-center gap-2 font-semibold'>
							<div>
								<Mail />
							</div>
							Email Preview Text
						</div>
						<div className='w-96 text-sm text-gray-700'>
							The preview text of the capital call email
						</div>
					</div>

					<SpokInlineInputEditor
						defaultValue={settings?.emailPreviewText ?? ''}
						onSave={value => {
							store.dispatch(
								setCapitalCallSettings({
									...settings,
									emailPreviewText: value
								})
							)
							CapitalCallTemplateService.saveCapitalCallTemplateSettings({
								settings: { emailPreviewText: value },
								fund: authUser?.fund
							})
						}}
					/>
				</div>

				<div className='flex justify-between px-5 pb-2 pt-5'>
					<div>
						<div className='flex items-center gap-2 font-semibold'>
							<div>
								<Mail />
							</div>
							Email Body
						</div>
						<div className='w-96 text-sm text-gray-700'>
							The body of the capital call email
						</div>
					</div>

					<DialogModal
						open={bodyComponentOpen}
						setOpen={setBodyComponentOpen}
						title='Edit Email Body'
						description='Edit the body of the capital call email'
						trigger={
							<Button variant='ghost' className='p-2'>
								View
								<Edit size={15} />
							</Button>
						}
					>
						<div className=''>
							<QuillEditor
								className='mb-20 h-[50vh] w-full'
								id='note-editor'
								value={settings?.emailBody ?? ''}
								onChange={value => {
									store.dispatch(
										setCapitalCallSettings({
											...settings,
											emailBody: value
										})
									)
									CapitalCallTemplateService.saveCapitalCallTemplateSettings({
										settings: { emailBody: value },
										fund: authUser?.fund
									})
								}}
							/>
							<Button
								onClick={() => {
									setBodyComponentOpen(false)
								}}
							>
								Save
							</Button>
						</div>
					</DialogModal>
				</div>

				<div className='flex justify-between px-5 pb-2 pt-5'>
					<div>
						<div className='flex items-center gap-2 font-semibold'>
							<div>
								<Mail />
							</div>
							Email Sender Name
						</div>
						<div className='w-96 text-sm text-gray-700'>
							The name of the sender of capital call email
						</div>
					</div>

					<SpokInlineInputEditor
						defaultValue={settings?.emailSenderName ?? ''}
						onSave={value => {
							store.dispatch(
								setCapitalCallSettings({
									...settings,
									emailSenderName: value
								})
							)
							CapitalCallTemplateService.saveCapitalCallTemplateSettings({
								settings: { emailSenderName: value },
								fund: authUser?.fund
							})
						}}
					/>
				</div>

				<div className='flex justify-between px-5 pb-2 pt-5'>
					<div>
						<div className='flex items-center gap-2 font-semibold'>
							<div>
								<Mail />
							</div>
							Email Sender Address
						</div>
						<div className='w-96 text-sm text-gray-700'>
							The address of the sender of capital call email
						</div>
					</div>

					<SpokInlineInputEditor
						defaultValue={settings?.emailFrom ?? ''}
						onSave={value => {
							store.dispatch(
								setCapitalCallSettings({
									...settings,
									emailFrom: value
								})
							)
							CapitalCallTemplateService.saveCapitalCallTemplateSettings({
								settings: { emailFrom: value },
								fund: authUser?.fund
							})
						}}
					/>
				</div>

				<div className='flex justify-between px-5 pb-2 pt-5'>
					<div>
						<div className='flex items-center gap-2 font-semibold'>
							<div>
								<Mail />
							</div>
							Email Reply To Address
						</div>
						<div className='w-96 text-sm text-gray-700'>
							The address of the recipient of follow up emails
						</div>
					</div>

					<SpokInlineInputEditor
						defaultValue={settings?.emailReplyToAddress ?? ''}
						onSave={value => {
							store.dispatch(
								setCapitalCallSettings({
									...settings,
									emailReplyToAddress: value
								})
							)
							CapitalCallTemplateService.saveCapitalCallTemplateSettings({
								settings: { emailReplyToAddress: value },
								fund: authUser?.fund
							})
						}}
					/>
				</div>
			</div>
		</div>
	)
}
