/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/consistent-function-scoping */
import { useState, type ReactNode } from 'react'
import { ImSpinner10 } from 'react-icons/im'
import { toast } from 'sonner'
import { loginWithGoogle } from '../authentication.api'

export default function GoogleSignInButton(): ReactNode {
	const [isLoggingIn, setIsLoggingIn] = useState(false)

	const onLoginWithGoogle = async (): Promise<void> => {
		try {
			setIsLoggingIn(true)
			localStorage.setItem('loginClicked', 'yes')
			await loginWithGoogle()
		} catch (error: any) {
			toast.error(error.message ?? 'An error occured')
			localStorage.removeItem('loginClicked')
		} finally {
			setIsLoggingIn(false)
		}
	}

	return (
		<div className='mt-3 flex flex-col items-center justify-center'>
			{isLoggingIn ? (
				<div className='bg-primary-500 flex items-center justify-center gap-2 rounded-full'>
					<ImSpinner10 className='animate-spin' /> loading...
				</div>
			) : (
				<button
					type='button'
					className='flex cursor-pointer items-center justify-center rounded-full border bg-white px-4 py-2 text-gray-800'
					onClick={() => onLoginWithGoogle()}
				>
					<img
						className='mr-2'
						src='https://developers.google.com/identity/images/g-logo.png'
						alt='Google Logo'
						height='24'
						width='24'
					/>
					<span>Continue with Google</span>
				</button>
			)}
		</div>
	)
}
