import { List, Plus, Rows2 } from 'lucide-react'
import { displayAmount, getConvertedAmount } from 'utils/currency'
import {
	selectInvestments,
	selectInvestmentsLoading
} from '../redux/investments.slice'
import { useEffect, useState } from 'react'

import Button from 'components/Buttons/Button'
import type { ICompany } from 'interfaces'
import InvestmentCard from './InvestmentCard'
import InvestmentList from './InvestmentList'
import NewInvestment from './NewInvestment'
import Skeleton from 'components/Skeleton'
import clsx from 'clsx'
import { listenToInvestments } from '../api/investments.api'
import { selectFundCurrency } from 'features/authentication/authentication.slice'
/* eslint-disable @typescript-eslint/prefer-destructuring */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppSelector } from 'app/hooks'

interface AppProps {
	company: ICompany
	isReadOnly?: boolean
}

export default function InvestmentTerms({ company, isReadOnly }: AppProps) {
	const fundCurrency = useAppSelector(selectFundCurrency)
	const investments = useAppSelector(selectInvestments)
	const investmentsLoading = useAppSelector(selectInvestmentsLoading)

	const [view, setView] = useState<'card' | 'list'>('list')

	useEffect(() => {
		const unsubscribe = listenToInvestments(company.id)

		return () => unsubscribe()
	}, [company.id])

	if (investmentsLoading)
		return (
			<div
				data-testid='investment-terms-loading'
				className='flex gap-10 p-2 pt-3'
			>
				<div className='flex gap-3'>
					<Skeleton className='h-8 w-8 rounded-full border' />
					<div className='flex flex-col gap-2'>
						<Skeleton className='h-5 w-52 border' />
						<Skeleton className='h-5 w-52 border' />
					</div>
				</div>

				<div className='flex-1' />
				{!isReadOnly && (
					<NewInvestment
						company={company}
						trigger={
							<Button id='open-new-round' className='h-fit text-xs'>
								<Plus size={14} />
								New Round
							</Button>
						}
					/>
				)}
			</div>
		)

	const totalAmountValue = investments.reduce(
		(acc, investment) =>
			acc +
			getConvertedAmount({
				currency: investment.currency,
				amount: investment.amount
			}).amount,
		0
	)
	const totalAmount = getConvertedAmount({
		currency: fundCurrency,
		amount: totalAmountValue
	})

	const latestInvestments = [...investments]
	latestInvestments.sort(
		(a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
	)

	const latestValuation =
		latestInvestments.length > 0
			? {
				currency: latestInvestments[0].currency,
				amount: latestInvestments[0].valuation
			}
			: { currency: fundCurrency, amount: 0 }

	// const totalOwnership = (
	// 	(totalAmount.amount / latestValuation.amount) *
	// 	100
	// ).toFixed(2)

	// Calculate total ownership. It is the sum of individual ownerships as percentages
	// eslint-disable-next-line unicorn/no-array-reduce
	const totalOwnership = investments.reduce((acc, investment) => {
		const investmentAmount = getConvertedAmount({
			currency: investment.currency,
			amount: investment.amount
		}).amount
		const investmentValuation = getConvertedAmount({
			currency: investment.currency,
			amount: investment.valuation
		}).amount
		const ownership = (investmentAmount / investmentValuation) * 100
		return acc + ownership
	}, 0)

	return (
		<>
			<div className='flex gap-10 p-2 pt-3'>
				<div>
					<div className='font-semibold text-blue-700'>Amount</div>
					<div>{displayAmount(totalAmount, true)}</div>
				</div>
				<div>
					<div className='font-semibold text-blue-700'>Latest Valuation</div>
					<div>{displayAmount(latestValuation, true)}</div>
				</div>
				<div>
					<div className='font-semibold text-blue-700'>Total ownership</div>
					<div>
						{totalAmount.amount && latestValuation.amount
							? totalOwnership.toFixed(2)
							: '0'}
						%
					</div>
				</div>

				<div className='flex-1' />
				{!isReadOnly && (
					<NewInvestment
						company={company}
						trigger={
							<Button id='open-new-round' className='h-fit text-xs'>
								<Plus size={14} />
								New Round
							</Button>
						}
					/>
				)}
			</div>

			<div className='flex items-center gap-1'>
				<Button
					variant='ghost'
					className={
						clsx(
							'p-1',
							view === 'card' && 'text-blue-700'
						)
					}
					onClick={() => setView('card')}
				>
					<Rows2 size={17} />
				</Button>
				<Button
					variant='ghost'
					className={
						clsx(
							'p-1',
							view === 'list' && 'text-blue-700'
						)
					}
					onClick={() => setView('list')}
				>
					<List size={17} />
				</Button>
			</div>

			<div className=''>
				{
					view === 'card' && <div className='flex flex-wrap gap-5 mt-5'>
						{[...investments]
							.sort(
								(a, b) =>
									new Date(b.createdAt).getTime() -
									new Date(a.createdAt).getTime()
							)
							.map(investment => {
								return (
									<InvestmentCard
										key={investment.id}
										investment={investment}
										isReadOnly={isReadOnly}
									/>
								)
							})}
					</div>
				}

				{
					view === 'list' && <InvestmentList
						investments={[...investments].sort((a, b) => { return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime() })}
						isLoading={investmentsLoading}
					/>
				}

				{investments.length === 0 && (
					<div className='text-center text-gray-500'>No investments found</div>
				)}
			</div>
		</>
	)
}
