import type {
	ILimitedPartner,
	ITotalCapitalCommitted
} from '../interfaces/limitedPartner.interface'

import { nanoid } from 'nanoid'

export const LIMITED_PARTNER_DETAILS_INFO_TABS = [
	{
		id: 'details',
		label: 'Details'
	},
	{
		id: 'relevantcompanies',
		label: 'Relevant Companies'
	}
]

export const TOTAL_COMMITED_CAPITAL: ITotalCapitalCommitted = {
	amountRemaining: [
		{ amount: 1_500_000, currency: 'USD' },
		{ amount: 1_000_000, currency: 'AUD' },
		{ amount: 1_000_000, currency: 'NZD' },
		{ amount: 1_000_000, currency: 'EUR' }
	],
	capitalCommitted: [
		{ amount: 100_000, currency: 'USD' },
		{ amount: 100_000, currency: 'AUD' },
		{ amount: 100_000, currency: 'NZD' },
		{ amount: 100_000, currency: 'EUR' }
	],
	lastCapitalCall: [
		{ amount: 600_000, currency: 'USD' },
		{ amount: 600_000, currency: 'AUD' },
		{ amount: 600_000, currency: 'NZD' },
		{ amount: 600_000, currency: 'EUR' }
	],
	lastCapitalCallPercentage: 10
}

export const LIMITED_PARTNERS: ILimitedPartner[] = [
	{
		id: nanoid(),
		name: 'Alex Kennedy',
		linkedIn: '@pstehlik',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Carl Meyer',
		linkedIn: '@carl',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Andi Grant',
		linkedIn: '@grant',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Laurence Cooper',
		linkedIn: '@andigrantnz',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Alex Kennedy',
		linkedIn: '@pstehlik',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Carl Meyer',
		linkedIn: '@carl',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Andi Grant',
		linkedIn: '@grant',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Laurence Cooper',
		linkedIn: '@andigrantnz',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Alex Kennedy',
		linkedIn: '@pstehlik',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Carl Meyer',
		linkedIn: '@carl',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Andi Grant',
		linkedIn: '@grant',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Laurence Cooper',
		linkedIn: '@andigrantnz',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Alex Kennedy',
		linkedIn: '@pstehlik',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Carl Meyer',
		linkedIn: '@carl',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Andi Grant',
		linkedIn: '@grant',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Laurence Cooper',
		linkedIn: '@andigrantnz',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Alex Kennedy',
		linkedIn: '@pstehlik',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Carl Meyer',
		linkedIn: '@carl',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Andi Grant',
		linkedIn: '@grant',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Laurence Cooper',
		linkedIn: '@andigrantnz',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Alex Kennedy',
		linkedIn: '@pstehlik',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Carl Meyer',
		linkedIn: '@carl',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Andi Grant',
		linkedIn: '@grant',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Laurence Cooper',
		linkedIn: '@andigrantnz',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Alex Kennedy',
		linkedIn: '@pstehlik',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Carl Meyer',
		linkedIn: '@carl',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Andi Grant',
		linkedIn: '@grant',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Laurence Cooper',
		linkedIn: '@andigrantnz',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Alex Kennedy',
		linkedIn: '@pstehlik',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Carl Meyer',
		linkedIn: '@carl',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Andi Grant',
		linkedIn: '@grant',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Laurence Cooper',
		linkedIn: '@andigrantnz',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Alex Kennedy',
		linkedIn: '@pstehlik',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Carl Meyer',
		linkedIn: '@carl',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Andi Grant',
		linkedIn: '@grant',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Laurence Cooper',
		linkedIn: '@andigrantnz',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Alex Kennedy',
		linkedIn: '@pstehlik',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Carl Meyer',
		linkedIn: '@carl',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Andi Grant',
		linkedIn: '@grant',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Laurence Cooper',
		linkedIn: '@andigrantnz',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Alex Kennedy',
		linkedIn: '@pstehlik',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Carl Meyer',
		linkedIn: '@carl',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Andi Grant',
		linkedIn: '@grant',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Laurence Cooper',
		linkedIn: '@andigrantnz',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Alex Kennedy',
		linkedIn: '@pstehlik',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Carl Meyer',
		linkedIn: '@carl',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Andi Grant',
		linkedIn: '@grant',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 100_000, currency: 'USD' },
			{ amount: 100_000, currency: 'AUD' },
			{ amount: 100_000, currency: 'NZD' },
			{ amount: 100_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	},
	{
		id: nanoid(),
		name: 'Laurence Cooper',
		linkedIn: '@andigrantnz',
		location: 'USA',
		firstCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		committedCapitalCall: [
			{ amount: 150_000, currency: 'USD' },
			{ amount: 150_000, currency: 'AUD' },
			{ amount: 150_000, currency: 'NZD' },
			{ amount: 150_000, currency: 'EUR' }
		],
		capitalCallPercent: 65,
		dateJoined: 'January 29, 2024'
	}
]

export const COMMITED_CAPITAL_STACKED_AREA_DATA = [
	{
		name: 'Jan',
		lastCapitalCall: 40_000,
		amountRemaining: 2400
	},
	{
		name: 'Feb',
		lastCapitalCall: 30_000,
		amountRemaining: 1398
	},
	{
		name: 'Mar',
		lastCapitalCall: 20_000,
		amountRemaining: 9800
	},
	{
		name: 'Apr',
		lastCapitalCall: 27_800,
		amountRemaining: 3908
	},
	{
		name: 'May',
		lastCapitalCall: 18_900,
		amountRemaining: 4800
	},
	{
		name: 'Jun',
		lastCapitalCall: 23_900,
		amountRemaining: 3800
	},
	{
		name: 'July',
		lastCapitalCall: 34_900,
		amountRemaining: 4300
	},
	{
		name: 'Aug',
		lastCapitalCall: 32_490,
		amountRemaining: 4300
	},
	{
		name: 'Sept',
		lastCapitalCall: 32_490,
		amountRemaining: 4300
	}
]

export const markers = [
	{
		markerOffset: -30,
		name: 'Buenos Aires',
		coordinates: [-58.3816, -34.6037]
	},
	{ markerOffset: 15, name: 'La Paz', coordinates: [-68.1193, -16.4897] },
	{ markerOffset: 15, name: 'Brasilia', coordinates: [-47.8825, -15.7942] },
	{ markerOffset: 15, name: 'Santiago', coordinates: [-70.6693, -33.4489] },
	{ markerOffset: 15, name: 'Bogota', coordinates: [-74.0721, 4.711] },
	{ markerOffset: 15, name: 'Quito', coordinates: [-78.4678, -0.1807] },
	{ markerOffset: -30, name: 'Georgetown', coordinates: [-58.1551, 6.8013] },
	{ markerOffset: -30, name: 'Asuncion', coordinates: [-57.5759, -25.2637] },
	{ markerOffset: 15, name: 'Paramaribo', coordinates: [-55.2038, 5.852] },
	{ markerOffset: 15, name: 'Montevideo', coordinates: [-56.1645, -34.9011] },
	{ markerOffset: 15, name: 'Caracas', coordinates: [-66.9036, 10.4806] },
	{ markerOffset: 15, name: 'Lima', coordinates: [-77.0428, -12.0464] }
]
