/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useAppDispatch, useAppSelector } from 'app/hooks'

import Button from 'components/Buttons/Button'
import { CirclePlus } from 'lucide-react'
import { CustomModal } from 'components/Modals/CustomModal'
import type { IOnboardingData } from 'interfaces/onboarding.interface'
import InviteUser from './InviteUser'
import { auth } from 'lib/firebase'
import { createOnboardingData } from '../service/onboarding.service'
import { mapRoleToName } from 'utils/roles'
import { nanoid } from 'nanoid'
import { removeOnboardingUser } from '../redux/onboarding.slice'
import { useState } from 'react'

interface IInviteUsersProps {
	nextStep: () => void
}

export default function InviteUsers({ nextStep }: IInviteUsersProps) {
	const dispatch = useAppDispatch()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onboardingData = useAppSelector(state => state.onboarding.data)
	const users = useAppSelector(state => state.onboarding.data.invitedUsers)

	const [openModal, setOpenModal] = useState(false)

	const deleteUser = (index: number) => {
		dispatch(removeOnboardingUser(index))
	}

	const handleSubmit = async () => {
		const payload: IOnboardingData = {
			id: nanoid(),
			fundName: onboardingData.fundName,
			fundSlug: onboardingData.slug,
			website: onboardingData.website,
			currency: onboardingData.currency,
			invitationId: auth.currentUser?.uid ?? '',
			dataSources: onboardingData.dataSources,
			partnerInvitations: onboardingData.invitedUsers
		}

		createOnboardingData(payload)

		nextStep()
	}

	return (
		<div className='flex h-full w-full flex-col items-center justify-center'>
			<div className='flex w-1/2 flex-col rounded-xl border p-8'>
				<h2 className='mb-6 border-b pb-4 text-xl font-semibold text-primary'>
					Invite Users
				</h2>
				<div className='space-y-4'>
					{users.length > 0 && (
						<table className='mt-4 w-full border'>
							<thead>
								<tr className=''>
									<th className='border p-1 text-left'>Name</th>
									<th className='border p-1 text-left'>Email</th>
									<th className='border p-1 text-left'>Role</th>
									<th className='border p-1 text-left'>Action</th>
								</tr>
							</thead>
							<tbody>
								{users.map((user, index) => (
									<tr key={user.email}>
										<td className='border p-1 text-left'>{user.name}</td>
										<td className='border p-1 text-left'>{user.email}</td>
										<td className='border p-1 text-left'>
											{mapRoleToName(user.role)}
										</td>
										<td className='border p-1 text-left'>
											<div
												onClick={() => deleteUser(index)}
												className='cursor-pointer text-primary underline'
											>
												Delete
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}

					<CustomModal
						open={openModal}
						setOpen={setOpenModal}
						trigger={
							<div
								onClick={() => setOpenModal(true)}
								className='mt-8 flex w-full items-center justify-center gap-4 rounded border border-dashed border-spok-gray bg-spok-light-gray p-3 text-spok-gray'
							>
								<CirclePlus
									className='font-semibold text-spok-gray'
									size={14}
								/>
								Add more users
							</div>
						}
					>
						<InviteUser
							nextStep={nextStep}
							closeModal={() => setOpenModal(false)}
						/>
					</CustomModal>

					<div className='flex justify-center gap-4'>
						<Button
							variant='outline'
							onClick={() => nextStep()}
							className='mt-4 w-40 rounded p-2 '
						>
							Skip
						</Button>
						<Button
							onClick={() => handleSubmit()}
							className='mt-4 w-40 rounded p-2'
						>
							Continue
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}
