/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as Yup from 'yup'

import type {
	IAddUpdate,
	ICompanyUpdate
} from '../interfaces/company_update.interface'

import Button from 'components/Buttons/Button'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import type { CompanyData } from 'interfaces'
import CompanySearchSelector from 'features/companies/view/CompanySearchSelector'
import { DialogModal } from 'components/shadcn/DialogModal'
import FileSelector from 'components/FileSelector'
import FileViewer from 'features/fileviewers/views/FileViewer'
import type { IEmailMessage } from 'features/mail/interfaces/email.interface'
import { Input } from 'components/ui/input'
import { MailPlus } from 'lucide-react'
import { useFormik } from 'formik'
import { useState } from 'react'

interface Props {
	update?: ICompanyUpdate | null
	email?: IEmailMessage
	trigger: React.ReactNode
	company?: CompanyData
	onAddUpdate: (
		update: IAddUpdate,
		newFiles: File[],
		company: CompanyData,
		email?: IEmailMessage
	) => void
	showCompanySelector?: boolean
}

export default function AddUpdate({
	update,
	trigger,
	onAddUpdate,
	showCompanySelector,
	email,
	company
}: Props) {
	const [open, setOpen] = useState(false)

	const formik = useFormik({
		initialValues: {
			id: update?.id || '',
			year: update?.year || new Date().getFullYear().toString(),
			month:
				update?.month ||
				new Date().toLocaleString('default', { month: 'long' }),
			note: update?.note || '',
			files: update?.files || [],
			newFiles: [],
			company: update?.company || company || null,
			email: email || null
		},
		validationSchema: Yup.object({
			year: Yup.string().required('Year is required'),
			month: Yup.string().required('Month is required'),
			note: Yup.string()
		}),
		onSubmit: async values => {
			const data: IAddUpdate = {
				id: formik.values.id,
				year: formik.values.year,
				month: formik.values.month,
				note: formik.values.note,
				files: formik.values.files
			}
			
			onAddUpdate(data, values.newFiles, values.company as CompanyData, email)

			setOpen(false)
		}
	})

	// useEffect(() => {
	// 	if (update && open) {
	// 		formik.setValues({
	// 			id: update.id,
	// 			year: update.year,
	// 			month: update.month,
	// 			note: update.note,
	// 			files: update.files,
	// 			newFiles: [],
	// 			company: update.company || null,
	// 			email: email || null
	// 		})
	// 	} else {
	// 		formik.resetForm()
	// 	}
	// }, [update, open])

	return (
		<DialogModal
			open={open}
			setOpen={setOpen}
			title={update ? 'Edit Update' : 'Add Update'}
			description={
				update
					? `Edit update for ${update.month} ${update.year}`
					: 'Add a new update'
			}
			trigger={
				<div
					onClick={() => {
						setOpen(true)
					}}
				>
					{trigger}
				</div>
			}
		>
			<main className='flex flex-col gap-3 pb-3'>
				<div className='rounded-md border p-3'>
					{showCompanySelector && (
						<CompanySearchSelector
							label='Select Company'
							defaultValue={formik.values.company?.name || ''}
							onSelect={value => {
								formik.setFieldValue('company', value)
							}}
						/>
					)}
					<div className='flex items-center gap-3 border-b pb-2'>
						<div>
							<ComboSelect
								onSelect={value => {
									formik.setFieldValue('year', value)
								}}
								defaultValue={formik.values.year}
								options={Array.from({ length: 10 }, (_, i) => ({
									label: (new Date().getFullYear() - i).toString(),
									value: (new Date().getFullYear() - i).toString()
								}))}
								content={<div>{formik.values.year}</div>}
							/>
							<div>{formik.errors.year}</div>
						</div>


						<div>
							<ComboSelect
								onSelect={value => {
									formik.setFieldValue('month', value)
								}}
								defaultValue={formik.values.month}
								options={Array.from({ length: 12 }, (_, i) => ({
									label: new Date(new Date().setMonth(i)).toLocaleString(
										'default',
										{ month: 'long' }
									),
									value: new Date(new Date().setMonth(i)).toLocaleString(
										'default',
										{ month: 'long' }
									)
								}))}
								content={<div>{formik.values.month}</div>}
							/>
							<div>{formik.errors.month}</div>
						</div>
					</div>
					{email && (
						<div className='mt-3 flex items-center gap-2 p-2 text-sm'>
							<MailPlus size={15} color='blue' />
							{email.subject}
						</div>
					)}

					<div className='mt-3 flex w-full items-start gap-2'>
						<div className='flex-1'>
							<Input
								error={formik.touched.note && formik.errors.note}
								id='input-update'
								name='note'
								className='w-full rounded border border-gray-200 shadow-none'
								placeholder='Add note'
								value={formik.values.note}
								onChange={async e =>
									formik.setFieldValue('note', e.target.value)
								}
								isMultiline
							/>
						</div>
					</div>

					<div className='mt-3 flex flex-col gap-2'>
						{formik.values.files.map(file => (
							<FileViewer
								readonly
								key={file.id}
								file={file}
								variant='list'
								onDelete={item => {
									formik.setFieldValue(
										'files',
										formik.values.files.filter(f => f.id !== item.id)
									)
								}}
							/>
						))}
					</div>
					<FileSelector
						className='mt-3'
						onSelect={files => {
							formik.setFieldValue(
								'newFiles',
								files.map(f => f.file)
							)
						}}
					/>
				</div>

				<div className='flex w-full items-center justify-end gap-3'>
					<Button
						id='cancel-update'
						variant='outline'
						onClick={() => {
							setOpen(false)
						}}
					>
						Cancel
					</Button>
					<Button
						id='button-add-update'
						onClick={() => {
							formik.handleSubmit()
						}}
						disabled={!formik.isValid || formik.isSubmitting}
						isLoading={formik.isSubmitting}
					>
						Add
					</Button>
				</div>
			</main>
		</DialogModal>
	)
}
