/* eslint-disable @typescript-eslint/no-explicit-any */

import type { AppThunk } from 'app/store'
import type { Unsubscribe } from 'firebase/firestore'
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	limit,
	onSnapshot,
	query,
	setDoc,
	updateDoc,
	where,
	writeBatch
} from 'firebase/firestore'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'
import type { IFile } from './file.interface'
import { setFiles } from './files.slice'

export const filesRef = collection(firestore, 'files')

export const addFile = async (file: IFile): Promise<IFile> => {
	const docRef = doc(filesRef, file.id)
	await setDoc(docRef, file, { merge: true })

	return file
}

export const addFiles = async (files: IFile[]): Promise<IFile[]> => {
	// Group into chunks of 500
	const batch = writeBatch(firestore)
	for (const file of files) {
		const docRef = doc(filesRef, file.id)
		batch.set(docRef, file)
	}
	await batch.commit()
	return files
}

export const getFile = async (id: string): Promise<IFile> => {
	const docRef = doc(filesRef, id)
	const docSnap = await getDoc(docRef)
	return docSnap.data() as IFile
}

export const getFiles = async (
	companyId: string,
	max = 50
): Promise<IFile[]> => {
	const q = query(filesRef, where('company.id', '==', companyId), limit(max))
	const querySnapshot = await getDocs(q)
	const files = querySnapshot.docs.map(d => d.data() as IFile)
	return files
}

export const deleteFile = async (id: string): Promise<void> => {
	const docRef = doc(filesRef, id)
	await deleteDoc(docRef)
}

export const updateFile = async (
	file: Partial<IFile>
): Promise<Partial<IFile>> => {
	const docRef = doc(filesRef, file.id)
	await updateDoc(docRef, file as any, { merge: true })
	return file
}

export const updateFiles = async (
	files: Partial<IFile>[]
): Promise<Partial<IFile>[]> => {
	// Group into chunks of 500
	const batch = writeBatch(firestore)
	for (const file of files) {
		const docRef = doc(filesRef, file.id)
		batch.update(docRef, file as any)
	}
	await batch.commit()
	return files
}

export const listenToFiles =
	(companyId: string): AppThunk<Unsubscribe> =>
		dispatch => {
			const q = query(filesRef, where('company.id', '==', companyId))

			const unsubscribe = onSnapshot(
				q,
				querySnapshot => {
					const files = querySnapshot.docs.map(d => d.data() as IFile)
					dispatch(setFiles(files))
				},
				error => {
					Errorhandler.captureException(error)
					Logger.error(error)
				}
			)

			return unsubscribe
		}

export const listenToLimitedPartnerFiles = (
	limitedPartnerId: string
): AppThunk<Unsubscribe> => dispatch => {
	const q = query(filesRef, where('limitedPartner.id', '==', limitedPartnerId))

	const unsubscribe = onSnapshot(
		q,
		querySnapshot => {
			const files = querySnapshot.docs.map(d => d.data() as IFile)
			dispatch(setFiles(files))
		},
		error => {
			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}
