/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppSelector } from 'app/hooks'
import Tags from 'components/Tags'
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { selectCompanyFunnels } from 'features/funnels/funnels.slice'
import type { ICompany } from 'interfaces'
import { Link } from 'react-router-dom'
import clsxm from 'utils/clsxm'
import { renderDate } from 'utils/date.util'
import CompanyCardMenu from './CompanyCardMenu'
import { InfoCircledIcon } from '@radix-ui/react-icons'

interface CompanyCardProps {
	company: ICompany
	isDragging?: boolean
}

function CompanyCard({ company, isDragging }: CompanyCardProps) {
	// const { authUser } = useContext(AuthContext)
	const funnels = useAppSelector(selectCompanyFunnels)
	// const navigate = useNavigate()
	return (
		<div
			className={clsxm([
				'flex h-full gap-3 rounded  border border-white bg-white p-4  text-gray-800  transition-all hover:bg-gray-50 ',
				'hover:border-gray-200 dark:hover:border-gray-600',
				'hover:cursor-pointer dark:bg-[#374151] dark:text-white',
				{ ' border-green-500': isDragging }
			])}
		>
			<Link
				to={`/company/${company.id}`}
				className='flex  w-full items-start gap-3'
			>
				<div className='flex flex-col items-center justify-center gap-2'>
					<div>
						<div className=' flex h-8 w-8 items-start rounded-full'>
							<Avatar className='h-8 w-8 border'>
								<AvatarImage
									src={
										company.website
											? `https://www.google.com/s2/favicons?sz=128&domain_url=${company.website}`
											: '/images/logo/Logo-4.png'
									}
								/>
								<AvatarFallback>C</AvatarFallback>
							</Avatar>
						</div>

						{company.pointPerson && company.pointPerson.name.length > 0 && (
							<div className='mt-1 flex flex-col items-center gap-1'>
								<div className='h-5 w-[1px] rounded bg-gray-300' />
								<div className='h-6 w-6 rounded-full bg-gray-200'>
									<Tooltip>
										<TooltipTrigger asChild>
											<Avatar className='h-6 w-6'>
												<AvatarImage
													src={
														company.website
															? `https://www.google.com/s2/favicons?sz=128&domain_url=${company.pointPerson.photoUrl}`
															: '/images/logo/Logo-4.png'
													}
												/>
												<AvatarFallback>C</AvatarFallback>
											</Avatar>
										</TooltipTrigger>
										<TooltipContent>
											<div>{company.pointPerson.name}</div>
										</TooltipContent>
									</Tooltip>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='flex-1 gap-x-3'>
					<h2 className='line-clamp-1 whitespace-pre-wrap text-base font-semibold text-black'>
						{company.name}
					</h2>

					<div className=' py-2'>
						<Tags tags={company.tags || []} maxNumber={2} />
					</div>
					<div className='mt-3 flex items-center gap-1 text-xs text-gray-500'>
						{renderDate(company.createdAt)}
						<div className='flex items-center gap-x-1 px-3 text-blue-800'>
							{company.hasCompanyDeck && (
								<ion-icon name='document-text-outline' />
							)}
							{company.hasAiGeneratedInfo && (
								<ion-icon name='color-wand-outline' />
							)}
							{company.hasInvestmentMemo && (
								<InfoCircledIcon className='h-5 text-green-500' />
							)}
						</div>
					</div>
				</div>
			</Link>
			<div className={clsxm('flex flex-col items-center justify-between ')}>
				<CompanyCardMenu company={company} funnels={funnels} />
			</div>
		</div>
	)
}

export default CompanyCard
