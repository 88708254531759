/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import _ from 'lodash'
import type { ITask } from '../interafces/task.interface'

export interface TaskSliceState {
	data: ITask[]
	isLoading: boolean
	error?: string | null
}

const initialState: TaskSliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const tasksSlice = createAppSlice({
	name: 'tasks',
	initialState,
	reducers: create => ({
		setTasks: create.reducer((state, action: PayloadAction<ITask[]>) => {
			state.isLoading = false
			state.data = action.payload
		}),
		addTasks: create.reducer((state, action: PayloadAction<ITask[]>) => {
			const merged = _.uniqBy([...action.payload, ...state.data], 'id')
			state.data = merged

			state.isLoading = false
		}),
		setTasksLoading: create.reducer((state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload
		}),
		setTasksError: create.reducer(
			(state, action: PayloadAction<string | null>) => {
				state.error = action.payload
				state.isLoading = false
			}
		)
	}),
	selectors: {
		selectCompanyTasks: (state, companyId: string) =>
			state.data.filter(task => task.company?.id === companyId)
	}
})

export const { setTasks, setTasksError, setTasksLoading, addTasks } =
	tasksSlice.actions

export const { selectCompanyTasks } = tasksSlice.selectors
