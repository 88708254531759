/* eslint-disable @typescript-eslint/no-extraneous-class */
import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from "lib/firebase";

export class ProtectedServices {

  private static readonly ref = collection(firestore, 'protected_settings')


  public static async getMaintenanceStatus() {
    const maintenanceDoc = doc(this.ref, 'maintenance')
    const maintenanceDocData = (await getDoc(maintenanceDoc))

    if (!maintenanceDocData.exists()) {
      return {
        isMaintenanceOn: false,
        message: ''
      }
    }

    const { isMaintenanceOn, message } = maintenanceDocData.data()

    return {
      isMaintenanceOn,
      message
    }
  }

}