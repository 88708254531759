import {
	getCompanies,
	getNextCompanies
} from 'features/companies/api/companies.api'

import type { ICompany } from 'interfaces'
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'app/store'
import { createAppSlice } from 'app/createAppSlice'

export interface CompaniesSliceState {
	data: ICompany[]
	isLoading: boolean
	error?: string | null
	shareableCompanies: ICompany[]
}

const initialState: CompaniesSliceState = {
	data: [],
	isLoading: true,
	error: null,
	shareableCompanies: []
}

export const companiesSlice = createAppSlice({
	name: 'companies',
	initialState,
	reducers: create => ({
		fetchCompanies: create.asyncThunk(
			async (fundId: string) => {
				const response = await getCompanies(fundId)
				return response
			},
			{
				pending: state => {
					state.isLoading = true
				},
				fulfilled: (state, action) => {
					state.data = action.payload
					state.isLoading = false
				},
				rejected: (state, action) => {
					state.error = action.error.message
					state.isLoading = false
				}
			}
		),
		fetchNextCompanies: create.asyncThunk(
			async ({ fundId }: { fundId: string }, { getState }) => {
				const lastCompany = companiesSlice.selectors.selectLastCompany(
					getState() as RootState
				)
				const response = await getNextCompanies(fundId, lastCompany)
				return response
			},
			{
				pending: state => {
					state.isLoading = true
				},
				fulfilled: (state, action) => {
					state.data = [...state.data, ...action.payload]
					state.isLoading = false
				},
				rejected: (state, action) => {
					state.error = action.error.message
					state.isLoading = false
				}
			}
		),
		setCompaniesIsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setCompaniesError: create.reducer(
			(state, action: PayloadAction<string | null>) => {
				state.error = action.payload
				state.isLoading = false
			}
		),
		setCompanies: create.reducer((state, action: PayloadAction<ICompany[]>) => {
			state.data = action.payload
			state.isLoading = false
		}),
		setShareableCompanies: create.reducer(
			(state, action: PayloadAction<ICompany[]>) => {
				state.shareableCompanies = action.payload
			}
		)
	}),
	selectors: {
		selectCompaniesState: state => state,
		selectCompanies: company => company.data,
		selectLastCompany: company => company.data.at(-1),
		selectInvitatonCount: company => company.data.length,
		getShareableCompanies: company => company.shareableCompanies,
		getCompanyNames: company => company.data.map(item => item.name)
	}
})

export const {
	fetchCompanies,
	setCompanies,
	setShareableCompanies,
	setCompaniesIsLoading,
	setCompaniesError
} = companiesSlice.actions

export const {
	selectCompaniesState,
	selectCompanies,
	selectInvitatonCount,
	getShareableCompanies,
	getCompanyNames
} = companiesSlice.selectors
