/* eslint-disable unicorn/no-array-callback-reference */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react/no-array-index-key */
import Skeleton from "components/Skeleton"
import { useContext, useEffect } from "react";
import { AuthContext } from "context/AuthContext"
import { useSelector } from "react-redux";
import { DashboardService } from "../services/dashboard.service"
import { selectMeetings, selectMeetingStatus } from "../redux/dashboardHome.slice";
import { getCalendarEventStatus } from "features/calendar_events/helpers/calendar.helper";
import clsx from "clsx";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import { renderDateAndTime, renderTime } from "utils/date.util";



export default function DashboardMeetings() {
  const { authUser } = useContext(AuthContext);
  const meetings = useSelector(selectMeetings)
  const loading = useSelector(selectMeetingStatus);

  useEffect(() => {
    if (!authUser?.fund.id) return;
    const unsubscribe = DashboardService.listenToFundDashboardMeetings({
      fundId: authUser.fund.id,
    });
    return () => unsubscribe();
  }, [authUser?.fund.id]);

  if (loading) {
    return (
      <div className="flex flex-col gap-3">
        {["Today", "Yesterday"].map((period) => (
          <div key={period} className="flex gap-3 border rounded-md p-3 flex-col">
            <div>{period}</div>
            <div>
              {["", "", "", ""].map((_, index) => (
                <div key={index} className="flex gap-5">
                  <div className="mt-[0.6rem]">
                    <Skeleton className="h-3 w-16 rounded-md border" />
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="flex items-center gap-1">
                      <Skeleton className="h-8 w-8 rounded-full border" />
                      <Skeleton className="h-8 w-8 rounded-full border" />
                      <Skeleton className="h-3 w-96 ml-5 rounded-md border" />
                    </div>
                    <Skeleton className="h-16 w-full rounded-md border" />
                    <div className="w-full flex items-end justify-end">
                      <Skeleton className="h-3 w-32 rounded-md border" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return <div className="border rounded-md p-3">
    {meetings.map(event => (
      <div
        key={event.id}
        className='mb-3 flex flex-col items-center gap-2 rounded-md border-b p-2'
      >
        <div className='flex w-full items-center justify-start gap-2'>
          <div
            className={clsx(
              'h-3 w-3 rounded-full',
              getCalendarEventStatus(event) === 'upcoming' &&
              'bg-green-500',
              getCalendarEventStatus(event) === 'past' &&
              'bg-gray-500',
              getCalendarEventStatus(event) === 'live' &&
              'bg-blue-500'
            )}
          />
          <div className='line-clamp-2 flex-1 text-sm font-semibold text-blue-700'>
            {event.summary}
          </div>
          {/* <CompanyHoverCard company={event.company} /> */}
        </div>
        <div className='flex w-full items-center gap-2'>
          <Avatar className='h-8 w-8 rounded-full border'>
            {event.user.photoUrl && (
              <AvatarImage
                src={
                  event.user.photoUrl || '/images/logo/Logo-4.png'
                }
              />
            )}
            <AvatarFallback>{event.user.name[0]}</AvatarFallback>
          </Avatar>
          <div className='text-xs font-semibold text-gray-600'>
            {renderDateAndTime(event.start.dateTime)} -{' '}
            {renderTime(event.end.dateTime)}
          </div>
        </div>
        <div className='w-full'>
          {event.attendees.map(attendee => (
            <div key={attendee.email} className='text-xs'>
              {attendee.email}
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>

}
