/* eslint-disable react/no-danger */
import DashboardLayout from "components/Dashboard/DashboardLayout";
import QuillEditor from "features/quilleditor/views/QuillEditor";
import { useState } from "react";

export default function Dev() {

  const [value, setValue] = useState('')


  return <DashboardLayout hideHeader={false} showSidebar title='Dev'>
    <div className="p-4">

      <div className="p-2 border rounded-md mb-4">
        <div className="border-b mb-3">
          Quill Output
        </div>
        {/* <div dangerouslySetInnerHTML={{ __html: value }} /> */}
        {value}
      </div>


      <QuillEditor
        className='mb-20 h-[50vh] w-full'
        id='note-editor'
        value={value}
        onChange={value => {
          setValue(value)
        }}
      />
    </div>
  </DashboardLayout>
}