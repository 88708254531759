/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-extraneous-class */
/* eslint-disable unicorn/no-static-only-class */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	collection,
	deleteDoc,
	doc,
	onSnapshot,
	query,
	setDoc,
	where
} from 'firebase/firestore'

import { Errorhandler } from 'lib/sentry'
import type { IDraftNote } from '../interfaces/notes.interface'
import { Logger } from 'lib/logger'
import { firestore } from 'lib/firebase'
import { setDraftNotes } from '../redux/draft_notes.slice'
import { store } from 'app/store'
import { toast } from 'sonner'

export class DraftNoteService {
	private static readonly collection = collection(firestore, 'note_drafts')

	public static addDraftNote = async (note: IDraftNote) => {
		try {
			const ref = doc(this.collection, note.id)
			await setDoc(ref, note)
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to add draft note')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static updateDraftNote = async (
		noteId: string,
		note: Partial<IDraftNote>
	) => {
		try {
			const ref = doc(this.collection, noteId)
			await setDoc(ref, note as any, { merge: true })
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to update draft note')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static deleteDratNote = async (noteId: string) => {
		try {
			const ref = doc(this.collection, noteId)
			await deleteDoc(ref)
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to delete draft note')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static listenToDraftNotes = (userId: string, fundId: string) => {
		const q = query(
			this.collection,
			where('creator.id', '==', userId),
			where('fund.id', '==', fundId)
		)
		const unsubscribe = onSnapshot(
			q,
			querySnapshot => {
				const notes: IDraftNote[] = []
				for (const doc of querySnapshot.docs) {
					notes.push(doc.data() as IDraftNote)
				}
				store.dispatch(setDraftNotes(notes))
			},
			(error: any) => {
				toast.error(error.message ?? 'Failed to fetch draft notes')

				Logger.error(error)
				Errorhandler.captureException(error)
			}
		)

		return unsubscribe
	}
}
