import type { FundData } from './fund.interface'
import type { UserRole } from './user.interface'

export interface Invitation {
	id: string
	fund: FundData
	invitor: {
		id: string
		name: string
		role: UserRole
		photoUrl?: string
	}
	invitee: {
		name: string
		email: string
		role: UserRole
		photoUrl?: string
	}
	status: InvitationStatus
	mail: {
		status: 'failed' | 'pending' | 'sent'
		error?: string
		timestamp?: string
	}
	createdAt: string
	lastUpdatedAt: string
}

export enum InvitationStatus {
	PENDING = 'pending',
	ACCEPTED = 'accepted',
	REJECTED = 'rejected',
	EXPIRED = 'expired'
}
