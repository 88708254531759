import { useEffect, useState } from 'react'

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Button from 'components/Buttons/Button'
import type { ICompany } from 'interfaces'
import type { ISharedLink } from 'features/companies/interfaces/sharedLink.interface'
import { ImSpinner10 } from 'react-icons/im'
import Layout from 'components/Layout'
import { LogOut } from 'lucide-react'
import SharedLinkCompanyDetails from 'features/sharedlinks/views/SharedLinkCompanyDetails'
import SharedLinkRequireEmail from 'features/sharedlinks/views/SharedLinkRequireEmail'
import { getCompany } from 'features/companies/api/companies.api'
import { getSharedLink } from 'features/companies/api/sharedLinks.api'
import logger from 'lib/logger'
import { nanoid } from 'nanoid'
import { toast } from 'sonner'
import { useLocation } from 'react-router-dom'
import usePageTimeTracking from 'hooks/usePageTracking'
import useTrackTimeSpent from 'hooks/useTrackTimeSpent'
import { userHasAccessToLink } from 'features/companies/helpers/ShareCompany.helper'

export default function SharedLink(): JSX.Element {
	usePageTimeTracking({ pageName: 'Shared Company' })

	const sessionId = nanoid()
	const userEmail = localStorage.getItem('sharedLinkEmail') || ''

	const location = useLocation()
	const id = location.pathname.split('/').pop()

	const [linkData, setLinkData] = useState<ISharedLink | null>()
	const [isLoading, setIsLoading] = useState(true)
	const [invalidLink, setInvalidLink] = useState(false)

	// Selected Company
	const [selectedCompany, setSelectedCompany] = useState<ICompany>()
	const [loadingSelectedCompany, setLoadingSelectedCompany] = useState(false)
	const [hasAccess, setHasAccess] = useState(false)

	useTrackTimeSpent({
		sessionId,
		userEmail,
		linkData: {
			id: linkData?.id ?? '',
			shareOption: linkData?.shareOption ?? ''
		},
		companyData: {
			id: selectedCompany?.id ?? '',
			name: selectedCompany?.name ?? '',
			website: selectedCompany?.website ?? ''
		},
		fundData: selectedCompany?.fund ?? {
			id: '',
			name: '',
			slug: '',
			website: ''
		}
	})

	useEffect(() => {
		const getAccess = async () => {
			if (!linkData) return
			if (!userEmail) return

			try {
				setIsLoading(true)
				const access = await userHasAccessToLink(linkData, userEmail)
				setHasAccess(access)
			} catch (error: any) {
				toast.error(error.message ?? 'Error fetching access')
			} finally {
				setIsLoading(false)
			}
		}

		getAccess()
	}, [linkData, userEmail])

	function onLogout() {
		localStorage.removeItem('sharedLinkEmail')
		window.location.reload()
	}

	useEffect(() => {
		async function fetchLinkData() {
			if (!id) return
			try {
				setIsLoading(true)

				const data = await getSharedLink(id)
				if (data === null) {
					setInvalidLink(true)
					return
				}
				setLinkData(data)
				setSelectedCompany(data.companies[0] as ICompany)
			} catch (error: any) {
				toast.error(error.message ?? 'Error fetching shared link')
				setInvalidLink(true)
			} finally {
				setIsLoading(false)
			}
		}
		fetchLinkData()
	}, [id])

	useEffect(() => {
		async function fetchCompany() {
			try {
				if (!selectedCompany?.id) return
				setLoadingSelectedCompany(true)
				const company = await getCompany(selectedCompany.id)
				setSelectedCompany(company)
			} catch (error: any) {
				logger.error(error)
				toast.error(error.message ?? 'Error fetching selected company')
			} finally {
				setLoadingSelectedCompany(false)
			}
		}
		fetchCompany()
	}, [selectedCompany?.id])

	if (isLoading)
		return (
			<Layout>
				<div className='flex h-[calc(100vh-3rem)] flex-col items-center gap-3 pt-20'>
					<ImSpinner10 className='animate-spin' />
					<p>Loading...</p>
				</div>
			</Layout>
		)

	if (invalidLink)
		return (
			<Layout>
				<div className='flex h-[calc(100vh-3rem)] flex-col items-center gap-3 pt-20'>
					<p>Invalid Link</p>
					<Button variant='blue' onClick={() => window.location.replace('/')}>
						Go Home
					</Button>
				</div>
			</Layout>
		)

	if (!hasAccess && !isLoading)
		return (
			<Layout>
				<div className='flex w-full justify-center pt-10'>
					<SharedLinkRequireEmail linkData={linkData} />
				</div>
			</Layout>
		)

	return (
		<Layout>
			<div className='flex h-[calc(100vh-3rem)] gap-2 overflow-hidden '>
				<div className='h-full overflow-auto px-5'>
					<div className='flex h-full flex-col pt-5'>
						<h1 className='text-lg font-semibold'>Shared Companies</h1>
						<p className='text-sm text-gray-400'>
							{linkData?.companies.length} Companies shared with you
						</p>
						{userEmail && (
							<div className=' mt-2 flex items-center gap-1'>
								<div className='rounded-lg bg-blue-50 px-2 py-0.5 text-xs text-blue-700 '>
									{userEmail}
								</div>
								<Button variant='ghost' className='p-2' onClick={onLogout}>
									<LogOut size={15} color='red' />
								</Button>
							</div>
						)}
						<div className='mt-5'>
							{linkData?.companies.map(company => (
								<div
									onClick={() => {
										if (selectedCompany?.id === company.id) return
										setSelectedCompany(company as ICompany)
									}}
									key={company.id}
									className='flex cursor-pointer items-center gap-3 rounded-md border-b py-3 pr-3 transition-all hover:bg-gray-100'
								>
									<div className='flex h-8 w-8 items-start rounded-full'>
										<img
											src={
												company.website
													? `https://www.google.com/s2/favicons?sz=128&domain_url=${company.website}`
													: '/images/logo/Logo-4.png'
											}
											alt='company'
											className='h-8 w-8 rounded-full border'
										/>
									</div>
									<div>
										<h3 className='text-sm font-semibold'>{company.name}</h3>
										<p className='text-xs text-gray-400'>{company.website}</p>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className='flex h-full flex-1 flex-col gap-3 bg-gray-50 pl-5 pt-5'>
					{loadingSelectedCompany && (
						<div className='mt-10 flex h-full flex-col items-center gap-5'>
							<ImSpinner10 className='animate-spin' />
							<div>Loading Company...</div>
						</div>
					)}

					{!loadingSelectedCompany && (
						<SharedLinkCompanyDetails
							loadingSelectedCompany={false}
							company={selectedCompany}
							linkData={linkData}
							currentUserEmail={userEmail}
						/>
					)}
				</div>
			</div>
		</Layout>
	)
}
