export const CUSTOM_SHARE_OPTIONS = [
	{
		label: 'Summary',
		value: 'summary'
	},
	{
		label: 'Deck',
		value: 'deck'
	},
	{
		label: 'Files',
		value: 'files'
	},
	{
		label: 'Investment',
		value: 'investment'
	},
	{
		label: 'Memorandum',
		value: 'memorandum'
	},
	{
		label: 'Notes',
		value: 'notes'
	},
	{
		label: 'Updates',
		value: 'updates'
	}
]
