import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons'
import { cx } from 'class-variance-authority'
import Button from 'components/Buttons/Button'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { getAmount } from 'utils/currency'
import { currencyFormatter } from 'utils/currencyformatter.util'
import { getMonthName } from 'utils/date.util'
import { percentangeFormatter } from 'utils/percentageFormatter.util'
import type {
    ICapitalCallMonthlySummary,
    ICapitalCallRequestMap,
    ICapitalCallYearlySummary
} from '../interfaces/capitalCall.interface'
import CapitalCallRequestList from './CapitalCallRequestList'

interface Props {
	data?: ICapitalCallRequestMap
	yearlySummary?: Record<number, ICapitalCallYearlySummary>
	monthlySummary?: Record<number, Record<number, ICapitalCallMonthlySummary>>
}

export default function CapitalCallSummaryTable({
	data,
	yearlySummary,
	monthlySummary
}: Props): JSX.Element {
	const [expandableMonthlyMap, setExpandableMonthlyMap] = useState<
		Record<string, boolean>
	>({})

	function onToggleExpanded(id: string) {
		const isExpanded: boolean = expandableMonthlyMap[id] || false

		setExpandableMonthlyMap({
			...expandableMonthlyMap,
			[id]: !isExpanded
		})
	}

	return (
		<div>
			{data &&
				Object.keys(data).map(
					year =>
						yearlySummary?.[Number(year)] && (
							<div key={nanoid()} className={cx('mb-4')}>
								<div className='mb-4 flex items-center rounded border border-blue-100 bg-white p-5 py-3'>
									<div className='mr-10 text-3xl font-semibold'>{year}</div>
									<div className='mr-7'>
										<span className='mr-1 font-semibold text-blue-600'>
											Total
										</span>
										<span>
											{currencyFormatter('USD').format(
												getAmount(yearlySummary[Number(year)].total, 'USD')
											)}
										</span>
									</div>
									<div className='mr-20'>
										<span className='mr-1 font-semibold text-blue-600'>
											Total Сapital calls
										</span>
										<span>{yearlySummary[Number(year)].totalCapitalCall}</span>
									</div>
									<div>
										<span className='mr-1 font-semibold text-blue-600'>
											Percent remaining
										</span>
										<span>
											{percentangeFormatter(
												yearlySummary[Number(year)].percentRemaining
											)}
										</span>
									</div>
								</div>
								{Object.keys(data[Number(year)]).map(month => (
									<div key={nanoid()}>
										<div className='mb-2 flex items-center rounded border border-blue-100 bg-white p-5 px-5'>
											<div className='mr-9 text-lg font-semibold'>
												{getMonthName(Number(month))}
											</div>
											<div className='mr-20'>
												<span className='mr-1 font-semibold text-blue-600'>
													Total capital calls
												</span>
												<span>
													{
														monthlySummary?.[Number(year)][Number(month)]
															.totalCapitalCall
													}
												</span>
											</div>
											<div className='mr-9'>
												<span className='mr-1 font-semibold text-blue-600'>
													Percent called
												</span>
												<span>
													{monthlySummary &&
														percentangeFormatter(
															monthlySummary[Number(year)][Number(month)]
																.percentCalled
														)}
												</span>
											</div>
											<div className='mr-9'>
												<span className='mr-1 font-semibold text-blue-600'>
													Total
												</span>
												<span>
													{monthlySummary &&
														currencyFormatter('USD').format(
															getAmount(
																monthlySummary[Number(year)][Number(month)]
																	.total,
																'USD'
															)
														)}
												</span>
											</div>
											{/* <div className="mr-9">
                          <span className="mr-1 text-blue-600 font-semibold">Called on</span>
                          <span>{monthlySummary?.[Number(year)][Number(month)].calledOn}</span>
                      </div> */}
											<div className='mr-auto'>
												<span className='mr-1 font-semibold text-blue-600'>
													Percent remaining
												</span>
												<span>
													{monthlySummary &&
														percentangeFormatter(
															monthlySummary[Number(year)][Number(month)]
																.percentRemaining
														)}
												</span>
											</div>
											<div>
												<Button
													className='w-fit truncate rounded-full border border-blue-300 p-0'
													variant='ghost'
													onClick={() =>
														onToggleExpanded(
															monthlySummary?.[Number(year)][Number(month)]
																.id || ''
														)
													}
												>
													{monthlySummary &&
													expandableMonthlyMap[
														monthlySummary[Number(year)][Number(month)].id
													] ? (
														<CaretDownIcon
															width='24'
															height='24'
															color='#93c5fd'
														/>
													) : (
														<CaretUpIcon
															width='24'
															height='24'
															color='#93c5fd'
														/>
													)}
												</Button>
											</div>
										</div>
										<div
											className=''
											style={{
												display:
													monthlySummary &&
													expandableMonthlyMap[
														monthlySummary[Number(year)][Number(month)].id
													]
														? 'none'
														: 'block'
											}}
										>
											<CapitalCallRequestList
												data={data[Number(year)][Number(month)]}
											/>
										</div>
									</div>
								))}
							</div>
						)
				)}
		</div>
	)
}
