import { useAppSelector } from 'app/hooks'
import { AuthContext } from 'context/AuthContext'
import { useContext, useEffect, useState } from 'react'
import {
	listenToCryptoAccounts,
	updateDefaultCryptoAccount
} from '../api/crypto-accounts.api'
import AddEditCryptoAccountDialog from '../component/AddEditCryptoAccount'
import type { ICryptoAccount } from '../interface/cryptoAccount.interface'
import { selectCryptoAccounts } from '../redux/cryptoAccounts.slice'
import { deleteCryptoAccountById } from '../service/crypto_account.service'
import CryptoAccountList from './CryptoAccountList'

export default function CryptoAccountPage(): JSX.Element {
	const {authUser} = useContext(AuthContext)
	
	const cryptoAccounts = useAppSelector(selectCryptoAccounts)
	const [isOpen, setIsOpen] = useState(false)
	const [isEditMode, setIsEditMode] = useState(false)
	const [formInitialValue, setFormInitialValue] = useState<ICryptoAccount>()

	function onClickEdit(value: ICryptoAccount) {
		setFormInitialValue(value)
		setIsEditMode(true)
		setIsOpen(true)
	}

	function onSubmitSuccess() {
		setIsEditMode(false)
		setIsOpen(false)
		setFormInitialValue(undefined)
	}

	async function onClickSetAsDefault(value: ICryptoAccount) {
		await updateDefaultCryptoAccount(value.id)
	}

	useEffect(() => {
		if (!isOpen) {
			setFormInitialValue(undefined)
			setIsEditMode(false)
		}
	}, [isOpen])

	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribe = listenToCryptoAccounts(authUser?.fund.id)
		return () => unsubscribe()
	}, [authUser?.fund.id])

	return (
		<div className='h-100vh flex flex-col p-5'>
			<div className='flex items-center justify-between'>
				<div className='pt-5 text-left text-lg font-semibold'>
					<div className='flex justify-between'>Crypto Accounts</div>
					<p className='text-sm font-normal text-gray-500'>
						Manage your crypto accounts
					</p>
				</div>
				<div>
					<AddEditCryptoAccountDialog
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						initialValue={formInitialValue}
						editMode={isEditMode}
						onSuccess={onSubmitSuccess}
					/>
				</div>
			</div>
			<CryptoAccountList
				data={cryptoAccounts}
				onClickSetDefault={onClickSetAsDefault}
				onClickEdit={value => onClickEdit(value)}
				onDelete={(value: ICryptoAccount) =>
					deleteCryptoAccountById(value.id, value.isDefault)
				}
			/>
		</div>
	)
}
