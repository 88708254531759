import Tags from "components/Tags";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import DOMPurify from "dompurify";
import { nanoid } from "nanoid";
import pluralize from "pluralize";
import { tagsMapper } from "../helper/reports.utils";
import type { IAiReports } from "../interfaces/reports.interface";
import sanitize from "sanitize-html";


interface AiReportsListProps {
  data: IAiReports[]
}

export default function AiReportsList(
  {
    data
  }: AiReportsListProps
): JSX.Element {

  return (
    <div className="bg-white border border-[#E4E5E8] rounded rounded-md p-6 pt-3">
      <div className="font-semibold mb-2">
        {data.length} {pluralize("Company", data.length)}
      </div>
      <div>
        {data.map((reportData) => (
          <div key={reportData.id} className="bg-white border border-[#A4A4A4] rounded rounded-md px-5 py-4 mb-5">
            <div className="flex items-center mb-3">
              <div className="flex items-center gap-2 border-b pb-2 border-gray-300">
                <div className='rounded flex h-10 w-10 items-start rounded-full'>
                  <Avatar className='h-10 w-10 border border-[#A4A4A4]'>
                    <AvatarImage
                      className="bg-blue"
                      src={
                        reportData.company.website
                          ? `https://www.google.com/s2/favicons?sz=128&domain_url=${reportData.company.website}`
                          : '/images/logo/Logo-4.png'
                      }
                    />
                    <AvatarFallback>C</AvatarFallback>
                  </Avatar>
                </div>

                <div className="capitalize font-semibold">
                  {reportData.company.name}
                </div>
              </div>
              <div className="ml-7">
                <Tags tags={tagsMapper(reportData.tags)} slice={false} />
              </div>
            </div>
            <div>
              <ol className="list-decimal list-inside">
                {
                  reportData.reports.map((report) => (
                    // eslint-disable-next-line react/no-danger, react/no-danger-with-children
                    <li key={`${report} - ${nanoid()}`} dangerouslySetInnerHTML={{ __html: sanitize(report) }}>{ }</li>
                  ))
                }
              </ol>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
