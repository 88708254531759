import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import type { IContact } from 'interfaces'
import { NoteEditorState, NoteType, type IDraftNote } from 'features/notes/interfaces/notes.interface'
import NotesList from 'features/notes/components/NotesList'
import { addDraftNote } from 'features/notes/redux/draft_notes.slice'
import { listenToContactNotes } from 'features/notes/api/notes.api'
import { nanoid } from 'nanoid'
import {
	selectContactNotes
} from 'features/notes/redux/notes.slice'
import { store } from 'app/store'
/* eslint-disable consistent-return */
import { useAppSelector } from 'app/hooks'

interface Props {
	contact: IContact
}

export default function ContactNotes({ contact }: Props) {
	const {authUser} = useContext(AuthContext)
	const { isLoading } = useAppSelector(state => state.notes)
	const notes = useAppSelector(state => selectContactNotes(state, contact.id))

	useEffect(() => {
		const unsubscribe = listenToContactNotes(contact.id)
		return () => unsubscribe()
	}, [contact.id])

	return (
		<NotesList
			notes={notes}
			loadingNotes={isLoading}
			onCreateDraftNote={() => {
				if(!authUser) return
				
				const note: IDraftNote = {
					id: nanoid(),
					windowState: NoteEditorState.RESTORED,
					contact: {
						id: contact.id,
						name: contact.name,
						email: contact.email || ''
					},
					fund: authUser.fund,
					creator: { id: authUser.id, name: authUser.name, photoUrl: authUser.photoUrl ?? '' },
					noteType: NoteType.Contact
				}
				store.dispatch(addDraftNote(note))
			}}
		/>
	)
}
