/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable react/function-component-definition */

import { CalendarDays, Hash, SquareChevronDown, Tag, TextCursorInput, Type } from "lucide-react";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { setSelectedButton } from "../hooks/customColumn.slice";

interface PageProps {

}


const CustomizeColumn: FC<PageProps> = () => {
  const dispatch = useDispatch();

  const handleClick = (value: string) => {
    dispatch(setSelectedButton(value));
  }

  const items = [
    { label: 'Dropdown', icon: SquareChevronDown, value: 'dropdown' },
    { label: 'Text', icon: Type, value: 'text' },
    { label: 'Date', icon: CalendarDays, value: 'date' },
    { label: 'Text area', icon: TextCursorInput, value: 'textarea' },
    { label: 'Labels', icon: Tag, value: 'label' },
    { label: 'Numbers', icon: Hash, value: 'numbers' },
  ];

  const ClickableItem = ({ onClick, icon: Icon, label }) => (
    <div
      onClick={onClick}
      className="flex items-center gap-2 hover:cursor-pointer hover:bg-slate-100 p-2"
    >
      <Icon size={20} className="text-blue-600" />
      {label}
    </div>
  );

  return (
    <div className="p-3">
      <input type="search" name="" id="" placeholder="Search" className="bg-slate-100 rounded-md w-full h-7 border-none" />
      <hr className="my-3" />

      <h3 className="text-blue-600 font-bold mb-3">Custom</h3>

      <div className="flex flex-col">
        {items.map((item) => (
          <ClickableItem
            key={item.value}
            onClick={() => handleClick(item.value)}
            icon={item.icon}
            label={item.label}
          />
        ))}
      </div>

    </div>
  )
}

export default CustomizeColumn;