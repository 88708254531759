export function capitalToBeCalledPieChartMapper(
	previouslyCalled: number,
	capitalToBeCalled: number,
	committedCapitalCall: number
) {
	return [
		{
			name: 'data',
			previouslyCalled,
			capitalToBeCalled,
			committedCapitalCall
		}
	]
}
