/* eslint-disable @typescript-eslint/no-useless-template-literals */
import { Copy, Linkedin, SquareArrowOutUpRight, Trash2 } from 'lucide-react'

import AddFounder from './AddFounder'
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Button from 'components/Buttons/Button'
import DeleteModal from 'components/DeleteModal'
import type { ICompany } from 'interfaces'
import clsxm from 'utils/clsxm'
import { deleteCompanyFounder } from '../api/companies.api'
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom'
import type { IActivity } from 'features/activity/interfaces/actvitiy.interface'
import { nanoid } from 'nanoid'
import { useContext } from 'react'
import { AuthContext } from 'context/AuthContext'
import { ActivityService } from 'features/activity/service/activity.service'

interface Props {
	company: ICompany
	viewOnly?: boolean
	wrapperClass?: string
}

export default function CompanyFounders({
	company,
	wrapperClass,
	viewOnly = false
}: Props) {
	const navigate = useNavigate()
	const { authUser } = useContext(AuthContext)

	async function onDeleteFounder(founderEmail: string) {

		if (!authUser) return


		try {
			await deleteCompanyFounder({
				company,
				founderEmail
			})

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUser,
				fund: authUser.fund,
				activity: 'deleted founder',
				description: `${founderEmail}`,
				icon: '',
				summary: company.name,
				company: {
					id: company.id,
					name: company.name
				},
				createdAt: new Date().toISOString()
			}

			ActivityService.add(activity)

			toast.success('Founder deleted')
		} catch {
			toast.error('Failed to delete founder')
		}
	}

	return (
		<div className={clsxm(' border-b bg-white p-2', wrapperClass)}>
			<div className='flex items-center gap-2 pl-2 pt-2 text-base font-semibold text-gray-700'>
				Founders
				{!viewOnly && (
					<div data-testid='add-company-founder'>
						<AddFounder company={company} />
					</div>
				)}
			</div>
			<div className='flex flex-wrap'>
				{company.founders?.map(founder => (
					<div
						key={founder.email}
						className={`group flex items-center gap-2 p-2 `}
					>
						<div
							data-testid={`founderName-${founder.name}`}
							className='hidden h-8 w-8 items-center justify-center rounded-full bg-blue-500 text-white xl:flex'
						>
							{founder.name.slice(0, 1)}
						</div>
						<div className='flex flex-col'>
							<div className="flex items-center gap-1 text-sm font-semibold transition-all">
								{founder.name}
								{founder.contactId &&
									<SquareArrowOutUpRight
										size={15}
										className='cursor-pointer'
										onClick={() => {
											navigate(`/contact/${founder.contactId}`)
										}}
									/>
								}
								{founder.linkedIn &&
									<Linkedin
										size={15}
										onClick={() => {
											window.open(founder.linkedIn, '_blank')
										}}
									/>
								}
							</div>
							<span
								data-testid={`founderEmail-${founder.email}`}
								className='flex items-center gap-1 text-xs text-gray-500'
							>
								{founder.email}
								<Button
									variant='ghost'
									className='px-2 py-1'
									onClick={() => {
										navigator.clipboard.writeText(founder.email)
										toast.success('Copied to clipboard')
									}}
								>
									<Copy size={13} />
								</Button>
							</span>
						</div>

						{!viewOnly && (
							<>
								<div
									data-testid={`editFounder-${founder.email}`}
									className='ml-3'
								>
									<AddFounder company={company} founder={founder} />
								</div>

								<DeleteModal
									deleteButtonId={`deleteFounder-${founder.email}`}
									title='Delete Founder'
									description={`Are you sure you want to delete ${founder.name}?`}
									trigger={
										<Button
											id={`deleteFounder-${founder.email}`}
											data-testid={`deleteFounder-${founder.email}`}
											variant='ghost'
											className='cursor-pointer p-2 text-red-500 opacity-0 transition-all group-hover:opacity-100'
										>
											<Trash2 size={15} />
										</Button>
									}
									onDelete={() => {
										onDeleteFounder(founder.email)
									}}
								/>
							</>
						)}
					</div>
				))}
			</div>

			{company.founders?.length === 0 && (
				<div className='pl-2 text-sm text-gray-500'>No founders added yet</div>
			)}
		</div>
	)
}
