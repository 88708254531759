/* eslint-disable consistent-return */
import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import { NoteEditorState, NoteType, type IDraftNote } from 'features/notes/interfaces/notes.interface'
import type { ILimitedPartner } from 'interfaces'
import LimitedPartnerCompanyComments from './LimitedPartnerCompanyComments'
import NotesList from 'features/notes/components/NotesList'
import { addDraftNote } from 'features/notes/redux/draft_notes.slice'
import { listenToNotes } from 'features/notes/api/notes.api'
import { nanoid } from 'nanoid'
import {
	selectLimitedPartnerNotes
} from 'features/notes/redux/notes.slice'
import { store } from 'app/store'
import { useAppSelector } from 'app/hooks'

interface Props {
	limitedPartner: ILimitedPartner
}

export default function LimitedPartnerNotes({ limitedPartner }: Props) {
	const { authUser } = useContext(AuthContext)
	const { isLoading } = useAppSelector(state => state.notes)
	const notes = useAppSelector(state =>
		selectLimitedPartnerNotes(state, limitedPartner.id)
	)

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubscribe = listenToNotes(authUser.fund.id)
		return () => unsubscribe()
	}, [authUser?.fund.id])

	return (
		<div>
			<LimitedPartnerCompanyComments limitedPartner={limitedPartner} />
			<NotesList
				notes={notes}
				loadingNotes={isLoading}
				onCreateDraftNote={() => {
					if (!authUser) return
					const note: IDraftNote = {
						id: nanoid(),
						windowState: NoteEditorState.RESTORED,
						limitedPartner: {
							id: limitedPartner.id,
							name: limitedPartner.name,
							photoUrl: limitedPartner.photoUrl || ''
						},
						creator: { id: authUser.id || '', name: authUser.name || '', photoUrl: authUser.photoUrl || '' },
						fund: authUser.fund,
						noteType: NoteType.LimitedPartner
					}
					store.dispatch(addDraftNote(note))
				}}
			/>
		</div>
	)
}
