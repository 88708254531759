import type { IBankAccount } from '../interface/BankAccount.interface'
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface BankAccountSliceState {
	data: IBankAccount[]
	isLoading: boolean
	error?: string | null
}

const initialState: BankAccountSliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const bankAcountsSlice = createAppSlice({
	name: 'bankAccounts',
	initialState,
	reducers: create => ({
		setBankAccountsError: create.reducer(
			(state, action: PayloadAction<string>) => {
				state.isLoading = false
				state.error = action.payload
			}
		),
		setBankAccountsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setBankAccounts: create.reducer(
			(state, action: PayloadAction<IBankAccount[]>) => {
				state.isLoading = false
				state.data = action.payload
			}
		)
	}),
	selectors: {
		selectBankAccounts: state => state.data,
		selectBankAccountsLoading: state => state.isLoading,
		selectDefaultBankAccount: state => state.data.find(x => x.isDefault)
	}
})

export const { setBankAccountsLoading, setBankAccountsError, setBankAccounts } =
	bankAcountsSlice.actions

export const {
	selectBankAccounts,
	selectBankAccountsLoading,
	selectDefaultBankAccount
} = bankAcountsSlice.selectors
