import {
	isBankAccount,
	isCryptoAccount
} from '../interface/bankAccountTags.interface'

import { Button } from 'components/ui/button'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import { DialogModal } from 'components/shadcn/DialogModal'
import type { IBankAccount } from 'features/bank_accounts/interface/BankAccount.interface'
import type { ICryptoAccount } from 'features/crypto_accounts/interface/cryptoAccount.interface'
import { Plus } from 'lucide-react'
import clsx from 'clsx'
import { useState } from 'react'

interface Props {
	disabled?: boolean
	title?: string
	className?: string
	options?: { label: string; value: IBankAccount | ICryptoAccount }[]
	addButtonName?: string
	onSelect: (arg0: IBankAccount | ICryptoAccount) => () => void
}

export default function AddPaymentAccountTag({
	disabled = false,
	title,
	className,
	options,
	addButtonName,
	onSelect
}: Props) {
	const [isOpen, setIsOpen] = useState(false)
	const [selectedPayment, setSelectedPayment] = useState<
		IBankAccount | ICryptoAccount | undefined
	>()

	function onClickSelect() {
		if (selectedPayment) {
			onSelect(selectedPayment)
			setSelectedPayment(undefined)
		}

		setIsOpen(false)
	}

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title={title || 'Select Payment'}
			description=''
			trigger={
				disabled ? (
					<div />
				) : (
					<Button
						data-testid='open-tag-selector-button'
						id='open-tag-selector-button'
						variant='ghost'
						className={clsx(
							`${className} rounded-md bg-blue-100 px-2 py-0 text-sm text-blue-700`
						)}
						disabled={disabled}
						onClick={() => {
							setIsOpen(true)
							setSelectedPayment(undefined)
						}}
					>
						<Plus size={15} />
						<div>{addButtonName || 'Add'}</div>
					</Button>
				)
			}
		>
			<div>
				<div className='flex flex-col gap-2 rounded-md border bg-gray-50 p-5'>
					<div className='mb-2 block text-xs font-bold text-gray-700'>
						Account
					</div>
					<ComboSelect
						placeholder='Select Account'
						allowSearch
						options={options || []}
						defaultValue='Select Account'
						onSelect={value => {
							setSelectedPayment(value)
						}}
					/>
					{selectedPayment && isBankAccount(selectedPayment) && (
						<div className='mt-3 text-sm'>
							<div className='mb-2'>
								<div className='font-bold text-blue-600'>Bank Name</div>
								<div>{selectedPayment.bankName}</div>
							</div>
							<div className='mb-2'>
								<div className='font-bold text-blue-600'>Title</div>
								<div>{selectedPayment.title}</div>
							</div>
							<div className='mb-2'>
								<div className='font-bold text-blue-600'>Account Name</div>
								<div>{selectedPayment.accountName}</div>
							</div>
							<div className='mb-2'>
								<div className='font-bold text-blue-600'>Account Number</div>
								<div>{selectedPayment.accountNumber}</div>
							</div>
							<div className='mb-2'>
								<div className='font-bold text-blue-600'>Account Address</div>
								<div>{selectedPayment.accountAddress}</div>
							</div>
							<div className='mb-2'>
								<div className='font-bold text-blue-600'>Routing Number</div>
								<div>{selectedPayment.routingNumber}</div>
							</div>
							<div className='mb-2'>
								<div className='font-bold text-blue-600'>Swift Code</div>
								<div>{selectedPayment.swiftCode}</div>
							</div>
							<div className='mb-2'>
								<div className='font-bold text-blue-600'>Bank Address</div>
								<div>{selectedPayment.bankAddress}</div>
							</div>
						</div>
					)}
					{selectedPayment && isCryptoAccount(selectedPayment) && (
						<div className='mt-3 text-sm'>
							<div className='mb-2'>
								<div className='font-bold text-blue-600'>Account Name</div>
								<div>{selectedPayment.accountName}</div>
							</div>
							<div className='mb-2'>
								<div className='font-bold text-blue-600'>Description</div>
								<div>{selectedPayment.description}</div>
							</div>
							<div>
								<div className='font-bold text-blue-600'>Wallet Address</div>
								<div>{selectedPayment.walletAddress}</div>
							</div>
						</div>
					)}
					<div className='mt-5 flex w-full justify-end gap-5'>
						<Button
							variant='ghost'
							className='px-2 py-1 text-red-500'
							onClick={() => setIsOpen(false)}
						>
							Cancel
						</Button>
						<Button
							id='create-tag-button'
							data-testid='create-tag-button'
							disabled={!selectedPayment}
							onClick={() => onClickSelect()}
						>
							Select
						</Button>
					</div>
				</div>
			</div>
		</DialogModal>
	)
}
