import Skeleton from 'components/Skeleton'
import type { ISharedLinkCompanyComment } from 'features/sharedlinks/interfaces/sharedlink.interface'
import { SharedLinksService } from 'features/sharedlinks/services/sharedlinks.service'
import type { ICompany } from 'interfaces'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import CommentItem from '../components/CommentItem'

interface Props {
	company: ICompany
}

export default function CompanyAnalyticsComments({ company }: Props) {
	const [comments, setComments] = useState<ISharedLinkCompanyComment[]>([])
	const [isLoading, setIsLoading] = useState(true)
	const groupedByCreatedAt = _.groupBy(comments, 'createdAt')

	useEffect(() => {
		SharedLinksService.listenToSharedLinkCompanyComments(
			company.id,
			_comments => {
				setComments(_comments)
			},
			_loading => {
				setIsLoading(_loading)
			}
		)
	}, [company])

	if (isLoading) {
		return (
			<div className='flex h-fit w-full flex-col gap-3 p-5'>
				<Skeleton className='h-6 w-96 border' />
				<Skeleton className='h-6 w-full border' />
				<Skeleton className='h-6 w-full border' />
				<div className='py-2' />
				<Skeleton className='h-6 w-96 border' />
				<Skeleton className='h-6 w-full border' />
				<Skeleton className='h-6 w-full border' />
				<div className='py-2' />
				<Skeleton className='h-6 w-96 border' />
				<Skeleton className='h-6 w-full border' />
				<Skeleton className='h-6 w-full border' />
			</div>
		)
	}

	if (comments.length === 0) {
		return (
			<div className='flex h-fit w-full items-center justify-center pb-5 pt-20'>
				<div className='flex flex-col items-center justify-center gap-2'>
					<div className='font-semibold'>No comments found</div>
				</div>
			</div>
		)
	}

	return (
		<div className='flex w-full flex-col overflow-auto pb-10'>
			<div className='sticky top-0 z-[2] my-2 flex items-center justify-between bg-white px-4 py-2'>
				<div className='font-bold'>Comments</div>
			</div>
			<section
				id='company-notes'
				data-cy='notes-page'
				className='relative z-0 flex w-full flex-col gap-4'
			>
				<section className='flex w-full flex-1 flex-col gap-5'>
					{Object.keys(groupedByCreatedAt)
						.sort((a, b) => (a > b ? -1 : 1))
						.filter(key => {
							const yearNotes = groupedByCreatedAt[key]
							return yearNotes.some(note => !note.isPinned)
						})
						.map(key => {
							const monthNotes = groupedByCreatedAt[key]
							return (
								<div key={key} className='relative flex w-full'>
									<section className='w-full overflow-auto px-2'>
										<div className='mb-3 flex items-center gap-3 text-xs font-semibold text-gray-500'>
											<div className='h-[0.05rem] flex-1 bg-gray-200' />
										</div>
										{monthNotes
											.filter(
												(_commentItem: ISharedLinkCompanyComment) =>
													!_commentItem.isPinned
											)
											.map((_commentItem: ISharedLinkCompanyComment) => (
												<CommentItem
													key={_commentItem.id}
													comment={_commentItem}
												/>
											))}
									</section>
								</div>
							)
						})}
				</section>
			</section>
		</div>
	)
}
