/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import { ColumnsService } from 'features/columns/sevice/column.service'
import { DataTable } from 'features/dataTable/DataTable'
import type { ICompany } from 'interfaces'
import { listenToColumns } from 'features/columns/columns.api'
import { selectCompanyColumns } from 'features/columns/column.slice'
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
import { useAppSelector } from 'app/hooks'
import useDataTableHook from 'features/dataTable/hooks/useDataTableHook'
import { CompanyService } from '../service/company.service'

interface IAllCompaniesTableProps {
	className?: string
	companies: ICompany[]
	loading?: boolean
}

export default function CompanyList({
	className,
	companies,
	loading
}: IAllCompaniesTableProps): JSX.Element {
	const { authUser } = useContext(AuthContext)
	const columnsData = useAppSelector(selectCompanyColumns)
	const { columns, columnData } = useDataTableHook({
		columnData: columnsData.map((column: any) => ({
			...column,
			canBeEdited: true,
		})),
		detailsRoute: 'company',
		onItemUpdate: (item, key, value) => {
			CompanyService.updateCompany({
				id: item.id,
				key,
				value,
				company: item
			})
		}
	})

	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribe = listenToColumns(authUser.fund.id)
		return () => unsubscribe()
	}, [authUser?.fund.id])

	return (
		<div className={`${className} flex flex-col`}>
			<div className='relative pb-2'>
				<DataTable
					showColumnToggle
					entity='company'
					columns={columns}
					columnData={columnData}
					data={companies}
					allowFilter
					filterPlaceholder='Search companies'
					isCompanyList
					loading={loading}
					onColumnUpdate={(updatedColumns: any) => {
						ColumnsService.updateColumns(updatedColumns)
					}}
					onColumnVisibilityChange={(columnId: string, isVisible: boolean) => {
						ColumnsService.editColumn(columnId, { isVisible })
					}}
				/>
			</div>
		</div>
	)
}
