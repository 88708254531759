import { MailX } from "lucide-react";

export default function NoEmailsFound() {

  return (
    <div className='mt-20'>
      <div className='flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center gap-2'>
          <div className='text-3xl'>
            <MailX />
          </div>
          <div className='flex items-center gap-3 text-center text-xl text-gray-600'>
            No emails found
          </div>
        </div>
      </div>
    </div>
  )
}