import {
	collection,
	doc,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	where
} from 'firebase/firestore'
import {
	setActivities,
	setActivitiesError,
	setActivitiesIsLoading
} from '../redux/activities.slice'

import { Errorhandler } from 'lib/sentry'
import type { IActivity } from '../interfaces/actvitiy.interface'
import Logger from 'lib/logger'
import { firestore } from 'lib/firebase'
/* eslint-disable @typescript-eslint/no-shadow */
import { store } from 'app/store'

const ref = collection(firestore, 'activities')

export const addActivity = async (activity: IActivity) => {
	const docRef = doc(ref, activity.id)
	await setDoc(docRef, activity, { merge: true })
}

export const listenToActivity = () => { }

export const listenToCompanyActivity = (companyId: string) => {
	store.dispatch(setActivitiesIsLoading(true))

	const q = query(
		ref,
		where('company.id', '==', companyId),
		orderBy('createdAt', 'desc')
	)
	const unsubscribe = onSnapshot(
		q,
		snapshot => {
			const data = snapshot.docs.map(doc => doc.data() as IActivity)
			store.dispatch(setActivities(data))
		},
		error => {
			store.dispatch(setActivitiesError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)
	return unsubscribe
}

export const listenToLimitedPartnerActivity = (limitedPartnerId: string) => {
	store.dispatch(setActivitiesIsLoading(true))

	const q = query(
		ref,
		where('limitedPartner.id', '==', limitedPartnerId),
		orderBy('createdAt', 'desc')
	)
	const unsubscribe = onSnapshot(
		q,
		snapshot => {
			const data = snapshot.docs.map(doc => doc.data() as IActivity)
			store.dispatch(setActivities(data))
		},
		error => {
			store.dispatch(setActivitiesError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)
	return unsubscribe
}


export const listenToContactActivity = (contactId: string) => {
	store.dispatch(setActivitiesIsLoading(true))

	const q = query(
		ref,
		where('contact.id', '==', contactId),
		// orderBy('createdAt', 'desc')
	)
	const unsubscribe = onSnapshot(
		q,
		snapshot => {
			const data = snapshot.docs.map(doc => doc.data() as IActivity).sort((a, b) => { return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime() })
			store.dispatch(setActivities(data))
		},
		error => {
			store.dispatch(setActivitiesError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)
	return unsubscribe
}

// Listen to competitor activity
export const listenToCompetitorActivity = (competitorId: string) => {
	store.dispatch(setActivitiesIsLoading(true))

	const q = query(
		ref,
		where('competitor.id', '==', competitorId),
		orderBy('createdAt', 'desc')
	)
	const unsubscribe = onSnapshot(
		q,
		snapshot => {
			const data = snapshot.docs.map(doc => doc.data() as IActivity)
			store.dispatch(setActivities(data))
		},
		error => {
			store.dispatch(setActivitiesError(error.message))

			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)
	return unsubscribe
}