/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { SelectLabel } from '@radix-ui/react-select'
import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import { Input } from 'components/ui/input'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { UserRoundPlus } from 'lucide-react'
import { useEffect } from 'react'
import { ImSpinner10 } from 'react-icons/im'
import { CoinType, Network, getAllCoinType, getAllNetworks } from '../data/crypto.data'
import { useCreateCryptoAccount } from '../hooks/useCreateCryptoAccount'
import type { ICryptoAccount } from '../interface/cryptoAccount.interface'

interface IProps {
	initialValue?: Partial<ICryptoAccount>
	editMode?: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	isOpen: boolean
	onSuccess: () => void
}

export default function AddEditCryptoAccountDialog({
	initialValue,
	editMode = false,
	setIsOpen,
	isOpen,
	onSuccess
}: IProps): JSX.Element {
	const { formik } = useCreateCryptoAccount({
		initialValue: initialValue as ICryptoAccount,
		editMode,
		onSuccess
	})

	useEffect(() => {
		if (!editMode) return

		formik.setValues({
			accountName: initialValue?.accountName || '',
			description: initialValue?.description || '',
			walletAddress: initialValue?.walletAddress || '',
			network: Network['ERC-20'],
			stableCoin: CoinType.USDC
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editMode])

	function onCloseModal(): void {
		setIsOpen(false)
	}

	function onOpenModal(): void {
		formik.resetForm()
		formik.setErrors({})
		setIsOpen(true)
	}

	return (
		<DialogModal
			size='3xl'
			open={isOpen}
			setOpen={setIsOpen}
			title={editMode ? 'Edit Crypto Account' : 'Create Crypto Account'}
			description=''
			trigger={
				<Button
					id='open-invite-partner-button'
					variant='blue'
					onClick={onOpenModal}
				>
					<UserRoundPlus size={15} />
					Add Crypto Account
				</Button>
			}
		>
			<div>
				<div id='manualFields'>
					<div className='mb-4 w-full'>
						<Input
							id='accountName'
							label='Account Name'
							type='text'
							isRequired
							value={formik.values.accountName}
							error={formik.errors.accountName}
							onInput={e => {
								formik.setFieldValue(
									'accountName',
									(e.target as HTMLInputElement).value
								)
							}}
						/>
					</div>
					<div className='mb-4 w-full'>
						<Input
							id='description'
							type='text'
							label='Description'
							isMultiline
							className='h-[100px]'
							value={formik.values.description}
							error={formik.errors.description}
							onInput={e => {
								formik.setFieldValue(
									'description',
									(e.target as HTMLInputElement).value
								)
							}}
						/>
					</div>
					<div className='mb-4 w-full'>
						<Input
							id='walletAddress'
							type='text'
							label='Wallet Address'
							isRequired
							value={formik.values.walletAddress}
							error={formik.errors.walletAddress}
							onInput={e => {
								formik.setFieldValue(
									'walletAddress',
									(e.target as HTMLInputElement).value
								)
							}}
						/>
					</div>
					<div className='mb-4 w-full'>
						<SelectGroup>
						<SelectLabel className='mb-2 block text-xs font-semibold'>Network</SelectLabel>
						<Select
								value={formik.values.network}
								defaultValue={formik.values.network}
								onValueChange={e => {
									formik.setFieldValue('network', e)
								}}
							>
								<SelectTrigger className='flex-1'>
									<SelectValue placeholder='Select a Network' />
								</SelectTrigger>
								<SelectContent>
									<SelectGroup>
											{
												getAllNetworks().map(type => (
													<SelectItem
													key={type.value}
													value={type.value}
													defaultChecked
													onClick={() => {
														formik.setFieldValue('network', type.value)
													}}
												>
													{ type.label }
												</SelectItem>
												))
											}
										</SelectGroup>
								</SelectContent>
							</Select>
						</SelectGroup>
					</div>
					<div className='mb-4 w-full'>
					<SelectGroup>
						<SelectLabel className='mb-2 block text-xs font-semibold'>Stable Coin</SelectLabel>
						<Select
								value={formik.values.stableCoin}
								defaultValue={formik.values.stableCoin}
								onValueChange={e => {
									formik.setFieldValue('stableCoin', e)
								}}
							>

								<SelectTrigger className='flex-1'>
									<SelectValue placeholder='Select a Stable Coin' />
								</SelectTrigger>
								<SelectContent>
									<SelectGroup>
										{
											getAllCoinType().map(type => (
												<SelectItem
												key={type.value}
												value={type.value}
												defaultChecked
												onClick={() => {
													formik.setFieldValue('stableCoin', type.value)
												}}
											>
												{ type.label }
											</SelectItem>
											))
										}
									</SelectGroup>
								</SelectContent>
							</Select>
						</SelectGroup>
					</div>
				</div>
				<div className='mt-3 flex w-full justify-end'>
					{formik.isSubmitting ? (
						<ImSpinner10 className='animate-spin' />
					) : (
						<div className='flex items-center '>
							<Button variant='ghost' className='px-10' onClick={onCloseModal}>
								Cancel
							</Button>
							<Button
								id='invite-partner-button'
								variant='blue'
								disabled={!formik.isValid}
								className='px-10'
								onClick={() => {
									formik.handleSubmit()
								}}
							>
								{editMode ? 'Update' : 'Create'}
							</Button>
						</div>
					)}
				</div>
			</div>
		</DialogModal>
	)
}
