import type * as React from 'react'

import { AutosizeTextarea } from '../ui/autosizetextarea'
import clsx from 'clsx'
import { cn } from 'lib/utils'

export type InputProps = React.InputHTMLAttributes<
	HTMLInputElement | HTMLTextAreaElement
>

function CustomInput({
	type = 'text',
	className,
	error,
	label,
	isMultiline = false,
	isRequired = false,
	side = 'top',
	variant = 'default',
	subText,
	...props
}: InputProps & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error?: any
	label?: string
	isRequired?: boolean
	side?: 'left' | 'top'
	isMultiline?: boolean
	subText?: string
	variant?: 'default' | 'outlined' | 'underlined'
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ref?: any
}) {
	return (
		<div
			className={clsx(
				'flex',
				side === 'top' && 'flex-col',
				side === 'left' && 'flex-row items-center gap-2'
			)}
		>
			{label && (
				<label htmlFor={type} className='mb-2 block font-semibold text-primary'>
					{label}
					{isRequired && <span className='text-red-500'>*</span>}
				</label>
			)}
			{isMultiline ? (
				<AutosizeTextarea
					{...props}
					data-testid={props.id}
					className={cn(
						variant === 'default' &&
							'border-slate-200 placeholder:text-slate-500 focus-visible:ring-slate-950 dark:border-slate-800 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 flex h-9 w-full rounded-md border bg-transparent px-3 py-1 shadow-sm transition-colors file:border-0 file:bg-transparent file:file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50',
						className,
						error && 'border-red-500',
						variant === 'outlined' && 'border-2',
						variant === 'underlined' &&
							'border-slate-200 bg-red-500 placeholder:text-slate-500 focus-visible:ring-slate-950 dark:border-slate-800 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 flex h-9 w-full rounded-md border-b bg-transparent px-3 py-1 shadow-sm transition-colors file:border-0 file:bg-transparent file:file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50'
					)}
				/>
			) : (
				<input
					type={type}
					data-testid={props.id}
					className={cn(
						variant === 'default' &&
							'border-slate-200 placeholder:text-slate-500 focus-visible:ring-slate-950 dark:border-slate-800 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 flex h-9 w-full rounded-md border bg-transparent px-3 py-2 shadow-sm transition-colors file:border-0 file:bg-transparent file:file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50',
						className,
						error && 'border-red-500',
						variant === 'outlined' && 'border-2',
						variant === 'underlined' &&
							'border-slate-200 bg-red-500 placeholder:text-slate-500 focus-visible:ring-slate-950 dark:border-slate-800 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 flex h-9 w-full rounded-md border-b bg-transparent px-3 py-2 shadow-sm transition-colors file:border-0 file:bg-transparent file:file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50'
					)}
					{...props}
				/>
			)}

			<div>
				{error && <div className='text-red-500 mt-1 text-xs'>{error}</div>}
			</div>
			<div>
				{subText && (
					<div className='text-slate-500 ml-3 mt-1 text-xs'>{subText}</div>
				)}
			</div>
		</div>
	)
}

CustomInput.displayName = 'Input'

export { CustomInput }
