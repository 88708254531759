 /* eslint-disable consistent-return */

import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import { DataTable } from 'features/dataTable/DataTable'
import type { ILimitedPartner } from '../interfaces/limitedPartner.interface'
import type { ITableColumn } from 'features/dataTable/interfaces/datatable.interface'
import Logger from 'lib/logger'
import { listenToColumns } from 'features/columns/columns.api'
import { selectLimitedPartnerColumns } from 'features/columns/column.slice'
import { useAppSelector } from 'app/hooks'
import useDataTableHook from 'features/dataTable/hooks/useDataTableHook'
import { useNavigate } from 'react-router-dom'

interface ILimitedPartnerListProps {
	className?: string
	data: ILimitedPartner[]
	loading: boolean
}

export default function LimitedPartnerList({
	className,
	data,
	loading
}: ILimitedPartnerListProps): JSX.Element {
	const { authUser } = useContext(AuthContext)
	const columnsData = useAppSelector(selectLimitedPartnerColumns)

	const { columns, columnData } = useDataTableHook({
		columnData: columnsData,
		detailsRoute: 'limited-partner'
	})

	const navigate = useNavigate()

	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribe = listenToColumns(authUser.fund.id)
		return () => unsubscribe()
	}, [authUser?.fund.id])

	return (
		<div className={`${className} flex flex-col overflow-hidden`}>
			<DataTable
				entity='lp'
				className='px-5'
				columnData={columnData}
				columns={columns}
				data={data}
				loading={loading}
				filterPlaceholder='Search....'
				onClickNewCapitalCall={() => {
					navigate('/capital-call/new')
				} }
				onColumnUpdate={(updatedColumns: ITableColumn) => {
					Logger.info('Updated columns', updatedColumns)
				} }
				onColumnVisibilityChange={(columnId: string, isVisible: boolean) => {
					Logger.info('Column visibility change', columnId, isVisible)
				} } 
				isGrouped={false}			
			/>
		</div>
	)
}
