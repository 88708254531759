import type {
	IChat,
	IConversation,
	IMessage
} from '../interfaces/chat.interface'

/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface ConversationStateSlice {
	isLoading: boolean
	newPrompt: string
	error?: string | null
	conversation: IConversation | null
	messages: IChat[]
}

const initialState: ConversationStateSlice = {
	isLoading: true,
	newPrompt: '',
	error: null,
	conversation: null,
	messages: []
}

export const conversationSlice = createAppSlice({
	name: 'conversation',
	initialState,
	reducers: creator => ({
		setConversation: creator.reducer(
			(state, action: PayloadAction<IConversation>) => {
				state.conversation = action.payload
			}
		),
		addMessage: creator.reducer((state, action: PayloadAction<IMessage>) => {
			state.conversation?.messages.push(action.payload)
		}),
		setNewPrompt: creator.reducer((state, action: PayloadAction<string>) => {
			state.newPrompt = action.payload
		}),
		setMessages: creator.reducer((state, action: PayloadAction<IChat[]>) => {
			state.messages = action.payload
		})
	}),
	selectors: {
		selectConversation: state => state.conversation,
		selectNewPrompt: state => state.newPrompt,
		selectMessages: state => state.messages
	}
})

export const { setConversation, addMessage, setNewPrompt, setMessages } =
	conversationSlice.actions

export const { selectConversation, selectNewPrompt, selectMessages } =
	conversationSlice.selectors
