/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import Button from "components/Buttons/Button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from 'components/ui/dropdown-menu';
import { GripVertical, Settings2 } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import type { ITableColumn } from "../interfaces/datatable.interface";

interface Props {
  columns: ITableColumn[]
  onColumnUpdate: (updatedColumns: ITableColumn[]) => void
  onColumnVisibilityChange: (columnId: string, isVisible: boolean) => void
}

export default function ManageDataTableColumns({ columns, onColumnUpdate, onColumnVisibilityChange }: Props) {

  const [list, setList] = useState(columns || [])

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (id) => {
    dragItem.current = id;
  }

  const dragEnter = (id) => {
    dragOverItem.current = id;
  }


  const drop = () => {
    const copyListItems = [...columns]
    const sourceIndex = copyListItems.findIndex((item) => item.id === dragItem.current)
    const destIndex = copyListItems.findIndex((item) => item.id === dragOverItem.current)

    if (sourceIndex === destIndex) return

    const [removed] = copyListItems.splice(sourceIndex, 1)
    copyListItems.splice(destIndex, 0, removed)


    // Update index
    const updatedList = copyListItems.map((item, index) => ({ ...item, index }))
    setList(updatedList)

    dragItem.current = null
    dragOverItem.current = null
    onColumnUpdate(updatedList)
  }

  useEffect(() => {
    setList(columns)
  }, [columns])


  return <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button variant='ghost' className='ml-5 truncate' >
        <Settings2 className='h-4 w-4' color='blue' />
        Manage columns
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align='end' className='w-[250px]'>
      <DropdownMenuLabel>Manage columns</DropdownMenuLabel>
      <DropdownMenuSeparator />
      {list
        .sort((a, b) => a.index - b.index)
        .map((column) => (
          <div
            draggable
            key={column.id}
            id={column.id}
            onDragStart={() => dragStart(column.id)}
            onDragEnter={() => dragEnter(column.id)}
            onDragOver={(e) => e.preventDefault()}
            onDragEnd={() => drop()}
          >
            <div
              className='flex items-center'
            >
              <GripVertical
                color='black'
                className='text-white cursor-grab'
                size={15}
              />
              <div className='flex-1 '>
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className='w-full capitalize'
                  checked={column.isVisible}
                  onCheckedChange={(value) => {
                    onColumnVisibilityChange(column.id, value)
                  }}
                >
                  {column.name}
                </DropdownMenuCheckboxItem>
              </div>
            </div>
          </div>
        ))}
    </DropdownMenuContent>
  </DropdownMenu>

}