/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from 'react'

import Button from 'components/Buttons/Button'
import type { CapitalCallRequest } from 'features/new-capital-call/interfaces/CapitalCallRequest'
import { CapitalCallRequestService } from 'features/new-capital-call/service/capital_call_request.service'
import { CapitalCallService } from 'features/capital_call/service/capital_call.service'
import { CaretSortIcon } from '@radix-ui/react-icons'
import type { ColumnDef } from '@tanstack/react-table'
import { DataTable } from 'features/dataTable/DataTable'
import {
	CapitalCallStatus,
	type ICapitalCall
} from 'features/capital_call/interfaces/capitalCall.interface'
import Logger from 'lib/logger'
import type { WiseTransaction } from 'interfaces/wise.interface'
import axiosInstance from 'lib/axios'
import { renderDate } from 'utils/date.util'
import { toast } from 'sonner'

interface Props {
	ccRequest?: CapitalCallRequest
	cc?: ICapitalCall
	onClose: () => void
}
export default function WiseTransactions({ ccRequest, cc, onClose }: Props) {
	const [wiseTransactions, setWiseTransactions] = useState<WiseTransaction[]>(
		[]
	)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		async function getWiseTransactions() {
			setIsLoading(true)

			const { data } = await axiosInstance.get('/wise/transactions')
			if (!data.success) {
				toast.error('Failed to fetch wise transactions')
				setIsLoading(false)
				return
			}
			setWiseTransactions(data.data)

			setIsLoading(false)
		}

		getWiseTransactions()
	}, [])

	const handleAssign = async (transaction: WiseTransaction) => {
		// Update the capital call request with the selected wise transaction
		if (ccRequest) {
			await CapitalCallRequestService.updateCapitalCallRequest(ccRequest.id, {
				wiseTransactionId: transaction.wiseId,
				wiseTransaction: transaction,
				wiseStatus: transaction.status
			})
		}
		if (cc) {
			await CapitalCallService.updateCapitalCall(cc.id, {
				wiseTransactionId: transaction.wiseId,
				wiseTransaction: transaction,
				wiseStatus: transaction.status,
				status: CapitalCallStatus.PAID
			})
			if (cc.requestId) {
				await CapitalCallRequestService.updateCapitalCallRequest(cc.requestId, {
					wiseTransactionId: transaction.wiseId,
					wiseTransaction: transaction,
					wiseStatus: transaction.status
				})
			}
		}
		onClose()
	}

	const columns: ColumnDef<WiseTransaction>[] = [
		{
			accessorKey: 'title',
			sortingFn: 'text',
			header: ({ column }) => (
				<Button
					className='px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Title
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				// <div className='capitalize'>{row.original.title || '-'}</div>
				// eslint-disable-next-line react/no-danger
				<div
					className='no-strong whitespace-nowrap capitalize'
					dangerouslySetInnerHTML={{ __html: row.original.title }}
				/>
			)
		},
		{
			accessorKey: 'createdAt',
			sortingFn: 'datetime',
			header: ({ column }) => (
				<Button
					className='px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Date
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='capitalize'>{renderDate(row.original.createdAt)}</div>
			)
		},
		{
			accessorKey: 'amount',
			sortingFn: 'text',
			header: ({ column }) => (
				<Button
					className='px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Amount
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='capitalize'>
					{row.original.amount
						.replaceAll(/<\/?[^>]+>/g, '')
						.replaceAll('+ ', '')}
				</div>
			)
		},
		{
			accessorKey: 'type',
			sortingFn: 'text',
			header: ({ column }) => (
				<Button
					className='px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Type
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => <div className='capitalize'>{row.original.type}</div>
		},
		{
			accessorKey: 'status',
			sortingFn: 'text',
			header: ({ column }) => (
				<Button
					className='px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Status
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => <div className='capitalize'>{row.original.status}</div>
		},
		{
			accessorKey: 'actions',
			header: ({ column }) => (
				<Button
					className='px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					More
				</Button>
			),
			cell: ({ row }) => (
				<Button onClick={() => handleAssign(row.original)}>Assign</Button>
			)
		}
	]

	return (
		<div className='mt-4'>
			<DataTable
				columns={columns}
				data={wiseTransactions}
				loading={isLoading}
				showColumnToggle
				allowFilter
				pageSize={15}
				filterPlaceholder='Search transactions'
				onColumnUpdate={(updatedColumns: any) => {
					Logger.info('Columns updated', updatedColumns)
				}}
				entity={'wise-transaction' as any}
				onColumnVisibilityChange={(columnId: string, isVisible: boolean) => {
					Logger.info(`Column ${columnId} visibility changed to ${isVisible}`)
				}}
			/>
		</div>
	)
}
