/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'

import Button from 'components/Buttons/Button'
import { FileService } from '../services/file.service'
import type { ICompany } from 'interfaces'
import type { IFile } from '../file.interface'
import { Link2 } from 'lucide-react'
import { nanoid } from 'nanoid'
import { selectAuthState } from 'features/authentication/authentication.slice'
import { useAppSelector } from 'app/hooks'

interface Props {
	company: ICompany
}

function DocSendUpload({ company }: Props) {
	const { authUserFund, authUser } = useAppSelector(selectAuthState)

	const [name, setName] = useState('')
	const [link, setLink] = useState('')
	const [isOpen, setIsOpen] = useState(false)

	async function handleUpload() {
		if(!link) return
		if(!authUserFund) return
		if(!authUser) return
		
		const fileData: IFile = {
			id: nanoid(),
			fund: authUserFund,
			creator: {
				id: authUser.id,
				name: authUser.name,
				photoUrl: authUser.photoUrl || ''
			},
			company: {
				id: company.id,
				name: company.name,
				website: company.website
			},
			name,
			mimeType: '',
			url: link,
			fileType: 'company_file',
			source: 'docsend',
			createdAt: new Date().toISOString(),
			lastUpdatedAt: new Date().toISOString()
		}

		await FileService.addFile(fileData)
	}

	return (
		<div>
			<div className='mb-2 flex w-fit gap-2 bg-white'>
				<Button type='button' onClick={() => setIsOpen(true)}>
					<div className='flex items-center gap-2 text-sm'>
						<Link2 className='text-md text-white' />
						Docsend Upload
					</div>
				</Button>
			</div>

			{isOpen && (
				<Transition appear show={isOpen} as={Fragment}>
					<Dialog
						as='div'
						className='relative z-10'
						onClose={() => setIsOpen(false)}
					>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0'
							enterTo='opacity-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<div className='fixed inset-0 bg-black/25' />
						</Transition.Child>

						<div className='fixed inset-0 overflow-y-auto'>
							<div className='flex min-h-full items-center justify-center p-4 text-center'>
								<Transition.Child
									as={Fragment}
									enter='ease-out duration-300'
									enterFrom='opacity-0 scale-95'
									enterTo='opacity-100 scale-100'
									leave='ease-in duration-200'
									leaveFrom='opacity-100 scale-100'
									leaveTo='opacity-0 scale-95'
								>
									<Dialog.Panel className='w-full max-w-3xl transform overflow-hidden rounded bg-white p-6 text-left shadow-xl transition-all'>
										<div>Add your docsend link</div>

										{/* Input field - Name */}
										<div className='mt-4'>
											<input
												type='text'
												placeholder='Name'
												className='w-full border border-gray-200 rounded p-2'
												value={name}
												onChange={e => setName(e.target.value)}
											/>
										</div>

										{/* Input field - Docsend */}
										<div className='mt-4'>
											<input
												type='text'
												placeholder='Docsend link'
												className='w-full border border-gray-200 rounded p-2'
												value={link}
												onChange={e => setLink(e.target.value)}
											/>
										</div>

										<div className='mt-4 flex gap-2'>
											<Button
												disabled={false}
												type='button'
												className='px-4, py-2'
												onClick={() => {
													handleUpload();
													setIsOpen(false)
												}}
											>
												Add file
											</Button>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition>
			)}
		</div>
	)
}

export default DocSendUpload
