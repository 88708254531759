import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { CheckIcon } from '@radix-ui/react-icons'
import type * as React from 'react'

import { cn } from 'lib/utils'

export type CheckboxProps = React.ComponentProps<typeof CheckboxPrimitive.Root>

function Checkbox({
	className,
	label,
	...props
}: CheckboxProps & { label?: string }) {
	return (
		<div className='flex items-center gap-2'>
			<CheckboxPrimitive.Root
				className={cn(
					'peer h-4 w-4 shrink-0 rounded-sm border  border-slate-900 shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-slate-900 data-[state=checked]:text-slate-50  dark:border-slate-800 dark:focus-visible:ring-slate-300 dark:data-[state=checked]:bg-slate-50 dark:data-[state=checked]:text-slate-900',
					className
				)}
				{...props}
			>
				<CheckboxPrimitive.Indicator
					className={cn('flex items-center justify-center text-current')}
				>
					<CheckIcon className='h-4 w-4' />
				</CheckboxPrimitive.Indicator>
			</CheckboxPrimitive.Root>
			{label && <div>{label}</div>}
		</div>
	)
}
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
