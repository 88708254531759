/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { IBankAccount } from 'features/bank_accounts/interface/BankAccount.interface'
import type { ICryptoAccount } from 'features/crypto_accounts/interface/cryptoAccount.interface'
import type { ILimitedPartner } from 'interfaces'
import { getAmount } from 'utils/currency'
import { isBankAccount } from 'features/payment_account_tags/interface/bankAccountTags.interface'

export function computeCapitalCall(
	capitalCalls: number[],
	committedCapitalCall: number,
	currentIndex: number
) {
	let committedCapitalCallLeft = committedCapitalCall

	for (const [i, capitalCall] of capitalCalls.entries()) {
		if (currentIndex !== i) {
			committedCapitalCallLeft -= capitalCall
		}
	}

	return committedCapitalCallLeft
}

export function limitedPartnersToDropdown(
	list: ILimitedPartner[],
	toExclude: ILimitedPartner[] = []
): {
	label: string
	value: ILimitedPartner
}[] {
	const data: {
		label: string
		value: ILimitedPartner
	}[] = []

	for (const element of list) {
		if (!toExclude.some(x => x.id === element.id))
			data.push({ value: element, label: element.name })
	}

	return data
}

export function limitedPartnerEmailAddressDropdown(
	lp: ILimitedPartner,
	toExclude?: string[]
) {
	const data: {
		label: string
		value: string
	}[] = []

	if (!toExclude?.includes(lp.email || '') && lp.email) {
		data.push({ label: lp.email, value: lp.email })
	}

	if (lp.teamMemberEmails)
		for (const email of lp.teamMemberEmails) {
			if (!toExclude?.includes(email) && email !== '')
				data.push({ label: email, value: email })
		}

	return data
}

export function capitalCallPaymentAccountDropdown(
	acccounts: IBankAccount[] | ICryptoAccount[],
	toExclude?: IBankAccount[] | ICryptoAccount[]
) {
	const data: {
		label: string
		value: IBankAccount | ICryptoAccount
	}[] = []

	for (const account of acccounts) {
		if (!toExclude?.some(x => x.id === account.id))
			if (isBankAccount(account))
				data.push({ label: account.title, value: account })
			else data.push({ label: account.walletAddress, value: account })
	}

	return data
}

export function emailAddressesMapToArray(map: Record<string, string[]>) {
	return Object.values(map).flat()
}

export function mapHasOneArrayValue<T>(map: Record<string, T[]>) {
	return Object.values(map).some(
		value => Array.isArray(value) && value.length > 0
	)
}

export function capitalToBeCalledMapper(
	data: Record<
		string,
		{ inputType: string; percent: number; value: number; currency: string }
	>
): Record<string, { currency: string; amount: number }> {
	const res: Record<string, { currency: string; amount: number }> = {}

	for (const [id, element] of Object.entries(data)) {
		res[id] = { amount: element.value, currency: element.currency }
	}

	return res
}

export function capitalToBeCalledInitialValueMapper(
	data: Record<string, { currency: string; amount: number }>
): Record<string, { inputType: string; percent: number; value: number }> {
	const res: Record<
		string,
		{ inputType: string; percent: number; value: number; currency: string }
	> = {}

	for (const [id, amount] of Object.entries(data)) {
		res[id] = {
			inputType: 'dollar',
			value: amount.amount,
			percent: 0,
			currency: amount.currency
		}
	}

	return res
}

export function onValidateCapitalCalledFormForm(
	recipients: ILimitedPartner[],
	capitalToBeCalled: Record<string, { currency: string; amount: number }>
) {
	if (Object.values(recipients).length <= 0) return false

	for (const value of Object.values(recipients)) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const element = capitalToBeCalled[value.id as any]

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (
			element?.value > (value?.totalCommitted?.amount || 0) ||
			!capitalToBeCalled[value.id as any] ||
			element?.value <= 0
		)
			return false
	}

	return true
}

export function getRemainingCommitted(lp: ILimitedPartner) {
	return (lp.totalCommitted.amount || 0) - (lp.totalCalled?.amount || 0) || 0
}

export function convertValueToPercent(
	value: number,
	lp: ILimitedPartner,
	currency = 'USD'
) {
	const totalCommitted = getAmount(lp?.amounts || [], currency)

	return (value / totalCommitted) * 100
}

export function convertPercentToValue(
	value: number,
	lp?: ILimitedPartner,
	currency = 'USD'
) {
	const totalCommitted = getAmount(lp?.amounts || [], currency)
	return (value * totalCommitted) / 100
}
