/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { CaretSortIcon } from '@radix-ui/react-icons'
import type { ColumnDef } from '@tanstack/react-table'
import Button from 'components/Buttons/Button'
import DeleteModal from 'components/DeleteModal'
import { DataTable } from 'features/dataTable/DataTable'
import type { ITableColumn } from 'features/dataTable/interfaces/datatable.interface'
import Logger from 'lib/logger'
import { Pencil, Trash2 } from 'lucide-react'
import { currencyFormatter } from 'utils/currencyformatter.util'
import { renderDate } from 'utils/date.util'
import type { IInvestment } from '../interfaces/investment.interface'
import { InvestmentService } from '../service/investment.service'
import NewInvestment from './NewInvestment'
import FileViewer from 'features/fileviewers/views/FileViewer'

interface Props {
  className?: string
  investments: IInvestment[]
  isLoading: boolean
  isReadOnly?: boolean
}

export default function InvestmentList({
  className,
  investments,
  isLoading,
  isReadOnly
}: Props): JSX.Element {

  const columns: ColumnDef<IInvestment>[] = [
    {
      accessorKey: 'date',
      header: ({ column }) => (
        <Button
          className='truncate px-0'
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Date
          <CaretSortIcon className='ml-2 h-4 w-4' />
        </Button>
      ),
      cell: ({ row }) => (
        <div>
          {renderDate(row.original.date)}
        </div>
      )
    },
    {
      accessorKey: 'stage',
      header: ({ column }) => (
        <Button
          className='truncate px-0'
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Stage
          <CaretSortIcon className='ml-2 h-4 w-4' />
        </Button>
      ),
      cell: ({ row }) => (
        <div className='whitespace-nowrap'>
          {row.original.stage}
        </div>
      )
    },
    {
      accessorKey: 'amount',
      header: ({ column }) => (
        <Button
          className='truncate px-0'
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Invested
          <CaretSortIcon className='ml-2 h-4 w-4' />
        </Button>
      ),
      cell: ({ row }) => (
        <div className='whitespace-nowrap font-semibold'>
          {currencyFormatter(row.original.currency).format(row.original.amount)}
        </div>
      )
    },
    {
      accessorKey: 'totalAmountRaised',
      header: ({ column }) => (
        <Button
          className='px-0'
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Raised
          <CaretSortIcon className='ml-2 h-4 w-4' />
        </Button>
      ),
      cell: ({ row }) => (
        <div>
          {currencyFormatter(row.original.currency).format(
            row.original.totalAmountRaised ?? 0
          )}
        </div>
      )
    },
    {
      accessorKey: 'ownership',
      header: ({ column }) => (
        <Button
          className='px-0'
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Approx. Ownership
          <CaretSortIcon className='ml-2 h-4 w-4' />
        </Button>
      ),
      cell: ({ row }) => {
        const preLevelApproxOwnership = (
          (row.original.amount /
            (row.original.valuation + (row.original.totalAmountRaised ?? 0))) *
          100
        ).toFixed(2)
        const postLevelApproxOwnership = (
          (row.original.amount / row.original.valuation) *
          100
        ).toFixed(2)

        return (
          <div className='rounded px-5 text-center'>
            {row.original.level === 'pre'
              ? preLevelApproxOwnership
              : postLevelApproxOwnership}
            %
          </div>
        )
      }
    },
    {
      accessorKey: 'valuation',
      header: ({ column }) => (
        <Button
          variant='ghost'
          className='truncate px-0'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Valuation
          <CaretSortIcon className='ml-2 h-4 w-4' />
        </Button>
      ),
      cell: ({ row }) => (
        <div className='capitalize'>
          {currencyFormatter(row.original.currency).format(
            row.original.valuation ?? 0
          )}
        </div>
      )
    },
    {
      accessorKey: 'files',
      header: ({ column }) => (
        <Button
          className='truncate px-0'
          variant='ghost'
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Files
          <CaretSortIcon className='ml-2 h-4 w-4' />
        </Button>
      ),
      cell: ({ row }) => (
        <div className='flex flex-col gap-1'>
          {row.original.files && row.original.files.length > 0 ? (
            row.original.files.map(file => (
              <div key={file.id} className='flex items-center'>
                <FileViewer
                  file={file}
                  content={<div className='flex-1 cursor-pointer overflow-scroll whitespace-nowrap p-2 text-sm rounded-md py-1 bg-blue-100 text-blue-700'>{file.name}</div>}
                />
              </div>
            ))
          ) : (
            <div className='text-gray-500'>No files</div>
          )}
        </div>
      )
    },
    {
      accessorKey: 'actions',
      header: ({ column }) => (
        <Button variant='ghost' className='truncate px-0'>
          Actions
        </Button>
      ),
      cell: ({ row }) => (
        <div>
          {isReadOnly ? '-' : (
            <div className='flex items-center'>
              <NewInvestment
                company={row.original.company}
                investment={row.original}
                trigger={
                  <Button
                    id={`edit-investment-${row.original.amount}`}
                    variant='ghost' className='h-fit px-1 py-1 text-xs'>
                    <Pencil size={14} />
                  </Button>
                }
              />
              <DeleteModal
                deleteButtonId={`delete-${row.original.id}`}
                title='Delete Investment'
                description='Are you sure you want to delete this investment?'
                trigger={
                  <div className='flex items-center px-2 py-1 text-red-500'>
                    <Trash2 size={15} color='red' />
                  </div>
                }
                onDelete={() => {
                  InvestmentService.deleteInvestment(row.original)
                }}
              />
            </div>
          )}
        </div>
      )
    }
  ]

  return (
    <div className={`${className} flex flex-col`}>
      <div className='relative pb-2'>
        <DataTable
          entity='investment'
          className=''
          columns={columns}
          data={investments}
          loading={isLoading}
          filterPlaceholder='Search investment'
          searchKey='description'
          onColumnUpdate={(updatedColumns: ITableColumn) => {
            Logger.info('Updated columns', updatedColumns)
          }}
          onColumnVisibilityChange={(columnId: string, isVisible: boolean) => {
            Logger.info('Updated columns', columnId, isVisible)
          }}
          isGrouped={false}
        />
      </div>
    </div>
  )
}
