import type { Amount, ILimitedPartner } from 'interfaces'
import {
	mapHasOneArrayValue,
	onValidateCapitalCalledFormForm
} from '../helper/capitalCall'

import type { IBankAccount } from 'features/bank_accounts/interface/BankAccount.interface'
import type { ICryptoAccount } from 'features/crypto_accounts/interface/cryptoAccount.interface'
import type { IEmailEditor } from '../interfaces/NewCapitalCall.interface'
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface NewCapitalCallSliceState {
	recipients: ILimitedPartner[]
	recipientsSelectedEmailAddress: Record<string, string[]>
	recipientsCapitalToBeCalled: Record<string, Amount>
	recipientsPaymentDetails: Record<string, IBankAccount[] | ICryptoAccount[]>
	recipientsEmailEditor: IEmailEditor
	isNewCapitalCallSaved: boolean
	isLoading: boolean
}

const initialState: NewCapitalCallSliceState = {
	recipients: [],
	recipientsSelectedEmailAddress: {},
	recipientsCapitalToBeCalled: {},
	recipientsPaymentDetails: {},
	recipientsEmailEditor: {} as IEmailEditor,
	isNewCapitalCallSaved: false,
	isLoading: false
}

export const newCapitalCallSlice = createAppSlice({
	name: 'newCapitalCall',
	initialState,
	reducers: create => ({
		setRecipients: create.reducer(
			(state, action: PayloadAction<ILimitedPartner[]>) => {
				state.recipients = action.payload

				for (const value of state.recipients) {
					state.recipientsSelectedEmailAddress[value.id] = value.email
						? [value.email]
						: []
					state.recipientsPaymentDetails[value.id] = []
				}

				state.isLoading = false
			}
		),
		addRecipients: create.reducer(
			(state, action: PayloadAction<ILimitedPartner>) => {
				const copy = [...state.recipients]

				copy.push(action.payload)

				state.recipientsSelectedEmailAddress[action.payload.id] = action.payload
					.email
					? [action.payload.email]
					: []
				state.recipientsPaymentDetails[action.payload.id] = []

				state.recipients = [...copy]
				state.isLoading = false
			}
		),
		removeRecipients: create.reducer(
			(state, action: PayloadAction<ILimitedPartner>) => {
				const copy = [...state.recipients]

				const index = copy.findIndex(x => x.id === action.payload.id)

				if (index >= 0) copy.splice(index, 1)

				state.recipientsSelectedEmailAddress[action.payload.id] = []
				state.recipientsPaymentDetails[action.payload.id] = []

				state.recipients = [...copy]
				state.isLoading = false
			}
		),
		setRecipientsSelectedEmailAddress: create.reducer(
			(state, action: PayloadAction<Record<string, string[]>>) => {
				state.recipientsSelectedEmailAddress = action.payload
				state.isLoading = false
			}
		),
		addRecipientsSelectedEmailAddress: create.reducer(
			(state, action: PayloadAction<{ id: string; email: string }>) => {
				const copy = { ...state.recipientsSelectedEmailAddress }
				copy[action.payload.id].push(action.payload.email)

				state.recipientsSelectedEmailAddress = { ...copy }
				state.isLoading = false
			}
		),
		removeRecipientsSelectedEmailAddress: create.reducer(
			(state, action: PayloadAction<{ id: string; index: number }>) => {
				const copy = { ...state.recipientsSelectedEmailAddress }
				copy[action.payload.id].splice(action.payload.index, 1)

				state.recipientsSelectedEmailAddress = { ...copy }
				state.isLoading = false
			}
		),
		setRecipientsPaymentDetails: create.reducer(
			(
				state,
				action: PayloadAction<Record<string, IBankAccount[] | ICryptoAccount[]>>
			) => {
				state.recipientsPaymentDetails = action.payload

				state.isLoading = false
			}
		),
		addRecipientsPaymentDetails: create.reducer(
			(
				state,
				action: PayloadAction<{
					id: string
					value: IBankAccount | ICryptoAccount
				}>
			) => {
				const copy = { ...state.recipientsPaymentDetails }

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				copy[action.payload.id].push(action.payload.value as any)

				state.recipientsPaymentDetails = copy

				state.isLoading = false
			}
		),
		clearRecipientPaymentDetails: create.reducer(
			(state, action: PayloadAction<{ id: string }>) => {
				const copy = { ...state.recipientsPaymentDetails }

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				copy[action.payload.id] = []

				state.recipientsPaymentDetails = copy

				state.isLoading = false
			}
		),
		removeRecipientsPaymentDetails: create.reducer(
			(state, action: PayloadAction<{ id: string; index: number }>) => {
				const copy = { ...state.recipientsPaymentDetails }

				copy[action.payload.id].splice(action.payload.index, 1)

				state.recipientsPaymentDetails = copy

				state.isLoading = false
			}
		),
		setRecipientsEmailEditor: create.reducer(
			(state, action: PayloadAction<IEmailEditor>) => {
				state.recipientsEmailEditor = { ...action.payload }

				state.isLoading = false
			}
		),
		setRecipientCapitalToBeCalled: create.reducer(
			(state, action: PayloadAction<Record<string, Amount>>) => {
				state.recipientsCapitalToBeCalled = { ...action.payload }

				state.isLoading = false
			}
		),
		setIsNewCapitalCallSaved: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isNewCapitalCallSaved = action.payload
			}
		)
	}),
	selectors: {
		selectRecipients: state => state.recipients,
		selectRecipientsEmailAddress: state => state.recipientsSelectedEmailAddress,
		selectRecipientsPaymentDetails: state => state.recipientsPaymentDetails,
		selectRecipientsCapitalToBeCalled: state =>
			state.recipientsCapitalToBeCalled,
		selectIsRecipientNextStepDisable: state => {
			return state.recipients.length <= 0
		},
		selectIsEmailAddressesNextStepDisable: state => {
			return !mapHasOneArrayValue(state.recipientsSelectedEmailAddress)
		},
		selectIsPaymentDetailsNextStepDisable: state => {
			return !mapHasOneArrayValue(state.recipientsPaymentDetails as any)
		},
		selectIsRecipientsCapitalToBeCalledStepDisable: state => {
			return (
				!mapHasOneArrayValue(state.recipientsCapitalToBeCalled as any) &&
				!onValidateCapitalCalledFormForm(
					state.recipients,
					state.recipientsCapitalToBeCalled
				)
			)
		},
		selectSetIsNewCapitalCallSaved: state => state.isNewCapitalCallSaved
	}
})

export const {
	setRecipients,
	addRecipients,
	removeRecipients,
	setRecipientsSelectedEmailAddress,
	addRecipientsSelectedEmailAddress,
	removeRecipientsSelectedEmailAddress,
	setRecipientsPaymentDetails,
	addRecipientsPaymentDetails,
	removeRecipientsPaymentDetails,
	clearRecipientPaymentDetails,
	setRecipientsEmailEditor,
	setRecipientCapitalToBeCalled,
	setIsNewCapitalCallSaved
} = newCapitalCallSlice.actions

export const {
	selectRecipients,
	selectRecipientsEmailAddress,
	selectRecipientsPaymentDetails,
	selectIsRecipientNextStepDisable,
	selectIsEmailAddressesNextStepDisable,
	selectIsPaymentDetailsNextStepDisable,
	selectRecipientsCapitalToBeCalled,
	selectIsRecipientsCapitalToBeCalledStepDisable,
	selectSetIsNewCapitalCallSaved
} = newCapitalCallSlice.selectors
