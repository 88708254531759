/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import { getInvitations } from 'features/invitations/invitations.api'
import type { Invitation } from 'interfaces'

export interface InvitationsSliceState {
	data: Invitation[]
	status: 'failed' | 'idle' | 'loading'
	error?: string | null
}

const initialState: InvitationsSliceState = {
	data: [],
	status: 'idle',
	error: null
}

export const invitationsSlice = createAppSlice({
	name: 'invitations',
	initialState,
	reducers: create => ({
		fetchInvitations: create.asyncThunk(
			async (fundId: string) => {
				const response = await getInvitations(fundId)
				return response
			},
			{
				pending: state => {
					state.status = 'loading'
				},
				fulfilled: (state, action) => {
					state.status = 'idle'
					state.data = action.payload
				},
				rejected: (state, action) => {
					state.status = 'failed'
					state.error = action.error.message
				}
			}
		),
		setInvitations: create.reducer(
			(state, action: PayloadAction<Invitation[]>) => {
				state.status = 'idle'
				state.data = action.payload
			}
		)
	}),
	selectors: {
		selectInvitations: invitation => invitation.data,
		selectInvitatonCount: invitation => invitation.data.length,
		selectInvitationStatus: invitation => invitation.status
	}
})

export const { fetchInvitations, setInvitations } = invitationsSlice.actions

export const {
	selectInvitations,
	selectInvitatonCount,
	selectInvitationStatus
} = invitationsSlice.selectors
