/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-await-in-loop */
import { collection, deleteDoc, doc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore'

import type { FoldersInterface } from '../interface/folders.interface'
import Logger from 'lib/logger'
import { firestore } from 'lib/firebase'
import { getFiles } from 'features/files/files.api'
import { toast } from 'sonner'
import { useState } from 'react'

export default function FoldersApi() {
	const folderReference = collection(firestore, 'folders')
	const filesCollection = collection(firestore, 'files')
	const [folders, setFolders] = useState([]);
	const [allFolders, setAllFolders] = useState([]);
	const [subFolders, setSubFolders] = useState([]);
	const [files, setFiles] = useState([]);

	const save = async (folder: FoldersInterface) => {
		try {
			const folderRef = doc(folderReference, folder.id);
			await setDoc(folderRef, folder);
			return folder.id;
		} catch (error) {
			Logger.error("Error adding document:", error);
			return null;
		}
	};
	const createFolder = async (folder: FoldersInterface) => {
		await save(folder);
	}

	const updateFolder = async (folderId: string, updatedData: FoldersInterface) => {
		const folderRef = doc(firestore, "folders", folderId);
		try {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			await updateDoc(folderRef, updatedData as any);
		} catch (error) {
			Logger.error("Error updating document:", error);
		}
	};

	const deleteFolder = async (folderId: string) => {
		const folderRef = doc(firestore, "folders", folderId);
		try {
			await deleteDoc(folderRef);
			toast.success('Folder deleted', { description: '' });
		} catch (error) {
			Logger.error("Error deleting document:", error);
		}
	};

	const getSubfolders = async (folderId: string) => {
		const q = query(folderReference, where("parentId", "==", folderId));
		const subfoldersSnapshot = await getDocs(q);

		return subfoldersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
	};

	const getFolder = async (parentId: string, companyId: string) => {
		// If parentId is null, fetch root folders, otherwise fetch subfolders
		let q;
		q = parentId ? query(folderReference, where("parentId", "==", parentId), where("companyId", "==", companyId)) : query(folderReference, where("parentId", "==", null), where("companyId", "==", companyId));

		const foldersSnapshot = await getDocs(q);
		const folders = foldersSnapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));
		setFolders(folders as any);
		return folders
	};

	const hasChildren = async (folderId: string | null) => {
		try {
			// Check for subfolders
			const subfoldersQuery = query(folderReference, where("parentId", "==", folderId));
			const subfoldersSnapshot = await getDocs(subfoldersQuery);

			if (!subfoldersSnapshot.empty) {
				return true;
			}

			// Check for files
			// const filesQuery = query(filesCollection, where("folderId", "==", folderId));
			// const filesSnapshot = await getDocs(filesQuery);

			// if (!filesSnapshot.empty) {
			// 	return true;
			// }

			return false;
		} catch (error) {
			Logger.error("Error checking for children:", error);
			throw error;
		}
	};


	const listenToFolders = (parentId: string | null, companyId: string) => {
		const q = query(folderReference, where("parentId", "==", parentId), where("companyId", "==", companyId));

		const unsubscribe = onSnapshot(q, async (snapshot) => {
			const foldersData = await Promise.all(snapshot.docs.map(async (doc) => {
				const folder = doc.data();
				const hasChildrenStatus = await hasChildren(doc.id);
				return {
					id: doc.id,
					...folder,
					hasChildren: hasChildrenStatus,
				};
			}));
			setFolders(foldersData);
		});

		return unsubscribe;
	};


	const deleteFolderContents = async (folderId: string) => {
		try {
			// Get all files in the folder
			const subFiles = await getFiles(folderId);
			// Delete each file
			for (const file of subFiles) {
				await deleteDoc(doc(firestore, "files", file.id));
			}

			// Get all subfolders in the folder
			const subfolders = await getSubfolders(folderId);
			// Recursively delete each subfolder and its contents
			for (const subfolder of subfolders) {
				await deleteFolderContents(subfolder.id);
			}

			// Finally, delete the folder itself
			await deleteDoc(doc(firestore, "folders", folderId));
		} catch (error) {
			Logger.error("Error deleting folder contents:", error);
			throw error;
		}
	};

	const listenToFiles = (parentId: string | null) => {
		try {
			const q = query(filesCollection, where("folderId", "==", parentId));
			const unsubscribe = onSnapshot(q, (snapshot) => {
				const data = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setFiles(data);
			});

			return unsubscribe; // Return the unsubscribe function to stop listening when needed
		} catch (error) {
			Logger.error('Error listening to files:', error);
			throw error;
		}
	};

	const getAllFolders = async (companyId: FoldersInterface) => {
		const q = query(folderReference, where("companyId", "==", companyId));

		const foldersSnapshot = await getDocs(q);
		const folders = foldersSnapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));

		setAllFolders(folders);
		return folders
	};

	const moveFile = async (fileId: string, newFolderId: string) => {
		const fileDoc = doc(filesCollection, fileId);
		await updateDoc(fileDoc, { folderId: newFolderId });
	};

	// const moveFolder = async (folderId: string, newParentId: string) => {
	// 	const folderDoc = doc(folderReference, folderId);

	// 	// Function to recursively move subfolders and files
	// 	const moveSubfoldersAndFiles = async (folderId: string, newParentId: string) => {
	// 		// Move all subfolders
	// 		const subfoldersQuery = query(folderReference, where("parentId", "==", folderId));
	// 		const subfoldersSnapshot = await getDocs(subfoldersQuery);
	// 		const subfolders = subfoldersSnapshot.docs;

	// 		for (const subfolder of subfolders) {
	// 			await moveSubfoldersAndFiles(subfolder.id, newParentId);
	// 			await updateDoc(doc(folderReference, subfolder.id), { parentId: newParentId });
	// 		}

	// 		// Move all files
	// 		const filesQuery = query(filesCollection, where("folderId", "==", folderId));
	// 		const filesSnapshot = await getDocs(filesQuery);
	// 		const files = filesSnapshot.docs;

	// 		for (const file of files) {
	// 			await updateDoc(doc(filesCollection, file.id), { folderId: newParentId });
	// 		}
	// 	};

	// 	// Start the recursive move operation
	// 	await moveSubfoldersAndFiles(folderId, newParentId);

	// 	// Update the parentId of the folder itself
	// 	await updateDoc(folderDoc, { parentId: newParentId });
	// };

	const moveFolder = async (folderId: string, newParentId: string) => {
		const folderDoc = doc(folderReference, folderId);

		// Update the parentId of the folder itself
		await updateDoc(folderDoc, { parentId: newParentId });
	};

	return {
		folders,
		delete: (folderId: string) => deleteFolder(folderId),
		deleteFolderContents: (folderId: string) => deleteFolderContents(folderId),
		createFolder: (data: FoldersInterface) => createFolder(data),
		updateFolder: (folderId: string, data: FoldersInterface) => updateFolder(folderId, data),
		setFolders,
		subFolders,
		getFolder: (parentId: string, companyId: string) => getFolder(parentId, companyId),
		// getFiles: (folderId: string) => getFiles(folderId),
		listenToFiles: (folderId: string) => listenToFiles(folderId),
		files,
		getAllFolders: (companyId: string) => getAllFolders(companyId),
		allFolders,
		moveFile: (fileId: string, newFolderId: string) => moveFile(fileId, newFolderId),
		moveFolder: (folderId: string, newParentId: string) => moveFolder(folderId, newParentId),
		listenToFolders: (parentId: null, companyId: string) => listenToFolders(parentId, companyId),
		hasChildren: (folderId: string) => hasChildren(folderId),
	}
}