/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IFile } from './file.interface'
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface FilesSliceState {
	data: IFile[]
	oracleData: any[]
	selectedFile: IFile | null
	isLoading: boolean
	error?: string | null
}

const initialState: FilesSliceState = {
	data: [],
	oracleData: [],
	selectedFile: null,
	isLoading: false,
	error: null
}

export const filesSlice = createAppSlice({
	name: 'files',
	initialState,
	reducers: create => ({
		setFiles: create.reducer((state, action: PayloadAction<IFile[]>) => {
			state.data = action.payload
			state.isLoading = false
		}),
		setOracleData: create.reducer((state, action: PayloadAction<any[]>) => {
			state.oracleData = action.payload
		}),
		setSelectedFile: create.reducer((state, action: PayloadAction<IFile>) => {
			state.selectedFile = action.payload
		})
	}),
	selectors: {
		selectSelectedFile: file => file.selectedFile,
		selectFiles: file => file.data,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		selectOracleData: file => file.oracleData
	}
})

export const { setFiles, setSelectedFile, setOracleData } = filesSlice.actions

export const { selectFiles, selectSelectedFile, selectOracleData } = filesSlice.selectors
