/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { ICapitalCallSettings } from '../interface/capital_call_settings.interafce'
import type { ICapitalCallTemplate } from '../interface/capital_call_template.interface'

export interface CapitalCallTemplateState {
	pdfTemplate: {
		data: ICapitalCallTemplate[]
		isLoading: boolean
		error?: string | null
	}
	settings: {
		data: ICapitalCallSettings | null
		isLoading: boolean
		error?: string | null
	}
}

const initialState: CapitalCallTemplateState = {
	pdfTemplate: {
		data: [],
		isLoading: false,
		error: null
	},
	settings: {
		data: null,
		isLoading: false,
		error: null
	}
}

export const capitalCallTemplateslice = createAppSlice({
	name: 'capitalCallTemplate',
	initialState,
	reducers: create => ({
		setCapitalCallTemplates: create.reducer(
			(state, action: PayloadAction<ICapitalCallTemplate[]>) => {
				state.pdfTemplate.data = action.payload
				state.pdfTemplate.isLoading = false
			}
		),
		setCapitalCallSettings: create.reducer(
			(state, action: PayloadAction<ICapitalCallSettings | null>) => {
				state.settings.data = action.payload
				state.settings.isLoading = false
			}
		)
	}),
	selectors: {
		selectCapitalCallTemplates: capitalCall => capitalCall.pdfTemplate,
		selectCapitalCallSettings: capitalCall => capitalCall.settings
	}
})

export const { setCapitalCallTemplates, setCapitalCallSettings } =
	capitalCallTemplateslice.actions

export const { selectCapitalCallTemplates, selectCapitalCallSettings } =
	capitalCallTemplateslice.selectors
