import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuPortal,
	DropdownMenuSeparator,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger
} from 'components/ui/dropdown-menu'
import { deleteCompany, updateCompany } from '../api/companies.api'

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DotsVerticalIcon } from '@radix-ui/react-icons'
import type { ICompany } from 'interfaces'
import type { IFunnel } from 'features/funnels/funnels.interface'
import { toast } from 'sonner'
import { Fragment } from 'react'

export default function CompanyCardMenu({
	company,
	funnels
}: {
	company: ICompany
	funnels: any[]
}) {
	const onFunnelChanged = async (funnel: IFunnel) => {
		try {
			const newCompany = {
				id: company.id,
				funnel: {
					id: funnel.id,
					name: funnel.name,
					category: funnel.category ?? 'inbound'
				},
				lastUpdatedAt: new Date().toISOString()
			}

			await updateCompany(newCompany)
			toast.success('Company moved successfully')
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to move company')
		}
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<DotsVerticalIcon className='h-4 w-4' />
			</DropdownMenuTrigger>
			<DropdownMenuContent className='w-56'>
				<DropdownMenuLabel className='rounded bg-blue-50 text-blue-500'>
					{company.name}
				</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuGroup>
					<DropdownMenuSub>
						<DropdownMenuSubTrigger>Move to</DropdownMenuSubTrigger>
						<DropdownMenuPortal>
							<DropdownMenuSubContent>
								<div className='w-full'>
									{funnels.map(funnel => (
										<Fragment key={funnel.id}>
											{!['holding-pattern', 'pass'].includes(funnel.id) && (
												<DropdownMenuItem
													onClick={async () => {
														await onFunnelChanged(funnel)
													}}
												>
													{funnel.name}
												</DropdownMenuItem>
											)}

											{['holding-pattern', 'pass'].includes(funnel.id) && (
												<DropdownMenuItem
													onClick={async () => {
														await onFunnelChanged(funnel)
													}}
												>
													{funnel.name}
												</DropdownMenuItem>
											)}
										</Fragment>
									))}
								</div>
							</DropdownMenuSubContent>
						</DropdownMenuPortal>
					</DropdownMenuSub>
					<DropdownMenuItem
						onClick={() => {
							window.open(`/company/${company.id}`, '_blank', 'noreferrer')
						}}
					>
						Open in new window
					</DropdownMenuItem>
				</DropdownMenuGroup>
				<DropdownMenuSeparator />
				<DropdownMenuItem
					onClick={() => {
						const res = confirm('Are you sure you want to delete this company?')
						if (res) {
							deleteCompany(company.id)
						}
					}}
					className='w-full text-red-500'
				>
					Delete
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
