import type { ICalendarEvent } from "features/companies/interfaces/calendarEvent.interface"

export function getCalendarEventStatus(calendarEvent: ICalendarEvent) {
  const now = new Date()
  const start = new Date(calendarEvent.start.dateTime)
  const end = new Date(calendarEvent.end.dateTime)
  if (now < start) {
    return 'upcoming'
  }
  if (now > end) {
    return 'past'
  }
  return 'live'
}
