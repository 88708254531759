import { Badge } from 'components/ui/badge'
import Button from 'components/Buttons/Button'
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
import { CaretSortIcon } from '@radix-ui/react-icons'
import type { ColumnDef } from '@tanstack/react-table'
import { DataTable } from 'features/dataTable/DataTable'
import DeleteModal from 'components/DeleteModal'
import type { IBankAccount } from '../interface/BankAccount.interface'

interface ICapitalCallRequestListProps {
	className?: string
	data: IBankAccount[]
	onClickEdit: (bank: IBankAccount) => void
	onClickSetDefault: (bank: IBankAccount) => void
	onDelete: (bank: IBankAccount) => void
}

export default function BankAccountList({
	className,
	data,
	onClickEdit,
	onDelete,
	onClickSetDefault
}: ICapitalCallRequestListProps): JSX.Element {
	const columns: ColumnDef<IBankAccount>[] = [
		{
			accessorKey: 'accountName',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Account Name
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>
					{row.original.accountName}

					{row.original.isDefault && (
						<Badge variant='default' className='ml-3'>
							{' '}
							Default{' '}
						</Badge>
					)}
				</div>
			)
		},
		{
			accessorKey: 'title',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Title
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => <div className='truncate'>{row.original.title}</div>
		},
		{
			accessorKey: 'accountNumber',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Account Number
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>{row.original.accountNumber}</div>
			)
		},
		{
			accessorKey: 'accountAddress',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Account Address
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>{row.original.accountAddress}</div>
			)
		},
		{
			accessorKey: 'routingNumber',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Routing Number
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>{row.original.routingNumber}</div>
			)
		},
		{
			accessorKey: 'swiftCode',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Swift Code
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>{row.original.swiftCode}</div>
			)
		},
		{
			accessorKey: 'bankName',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Bank Name
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => <div className='truncate'>{row.original.bankName}</div>
		},
		{
			accessorKey: 'bankAddress',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Bank Address
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>{row.original.bankAddress}</div>
			)
		},
		{
			accessorKey: 'cta',
			maxSize: 150,
			minSize: 150,
			header: () => '',
			cell: ({ row }) => (
				<div className='flex items-center'>
					{!row.original.isDefault && (
						<Button
							id='deleteBankAccount'
							variant='outline'
							className='whitespace-nowrap px-2'
							onClick={() => onClickSetDefault(row.original)}
						>
							Set As Default
						</Button>
					)}

					<Button
						id='deleteBankAccount'
						variant='ghost'
						className='px-2'
						onClick={() => onClickEdit(row.original)}
					>
						Edit
					</Button>

					<DeleteModal
						deleteButtonId='deleteBankAccount'
						title='Delete Bank Account'
						description={`Are you sure you want to delete ${row.original.accountName}?`}
						trigger={
							<Button
								id='deleteBankAccount'
								variant='ghost'
								className='px-2 text-red-500'
							>
								Delete
							</Button>
						}
						onDelete={() => onDelete(row.original)}
					/>
				</div>
			)
		}
	]

	const isLoading = false

	return (
		<div className={`${className} flex flex-col`}>
			<DataTable
				entity='bank account'
				columns={columns}
				data={data}
				loading={isLoading}
				allowSearch={false}
				filterPlaceholder='Search....'
			/>
		</div>
	)
}
