import * as Yup from 'yup'

import { doc, updateDoc } from 'firebase/firestore'

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthContext } from 'context/AuthContext'
import type { Partner } from 'interfaces'
import { UserRole } from 'interfaces'
import { firestore } from 'lib/firebase'
import { toast } from 'sonner'
import { updatePartner } from '../partner.api'
import { useContext } from 'react'
import { useFormik } from 'formik'

export default function useEditPartner({
	onSuccess,
	partner
}: {
	onSuccess?: () => void
	partner: Partner
}) {
	const { authUser } = useContext(AuthContext)

	const validationSchema = Yup.object({
		name: Yup.string().required('Name is required'),
		email: Yup.string().required('Email is required').email('Invalid email'),
		role: Yup.mixed<UserRole>()
			.oneOf(Object.values(UserRole))
			.required('Role is required'),
		linkedIn: Yup.string().url('Enter a valid url'),
		receivesEmails: Yup.boolean(),
		// photoUrl: Yup.string().url('Enter a valid url')
	})

	const formik = useFormik({
		initialValues: {
			name: partner.name,
			email: partner.email,
			role: partner.role,
			linkedIn: partner.linkedIn,
			receivesEmails: partner.receivesEmails,
			photoUrl: partner.photoUrl
		},
		validationSchema,
		onSubmit: async values => {
			if (!authUser) return

			try {
				formik.setSubmitting(true)

				const data: Partner = {
					...partner,
					name: values.name,
					email: values.email,
					role: values.role,
					linkedIn: values.linkedIn ?? '',
					receivesEmails: values.receivesEmails,
					photoUrl: values.photoUrl ?? '',
					lastUpdatedAt: new Date().toISOString()
				}

				await updatePartner(data)

				// update the user's role
				if (partner.role !== values.role) {
					const ref = doc(firestore, 'users', partner.userId)
					await updateDoc(ref, { role: values.role } as any)
				}

				toast.success('Partner updated successfully')
				onSuccess?.()
			} catch (error: any) {
				toast.error(error.message)
			} finally {
				formik.setSubmitting(false)
				formik.resetForm()
			}
		}
	})

	return { formik }
}
