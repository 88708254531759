import { selectNoteSettings, setNoteSettings } from '../redux/note_settings.slice'
import { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import { Mail } from 'lucide-react'
import { NoteSettingsService } from '../service/notes_settings.service'
import SpokInlineInputEditor from 'components/shadcn/SpokInlineInputEditor'
import { Switch } from '@headlessui/react'
import clsxm from 'utils/clsxm'
import { store } from 'app/store'
import { useAppSelector } from 'app/hooks'

export default function NotesSettingsPage() {
	const { authUser } = useContext(AuthContext)
	const { data, isLoading, error } = useAppSelector(selectNoteSettings)

	useEffect(() => {
		if (!authUser?.fund.id) return
		const unsubscribe = NoteSettingsService.listenToNoteSettings(
			{ fundId: authUser.fund.id }
		)
		return () => {
			unsubscribe()
		}
	}, [authUser?.fund.id])

	if (isLoading) return <div>Loading...</div>

	if (error) return <div>Error: {error}</div>

	return (
		<div className='flex flex-col gap-4 p-8'>
			{/* Settings */}
			<div>Notes Settings</div>
			<div className='flex flex-col'>

				{/* Send CC Emails */}
				<div className='w-full max-w-[50vw]'>
					<div className='flex items-center justify-between px-5 pb-2 pt-5'>
						<div>
							<div className='flex items-center gap-2 font-semibold'>
								<div>
									<Mail />
								</div>
								Send Note Emails
							</div>
							<div className='w-96 text-sm text-gray-700'>
								{data?.shoudlSendNoteEmails
									? 'Parters will receive note emails.\nThis is after as note has been added.'
									: 'Enable to send note emails to partners.\nThis is after the a note has been added.'}
							</div>
						</div>

						<div className=''>
							<Switch
								checked={data?.shoudlSendNoteEmails ?? false}
								onChange={checked => {
									store.dispatch(
										setNoteSettings({
											...data,
											shoudlSendNoteEmails: checked
										})
									)

									NoteSettingsService.saveNoteSettings({
										settings: { shoudlSendNoteEmails: checked },
										fund: authUser?.fund
									})
								}}
								className={clsxm(
									'relative inline-flex h-[28px] w-[64px] shrink-0 cursor-pointer rounded-full border',
									'focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75',
									'border-gray-300 shadow-inner transition-colors duration-200 ease-in-out',
									{
										'border-green-500 bg-green-500':
											data?.shoudlSendNoteEmails,
										'bg-white': !data?.shoudlSendNoteEmails
									}
								)}
							>
								<span className='sr-only'>Use setting</span>
								<span
									aria-hidden='true'
									className={clsxm(
										'pointer-events-none mt-[1px] inline-block h-[24px] w-[24px] transform rounded-full border',
										'shadow-lg ring-0 transition duration-200 ease-in-out',
										data?.shoudlSendNoteEmails ?? false
											? 'translate-x-9 bg-white'
											: 'translate-x-0 bg-gray-300'
									)}
								/>
							</Switch>
						</div>
					</div>
				</div>

				{/* Sender reply to address */}
				<div className='flex justify-between px-5 pb-2 pt-5'>
					<div>
						<div className='flex items-center gap-2 font-semibold'>
							<div>
								<Mail />
							</div>
							Email Reply To Address
						</div>
						<div className='w-96 text-sm text-gray-700'>
							The address of the recipient of follow up emails
						</div>
					</div>

					<SpokInlineInputEditor
						defaultValue={data?.emailReplyToAddress ?? ''}
						onSave={value => {
							store.dispatch(
								setNoteSettings({
									...data,
									emailReplyToAddress: value
								})
							)
							NoteSettingsService.saveNoteSettings({
								settings: { emailReplyToAddress: value },
								fund: authUser?.fund
							})
						}}
					/>
				</div>

			</div>
		</div>
	)
}
