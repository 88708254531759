export const CURRENCIES = [
  {
    name: 'New Zealand Dollar',
    code: 'NZD',
    symbol: '$'
  },
  {
    name: 'United States Dollar',
    code: 'USD',
    symbol: '$'
  },
  {
    name: 'Euro',
    code: 'EUR',
    symbol: '€'
  },
  {
    name: 'British Pound',
    code: 'GBP',
    symbol: '£'
  },
  {
    name: 'Australian Dollar',
    code: 'AUD',
    symbol: '$'
  },
  {
    name: 'Japanese Yen',
    code: 'JPY',
    symbol: '¥'
  },
  {
    name: 'Chinese Yuan',
    code: 'CNY',
    symbol: '¥'
  },
  {
    name: 'Canadian Dollar',
    code: 'CAD',
    symbol: '$'
  },
  {
    name: 'Swiss Franc',
    code: 'CHF',
    symbol: 'CHF'
  },
  {
    name: 'Hong Kong Dollar',
    code: 'HKD',
    symbol: '$'
  },
  {
    name: 'Swedish Krona',
    code: 'SEK',
    symbol: 'kr'
  },
  {
    name: 'New Taiwan Dollar',
    code: 'TWD',
    symbol: 'NT$'
  },
  {
    name: 'South Korean Won',
    code: 'KRW',
    symbol: '₩'
  },
  {
    name: 'Singapore Dollar',
    code: 'SGD',
    symbol: '$'
  },
  {
    name: 'Norwegian Krone',
    code: 'NOK',
    symbol: 'kr'
  },
  {
    name: 'Mexican Peso',
    code: 'MXN',
    symbol: '$'
  },
  {
    name: 'Indian Rupee',
    code: 'INR',
    symbol: '₹'
  },
  {
    name: 'Russian Ruble',
    code: 'RUB',
    symbol: '₽'
  },
  {
    name: 'South African Rand',
    code: 'ZAR',
    symbol: 'R'
  }
]

export const STAGE = [
  'Pre-Seed',
  'Seed',
  'Bridge',
  'Series A',
  'Series B',
  'Secondary Purchase',
]

export const ROUNDS = [
  'Safe Note',
  'Convertible Note',
  'Priced Round',
  'Preffered Stock',
]