import {
	deleteBankAccountById,
	updateBankAccountById
} from '../service/bank_account.service'
import { useContext, useEffect, useState } from 'react'

import AddEditBankAccountDialog from '../component/AddEditBankAccountDialog'
import { AuthContext } from 'context/AuthContext'
import BankAccountList from './BackAccountList'
import type { IBankAccount } from '../interface/BankAccount.interface'
import { listenToBankAccounts } from '../api/bank-accounts.api'
import { selectBankAccounts } from '../redux/bankAccounts.slice'
import { useAppSelector } from 'app/hooks'

export default function BankAccountPage(): JSX.Element {
	const { authUser } = useContext(AuthContext)

	const bankAccounts = useAppSelector(selectBankAccounts)
	const [isOpen, setIsOpen] = useState(false)
	const [isEditMode, setIsEditMode] = useState(false)
	const [formInitialValue, setFormInitialValue] = useState<IBankAccount>()

	function onClickEdit(value: IBankAccount) {
		setFormInitialValue(value)
		setIsEditMode(true)
		setIsOpen(true)
	}

	function onSubmitSuccess() {
		setIsEditMode(false)
		setIsOpen(false)
		setFormInitialValue(undefined)
	}

	useEffect(() => {
		if (!isOpen) {
			setFormInitialValue(undefined)
			setIsEditMode(false)
		}
	}, [isOpen])

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubscribe = listenToBankAccounts(authUser.fund.id)
		// eslint-disable-next-line consistent-return
		return () => unsubscribe()
	}, [authUser?.fund.id])

	return (
		<div className='h-100vh flex flex-col p-5'>
			<div className='flex items-center justify-between'>
				<div className='pt-5 text-left text-lg font-semibold'>
					<div className='flex justify-between'>Bank Accounts</div>
					<p className='text-sm font-normal text-gray-500'>
						Manage your bank accounts
					</p>
				</div>
				<div>
					<AddEditBankAccountDialog
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						initialValue={formInitialValue}
						editMode={isEditMode}
						onSuccess={onSubmitSuccess}
					/>
				</div>
			</div>
			<BankAccountList
				data={bankAccounts}
				onClickEdit={value => onClickEdit(value)}
				onDelete={(value: IBankAccount) => deleteBankAccountById(value.id)}
				onClickSetDefault={async (bank: IBankAccount): Promise<void> => {
					await updateBankAccountById(bank.id, { ...bank, isDefault: true })

					for (const bankAccount of bankAccounts) {
						if (bankAccount.id !== bank.id) {
							updateBankAccountById(bankAccount.id, {
								...bankAccount,
								isDefault: false
							})
						}
					}
				}}
			/>
		</div>
	)
}
