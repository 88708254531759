import clsx from "clsx";
import DeleteModal from "components/DeleteModal";
import { XIcon } from "lucide-react";
import { nanoid } from "nanoid";

interface Props {
  data: string[]
  className?: string,
  onDelete?: (index: number) => void
}

export default function SpokBadge({
  data,
  className,
  onDelete
}: Props) {
  return (
    <div className={clsx(`${className} flex gap-2 flex-wrap`)}>
      {
        data.map((str, index) => (
          <div className="bg-[#D9EAFF] flex justify-center items-center rounded rounded-smd text-sm font-semibold py-2 px-1 text-blue-700 cursor-pointer" id="spok-badge-item" data-testid="spok-badge-item" key={nanoid()}>
            <span className="px-2">
              {str}
            </span>
            
            {onDelete && (
									<DeleteModal
										deleteButtonId={`deleteTag-${str}`}
										title='Delete'
										description={`Are you sure you want to delete ${str}?`}
										trigger={
											<XIcon
												data-testid={`deleteTag-${str}`}
												size={12}
												className='transform cursor-pointer opacity-30 transition-all duration-200 hover:scale-110 hover:text-red-500 group-hover:opacity-100'
											/>
										}
										onDelete={() => onDelete(index)}
									/>
              )}
          </div>
        ))
      }
    </div>
  );
}