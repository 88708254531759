/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Button from 'components/Buttons/Button'
import DeleteModal from 'components/DeleteModal'
import { AuthContext } from 'context/AuthContext'
import { UserRole, type ILimitedPartner } from 'interfaces'
import { CircleSlash, Copy, ExternalLink, Trash2 } from 'lucide-react'
import { useContext } from 'react'
import { toast } from 'sonner'
import clsxm from 'utils/clsxm'
import { LPService } from '../service/lp.service'
import AddTeamMember from './AddTeamMember'

interface Props {
	limitedPartner: ILimitedPartner
	viewOnly?: boolean
	wrapperClass?: string
}

export default function LimitedPartnerTeamMembers({
	limitedPartner,
	wrapperClass,
	viewOnly = false
}: Props) {
	const { authUser } = useContext(AuthContext)

	if (!limitedPartner.teamMembers || limitedPartner.teamMembers.length === 0)
		return (
			<div
				className={clsxm(' mt-5 rounded border bg-white pb-5', wrapperClass)}
			>
				<div className='border-b px-2 pb-3'>
					<div className='flex items-center gap-2 pl-2 pt-2 text-base font-semibold text-gray-700 '>
						Team Members
						{!viewOnly && (
							<div data-testid='add-company-teamMember'>
								<AddTeamMember limitedPartner={limitedPartner} />
							</div>
						)}
					</div>
					<div className='px-2 text-sm'>
						You can add members of your team whom you want to have deletegate
						access to this dashboard
					</div>
				</div>
				<div className='text mt-5 flex items-center gap-3 px-4 text-gray-500'>
					<CircleSlash />
					No team members added yet
				</div>
			</div>
		)

	return (
		<div className={clsxm(' mt-5 rounded border bg-white', wrapperClass)}>
			<div className='border-b px-2 pb-3'>
				<div className='flex items-center gap-2 pl-2 pt-2 text-base font-semibold text-gray-700 '>
					Team Members
					{!viewOnly && (
						<div data-testid='add-company-teamMember'>
							<AddTeamMember limitedPartner={limitedPartner} />
						</div>
					)}
				</div>
				<div className='px-2 text-sm'>
					You can add members of your team whom you want to have deletegate
					access to this dashboard
				</div>
			</div>
			<div className='flex flex-wrap p-2'>
				{limitedPartner.teamMembers.map(teamMember => (
					<div
						key={teamMember.email}
						className='group flex items-center gap-2 p-2'
					>
						<div
							data-testid={`teamMemberName-${teamMember.name}`}
							className='hidden h-8 w-8 items-center justify-center rounded-full bg-blue-500 text-white xl:flex'
						>
							{teamMember.name.slice(0, 1)}
						</div>
						<div className='flex flex-col'>
							<div
								onClick={() => {
									if (teamMember.linkedIn) {
										window.open(teamMember.linkedIn, '_blank', 'noreferrer')
									}
								}}
								className={`flex items-center gap-1 text-sm font-semibold transition-all ${
									teamMember.linkedIn ? 'cursor-pointer hover:underline' : ''
								}`}
							>
								{teamMember.name}
								{teamMember.linkedIn && <ExternalLink size={15} />}
							</div>
							{authUser?.role === UserRole.GeneralPartner && (
								<span
									data-testid={`teamMemberEmail-${teamMember.email}`}
									className='flex items-center gap-1 text-xs text-gray-500'
								>
									{teamMember.email}
									<Button
										variant='ghost'
										className='px-2 py-1'
										onClick={() => {
											navigator.clipboard.writeText(teamMember.email)
											toast.success('Copied to clipboard')
										}}
									>
										<Copy size={13} />
									</Button>
								</span>
							)}
						</div>

						{!viewOnly && (
							<>
								<div
									data-testid={`editteamMember-${teamMember.email}`}
									className='ml-3'
								>
									<AddTeamMember
										limitedPartner={limitedPartner}
										teamMember={teamMember}
									/>
								</div>

								<DeleteModal
									deleteButtonId={`deleteteamMember-${teamMember.email}`}
									title='Delete Team Member'
									description={`Are you sure you want to delete ${teamMember.name}?`}
									trigger={
										<Button
											id={`deleteteamMember-${teamMember.email}`}
											variant='ghost'
											className='cursor-pointer p-2 text-red-500 opacity-0 transition-all group-hover:opacity-100'
										>
											<Trash2 size={15} />
										</Button>
									}
									onDelete={() =>
										LPService.removeTeamMember(limitedPartner, teamMember.email)
									}
								/>
							</>
						)}
					</div>
				))}
			</div>

			{limitedPartner.teamMembers.length === 0 && (
				<div className='pl-2 text-sm text-gray-500'>
					No team members added yet
				</div>
			)}
		</div>
	)
}
