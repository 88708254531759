import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import type { IBarChartData } from "../interfaces/reports.interface";

interface Props {
  data: IBarChartData[],
  height?: number | string,
  width?: number | string
}

export default function SpokReportBarChart(
  {
    data,
    height = 340,
    width = "100%"
  }: Props
) {
  return (
    <div style={{width, height}}>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{
            top: 30,
            right: 0,
            left: 10,
            bottom: 0
          }}
        >
          <CartesianGrid strokeWidth={0} />
          <XAxis dataKey="name" tickSize={0} tick={{fontSize: 14}} tickMargin={10}/>
          <YAxis tick={{fontSize: 14}} tickSize={0} tickMargin={10}/>
          <Tooltip />
          <Bar dataKey="value" stackId="a" fill="#2466DD" radius={[7,7,0,0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}