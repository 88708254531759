/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import type { CompanyData, ICompany } from 'interfaces'
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import { CompanyService } from 'features/companies/service/company.service'
import { DialogModal } from 'components/shadcn/DialogModal'
import { Plus } from 'lucide-react'
import { listenToCompanies } from 'features/companies/api/companies.api'
import { selectCompanies } from '../../companies/redux/companies.slice'
import { useAppSelector } from 'app/hooks'

interface Props {
	competitors: CompanyData[]
	company: CompanyData
	disabled?: boolean
	onSave: (value: CompanyData[]) => void
	// onCompetitorDelete?: (value: ICompetitor) => void
}

export function companiesToDropdown(
	list: ICompany[],
	toExclude: ICompany[] = []
): {
	label: string
	value: ICompany
}[] {
	const data: {
		label: string
		value: ICompany
	}[] = []

	for (const element of list) {
		if (!toExclude.some(x => x.id === element.id))
			data.push({ value: element, label: element.name })
	}

	return data
}

export default function CompetitorSelectorOld({
	competitors,
	company,
	disabled = false,
	onSave
	// onCompetitorDelete
}: Props) {
	const { authUser } = useContext(AuthContext)
	const companies = useAppSelector(selectCompanies)

	const [isOpen, setIsOpen] = useState(false)
	const [selectedCompetitor, setSelectedCompetitor] = useState<CompanyData & {competitors: CompanyData[]}>()

  const dropDownData = companiesToDropdown(companies, competitors as any[])

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubscribe = listenToCompanies(authUser.fund.id)
		return () => unsubscribe()
	}, [authUser?.fund.id])

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title='Add Competitor'
			description=''
			trigger={
				disabled ? (
					<div />
				) : (
					<Button
						id='open-competitor-selector-button'
						variant='ghost'
						className='rounded-md bg-blue-100 px-2 py-0 text-blue-700'
						disabled={disabled}
						onClick={() => {
							setIsOpen(true)
						}}
					>
						<Plus size={15} />
						<div>Add</div>
					</Button>
				)
			}
		>
			<div>
				{/* <AddNewCompetitor /> */}

				<div className=''>
					<label className='block text-sm font-medium text-gray-700'>Select Company</label>
					<ComboSelect
						className=''
						placeholder='-'
						allowSearch
						defaultValue={selectedCompetitor?.name ?? '-'}
						options={dropDownData}
						onSelect={value =>
							setSelectedCompetitor(value)
						}
					/>
				</div>
			</div>
				<div className='mt-8 flex w-full justify-end gap-5'>
					<Button
						id='cancel-create-competitor-button'
						variant='danger'
						onClick={() => setIsOpen(false)}
					>
						Cancel
					</Button>
					<Button
						id='save-competitor-button'
						className='px-4'
						disabled={!selectedCompetitor}
						onClick={() => {
							if(!selectedCompetitor) return;

							setIsOpen(false)
							
							onSave(
								[
									...(competitors as any), 
									{
										id: selectedCompetitor.id, 
										name: selectedCompetitor.name, 
										website: selectedCompetitor.website, 
									}
								]
							)	

							// Add new competitor to the list of the selected company
							CompanyService.updateCompany({
								id: selectedCompetitor.id,
								company: selectedCompetitor,
								value: [...selectedCompetitor.competitors, company],
								key: 'competitors',
								shouldLogActivity: true,
								shouldShowToast: false								
							})

							// Update the ids
							CompanyService.updateCompany({
								id: selectedCompetitor.id,
								company: selectedCompetitor,
								// eslint-disable-next-line @typescript-eslint/no-unsafe-return
								value: [...selectedCompetitor.competitors, company].map(i => i.id),
								key: 'competitorIds',
								shouldLogActivity: false,
								shouldShowToast: false								
							})
					
						}}
					>
						Add
					</Button>
				</div>
		</DialogModal>
	)
}
