import BankAccountPage from 'features/bank_accounts/views/BankAccountPage'
import CapitalCallSettings from '../CapitalCallTemplates/view/CapitalCallSettings'
import CryptoAccountPage from 'features/crypto_accounts/views/CryptoAccountPage'
import DashboardLayout from 'components/Dashboard/DashboardLayout'
import IntegrationSettings from '../Integrations/views/IntegrationsSettings'
import Invitations from 'features/invitations/view/Invitations'
import NotesSettingPage from '../Notes/view/NotesSettingsPage'
import Partners from 'features/partners/Partners'
import ProfileSettings from '../Profile/view/ProfileSettings'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import NotFound from 'components/NotFound'
import CompanyFormSettings from '../CompanyForm/view/CompanyFormSettings'

const tabs = [
	'profile',
	'integrations',
	'partners',
	'invitations',
	'bank accounts',
	'crypto accounts',
	'capital call',
	'notes',
	'company form'
]

export default function Settings(): JSX.Element {
	const { search, pathname } = useLocation()
	const navigate = useNavigate()
	const searchParams = new URLSearchParams(search)
	const page = searchParams.get('tab')

	const [selectedTab, setSelectedTab] = useState<string>(page ?? tabs[1])

	useEffect(() => {
		const activeTab = page?.replaceAll('-', ' ') ?? ''
		if (!activeTab.trim()) {
			setSelectedTab('profile')
			return
		}
		if (tabs.includes(activeTab)) {
			setSelectedTab(activeTab)
		} else {
			setSelectedTab('not-found')
		}
	}, [page])

	return (
		<DashboardLayout hideHeader={false} showSidebar title='Settings'>
			<div>

				<div className='flex w-screen overflow-x-auto border-b'>

					{tabs.map(item => (
						<button
							data-testid={`tab-${item}`}
							type='button'
							onClick={() =>
								navigate(
									`${pathname}?tab=${item.replaceAll(' ', '-').toLowerCase()}`
								)
							}
							className={` cursor-pointer whitespace-nowrap border-b-2 px-4 py-2 text-sm capitalize
               ${
									selectedTab === item
										? 'border-b-[#1486C1] text-black'
										: 'border-b-transparent text-gray-500'
								}`}
							key={item}
						>
							{item.replaceAll('-', ' ')}
						</button>
					))}
				</div>

				{selectedTab === 'partners' && <Partners />}
				{selectedTab === 'integrations' && <IntegrationSettings />}
				{selectedTab === 'invitations' && <Invitations />}
				{selectedTab === 'profile' && <ProfileSettings />}
				{selectedTab === 'bank accounts' && <BankAccountPage />}
				{selectedTab === 'crypto accounts' && <CryptoAccountPage />}
				{selectedTab === 'capital call' && <CapitalCallSettings />}
				{selectedTab === 'notes' && <NotesSettingPage />}
				{selectedTab === 'company form' && <CompanyFormSettings />}
				{selectedTab === 'not-found' && <NotFound />}
			</div>
		</DashboardLayout>
	)
}
