import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'

import Button from 'components/Buttons/Button'
import { Input } from 'components/ui/input'
/* eslint-disable @typescript-eslint/sort-type-constituents */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Pencil1Icon } from '@radix-ui/react-icons'
import { PopoverClose } from '@radix-ui/react-popover'
import { useState } from 'react'

interface Props {
	onSave: (value?: string) => void
	defaultValue: string
	value?: string
	placeholder?: string
	content?: React.ReactNode
	editButton?: React.ReactNode
	multiLine?: boolean
	showEditButton?: boolean
	title?: string
	id?: string
	inputType?: 'email' | 'number' | 'password' | 'text' | 'date'
	isReadOnly?: boolean
}

export default function SpokInlineInputEditor({
	defaultValue = '',
	onSave,
	placeholder,
	content,
	value,
	multiLine = false,
	showEditButton = true,
	editButton,
	id,
	title,
	inputType,
	isReadOnly
}: Props) {
	const [input, setInput] = useState(defaultValue)

	if (isReadOnly)
		return (
			<div>
				{content ? (
					<div
						data-testid={`content-${id}`}
						className='group flex items-center gap-2'
					>
						{content}
					</div>
				) : (
					<div
						data-testid={`content-${id}`}
						className='group flex w-fit items-center gap-1'
					>
						{(value ?? defaultValue) || '-'}
					</div>
				)}
			</div>
		)

	return (
		<Popover>
			<div className='group flex items-center gap-1'>
				<div
					onClick={() => {
						// If input is a link
						if (input.startsWith('http')) {
							window.open(input, '_blank', 'noopener, noreferrer')
						}
					}}
					className='cursor-pointer text-sm'
				>
					{content ? (
						<div
							data-testid={`content-${id}`}
							className='group flex items-center gap-2'
						>
							{content}{' '}
						</div>
					) : (
						<div
							data-testid={`content-${id}`}
							className='group flex w-fit items-center gap-1 truncate'
						>
							{(value ?? defaultValue) || '-'}
						</div>
					)}
				</div>
				<PopoverTrigger asChild>
					{showEditButton
						? (editButton || (
							<Pencil1Icon
								data-testid={`trigger-${id}`}
								className='cursor-pointer opacity-20 group-hover:opacity-100'
							/>
						))
						: null}
				</PopoverTrigger>
			</div>
			<PopoverContent
				align='start'
				className='z-[999999] flex w-auto flex-col space-y-2 rounded p-2'
			>
				<div>
					{title && <div className='text-sm font-semibold'>{title}</div>}
				</div>
				{multiLine ? (
					<textarea
						id={id}
						className='h-32 w-96 rounded-md border border-gray-200'
						placeholder={placeholder}
						value={input}
						onChange={e => {
							setInput(e.target.value)
						}}
					/>
				) : (
					<Input
						id={id}
						type={inputType}
						className={`${inputType === 'date' ? 'w-40' : 'w-96'
							} rounded-md border`}
						placeholder={placeholder}
						value={input}
						onChange={e => {
							setInput(e.target.value)
						}}
					/>
				)}

				<PopoverClose className='w-fit rounded-md'>
					<div className='flex items-center justify-center gap-2'>
						<Button
							id={`cancel-${id}`}
							variant='ghost'
							className='rounded px-5 text-red-500'
							onClick={() => {
								setInput(defaultValue)
							}}
						>
							Cancel
						</Button>
						<Button
							id={`save-${id}`}
							variant='blue'
							className='rounded px-5'
							onClick={() => {
								onSave(input.trim())
								setInput('')
							}}
						>
							Save
						</Button>
					</div>
				</PopoverClose>
			</PopoverContent>
		</Popover>
	)
}
