import type { FundData } from 'interfaces'
import { DEFAULT_COMPANY_FORM_TEMPLATE } from '../data/default-template.data'

/**
 * @func getDefaultTemplate
 * Gets default template
 *
 * @param {String} postfix
 * - string fragment to append to template id to uniquely identify template
 * - string to append to template id to uniquely identify default template of a given fund.
 * @returns {ICustomCompanyFormTemplate | Object}
 */
export function getDefaultTemplate(id: string, fund: FundData) {
	const defaultTemplate = DEFAULT_COMPANY_FORM_TEMPLATE
	defaultTemplate.id = id

	defaultTemplate.fund = fund
	return defaultTemplate
}
