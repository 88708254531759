/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'

import CreateKanbanColumn from './AddKanbanColumn'
import DnDColumn from './DnDColumn'
import { DragDropContext } from 'react-beautiful-dnd'
import type { DropResult } from 'react-beautiful-dnd'
import { FunnelsService } from 'features/funnels/service/funnels.service'
import { Input } from 'components/ui/input'
import StrictModeDroppable from './Droppable'
import Skeleton from 'components/Skeleton'

export interface Column {
	id: string
	title: string
	index: number
	category?: string
	items: any[]
	pureItems: any[]
	isArchived?: boolean
}

interface BoardProps {
	columns: Column[]
	loading: boolean
	group: string
	itemsLoading: boolean
	onColumnAdd?: (column: Column) => void
	renderItem: (item: any, index: number) => JSX.Element
	onItemSameColumnMove: (items: any[]) => void
	allowSpaces?: boolean
	workspaces: string[]
	onItemDifferentColumnMove: ({
		item,
		dropResult
	}: {
		item: any
		dropResult: DropResult
	}) => void
	entity: 'company' | 'prospect' | 'task'
	allowSearch?: boolean
	searchKey?: string
	onUpdateFunnels?: (update: { index: number; id: string }[]) => void
}

export default function KanbanView({
	loading,
	itemsLoading,
	columns,
	onItemSameColumnMove,
	onItemDifferentColumnMove,
	renderItem,
	onColumnAdd,
	allowSpaces = false,
	group,
	workspaces,
	entity,
	allowSearch = false,
	onUpdateFunnels,
	searchKey = 'name'
}: BoardProps): JSX.Element {
	const [boardColumns, setBoardColumns] = useState<Column[]>(columns)
	const [searchVal, setSearchVal] = useState<string>('')

	const handleColumnDragEnd = (result: DropResult) => {
		const { source, destination } = result

		if (!destination) return
		if (!source || !destination) return

		const columnsCopy = structuredClone(columns)
		const [removedColumn] = columnsCopy.splice(source.index, 1)
		columnsCopy.splice(destination.index, 0, removedColumn)

		const startIndex = Math.min(destination.index, source.index)
		const endIndex = Math.max(destination.index, source.index)

		for (let i = startIndex; i <= endIndex; i += 1) {
			columnsCopy[i].index = i
		}

		const funnelMap = columnsCopy.map((col, index) => {
			return {
				id: col.id,
				index
			}
		})

		if (onUpdateFunnels) {
			onUpdateFunnels(funnelMap)
		} else {
			FunnelsService.updateFunnels(funnelMap)
		}

		setBoardColumns(columnsCopy)
	}

	const handleRowItemDragEnd = async (result: DropResult) => {
		const { source, destination } = result

		if (!destination) return

		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return
		}

		/**
		 * Move in same list
		 */
		if (source.droppableId === destination.droppableId) {
			const column = [...boardColumns].find(
				col => col.id === source.droppableId
			)

			if (column) {
				const newItems = [...column.items]
				const [removed] = newItems.splice(source.index, 1)
				newItems.splice(destination.index, 0, removed)

				const updatedColumns = boardColumns.map(col => {
					if (col.id === source.droppableId) {
						return {
							...col,
							items: newItems.map((item, index) => ({
								...item,
								index
							}))
						}
					}
					return col
				})

				setBoardColumns(updatedColumns)

				const startIndex = Math.min(destination.index, source.index)
				const endIndex = Math.max(destination.index, source.index)

				const itemsToUpdate: any[] = []

				for (let i = startIndex; i <= endIndex; i += 1) {
					itemsToUpdate.push({
						...newItems[i],
						index: i,
						lastUpdatedAt: new Date().toString()
					})
				}

				onItemSameColumnMove(itemsToUpdate)
			}

			return
		}

		/**
		 * Move between lists
		 *
		 */
		const sourceColumn = [...boardColumns].find(
			col => col.id === source.droppableId
		)
		const destinationColumn = [...boardColumns].find(
			col => col.id === destination.droppableId
		)

		if (sourceColumn && destinationColumn) {
			const sourceItems = [...sourceColumn.items]
			const destItems = [...destinationColumn.items]
			const [removed] = sourceItems.splice(source.index, 1)

			destItems.splice(destination.index, 0, {
				...removed,
				funnel: {
					id: destinationColumn.id,
					name: destinationColumn.title,
					category: destinationColumn.title.toLowerCase()
				}
			})

			const updatedColumns = [...boardColumns].map(col => {
				if (col.id === source.droppableId) {
					return {
						...col,
						items: sourceItems
					}
				}

				if (col.id === destination.droppableId) {
					return {
						...col,
						items: destItems
					}
				}

				return col
			})

			setBoardColumns(updatedColumns)

			onItemDifferentColumnMove({
				item: {
					...removed,
					funnel: {
						id: destinationColumn.id,
						name: destinationColumn.title,
						category: destinationColumn.title.toLowerCase()
					},
					lastUpdatedAt: new Date().toString()
				},
				dropResult: result
			})
		}
	}

	const handleDragEnd = (result: DropResult) => {
		if (result.type === 'column') {
			handleColumnDragEnd(result)
		} else {
			handleRowItemDragEnd(result)
		}
	}

	function handleOnColumnAdd(column: Column) {
		setBoardColumns([...boardColumns, column])
		onColumnAdd?.(column)
	}

	useEffect(() => {
		if (loading) return
		setBoardColumns(columns)
	}, [loading, columns, itemsLoading])

	// if (boardColumns.length === 0 && !loading) {
	// 	return <div className='mt-10'>
	// 		<div className='flex items-center justify-center h-full'>
	// 			<div className='text-center'>
	// 				<div className='text-lg font-semibold text-gray-800'>
	// 					No columns found
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>
	// }

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<div className='h-full whitespace-nowrap'>
				{allowSearch && (
					<div className='mt-8 flex items-end gap-3'>
						<Input
							placeholder='Search...'
							value={searchVal}
							onChange={e => {
								setSearchVal(e.target.value)

								for (const column of columns) {
									const filteredList = column.pureItems.filter(item => {
										return item[searchKey]
											.toLowerCase()
											.includes(e.target.value.toLowerCase())
									})

									column.items = filteredList
								}
							}}
							className='w-96 rounded-md bg-white'
						/>
					</div>
				)}
				{loading ? (
					<div className='flex h-[calc(100vh-5rem)] items-center gap-4 overflow-x-auto p-4 pt-8'>
						<Skeleton className='h-full min-w-[350px]' />
						<Skeleton className='h-full min-w-[350px]' />
						<Skeleton className='h-full min-w-[350px]' />
					</div>
				) : (
					<StrictModeDroppable
						droppableId='all-droppables'
						direction='horizontal'
						type='column'
					>
						{provided => (
							<div
								className='flex h-[calc(100vh-5rem)] space-x-4 overflow-x-auto py-5'
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								{boardColumns.map((column, index) => (
									<DnDColumn
										entity={entity}
										workspaces={workspaces}
										allowSpaces={allowSpaces}
										key={column.id}
										column={column}
										columnIndex={index}
										loadingItems={itemsLoading}
										renderItem={item => renderItem(item, index)}
									/>
								))}
								{provided.placeholder}
								{onColumnAdd && (
									<CreateKanbanColumn
										group={group}
										onAddColumn={handleOnColumnAdd}
										type={entity}
									/>
								)}
							</div>
						)}
					</StrictModeDroppable>
				)}
			</div>
		</DragDropContext>
	)
}
