import React, { useEffect } from 'react'

import { WithContext as ReactTags } from 'react-tag-input'
import type { Tag } from 'react-tag-input/types/components/SingleTag'
import clsxm from 'utils/clsxm'

const suggestions = [].map(country => {
	return {
		id: country,
		text: country,
		className: ''
	}
})

//  interface Tag {
//   id: string;
//   text: string;className: string;
// }

// const KeyCodes = {
// 	comma: 188,
// 	enter: [10, 13]
// }

function TagInput() {
	const [tags, setTags] = React.useState<Tag[]>([
		{ id: 'Thailand', text: 'Thailand', className: '' },
		{ id: 'India', text: 'India', className: '' },
		{ id: 'Vietnam', text: 'Vietnam', className: '' },
		{ id: 'Turkey', text: 'Turkey', className: '' }
	])

	const handleDelete = (index: number) => {
		setTags(tags.filter((_, i) => i !== index))
	}

	const onTagUpdate = (index: number, newTag: Tag) => {
		const updatedTags = [...tags]
		updatedTags.splice(index, 1, newTag)
		setTags(updatedTags)
	}

	const handleAddition = (tag: Tag) => {
		setTags(prevTags => {
			return [...prevTags, tag]
		})
	}

	useEffect(() => {}, [tags])

	const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
		const newTags = [...tags]

		newTags.splice(currPos, 1)
		newTags.splice(newPos, 0, tag)

		// re-render
		setTags(newTags)
	}

	// eslint-disable-next-line unicorn/consistent-function-scoping
	const handleTagClick = (index: number) => {
		console.log(`The tag at index ${index} was clicked`)
	}

	const onClearAll = () => {
		setTags([])
	}

	return (
		<div className='app'>
			<ReactTags
				tags={tags}
				suggestions={suggestions}
				classNames={
					{
						tag: clsxm(
							'bg-primary text-white hover:bg-gray-400 hover:text-white py-1 text-sm capitalize rounded-full pl-3 pr-2 mr-2'
						),
						tags: clsxm(''),
						tagInput: clsxm('flex mt-2 mr-2'),
						tagInputField: clsxm(
							'flex-1 border-gray-300 px-3 py-1 rounded placeholder:text-xs'
						),
						remove: clsxm('ml-2 !text-black'),
						clearAll: clsxm('ml-2 text-xs')
					} as never
				}
				placeholder='enter comma separated tags'
				// separators={[SEPARATORS.ENTER, SEPARATORS.COMMA]}
				handleDelete={handleDelete}
				handleAddition={handleAddition}
				handleDrag={handleDrag}
				handleTagClick={handleTagClick}
				onTagUpdate={onTagUpdate}
				inputFieldPosition='bottom'
				editable
				// shouldRenderSuggestions={r => <>{r}</>}
				clearAll
				onClearAll={onClearAll}
			/>
		</div>
	)
}

export default TagInput
