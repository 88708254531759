/* eslint-disable consistent-return */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-continue */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-shadow */
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import {
	Building2,
	Calendar,
	CaseLower,
	Check,
	CircleDollarSign,
	Command,
	Copy,
	EyeOff,
	Filter,
	Globe,
	HandCoins,
	Info,
	Link2,
	Linkedin,
	LocateIcon,
	Mail,
	MoreHorizontal,
	Option,
	Percent,
	PlusCircleIcon,
	SquareGanttChart,
	TagsIcon,
	User,
	Zap,
	ZapIcon
} from 'lucide-react'
import {
	CaretDownIcon,
	CaretSortIcon,
	CaretUpIcon
} from '@radix-ui/react-icons'
import type { Cell, Column, ColumnDef } from '@tanstack/react-table'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger
} from 'components/ui/dropdown-menu'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import {
	deleteCompany,
	updateCompany
} from 'features/companies/api/companies.api'
import { displayAmount, getAmount } from 'utils/currency'

import { Button } from 'components/ui/button'
import { ColumnsService } from 'features/columns/sevice/column.service'
import CompanyHoverCard from 'components/CompanyHoverCard'
import DeleteModal from 'components/DeleteModal'
import type { ICompany } from 'interfaces'
import type { ITableColumn } from '../interfaces/datatable.interface'
import type { Column as KanbanViewColumn } from 'features/kanban/views/KanbanView'
import { Link } from 'react-router-dom'
import { LinkPreview } from 'components/ui/linkpreview'
import Logger from 'lib/logger'
import SpokDatePicker from 'components/shadcn/SpokDatePicker'
import SpokInlineInputEditor from 'components/shadcn/SpokInlineInputEditor'
import Tags from 'components/Tags'
import UpdateLocation from 'features/companies/view/UpdateLocation'
import { currencyFormatter } from 'utils/currencyformatter.util'
import { getDomain } from '../helpers/datatablehook.helper'
import { toast } from 'sonner'
import uniqolor from 'uniqolor'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import useCompanyFunnels from 'features/companies/hooks/useCompanyFunnels'
import { listenToIndustries } from 'features/industries/api/industries.api'
import { useAppSelector } from 'app/hooks'
import { selectIndustries } from 'features/industries/redux/industries.slice'
import { useEffect } from 'react'
import CustomColumnAPI from '../api/columns.api'
import { getValueByDataKey } from 'recharts/types/util/ChartUtils'

interface Props {
	columnData: ITableColumn[]
	detailsRoute?: string
	meta?: {
		type?: 'group-list'
		columns?: KanbanViewColumn[]
	}
	onItemUpdate?: (item: any, key: string, value: any) => void
}

function onToggleExpanded(cell: Cell<ICompany, unknown>) {
	try {
		cell.row.toggleExpanded(!cell.row.getIsExpanded())
	} catch (error) {
		Logger.error(error)
	}
}

function onChangeVisibility(columnId: string, isVisible: boolean) {
	ColumnsService.editColumn(columnId, { isVisible })
}

function generateCompanyListCTAIcon(dataType: string) {
	switch (dataType) {
		case 'name': {
			return <User width={20} height={20} className='text-blue-700' />
		}
		case 'email': {
			return <Mail width={20} height={20} className='text-blue-700' />
		}
		case 'linkedIn': {
			return <Linkedin width={20} height={20} className='text-blue-700' />
		}
		case 'company': {
			return <Building2 width={20} height={20} className='text-blue-700' />
		}
		case 'tags': {
			return <TagsIcon width={20} height={20} className='text-blue-700' />
		}
		case 'website': {
			return <Globe width={20} height={20} className='text-blue-700' />
		}
		case 'link': {
			return <Link2 width={20} height={20} className='text-blue-700' />
		}
		case 'connectionStrength': {
			return <ZapIcon width={20} height={20} className='text-blue-700' />
		}
		case 'location': {
			return <LocateIcon width={20} height={20} className='text-blue-700' />
		}
		case 'date': {
			return <Calendar width={20} height={20} className='text-blue-700' />
		}
		case 'string': {
			return <CaseLower width={20} height={20} className='text-blue-700' />
		}
		case 'text': {
			return (
				<SquareGanttChart width={20} height={20} className='text-blue-700' />
			)
		}
		case 'funnel': {
			return <Filter width={20} height={20} className='text-blue-700' />
		}
		case 'amount': {
			return (
				<CircleDollarSign width={20} height={20} className='text-blue-700' />
			)
		}
		case 'amount-converted': {
			return <HandCoins width={20} height={20} className='text-blue-700' />
		}
		case 'percentage': {
			return <Percent width={20} height={20} className='text-blue-700' />
		}
		case 'cta': {
			return <Option width={20} height={20} className='text-blue-700' />
		}
		default: {
			return <Command width={20} height={20} className='text-blue-700' />
		}
	}
}

function companyListCTADropdownMenu(columns: ITableColumn[]) {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button className='w-full truncate px-0' variant='ghost'>
					<PlusCircleIcon className='ml-2 h-4 w-4' />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className='w-56' align='end' forceMount>
				<DropdownMenuGroup>
					{columns &&
						columns.length > 0 &&
						columns.map(
							column =>
								column.name !== 'CTA' &&
								!column.isHidden && (
									<DropdownMenuItem
										key={column.id}
										onClick={() => {
											ColumnsService.editColumn(column.id, {
												isVisible: !column.isVisible
											})
										}}
									>
										<div className='flex w-full items-center justify-between'>
											<div className='flex items-center'>
												{generateCompanyListCTAIcon(column.dataType)}
												<span className='ml-3'>{column.name}</span>
											</div>
											<div>
												{column.isVisible && <Check width={16} height={16} />}
											</div>
										</div>
									</DropdownMenuItem>
								)
						)}
				</DropdownMenuGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}


function companyListRowCTADropdownMenu(
	company: ICompany,
	meta: { linkRoute: string; columns: KanbanViewColumn[] }
) {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button className='w-full truncate px-0' variant='ghost'>
					<MoreHorizontal className='ml-2 h-4 w-4' />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className='w-56' align='end' forceMount>
				<DropdownMenuGroup>
					<DropdownMenuSub>
						<DropdownMenuSubTrigger>Move to</DropdownMenuSubTrigger>
						<DropdownMenuPortal>
							<DropdownMenuSubContent>
								{meta.columns.map(
									data =>
										company.funnel?.id !== data.id && (
											<DropdownMenuItem
												key={data.id}
												onClick={() =>
													updateCompany({
														...company,
														funnel: {
															category: data.category || '',
															id: data.id,
															name: data.title
														}
													})
												}
											>
												{data.title}
											</DropdownMenuItem>
										)
								)}
							</DropdownMenuSubContent>
						</DropdownMenuPortal>
					</DropdownMenuSub>
					<DropdownMenuItem
						onClick={() =>
							window
								.open(meta.linkRoute, '_blank', 'noopener, noreferrer')
								?.focus()
						}
					>
						Open in new window
					</DropdownMenuItem>
					<DropdownMenuItem
						onClick={e => {
							e.stopPropagation()
							e.preventDefault()
						}}
					>
						<DeleteModal
							deleteButtonId={`delete-company-${company.id}`}
							title='Delete Company'
							description={`Are you sure you want to delete ${company.name}?`}
							triggerClassName='w-full'
							trigger={<div className='w-full text-red-400'>Delete</div>}
							onDelete={() => deleteCompany(company.id)}
						/>
					</DropdownMenuItem>
				</DropdownMenuGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

function companyGroupListDropdown(
	column: Column<any>,
	columnData: ITableColumn
) {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button className='w-full truncate px-0' variant='ghost'>
					{columnData.name}
					<CaretDownIcon className='ml-2 h-4 w-4' />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className='w-56' align='end' forceMount>
				<DropdownMenuGroup>
					<DropdownMenuItem
						onClick={() => {
							column.toggleSorting(column.getIsSorted() === 'asc')
						}}
					>
						<div className='flex items-center'>
							<CaretSortIcon width='16' height='16' />
							<span className='ml-3'>Sort</span>
						</div>
					</DropdownMenuItem>
					<DropdownMenuItem
						onClick={() =>
							onChangeVisibility(columnData.id, !columnData.isVisible)
						}
					>
						<div className='flex items-center'>
							<EyeOff width='16' height='16' color='red' />
							<span className='ml-3'>Hide</span>
						</div>
					</DropdownMenuItem>
				</DropdownMenuGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

export default function useDataTableHook({
	columnData,
	detailsRoute,
	meta,
	onItemUpdate
}: Props) {
	// console.log('columnData', columnData)

	const { funnels } = useCompanyFunnels()
	const { listenToColumns, columns } = CustomColumnAPI();

	useEffect(() => {
		const unsubscribe = listenToColumns('company')
		return () => unsubscribe()
	}, [])

	useEffect(() => {
		const unsubscribe = listenToIndustries()
		return () => {
			unsubscribe()
		}
	}, [])

	const allIndustries = useAppSelector(selectIndustries)

	function generateTableColumn() {
		const column: ColumnDef<any>[] = []

		const customDropdown = [] as any
		const customLabel = [] as any

		for (const col of columns) {
			if (col.dataType === 'custom_dropdown') customDropdown.push(col)
			if (col.dataType === 'custom_label') customLabel.push(col)
		}

		for (const col of columnData) {

			if (!col.isVisible) continue

			switch (col.dataType) {
				case 'name': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}

								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row, cell }) => (
							<div className='flex items-center gap-2'>
								{meta?.type === 'group-list' && cell.row.getCanExpand() && (
									<Button
										variant='ghost'
										onClick={() => onToggleExpanded(cell)}
									>
										<div className='mr-6'>
											{cell.row.getIsExpanded() ? (
												<CaretUpIcon />
											) : (
												<CaretDownIcon />
											)}
										</div>
										<div
											style={{
												backgroundColor: uniqolor(cell.row.original.funnel.name)
													.color
											}}
											className='flex items-center justify-around rounded-md px-4 py-3 text-white'
										>
											<span className='mr-5'>
												{cell.row.original.funnel.name}
											</span>{' '}
											<span>{cell.row.subRows.length}</span>
										</div>
									</Button>
								)}
								<span>{row.index + 1}.</span>
								{!row.getCanExpand() && detailsRoute === 'company' && (
									<Avatar className='h-6 w-6 border'>
										<AvatarImage
											src={
												row.original.website
													? `https://www.google.com/s2/favicons?sz=128&domain_url=${row.original.website}`
													: '/images/logo/Logo-4.png'
											}
										/>
										<AvatarFallback>C</AvatarFallback>
									</Avatar>
								)}
								{detailsRoute === 'limited-partner' && (
									<Avatar className='h-6 w-6 border'>
										<AvatarImage
											src={row.original.photoUrl}
											className='object-cover'
										/>
										<AvatarFallback>
											{row.original.name.charAt(0)}
										</AvatarFallback>
									</Avatar>
								)}

								{!row.getCanExpand() && (
									<SpokInlineInputEditor
										isReadOnly={!col.canBeEdited}
										title={col.name}
										content={
											<div className='truncate'>
												<Link
													data-testid={`name-${cell.row.original.name}`}
													to={`/${detailsRoute}/${cell.row.original.id}`}
													className='ml-3 flex items-center truncate capitalize text-blue-700 underline'
												>
													{cell.row.original.name}
												</Link>
											</div>
										}
										onSave={value => {
											onItemUpdate?.(row.original, col.key, value)
										}}
										defaultValue={row.original[col.key]}
									/>
								)}
							</div>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})

					break
				}
				case 'email': {
					column.push({
						accessorKey: col.key,
						sortingFn: 'text',
						id: col.key,
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<div className='flex items-center gap-1 truncate lowercase'>
								<SpokInlineInputEditor
									multiLine
									isReadOnly={!col.canBeEdited}
									title={col.name}
									content={
										<div className=' flex max-w-96 gap-2 truncate'>
											{detailsRoute === 'contact' ? (
												<Link
													to={`/${detailsRoute}/${row.original.id}`}
													className='ml-3 flex items-center truncate text-blue-700 underline'
												>
													{((row.original.email?.length ?? 0) > 50
														? `${row.original.email?.slice(0, 50)}`
														: row.original.email) ?? '-'}
												</Link>
											) : (
												((row.original.email?.length ?? 0) > 50
													? `${row.original.email?.slice(0, 50)}`
													: row.original.email) ?? '-'
											)}
											{row.original.email && (
												<Button
													variant='ghost'
													className='px-1 py-1'
													onClick={() => {
														navigator.clipboard.writeText(
															row.original.email || ''
														)
														toast.success('Email copied to clipboard')
													}}
												>
													<Copy size={12} />
												</Button>
											)}
										</div>
									}
									onSave={value => {
										onItemUpdate?.(row.original, col.key, value)
									}}
									defaultValue={row.original[col.key]}
								/>
							</div>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})

					break
				}
				case 'linkedIn': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<div className='flex items-center gap-1 truncate lowercase'>
								{!row.getCanExpand() &&
									(((row.original[col.key]?.length ?? 0) > 50
										? `${row.original[col.key]?.slice(0, 50)}`
										: row.original[col.key]) ||
										'-')}

								{!row.getCanExpand() && row.original[col.key] && (
									<Button
										variant='ghost'
										className='px-1 py-1'
										onClick={() => {
											navigator.clipboard.writeText(row.original[col.key] || '')
											toast.success('LinkedIn copied to clipboard')
										}}
									>
										<Copy size={12} />
									</Button>
								)}
							</div>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})

					break
				}
				case 'company': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<div className='truncate lowercase'>
								{row.original.company?.id ? (
									<CompanyHoverCard company={row.original.company} />
								) : (
									'-'
								)}
							</div>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})

					break
				}
				case 'tags': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<div className=''>
								{meta?.type === 'group-list' && !row.getCanExpand() && (
									<Tags tags={row.original?.[col.key] || []} />
								)}

								{meta?.type !== 'group-list' &&
									(row.original[col.key] ? (
										<Tags tags={row.original[col.key]} />
									) : (
										'-'
									))}
							</div>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})

					break
				}
				case 'website': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row, cell }) => (
							<>
								{!cell.row.getCanExpand() &&
									(row.original[col.key] ? (
										<LinkPreview url={row.original[col.key]}>
											<div
												className='cursor-pointer truncate lowercase text-blue-700'
												onClick={() =>
													window.open(
														row.original[col.key],
														'_blank',
														'noopener, noreferrer'
													)
												}
											>
												{getDomain(
													(row.original[col.key]?.length ?? 0) > 50
														? `${row.original[col.key]?.slice(0, 50)}`
														: row.original[col.key]
												) || '-'}
											</div>
										</LinkPreview>
									) : (
										'-'
									))}
							</>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})

					break
				}
				case 'link': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<>
								<SpokInlineInputEditor
									multiLine
									isReadOnly={!col.canBeEdited}
									title={col.name}
									content={
										<div>
											{!row.getCanExpand() &&
												(row.original[col.key] ? (
													<LinkPreview url={row.original[col.key]}>
														<div
															className='cursor-pointer truncate lowercase text-blue-700'
															onClick={() =>
																window.open(
																	row.original[col.key],
																	'_blank',
																	'noopener, noreferrer'
																)
															}
														>
															{getDomain(
																(row.original[col.key]?.length ?? 0) > 50
																	? `${row.original[col.key]?.slice(0, 50)}`
																	: row.original[col.key]
															) || '-'}
														</div>
													</LinkPreview>
												) : (
													'-'
												))}
										</div>
									}
									onSave={value => {
										onItemUpdate?.(row.original, col.key, value)
									}}
									defaultValue={row.original[col.key]}
								/>
							</>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})

					break
				}
				case 'connectionStrength': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: (rowA, rowB) => {
							const a =
								rowA.original.connectionStrength === undefined
									? Number.POSITIVE_INFINITY
									: rowA.original.connectionStrength
							const b =
								rowB.original.connectionStrength === undefined
									? Number.POSITIVE_INFINITY
									: rowB.original.connectionStrength
							return a - b
						},
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<div className='flex items-center gap-1 lowercase'>
								<Zap
									size={15}
									color={
										Number(row.original[col.key]) > 80
											? 'green'
											: (Number(row.original[col.key]) > 50
												? 'blue'
												: 'red')
									}
								/>
								{row.original[col.key] ? `${row.original[col.key]}/100` : '-'}
								<div className='flex-1' />
								<Button variant='ghost' className='p-2' onClick={() => { }}>
									<Info size={12} color='gray' />
								</Button>
							</div>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})

					break
				}
				case 'location': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<UpdateLocation
								isReadOnly={!col.canBeEdited}
								id={row.original.id}
								title={col.name}
								description={col.name}
								location={row.original?.location}
								onUpdate={location => {
									onItemUpdate?.(row.original, col.key, location)
								}}
							/>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})

					break
				}
				case 'date': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: 'datetime',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<div className='capitalize'>
								{/* <SpokDatePicker
									isReadOnly={!col.canBeEdited}
									defaultValue={row.original[col.key] || 0}	
									content={row.original[col.key] ? null : '-'}
									onSelect={(value) => {
										onItemUpdate?.(row.original, col.key, value)
									}}
								/> */}
							</div>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})

					break
				}
				case 'string': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row, cell }) => (
							<>
								{meta?.type === 'group-list' &&
									!cell.row.getCanExpand() &&
									cell.row.original[col.key]}

								{meta?.type !== 'group-list' && (
									<SpokInlineInputEditor
										multiLine
										isReadOnly={!col.canBeEdited}
										title={col.name}
										content={
											<div className=' max-w-96 truncate'>
												{row.original[col.key]}
											</div>
										}
										onSave={value => {
											onItemUpdate?.(row.original, col.key, value)
										}}
										defaultValue={row.original[col.key]}
									/>
								)}
							</>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})
					break
				}
				case 'text': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<>
								{!row.getCanExpand() && (
									<Tooltip>
										<TooltipTrigger asChild>
											<SpokInlineInputEditor
												multiLine
												isReadOnly={!col.canBeEdited}
												title={col.name}
												content={
													<div className=' max-w-96 truncate'>
														{row.original[col.key]}
													</div>
												}
												onSave={value => {
													onItemUpdate?.(row.original, col.key, value)
												}}
												defaultValue={row.original[col.key]}
											/>
										</TooltipTrigger>
										<TooltipContent>
											<div className='w-96'>{row.original[col.key]}</div>
										</TooltipContent>
									</Tooltip>
								)}
							</>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})
					break
				}
				case 'funnel': {
					column.push({
						id: col.key,
						accessorKey: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<div className='truncate'>{row.original.funnel.name}</div>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})
					break
				}
				case 'amount': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: (rowA, rowB) => {
							const a = getAmount(rowA.original[col.key] || [], 'USD') || 0
							const b = getAmount(rowB.original[col.key] || [], 'USD') || 0
							return a > b ? 1 : (a < b ? -1 : 0)
						},
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<div className='truncate'>
								{/* {!row.getCanExpand() &&
									currencyFormatter('USD').format(
										getAmount(row.original[col.key] || [], 'USD')
									)} */}

								<SpokInlineInputEditor
									isReadOnly={!col.canBeEdited}
									title={col.name}
									content={
										<div className='max-w-96 truncate'>
											{currencyFormatter('USD').format(
												getAmount(row.original[col.key] || [], 'USD')
											)}
										</div>
									}
									onSave={value => {
										onItemUpdate?.(row.original, col.key, value)
									}}
									defaultValue={currencyFormatter('USD').format(
										getAmount(row.original[col.key] || [], 'USD')
									)}
								/>
							</div>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})
					break
				}
				case 'amount-converted': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: (rowA, rowB) => {
							const a = rowA.original[`${col.key}Converted`].amount || 0
							const b = rowB.original[`${col.key}Converted`].amount || 0
							return b - a
						},
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<div className='truncate'>
								{/* {!row.getCanExpand() &&
									displayAmount(row.original[`${col.key}Converted`])} */}

								<SpokInlineInputEditor
									isReadOnly={!col.canBeEdited}
									title={col.name}
									content={
										<div className='max-w-96 truncate'>
											{displayAmount(row.original[`${col.key}Converted`])}
										</div>
									}
									onSave={value => {
										onItemUpdate?.(row.original, col.key, value)
									}}
									defaultValue={displayAmount(
										row.original[`${col.key}Converted`]
									)}
								/>
							</div>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})
					break
				}
				case 'percentage': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: (rowA, rowB) => {
							const a = rowA.original[col.key] || 0
							const b = rowB.original[col.key] || 0
							return b - a
						},
						header: ({ column }) => (
							<Button
								className='px-0'
								variant='ghost'
								onClick={() =>
									column.toggleSorting(column.getIsSorted() === 'asc')
								}
							>
								{col.name}
								<CaretSortIcon className='ml-2 h-4 w-4' />
							</Button>
						),
						cell: ({ row }) => (
							<div className='truncate'>
								{(!row.getCanExpand() &&
									Number(row.original[col.key]).toFixed(1)) ||
									'0'}{' '}
								{!row.getCanExpand() && '%'}
							</div>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})
					break
				}
				case 'select': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<>
								<div className='truncate'>
									{col.key === 'status' &&
										<ComboSelect
											isReadOnly={false}
											onSelect={value => {
												const funnel = funnels.find(f => f.id === value)
												onItemUpdate?.(row.original, col.key, funnel?.name)
											}}
											defaultValue={row.original.company?.funnel?.id as string}
											content={
												<div className='flex items-center gap-2 pr-2'>
													<div className='text-sm'>{row.original[col.key] || '-'}</div>
												</div>
											}
											options={funnels.map(funnel => ({
												value: funnel.id,
												label: funnel.name
											}))}
										/>
									}

									{col.key === 'category' &&
										<ComboSelect
											isReadOnly={false}
											onSelect={value => {
												const industry = allIndustries.find(f => f.id === value)
												onItemUpdate?.(row.original, col.key, industry?.industry)
											}}
											defaultValue={row.original.company?.industry?.id as string}
											content={
												<div className='flex items-center gap-2 pr-2'>
													<div className='text-sm'>{row.original[col.key] || '-'}</div>
												</div>
											}
											options={allIndustries.map(industry => ({
												value: industry.id,
												label: industry.industry
											}))}
										/>}
								</div>
							</>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})

					break
				}
				case 'custom_dropdown': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<>
								<ComboSelect
									isReadOnly={false}
									onSelect={value => {
										onItemUpdate?.(row.original, col.key, value)
									}}
									defaultValue={row.original.company?.funnel?.id as string}
									content={
										<div className='flex items-center gap-2 pr-2'>
											<div className='text-sm'>{row.original[col.key] || '-'}</div>
										</div>
									}
									options={customDropdown.flatMap((col: { options: any[] }) =>
										col.options.map((c) => ({
											value: c,
											label: c
										}))
									)}
								/>
							</>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})

					break
				}
				case 'custom_label': {
					column.push({
						accessorKey: col.key,
						id: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row }) => (
							<>
								<ComboSelect
									isReadOnly={false}
									onSelect={value => {
										onItemUpdate?.(row.original, col.key, value)
									}}
									defaultValue={row.original.company?.funnel?.id as string}
									content={
										<div className='flex items-center gap-2 pr-2'>
											<div className='text-sm'>{row.original[col.key] || '-'}</div>
										</div>
									}
									options={customLabel.flatMap((col: { options: any[] }) =>
										col.options.map((c) => ({
											value: c,
											label: c
										}))
									)}
								/>
							</>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})

					break
				}
				default: {
					column.push({
						accessorKey: col.key,
						sortingFn: 'text',
						header: ({ column }) => (
							<>
								{meta?.type === 'group-list' &&
									companyGroupListDropdown(column, col)}
								{meta?.type !== 'group-list' && (
									<Button
										className='truncate px-0'
										variant='ghost'
										onClick={() =>
											column.toggleSorting(column.getIsSorted() === 'asc')
										}
									>
										{col.name}
										<CaretSortIcon className='ml-2 h-4 w-4' />
									</Button>
								)}
							</>
						),
						cell: ({ row, cell }) => (
							<div className=''>
								{/* {!cell.row.getCanExpand() &&
									((typeof row.original[col.key] === 'string' &&
										row.original[col.key]) ||
										'-')} */}

								<SpokInlineInputEditor
									multiLine
									isReadOnly={!col.canBeEdited}
									title={col.name}
									content={
										<div className=' max-w-96 truncate'>
											{row.original[col.key]}
										</div>
									}
									onSave={value => {
										onItemUpdate?.(row.original, col.key, value)
									}}
									defaultValue={row.original[col.key] || '-'}
								/>
							</div>
						),
						enableHiding: col.canBeHidden,
						enableSorting: col.canBeSorted
					})
				}
			}
		}

		if (meta?.type === 'group-list') {
			column.push({
				id: 'cta',
				header: () => <> {companyListCTADropdownMenu(columnData)} </>,
				cell: ({ row }) => (
					<div className='truncate'>
						{' '}
						{!row.getCanExpand() &&
							companyListRowCTADropdownMenu(row.original, {
								linkRoute: `${window.location.protocol}//${window.location.host}/${detailsRoute}/${row.original.id}`,
								columns: meta.columns || []
							})}{' '}
					</div>
				)
			})
		}

		return column
	}

	return {
		columns: generateTableColumn(),
		columnData
	}
}
