/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
import { CaretSortIcon } from '@radix-ui/react-icons'
import type { ColumnDef } from '@tanstack/react-table'
import Button from 'components/Buttons/Button'
import DeleteModal from 'components/DeleteModal'
import { Badge } from 'components/ui/badge'
import { DataTable } from 'features/dataTable/DataTable'
import type { ICryptoAccount } from '../interface/cryptoAccount.interface'

interface ICapitalCallRequestListProps {
	className?: string
	data: ICryptoAccount[]
	onClickEdit: (bank: ICryptoAccount) => void
	onDelete: (bank: ICryptoAccount) => void
	onClickSetDefault: (bank: ICryptoAccount) => void
}

export default function CryptoAccountList({
	className,
	data,
	onClickEdit,
	onDelete,
	onClickSetDefault
}: ICapitalCallRequestListProps): JSX.Element {
	const columns: ColumnDef<ICryptoAccount>[] = [
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Account Name
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>
					{row.original.isDefault && (
						<Badge variant='default' className='mr-3'>
							{' '}
							Default{' '}
						</Badge>
					)}

					{row.original.accountName}
				</div>
			)
		},
		{
			accessorKey: 'description',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Description
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>{row.original.description}</div>
			)
		},
		{
			accessorKey: 'walletAddress',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Wallet Address
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>{row.original.walletAddress}</div>
			)
		},
		{
			accessorKey: 'network',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Network
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => <div className='truncate'>{row.original.network}</div>
		},
		{
			accessorKey: 'stableCoin',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Stable Coin
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>{row.original.stableCoin}</div>
			)
		},
		{
			accessorKey: 'cta',
			maxSize: 150,
			minSize: 150,
			header: () => '',
			cell: ({ row }) => (
				<div className='flex items-center'>
					{!row.original.isDefault && (
						<Button
							id='deleteBankAccount'
							variant='outline'
							className='px-2'
							onClick={() => onClickSetDefault(row.original)}
						>
							Set As Default
						</Button>
					)}

					<Button
						id='deleteCryptoAccount'
						variant='ghost'
						className='px-2'
						onClick={() => onClickEdit(row.original)}
					>
						Edit
					</Button>

					<DeleteModal
						deleteButtonId='deleteCryptoAccount'
						title='Delete Crypto Account'
						description={`Are you sure you want to delete ${row.original.accountName}?`}
						trigger={
							<Button
								id='deleteCryptoAccount'
								variant='ghost'
								className='px-2 text-red-500'
							>
								Delete
							</Button>
						}
						onDelete={() => onDelete(row.original)}
					/>
				</div>
			)
		}
	]

	const isLoading = false

	return (
		<div className={`${className} flex flex-col`}>
			<DataTable
				entity='crypto'
				columns={columns}
				data={data}
				loading={isLoading}
				allowSearch={false}
				filterPlaceholder='Search....' onColumnUpdate={function (updatedColumns: any): void {
					throw new Error('Function not implemented.')
				} } onColumnVisibilityChange={function (columnId: string, isVisible: boolean): void {
					throw new Error('Function not implemented.')
				} } 
				isGrouped={false}			/>
		</div>
	)
}
