/* eslint-disable react/display-name */
import * as React from 'react'

import { ImSpinner10 } from 'react-icons/im'
import clsxm from 'utils/clsxm'

enum ButtonVariant {
	'primary',
	'danger',
	'outline',
	'ghost',
	'light',
	'dark',
	'success',
	'gray',
	'cancel',
	'blue'
}

type ButtonProperties = React.ComponentPropsWithRef<'button'> & {
	isLoading?: boolean
	isDarkBg?: boolean
	variant?: keyof typeof ButtonVariant
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProperties>(
	(
		{
			children,
			className,
			disabled: buttonDisabled,
			isLoading,
			variant = 'blue',
			isDarkBg = false,
			...rest
		},
		reference
	) => {
		const disabled = isLoading || buttonDisabled

		return (
			<button
				ref={reference}
				type='button'
				data-testid={rest.id}
				disabled={disabled}
				className={clsxm(
					'inline-flex items-center rounded-md text-sm font-semibold',
					'focus-visible:ring-primary-500 focus:outline-none focus-visible:ring',
					'relative cursor-pointer px-2 shadow-sm',
					'transition-colors duration-75',
					isLoading ? 'px-4 py-1' : 'px-6 py-2',
					// #region  //*=========== Variants ===========
					[
						variant === 'primary' && [
							'bg-primary-green text-white',
							'dark:border-gray-800 ',
							// 'border border-primary-600',
							// 'hover:bg-primary-50 hover:text-white',
							'active:bg-primary-50',
							'disabled:opacity-50'
						],
						variant === 'gray' && [
							'text-gray bg-[#F9F9FB]',
							'dark:bg-[#374151] dark:text-white',
							'hover:bg-primary-50 hover:text-white',
							'active:bg-primary-50',
							'disabled:bg-primary-50 disabled:hover:bg-primary-400'
						],
						variant === 'danger' && [
							'bg-red-500 px-2 py-1 text-white',
							'border border-red-500',
							'hover:bg-red-500 hover:text-white',
							'active:bg-red-500',
							'disabled:border-red-100 disabled:bg-red-100 disabled:hover:bg-red-100'
						],
						variant === 'success' && [
							'bg-green-600 text-white',
							'border border-green-600',
							'hover:bg-green-600 hover:text-white',
							'active:bg-green-600',
							' disabled:bg-green-100 disabled:hover:bg-green-100'
						],
						variant === 'outline' && [
							'px-2 py-1 text-primary',
							'border border-primary',
							'hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100',
							isDarkBg &&
								'hover:bg-gray-900 active:bg-gray-800 disabled:bg-gray-800'
						],
						variant === 'ghost' && [
							'text-gray-700 ',
							' bg-transparent px-2 shadow-none hover:bg-gray-100',
							isDarkBg &&
								'hover:bg-gray-900 active:bg-gray-800 disabled:bg-gray-800'
						],
						variant === 'light' && [
							'bg-primary-100 text-primary-50 ',
							'border border-gray-300',
							'hover:text-dark hover:bg-gray-100',
							'active:bg-white/80 disabled:bg-gray-200'
						],
						variant === 'dark' && [
							'border-none bg-gray-900 px-2 py-1 text-white',
							'border border-gray-600',
							'hover:bg-gray-800 active:bg-gray-700 disabled:bg-gray-700'
						],
						variant === 'cancel' && [
							'bg-transparent px-2 py-1 text-red-500',
							'border border-red-500',
							'hover:bg-transparent active:bg-transparent disabled:bg-gray-700'
						],
						variant === 'blue' && [
							'bg-blue-600 px-2 py-1 text-white',
							'dark:bg-[#0088FE] dark:text-white',
							'hover:bg-[#0088FE] active:bg-[#0088FE]',
							'disabled:bg-gray-700'
						]
					],
					// #endregion  //*======== Variants ===========
					'flex justify-center disabled:cursor-not-allowed',
					isLoading &&
						'px-0 text-transparent transition-none hover:text-transparent disabled:cursor-wait',
					className
				)}
				{...rest}
			>
				{isLoading ? (
					<div
						className={clsxm(
							'absolute left-1/2 top-1/2 mx-auto -translate-x-1/2 -translate-y-1/2',
							{
								'text-white': ['primary', 'dark'].includes(variant),
								'text-black': ['light'].includes(variant),
								'text-primary-500': ['outline', 'ghost'].includes(variant)
							}
						)}
					>
						<ImSpinner10
							className={clsxm(
								'h-4 w-4 animate-spin text-white',
								variant === 'outline' ? 'text-primary' : ''
							)}
						/>
					</div>
				) : null}
				<span
					className={clsxm('flex items-center gap-1', {
						'opacity-0': isLoading
					})}
				>
					{children}
				</span>
			</button>
		)
	}
)

export default Button
