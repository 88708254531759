import * as Yup from 'yup'

import { addContact, updateContact } from '../api/contacts.api'

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthContext } from 'context/AuthContext'
import { ContactService } from '../services/contact.service'
import type { IContact } from 'interfaces'
import { nanoid } from 'nanoid'
import { toast } from 'sonner'
import { useContext } from 'react'
import { useFormik } from 'formik'

export const useCreateContact = ({
	contact,
	onSuccess
}: {
	contact?: IContact
	onSuccess?: () => void
}) => {
	const { authUser } = useContext(AuthContext)

	const validarionSchema = Yup.object({
		name: Yup.string().required('Name is required'),
		email: Yup.string()
			.email('Invalid email')
			.required('Email is required')
			.test('email-unique', 'Email already exists', async value => {
				if (!value) return true

				const exists = await ContactService.contactExists(
					authUser?.fund.id ?? '',
					value
				)
				if (exists) return false

				return true
			}),
		linkedin: Yup.string(),
		phone: Yup.string(),
		website: Yup.string().url('Enter a valid url')
	})

	const formik = useFormik({
		initialValues: {
			name: contact?.name,
			email: contact?.email,
			linkedin: contact?.linkedIn,
			phone: contact?.phone,
			website: contact?.website,
			country: contact?.location?.country,
			state: contact?.location?.state,
			city: contact?.location?.city,
			company: ''
		},
		validationSchema: validarionSchema,
		onSubmit: async values => {
			if (!authUser?.fund) return

			try {
				formik.setSubmitting(true)

				const contactData: IContact = {
					id: contact?.id ?? nanoid(),
					name: values.name ?? '',
					fund: authUser.fund,
					email: values.email,
					linkedIn: values.linkedin ?? '',
					phone: values.phone ?? '',
					location: {
						country: values.country ?? '',
						state: values.state ?? '',
						city: values.city ?? ''
					},
					website: values.website ?? '',
					createdAt: new Date().toISOString(),
					lastUpdatedAt: new Date().toISOString()
				}

				await (contact ? updateContact(contactData) : addContact(contactData))

				toast.success(contact ? 'Contact updated' : 'Contact added')

				if (onSuccess) onSuccess()
			} catch (error: any) {
				toast.error(error.message)
			} finally {
				formik.setSubmitting(false)
				formik.resetForm()
			}
		}
	})

	return { formik }
}
