/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { ICalendarEvent } from 'features/companies/interfaces/calendarEvent.interface'

export interface CalendarEventsSliceState {
	data: ICalendarEvent[]
	isLoading: boolean
	error?: string | null
}

const initialState: CalendarEventsSliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const calendarEventsSlice = createAppSlice({
	name: 'calendarEvents',
	initialState,
	reducers: create => ({
		setCalendarEventsLoading: create.reducer(
			(state, action: PayloadAction<boolean>) => {
				state.isLoading = action.payload
			}
		),
		setCalendarEvents: create.reducer(
			(state, action: PayloadAction<ICalendarEvent[]>) => {
				state.data = action.payload
				state.isLoading = false
			}
		),
		setCalendarEventsError: create.reducer(
			(state, action: PayloadAction<string | null>) => {
				state.error = action.payload
				state.isLoading = false
			}
		)
	}),
	selectors: {
		selectCalendarEvents: state => state
	}
})

export const {
	setCalendarEvents,
	setCalendarEventsLoading,
	setCalendarEventsError
} = calendarEventsSlice.actions

export const { selectCalendarEvents } = calendarEventsSlice.selectors
