/* eslint-disable react/no-array-index-key */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
/* eslint-disable unicorn/prefer-code-point */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Area,
	AreaChart,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts'

import { AlignStartHorizontal } from 'lucide-react'
import { ScrollArea } from 'components/ui/scroll-area'
import { currencyFormatter } from 'utils/currencyformatter.util'
import { getAmount } from 'utils/currency'

interface ILimitedPartnerStackedAreaProps {
	data?: any[]
	callers?: string[]
}

function stringToColor(str?: string): string {
	if (!str) return '#000000'

	let hash = 0
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash)
	}
	let color = '#'
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xff
		color += `00${value.toString(16)}`.slice(-2)
	}
	return color
}

function CustomTooltip({ active, payload, label }: any) {
	if (active && payload?.length) {
		return (
			<div className='rounded-md border bg-gray-50 p-2'>
				<p className='label'>{`${label}`}</p>
				<ScrollArea className='rounded-md border p-4'>
					<div className='mb-3 flex items-center gap-2 border bg-gray-100 p-2'>
						<AlignStartHorizontal />
						Top 10 capital calls
					</div>
					<div className='flex flex-col'>
						{payload
							.sort((a: any, b: any) => b.value - a.value)
							.filter((_: any, index: number) => index < 10)
							.map((item: any, index: number) => (
								<div key={index} style={{ color: item.color }}>
									{index + 1}. {item.name}:{' '}
									{currencyFormatter('USD').format(
										getAmount([{ currency: 'USD', amount: item.value }], 'USD')
									)}
								</div>
							))}
					</div>
				</ScrollArea>
			</div>
		)
	}

	return null
}

function abbreviateNumber(value: number): string {
	if (value >= 1e9) {
		return `${(value / 1e9).toFixed(1)}B`
	}
	if (value >= 1e6) {
		return `${(value / 1e6).toFixed(1)}M`
	}
	if (value >= 1e3) {
		return `${(value / 1e3).toFixed(1)}K`
	}
	return value.toString()
}

export default function LimitedPartnerTotalCommittedStackedArea({
	data,
	callers
}: ILimitedPartnerStackedAreaProps): JSX.Element {
	return (
		<div className='rounded-md border border-[#E4E5E8] bg-white px-6 py-6'>
			<div style={{ width: '100%', height: 400 }}>
				<ResponsiveContainer>
					<AreaChart
						data={data}
						margin={{
							top: 10,
							right: 30,
							left: 0,
							bottom: 0
						}}
					>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='name' />
						<YAxis tickFormatter={abbreviateNumber} />
						<Tooltip content={<CustomTooltip />} />

						{callers?.map(entry => {
							const color = stringToColor(entry)
							return (
								<Area
									key={entry}
									type='monotone'
									dataKey={entry}
									stackId='1'
									stroke={color}
									fill={color}
									name={entry}
								/>
							)
						})}
					</AreaChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}
