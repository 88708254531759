/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'
import type { ITask } from '../interafces/task.interface'

export interface TaskSliceState {
	data: ITask | null
	isLoading: boolean
	error?: string | null
}

const initialState: TaskSliceState = {
	data: null,
	isLoading: false,
	error: null
}

export const taskSlice = createAppSlice({
	name: 'task',
	initialState,
	reducers: create => ({
		setTask: create.reducer((state, action: PayloadAction<ITask>) => {
			state.isLoading = false
			state.data = action.payload
		}),
		setTaskLoading: create.reducer((state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload
		}),
		setTaskError: create.reducer(
			(state, action: PayloadAction<string | null>) => {
				state.error = action.payload
				state.isLoading = false
			}
		)
	}),
	selectors: {}
})

export const { setTask, setTaskError, setTaskLoading } = taskSlice.actions

// export const {} = taskSlice.selectors
