/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import { useContext, useEffect, useRef, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import CompanyDeckPreview from 'features/companies/view/CompanyDeckPreview'
import CompanyDetailsInfo from 'features/companies/view/CompanyDetailsInfo'
import CompanyFiles from 'features/companies/view/CompanyFiles'
import CompanyNotes from 'features/companies/view/CompanyNotes'
import CompanyUpdates from 'features/companies/view/CompanyUpdates'
import HorizontalTabs from './HorizontalTabs'
import type { ICompany } from 'interfaces'
import type { ISharedLink } from 'features/companies/interfaces/sharedLink.interface'
import type { ISharedLinkView } from '../interfaces/shared_link_view.interace'
import InvestmentTerms from 'features/investments/view/InvestmentTerms'
import SharedLinkNewCompanyNote from './AddNewComment'
import { SharedLinksService } from '../services/sharedlinks.service'
import type { TabItem } from './HorizontalTabs'
import VerticalTabs from './VerticalTabs'
import { nanoid } from 'nanoid'

// import { secondsToHms } from 'utils'

interface Props {
	linkData?: ISharedLink | null
	loadingSelectedCompany: boolean
	company?: ICompany
	currentUserEmail: string
}

const COMPANY_DETAILS_INFO_TABS: TabItem[] = [
	{
		id: 'details',
		label: 'Details',
		includeOptionLabel: 'all'
	},
	{
		id: 'investment',
		label: 'Investment',
		includeOptionLabel: 'investment'
	}
]

const COMPANY_DETAILS_RIGHT_TABS = [
	{
		id: 'files',
		label: 'Files',
		icon: File,
		disabled: false,
		includeOptionLabel: 'files'
	},
	{
		id: 'updates',
		label: 'Updates',
		disabled: false,
		includeOptionLabel: 'updates'
	},
	{
		id: 'notes',
		label: 'Notes',
		disabled: false,
		includeOptionLabel: 'notes'
	}
]

function Timer({	
		linkData,
		company,
		currentUserEmail
	}: {	
		linkData: ISharedLink | null
		company?: ICompany
		currentUserEmail: string
	}) {
		const {authUser} = useContext(AuthContext)

  const [startTime, setStartTime] = useState<Date>(new Date());
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(true);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    if (isActive) {
      intervalRef.current = window.setInterval(() => {
        const endTime = new Date();
        setElapsedTime((prevElapsedTime) => prevElapsedTime + (endTime.getTime() - startTime.getTime()));
        setStartTime(new Date());
      }, 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isActive, startTime]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsActive(false);
      } else {
        setStartTime(new Date());
        setIsActive(true);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if(!company || !linkData || !authUser) return
      const totalElapsedTime = elapsedTime + (Date.now() - startTime.getTime());
			
			const data: ISharedLinkView = {
				id: nanoid(),
				time: totalElapsedTime / 1000,
				name: 'all',
				user: { name: '', email: currentUserEmail },
				startTime: startTime.toISOString(),
				endTime: new Date().toISOString(),
				linkData: {
					id: linkData.id,
					access: linkData.shareOption,
					url: location.pathname
				},
				company: {
					name: company.name,
					id: company.id,
					website: company.website || ''
				},
				fund: company.fund,
				createdAt: new Date().toISOString()
			}
			SharedLinksService.logSharedLinkView(data)
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [authUser, company, currentUserEmail, elapsedTime, linkData, startTime]);

	return (<div/>)

  // return (
  //   <div>
  //     <h1>Active Time: {secondsToHms((elapsedTime / 1000).toString())}</h1>
  //     <button type="button" onClick={() => setIsActive(!isActive)}>
  //       {isActive ? 'Pause' : 'Resume'}
  //     </button>
  //   </div>
  // );
};

export default function SharedLinkCompanyDetails({
	linkData,
	loadingSelectedCompany,
	company,
	currentUserEmail
}: Props) {
	function getTabContent(selectedTabId: string): JSX.Element {
		if (!company) return <div>No Company</div>
		switch (selectedTabId) {
			case 'details': {
				return (
					<CompanyDetailsInfo
						showFounders={false}
						isReadOnly
						company={company}
						fields={[
							'Tags',
							'Referral Method',
							'Headquarters',
							'Business Model',
							'Industry',
							'Category',
							'Date Created'
						]}
					/>
				)
			}
			case 'investment': {
				return <InvestmentTerms company={company} isReadOnly />
			}
			default: {
				return <div>Select a tab</div>
			}
		}
	}

	function getRightTabContent(selectedTabId: string): JSX.Element {
		if (!company) return <div>No Company</div>
		if (!linkData) return <div>No Link Data</div>
		switch (selectedTabId) {
			case 'files': {
				return (
					<div>
						{linkData.includeOptions.includes('deck') && (
							<CompanyDeckPreview
								isReadOnly
								company={company}
								url={company.deck?.url}
								thumbnail={company.deck?.thumbnail}
								name={company.deck?.name}
							/>
						)}
						{linkData.includeOptions.includes('files') && (
							<CompanyFiles isReadOnly company={company} />
						)}
					</div>
				)
			}
			case 'updates': {
				return <CompanyUpdates isReadOnly company={company} />
			}
			case 'notes': {
				return <CompanyNotes isReadOnly company={company} />
			}
			default: {
				return <div>Select a tab</div>
			}
		}
	}
	
	if (!company || !linkData) {
		return null
	}

	if (loadingSelectedCompany) {
		return null
	}

	return (
		<div
			data-testid='shared-link-company-details'
			className='sticky top-0 flex h-[calc(100vh-3rem)] flex-wrap justify-between gap-5 rounded-md'
		>
			{/* Left content area */}
			<div className='flex h-full flex-1 flex-col gap-5 overflow-auto'>
				<div className='flex gap-3 rounded-md border bg-white p-5'>
					<Avatar>
						<AvatarImage
							className='h-10 w-10'
							src={
								company.website
									? `https://www.google.com/s2/favicons?sz=128&domain_url=${company.website}`
									: '/images/logo/Logo-4.png'
							}
						/>
						<AvatarFallback>S</AvatarFallback>
					</Avatar>

					<div>
						<h1 className='text-lg font-semibold'>{company.name}</h1>
						<p className='text-sm text-gray-400'>{company.website}</p>
					</div>

					<div className='ml-5 line-clamp-4 flex-1 text-sm'>
						{company.description}
					</div>
				</div>
				
				{/* Timer */}
				<Timer company={company} linkData={linkData} currentUserEmail={currentUserEmail} />

				{/* New Tabs */}
				<HorizontalTabs
					tabs={COMPANY_DETAILS_INFO_TABS}
					linkData={linkData}
					company={company}
					userData={{ email: currentUserEmail, name: '' }}
				>
					{getTabContent}
				</HorizontalTabs>

				{/* Note / Comment */}
				<SharedLinkNewCompanyNote
					linkData={linkData}
					currentUserEmail={currentUserEmail}
					company={company}

				/>
			</div>

			{/* Right content area */}
			<div className='flex h-full rounded-md lg:w-[35vw] '>
				<VerticalTabs
					tabs={COMPANY_DETAILS_RIGHT_TABS}
					linkData={linkData}
					company={company}
					userData={{ email: currentUserEmail, name: '' }}
				>
					{getRightTabContent}
				</VerticalTabs>
			</div>
		</div>
	)
}



