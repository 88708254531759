/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useContext, useEffect, useState } from 'react'

import AddNewTag from './AddNewTag'
import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import type { ITag } from 'interfaces'
import { Input } from 'components/ui/input'
import { Plus } from 'lucide-react'
import SpokInlineInputEditor from 'components/shadcn/SpokInlineInputEditor'
import { TagService } from '../service/tag.service'
import clsx from 'clsx'
import { getColorCode } from 'utils/colors'
import { groupTagsByCategory } from '../helpers/tag.helper'
import { listenToTags } from '../tags.api'
import { nanoid } from 'nanoid'
import { selectTags } from '../tags.slice'
import { values } from 'lodash'

interface Props {
	tags?: any[]
	disabled?: boolean
	onSave?: (value: ITag[]) => void
	// onTagDelete?: (value: ITag) => void
}

export default function TagSelector({
	tags,
	disabled = false,
	onSave
	// onTagDelete
}: Props) {
	const { authUser } = useContext(AuthContext)
	const dispatch = useAppDispatch()

	const allTags = useAppSelector(selectTags)

	const [isOpen, setIsOpen] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')
	const [filteredTags, setFilteredTags] = useState(allTags)
	const [selectedTags, setSelectedTags] = useState<ITag[]>(tags ?? [])
	const [groupedTags, setGroupedTags] = useState(
		groupTagsByCategory(filteredTags)
	)

	function onTagClicked(tag: ITag) {
		if (selectedTags.map(item => item.id).includes(tag.id)) {
			setSelectedTags(selectedTags.filter(t => t.id !== tag.id))
		} else {
			setSelectedTags([...selectedTags, tag])
		}
	}

	useEffect(() => {
		if (isOpen) {
			setSelectedTags(tags ?? [])
		}
	}, [isOpen])

	useEffect(() => {
		if (!authUser?.fund.id) return

		const unsubscribe = dispatch(listenToTags(authUser.fund.id))
		return () => unsubscribe()
	}, [authUser?.fund.id])

	useEffect(() => {
		const filtered = allTags.filter(tag =>
			tag.name.toLowerCase().includes(searchQuery.toLowerCase())
		)
		setFilteredTags(filtered)

		const grouped = groupTagsByCategory(filtered)
		setGroupedTags(grouped)
	}, [searchQuery, allTags])

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title='Select Tags'
			description=''
			trigger={
				disabled ? (
					<div />
				) : (
					<Button
						id='open-tag-selector-button'
						variant='ghost'
						className='rounded-md bg-blue-100 px-2 py-0 text-blue-700'
						disabled={disabled}
						onClick={() => {
							setIsOpen(true)
						}}
					>
						<Plus size={15} />
						<div>Add</div>
					</Button>
				)
			}
		>
			<div>
				<AddNewTag />
				<Input
					id='search-tag-input'
					placeholder='Search Tags'
					className='mt-5'
					value={searchQuery}
					onChange={e => setSearchQuery(e.target.value)}
				/>
				<div className='mt-1 flex flex-col gap-2'>
					{Object.keys(groupedTags).map(category => {
						return (
							<div key={category} className='flex flex-col gap-2'>
								<div className='mt-5 flex items-center gap-2'>
									<div className='text-sm font-semibold'>{category}</div>
									<SpokInlineInputEditor
										showEditButton
										isReadOnly={false	}
										title={`Add new tag to ${category}`}
										onSave={value => {
											if(!authUser?.fund) return
											if(!value) return

											const tagData: ITag = {
												id: nanoid(),
												name: value,
												category: {
													name: category
												},
												color: `#${getColorCode(values.name)}`,
												createdAt: new Date().toISOString(),
												lastUpdatedAt: new Date().toISOString(),
												fund: authUser.fund,
												creator: {
													id: authUser.id,
													name: authUser.name,
													photoUrl: authUser.photoUrl ?? ''
												},
												isDeleted: false
											}

											TagService.addTag(tagData)
										}}
										defaultValue=''
										content={<div/>}
										editButton={
											<Button variant='blue' className='px-1 py-0 text-xs'>
												<Plus size={12} />
												Add
											</Button>
										}
									/>
								</div>
								<div className='flex flex-wrap gap-2'>
									{groupedTags[category].map(tag => {
										return (
											<div
												data-testid={`tag-option-${tag.name}`}
												key={tag.id}
												className={clsx(
													'group flex cursor-pointer items-center gap-1  rounded-md border px-2 py-1 text-sm transition-all',
													selectedTags.map(item => item.id).includes(tag.id) && 'border-blue-200 bg-blue-700 text-white hover:text-white hover:bg-opacity-90',
													!selectedTags.map(item => item.id).includes(tag.id) && ' hover:border-blue-200 hover:bg-blue-100'
												)}
												onClick={() => onTagClicked(tag)}
											>
												{tag.name}
											</div>
										)
									})}
								</div>
							</div>
						)
					})}
				</div>
				{Object.keys(groupedTags).length === 0 && (
					<div className='mt-2 text-sm text-gray-500'>No tags added</div>
				)}
				<div className='mt-10 flex w-full justify-end gap-5'>
					<Button
						id='cancel-create-tag-button'
						variant='danger'
						onClick={() => setIsOpen(false)}
					>
						Cancel
					</Button>
					<Button
						id='save-tag-button'
						onClick={() => {
							onSave?.(selectedTags)
							setIsOpen(false)
						}}
					>
						Save
					</Button>
				</div>
			</div>
		</DialogModal>
	)
}
