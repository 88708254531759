/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { ImSpinner10 } from "react-icons/im";

export default function VideoPlayer({ file, content }: { file: any; content: any }) {
  const [isOpen, setIsOpen] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(true);
  const videoElement = useRef<HTMLVideoElement>(null);

  function closeModal() {
    setIsOpen(false);
  }

  function checkVideoLoadingState() {
    if (videoElement.current && videoElement.current.readyState >= 2) {
      setLoadingVideo(false);
    }
  }

  // When I press the escape key, close the modal
  useEffect(() => {
    function handleEsc(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        closeModal();
      }
    }

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <>
      <div
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {content}
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25 ' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='relative w-screen max-w-5xl transform overflow-hidden rounded bg-black text-left align-middle shadow-lg transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='flex flex-col  bg-white px-4 py-2 font-medium leading-6 text-gray-900'
                  >
                    <small className='text-xs  text-gray-400'>
                      Video player
                    </small>
                    <span className='text-base'>{file.name}</span>
                  </Dialog.Title>
                  <section className='relative'>
                    <video
                      className='h-auto w-full'
                      ref={videoElement}
                      onLoadedMetadata={checkVideoLoadingState}
                      onLoadedData={checkVideoLoadingState}
                      onCanPlayThrough={checkVideoLoadingState}
                      autoPlay
                      controls
                      loop
                    >
                      <source
                        src={file.url}
                        type={
                          file.mimeType ??
                          `video/${file.name.split('.').at(-1)}`
                        }
                      />
                      <source src='myVideo.mp4' type='video/mp4' />
                      <p>
                        Your browser doesn&apos;t support HTML video. Here is a
                        <a href={file.url}>link to the video</a>{' '}
                        instead.
                      </p>
                    </video>
                    {loadingVideo && (
                      <div className='absolute inset-0 flex items-center justify-center'>
                        <ImSpinner10 className='animate-spin' />
                      </div>
                    )}
                  </section>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
