/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import type { CompanyData, ICompany, PartnerData, TagData } from 'interfaces'

import { ComboSelect } from 'components/shadcn/ComboSelect'
import CompanyFounders from './CompanyFounders'
import { CompanyService } from '../service/company.service'
import CompetitorSelector from 'features/competitors/view/CompetitorSelector'
import Competitors from '../../competitors/view/Competitors'
import CustomColumnAPI from 'features/dataTable/api/columns.api'
import Industries from 'features/industries/views/Industry'
import type { IndustryData } from 'features/industries/interfaces/industries.interface'
import IndustrySelector from 'features/industries/views/IndustrySelector'
import { PartnerSelector } from 'components/shadcn/PartnerSelector'
import SpokInlineInputEditor from 'components/shadcn/SpokInlineInputEditor'
import TagSelector from 'features/tags/view/TagSelector'
import Tags from 'components/Tags'
import UpdateLocation from './UpdateLocation'
import { contactsToDropdown } from 'features/contacts/helper/contact'
import { listenToIndustries } from 'features/industries/api/industries.api'
import { renderDate } from 'utils/date.util'
import { selectCompanies } from '../redux/companies.slice'
import { selectContacts } from 'features/contacts/redux/contacts.slice'
import { useAppSelector } from 'app/hooks'
import useCompanyFunnels from '../hooks/useCompanyFunnels'
import { useEffect } from 'react'

interface Props {
	company: ICompany
	fields?: string[]
	showFounders?: boolean
	isReadOnly?: boolean
}

function formatDate(dateString: Date) {
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'short',
		day: '2-digit'
	}
	const formattedDate = new Date(dateString)
		.toLocaleDateString('en-US', options)
		.replace(',', '')
	return `${formattedDate.slice(0, -4)}, ${formattedDate.slice(-4)}`
}

export default function CompanyDetailsInfo({
	company,
	fields,
	showFounders = true,
	isReadOnly
}: Props) {
	const { funnels } = useCompanyFunnels()
	const { data: contacts } = useAppSelector(selectContacts)
	const contactDropdownData = contactsToDropdown(contacts)

	const { listenToColumns, columns, upsertCustomField } = CustomColumnAPI()

	useEffect(() => {
		const unsubscribe = listenToColumns('company')
		return () => unsubscribe()
	}, [])
	const companies = useAppSelector(selectCompanies)

	function onTagDelete(tag: TagData) {
		CompanyService.updateCompany({
			id: company.id,
			key: 'tags',
			value: company.tags?.filter(t => t.id !== tag.id),
			company
		})

		CompanyService.updateCompany({
			id: company.id,
			key: 'tagIds',
			value: company.tags?.map(t => t.id),
			company,
			shouldLogActivity: false
		})
	}

	function onIndustriesDelete(industry: IndustryData) {
		CompanyService.updateCompany({
			id: company.id,
			key: 'industries',
			value: company.industries?.filter(t => t.id !== industry.id),
			company
		})
	}

	function onCompetitorDelete(competitor: CompanyData) {
		// Remmove competitor from the list
		const updatedCompetitors = company.competitors?.filter(
			(t: { id: string }) => t.id !== competitor.id
		)
		const updatedCompetitorIds = updatedCompetitors?.map(
			(t: { id: string }) => t.id
		)

		CompanyService.updateCompany({
			id: company.id,
			key: 'competitors',
			value: updatedCompetitors,
			company
		})

		CompanyService.updateCompany({
			id: company.id,
			key: 'competitorIds',
			value: updatedCompetitorIds,
			company,
			shouldLogActivity: false,
			shouldShowToast: false
		})

		// Remove the company from the competitor's list
		// get the competitor
		const competitorCompany = companies.find(c => c.id === competitor.id)
		if (!competitorCompany) return

		const updatedCompanyCompetitors = competitorCompany.competitors?.filter(
			(t: { id: string }) => t.id !== company.id
		)
		const updatedCompanyCompetitorIds = updatedCompanyCompetitors?.map(
			(t: { id: string }) => t.id
		)

		CompanyService.updateCompany({
			id: competitorCompany.id,
			key: 'competitors',
			value: updatedCompanyCompetitors,
			company: competitorCompany,
			shouldLogActivity: false,
			shouldShowToast: false
		})

		CompanyService.updateCompany({
			id: competitorCompany.id,
			key: 'competitorIds',
			value: updatedCompanyCompetitorIds,
			company: competitorCompany,
			shouldLogActivity: false,
			shouldShowToast: false
		})
	}

	useEffect(() => {
		const unsubscribe = listenToIndustries()
		return () => {
			unsubscribe()
		}
	}, [])

	return (
		<div data-testid='company-info-details'>
			{showFounders && <CompanyFounders company={company} />}
			<div className='rounded-md bg-white'>
				<div className='grid grid-cols-2 gap-5 p-5 xl:grid-cols-3 2xl:grid-cols-4'>
					{Object.entries({
						timeline: {
							label: 'Status',
							value: (
								<div>
									<ComboSelect
										isReadOnly={isReadOnly}
										onSelect={value => {
											const funnel = funnels.find(f => f.id === value)
											CompanyService.updateCompany({
												id: company.id,
												key: 'funnel',
												value: funnel,
												company
											})
										}}
										defaultValue={company.funnel?.id as string}
										content={
											<div className='flex items-center gap-2 pr-2'>
												<div className='text-sm'>{company.funnel?.name}</div>
											</div>
										}
										options={funnels.map(funnel => ({
											value: funnel.id,
											label: funnel.name
										}))}
									/>
								</div>
							)
						},
						tags: {
							label: 'Tags',
							value: (
								<div className='flex w-fit flex-wrap items-center gap-2 pb-2'>
									{isReadOnly ? (
										<Tags
											tags={company.tags ?? []}
											variant='default'
											maxNumber={6}
											wrap
										/>
									) : (
										<Tags
											onTagDelete={tag => {
												onTagDelete(tag)
											}}
											tags={company.tags ?? []}
											variant='default'
											maxNumber={6}
											wrap
										/>
									)}
									{!isReadOnly && (
										<TagSelector
											tags={company.tags ?? []}
											onSave={tags => {
												CompanyService.updateCompany({
													id: company.id,
													key: 'tags',
													value: tags,
													company
												})
												CompanyService.updateCompany({
													id: company.id,
													key: 'tagIds',
													value: tags.map(t => t.id),
													company,
													shouldLogActivity: false
												})
											}}
										/>
									)}
								</div>
							)
						},
						pointPerson: {
							label: 'Point Person',
							value: (
								<PartnerSelector
									isReadOnly={isReadOnly}
									onSelect={(partner: PartnerData) => {
										const pointPerson = {
											id: partner.id,
											name: partner.name
										}
										CompanyService.updateCompany({
											id: company.id,
											key: 'pointPerson',
											value: pointPerson,
											company
										})
									}}
									content={
										<div className='-ml-3 flex items-center gap-2'>
											<div className='text-sm'>
												{company.pointPerson?.name || 'Select Point Person'}
											</div>
										</div>
									}
								/>
							)
						},
						referralMethod: {
							label: 'Referral Method',
							value: (
								<ComboSelect
									className=''
									placeholder='-'
									allowSearch
									defaultValue={company.referralMethod || '-'}
									options={contactDropdownData}
									onSelect={value =>
										CompanyService.updateCompany({
											id: company.id,
											key: 'referralMethod',
											value,
											company
										})
									}
								/>
							)
						},
						location: {
							label: 'Headquarters',
							value: (
								<UpdateLocation
									isReadOnly={isReadOnly}
									id='company-headquarters'
									title='Company Headquarters'
									description='Update the company headquarters information'
									location={company.location}
									onUpdate={location => {
										CompanyService.updateCompany({
											id: company.id,
											key: 'location',
											value: location,
											company
										})
									}}
								/>
							)
						},
						industry: {
							label: 'Industry',
							value: (
								<div className='flex w-fit flex-wrap items-center gap-2 pb-2'>
									{isReadOnly ? (
										<Industries
											industries={company.industries ?? []}
											variant='default'
											maxNumber={6}
											wrap
										/>
									) : (
										<Industries
											onIndustryDelete={industry => {
												onIndustriesDelete(industry)
											}}
											industries={company.industries ?? []}
											variant='default'
											maxNumber={6}
											wrap
										/>
									)}
									{!isReadOnly && (
										<IndustrySelector
											industries={company.industries ?? []}
											onSave={industries => {
												CompanyService.updateCompany({
													id: company.id,
													key: 'industries',
													value: industries,
													company
												})
											}}
										/>
									)}
								</div>
							)
						},
						competitors: {
							label: 'Competitors',
							value: (
								<div className='flex w-fit flex-wrap items-center gap-2 pb-2'>
									{isReadOnly ? (
										<Competitors
											competitors={company.competitors ?? []}
											variant='default'
											maxNumber={6}
											wrap
										/>
									) : (
										<Competitors
											onCompetitorDelete={competitor => {
												onCompetitorDelete(competitor)
											}}
											competitors={company.competitors ?? []}
											variant='default'
											maxNumber={6}
											wrap
										/>
									)}
									{!isReadOnly && (
										<CompetitorSelector
											competitors={company.competitors ?? []}
											company={{
												id: company.id,
												name: company.name,
												website: company.website
											}}
											onSave={competitors => {
												CompanyService.updateCompany({
													id: company.id,
													key: 'competitors',
													value: competitors,
													company
												})
												CompanyService.updateCompany({
													id: company.id,
													key: 'competitorIds',
													value: competitors.map(t => t.id),
													company,
													shouldLogActivity: false
												})
											}}
										/>
									)}
								</div>
							)
						},
						dateCreated: {
							label: 'Date Created',
							value: (
								<div className='text-sm'>
									{renderDate(company.createdAt) || '-'}
								</div>
							)
						}
					}).map(([field, { label, value }]) => {
						if (fields && !fields.includes(label)) return null
						return (
							<div key={field}>
								<div className='text-sm font-semibold text-blue-700'>
									{label}
								</div>
								{value}
							</div>
						)
					})}

					{company.custom_fields && (
						<>
							{columns.map(col => {
								return (
									<div key={col.index}>
										<p className='text-sm font-semibold text-blue-700'>
											{col.name}
										</p>
										{(col.dataType === 'custom_dropdown' ||
											col.dataType === 'custom_label') && (
											<ComboSelect
												isReadOnly={isReadOnly}
												onSelect={async value => {
													await upsertCustomField(
														company,
														col.name,
														value,
														'companies'
													)
												}}
												defaultValue={company.custom_fields[col.key] || '-'}
												content={
													<div className='flex items-center gap-2 pr-2'>
														<div className='text-sm'>
															{company.custom_fields[col.key] ?? '-'}
														</div>
													</div>
												}
												options={col.options.map(opt => ({
													label: opt,
													value: opt
												}))}
											/>
										)}

										{col.dataType === 'custom_text' && (
											<SpokInlineInputEditor
												isReadOnly={isReadOnly}
												title={col.key}
												id={col.key}
												onSave={async value => {
													await upsertCustomField(
														company,
														col.key,
														value,
														'companies'
													)
												}}
												defaultValue={company.custom_fields[col.key] || '-'}
											/>
										)}

										{col.dataType === 'custom_numbers' && (
											<SpokInlineInputEditor
												inputType='number'
												isReadOnly={isReadOnly}
												title={col.key}
												id={col.key}
												onSave={async value => {
													await upsertCustomField(
														company,
														col.key,
														value,
														'companies'
													)
												}}
												defaultValue={company.custom_fields[col.key] || '-'}
											/>
										)}

										{col.dataType === 'custom_date' && (
											<SpokInlineInputEditor
												inputType='date'
												title={col.key}
												id={col.key}
												onSave={async value => {
													await upsertCustomField(
														company,
														col.key,
														value,
														'companies'
													)
												}}
												defaultValue={
													company.custom_fields[col.key]
														? formatDate(company.custom_fields[col.key])
														: '-'
												}
											/>
										)}

										{col.dataType === 'custom_textarea' && (
											<SpokInlineInputEditor
												isReadOnly={isReadOnly}
												multiLine
												title={col.key}
												id={col.key}
												onSave={async value => {
													await upsertCustomField(
														company,
														col.key,
														value,
														'companies'
													)
												}}
												defaultValue={company.custom_fields[col.key] || '-'}
											/>
										)}
									</div>
								)
							})}
						</>
					)}
				</div>
			</div>
		</div>
	)
}
