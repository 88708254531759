import _ from 'lodash'
import type { DocTipInterface } from '../interface/DocTipInterface'
import type { DataInterface } from '../interface/DataInterface'

const DocTipServiceConfig = {
	width: '400px',
	nonAvailableTitle: 'Not Available',
	nonAvailableMessage: 'Document summary not available'
}

function getContent(title: string, value: string) {
	return <div><strong>{title}: </strong><span>{value}</span></div>
}

function formatResult(result: Record<string, never>) {
	const keys = Object.keys(result)
	const content = []

	for (const key of keys) {
		const title: string = _.startCase(key)
		const value: never = result[key]
		const html = (typeof value === 'object') ? "" : getContent(title, value);
		content.push(html)
	}
	return content
}

export function getIcon() {
	return (<svg className="float-start w-5 h-5 text-gray-800 dark:text-white" viewBox="0 0 15 15"
							 fill="none" xmlns="http://www.w3.org/2000/svg"><path
				d="M7.75432 1.81954C7.59742 1.72682 7.4025 1.72682 7.24559 1.81954L1.74559 5.06954C1.59336 5.15949 1.49996 5.32317 1.49996
				5.5C1.49996 5.67683 1.59336 5.84051 1.74559 5.93046L7.24559 9.18046C7.4025 9.27318 7.59742
				9.27318 7.75432 9.18046L13.2543 5.93046C13.4066 5.84051 13.5 5.67683 13.5
				5.5C13.5 5.32317 13.4066 5.15949 13.2543 5.06954L7.75432 1.81954ZM7.49996 8.16923L2.9828
				5.5L7.49996 2.83077L12.0171 5.5L7.49996 8.16923ZM2.25432 8.31954C2.01658 8.17906 1.70998
				8.2579 1.56949 8.49564C1.42901 8.73337 1.50785 9.03998 1.74559 9.18046L7.24559 12.4305C7.4025 12.5232
				7.59742 12.5232 7.75432 12.4305L13.2543 9.18046C13.4921 9.03998 13.5709 8.73337 13.4304 8.49564C13.2899
				8.2579 12.9833 8.17906 12.7456 8.31954L7.49996 11.4192L2.25432 8.31954Z"
				fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
	</svg>)
}

export function getWidth() {
	return DocTipServiceConfig.width
}

export function isPDF(file: DocTipInterface): boolean {
	const filename = file.name
	const extension = filename.split('.').pop()
	return file.extractedData !== undefined || extension && extension === 'pdf' || filename.endsWith('.pdf')
}

export function getAiResult(file?: DocTipInterface | undefined): DataInterface {
	if (file?.extractedData === undefined) return {
		type: DocTipServiceConfig.nonAvailableTitle,
		content: [getContent('Message', DocTipServiceConfig.nonAvailableMessage)]
	}
	const { extractedData } = file
	return {
		type: extractedData.type,
		content: formatResult(extractedData.data)
	} as DataInterface;
}