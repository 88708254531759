import type { IContact, ITag, PartnerData } from 'interfaces'

import { ComboSelect } from 'components/shadcn/ComboSelect'
import { ContactService } from '../services/contact.service'
import CustomColumnAPI from 'features/dataTable/api/columns.api'
import { PartnerSelector } from 'components/shadcn/PartnerSelector'
import SpokInlineInputEditor from 'components/shadcn/SpokInlineInputEditor'
import TagSelector from 'features/tags/view/TagSelector'
import Tags from 'components/Tags'
import UpdateLocation from 'features/companies/view/UpdateLocation'
import { renderDate } from 'utils/date.util'
import { useEffect } from 'react'

interface Props {
	contact: IContact
	isReadOnly?: boolean
}

function formatDate(dateString: Date) {
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'short',
		day: '2-digit'
	};
	const formattedDate = new Date(dateString).toLocaleDateString('en-US', options).replace(',', '');
	return `${formattedDate.slice(0, -4)}, ${formattedDate.slice(-4)}`;
}

export default function ContactDetailsInfo({ contact, isReadOnly }: Props) {
	const { listenToColumns, columns, upsertCustomField } = CustomColumnAPI();

	useEffect(() => {
		const unsubscribe = listenToColumns('contact')
		return () => unsubscribe()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onSave = async (tags: ITag[]) => {
		const data = tags.map(item => {
			return {
				id: item.id,
				name: item.name,
				category: item.category
			}
		})

		ContactService.updateContact({
			id: contact.id,
			key: 'tags',
			value: data,
			contact
		})

		ContactService.updateContact({
			id: contact.id,
			key: 'tagIds',
			value: tags.map(tag => tag.id),
			contact
		})
	}


	return (
		<div className='mt-5 flex flex-col gap-3 rounded-md border bg-white'>
			<div className='font border-b p-2 font-semibold text-blue-700'>
				Details
			</div>
			<div className='grid grid-cols-2 gap-3 px-3 pb-3 xl:grid-cols-3 2xl:grid-cols-4'>
				{Object.entries({
					tags: {
						label: 'Tags',
						value: (
							<div className='flex w-fit flex-wrap items-center gap-2 pb-2'>
								<Tags
									tags={contact.tags ?? []}
									variant='default'
									maxNumber={3}
									wrap
								/>
								<TagSelector tags={contact.tags ?? []} onSave={onSave} />
							</div>
						)
					},
					connectionStrength: {
						label: 'Connection Strength',
						value: (
							<SpokInlineInputEditor
								id='connectionStrength'
								title='Connection Strength'
								isReadOnly
								onSave={value => {
									// None
								}}
								defaultValue={`${contact.connectionStrength || 0}/100`}
							/>
						)
					},
					pointPerson: {
						label: 'Point Person',
						value: (
							<PartnerSelector
								onSelect={(partner: PartnerData) => {
									const pointPerson = {
										id: partner.id,
										name: partner.name
									}
									ContactService.updateContact({
										id: contact.id,
										key: 'pointPerson',
										value: pointPerson,
										contact
									})
								}}
								content={
									<div className='-ml-3 flex items-center gap-2'>
										<div className='text-sm'>
											{contact.pointPerson?.name || 'Select Point Person'}
										</div>
									</div>
								}
							/>
						)
					},
					linkedIn: {
						label: 'LinkedIn',
						value: (
							<SpokInlineInputEditor
								id='linkedIn'
								title='LinkedIn'
								onSave={value => {
									ContactService.updateContact({
										id: contact.id,
										key: 'linkedIn',
										value,
										contact
									})
								}}
								defaultValue={contact.linkedIn || '-'}
							/>
						)
					},

					firstMeeting: {
						label: 'First Email',
						value: (
							<SpokInlineInputEditor
								id='firstEmail'
								isReadOnly
								title='First Meeting'
								onSave={value => {
									ContactService.updateContact({
										id: contact.id,
										key: 'firstEmail',
										value,
										contact
									})
								}}
								defaultValue={
									contact.firstEmail ? renderDate(contact.firstEmail) : '-'
								}
							/>
						)
					},
					lastEmail: {
						label: 'Last Email',
						value: (
							<SpokInlineInputEditor
								id='lastEmail'
								title='Last Email'
								isReadOnly
								onSave={value => {
									// Read only
								}}
								defaultValue={
									contact.lastEmail ? renderDate(contact.lastEmail) : '-'
								}
							/>
						)
					},
					referralMethod: {
						label: 'Referral Method',
						value: (
							<SpokInlineInputEditor
								id='referralMethod'
								title='Referral Method'
								onSave={value => {
									ContactService.updateContact({
										id: contact.id,
										key: 'referralMethod',
										value,
										contact
									})
								}}
								defaultValue={contact.referralMethod || '-'}
							/>
						)
					},
					location: {
						label: 'Location',
						value: (
							<UpdateLocation
								id='contact-headquarters'
								title='Company Headquarters'
								description='Update the contact headquarters information'
								location={contact.location}
								onUpdate={location => {
									ContactService.updateContact({
										id: contact.id,
										key: 'location',
										value: location,
										contact
									})
								}}
							/>
						)
					},
					phone: {
						label: 'Phone Number',
						value: (
							<SpokInlineInputEditor
								id='phone'
								title='Phone Number'
								onSave={value => {
									ContactService.updateContact({
										id: contact.id,
										key: 'phone',
										value,
										contact
									})
								}}
								defaultValue={contact.phone || '-'}
							/>
						)
					},
					industry: {
						label: 'Industry',
						value: (
							<SpokInlineInputEditor
								id='industry'
								title='Industry'
								onSave={value => {
									ContactService.updateContact({
										id: contact.id,
										key: 'industry',
										value,
										contact
									})
								}}
								defaultValue={contact.industry || '-'}
							/>
						)
					},
					category: {
						label: 'Category',
						value: (
							<SpokInlineInputEditor
								id='category'
								title='Category'
								onSave={value => {
									ContactService.updateContact({
										id: contact.id,
										key: 'category',
										value,
										contact
									})
								}}
								defaultValue={contact.category || '-'}
							/>
						)
					},
					dateCreated: {
						label: 'Date Created',
						value: (
							<div className='text-sm'>
								{renderDate(contact.createdAt) || '-'}
							</div>
						)
					}
				}).map(([field, { label, value }]) => (
					<div data-testid={`contact-details-${field}`} key={field}>
						<div className='text-sm font-semibold text-blue-700'>{label}</div>
						<div>{value}</div>
					</div>
				))}

				{columns.map((col) => {
					return (
						<div key={col.index}>
							<p className='text-sm text-blue-700 font-semibold'>{col.name}</p>
							{(col.dataType === 'custom_dropdown' || col.dataType === 'custom_label') && <ComboSelect
								isReadOnly={isReadOnly}
								onSelect={async (value) => {
									await upsertCustomField(contact, col.name, value, 'contacts')
								}}
								defaultValue={contact.custom_fields[col.key] || '-'}
								content={
									<div className='flex items-center gap-2 pr-2'>
										<div className='text-sm'>
											{contact.custom_fields[col.key] ?? '-'}
										</div>
									</div>
								}
								options={
									col.options.map(opt => ({
										label: opt,
										value: opt
									}))
								}
							/>}

							{col.dataType === 'custom_text' && <SpokInlineInputEditor
								isReadOnly={isReadOnly} title={col.key} id={col.key}
								onSave={async (value) => {
									await upsertCustomField(contact, col.key, value, 'contacts')
								}} defaultValue={contact.custom_fields[col.key] || '-'} />}

							{col.dataType === 'custom_numbers' && <SpokInlineInputEditor
								inputType='number'
								isReadOnly={isReadOnly} title={col.key} id={col.key}
								onSave={async (value) => {
									await upsertCustomField(contact, col.key, value, 'contacts')
								}} defaultValue={contact.custom_fields[col.key] || '-'} />}

							{col.dataType === 'custom_date' && <SpokInlineInputEditor
								inputType="date" title={col.key} id={col.key}
								onSave={async (value) => {
									await upsertCustomField(contact, col.key, value, 'contacts')
								}}
								defaultValue={contact.custom_fields[col.key] ? formatDate(contact.custom_fields[col.key]) : '-'} />}

							{col.dataType === 'custom_textarea' && <SpokInlineInputEditor
								isReadOnly={isReadOnly} multiLine title={col.key} id={col.key}
								onSave={async (value) => {
									await upsertCustomField(contact, col.key, value, 'contacts')
								}}
								defaultValue={contact.custom_fields[col.key] || '-'} />}
						</div>
					)
				})}
			</div>
		</div>
	)
}
