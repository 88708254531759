import {
	authorizeGoogleCalendar,
	disconnectGoogleCalendar
} from '../api/google_calendar.api'

import Errorhandler from 'lib/sentry'
import Logger from 'lib/logger'
import axiosInstance from 'lib/axios'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'sonner'

export const handleGoogleCalendarAuthorization = async () => {
	try {
		localStorage.setItem('googleAuth', 'google_calendar')
		authorizeGoogleCalendar()
	} catch (error: any) {
		toast.error(error.message ?? 'Failed to connect Gmail')
		localStorage.removeItem('googleAuth')
	}
}

export const handleDisconnectGoogleCalendar = async () => {
	try {
		await disconnectGoogleCalendar()
	} catch (error: any) {
		toast.error(error.message ?? 'Failed to disconnect GoogleCalendar')
	}
}

export const verifyGoogleCalendarCode = async (code: string) => {
	try {
		await axiosInstance.post('/google-calendar/authorize', { code })
		return true
	} catch (error: any) {
		const message = error.response?.data?.message ?? error.message
		toast.error(message)

		Logger.error(error)
		Errorhandler.captureException(error)

		return false
	}
}

