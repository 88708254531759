/* eslint-disable react/no-array-index-key */
import { Field, Input, Label } from '@headlessui/react'
import { InfoIcon } from 'lucide-react'
import type React from 'react'
import { useEffect, useState } from 'react'
import clsxm from 'utils/clsxm'

interface IOptionListCreatorProps {
	defaultValues?: string[]
	onChange: (change: IOptionChangeEvent) => void
}

export interface IOptionChangeEvent {
	hasDuplicates: boolean
	options: string[]
	clear: () => void
}

function OptionListCreator({
	onChange,
	defaultValues
}: Readonly<IOptionListCreatorProps>) {
	const [options, setOptions] = useState<string[]>([])
	const [newOption, setNewOption] = useState('')

	function handleOptionChange(optionIdx: number, newValue: string) {
		const previousOptions = [...options]
		previousOptions[optionIdx] = newValue
		setOptions(previousOptions)
	}

	function handleEnterKeyPress(
		evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
	) {
		if (evt.key !== 'Enter') return

		setOptions(prev => {
			const option = newOption
			setNewOption('')
			return [...prev, option]
		})
	}

	function handleOptionBlur(optionIdx: number, value: string) {
		if (value.trim().length === 0) {
			const optionsCopy = [...options]
			optionsCopy.splice(optionIdx, 1)
			setOptions(optionsCopy)
		}
	}

	const hasDuplicates =
		new Set(options.map(option => option.toLowerCase())).size < options.length

	function clear() {
		setNewOption('')
		setOptions([])
	}

	useEffect(() => {
		onChange({ hasDuplicates, options, clear })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options])

	useEffect(() => {
		if (defaultValues) {
			setOptions(defaultValues)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Field className={clsxm('mt-5')}>
			<Label
				className={clsxm('text-sm/6 font-medium text-black', {
					'mb-2 ': options.length > 1
				})}
			>
				Options
			</Label>

			<div className='relative flex flex-col gap-2'>
				{options.map((option, optionIdx) => (
					<Input
						key={optionIdx}
						className={clsxm('rounded border-gray-300 text-sm shadow-none')}
						value={option}
						required
						onBlur={evt => handleOptionBlur(optionIdx, evt.target.value)}
						onChange={evt => handleOptionChange(optionIdx, evt.target.value)}
						placeholder={`option ${optionIdx}`}
					/>
				))}
				{hasDuplicates && (
					<small className='text-red-500'>Duplicate options detected</small>
				)}

				<p
					className={clsxm(
						'  flex flex-wrap items-center gap-1 text-xs text-primary',
						{ 'mt-4': options.length > 1 }
					)}
				>
					<InfoIcon className='h-3 w-3' />
					Press enter key to include new option to options list
				</p>
				<Input
					className={clsxm(
						' border-l-0 border-r-0 border-t-0 border-gray-300 px-0 text-sm shadow-none focus:ring-0'
					)}
					value={newOption}
					onKeyDown={handleEnterKeyPress}
					required
					onChange={evt => setNewOption(evt.target.value)}
					placeholder='New option'
				/>
			</div>
		</Field>
	)
}

export default OptionListCreator
