/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import CompanyActivities from 'features/companies/view/CompanyActivities'
import CompanyAnalyticsComments from './CompanyAnalyticsComments'
import CompanyAnalyticsEventIcons from '../components/CompanyAnalyticsEventIcons'
import CompanyAnalyticsSummaryTable from './CompanyAnalyticsSummaryTable'
import type { ICompany } from 'interfaces'
import type { ISharedLinkViewGroup } from 'features/sharedlinks/interfaces/shared_link_view.interace'
import { SharedLinksService } from 'features/sharedlinks/services/sharedlinks.service'
import _ from 'lodash'
import { listenToColumns } from 'features/columns/columns.api'
import { listenToRelevantPeople } from 'features/companies/api/companies.api'
import { selectRelevantPeople } from 'features/companies/redux/company.slice'
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
import { useAppSelector } from 'app/hooks'

interface Props {
	company: ICompany
}

export default function CompanyAnalytics({ company }: Props) {
	const { authUser } = useContext(AuthContext)

	const [views, setViews] = useState<ISharedLinkViewGroup[]>([])
	const [isLoading, setIsLoading] = useState(true)

	const { data } = useAppSelector(selectRelevantPeople)

	useEffect(() => {
		if (!authUser?.fund.id) return
		if (!company.tagIds || company.tagIds.length <= 0) return

		const unsub1 = listenToColumns(authUser.fund.id)
		const unsub2 = listenToRelevantPeople(company.tagIds, authUser.fund.id)

		return () => {
			unsub1()
			unsub2()
		}
	}, [company.tagIds, authUser?.fund.id])

	useEffect(() => {
		SharedLinksService.listenToSharedLinkViews(
			company.id,
			_views => {
				const data = _.groupBy(_views, 'user.email')
				const views = Object.keys(data).map(email => {
					const userViews = data[email]

					const grouped = _.groupBy(userViews, 'name')
					const viewsBreakDown = Object.keys(grouped).map(name => {
						const views = grouped[name]
						const time = views.reduce(
							(acc, view) => acc + (view.timeSpent ?? 0),
							0
						)
						return {
							name,
							time
						}
					})

					const visitedPages = userViews
						.map(view => view.name)
						.filter(i => i !== 'all')

					const allList = grouped.all || []
					const totalTime = allList.reduce(
						(acc, view) => acc + (view.timeSpent ?? 0),
						0
					)

					return {
						...userViews[0],
						visitedPages: [...new Set(visitedPages)].join(', ') , // remove duplicates
						viewsBreakDown,
						time: totalTime
					} as unknown as ISharedLinkViewGroup
				})
				setViews(views)
			},
			_loading => {
				setIsLoading(_loading)
			}
		)
	}, [company])

	return (
		<div className='sticky top-0 flex h-[calc(100vh-3rem)] flex-wrap justify-between gap-5 bg-gray-50 p-4'>
			<div className='flex h-full flex-1 flex-col gap-2 overflow-auto'>
				<CompanyAnalyticsEventIcons views={views} isLoading={isLoading} />
				<CompanyAnalyticsSummaryTable
					views={views}
					isLoading={isLoading}
					relevantPeople={data}
					company={company}
				/>
				<CompanyActivities company={company} source='slink_comment' />
			</div>
			<div className='flex h-full rounded-md border bg-white lg:w-[35vw]'>
				<CompanyAnalyticsComments company={company} />
			</div>
		</div>
	)
}
