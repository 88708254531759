/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger
} from 'components/ui/hover-card'
import type { TagData } from 'interfaces'
import { Tags as TagIcon, Trash2 } from 'lucide-react'
import { getColorCode } from 'utils/colors'
import DeleteModal from './DeleteModal'

export default function Tags({
	tags,
	wrap = false,
	className,
	maxNumber = 3,
	variant = 'compact',
	slice = true,
	onTagDelete
}: {
	tags: TagData[]
	wrap?: boolean
	className?: string
	maxNumber?: number
	variant?: 'compact' | 'default'
	slice?: boolean
	onTagDelete?: (tag: TagData) => void
}) {
	if (tags.length === 0)
		return (
			<div>
				{variant === 'compact' ? (
					<div className='text-xs' />
				) : (
					<div
						data-testid='no-tag-added-label'
						className='flex items-center gap-1 truncate rounded border bg-gray-50 px-3 text-sm'
					>
						<TagIcon size={15} />
						No tag added
					</div>
				)}
			</div>
		)
	if (typeof tags === 'string')
		return <div className='flex gap-1 truncate'>-</div>

	return (
		<div
			className={`flex items-center gap-1 truncate
      ${wrap ? 'flex-wrap' : ''}
      ${className}
    `}
		>
			{tags
				.filter((_, index) => index < maxNumber)
				.map(tag => (
					<HoverCard key={tag.id} openDelay={100} closeDelay={100}>
						<HoverCardTrigger asChild>
							<div
								style={{
									backgroundColor: `#${getColorCode(tag.name || '')}22`,
									color: `#${getColorCode(tag.name || '')}`,
									borderWidth: 1,
									borderColor: `#${getColorCode(tag.name || '')}33`
								}}
								className='group flex cursor-pointer items-center gap-1 text-ellipsis rounded px-1 py-[1px] text-[0.7rem]'
							>
								<div data-testid={`tag-${tag.name}`}>
									{tag.name && tag.name.length > 8 && slice
										? `${tag.name.slice(0, 8)}...`
										: tag.name || ''}
								</div>

								{onTagDelete && (
									<DeleteModal
										deleteButtonId={`deleteTag-${tag.name}`}
										title='Delete Tag'
										description={`Are you sure you want to delete ${tag.name}?`}
										trigger={
											<Trash2
												data-testid={`deleteTag-${tag.name}`}
												size={12}
												className='transform cursor-pointer opacity-30 transition-all duration-200 hover:scale-110 hover:text-red-500 group-hover:opacity-100'
											/>
										}
										onDelete={() => onTagDelete(tag)}
									/>
								)}
							</div>
						</HoverCardTrigger>
						<HoverCardContent className='w-fit'>
							<div className='flex justify-between space-x-4'>
								<div className='space'>
									<p className='text-sm'>{tag.name}</p>
								</div>
							</div>
						</HoverCardContent>
					</HoverCard>
				))}

			{tags.length === 0 && <div className='text-xs' />}

			{tags.length > maxNumber && (
				<HoverCard openDelay={100} closeDelay={100}>
					<HoverCardTrigger asChild>
						<div className='w-5 max-w-10 text-ellipsis text-xs'>
							+{tags.length - maxNumber}
						</div>
					</HoverCardTrigger>
					<HoverCardContent className=' flex w-96 flex-wrap gap-2'>
						{tags
							.filter(item => item.name && item.name.length > 0)
							.map(tag => (
								<div
									key={tag.id}
									style={{
										backgroundColor: `#${getColorCode(tag.name)}22`,
										color: `#${getColorCode(tag.name)}`,
										borderWidth: 1,
										borderColor: `#${getColorCode(tag.name)}33`
									}}
									className=' w-fit text-ellipsis rounded px-1 py-[1px] text-xs'
								>
									{tag.name}
								</div>
							))}
					</HoverCardContent>
				</HoverCard>
			)}
		</div>
	)
}
