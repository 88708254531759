/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-extraneous-class */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from 'lib/axios'
import Logger from 'lib/logger'
import Errorhandler from 'lib/sentry'
import { toast } from 'sonner'

export interface ISendMessageRequest {
	to: string[]
	cc?: string[]
	bcc?: string[]
	subject: string
	message: string
}

export class MessageService {
	public static send = async (data: ISendMessageRequest) => {
		try {
			toast.loading('Sending email...')

			const { data: response } = await axiosInstance.post('/messages', data)

			toast.dismiss()
			if (response.success) toast.success('Message sent')
			if (!response.success) toast.error(response.message)
		} catch (error: any) {
			const message = error.response?.data?.message ?? error.message

			toast.dismiss()
			toast.error(message ?? 'An error occurred')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static extractCompany = async (id: string) => {
		try {
			Logger.info('Extracting company from message...', id)
		} catch (error: any) {
			const message = error.response?.data?.message ?? error.message
			toast.error(message ?? 'An error occurred')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static extractLimitedParter = async (id: string) => {
		try {
			Logger.info('Extracting limited partner from message...', id)
		} catch (error: any) {
			const message = error.response?.data?.message ?? error.message
			toast.error(message ?? 'An error occurred')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}
}
