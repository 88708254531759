import AllCompanies from 'features/companies/view/AllCompanies'
import DashboardLayout from 'components/Dashboard/DashboardLayout'
import usePageTimeTracking from 'hooks/usePageTracking'

export default function Companies(): JSX.Element {
	usePageTimeTracking({ pageName: 'Companies' })

	return (
		<DashboardLayout hideHeader={false} showSidebar title='Companies'>
			<AllCompanies />
		</DashboardLayout>
	)
}
