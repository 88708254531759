import type { IFirstCheckAndFollowOns, IPieChart } from "../interfaces/reports.interface";

import type { TagData } from "interfaces";
import { getConvertedAmounts } from "utils/currency";
import { nanoid } from "nanoid";

export function funnelToComboSelectOptionsMapper(funnels: string[]): {
  label: string;
  value: string;
}[] {
  return funnels.map((funnel) => {
    return {
      label: funnel,
      value: funnel
    }
  })
}

export function tagsMapper(tags: string[]): TagData[] {
  return tags.map((tag) => {
    return {
      category: {
        name: tag
      },
      id: nanoid(),
      name: tag
    }
  })
}

export function firstCheckAndFollowOnReportMapper(data: IFirstCheckAndFollowOns): IPieChart[] {
  return [
    {
      name: "First Check",
      value: getConvertedAmounts(data.firstCheck).amount
    },
    {
      name: "Follow Ons",
      value: getConvertedAmounts(data.followOns).amount
    }
  ]
}