import { File, Mail } from 'lucide-react'

export const COMPANY_STAGES = [
	'Angel',
	'Pre-Seed',
	'Seed',
	'Series A',
	'Series B',
	'Series C',
	'Late Stage'
]

export const COMPANY_DETAILS_INFO_TABS = [
	{
		id: 'details',
		label: 'Details'
	},
	{
		id: 'investment',
		label: 'Investment'
	},
	{
		id: 'relevantpeople',
		label: 'Relevant People'
	},
	{
		id: 'tasks',
		label: 'Tasks'
	}
]

export const COMPANY_DETAILS_RIGHT_TABS = [
	{
		id: 'files',
		label: 'Files',
		icon: File,
		disabled: false
	},
	{
		id: 'email',
		label: 'Email',
		icon: Mail,
		disabled: false
	},
	{
		id: 'updates',
		label: 'Updates',
		disabled: false
	},
	{
		id: 'notes',
		label: 'Notes',
		disabled: false
	},
	{
		id: 'chat',
		label: 'Chat',
		disabled: false
	},
	{
		id: 'logAction',
		label: 'Log',
		disabled: true
	},
	{
		id: 'reminder',
		label: 'Reminder',
		disabled: true
	}
]
