import type { ITableColumn } from 'features/dataTable/interfaces/datatable.interface'
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAppSlice } from 'app/createAppSlice'

export interface ColumnsSliceState {
	data: ITableColumn[]
	isLoading: boolean
	error?: string | null
}

const initialState: ColumnsSliceState = {
	data: [],
	isLoading: false,
	error: null
}

export const columnsSlice = createAppSlice({
	name: 'columns',
	initialState,
	reducers: create => ({
		setColumns: create.reducer(
			(state, action: PayloadAction<ITableColumn[]>) => {
				state.data = action.payload.sort((a, b) => a.index - b.index)
				state.isLoading = false
			}
		)
	}),
	selectors: {
		selectColumns: state => state.data,
		selectCompanyColumns: state =>
			state.data.filter(column => column.type === 'company'),
		selectCompetitorColumns: state =>
			state.data.filter(column => column.type === 'competitor'),
		selectContactColumns: state =>
			state.data.filter(column => column.type === 'contact'),
		selectProspectColumns: state =>
			state.data.filter(column => column.type === 'prospect'),
		selectLimitedPartnerColumns: state =>
			state.data.filter(column => column.type === 'limitedPartner'),
		selectCompanyGroupedColumns: state =>
			state.data.filter(column => column.type === 'company-group-list')
	}
})

export const { setColumns } = columnsSlice.actions

export const {
	selectColumns,
	selectContactColumns,
	selectProspectColumns,
	selectCompanyColumns,
	selectLimitedPartnerColumns,
	selectCompanyGroupedColumns,
	selectCompetitorColumns
} = columnsSlice.selectors
