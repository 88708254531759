/* eslint-disable no-param-reassign */
/**
 * Extracts and returns the domain from a given URL string.
 * @param url - The input URL string.
 * @returns The domain of the URL.
 */
export function getDomain(url: string): string {
  try {
    // Normalize the URL by adding a protocol if it's missing
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = `http://${url}`;
    }

    // Create a URL object to easily extract the hostname
    const parsedUrl = new URL(url);
    let domain = parsedUrl.hostname;

    // Remove 'www.' prefix if it exists
    if (domain.startsWith('www.')) {
      domain = domain.slice(4);
    }

    return domain;
  } catch {
    // Handle invalid URLs
    return '-';
  }
}