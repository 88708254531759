/* eslint-disable @typescript-eslint/no-explicit-any */
import { DialogModal } from 'components/shadcn/DialogModal'
import { useState } from 'react'
import type { IEmailMessage } from '../interfaces/email.interface'
import AllEmailComponent from './EmailDetails'

interface Props {
	email?: IEmailMessage
	trigger: React.ReactNode
	title?: string
	isReadOnly?: boolean
}

export default function EmailDialog({ email, trigger, title, isReadOnly }: Props) {
	const [isOpen, setIsOpen] = useState(false)

	if (!email) return null

	return (
		<DialogModal
			size='sm:max-w-7xl'
			open={isOpen}
			setOpen={setIsOpen}
			title={title || 'Task Email'}
			description={email.subject}
			trigger={trigger}
		>
			<div className=''>
				<AllEmailComponent isReadOnly={isReadOnly} data={email} wrapperClass='w-full rounded-none' />
			</div>
		</DialogModal>
	)
}
