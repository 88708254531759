import { Avatar, AvatarFallback } from 'components/ui/avatar'
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger
} from 'components/ui/hover-card'

import type { IEmailMessage } from '../interfaces/email.interface'

interface Props {
	variant: 'recipients' | 'sender'
	email: IEmailMessage
	only?: 'email' | 'name'
	triggerContentClassName?: { name?: string; email?: string }
}

export function EmailPeopleHoverCard({
	variant,
	email,
	only,
	triggerContentClassName
}: Props) {
	return (
		<HoverCard openDelay={100} closeDelay={100}>
			<HoverCardTrigger asChild>
				<div>
					{variant === 'sender' && (
						<div>
							<div className={`${triggerContentClassName?.email}`}>
								{email.from.name || email.from.email}
							</div>
						</div>
					)}

					{variant === 'recipients' && (
						<div>
							{only !== 'email' && (
								<div className='flex'>
									{email.to.map(person => (
										<div
											key={person.email}
											className={`${triggerContentClassName?.name} truncate`}
										>
											{person.name || person.email},&nbsp;
										</div>
									))}

									{email.cc.map(person => (
										<div
											key={person.name}
											className={`${triggerContentClassName?.name} truncate`}
										>
											{person.name || person.email},&nbsp;
										</div>
									))}

									{email.bcc.map(person => (
										<div
											key={person.name}
											className={`${triggerContentClassName?.name} truncate`}
										>
											{person.email},&nbsp;
										</div>
									))}
								</div>
							)}
							{only !== 'name' && (
								<div className={`${triggerContentClassName?.email}`}>
									{email.to.map(person => (
										<div
											key={person.email}
											className={`${triggerContentClassName?.email} truncate`}
										>
											{person.email}
										</div>
									))}

									{email.cc.map(person => (
										<div
											key={person.email}
											className={`${triggerContentClassName?.email} truncate`}
										>
											{person.email}
										</div>
									))}

									{email.bcc.map(person => (
										<div
											key={person.email}
											className={`${triggerContentClassName?.email} truncate`}
										>
											{person.email}
										</div>
									))}
								</div>
							)}
						</div>
					)}
				</div>
			</HoverCardTrigger>
			<HoverCardContent className='w-fit'>
				{variant === 'sender' && (
					<div>
						<div className='mb-3 font-semibold'>Sender</div>
						<div className='flex gap-2 text-sm font-semibold'>
							<Avatar>
								<AvatarFallback>
									{(email.from.name || email.from.email || '')
										.charAt(0)
										.toUpperCase()}
								</AvatarFallback>
							</Avatar>
							<div>
								<h4 className='text-sm'>{email.from.name}</h4>
								<div className='text-xs font-medium'>{email.from.email}</div>
							</div>
						</div>
					</div>
				)}

				{variant === 'recipients' && (
					<div>
						<div className='mb-3 font-semibold'>Participants</div>
						{email.to.map(person => (
							<div
								key={person.email}
								className='mb-2 flex items-center space-x-3'
							>
								<div>
									<div className='flex gap-2 text-sm font-semibold'>
										<div className='w-10'>
											<div className='flex h-fit w-fit rounded bg-blue-100 px-2 text-blue-700'>
												To
											</div>
										</div>
										<div>
											<h4 className='text-sm'>{person.name}</h4>
											<div className='text-xs font-medium'>{person.email}</div>
										</div>
									</div>
								</div>
							</div>
						))}

						{email.cc.map(person => (
							<div
								key={person.email}
								className='mb-2 flex items-center space-x-3'
							>
								<div>
									<div className='flex gap-2 text-sm font-semibold'>
										<div className='w-10'>
											<div className='flex h-fit w-fit rounded bg-blue-100 px-2 text-blue-700'>
												CC
											</div>
										</div>
										<div>
											<h4 className='text-sm'>{person.name}</h4>
											<div className='text-xs font-medium'>{person.email}</div>
										</div>
									</div>
								</div>
							</div>
						))}

						{email.bcc.map(person => (
							<div
								key={person.email}
								className='mb-2 flex items-center space-x-3'
							>
								<div>
									<div className='flex gap-2 text-sm font-semibold'>
										<div className='w-10'>
											<div className='flex h-fit w-fit rounded bg-blue-100 px-2 text-blue-700'>
												BCC
											</div>
										</div>
										<div>
											<h4 className='text-sm'>{person.name}</h4>
											<div className='text-xs font-medium'>{person.name}</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				)}
			</HoverCardContent>
		</HoverCard>
	)
}
