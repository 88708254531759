
import { useAppSelector } from 'app/hooks'
import { store } from 'app/store'
import Button from 'components/Buttons/Button'
import SpokBadge from 'components/shadcn/SpokBadge'
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import { HoverCard, HoverCardContent, HoverCardTrigger } from 'components/ui/hover-card'
import { useWizard } from 'react-use-wizard'
import AddEmailTag from '../components/AddEmailTag'
import { limitedPartnerEmailAddressDropdown } from '../helper/capitalCall'
import {
	addRecipientsSelectedEmailAddress,
	removeRecipientsSelectedEmailAddress,
	selectIsEmailAddressesNextStepDisable,
	selectIsRecipientNextStepDisable,
	selectRecipients,
	selectRecipientsEmailAddress
} from '../redux/newCapitalCall.slice'

function onClickSaveEmailAddresses(email: string, id: string) {
	store.dispatch(addRecipientsSelectedEmailAddress({ id, email }))
}

function onDeleteEmailAddresses(index: number, id: string) {
	store.dispatch(removeRecipientsSelectedEmailAddress({ id, index }))
}

export default function NewCapitalCallEmailAddressesStep(): JSX.Element {
	const { nextStep } = useWizard()

	const recipients = useAppSelector(selectRecipients)
	const emailAddressesMap = useAppSelector(selectRecipientsEmailAddress)

	const isRecipientNextStepDisable = useAppSelector(
		selectIsRecipientNextStepDisable
	)
	const isEmailAddressesNextStepDisable = useAppSelector(
		selectIsEmailAddressesNextStepDisable
	)

	return (
		<div className='h-100vh mx-auto min-h-[calc(100vh-3rem)] gap-x-60 bg-white p-14 pb-0'>
			<div className='rounded-md border border-[#E4E5E8] bg-white p-5'>
				<div className='mb-6 text-center text-3xl font-semibold'>
					Email Addresses
				</div>
				<div className='flex flex-col items-center gap-7'>
					{recipients.map(recipient => (
						<div
							key={recipient.id}
							className='flex w-full flex-grow justify-center gap-5'
						>
							<div className='relative flex w-[250px] flex-col items-center rounded-md border border-[#E4E5E8] p-5 px-20'>
								<div>
									<Avatar className='h-20 w-20 border'>
										<AvatarImage src={recipient.photoUrl} />
										<AvatarFallback>
											{recipient.name.slice(0, 1).toUpperCase()}
										</AvatarFallback>
									</Avatar>
								</div>
								<HoverCard openDelay={0} closeDelay={0}>
									<HoverCardTrigger asChild>
										<div className='mt-2 whitespace-nowrap text-ellipsis text-center overflow-hidden w-[200px]'>
											{recipient.name}
										</div>
									</HoverCardTrigger>
									<HoverCardContent className='w-fit'>
										<div>
											{recipient.name}
										</div>
									</HoverCardContent>
								</HoverCard>
							</div>
							<div className='relative flex flex-grow flex-row flex-wrap items-center rounded-md border border-[#E4E5E8]  p-5 px-20'>
								<div className='flex flex-wrap items-start gap-2 self-start'>
									<SpokBadge
										data={emailAddressesMap[recipient.id]}
										onDelete={badgeIndex =>
											onDeleteEmailAddresses(badgeIndex, recipient.id)
										}
										className='mr-2'
									/>
									<AddEmailTag
										onSelect={value =>
											onClickSaveEmailAddresses(value, recipient.id)
										}
										options={limitedPartnerEmailAddressDropdown(
											recipient,
											emailAddressesMap[recipient.id]
										)}
									/>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<Button
				className='right-0 ml-auto mt-5 px-10'
				variant='blue'
				id='continue-step-button'
				onClick={() => nextStep()}
				disabled={isRecipientNextStepDisable || isEmailAddressesNextStepDisable}
			>
				Continue
			</Button>
		</div>
	)
}
