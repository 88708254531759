import { Upload, X } from 'lucide-react'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { useContext, useEffect, useRef, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-await-in-loop */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import type { FileUploadResult } from 'features/storage/hooks/useFirebaseStorageUploader'
import { ImSpinner10 } from 'react-icons/im'
import clsxm from 'utils/clsxm'
import { formatFileSize } from '../helpers/fileviewer.helper'
import { motion } from 'framer-motion'
import { nanoid } from 'nanoid'
import { storage } from 'lib/firebase'
import { toast } from 'sonner'

// type Document = {
//   name: string;
//   file: File;
// };

interface FileUploaderProps {
	onUpload?: (files: FileUploadResult[]) => void
	buttonText?: string
	accept?: string
	childPropClass?: string
	multiple?: boolean
	child?: React.ReactNode
	options?: React.ReactNode
	onFilesSelected?: (files: File[]) => void
	shouldUpload?: boolean
	id?: string
}

export default function FileUploader({
	onUpload,
	buttonText = 'Upload',
	multiple = true,
	child,
	childPropClass,
	accept = '.pdf, .xlsx, .csv, .ppt, .doc, .docx, .eml, image/*, video/*',
	onFilesSelected,
	options,
	shouldUpload = true,
	id
}: FileUploaderProps) {
	const [isOpen, setIsOpen] = useState(false)
	const { authUser } = useContext(AuthContext)
	const [selectedFiles, setSelectedFiles] = useState<any>([])
	const fileInputRef = useRef<HTMLInputElement>(null)
	const [isUploading, setIsUploading] = useState(false)

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = [...(event.target.files ?? [])]
		if (files.length === 0) return

		setSelectedFiles((prevSelectedFiles: any) => [
			...prevSelectedFiles,
			...files
		])

		if (onFilesSelected) {
			onFilesSelected(files)
			return
		}

		openModal()
	}

	const openFileSelector = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	useEffect(() => {
		if (!isOpen) {
			setSelectedFiles([])
		}
	}, [isOpen])

	async function uploadDocumentsToFirestore(): Promise<
		File[] | FileUploadResult[] | undefined
	> {
		if (selectedFiles.length === 0) {
			toast.error('No files selected!')
			return
		}

		if (!shouldUpload) {
			const results = selectedFiles
			setIsUploading(false)
			if (onUpload) {
				onUpload(results)
			}

			closeModal()

			return results
		}

		setIsUploading(true)
		const results: FileUploadResult[] = []
		toast.loading('Uploading files...')

		for (const document of selectedFiles) {
			const file = document
			const fileName = `${nanoid()}_${file.name}`

			const storagePath = `funds/${authUser?.fund.id}/documents/${fileName}`
			const storageRef = ref(storage, storagePath)

			try {
				await uploadBytes(storageRef, file)
				const downloadURL = await getDownloadURL(storageRef)
				results.push({
					id: nanoid(),
					name: file.name,
					url: downloadURL,
					type: file.type,
					size: file.size,
					createdAt: new Date().toISOString()
				})
			} catch (error) {
				setIsUploading(false)
				throw error
			}
		}

		setIsUploading(false)

		onUpload?.(results)

		closeModal()
		toast.dismiss()
		toast.success('All files uploaded successfully!')
		return results
	}

	return (
		<>
			{child ? (
				<div className={clsxm('flex h-full', childPropClass)}>
					<Button variant='ghost' className='p-0' onClick={openFileSelector}>
						{child}
					</Button>
					{options}
				</div>
			) : (
				<div className='flex w-full items-center justify-center'>
					<motion.div
						onClick={openFileSelector}
						whileTap={{ scale: 0.95 }}
						whileHover={{ scale: 1.02 }}
						className='flex w-fit cursor-pointer items-center gap-2 rounded bg-[#3868DB] px-2 py-1 text-sm text-white'
					>
						<Upload className='text-md' />
						{buttonText}
					</motion.div>
				</div>
			)}

			<input
				id={id}
				data-testid={id}
				ref={fileInputRef}
				type='file'
				accept={accept}
				multiple={multiple}
				onChange={handleFileUpload}
				className='hidden'
			/>

			<DialogModal
				open={isOpen}
				setOpen={setIsOpen}
				title='Review Selected Documents'
				description='Review the documents you have selected for upload'
				trigger={<div />}
			>
				<div>
					<div className='mt-5'>
						<div>
							{selectedFiles.length} document
							{selectedFiles.length === 1 ? '' : 's'} selected
						</div>
						{selectedFiles.length > 0 && (
							<div className='relative mt-1 overflow-x-auto sm:rounded-lg'>
								<table className='w-full text-left text-sm text-gray-500 dark:text-gray-400'>
									<thead className='bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400'>
										<tr>
											<th scope='col' className='px-6 py-3'>
												Name
											</th>
											<th scope='col' className='px-6 py-3'>
												Type
											</th>
											<th scope='col' className='px-6 py-3'>
												Size
											</th>

											<th scope='col' className='sr-only px-6 py-3'>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{selectedFiles.length > 0 && (
											<>
												{selectedFiles.map((file: any) => (
													<tr
														key={file.name}
														className='border-b bg-white dark:border-gray-700 dark:bg-gray-900'
													>
														<th
															scope='row'
															className='whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white'
														>
															{file.name}
														</th>
														<td className='px-6 py-4 uppercase'>
															{file.type.split('/')[1]}
														</td>
														<td className='px-6 py-4'>
															{formatFileSize(file.size)}
														</td>
														<td
															onClick={() => {
																if (isUploading) return
																setSelectedFiles(
																	selectedFiles.filter(
																		(x: any) => x.name !== file.name
																	)
																)
															}}
															className='cursor-pointer px-6 py-4 text-red-500'
														>
															<X />
														</td>
													</tr>
												))}
											</>
										)}
									</tbody>
								</table>
							</div>
						)}
					</div>

					<div className='mt-10'>
						<button
							data-testid={`${id}-upload-button`}
							disabled={isUploading}
							type='button'
							className='flex items-center justify-center gap-2 rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
							onClick={uploadDocumentsToFirestore}
						>
							{isUploading ? (
								<>
									<ImSpinner10 className='animate-spin' /> Uploading...
								</>
							) : (
								'Upload'
							)}
						</button>
					</div>
				</div>
			</DialogModal>
		</>
	)
}
