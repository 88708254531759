import { Bot } from 'lucide-react'
import type { IChat } from '../interfaces/chat.interface'
import ReactMarkdown from 'react-markdown'
import clsxm from 'utils/clsxm'

interface ConversationProps {
	message: IChat
	role: 'assistant' | 'user'
}

function getInitials(val: string) {
	return val
		.split(' ')
		.slice(0, 2)
		.map(name => name.charAt(0))
		.join('')
}

function formatContent(text: string) {
	const bodyRegex = /<body[^>]*>([\S\s]*?)<\/body>/gi
	const match = bodyRegex.exec(text)

	// eslint-disable-next-line @typescript-eslint/prefer-optional-chain
	if (match && match[0]) {
		return match[0].replaceAll(/(\\n)+/g, '<br>')
	}
	return text.replaceAll(/(\\n)+/g, '<br>')
}

function ChatBubble({ message, role }: Readonly<ConversationProps>) {
	return (
		<div className='flex flex-col items-center'>
			<div
				className={`flex w-full justify-start ${
					role === 'user' ? 'justify-end' : 'justify-start'
				}`}
			>
				<div
					className={clsxm(
						`flex w-4/5 items-start gap-2 py-4`,
						role === 'user' ? 'flex-row-reverse justify-end' : 'justify-start'
					)}
				>
					<div className=''>
						{role === 'assistant' && <Bot size={20} />}
						{role === 'user' && message.user.photoUrl && (
							<img
								className='h-6 w-6 rounded-full object-cover'
								src={message.user.photoUrl}
								alt='profile'
								title={message.user.photoUrl}
							/>
						)}
						{role === 'user' && !message.user.photoUrl && (
							<div
								className={clsxm(
									'flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-xs'
								)}
							>
								{getInitials(message.user.name)}
							</div>
						)}
					</div>
					<div className={clsxm(' flex-1')}>
						{role === 'assistant' ? (
							<div
								className={clsxm(
									`relative w-fit rounded-lg border !bg-white px-5 py-2 text-sm `,
									'border-primary-100 bg-primary-50 rounded-tl-none'
								)}
							>
								<ReactMarkdown>{message.message}</ReactMarkdown>
							</div>
						) : (
							<div
								className={clsxm(
									`relative w-fit rounded-lg border !bg-white px-5 py-2 text-sm `,
									'border-primary-15 bg-primary-10 ml-auto rounded-tr-none'
								)}
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: formatContent(message.message)
								}}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ChatBubble
