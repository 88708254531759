import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { useEffect, useState } from 'react'

import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import type { IContact } from 'interfaces'
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ImSpinner10 } from 'react-icons/im'
import { Input } from 'components/ui/input'
import { VirtualizedCompanySelector } from 'components/VirtualizedCompanySelector'
import clsxm from 'utils/clsxm'
import { deleteContact } from '../api/contacts.api'
import { toast } from 'sonner'
import { useCreateContact } from '../hooks/useCreateContact'

export default function AddEditContact({
	contact,
	trigger
}: {
	contact?: IContact
	trigger?: React.ReactNode
}) {
	const [isOpen, setIsOpen] = useState(false)

	const { formik } = useCreateContact({
		contact,
		onSuccess: () => setIsOpen(false)
	})

	async function onContactDelete() {
		if (!contact) return
		const res = confirm(`Are you sure you want to delete ${contact.name}?`)
		if (!res) return

		try {
			await deleteContact(contact.id)
			setIsOpen(false)
			toast.success('Contact deleted')
		} catch {
			toast.error('Failed to delete contact')
		}
	}

	useEffect(() => {
		if (!isOpen) formik.resetForm()
	}, [isOpen])

	useEffect(() => {
		if (!isOpen) return
		if (contact) {
			formik.setValues({
				name: contact.name,
				email: contact.email,
				linkedin: contact.linkedIn,
				phone: contact.phone,
				website: contact.website,
				country: contact.location?.country,
				state: contact.location?.state,
				city: contact.location?.city,
				company: ''
			})
		}
	}, [contact, isOpen])

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title={`${contact ? 'Edit' : 'Add'} Contact`}
			description=''
			trigger={
				trigger ?? (
					<Button
						data-testid='add-contact-button'
						onClick={() => setIsOpen(true)}
						className='whitespace-nowrap'
					>
						{contact ? 'Edit Contact' : 'Add Contact'}
					</Button>
				)
			}
		>
			<div className=''>
				<div className='flex flex-col gap-5'>
					<Input
						id='name'
						name='contact-name'
						placeholder='Enter contact name'
						label='Name'
						value={formik.values.name}
						onChange={e => formik.setFieldValue('name', e.target.value)}
						error={formik.errors.name}
					/>
					<Input
						id='email'
						name='email'
						placeholder='Enter contact email'
						label='Email'
						value={formik.values.email}
						onChange={e => formik.setFieldValue('email', e.target.value)}
						error={formik.errors.email}
					/>
					<Input
						id='linkedin'
						name='linkedin'
						placeholder='Enter contact linkedin'
						label='LinkedIn'
						value={formik.values.linkedin}
						onChange={e => formik.setFieldValue('linkedin', e.target.value)}
						error={formik.errors.linkedin}
					/>
					<Input
						id='phone'
						name='phone'
						placeholder='Enter contact phone'
						label='Phone'
						value={formik.values.phone}
						onChange={e => formik.setFieldValue('phone', e.target.value)}
						error={formik.errors.phone}
					/>
					<Input
						id='website'
						name='website'
						placeholder='Enter contact website'
						label='Website'
						value={formik.values.website}
						onChange={e => formik.setFieldValue('website', e.target.value)}
						error={formik.errors.website}
					/>
					<div className='flex items-center gap-3'>
						<div>
							<div>Country</div>
							<CountryDropdown
								priorityOptions={['US', 'NZ', 'CA']}
								classes={clsxm('w-full border-gray-300')}
								value={formik.values.country ?? 'US'}
								onChange={e => {
									formik.setFieldValue('country', e)
								}}
							/>
						</div>
						<div>
							<div>Region/State</div>
							<RegionDropdown
								classes='w-full border-gray-300'
								disableWhenEmpty
								country={formik.values.country ?? 'US'}
								value={formik.values.state ?? ''}
								onChange={e => {
									formik.setFieldValue('state', e)
								}}
							/>
						</div>
					</div>
					<div>
						<div>City</div>
						<Input
							value={formik.values.city}
							onChange={e => {
								formik.setFieldValue('city', e.target.value)
							}}
							placeholder='City'
							className='w-full rounded-none'
						/>
					</div>
					<VirtualizedCompanySelector
						defaultValue={formik.values.company}
						onSelect={value => {
							formik.setFieldValue('company', value)
						}}
					/>
				</div>
				{contact && (
					<div className='mt-10 rounded border border-red-200 bg-red-100 p-3'>
						<Button
							id={`delete-contact-${contact.name}`}
							variant='danger'
							onClick={onContactDelete}
						>
							Delete Contact
						</Button>
					</div>
				)}
				<div className='mt-10 flex w-full justify-end gap-5'>
					{!formik.isSubmitting && (
						<Button
							id='cancel-create-company-button'
							variant='danger'
							onClick={() => setIsOpen(false)}
						>
							Cancel
						</Button>
					)}
					<Button
						id='create-contact-button'
						disabled={formik.isSubmitting || !formik.isValid}
						onClick={() => formik.handleSubmit()}
					>
						{formik.isSubmitting ? (
							<div className='flex items-center gap-2'>
								<ImSpinner10 className='animate-spin' /> Saving
							</div>
						) : (
							<div>{contact ? 'Edit Contact' : 'Add Contact'}</div>
						)}
					</Button>
				</div>
			</div>
		</DialogModal>
	)
}
