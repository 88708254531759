import type { CompanyData, FundData } from 'interfaces'
import { doc, setDoc, updateDoc } from 'firebase/firestore'
import { useEffect, useRef } from 'react'

import type { ISharedLinkView } from 'features/sharedlinks/interfaces/shared_link_view.interace'
import Logger from 'lib/logger'
import { firestore } from 'lib/firebase'

interface UseTrackTimeSpentProps {
	sessionId: string
	userEmail: string
	userName?: string
	linkData: {
		id: string
		shareOption: string
	}
	companyData: CompanyData
	fundData: FundData
}

function useTrackTimeSpent(props: UseTrackTimeSpentProps): void {
	const timeSpentRef = useRef(0)
	const isTabActiveRef = useRef(true)
	const intervalRef = useRef<NodeJS.Timeout | null>(null)

	useEffect(() => {
		const { sessionId, userEmail, userName, linkData, companyData, fundData } =
			props

		// if (!companyData.id || !fundData.id || linkData.id) return

		const docRef = doc(firestore, 'shared_link_logs', sessionId)
		const updateFirestore = async () => {
			try {
				const currentTime = timeSpentRef.current
				if (currentTime < 1) return
				// Update Firestore with the new time spent
				await updateDoc(docRef, {
					timeSpent: currentTime
				}).catch(async () => {
					// If the document doesn't exist, create a new one
					const data: ISharedLinkView = {
						id: sessionId,
						name: 'all',
						time: 0,
						timeSpent: currentTime,
						user: { name: userName || '', email: userEmail },
						startTime: new Date().toISOString(),
						endTime: new Date().toISOString(),
						linkData: {
							id: linkData.id,
							access: linkData.shareOption,
							url: location.pathname
						},
						company: companyData,
						fund: fundData,
						createdAt: new Date().toISOString()
					}

					await setDoc(docRef, data)
				})
			} catch (error) {
				Logger.error('Error updating time spent:', error)
			}
		}

		const startTimer = () => {
			intervalRef.current = setInterval(() => {
				timeSpentRef.current += 1
				updateFirestore() // Update Firestore every minute
			}, 1000)
		}

		const stopTimer = () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current)
				intervalRef.current = null
			}
		}

		// Handle visibility change
		const handleVisibilityChange = () => {
			if (document.hidden) {
				isTabActiveRef.current = false
				stopTimer() // Stop the timer if the tab is inactive
			} else {
				isTabActiveRef.current = true
				startTimer() // Restart the timer when the tab is active again
			}
		}

		// Start the timer when the tab is active
		if (!document.hidden) startTimer()

		// Add event listener for visibility change
		document.addEventListener('visibilitychange', handleVisibilityChange)

		// eslint-disable-next-line consistent-return
		return () => {
			if (intervalRef.current) clearInterval(intervalRef.current)
			updateFirestore() // Update Firestore one last time when the component unmounts
			document.removeEventListener('visibilitychange', handleVisibilityChange)
		}
	}, [props])
}

export default useTrackTimeSpent
