import type { RootState } from 'app/store'

/**
 * @func getAuthUser
 * gets authenticated user's information
 *
 * @param {RootState | Object} state - redux state
 * @returns {Fund | Object} - user object
 */
export const getAuthUser = (state: RootState) => {
	return state.authentication.authUser
}

/**
 * @func getAuthUserFund
 * gets authenticated user's base fund information
 *
 * @param {RootState | Object} state - redux state
 * @returns {FundData | Object} - fund object
 */
export const getAuthUserFund = (state: RootState) => {
	return state.authentication.authUser?.fund
}
/**
 * @func getAuthFund
 * gets authenticated user's base fund information
 *
 * @param {RootState | Object} state - redux state
 * @returns {Fund | Object} - fund object
 */
export const getAuthFund = (state: RootState) => {
	return state.authentication.authUserFund
}
