/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-extraneous-class */
import { store } from "app/store"
import { collection, doc, onSnapshot, query, setDoc, where, writeBatch } from "firebase/firestore"
import { firestore } from "lib/firebase"
import type { IFunnel } from "../funnels.interface"
import { setFunnels } from "../funnels.slice"

export class FunnelsService {

  private static readonly ref = collection(firestore, 'funnels')

  // Listen to feature filters
  public static listenToFeatureFilters(
    { fundId, feature }: {
      fundId?: string
      feature: string
    }
  ) {

    if (!fundId) throw new Error('Not authorized to read filter')

    const q = query(this.ref, where('fund.id', '==', fundId), where('type', '==', feature))
    return onSnapshot(q, (querySnapshot) => {
      const funnels = querySnapshot.docs.map(d => d.data() as IFunnel)
      store.dispatch(setFunnels(funnels))
    })
  }


  public static async updateFunnel(funnel: Partial<IFunnel>) {
    const res = doc(this.ref, funnel.id);
    await setDoc(res, funnel, { merge: true })
    return funnel
  }

  public static async updateFunnels(funnels: Partial<IFunnel>[]) {
    // Batch write
    const batch = writeBatch(firestore)
    for (const funnel of funnels) {
      const ref = doc(this.ref, funnel.id)
      batch.update(ref, funnel as any)
    }
    await batch.commit()
  }

}