/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch } from 'app/hooks'
import clsx from 'clsx'
import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import { Input } from 'components/ui/input'
import { AuthContext } from 'context/AuthContext'
import { useFormik } from 'formik'
import { motion } from 'framer-motion'
import type { ICompany } from 'interfaces'
import { Check, Link2, Share2, X } from 'lucide-react'
import { nanoid } from 'nanoid'
import { useContext, useEffect, useState } from 'react'
import { toast } from 'sonner'
import clsxm from 'utils/clsxm'
import * as Yup from 'yup'
import { addSharedLink } from '../api/sharedLinks.api'
import { CUSTOM_SHARE_OPTIONS } from '../data/ShareCompany.data'
import { getCompanyShareLink } from '../helpers/ShareCompany.helper'
import type { ISharedLink } from '../interfaces/sharedLink.interface'
import { setShareableCompanies } from '../redux/companies.slice'

interface ShareCompnayProps {
	companies: ICompany[],
	trigger?: React.ReactNode,
	onLinkGenerated?: (link: ISharedLink) => void
}

const shareOptionsData = [
	{
		name: 'Minimal',
		value: 'minimal',
		description: 'Quick share to everyone',
		includeOptions: [
			{
				option: "summary",
				isIncluded: true
			},
			{
				option: "deck",
				isIncluded: true
			},
			{
				option: "files",
				isIncluded: false
			},
			{
				option: "investment",
				isIncluded: false
			},
			{
				option: "memorandum",
				isIncluded: false
			},
			{
				option: "notes",
				isIncluded: false
			},
			{
				option: "updates",
				isIncluded: false
			},
		]
	},
	{
		name: 'Full',
		value: 'full',
		description: 'Share with partners outside of fund',
		includeOptions: [
			{
				option: "summary",
				isIncluded: true
			},
			{
				option: "deck",
				isIncluded: true
			},
			{
				option: "files",
				isIncluded: true
			},
			{
				option: "investment",
				isIncluded: true
			},
			{
				option: "memorandum",
				isIncluded: true
			},
			{
				option: "notes",
				isIncluded: true
			},
			{
				option: "updates",
				isIncluded: true
			},
		]
	},
	{
		name: 'Custom',
		value: 'custom',
		description: 'Select what to share'
	}
]

export function ShareCompany({ companies, trigger, onLinkGenerated }: ShareCompnayProps) {
	const [url, setUrl] = useState('')
	const [id, setId] = useState(nanoid())
	const [isOpen, setIsOpen] = useState(false)
	const dispatch = useAppDispatch()
	const company = companies.length === 1 ? companies[0] : null
	const { authUser } = useContext(AuthContext)

	function openModal() {
		setIsOpen(true)
	}

	function closeModal() {
		setIsOpen(false)
	}

	const formik = useFormik({
		initialValues: {
			companies,
			shareOptionsPreset: 'minimal',
			requiresEmail: true,
			requiresEmailVerification: false,
			access: 'public',
			accessEmails: [],
			includeOptions: shareOptionsData.find(option => option.value === 'minimal')?.includeOptions?.map(option => option.option) || []
		},
		validationSchema: Yup.object({
			companies: Yup.array().min(1, 'Select at least one company'),
			shareOptionsPreset: Yup.string().required('Select share options'),
			requiresEmail: Yup.boolean().required('Select if email is required'),
			access: Yup.string().required('Select access type')
		}),
		onSubmit: async values => {
			try {
				if (!authUser) return
				const data: ISharedLink = {
					id,
					companies: companies.map(companyItem => ({
						id: companyItem.id,
						name: companyItem.name,
						website: companyItem.website || '',
						slug: companyItem.slug || ''
					})),
					shareOption: values.shareOptionsPreset,
					includeOptions: values.includeOptions,
					fund: {
						id: authUser.fund.id || '',
						name: authUser.fund.name || '',
						website: authUser.fund.website || '',
						slug: authUser.fund.slug || ''
					},
					creator: {
						id: authUser.id || '',
						name: authUser.name || '',
						photoUrl: authUser.photoUrl || ''
					},
					requiresEmail: values.requiresEmail,
					requiresEmailVerification: values.requiresEmailVerification,
					access: values.access as 'private' | 'public',
					createdAt: new Date().toISOString(),
					lastUpdatedAt: new Date().toISOString()
				}

				const result = await addSharedLink(data, values.accessEmails)

				const shareLinkUrl = getCompanyShareLink(result.id)
				setUrl(shareLinkUrl)

				navigator.clipboard.writeText(shareLinkUrl)
				toast.success('Link copied to clipboard')
				onLinkGenerated?.(result)
			} catch (error: any) {
				toast.error(error.message ?? 'Error sharing company')
			} finally {
				formik.setSubmitting(false)
			}
		}
	})

	useEffect(() => {
		if (isOpen) {
			setId(nanoid())
			return
		}
		setTimeout(() => {
			formik.resetForm()
			setUrl('')
			setId('')
			formik.setFieldValue('companies', companies)
			formik.setFieldValue('shareOptionsPreset', 'minimal')
			formik.setFieldValue('requiresEmail', true)
			formik.setFieldValue('requiesEmailVerification', false)
			formik.setFieldValue('access', 'public')
			formik.setFieldValue('accessEmails', [])
			formik.setFieldValue('includeOptions', [])
			formik.setFieldValue('id', '')
		}, 0);
	}, [isOpen])

	return (
		<DialogModal
			size='sm:max-w-3xl'
			open={isOpen}
			setOpen={setIsOpen}
			title={
				companies.length > 1
					? `Share ${companies.length} Companies`
					: 'Share Company'
			}
			description={
				companies.length > 1
					? `Share the following companies`
					: `Share ${company?.name}`
			}
			trigger={
				<div>
					{trigger || (
						<Button
							id='open-share-company-button'
							className='h-10 w-10 rounded-md'
							variant='blue'
							onClick={() => {
								openModal()
							}}
						>
							<Share2 size={20} />
						</Button>
					)}
				</div>
			}
		>
			{!url && <div>
				<div className={`flex h-full w-full transition-all `}>
					<div className=' flex-1'>
						<div className='justify-between sm:flex'>
							<div className='flex flex-1 gap-x-3 border rounded p-2'>
								{companies.length === 1 && (
									<img
										className='h-12 w-12 rounded-full'
										src={
											company?.website
												? `https://www.google.com/s2/favicons?sz=128&domain_url=${company.website}`
												: '../../images/logo/Logo-4.png'
										}
										alt=''
									/>
								)}

								<div className='flex flex-1 flex-col'>
									{companies.length > 1 ? (
										<>
											The following companies will be shared
											<ul className='mb-5 mt-2 flex flex-wrap gap-2  py-1'>
												{companies.map(companyItem => (
													<li
														key={companyItem.id}
														className={clsxm(
															'rounded-full bg-gray-200 px-2 py-1 text-sm',
															'flex items-center gap-x-2'
														)}
													>
														{companyItem.name}{' '}
														<motion.button
															whileHover={{
																scale: 1.1,
																color: 'red'
															}}
															onClick={() => {
																dispatch(
																	setShareableCompanies(
																		companies.filter(
																			item => item.id !== companyItem.id
																		)
																	)
																)
															}}
														>
															<X size={15} />
														</motion.button>
													</li>
												))}
											</ul>
										</>
									) : (
										<div>
											<div className='relative inline whitespace-nowrap py-1 text-xl font-semibold'>
												{company?.name}
											</div>
											<div className='text-sm text-gray-500'>
												{company?.website}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className='mt-10'>
							<div className='w-full gap-5'>
								<div className='flex gap-2'>
									{
										shareOptionsData.map((option) => (
											<div
												key={option.value}
												data-testid={`${option.value}-share-option`}
												onClick={() => {
													formik.setFieldValue('shareOptionsPreset', option.value)
													if (option.value === 'custom') {
														formik.setFieldValue('includeOptions', [])
													} else {
														formik.setFieldValue('includeOptions', option.includeOptions
															?.filter((include) => include.isIncluded)
															?.map((include) => include.option) || [])
													}
												}}
												className={
													clsx(
														'flex gap-2 border-2 rounded-lg p-2 cursor-pointer flex-1',
														{
															'border-blue-700 bg-blue-50': formik.values.shareOptionsPreset === option.value
														}
													)
												}
											>
												<div className='flex flex-col items-start'>
													<div className='font-semibold'>{option.name}</div>
													<div className='text-gray-500 text-xs'>
														{option.description}
													</div>
													<div className='text-blue-700 font-semibold mt-3'>
														Includes
													</div>
													{option.value !== 'custom' && <ul>
														{option.includeOptions?.map((include) => (
															<li key={include.option} className='flex items-center gap-2'>
																{include.isIncluded ? <Check color='green' size={15} /> : <X color='red' size={15} />}	{include.option}
															</li>
														))}

													</ul>}

													{
														option.value === 'custom' && <div className='mt-2 flex flex-col gap-2'>
															{CUSTOM_SHARE_OPTIONS.map(option => (
																<div
																	key={option.value}
																	className='flex items-center gap-1'
																>
																	<input
																		data-testid={`${option.value}-custom-share-option`}
																		type='checkbox'
																		className='rounded'
																		checked={formik.values.includeOptions.includes(
																			option.value as never
																		)}
																		onChange={e => {
																			if (e.target.checked) {
																				formik.setFieldValue('includeOptions', [
																					...formik.values.includeOptions,
																					option.value
																				])
																			} else {
																				formik.setFieldValue(
																					'includeOptions',
																					formik.values.includeOptions.filter(
																						item => item !== option.value
																					)
																				)
																			}
																		}}
																	/>
																	<span>{option.label}</span>
																</div>
															))}
														</div>
													}
												</div>
											</div>
										))
									}
								</div>
							</div>

							<div className='mt-10'>
								<div className='flex items-center gap-2 pb-3 text-gray-700'>
									<Share2 size={15} />
									Access
									<div className='h-[1px] flex-1 bg-gray-200' />
								</div>
								<div className='flex flex-col gap-5'>
									<div className='flex gap-2'>
										<div>
											<input
												data-testid='public-access'
												id='public-access'
												type='radio'
												name='access'
												value='public'
												checked={formik.values.access === 'public'}
												onChange={e =>
													formik.setFieldValue('access', e.target.value)
												}
											/>
											<span className='ml-2'>Anyone With Link</span>
										</div>
										<div>
											<input
												data-testid='private-access'
												id='private-access'
												type='radio'
												name='access'
												value='private'
												checked={formik.values.access === 'private'}
												onChange={e =>
													formik.setFieldValue('access', e.target.value)
												}
											/>
											<span className='ml-2'>Restricted</span>
										</div>
									</div>

									<div className='flex flex-col gap-2'>
										<div>
											<input
												data-testid='requires-email'
												id='requires-email'
												className='rounded'
												type='checkbox'
												checked={formik.values.requiresEmailVerification}
												onChange={e =>
													formik.setFieldValue(
														'requiresEmailVerification',
														e.target.checked
													)
												}
											/>
											<span className='ml-2'>Requires Email Verification</span>
										</div>
									</div>
								</div>
								<div>
									{formik.values.access === 'private' && (
										<div
											data-testid='share-company-access-emails'
											className='mt-5 flex flex-col gap-2'
										>
											<div>
												<Input
													id='access-emails'
													label='Enter Emails, separated by comma'
													type='text'
													placeholder='Enter email'
													error={formik.errors.accessEmails}
													value={formik.values.accessEmails.join(',')}
													onChange={e =>
														formik.setFieldValue(
															'accessEmails',
															e.target.value
																.split(',')
																.map(email => email.trim())
														)
													}
												/>
												<div className='mt-5 flex flex-wrap gap-2'>
													{formik.values.accessEmails.map(email => (
														<div
															className='rounded-lg bg-gray-200 px-2 py-0.5 text-xs'
															key={email}
														>
															{email}
														</div>
													))}
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='mt-10 flex w-full justify-end gap-5'>
					<div>
						{url && (
							<div className='flex items-center gap-2'>
								<div className='flex items-center gap-2'>
									<a
										data-testid='share-company-link'
										href={url}
										target='_blank'
										rel='noreferrer'
										className='text-xs text-blue-700 underline'
									>
										{id}
									</a>
									<Button
										id='copy-share-company-link-button'
										variant='outline'
										className='text-xs'
										onClick={() => {
											navigator.clipboard.writeText(url)
											toast.success('Link copied to clipboard')
										}}
									>
										<Link2 size={15} />
									</Button>
								</div>
							</div>
						)}
					</div>
					<div className='flex-1' />

					<Button onClick={() => closeModal()} variant='danger'>
						Cancel
					</Button>
					<Button
						id='create-share-company-button'
						isLoading={formik.isSubmitting}
						disabled={!formik.isValid || formik.isSubmitting}
						onClick={() => formik.handleSubmit()}
					>
						{url ? 'Update' : 'Create'} Link
					</Button>
				</div>


			</div>
			}

			{
				url && <div>
					<div className='border rounded-md p-3'>
						<div className='capitalize font-semibold text-blue-700'>{formik.values.shareOptionsPreset} access</div>
						<div className='mt-2'>Includes</div>
						<div className='flex flex-wrap gap-2 mt-2'>
							{formik.values.includeOptions.map(option => (
								<div key={option} className='flex gap-1 items-center'>
									<Check size={15} color='green' />
									{option}
								</div>
							))}
						</div>
					</div>
					<div className='text-blue-700 font-semibold text-sm mt-5'>Generated Link</div>
					<div className='flex items-center gap-2'>
						<div className='border rounded-md px-3 py-1 bg-gray-100 mt-1'>
							<a
								data-testid='share-company-link'
								href={url}
								target='_blank'
								rel='noreferrer'
								className='text-xs text-blue-700 underline'
							>
								{url}
							</a>
						</div>
						<Button
							id='copy-share-company-link-button'
							onClick={() => {
								navigator.clipboard.writeText(url)
								toast.success('Link copied to clipboard')
							}}
						>
							<Link2 size={15} />
							Copy Link
						</Button>
					</div>
				</div>
			}
		</DialogModal>
	)
}
