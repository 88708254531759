/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { CheckBadgeIcon } from '@heroicons/react/20/solid'
import Button from 'components/Buttons/Button'
import NotFound from 'components/NotFound'
import Skeleton from 'components/Skeleton'

import CompanyFormSection from 'features/companies/components/CompanyFormSection'
import SystemCompanyFormSection from 'features/companies/components/SystemCompanyFromSection'
import getValidationSchema from 'features/companies/helpers/formik.helper'

import type { ICustomCompanyFormTemplate } from 'features/companies/interfaces/company.interface'
import { COMPANY_ONBOARDING_VALIDATION_SCHEMA } from 'features/companies/validators/company.validator'
import { listenToIndustries } from 'features/industries/api/industries.api'
import { selectIndustries } from 'features/industries/redux/industries.slice'
import { getTags } from 'features/tags/tags.api'

import { doc, getDoc, setDoc } from 'firebase/firestore'
import { useFormik } from 'formik'
import type { ITag } from 'interfaces'
import { firestore } from 'lib/firebase'
import Logger from 'lib/logger'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import clsxm from 'utils/clsxm'

export interface IOnboardCompanyProps {
	wrapperClass?: string
}

export default function OnboardCompany({ wrapperClass }: IOnboardCompanyProps) {
	const navigate = useNavigate()
	const location = useLocation()
	const query = new URLSearchParams(location.search)

	const fundId = query.get('_f')
	const templateId = query.get('_t')
	const isConfirmation = query.get('confirmation')
	const createdCompany = query.get('company')

	const [tags, setTags] = useState<ITag[]>([])
	const [creatingCompany, setCreatingCompany] = useState(false)
	const [template, setTemplate] = useState<ICustomCompanyFormTemplate | null>(
		null
	)
	const [isLoadingTemplate, setIsLoadingTemplate] = useState(true)
	const [customFieldValidationErrors, setCustomFieldValidationErrors] =
		useState({})
	const [enableAnotherCompanyCreation, setEnableAnotherCompanyCreation] =
		useState(false)

	const industries = useSelector(selectIndustries)

	useEffect(() => {
		if (!fundId) return
		;(async () => {
			try {
				const _tags: ITag[] = await getTags(fundId)
				setTags(_tags)
			} catch (error) {
				Logger.info(error)
			}
		})()
	}, [fundId])

	useEffect(() => {
		listenToIndustries()
	}, [])

	useEffect(() => {
		if (!templateId) {
			Logger.error('Missing template for onboarding')
			return
		}
		const collectionQuery = doc(
			firestore,
			`company_form_templates/${templateId}`
		)

		getDoc(collectionQuery)
			.then(res => {
				if (res.exists()) {
					setTemplate(res.data() as ICustomCompanyFormTemplate)
				}
			})
			.finally(() => {
				setIsLoadingTemplate(false)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const systemFieldsForm: ReturnType<typeof useFormik> = useFormik<
		Record<string, unknown>
	>({
		initialValues: {
			name: '',
			description: '',
			tagIds: [],
			tags: [],
			industries: [],
			industryIds: []
		},
		enableReinitialize: true,
		validationSchema: COMPANY_ONBOARDING_VALIDATION_SCHEMA,
		validateOnMount: false,
		onSubmit(values, formikHelpers) {
			Logger.info({ values, formikHelpers })
		}
	})

	async function validateCustomFields() {
		if (template === null) return

		const errors: any = {}
		const customFieldValidationSchemas =
			getValidationSchema(template).validationSchema

		for (const section of template.sections) {
			// eslint-disable-next-line no-continue
			if (section.isSystem) continue

			for (const field of section.fields) {
				// eslint-disable-next-line no-await-in-loop
				try {
					// eslint-disable-next-line no-await-in-loop
					await customFieldValidationSchemas[field.id].validate(
						systemFieldsForm.values[field.id]
					)
				} catch (error: any) {
					errors[field.id] = error?.message
				}
			}
		}

		if (Object.keys(errors).length > 0) {
			setCustomFieldValidationErrors(errors)
		}
	}

	useEffect(() => {
		validateCustomFields()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [systemFieldsForm.touched])

	async function companyCreationHandler() {
		validateCustomFields()
		if (!systemFieldsForm.isValid) {
			systemFieldsForm.handleSubmit()
			Logger.error({ errors: systemFieldsForm.errors })
			return
		}

		try {
			setCreatingCompany(true)
			const companyName = systemFieldsForm.values.name

			// const companyQuery = query(doc(firestore, 'new_companies'), where('name', "==", companyName));
			const newCompanyId = nanoid()
			const newCompanyDoc = doc(firestore, `new_companies/${newCompanyId}`)
			await setDoc(
				newCompanyDoc,
				{ ...systemFieldsForm.values, fund: template?.fund, id: newCompanyId },
				{ merge: true }
			)

			systemFieldsForm.setValues(systemFieldsForm.initialValues as any)
			setCustomFieldValidationErrors({})

			if (enableAnotherCompanyCreation) {
				toast.success(
					<div className='flex flex-wrap gap-1'>
						<strong>{companyName}</strong>created in fund
						<strong>{template?.fund.name}</strong>&nbsp;successfully
					</div>
				)
			} else {
				navigate(
					`/create-company?_f=&${fundId}&_t=${templateId}&company=${encodeURIComponent(
						companyName
					)}&confirmation=true`
				)
			}
		} catch {
			Logger.error('Failed to create company')
			toast.error(
				'Company creation failed. Check provided information and try again'
			)
		} finally {
			setCreatingCompany(false)
		}
	}

	if (isConfirmation) {
		return (
			<section className='mt-24'>
				<div className='mx-auto my-auto max-w-sm flex-1 text-center'>
					<CheckBadgeIcon className='mx-auto h-12 w-12 text-green-500' />
					<h1 className='text-2xl font-semibold'>Success</h1>
					<p className='mt-2'>
						The company&nbsp;
						<strong>{decodeURIComponent(createdCompany ?? '')}</strong> was
						successfully created in&nbsp;
						<strong>{template?.fund.name}</strong> fund
					</p>
				</div>
			</section>
		)
	}

	return (
		<>
			<title>Onboard company</title>
			<main className={wrapperClass}>
				<header
					className={clsxm(
						'h-14 border-b px-4',
						'flex items-center',
						'overflow-y-clip'
					)}
				>
					<span className='flex items-center gap-1 font-semibold'>
						<img
							src='/images/logo/Logo-4.png'
							className='h-10 w-auto scale-110'
							alt='logo'
						/>
						Company submission
					</span>
				</header>
				<div className='flex h-[calc(100vh-40px)]'>
					<aside className={clsxm('max-w-md bg-primary px-4 py-8 text-white')}>
						<h1 className='mb-4 text-2xl font-bold capitalize'>
							{template?.fund.name}{' '}
							{/fund\s*$/i.test(template?.fund.name ?? '') ? '' : 'Fund'}
						</h1>
						<strong className={clsxm('text-lg')}>Tips</strong>
						<ul className=' flex flex-col gap-4'>
							<li>
								Ensure your company name is correctly spelt and matches the
								legal name used on official documents
							</li>
							<li>
								Avoid using abbreviations or acronyms unless they unambiguously
								represent the same company as yours{' '}
							</li>
							<li>
								Categorize your company by attaching tags for easy
								identification when searching
							</li>
						</ul>
					</aside>
					<section className='h-full flex-1 overflow-y-auto p-4 py-10'>
						<div className='mx-auto max-w-2xl'>
							{isLoadingTemplate && (
								<div className='flex flex-col gap-8'>
									<Skeleton className='h-5 w-full' />
									<Skeleton className='h-[100px]' />
									<Skeleton className='h-[150px]' />
								</div>
							)}

							{!isLoadingTemplate && template === null && (
								<NotFound
									title='Error encountered'
									description='There are no templates available for this fund. Kindly ask fund manager to re-share template'
								/>
							)}

							{template?.sections
								.filter(section => section.fields.length > 0)
								.map(section =>
									section.isSystem ? (
										<SystemCompanyFormSection
											key={section.id}
											tags={tags}
											industries={industries}
											wrapperProps={{ className: clsxm('mb-10') }}
											form={systemFieldsForm}
											section={section}
										/>
									) : (
										<CompanyFormSection
											key={section.id}
											errors={customFieldValidationErrors}
											wrapperProps={{ className: clsxm('mb-10') }}
											form={systemFieldsForm}
											section={section}
										/>
									)
								)}

							{!isLoadingTemplate && template && (
								<div className='flex items-center justify-between'>
									<label
										htmlFor='onboard-company-checkbox'
										className={clsxm('flex items-center gap-x-2 text-xs')}
									>
										<input
											type='checkbox'
											name='onboard-company-checkbox'
											checked={enableAnotherCompanyCreation}
											className='rounded border-gray-600 text-primary'
											id='onboard-company-checkbox'
											onChange={evt =>
												setEnableAnotherCompanyCreation(evt.target.checked)
											}
										/>
										<span>Onboard another company</span>
									</label>
									<Button
										variant='dark'
										className='ml-auto px-2 py-2'
										isLoading={creatingCompany}
										disabled={creatingCompany}
										onClick={companyCreationHandler}
									>
										Submit
									</Button>
								</div>
							)}
						</div>
					</section>
				</div>
			</main>
		</>
	)
}
