import Button from 'components/Buttons/Button'
import { DialogModal } from 'components/shadcn/DialogModal'
import { PartnerSelector } from 'components/shadcn/PartnerSelector'
import SpokDatePicker from 'components/shadcn/SpokDatePicker'
import CKEditorView from 'features/ckeditor/view/ckeditor'
import CompanySearchSelector from 'features/companies/view/CompanySearchSelector'
import type { ICompany } from 'interfaces'
import type { Dispatch, SetStateAction } from 'react'
import { ImSpinner10 } from 'react-icons/im'
import useCreateTask from '../hooks/useCreateTask'
import type { ITask } from '../interafces/task.interface'
import { onUpdateTask, onCreateTask } from '../services/tasks.service'

interface ITaskManagerModalProps {
	isOpen?: boolean
	setIsOpen?: Dispatch<SetStateAction<boolean>>
	title?: string
	task?: Partial<ITask>
}

function TaskManagerModal({
	isOpen,
	setIsOpen,
	task,
	title
}: ITaskManagerModalProps) {
	const { formik } = useCreateTask({
		task: task as ITask,
		onCreateTask: async (data: ITask) => {
			if (setIsOpen) {
				setIsOpen(false)
			}
			await (task?.id ? onUpdateTask(data) : onCreateTask(data))
		}
	})

	return (
		<DialogModal
			open={isOpen}
			setOpen={setIsOpen}
			title={title ?? task?.id ? 'Edit Task' : 'Create Task'}
			description=''
			onClose={formik.resetForm}
			size='max-w-3xl'
			trigger={null}
		>
			<div className=''>
				<div className='mb-5 grid grid-cols-2 gap-5'>
					<PartnerSelector
						label='Select Partner'
						className='w-full'
						onSelect={value => {
							formik.setFieldValue('executor', value)
						}}
						defaultValue={task?.executor}
					/>

					<SpokDatePicker
						label='Due Date'
						onSelect={value => {
							formik.setFieldValue('dueDate', value?.toISOString())
						}}
						defaultValue={new Date(formik.values.dueDate || Date.now())}
						className='w-full rounded-md border px-2 py-1'
					/>
				</div>
				<div className='relative z-[9999]'>
					<div className='w-1/2'>
						<CompanySearchSelector
							label='Select Company'
							defaultValue={formik.values.company as ICompany}
							onSelect={value => {
								const companyData = {
									id: value.id,
									name: value.name,
									website: value.website ?? ''
								}
								formik.setFieldValue('company', companyData)
							}}
						/>
					</div>
				</div>
				<div className='mt-12'>
					{/* <Input
						data-testid='input-task'
						id='input-task'
						name='task'
						isMultiline
						label='Task'
						placeholder='Enter task description'
						value={formik.values.task}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={(formik.touched.task && formik.errors.task) || ''}
					/> */}
					{/* <PlateEditor
						className='rounded border'
						editorHeight="h-[50vh]"
						value={formik.values.taskRawPlate}
				<div className='mt-10'>
					<QuillEditor
						className='mb-20 h-[50vh] w-full'
						id='note-editor'
						value={formik.values.task}
						onChange={value => {
							formik.setFieldValue('task', value)
						}}
					/> */}
					<CKEditorView
						data={formik.values.task}
						onChange={value => {
							formik.setFieldValue('task', value)
						}}
					/>
				</div>

				<div className='mt-10 flex w-full justify-end gap-5'>
					{!formik.isSubmitting && (
						<Button
							id='cancel-create-task-button'
							variant='danger'
							onClick={() => {
								if (setIsOpen) {
									setIsOpen(false)
								}
							}}
						>
							Cancel
						</Button>
					)}
					<Button
						id='create-task-button'
						disabled={formik.isSubmitting || !formik.isValid}
						onClick={() => formik.handleSubmit()}
					>
						{formik.isSubmitting ? (
							<div className='flex items-center gap-2'>
								<ImSpinner10 className='animate-spin' /> Saving...
							</div>
						) : (
							<div> {task ? 'Save' : 'Create'}</div>
						)}
					</Button>
				</div>
			</div>
		</DialogModal>
	)
}

export default TaskManagerModal
