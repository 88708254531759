/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ISharedLinkViewGroup } from 'features/sharedlinks/interfaces/shared_link_view.interace'
import {
  Banknote,
  CopyPlus,
  File,
  FileBadge,
  Home,
  Notebook,
  Scroll
} from 'lucide-react'

interface Props {
  views: ISharedLinkViewGroup[]
  isLoading: boolean
}

function EventIcon({
  name,
  count,
  icon
}: {
  name: string
  count: number
  icon: any
}) {
  return (
    <div className='flex gap-2'>
      <div className='flex items-center gap-2 rounded-md border bg-white px-2 py-2'>
        {icon}
        <div className='text-xs font-semibold'>{name}</div>
        <div className='flex h-4 min-w-4 items-center justify-center rounded-full border border-blue-200 bg-blue-50 text-xs font-semibold text-blue-700'>
          {count}
        </div>
      </div>
    </div>
  )
}

export default function CompanyAnalyticsEventIcons({
  views,
  isLoading
}: Props) {
  const getCount = (name: string) => {
    if (isLoading) return 0
    return views.filter(view =>
      view.viewsBreakDown.map(item => item.name).includes(name)
    ).length
  }
  return (
    <div className='flex flex-wrap gap-2'>
      <EventIcon
        name='Main Info'
        count={getCount('details')}
        icon={<Home color='blue' size={16} />}
      />
      <EventIcon
        name='Deck'
        count={getCount('deck')}
        icon={<FileBadge color='blue' size={16} />}
      />
      <EventIcon
        name='Files'
        count={getCount('files')}
        icon={<File color='blue' size={16} />}
      />
      <EventIcon
        name='Investment'
        count={getCount('investment')}
        icon={<Banknote color='blue' size={16} />}
      />
      <EventIcon
        name='Memo'
        count={getCount('memo')}
        icon={<Scroll color='blue' size={16} />}
      />
      <EventIcon
        name='Notes'
        count={getCount('notes')}
        icon={<Notebook color='blue' size={16} />}
      />
      <EventIcon
        name='Updates'
        count={getCount('updates')}
        icon={<CopyPlus color='blue' size={16} />}
      />
    </div>
  )
}
