/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-empty-pattern */

import { Tooltip, TooltipTrigger } from '@radix-ui/react-tooltip'
import Button from 'components/Buttons/Button'
import DeleteModal from 'components/DeleteModal'
import { TooltipContent } from 'components/ui/tooltip'
import { IFile } from 'features/files/file.interface'
import { FileService } from 'features/files/services/file.service'
import FileViewer from 'features/fileviewers/views/FileViewer'
import { ICompany } from 'interfaces'
import { Trash2 } from 'lucide-react'
import { FC } from 'react'

interface Props {
	view: string
	files: any
	fileSort: {
		field: keyof IFile
		order: string
	}
	isReadOnly?: boolean
	company: ICompany
}

const AllCompanyFiles: FC<Props> = ({
	view,
	files,
	fileSort,
	isReadOnly,
	company
}) => {
	return (
		<div>
			{view === 'grid' && (
				<div className='grid grid-cols-1 gap-2 px-2 xl:grid-cols-2 2xl:grid-cols-3'>
					{[...files]
						.sort((a, b) => {
							if (fileSort.order === 'asc') {
								return a[fileSort.field]! > b[fileSort.field]! ? 1 : -1
							}
							return a[fileSort.field]! < b[fileSort.field]! ? 1 : -1
						})
						.map(file => {
							return (
								<div
									key={file.id}
									data-testid={`file-${file.name}`}
									className='flex items-center justify-between rounded border border-gray-200 bg-gray-100 p-1'
								>
									<div className='w-full items-center gap-2'>
										<FileViewer
											company={company}
											readonly={isReadOnly}
											file={file}
											onDelete={f => {
												FileService.deleteFile(f)
											}}
										/>
									</div>
								</div>
							)
						})}
				</div>
			)}

			<div className='flex flex-col gap-2 px-2 pb-10'>
				{view === 'list' && (
					<div className=' flex flex-col gap-2'>
						{[...files]
							.sort((a, b) => {
								if (fileSort.order === 'asc') {
									return a[fileSort.field]! > b[fileSort.field]! ? 1 : -1
								}
								return a[fileSort.field]! < b[fileSort.field]! ? 1 : -1
							})
							.map(file => (
								<div
									key={file.id}
									data-testid={`file-${file.name}`}
									className='flex items-center rounded border border-gray-200 bg-gray-100 px-3'
								>
									<div className='flex-1'>
										<FileViewer
											company={company}
											file={file}
											readonly={isReadOnly}
											onDelete={file => FileService.deleteFile(file)}
											content={
												<div className='flex-1 cursor-pointer whitespace-nowrap p-3 text-sm '>
													{file.name}
												</div>
											}
										/>
									</div>
									{!isReadOnly && (
										<div className=' flex items-center gap-2'>
											<div className='flex w-fit justify-end '>
												<DeleteModal
													deleteButtonId={`deleteFile-${file.name}`}
													title='Delete File'
													description={`Are you sure you want to delete ${file.name}?`}
													trigger={
														<Tooltip>
															<TooltipTrigger asChild>
																<Button
																	id={`file-${file.name}-delete-button`}
																	variant='ghost'
																	className='px-1 py-1'
																>
																	<Trash2 color='red' size={15} />
																</Button>
															</TooltipTrigger>
															<TooltipContent>
																<>Delete {file.name}</>
															</TooltipContent>
														</Tooltip>
													}
													onDelete={() => FileService.deleteFile(file)}
												/>
											</div>
										</div>
									)}
								</div>
							))}
					</div>
				)}
			</div>

			{files.length === 0 && (
				<div
					data-testid='no-files-label'
					className='flex h-20 items-center justify-center'
				>
					<div className='text-gray-500'>No files uploaded</div>
				</div>
			)}
		</div>
	)
}

export default AllCompanyFiles
