export enum CoinType {
  USDC = "USDC"
};

export enum Network {
  "ERC-20" = "ERC-20" 
}

export function getAllNetworks() {
  return [
    {
      label: Network["ERC-20"],
      value: Network["ERC-20"]
    }
  ]
}
export function getAllCoinType() {
  return [
    {
      label: CoinType.USDC,
      value: CoinType.USDC
    }
  ]
}