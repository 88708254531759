/* eslint-disable @typescript-eslint/max-params */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { collection, doc, getDoc, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";

import { ActivityService } from "features/activity/service/activity.service";
import { IActivity } from "features/activity/interfaces/actvitiy.interface";
import { ITableColumn } from "../interfaces/datatable.interface";
import Logger from "lib/logger";
import { firestore } from "lib/firebase";
import { nanoid } from "nanoid";
import { store } from 'app/store'
import { toast } from "sonner";
import { useState } from "react";


export default function CustomColumnAPI() {
  const columnReference = collection(firestore, 'columns')
  const customFieldRef = collection(firestore, 'custom_fields')

  const [columns, setColumns] = useState<ITableColumn[]>([])

  const createColumn = async (column: ITableColumn) => {
    try {
      const columnRef = doc(columnReference, column.id);
      await setDoc(columnRef, column);
      return column.id;
    } catch (error) {
      Logger.error("Error adding document:", error);
    }
  };

  const createCustomFields = async (column: ITableColumn) => {
    try {
      const columnRef = doc(customFieldRef, column.id);
      await setDoc(columnRef, column);
      return column.id;
    } catch (error) {
      Logger.error("Error adding document:", error);
    }
  };

  const listenToColumns = (entity: string) => {
    try {
      const q = query(customFieldRef, where("type", "==", entity));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setColumns(data);
      });

      return unsubscribe; // Return the unsubscribe function to stop listening when needed
    } catch (error) {
      Logger.error('Error listening to files:', error);
      throw error;
    }
  };

  // Function to add or update a key-value pair in the custom_fields map
  const upsertCustomField = async (entity: any, key: string, value: string, model: string) => {
    const { authUserData, authUserFundData } = store.getState().authentication
    if (!authUserData || !authUserFundData) return

    try {
      const modelRef = model === 'companies' ? 'companies' : (model === 'contacts' ? 'contacts' : 'lps');
      const entityRef = doc(firestore, modelRef, entity.id);
      const entitySnap = await getDoc(entityRef);

      if (entitySnap.exists()) {
        // Get the current custom_fields map or initialize it if it doesn't exist
        const currentData = entitySnap.data();
        const customFields = currentData.custom_fields ?? {};

        if (typeof customFields !== 'object' || Array.isArray(customFields)) {
          Logger.error('Invalid custom_fields data type:', customFields);
          throw new Error('custom_fields should be an object');
        }

        // Add or update the key-value pair
        customFields[key.toLowerCase()] = value;

        // Update the document with the new custom_fields map
        await updateDoc(entityRef, { custom_fields: customFields });
        toast.success(`${key} updated successfully`);

        // Add the activity to the activity feed
        const baseActivity: Omit<IActivity, 'company' | 'contact' | 'limitedPartner'> = {
          id: nanoid(),
          user: authUserData,
          fund: authUserFundData,
          activity: `updated ${key}`,
          description: `new value, ${value}`,
          icon: '',
          summary: entity.name,
          createdAt: new Date().toISOString()
        };

        let activity: IActivity;

        if (model === 'companies') {
          activity = {
            ...baseActivity,
            company: {
              id: entity.id,
              name: entity.name
            }
          };
        } else if (model === 'contacts') {
          activity = {
            ...baseActivity,
            contact: {
              id: entity.id,
              name: entity.name
            }
          };
        } else {
          activity = {
            ...baseActivity,
            limitedPartner: {
              id: entity.id,
              name: entity.name
            }
          };
        }

        await ActivityService.add(activity);


      } else {
        Logger.info('No such entity found.');
      }
    } catch (error) {
      Logger.error('Error upserting custom field:', error);
      toast.error('Failed to update custom field.');
    }
  };

  return {
    createColumn,
    createCustomFields,
    listenToColumns,
    columns,
    upsertCustomField: (entity: any, key: string, value: string, model: string) => upsertCustomField(entity, key, value, model)
  }
}