/* eslint-disable no-param-reassign */
import axios from 'axios'
import { auth } from './firebase'

const axiosInstance = axios.create({
	// Set your base API URL here
	baseURL: import.meta.env.VITE_SERVER_URL as string
})

axiosInstance.interceptors.request.use(
	async config => {
		// Fetch the Firebase ID token from your authentication system
		const idToken = await auth.currentUser?.getIdToken()

		// Add the ID token to the Authorization header
		config.headers.Authorization = `Bearer ${idToken}`

		return config
	},
	error => {
		return Promise.reject(error)
	}
)

export default axiosInstance
