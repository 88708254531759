/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-extraneous-class */
import { doc, getDoc } from "firebase/firestore";

import Errorhandler from "lib/sentry";
import Logger from "lib/logger";
import { firestore } from "lib/firebase";
import { setStatusData } from "./status.slice";
import { store } from "app/store";

export class AppStatusService {
  private static readonly ref = doc(firestore, "app_statuses/statuses");

  public static async getStatuses() {
    try {
      const snapshot = await getDoc(this.ref);
      const data = snapshot.data();

      store.dispatch(setStatusData(data as Record<string, string>));
    } catch (error: any) {
      Logger.error("Error getting status", error);
      Errorhandler.captureException(error);
    }
  }
}