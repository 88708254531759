import clsx from 'clsx'
import { Input } from 'components/ui/input'
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue
} from 'components/ui/select'
import useListenToPartners from 'features/partners/hooks/usePartners.hook'
import type { PartnerData } from 'interfaces'
import { useEffect, useState } from 'react'

interface Props {
	onSelect: (value: PartnerData) => void
	defaultValue?: PartnerData
	placeholder?: string
	isCombo?: boolean
	content?: React.ReactNode
	groupName?: string
	className?: string
	label?: string
	allowSearch?: boolean
	searchPlaceHolder?: string
	isReadOnly?: boolean
}

export function PartnerSelector({
	defaultValue,
	onSelect,
	placeholder,
	label,
	allowSearch = false,
	searchPlaceHolder,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	isCombo = true,
	content,
	groupName,
	className = 'w-[280px]',
	isReadOnly
}: Props) {
	const { partners } = useListenToPartners({})
	const [optionsResult, setOptionsResult] = useState(partners)

	function onSearchChange(
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) {
		const { value } = e.target
		const result = partners.filter(option =>
			option.name.toLowerCase().includes(value.toLowerCase())
		)
		setOptionsResult(result)
	}

	useEffect(() => {
		setOptionsResult(partners)
	}, [partners, defaultValue])

	if (isReadOnly) {
		return (
			<div>
				{label && (
					<div className='mb-2 block text-xs font-bold text-gray-700'>
						{label}
					</div>
				)}
				<div className='group flex w-fit items-center gap-1'>
					{partners.find(option => option.id === defaultValue?.id)?.name || '-'}
				</div>
			</div>
		)
	}

	return (
		<div>
			{label && (
				<div className='mb-2  block text-xs font-bold text-gray-700'>
					{label}
				</div>
			)}
			<Select
				defaultValue={defaultValue?.id}
				onValueChange={e => {
					const partner = partners.find(_partner => _partner.userId === e)
					onSelect(partner as PartnerData)
				}}
			>
				{content ? (
					<SelectTrigger
						defaultValue='onlyContent'
						className='border-none border-transparent shadow-none'
					>
						{content}
					</SelectTrigger>
				) : (
					<SelectTrigger className={clsx(className)}>
						<SelectValue placeholder={placeholder} />
					</SelectTrigger>
				)}
				<SelectContent className='z-[999999]'>
					{allowSearch && (
						<Input
							placeholder={searchPlaceHolder ?? 'Search'}
							className='mb-2'
							onChange={e => {
								onSearchChange(e)
							}}
						/>
					)}

					{optionsResult.length === 0 && (
						<div className='text-center text-gray-500'>No results found</div>
					)}

					<SelectGroup>
						{groupName && <SelectLabel>{groupName}</SelectLabel>}
						{optionsResult.map(option => (
							<SelectItem key={option.id} value={option.userId ?? ''}>
								{option.name}
							</SelectItem>
						))}
					</SelectGroup>
				</SelectContent>
			</Select>
		</div>
	)
}
