/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
	CheckSquare,
	Dot,
	Forward,
	NotebookPen,
	NotepadText,
	Paperclip,
	Reply
} from 'lucide-react'
import type { IAttachment, IEmailMessage } from '../interfaces/email.interface'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import {
	setAction,
	setMailWindowState,
	setMessage,
	setMessageId,
	setSubject,
	setThreadId,
	setTo
} from '../redux/mailcompose.slice'
/* eslint-disable unicorn/prefer-spread */
/* eslint-disable unicorn/consistent-destructuring */
/* eslint-disable @typescript-eslint/max-params */
/* eslint-disable @typescript-eslint/no-shadow */
import { useAppDispatch, useAppSelector } from 'app/hooks'

import AddUpdate from 'features/updates/views/AddUpdate'
import { AuthContext } from 'context/AuthContext'
import Button from 'components/Buttons/Button'
import CompanyFromEmail from '../modules/CompanyFromEmail/views/CompanyFromEmail'
import { CompanyUpdateService } from 'features/updates/services/company_updates.service'
import CreateTask from 'features/tasks/view/CreateTask'
import { EmailPeopleHoverCard } from '../components/EmailPeopleHoverCard'
import {
	NoteEditorState,
	NoteType,
	type IDraftNote
} from 'features/notes/interfaces/notes.interface'
import LimitedPartnerFromEmail from '../modules/LimitedPartnerFromEmail/views/LimitedPartnerFromEmail'
import { addDraftNote } from 'features/notes/redux/draft_notes.slice'
import clsxm from 'utils/clsxm'
import { nanoid } from 'nanoid'
import { purifyHtml } from 'utils/purifyHtml'
import { renderDate } from 'utils/date.util'
import { selectSelectedThread } from '../redux/threads.slice'
import { store } from 'app/store'
import { useContext } from 'react'
import Logger from 'lib/logger'

interface Props {
	data: IEmailMessage
	wrapperClass?: string
	isReadOnly?: boolean
}

export default function AllEmailComponent({
	data,
	wrapperClass,
	isReadOnly
}: Props) {
	const {
		snippet,
		labelIds,
		id,
		threadId,
		textHtml,
		textPlain,
		createdAt,
		attachments,
		subject
	} = data

	const dispatch = useAppDispatch()
	const { authUser } = useContext(AuthContext)

	const selectedThread = useAppSelector(selectSelectedThread)

	return (
		<div
			className={clsxm(
				'mb-2 cursor-pointer rounded px-4 py-2 pb-4 transition-all hover:bg-gray-50',
				wrapperClass
			)}
		>
			<div className='flex items-center gap-3'>
				{!isReadOnly && (
					<div className='flex w-max flex-wrap items-center gap-3'>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant='ghost'
									onClick={() => {
										Logger.info(data)
										if (!authUser) return
										const noteData: IDraftNote = {
											id: nanoid(),
											windowState: NoteEditorState.RESTORED,
											title: subject,
											note: textHtml,
											creator: {
												id: authUser.id,
												name: authUser.name,
												photoUrl: authUser.photoUrl ?? ''
											},
											fund: authUser.fund,
											noteType: NoteType.Company
										}

										if (data.companies?.[0]) {
											noteData.company = data.companies?.[0]
											noteData.noteType = NoteType.Company
										}

										if (data.limitedPartners?.[0]) {
											noteData.limitedPartner = data.limitedPartners?.[0]
											noteData.noteType = NoteType.LimitedPartner
										}

										dispatch(addDraftNote(noteData))
									}}
									className='px-1 py-1 text-lg'
								>
									<NotebookPen size={15} />
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								<p>Create new note</p>
							</TooltipContent>
						</Tooltip>
						<AddUpdate
							showCompanySelector
							email={data}
							company={data.companies?.[0]}
							trigger={
								<Tooltip>
									<TooltipTrigger asChild>
										<Button variant='ghost' className='px-1 py-1 text-lg'>
											<NotepadText size={15} />
										</Button>
									</TooltipTrigger>
									<TooltipContent>
										<p>Company Update</p>
									</TooltipContent>
								</Tooltip>
							}
							onAddUpdate={(update, files, company, email) => {
								if (!authUser) return
								CompanyUpdateService.createCompanyUpdate(
									update,
									files,
									authUser,
									{ id: company.id, name: company.name ?? '' },
									email
								)
							}}
						/>
						<CompanyFromEmail
							email={data}
							companies={selectedThread?.companies}
						/>
						<LimitedPartnerFromEmail
							email={data}
							limitedPartners={selectedThread?.limitedPartners}
						/>
						<CreateTask
							task={{
								mail: data
							}}
							trigger={
								<Tooltip>
									<TooltipTrigger asChild>
										<Button
											variant='ghost'
											onClick={() => {}}
											className='px-1 py-1 text-lg'
										>
											<CheckSquare size={15} />
										</Button>
									</TooltipTrigger>
									<TooltipContent>
										<p>Create task</p>
									</TooltipContent>
								</Tooltip>
							}
						/>

						<Dot size={5} />

						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant='ghost'
									onClick={() => {
										store.dispatch(setSubject(`Re: ${subject}`))
										store.dispatch(setTo([data.from.email]))
										store.dispatch(setAction('reply'))
										store.dispatch(setThreadId(threadId))
										store.dispatch(setMessageId(id))
										store.dispatch(
											setMessage(textHtml || textPlain || snippet || '')
										)
										store.dispatch(setMailWindowState('restore'))
									}}
									className='px-1 py-1 text-lg'
								>
									<Reply size={15} />
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								<p>Reply</p>
							</TooltipContent>
						</Tooltip>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant='ghost'
									onClick={() => {
										store.dispatch(setSubject(`Fwd: ${subject}`))
										store.dispatch(setTo([]))
										store.dispatch(
											setMessage(textHtml || textPlain || snippet || '')
										)
										store.dispatch(setMailWindowState('restore'))
									}}
									className='px-1 py-1 text-lg'
								>
									<Forward size={15} />
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								<p>Forward</p>
							</TooltipContent>
						</Tooltip>
					</div>
				)}
			</div>
			<div className='mt-2 flex items-center gap-2 text-xs text-gray-900'>
				to:
				<EmailPeopleHoverCard
					variant='recipients'
					email={data}
					only='name'
					triggerContentClassName={{ name: 'text-xs', email: 'text-xs' }}
				/>
			</div>

			<div className='mt-1 flex gap-2'>
				{labelIds
					.filter((id: string) => id !== 'SENT')
					.map((label: string) => (
						<div
							key={label}
							className='mb-2 rounded-lg bg-gray-100 px-2 text-[0.7rem] text-gray-700 '
						>
							{label.replace('_', ' ')}
						</div>
					))}
			</div>

			<div className='mb-6 mt-5 text-gray-800'>
				<p
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: purifyHtml(textHtml || textPlain || snippet || '--')
					}}
				/>
			</div>

			<div className='flex w-full justify-between'>
				<div className='text-xs text-gray-500'>
					{renderDate(new Date(createdAt))}
				</div>
			</div>

			{attachments.length > 0 && (
				<div className='mt-5 flex flex-wrap gap-2 border-t pt-2'>
					{attachments.map((attachment: IAttachment) => (
						<div
							key={attachment.filename}
							className='flex items-center gap-2 text-xs text-gray-800'
						>
							<Paperclip />
							<div>{attachment.filename}</div>
						</div>
					))}
				</div>
			)}

			{process.env.NODE_ENV === 'development' && (
				<>
					<div className='mt-10'>Thread ID: {threadId}</div>
					<div className=''>ID: {id}</div>
				</>
			)}
		</div>
	)
}
