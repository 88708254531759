/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import type React from 'react';
import type { FoldersInterface } from '../interface/folders.interface';


interface PageProps {
  x: number;
  y: number;
  contextMenuRef: React.RefObject<HTMLDivElement>;
  togglePrompts: () => void
  lastFolder: FoldersInterface
  setSelectedFiles: React.Dispatch<React.SetStateAction<any>>
  openModal: () => void
}

export default function CustomContextMenu({ x, y, contextMenuRef, togglePrompts, lastFolder, setSelectedFiles, openModal }: PageProps) {
  const accept = '.pdf, .xlsx, .csv, .ppt, .doc, .docx, .eml, image/*, video/*'
  const multiple = true
  const id = 'file-upload'



  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = [...(e.target.files ?? [])]
    if (files.length === 0) return

    setSelectedFiles((prevSelectedFiles: any) => [
      ...prevSelectedFiles,
      ...files
    ])

    openModal()
  };

  return (
    <div
      ref={contextMenuRef}
      className="fixed z-50" style={{ top: `${y + 2}px`, left: `${x + 2}px` }}>
      <div className="text-xs bg-white shadow-md rounded-md p-3 w-[180px] z-50">
        <div className='hover:cursor-pointer'>
          <a
            onClick={togglePrompts}
            href="#"
            className="flex items-center pl-3 justify-start align-middle align-center text-center"
          >
            <div className="w-fit mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-blue-600">
                <path d="M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z" />
              </svg>
            </div>
            {lastFolder ? 'Create Sub Folder' : 'Create New Folder'}
          </a>
          {lastFolder && (
            <>
              <hr className='my-3' />
              <label htmlFor={id}
                className="flex items-center pl-3 justify-start align-middle align-center text-center hover:cursor-pointer"
              >
                <input
                  id={id}
                  hidden
                  type="file"
                  name=""
                  accept={accept}
                  multiple={multiple}
                  onChange={handleChange}
                />
                <div className="w-fit mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 text-blue-600">
                    <path fillRule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875Zm6.905 9.97a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72V18a.75.75 0 0 0 1.5 0v-4.19l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z" clipRule="evenodd" />
                    <path d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
                  </svg>
                </div>
                Upload Files
              </label>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
