/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue
} from 'components/ui/select'
import { useEffect, useState } from "react"

import Errorhandler from 'lib/sentry'
import type { IContact } from "interfaces"
import { Input } from "components/ui/input"
import Logger from 'lib/logger'
import axiosInstance from "lib/axios"
import { nanoid } from "nanoid"

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  onSelect: (value?: any) => void
  label?: string
  defaultValue?: any
}

export default function ContactSearchSelector({onSelect, defaultValue, label = 'Companies'}: Props) {

	const [contacts, setContacts] = useState<IContact[]>([])

  const options = contacts.map((contact) => ({
    label: contact.name || contact.email || '',
    value: contact
  }))

  const [optionsResult, setOptionsResult] = useState(options)
	const [searchValue, setSearchValue] = useState('')

	function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
		const { value } = e.target
		setSearchValue(value)
		const result = options.filter(option =>
			option.label.toLowerCase().includes(value.toLowerCase())
		)
		setOptionsResult(result)
	}

	useEffect(() => {
		setOptionsResult(options)
	}, [options])

  useEffect(() => {
    const search = async () => {
      try{
        const {data} = await axiosInstance.get(`/search?index=contacts&query=${searchValue}`)
        setContacts(data.data)
      } catch (error: any) {
        Logger.error(error)
        Errorhandler.captureException(error) 
      }
    }

    search()
  }, [searchValue])

	useEffect(() => {
		setSearchValue('')
	}, [])
  
  return (
    <div>
      <label className='block text-sm font-medium text-gray-700'>{label}</label>
      <Select
				defaultValue={defaultValue}
				onValueChange={e => {
					onSelect(e)
				}}
			>
        <SelectTrigger className=''>
          <SelectValue placeholder={defaultValue} />
        </SelectTrigger>
				
				<SelectContent className='z-[999999] min-h-8'>
          <Input
            placeholder="Search"
            className='mb-2 static w-full'
            onChange={e => {
              onSearchChange(e as React.ChangeEvent<HTMLInputElement>)
            }}
          />
				
					{optionsResult.length === 0 && (
						<div className='text-center text-gray-500'>No results found</div>
					)}
					{optionsResult.length === 0 &&
						searchValue &&
						searchValue.trim() !== '' && (
							<SelectGroup>
								<SelectItem key={nanoid()} value={searchValue}>
									{searchValue}
								</SelectItem>
							</SelectGroup>
						)}
					<SelectGroup>
						{optionsResult.map(option => (
							<SelectItem key={nanoid()} value={option.value as any}>
								{option.label}
							</SelectItem>
						))}
					</SelectGroup>
				</SelectContent>
			</Select>
    </div>
  );
}