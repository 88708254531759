import { serverTimestamp } from 'firebase/firestore'
import type { ICompanyFormSection } from '../../../companies/interfaces/company.interface'
import { ECustomFormTemplateStatus } from '../../../companies/interfaces/company.interface'
import {
	ECustomCompanyFormFieldType,
	type ICustomCompanyFormTemplate
} from 'features/companies/interfaces/company.interface'
import { uniqueId } from 'lodash'

export const DEFAULT_TEMPLATE_ID = 'default-company-form-configuration-template'
export const DEFAULT_SECTION_ID = 'default-company-form-section-id'
export const DEFAULT_COMPANY_FORM_TEMPLATE: ICustomCompanyFormTemplate = {
	id: DEFAULT_TEMPLATE_ID,
	name: 'Default',
	sections: [
		{
			id: DEFAULT_SECTION_ID,
			name: 'Company Information',
			description:
				'Provide your company information as stated on official documents',
			isSystem: true,
			fields: [
				{
					id: uniqueId('field_'),
					label: 'Company name',
					field: 'name',
					isSystem: true,
					subText: '',
					type: ECustomCompanyFormFieldType.SingleLine,
					fundId: uniqueId('fund_'),
					isRequired: true
				},
				{
					id: uniqueId('field_'),
					label: 'Description',
					field: 'description',
					isSystem: true,
					subText: '',
					type: ECustomCompanyFormFieldType.Multiline,
					fundId: uniqueId('fund_'),
					isRequired: true
				},
				{
					id: uniqueId('field_'),
					label: 'Tags',
					field: 'tags',
					isSystem: true,
					subText: '',
					type: ECustomCompanyFormFieldType.Tag,
					fundId: uniqueId('fund_'),
					isRequired: true
				},
				{
					id: uniqueId('field_'),
					label: 'Industry',
					field: 'industries',
					isSystem: true,
					subText: '',
					type: ECustomCompanyFormFieldType.Industry,
					fundId: uniqueId('fund_'),
					isRequired: true
				}
			],
			index: 0
		}
	],
	fund: {
		id: '',
		name: '',
		slug: '',
		website: ''
	},
	status: ECustomFormTemplateStatus.Draft,
	createdAt: serverTimestamp(),
	updatedAt: serverTimestamp()
}

export const MOCK_FORM_SECTIONS: ICompanyFormSection[] = [
	{
		id: '1',
		description: 'hey',
		name: 'Section 1',
		fundId: 'fund1',
		createdAt: '',
		updatedAt: '',
		isSystem: true,
		index: 1,
		fields: [
			{
				id: '1',
				label: 'First name',
				field: 'firstName',
				type: ECustomCompanyFormFieldType.SingleLine,
				fundId: 'fund1',
				isRequired: true,
				isDeleted: false,
				createdAt: new Date().toDateString(),
				updatedAt: new Date().toDateString()
			},
			{
				id: '2',
				label: 'profile url',
				field: 'profileUrl',
				type: ECustomCompanyFormFieldType.Link,
				fundId: 'fund1',
				isRequired: true,
				isDeleted: false,
				createdAt: new Date().toDateString(),
				updatedAt: new Date().toDateString()
			},
			{
				id: '3',
				label: 'Tags',
				field: 'tag',
				type: ECustomCompanyFormFieldType.Tag,
				fundId: 'fund1',
				isRequired: true,
				isDeleted: false,
				createdAt: new Date().toDateString(),
				updatedAt: new Date().toDateString()
			},
			{
				id: '4',
				label: 'story',
				field: 'story',
				type: ECustomCompanyFormFieldType.Multiline,
				fundId: 'fund1',
				isRequired: true,
				isDeleted: false,
				createdAt: new Date().toDateString(),
				updatedAt: new Date().toDateString()
			},
			{
				id: '5',
				label: 'Dropdown',
				field: 'dropdown',
				type: ECustomCompanyFormFieldType.Dropdown,
				fundId: 'fund1',
				isRequired: true,
				isDeleted: false,
				createdAt: new Date().toDateString(),
				updatedAt: new Date().toDateString()
			},
			{
				id: '6',
				label: 'Country',
				field: 'country',
				type: ECustomCompanyFormFieldType.Dropdown,
				fundId: 'fund1',
				isRequired: true,
				isDeleted: false,
				createdAt: new Date().toDateString(),
				updatedAt: new Date().toDateString()
			},
			{
				id: '7',
				label: 'Region',
				field: 'region',
				type: ECustomCompanyFormFieldType.Dropdown,
				fundId: 'fund1',
				isRequired: true,
				isDeleted: false,
				createdAt: new Date().toDateString(),
				updatedAt: new Date().toDateString()
			},
			{
				id: '6',
				field: 'Deck',
				label: 'Deck',
				type: ECustomCompanyFormFieldType.File,
				fundId: 'fund1',
				isRequired: true,
				isDeleted: false,
				createdAt: new Date().toDateString(),
				updatedAt: new Date().toDateString()
			}
		]
	}
]
