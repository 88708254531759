/* eslint-disable react/no-array-index-key */
import { Field, Label, Select, Switch } from '@headlessui/react'
import Button from 'components/Buttons/Button'
import TagInput from 'components/Inputs/TagInput'
import SideDrawer from 'components/SideDrawer'

import { Input } from 'components/ui/input'
import { selectAuthUserFund } from 'features/authentication/authentication.slice'
import { ECustomCompanyFormFieldType } from 'features/companies/interfaces/company.interface'
import type {
	ICustomCompanyFormField,
	ICustomCompanyFormTemplate
} from 'features/companies/interfaces/company.interface'
import { doc, setDoc } from 'firebase/firestore'
import { firestore } from 'lib/firebase'

import { toCamelCase } from 'lib/utils'
import { nanoid } from 'nanoid'
import { useState, type Dispatch, type SetStateAction } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'sonner'

import clsxm from 'utils/clsxm'
import type { IOptionChangeEvent } from './OptionListCreator'
import OptionListCreator from './OptionListCreator'

interface IAddCustomFieldModalProps {
	template: ICustomCompanyFormTemplate | null
	sectionIndex: number
	show?: boolean
	setShow: Dispatch<SetStateAction<boolean>>
}

function AddCustomFieldModal({
	template,
	sectionIndex,
	show,
	setShow
}: Readonly<IAddCustomFieldModalProps>) {
	const [creatingField, setCreatingField] = useState(false)

	const [optionChangeEvent, setOptionChangeEvent] =
		useState<IOptionChangeEvent>()
	// const [tags, setTags] = useState<string>('')

	const authFund = useSelector(selectAuthUserFund)

	const [newField, setNewField] = useState<ICustomCompanyFormField>({
		id: nanoid(),
		field: '',
		type: ECustomCompanyFormFieldType.SingleLine,
		fundId: authFund?.id ?? '',
		label: '',
		isRequired: true,
		createdAt: new Date().toString(),
		updatedAt: new Date().toString(),
		isSystem: false,
		options: [],
		tags: []
	})

	function handleChange(field: keyof ICustomCompanyFormField, value: unknown) {
		const update = structuredClone(newField)
		update[field] = value as never

		if (field === 'label') {
			update.field = toCamelCase(value as string)
		}

		setNewField(update)
	}

	function validateNewField() {
		const newFieldCopy = structuredClone(newField)

		if (newFieldCopy.label.trim().length === 0) {
			toast.error('Provide name of field')
			return null
		}

		if (optionChangeEvent?.hasDuplicates) {
			toast.error('Remove duplicate options')
			return null
		}

		if (
			newFieldCopy.type === ECustomCompanyFormFieldType.Dropdown &&
			newFieldCopy.options?.length === 0
		) {
			toast.error('Add at least one option')
			return null
		}

		return newFieldCopy
	}

	async function createNewField() {
		const newFieldCopy = validateNewField()
		if (newFieldCopy === null) return

		setCreatingField(true)
		const sectionsUpdate = structuredClone(template)?.sections ?? []
		if (sectionsUpdate.length === 0) {
			console.error('Template must have at least one section')
			return
		}

		sectionsUpdate[sectionIndex].fields.push(newFieldCopy)

		try {
			const templateDoc = doc(
				firestore,
				`company_form_templates/${template?.id}`
			)
			await setDoc(templateDoc, { sections: sectionsUpdate }, { merge: true })
			toast.success('Field added')
			setNewField({
				id: nanoid(),
				field: '',
				type: newFieldCopy.type,
				fundId: authFund?.id ?? '',
				label: '',
				isRequired: true,
				createdAt: new Date().toString(),
				updatedAt: new Date().toString(),
				isSystem: false,
				options: [],
				tags: []
			})

			optionChangeEvent?.clear()
		} catch (error) {
			console.error(error)
			toast.error('Field creation failed')
		} finally {
			setCreatingField(false)
		}
	}

	function handleOptionsChange(change: IOptionChangeEvent) {
		setNewField(prev => ({ ...prev, options: change.options }))
		setOptionChangeEvent(change)
	}

	return (
		<SideDrawer
			slideFrom='right'
			show={show}
			title='New field'
			setShow={setShow}
			dialogPanelClass={clsxm('max-w-md')}
		>
			<div className='flex flex-col gap-6'>
				<div className={clsxm('')}>
					<Input
						className={clsxm('border-gray-300 shadow-none')}
						value={newField.label}
						label='Field name'
						required
						onChange={evt => handleChange('label', evt.target.value)}
						placeholder='Field name'
					/>
				</div>
				<div className={clsxm('')}>
					<Field>
						<Label className='text-sm/6 font-medium text-black'>
							Input type
						</Label>

						<div className='relative'>
							<Select
								className={clsxm(
									'mt-1 block w-full appearance-none rounded border-gray-300 bg-white px-3 py-1.5 text-sm/6 text-black',
									'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
									// Make the text of each option black on Windows
									'capitalize *:text-black'
								)}
								value={newField.type ?? ''}
								onChange={evt => handleChange('type', evt.target.value)}
							>
								{[
									ECustomCompanyFormFieldType.SingleLine,
									// ECustomCompanyFormFieldType.Industry,
									ECustomCompanyFormFieldType.Dropdown,
									// ECustomCompanyFormFieldType.MultiSelect,
									ECustomCompanyFormFieldType.Multiline,
									ECustomCompanyFormFieldType.RichEditor,
									// ECustomCompanyFormFieldType.File,
									ECustomCompanyFormFieldType.Link
									// ECustomCompanyFormFieldType.Tag
								].map(option => (
									<option value={option} key={option}>
										{option}
									</option>
								))}
							</Select>
						</div>
					</Field>

					{newField.type === ECustomCompanyFormFieldType.Tag && (
						<Field className={clsxm('mt-5')}>
							<Label className='text-sm/6 font-medium text-black'>Tags</Label>

							<TagInput />
						</Field>
					)}

					{[
						ECustomCompanyFormFieldType.Dropdown
						// ECustomCompanyFormFieldType.MultiSelect
					].includes(newField.type as ECustomCompanyFormFieldType) && (
						<OptionListCreator onChange={handleOptionsChange} />
					)}
				</div>

				<div className={clsxm('flex gap-2')}>
					<Switch
						checked={newField.isRequired}
						onChange={enabled => handleChange('isRequired', enabled)}
						className={clsxm(
							'group relative flex h-7 w-14 cursor-pointer rounded-full bg-white/10 p-1 transition-colors duration-200 ease-in-out focus:outline-none',
							' data-[checked]:bg-primary data-[focus]:outline-1 data-[focus]:outline-white',
							'border border-primary'
						)}
					>
						<span
							aria-hidden='true'
							className={clsxm(
								'pointer-events-none inline-block size-5 translate-x-0 rounded-full  ',
								'shadow-lg ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-7',
								newField.isRequired ? 'bg-white' : 'bg-primary'
							)}
						/>
					</Switch>
					<span>Require field</span>
				</div>
				<div>
					<Button
						isLoading={creatingField}
						disabled={creatingField}
						onClick={createNewField}
						variant='dark'
						className='ml-auto px-3 py-1.5'
					>
						Add field
					</Button>
				</div>
			</div>
		</SideDrawer>
	)
}

export default AddCustomFieldModal
