import {
	deleteBankAccount,
	getFirstBankAccount,
	updateBankAccount
} from '../api/bank-accounts.api'

import Errorhandler from 'lib/sentry'
import type { IBankAccount } from '../interface/BankAccount.interface'
import Logger from 'lib/logger'
import { toast } from 'sonner'

export async function deleteBankAccountById(id: string, isDefault?: boolean) {
	try {
		await deleteBankAccount(id)

		if (isDefault) {
			const first = await getFirstBankAccount()
			if (first) await updateBankAccount({ ...first, isDefault: true })
		}

		toast.success('Update deleted successfully')
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		toast.error(error.message ?? 'Failed to delete update')

		Logger.error(error)
		Errorhandler.captureException(error)
	}
}

// Update
export async function updateBankAccountById(
	id: string,
	data: Partial<IBankAccount>
) {
	try {
		await updateBankAccount({ ...data, id })
		toast.success('Update updated successfully')
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		toast.error(error.message ?? 'Failed to update update')

		Logger.error(error)
		Errorhandler.captureException(error)
	}
}
