import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import { Building } from 'lucide-react'
import Button from 'components/Buttons/Button'
import { ComboSelect } from 'components/shadcn/ComboSelect'
import type { CompanyData } from 'interfaces'
import { ENotificationStatus } from 'features/notifications/enums/notifications.enums'
import EmailInThread from 'features/mail/views/EmailInThread'
import type { IEmailMessage } from '../../../interfaces/email.interface'
import { ImSpinner10 } from 'react-icons/im'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { NotificationsService } from 'features/notifications/service/Notifications.service'
import axiosInstance from 'lib/axios'
import { cn } from '@udecode/cn'
import { nanoid } from 'nanoid'
import { toast } from 'sonner'
import useCreateCompany from 'features/companies/hooks/useCreateCompany'

interface Props {
	email: IEmailMessage
	companies?: CompanyData[]
}

export default function CompanyFromEmail({ email, companies }: Props) {
	const [isOpen, setIsOpen] = useState(false)
	const [isExtractingDoc, setIsExtractingDoc] = useState(false)
	const [extractedDoc, setExtractedDoc] = useState<boolean>(false)
	const { formik } = useCreateCompany({
		onSuccess: () => {
			setIsOpen(false)
		}
	})

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	const handleBeginCompanyDataExtraction = async () => {
		try {
			const notificationId = nanoid()

			NotificationsService.addCompanyFromEmailNotification({
				id: notificationId,
				status: ENotificationStatus.PROGRESS,
				message: 'Extracting company info from email',
				type: 'company_from_email',
				details: email.snippet,
				metadata: {
					emailId: email.id,
					threadId: email.threadId,
					email
				}
			})

			// call
			axiosInstance.get(
				`/messages/${email.id}/extract-company/${notificationId}`
			)

			setIsOpen(false)
			toast.success(
				'Company info extraction started. You can check the progress in the notifications tab.'
			)
		} catch (error) {
			Logger.log('Error', error)
			toast.error('Failed to extract company info from email')
		}
	}

	useEffect(() => {
		if (isOpen) return
		setExtractedDoc(false)
		setIsExtractingDoc(false)
	}, [isOpen])

	return (
		<>
			<Tooltip>
				<TooltipTrigger asChild>
					<Button
						id={`create-company-from-email-trigger-${email.id}`}
						variant='ghost'
						onClick={() => {
							openModal()
						}}
						className='px-1 py-1 text-lg disabled:opacity-45'
						disabled={(companies?.length || 0) > 0}
					>
						<Building size={15} />
					</Button>
				</TooltipTrigger>
				<TooltipContent>
					<p>
						{(companies?.length || 0) > 0
							? 'Company Already Exists'
							: 'Create Company'}
					</p>
				</TooltipContent>
			</Tooltip>

			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={() => {}}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-black/25' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<Dialog.Panel className='w-full max-w-5xl transform overflow-hidden rounded  bg-white p-6 text-left align-middle shadow-xl transition-all'>
									<Dialog.Title
										as='h3'
										className='text-lg font-medium leading-6 text-gray-900'
									>
										Create company from email
									</Dialog.Title>
									<div className='mt-5 max-h-[70vh] min-h-[20vh] overflow-auto'>
										<EmailInThread email={email} />

										{extractedDoc && (
											<div data-testid='create-company-from-email-form'>
												<div className='mt-5 border-t pt-5'>
													<Label>Select an attachment as deck</Label>
													<ComboSelect
														onSelect={value => {
															formik.setFieldValue('mainDeck', value)
														}}
														defaultValue={
															formik.values.deck || email.attachments[0]?.url
														}
														options={email.attachments.map(
															(attachment: any) => {
																return {
																	label: attachment.filename,
																	value: attachment.downloadUrl
																}
															}
														)}
													/>
												</div>

												<div className=' border-black pt-5'>
													<Label>Company Name</Label>
													<Input
														id='create-company-from-email-name'
														placeholder='Enter company name'
														onChange={e => {
															formik.setFieldValue('name', e.target.value)
														}}
														value={formik.values.name}
													/>
												</div>
												<div className='mt-5 flex gap-5'>
													<div className='flex-1'>
														<Label>Website</Label>
														<Input
															placeholder='Enter website'
															onChange={e => {
																formik.setFieldValue('website', e.target.value)
															}}
															value={formik.values.website}
														/>
													</div>
													<div className='flex-1'>
														<Label>Traction</Label>
														<Input
															placeholder='Enter company traction'
															onChange={e => {
																formik.setFieldValue('traction', e.target.value)
															}}
															value={formik.values.traction}
														/>
													</div>
												</div>
												<div className='mt-5 flex flex-col'>
													<Label>Description</Label>
													<textarea
														className={cn(
															'mt-1 flex rounded-md border border-gray-300'
														)}
														placeholder='Enter company description'
														onChange={e => {
															formik.setFieldValue(
																'description',
																e.target.value
															)
														}}
														value={formik.values.description}
													/>
												</div>

												<div className='mt-5 flex gap-5'>
													<div className='flex-1'>
														<Label>Company Stage</Label>
														<Input
															placeholder='Enter company stage'
															onChange={e => {
																formik.setFieldValue('stage', e.target.value)
															}}
															value={formik.values.stage}
														/>
													</div>
													<div className='flex-1'>
														<Label>Referral</Label>
														<Input
															placeholder='Enter referral'
															onChange={e => {
																formik.setFieldValue(
																	'referralMethod',
																	e.target.value
																)
															}}
															value={formik.values.referralMethod}
														/>
													</div>
												</div>

												<div className='mt-5 flex gap-5'>
													<div className='flex-1'>
														<Label>Founder Name</Label>
														<Input
															placeholder='Enter founder name'
															onChange={e => {
																formik.setFieldValue(
																	'founderName',
																	e.target.value
																)
															}}
															value={formik.values.founderName}
														/>
													</div>
													<div className='flex-1'>
														<Label>Founder Email</Label>
														<Input
															placeholder='Enter founder email'
															onChange={e => {
																formik.setFieldValue(
																	'founderEmail',
																	e.target.value
																)
															}}
															value={formik.values.founderEmail}
														/>
													</div>
												</div>
											</div>
										)}
									</div>

									<div className='mt-4 flex w-full items-center justify-between gap-3'>
										<div>
											{isExtractingDoc && (
												<div className='flex items-center gap-2 text-sm'>
													<ImSpinner10 className='animate-spin' />
													Extracting company info. This can take up to a
													minute...
												</div>
											)}
										</div>
										<div className='flex gap-3'>
											<Button
												type='button'
												variant='ghost'
												onClick={closeModal}
											>
												Cancel
											</Button>
											{extractedDoc && (
												<Button
													id='create-company-from-email-submit'
													disabled={isExtractingDoc || formik.isSubmitting}
													isLoading={formik.isSubmitting}
													type='button'
													onClick={() => {
														formik.handleSubmit()
													}}
												>
													Create Company
												</Button>
											)}

											{!extractedDoc && (
												<Button
													id='extract-company-info-from-email'
													type='button'
													isLoading={isExtractingDoc}
													disabled={isExtractingDoc}
													onClick={() => {
														handleBeginCompanyDataExtraction()
													}}
												>
													Extract Company Info
												</Button>
											)}
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}
