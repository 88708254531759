import type { Fund, User } from 'interfaces'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { auth, firestore } from 'lib/firebase'
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore'
import { setAuthUser, setAuthUserFund } from './authentication.slice'

import { Errorhandler } from 'lib/sentry'
import Logger from 'lib/logger'
import type { UserCredential } from 'firebase/auth'
import axiosInstance from 'lib/axios'
import { getConvertedAmounts } from 'utils/currency'
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { store } from 'app/store'

/**
 * Logs in the user using Google authentication.
 *
 * @returns {Promise<void>} A Promise that resolves when the login process is complete.
 */
export const loginWithGoogle = async (): Promise<UserCredential> => {
	const provider = new GoogleAuthProvider()
	const user = await signInWithPopup(auth, provider)
	return user
}

/**
 * Logs out the user.
 *
 * @returns {Promise<void>} A promise that resolves when the user is successfully logged out.
 */
export const logout = async (): Promise<void> => auth.signOut()

/**
 * Retrieves the currently authenticated user.
 *
 * @returns {Promise<User | null>} A Promise that resolves with the currently authenticated user, or `null` if there is no authenticated user.
 */
export const getAuthUser = async (): Promise<User | null> => {
	if (!auth.currentUser) return null

	const documentReference = doc(firestore, `users/${auth.currentUser.uid}`)
	const snapshot = await getDoc(documentReference)
	const user = snapshot.data() as User
	return user
}

export const listenToAuthUser = (id: string) => {
	const ref = doc(firestore, `users/${id}`)

	const unsubscribe = onSnapshot(
		ref,
		docSnap => {
			const data = docSnap.data() as User
			store.dispatch(setAuthUser(data))
		},
		error => {
			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}

export const listenToAuthUserFund = (id: string) => {
	const ref = doc(firestore, `funds/${id}`)

	const unsubscribe = onSnapshot(
		ref,
		docSnap => {
			const data = docSnap.data() as any
			const processedData: Fund = {
				...data,
				capitalCallSummary: {
					totalCapitalCommitted: getConvertedAmounts(
						data.capitalCallSummary?.totalCapitalCommittedAmounts || {}
					),
					totalCapitalCalled: getConvertedAmounts(
						data.capitalCallSummary?.totalCapitalCalledAmounts || {}
					),
					totalCapitalRemaining: getConvertedAmounts(
						data.capitalCallSummary?.totalCapitalRemainingAmounts || {}
					),
					lastCapitalCalled: getConvertedAmounts(
						data.capitalCallSummary?.lastCapitalCalledAmounts || {}
					)
				}
			}
			store.dispatch(setAuthUserFund(processedData))
		},
		error => {
			Errorhandler.captureException(error)
			Logger.error(error)
		}
	)

	return unsubscribe
}

export const isEmailTaken = async (email: string) => {
	try {
		const { data } = await axiosInstance.get(`/users/${email}/exists`)
		return data
	} catch (error: any) {
		throw new Error(error.response.data.message)
	}
}

export const disconnectGmail = async (userId: string) => {
	const ref = doc(firestore, `users/${userId}`)
	await updateDoc(ref, { googleToken: {} })
}
