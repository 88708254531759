/* eslint-disable @typescript-eslint/no-explicit-any */
import { Check, CircleOff } from 'lucide-react'

import Button from 'components/Buttons/Button'
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { CaretSortIcon } from '@radix-ui/react-icons'
import type { ColumnDef } from '@tanstack/react-table'
import { DataTable } from 'features/dataTable/DataTable'
import EditPartner from './components/EditPartner'
import InviteNewUser from 'features/invitations/view/InviteNewUser'
import Logger from 'lib/logger'
import type { Partner } from 'interfaces/partner'
import { deletePartner } from './partner.api'
import { mapRoleToName } from 'utils/roles'
import useListenToPartners from 'features/partners/hooks/usePartners.hook'

const partnerColumns: ColumnDef<Partner>[] = [
	// Will be brought back once we find a use for the multi selector

	// {
	// 	id: 'select',
	// 	header: ({ table }) => (
	// 		<Checkbox
	// 			checked={
	// 				table.getIsAllPageRowsSelected() ||
	// 				(table.getIsSomePageRowsSelected() && 'indeterminate')
	// 			}
	// 			onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
	// 			aria-label='Select all'
	// 		/>
	// 	),
	// 	cell: ({ row }) => (
	// 		<Checkbox
	// 			checked={row.getIsSelected()}
	// 			onCheckedChange={value => row.toggleSelected(!!value)}
	// 			aria-label='Select row'
	// 		/>
	// 	),
	// 	enableSorting: false,
	// 	enableHiding: false
	// },
	{
		accessorKey: 'name',
		header: ({ column }) => (
			<Button
				variant='ghost'
				className='px-0'
				onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
			>
				Name
				<CaretSortIcon className='ml-2 h-4 w-4' />
			</Button>
		),
		cell: ({ row }) => (
			<div data-testid={row.original.name} className=''>
				{row.original.name}
			</div>
		)
	},
	{
		accessorKey: 'email',
		header: ({ column }) => (
			<Button
				className='px-0'
				variant='ghost'
				onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
			>
				Email
				<CaretSortIcon className='ml-2 h-4 w-4' />
			</Button>
		),
		cell: ({ row }) => (
			<div className='lowercase' data-testid={row.original.email}>
				{row.getValue('email')}
			</div>
		)
	},
	{
		accessorKey: 'role',
		header: ({ column }) => (
			<Button
				className='px-0'
				variant='ghost'
				onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
			>
				Role
				<CaretSortIcon className='ml-2 h-4 w-4' />
			</Button>
		),
		cell: ({ row }) => (
			<div className='' data-testid={row.original.role}>
				{mapRoleToName(row.original.role)}
			</div>
		)
	},
	{
		accessorKey: 'status',
		header: ({ column }) => (
			<Button
				className='px-0'
				variant='ghost'
				onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
			>
				Status
				<CaretSortIcon className='ml-2 h-4 w-4' />
			</Button>
		),
		cell: ({ row }) => (
			<div className='capitalize'>{row.getValue('status')}</div>
		)
	},
	{
		accessorKey: 'receivesEmails',
		header: ({ column }) => (
			<Button
				className='px-0'
				variant='ghost'
				onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
			>
				Receives Email
				<CaretSortIcon className='ml-2 h-4 w-4' />
			</Button>
		),
		cell: ({ row }) => (
			<div className='lowercase'>
				{row.getValue('receivesEmails') ? (
					<Check size={15} />
				) : (
					<CircleOff size={15} />
				)}
			</div>
		)
	},
	{
		accessorKey: 'lastActive',
		header: ({ column }) => (
			<Button
				className='px-0'
				variant='ghost'
				onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
			>
				Last Active
				<CaretSortIcon className='ml-2 h-4 w-4' />
			</Button>
		),
		cell: ({ row }) => (
			<div className='lowercase'>{row.getValue('lastActive') ?? '-'}</div>
		)
	},
	{
		accessorKey: 'actions',
		header: ({ column }) => (
			<Button
				className='px-0'
				variant='ghost'
				onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
			>
				Actions
			</Button>
		),
		cell: ({ row }) => (
			<div className='flex items-center gap-0'>
				<EditPartner editPartner={row.original} />
				<Button
					id={`delete-partner-${row.original.name}`}
					variant='ghost'
					className='px-2 text-red-500'
					onClick={() => {
						const res = confirm('Are you sure you want to delete this partner?')
						if (!res) return
						deletePartner(row.original.id)
					}}
				>
					Delete
				</Button>
			</div>
		),
		enableHiding: false,
		enableSorting: false
	}
]

export default function Partners(): JSX.Element {
	const { partners } = useListenToPartners({})

	return (
		<main className='px-5'>
			<div className='w-full  pt-5 text-left text-lg font-semibold'>
				<div className='flex justify-between'>
					Partners & Permissions
					<InviteNewUser />
				</div>
				<p className='text-sm font-normal text-gray-500'>
					Manage who accesses your fund dashboard.
				</p>
			</div>
			<DataTable
				entity='partner'
				columns={partnerColumns}
				data={partners}
				filterPlaceholder='Search partners...'
				showColumnToggle={false}
				onColumnUpdate={(updatedColumns: any) => {
					Logger.info({ updatedColumns })
				}}
				onColumnVisibilityChange={ (columnId: string, isVisible: boolean) => {
					Logger.info({ columnId, isVisible })
				}} />
		</main>
	)
}
