/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/destructuring-assignment */
import Button from 'components/Buttons/Button'
import DeleteModal from 'components/DeleteModal'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import FileViewer from 'features/fileviewers/views/FileViewer'
import { Pencil, Trash2 } from 'lucide-react'
import { toast } from 'sonner'
import { currencyFormatter } from 'utils/currencyformatter.util'
import { renderDate } from 'utils/date.util'
import { updateInvestment } from '../api/investments.api'
import type { IInvestment } from '../interfaces/investment.interface'
import { InvestmentService } from '../service/investment.service'
import NewInvestment from './NewInvestment'

export default function InvestmentCard({
	investment,
	isReadOnly
}: {
	investment: IInvestment,
	isReadOnly?: boolean
}) {
	const preLevelApproxOwnership = (
		(investment.amount /
			(investment.valuation + (investment.totalAmountRaised ?? 0))) *
		100
	).toFixed(2)
	const postLevelApproxOwnership = (
		(investment.amount / investment.valuation) *
		100
	).toFixed(2)
	const approxValuation =
		investment.amount + (investment.totalAmountRaised ?? 0)

	return (
		<div className='rounded-md border bg-gray-100 p-2'>
			<div className='mb-2 flex w-full justify-between'>
				<div className='font-semibold text-gray-700'>{investment.stage}</div>
				{!isReadOnly && <div className='flex items-center'>
					<NewInvestment
						company={investment.company}
						investment={investment}
						trigger={
							<Button
								id={`edit-investment-${investment.amount}`}
								variant='ghost' className='h-fit px-1 py-1 text-xs'>
								<Pencil size={14} />
							</Button>
						}
					/>
					<DeleteModal
						deleteButtonId={`delete-${investment.id}`}
						title='Delete Investment'
						description='Are you sure you want to delete this investment?'
						trigger={
							<div className='flex items-center px-2 py-1 text-red-500'>
								<Trash2 size={15} color='red' />
							</div>
						}
						onDelete={() => {
							InvestmentService.deleteInvestment(investment)
						}}
					/>
				</div>
				}
			</div>
			<div className='grid grid-cols-3 gap-2'>
				<div className='flex flex-col gap-1'>
					<div className='text-sm font-semibold'>Invested</div>
					<div className='rounded bg-blue-700 px-5 text-center text-white'>
						{currencyFormatter(investment.currency).format(investment.amount)}
					</div>
				</div>
				<div className='flex flex-col gap-1'>
					<div className='text-sm font-semibold'>Pre</div>
					<div className='rounded bg-blue-100 px-5 text-center text-blue-700'>
						{currencyFormatter(investment.currency).format(
							investment.valuation
						)}
					</div>
				</div>
				<div className='flex flex-col gap-1'>
					<div className='text-sm font-semibold'>Raised</div>
					<div className='rounded bg-blue-100 px-5 text-center text-blue-700'>
						{currencyFormatter(investment.currency).format(
							investment.totalAmountRaised ?? 0
						)}
					</div>
				</div>
				<div className='flex flex-col gap-1'>
					<div className='text-sm font-semibold'>Appox. Valuation</div>
					<div className='rounded bg-blue-100 px-5 text-center text-blue-700'>
						{currencyFormatter(investment.currency).format(approxValuation)}
					</div>
				</div>
				<div className='flex flex-col gap-1'>
					<div className='text-sm font-semibold'>Appox. Ownership</div>
					<div className='rounded bg-blue-100 px-5 text-center text-blue-700'>
						{investment.level === 'pre'
							? preLevelApproxOwnership
							: postLevelApproxOwnership}
						%
					</div>
				</div>
			</div>
			{investment.files && <div className='flex flex-col gap-2 mt-5'>
				<div className='font-semibold text-sm'>Files</div>
				{investment.files.map(file => (
					<div
						key={file.id}
						data-testid={`file-${file.name}`}
						className='flex items-center justify-between rounded border border-gray-200 bg-white'
					>
						<FileViewer
							file={file}
							onDelete={file => {
								updateInvestment({
									...investment,
									files: investment.files?.filter(f => f.id !== file.id)
								})
								toast.success('File deleted successfully')
							}}
							content={
								<div className='flex-1 cursor-pointer overflow-scroll whitespace-nowrap p-2 text-sm '>
									{file.name}
								</div>
							}
						/>
						{!isReadOnly && <div className=' flex items-center gap-2'>
							<div className='flex w-fit justify-end '>
								<DeleteModal
									deleteButtonId={`deleteFile-${file.name}`}
									title='Delete File'
									description={`Are you sure you want to delete ${file.name}?`}
									trigger={
										<Tooltip>
											<TooltipTrigger asChild>
												<Button
													id={`file-${file.name}-delete-button`}
													variant='ghost'
													className='px-1 py-1'
												>
													<Trash2 color='red' size={15} />
												</Button>
											</TooltipTrigger>
											<TooltipContent>
												<>Delete {file.name}</>
											</TooltipContent>
										</Tooltip>
									}
									onDelete={() => {
										updateInvestment({
											...investment,
											files: investment.files?.filter(f => f.id !== file.id)
										})
										toast.success('File deleted successfully')
									}}
								/>
							</div>
						</div>}
					</div>
				))}
			</div>}
			<div className='mt-5 flex w-full justify-end text-xs'>
				{renderDate(investment.date)}
			</div>
		</div>
	)
}
