import Logger from 'lib/logger'
import Errorhandler from 'lib/sentry'
import { toast } from 'sonner'
import {
	deleteCryptoAccount,
	getFirstCryptoAccount,
	updateCryptoAccount
} from '../api/crypto-accounts.api'

export async function deleteCryptoAccountById(id: string, isDefault?: boolean) {
	try {
		await deleteCryptoAccount(id)
		if (isDefault) {
			const first = await getFirstCryptoAccount()
			if (first) await updateCryptoAccount({ ...first, isDefault: true })
		}

		toast.success('Update deleted successfully')
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		toast.error(error.message ?? 'Failed to delete update')

		Logger.error(error)
		Errorhandler.captureException(error)
	}
}
