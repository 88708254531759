/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable consistent-return */

import { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import { LPService } from '../service/lp.service'
import LimitedPartnerTotalCommittedChart from '../components/LimitedPartnerTotalCommittedChart'
import StatusBadge from 'features/status/view/StatusBadge'
import { displayAmount } from 'utils/currency'
import { listenToAuthUserFund } from 'features/authentication/authentication.api'
import { selectAuthUserFund } from 'features/authentication/authentication.slice'
import { useAppSelector } from 'app/hooks'

export default function LimitedPartnerTotalCommitted(): JSX.Element {
	const { authUser } = useContext(AuthContext)
	const fund = useAppSelector(selectAuthUserFund)

	const [amountRemaining, setAmountRemaining] = useState({
		currency: 'USD',
		amount: 0
	})

	useEffect(() => {
		if (!authUser) return
		LPService.listenToFundLPCapitalCalls({
			authUser
		})
		const unsubscribeFund = listenToAuthUserFund(authUser.fund.id)
		return () => {
			unsubscribeFund()
		}
	}, [authUser])

	useEffect(() => {
		const totalCapitalCommitted =
			fund?.capitalCallSummary?.totalCapitalCommitted.amount || 0
		const totalCapitalCalled =
			fund?.capitalCallSummary?.totalCapitalCalled.amount || 0

		setAmountRemaining({
			currency: 'USD',
			amount: totalCapitalCommitted - totalCapitalCalled
		})
	}, [fund?.capitalCallSummary])

	return (
		<div className='flex flex-row justify-around gap-5 rounded-md border border-[#E4E5E8] bg-white px-6 py-6'>
			<div className='flex flex-col justify-center'>
				<div className='mb-2 flex flex-col border border-[#E4E5E8] py-1 text-center'>
					<div className='text-md flex flex-grow items-center justify-center gap-2 whitespace-nowrap border-b border-[#E4E5E8] px-5 pb-1 font-semibold'>
						Total Capital Committed <StatusBadge name='totalCommittedCapital' />
					</div>
					{displayAmount(fund?.capitalCallSummary?.totalCapitalCommitted)}
				</div>
				<div className='mb-2 flex flex-col border border-[#E4E5E8] py-1 text-center'>
					<div className='text-md flex flex-grow items-center justify-center gap-2 whitespace-nowrap border-b border-[#E4E5E8] px-5 pb-1 font-semibold text-blue-800'>
						Total Capital Called <StatusBadge name='totalCommittedCapital' />
					</div>
					{displayAmount(fund?.capitalCallSummary?.totalCapitalCalled)} (
					{(
						((fund?.capitalCallSummary?.totalCapitalCalled.amount || 1) /
							(fund?.capitalCallSummary?.totalCapitalCommitted.amount || 1)) *
						100
					)?.toFixed(1)}
					%)
				</div>
				<div className='mb-2 flex-col gap-4 border border-[#E4E5E8] py-1 text-center'>
					<div className='text-md flex flex-grow items-center justify-center gap-2 whitespace-nowrap border-b border-[#E4E5E8] pb-1 font-semibold text-[#ED9630]'>
						Amount remaining <StatusBadge name='totalCommittedCapital' />
					</div>
					<div className='pt-1'>
						{displayAmount(amountRemaining)} (
						{(
							(amountRemaining.amount /
								(fund?.capitalCallSummary?.totalCapitalCommitted.amount || 1)) *
							100
						)?.toFixed(1)}
						%)
					</div>
				</div>
			</div>
			<LimitedPartnerTotalCommittedChart
				data={[
					{
						name: 'Total Capital Called',
						color: '#175DCD',
						value: fund?.capitalCallSummary?.totalCapitalCalled?.amount || 0
					},
					{
						name: 'Amount remaining',
						color: '#ED9630',
						value: amountRemaining.amount || 0
					}
				]}
			/>
		</div>
	)
}
