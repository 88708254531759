/* eslint-disable @typescript-eslint/no-unsafe-return */
import type {
	ILimitedPartner,
	LimitedPartnerData,
	TagData,
	User
} from 'interfaces'
import {
	addLP,
	deleteLP,
	updateLP
} from 'features/limited_partners/api/lps.api'
import {
	collection,
	doc,
	getDoc,
	onSnapshot,
	orderBy,
	query,
	where
} from 'firebase/firestore'

import { ActivityService } from 'features/activity/service/activity.service'
import { Errorhandler } from 'lib/sentry'
import type { IActivity } from 'features/activity/interfaces/actvitiy.interface'
import type { ICapitalCall } from 'features/capital_call/interfaces/capitalCall.interface'
import Logger from 'lib/logger'
import { firestore } from 'lib/firebase'
import { getEmailCount } from 'features/mail/api/threads.api'
import { getLastEvent } from 'features/calendar_events/api/calender_events.api'
import { isEmailTaken } from 'features/authentication/authentication.api'
import { nanoid } from 'nanoid'
import { setCapitalCalls } from 'features/capital_call/redux/capital_calls.slice'
import { setFundLPCapitalCalls } from '../redux/lp.slice'
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-extraneous-class */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { store } from 'app/store'
import { toast } from 'sonner'

export class LPService {
	private static readonly ref = collection(firestore, 'lps')

	private static readonly capitalCallsRef = collection(
		firestore,
		'capital_calls'
	)

	public static getLimitedPartnerById = async (id: string): Promise<ILimitedPartner | null> => {
		try {
			const ref = doc(firestore, 'lps', id)
			const lpDoc = await getDoc(ref)
			if (!lpDoc.exists()) return null
			const lp = lpDoc.data() as ILimitedPartner
			return lp
		} catch (error: any) {
			Logger.error(error)
			Errorhandler.captureException(error)
			return null
		}
	}

	protected static getDescription(value: any) {
		if (Array.isArray(value)) {
			return value.map(v => v.name).join(', ')
		}
		if (typeof value === 'object') {
			return value.name
		}
		return value || ''
	}

	public static async updateLP({
		id,
		key,
		value,
		lp,
		activityDescription,
		activityKey,
		shouldLog = true
	}: {
		id: string
		key: string
		value: any
		lp: LimitedPartnerData
		activityDescription?: string
		activityKey?: string
		shouldLog?: boolean
	}): Promise<void> {
		try {
			await updateLP({ id, [key]: value })

			toast.success('Limited partner updated')

			const { authUserData, authUserFundData } = store.getState().authentication
			if (!authUserData || !authUserFundData) return

			if (!shouldLog) return

			// Add the activity to the activity feed
			const activity: IActivity = {
				id: nanoid(),
				user: authUserData,
				fund: authUserFundData,
				activity: activityKey || `updated ${key}`,
				description: activityDescription || this.getDescription(value),
				icon: '',
				summary: lp.name,
				limitedPartner: {
					id: lp.id,
					name: lp.name
				},
				createdAt: new Date().toISOString()
			}

			await ActivityService.add(activity)
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to update limited partner')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static async deleteLp(id: string): Promise<void> {
		try {
			await deleteLP(id)

			toast.success('Limited partner deleted')
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to delete limited partner')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static async addLP(lp: ILimitedPartner): Promise<void> {
		try {
			await addLP(lp)

			toast.success('Limited partner added')
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to add limited partner')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static async removeTag(
		lp: ILimitedPartner,
		tag: TagData
	): Promise<void> {
		try {
			await this.updateLP({
				id: lp.id,
				value: lp.tags?.filter(t => t.id !== tag.id),
				key: 'tags',
				lp
			})
		} catch (error: any) {
			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static async updateEmail(id: string, email: string): Promise<void> {
		try {
			// Check if email is taken
			const result = await isEmailTaken(email)
			if (result.data.exists) throw new Error('Email already taken')

			await this.updateLP({ id, key: 'email',  value: email, lp: { id, name: '' } })

			toast.success('Email updated')
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to update email')

			Logger.error(error)
			if (error.message !== 'Email already taken') {
				Errorhandler.captureException(error)
			}
		}
	}

	public static async getTotalEmails(
		userId: string,
		lp: ILimitedPartner
	): Promise<number> {
		try {
			if (!lp.email || lp.teamMemberEmails) return 0
			const emails = [lp.email, ...(lp.teamMemberEmails || [])].filter(Boolean)
			const count = await getEmailCount(userId, emails)
			return count
		} catch (error: any) {
			Logger.error(error)
			Errorhandler.captureException(error)

			return 0
		}
	}

	public static async getLastMeeting(
		lp: ILimitedPartner
	): Promise<string | null> {
		try {
			if (!lp.email) return null

			const lastMeeting = await getLastEvent(lp.email)
			return lastMeeting?.start.dateTime ?? null
		} catch (error: any) {
			Logger.error(error)
			Errorhandler.captureException(error)

			return null
		}
	}

	public static async removeTeamMember(
		limitedPartner: ILimitedPartner,
		email: string
	) {
		try {
			const updatedFounders = limitedPartner.teamMembers?.filter(
				founder => founder.email !== email
			)
			await updateLP({
				id: limitedPartner.id,
				teamMembers: updatedFounders,
				teamMemberEmails: updatedFounders?.map(f => f.email)
			})

			toast.success('Team member removed')
		} catch (error: any) {
			toast.error(error.message ?? 'Failed to remove team member')

			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static async listenToCapitalCalls({
		authUser,
		callback,
		loadingCallback,
		limitedPartner
	}: {
		authUser?: User
		limitedPartner: ILimitedPartner
		callback: (capitalCalls: ICapitalCall[]) => void
		loadingCallback: (loading: boolean) => void
	}): Promise<() => void> {
		if (!authUser) throw new Error('Not authorized to read filter')

		loadingCallback(true)
		const q = query(
			this.capitalCallsRef,
			where('fund.id', '==', authUser.fund.id),
			where('limitedPartner.id', '==', limitedPartner.id)
		)
		return onSnapshot(q, querySnapshot => {
			const filters = querySnapshot.docs.map(d => d.data() as ICapitalCall)
			callback(filters)
			loadingCallback(false)
		})
	}

	public static async listenToAllCapitalCalls({
		authUser,
	}: {
		authUser?: User
	}): Promise<() => void> {
		if (!authUser) throw new Error('Not authorized to read filter')

		const q = query(
			this.capitalCallsRef,
			where('fund.id', '==', authUser.fund.id)
		)
		return onSnapshot(q, querySnapshot => {
			const capitalCalls = querySnapshot.docs.map(d => d.data() as ICapitalCall)
			store.dispatch(setCapitalCalls(capitalCalls))
		})
	}

	public static async listenToFundLPCapitalCalls({
		authUser
	}: {
		authUser?: User
	}): Promise<() => void> {
		if (!authUser) throw new Error('Not authorized to read filter')

		const q = query(
			this.capitalCallsRef,
			where('fund.id', '==', authUser.fund.id),
			orderBy('createdAt', 'desc')
		)
		return onSnapshot(q, querySnapshot => {
			const capitalCalls = querySnapshot.docs.map(d => d.data() as ICapitalCall)
			store.dispatch(setFundLPCapitalCalls(capitalCalls))
		})
	}
}
