/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as Yup from 'yup'

import type { CountryData, ICompany } from 'interfaces'
import { addCompany, checkIfCompanyExists } from '../api/companies.api'

import { ActivityService } from 'features/activity/service/activity.service'
import { AuthContext } from 'context/AuthContext'
import { ENotificationStatus } from 'features/notifications/enums/notifications.enums'
import type { IActivity } from 'features/activity/interfaces/actvitiy.interface'
import type { INotification } from 'features/notifications/interface/notifications.interface'
import Logger from 'lib/logger'
import { NotificationsService } from 'features/notifications/service/Notifications.service'
import { nanoid } from 'nanoid'
import { selectDefaultCompanyFunnel } from 'features/funnels/funnels.slice'
import { toast } from 'sonner'
import { useAppSelector } from 'app/hooks'
import { useContext } from 'react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'

export default function useCreateCompany({
	onSuccess,
	close
}: {
	onSuccess?: (company: ICompany) => void
	close?: () => void
}) {
	const { authUser } = useContext(AuthContext)
	const navigate = useNavigate()
	const defaultCompanyFunnel = useAppSelector(selectDefaultCompanyFunnel)

	const validationSchema = Yup.object({
		name: Yup.string()
			.required('Name is required')
			.test('unique', 'Company already exists', async value => {
				try {
					const { exists } = await checkIfCompanyExists(
						value.trim().toLowerCase().replaceAll(' ', '-')
					)
					return !exists
				} catch {
					return false
				}
			})
	})

	const formik = useFormik({
		initialValues: {
			id: '',
			name: '',
			location: {},
			tags: [],
			founders: [],
			funnel: {
				id: defaultCompanyFunnel?.id ?? '',
				name: defaultCompanyFunnel?.name ?? '',
				category: defaultCompanyFunnel?.category ?? ''
			},
			website: '',
			traction: '',
			description: '',
			stage: '',
			referralMethod: '',
			founderName: '',
			founderEmail: '',
			deck: null,
			source: 'name'
		},
		validationSchema,
		onSubmit: async values => {
			if (!authUser) return
			try {
				formik.setSubmitting(true)

				close?.()

				const company: ICompany = {
					id: values.id || nanoid(),
					name: values.name,
					slug: values.name.trim().toLowerCase().replaceAll(' ', '-'),
					funnel: formik.values.funnel,
					creator: {
						id: authUser.id,
						name: authUser.name,
						photoUrl: authUser.photoUrl ?? ''
					},
					index: 0,
					location: values.location as CountryData,
					tags: values.tags,
					founders: values.founders || [],
					founderEmails:
						values.founders?.map((founder: { email: any }) => founder.email) ||
						[],
					createdAt: new Date().toISOString(),
					lastUpdatedAt: new Date().toISOString(),
					fund: authUser.fund,
					website: values.website || '',
					amounts: [],
					traction: values.traction || '',
					description: values.description,
					stage: values.stage,
					referralMethod: values.referralMethod,
					source: values.source as any
				}

				if (values.deck) company.deck = values.deck
				if (values.founderName && values.founderEmail) {
					company.founders = [
						...(company.founders || []),
						{
							name: values.founderName,
							email: values.founderEmail
						}
					]
				}

				await addCompany(company)

				const activity: IActivity = {
					id: nanoid(),
					user: authUser,
					fund: authUser.fund,
					activity: `created ${company.name}`,
					description: `created ${company.name}`,
					icon: '',
					summary: company.name,
					company: {
						id: company.id,
						name: company.name
					},
					createdAt: new Date().toISOString()
				}

				ActivityService.add(activity)

				toast.success('Company Created', {
					description: 'You can view the company now',
					action: {
						label: 'View',
						onClick: () => {
							navigate(`/company/${company.id}`)
						}
					}
				})
				
				onSuccess?.(company)

				if (company.source === 'deck') {
					const data: INotification = {
						createdAt: new Date().toISOString(),
						updatedAt: new Date().toISOString(),
						id: nanoid(),
						status: ENotificationStatus.PROGRESS,
						message: 'Company data is being extracted',
						details: `${company.name}'s data is being extracted`,
						fund: authUser.fund,
						user: {
							id: authUser.id,
							name: authUser.name
						},
						metadata: {
							companyId: company.id,
							companyName: company.name
						},
						type: 'company_from_deck'
					}

					NotificationsService.addNotification(data)
				}
			} catch (error: any) {
				toast.error(error.message)
				Logger.error(error)
			} finally {
				formik.setSubmitting(false)
				formik.resetForm()
			}
		}
	})

	return { formik }
}
