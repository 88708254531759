/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { ChevronDown, ChevronRight } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

import Button from 'components/Buttons/Button';
import FoldersApi from '../api/folders.api';
import type { FoldersInterface } from '../interface/folders.interface';
import type { ICompany } from 'interfaces';
import type { IFile } from 'features/files/file.interface';
import Logger from 'lib/logger';
import clsxm from 'utils/clsxm';
import { toast } from 'sonner';

interface CreateComponentProps {
	file?: IFile;
	folder?: FoldersInterface;
	onClose: (value: boolean) => void;
	fileId: string;
	wrapperClass?: string;
	isFolder?: boolean;
	isFile?: boolean;
	company: ICompany;
}

export default function MoveFolder({
	file,
	onClose,
	folder,
	fileId,
	isFolder,
	isFile,
	company,
	wrapperClass
}: CreateComponentProps) {
	const [selectedFolder, setSelectedFolder] = useState<FoldersInterface>(null);
	const [loading, setLoading] = useState(false);
	const {
		moveFile,
		moveFolder,
		listenToFolders,
		folders,
		getFolder,
		hasChildren,
		setFolders
	} = FoldersApi();
	const unsubscribeRef = useRef(null);
	const [subFolders, setSubFolders] = useState<FoldersInterface[]>([]);
	const clickTimeout = useRef(null);
	const DOUBLE_CLICK_DELAY = 300;
	const [clickedIndex, setClickedIndex] = useState<number | null>(null);

	// Start listening to folders when the page loads
	useEffect(() => {
		if (unsubscribeRef.current) {
			unsubscribeRef.current(); // Unsubscribe from the previous listener
		}

		unsubscribeRef.current = listenToFolders(null, company.id);

		// Clean up the listener when the component unmounts or company.id changes
		return () => {
			if (unsubscribeRef.current) {
				unsubscribeRef.current();
			}
		};
	}, [company.id]);

	const handleMoving = async () => {
		if (!selectedFolder) return;
		try {
			setLoading(true);
			if (isFile) {
				await moveFile(fileId, selectedFolder.id);
				toast.success('File moved successfully');
			} else if (isFolder) {
				await moveFolder(fileId, selectedFolder.id);
				toast.success('Folder moved successfully');
			}
			onClose(false);
		} catch (error) {
			Logger.error(error);
		} finally {
			setLoading(false);
			setSelectedFolder(null);
		}
	};

	const handleChildrenChecking = async (f: FoldersInterface) => {
		// check if the folders to be displayed has children
		try {
			const subFolders = await getFolder(f.id, f.companyId);
			const subFoldersWithChildren = await Promise.all(
				subFolders.map(async subFolder => {
					const childrenExist = await hasChildren(subFolder.id);
					return {
						...subFolder,
						hasChildren: childrenExist
					};
				})
			);
			setFolders(subFoldersWithChildren);
		} catch (error) {
			Logger.error(
				'Error fetching subfolders or checking for children:',
				error
			);
			toast.error('Error fetching subfolders', { description: error.message });
		}
	};

	const handleDoubleClick = async (f: FoldersInterface) => {
		setSelectedFolder(null);
		setSubFolders(prevSubFolders => {
			const folderExists = prevSubFolders.some(x => x.id === f.id);
			if (!folderExists) {
				return [...prevSubFolders, f];
			}
			return prevSubFolders;
		});

		handleChildrenChecking(f);
	};

	const folderClick = async (f: FoldersInterface) => {
		if (clickTimeout.current) {
			clearTimeout(clickTimeout.current);
			clickTimeout.current = null;
			handleDoubleClick(f);
		} else {
			clickTimeout.current = setTimeout(() => {
				setSelectedFolder(f);
				clickTimeout.current = null;
			}, DOUBLE_CLICK_DELAY);
		}
	};

	// handle subfolders click on the folder path
	const handleFolderClick = async (index: number, folder: FoldersInterface) => {
		setClickedIndex(index);

		// Update the subFolders to include only up to the clicked folder
		setSubFolders(prevSubFolders => {
			const updatedSubFolders = prevSubFolders.slice(0, index + 1);
			if (!updatedSubFolders.some(f => f.id === folder.id)) {
				updatedSubFolders.push(folder);
			}
			return updatedSubFolders;
		});
		// Fetch folders and update the path
		await getFolder(folder.id, folder.companyId);
		handleChildrenChecking(folder);
	};

	const rootFolders = () => {
		if (unsubscribeRef.current) {
			unsubscribeRef.current(); // Unsubscribe from the previous listener
		}
		unsubscribeRef.current = listenToFolders(null, company.id);
		// Clean up the listener when the component unmounts or parentId changes
		setSubFolders([]);
		return () => {
			if (unsubscribeRef.current) {
				unsubscribeRef.current();
			}
		};
	};

	return (
		<div className={clsxm('z-50 w-full gap-1 rounded-md', wrapperClass)}>
			<div className=''>
				<div className='mb-3 flex items-center gap-3'>
					<div className='pl-1 text-base font-bold'>{`Move "${
						file?.name || folder?.name
					}"`}</div>
				</div>

				<div className='mb-3 flex h-10 items-center overflow-x-auto'>
					<div
						onClick={rootFolders}
						className='flex items-center gap-2 hover:cursor-pointer hover:text-blue-600'
					>
						<svg
							className='text-blue-600'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
						>
							<path
								fill='currentColor'
								d='M20 6h-8l-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2m-3 7v4h-2v-3h-2v3h-2v-4H9l5-4l5 4Z'
							/>
						</svg>
						Home
						{subFolders.length > 0 && <ChevronRight size={20} />}
					</div>
					<div className='flex w-full items-center overflow-auto'>
						{subFolders.map((folder: FoldersInterface, index: number) => {
							return (
								<div key={folder.id} className='flex'>
									<div
										className='flex items-center hover:cursor-pointer hover:text-blue-500'
										onClick={() => handleFolderClick(index, folder)}
									>
										<p className=''>{folder.name}</p>
										{subFolders.at(-1).id === folder.id ? (
											<ChevronDown size={20} />
										) : (
											<ChevronRight size={20} />
										)}
									</div>
								</div>
							);
						})}
					</div>
				</div>

				{folders.length > 0 &&
					folders.map((folder: FoldersInterface) => {
						return (
							<div
								key={folder.id}
								onClick={() => {
									folderClick(folder);
								}}
								className={`${
									folder.id === selectedFolder?.id ? 'bg-slate-200' : ''
								} mb-2 flex items-center p-2 hover:cursor-pointer hover:bg-slate-100`}
							>
								<div className='flex w-fit flex-1 items-center'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='mr-2 size-5 text-blue-500'
									>
										<path d='M19.5 21a3 3 0 0 0 3-3v-4.5a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h15ZM1.5 10.146V6a3 3 0 0 1 3-3h5.379a2.25 2.25 0 0 1 1.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 0 1 3 3v1.146A4.483 4.483 0 0 0 19.5 9h-15a4.483 4.483 0 0 0-3 1.146Z' />
									</svg>
									{folder.name}
								</div>
								{folder.hasChildren && (
									<ChevronRight size={20} className='justify-end' />
								)}
							</div>
						);
					})}
				{folders.length === 0 && (
					<div className='my-5 text-center'>No folders available</div>
				)}
				<div className='mt-5 flex justify-end gap-3'>
					<Button
						variant='danger'
						disabled={loading}
						onClick={() => {
							onClose(false);
							setSelectedFolder(null);
							setSubFolders([]);
						}}
					>
						Close
					</Button>
					<Button
						onClick={handleMoving}
						isLoading={loading}
						disabled={!selectedFolder || loading}
					>
						Move
					</Button>
				</div>
			</div>
		</div>
	);
}
