import { listenToConversation, sendMessage } from '../service/chat.service'
import { useContext, useEffect, useRef, useState } from 'react'

import { AuthContext } from 'context/AuthContext'
import { AutosizeTextarea } from 'components/ui/autosizetextarea'
import ChatBubble from './ChatBubble'
import type { IChat } from '../interfaces/chat.interface'
import type { ICompany } from 'interfaces'
import { SendHorizontal } from 'lucide-react'
import clsxm from 'utils/clsxm'
import { nanoid } from 'nanoid'
import { selectMessages } from '../redux/conversation.slice'
import { useSelector } from 'react-redux'

interface ChatPanelProps {
	company: ICompany
}
function ChatPanel({ company }: ChatPanelProps) {
	const { authUser } = useContext(AuthContext)
	const conversationRef = useRef<HTMLDivElement>(null)
	const conversation = useSelector(selectMessages)

	const [prompt, setPrompt] = useState('')

	const scrollToBottom = ({
		scrollBehavior = 'smooth'
	}: {
		scrollBehavior: 'auto' | 'instant' | 'smooth'
	}) => {
		const chatContainer = conversationRef.current as HTMLDivElement
		const chatScrollHeight = chatContainer.scrollHeight
		chatContainer.scrollTo({
			top: chatScrollHeight - 100,
			behavior: scrollBehavior
		})
	}

	useEffect(() => {
		if (!authUser?.id) return
		const unsubscribe = listenToConversation(authUser.id, company.id)
		// eslint-disable-next-line consistent-return
		return () => unsubscribe()
	}, [authUser?.id, company.id])

	useEffect(() => {
		scrollToBottom({ scrollBehavior: 'instant' })
	}, [conversation])

	async function submitPrompt() {
		if (!authUser) return
		if (prompt.trim().length === 0) return
		if (!company.oracleData) return

		const message: IChat = {
			id: nanoid(),
			user: {
				id: authUser.id,
				name: authUser.name,
				photoUrl: authUser.photoUrl || ''
			},
			company: {
				id: company.id,
				name: company.name,
				website: company.website || ''
			},
			oracleCompanyData: {
				id: company.oracleData.id,
				conversationId: company.oracleData.conversationId || ''
			},
			message: prompt,
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString()
		}

		sendMessage(message)

		setPrompt('')
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault()
			submitPrompt()
		}
	}

	return (
		<div className='relative flex h-full flex-col overflow-hidden bg-gray-100'>
			<div className='flex-1 overflow-auto p-4 pb-24' ref={conversationRef}>
				{conversation.map(message => (
					<ChatBubble
						key={message.id}
						role={message.isResponse ? 'assistant' : 'user'}
						message={message}
					/>
				))}
			</div>
			<div className='absolute bottom-0 left-0 right-0 flex items-center  bg-inherit p-4 pr-0'>
				<AutosizeTextarea
					value={prompt}
					onKeyDown={handleKeyDown}
					onChange={evt => setPrompt(evt.target.value)}
					className={clsxm(
						'flex-1 resize-none appearance-none  rounded border-gray-200  bg-white',
						'font-medium  placeholder-primary placeholder-opacity-50 outline-none focus:ring-0'
					)}
					placeholder='Compose message....'
				/>

				<button
					type='button'
					className={clsxm(
						` flex h-12 w-12 items-center justify-center text-primary disabled:text-gray-400`
					)}
					onClick={submitPrompt}
				>
					<SendHorizontal size={20} />{' '}
					<span className='sr-only'>Send message</span>
				</button>
			</div>
		</div>
	)
}

export default ChatPanel
