import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import type { ILineChartData } from "../interfaces/reports.interface";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CustomizedYAxisTick(props: any) {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor="end"
        fill="#666"
        fontSize={14}
      >
        {payload.value}%
      </text>
    </g>
  );
}

interface Props {
  data: ILineChartData[],
  width?: number | string,
  height?: number | string
}

export default function SpokReportLineChart(
  {
    data,
    height = 340,
    width = "100%"
  }: Props
) {
  return (
    <div style={{width, height}}>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 30,
            right: 0,
            left: -30,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={{fontSize: 0}} tickSize={0} tickMargin={10}  />
          <YAxis tick={<CustomizedYAxisTick/>} tickSize={4} tickMargin={5} />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            fill="#8884d8"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
