/* eslint-disable @typescript-eslint/no-extraneous-class */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import type { ITableColumn } from 'features/dataTable/interfaces/datatable.interface'
import Logger from 'lib/logger'
import { Errorhandler } from 'lib/sentry'
import { updateColumn, updateColumns } from '../columns.api'

export class ColumnsService {
	public static async updateColumns(columns: ITableColumn[]) {
		try {
			await updateColumns(columns)
		} catch (error: any) {
			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}

	public static async editColumn(id: string, data: Partial<ITableColumn>) {
		try {
			await updateColumn(id, data)
		} catch (error: any) {
			Logger.error(error)
			Errorhandler.captureException(error)
		}
	}
}
