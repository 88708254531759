import {} from 'react-day-picker'

/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react'

import Button from 'components/Buttons/Button'
import { Check } from 'lucide-react'
import type { IOnboardingData } from 'interfaces/onboarding.interface'
import { ImSpinner10 } from 'react-icons/im'
import { auth } from 'lib/firebase'
import clsxm from 'utils/clsxm'
import { listenToOnboardingData } from '../service/onboarding.service'

export default function ProcessingOnboarding() {
	const invitationId = auth.currentUser?.uid ?? ''

	// const navigate = useNavigate()

	const [data, setData] = useState<IOnboardingData>()

	useEffect(() => {
		if (!invitationId) return

		const unsubscribe = listenToOnboardingData(invitationId, data => {
			setData(data)
		})

		return () => unsubscribe()
	}, [invitationId])

	return (
		<div>
			<div className='flex w-full flex-col items-center justify-center'>
				<div className='flex items-center space-x-2'>
					<ImSpinner10
						className={
							Object.values(data?.statuses ?? {}).includes('in_progress')
								? 'animate-spin'
								: ''
						}
					/>
					<span className='text-lg'>Processing</span>
				</div>
				<p className='mt-2 text-sm text-gray-500'>
					We are creating your fund and inviting partners
				</p>
			</div>
			<ul className='mt-8 list-none text-left  text-spok-gray'>
				<li
					className={clsxm(
						'flex items-center gap-4',
						data?.statuses?.creatingFund === 'in_progress' &&
							'italic text-blue-600',
						data?.statuses?.creatingFund === 'completed' && 'text-green-600'
					)}
				>
					Creating Fund
					{data?.statuses?.creatingFund === 'in_progress' && (
						<ImSpinner10 className='animate-spin' />
					)}
					{data?.statuses?.creatingFund === 'completed' && <Check />}
				</li>
				<li
					className={clsxm(
						'flex items-center gap-4',
						data?.statuses?.invitingPartners === 'in_progress' &&
							'italic text-blue-600',
						data?.statuses?.invitingPartners === 'completed' && 'text-green-600'
					)}
				>
					Inviting Partners
					{data?.statuses?.invitingPartners === 'in_progress' && (
						<ImSpinner10 className='animate-spin' />
					)}
					{data?.statuses?.invitingPartners === 'completed' && <Check />}
				</li>
				{/* <li
					className={clsxm(
						'flex items-center gap-4',
						data?.statuses?.extractingData === 'in_progress' &&
							'italic text-blue-600',
						data?.statuses?.extractingData === 'completed' && 'text-green-600'
					)}
				>
					Processing Data
					{data?.statuses?.extractingData === 'in_progress' && (
						<ImSpinner10 className='animate-spin' />
					)}
					{data?.statuses?.extractingData === 'completed' && <Check />}
				</li> */}
			</ul>

			<Button
				disabled={data?.statuses?.creatingFund !== 'completed'}
				onClick={async () => {
					// navigate('/dashboard')

					// relaod the page
					window.location.replace('/dashboard')
				}}
				className='mt-8'
			>
				Go To Dashboard
			</Button>
		</div>
	)
}
