export function getColorCode(text: string): string {
	const firstLetter = text.charAt(0).toLowerCase()

	const colorMap: Record<string, string> = {
		a: '474C2B',
		b: '2F3E69',
		c: '583524',
		d: '264F46',
		e: '3D2B2B',
		f: '2F3E69',
		g: '583524',
		h: '264F46',
		i: '3D2B2B',
		j: '2F3E69',
		k: '474C2B',
		l: '2F3E69',
		m: '583524',
		n: '264F46',
		o: '3D2B2B',
		p: '2F3E69',
		q: '583524',
		r: '264F46',
		s: '3D2B2B',
		t: '2F3E69',
		u: '2F3E69',
		v: '474C2B',
		w: '2F3E69',
		x: '583524',
		y: '264F46',
		z: '3D2B2B'
	}

	const defaultColor = '000000' // Default color if no match is found

	const firstColor = colorMap[firstLetter] || defaultColor

	return firstColor
}
