import type { ICompany, ILimitedPartner } from 'interfaces'
import type {
	ISharedLinkView,
	ISharedLinkViewGroup
} from 'features/sharedlinks/interfaces/shared_link_view.interace'

import Button from 'components/Buttons/Button'
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
import { CaretSortIcon } from '@radix-ui/react-icons'
import type { ColumnDef } from '@tanstack/react-table'
import { DataTable } from 'features/dataTable/DataTable'
import Logger from 'lib/logger'
import { ShareCompany } from 'features/companies/view/ShareCompany'
import { secondsToHms } from 'utils'

interface Props {
	views: ISharedLinkViewGroup[]
	relevantPeople: ILimitedPartner[]
	isLoading: boolean
	company: ICompany
}

export default function CompanyAnalyticsSummaryTable({
	views,
	isLoading,
	relevantPeople,
	company
}: Props) {
	const relevantPeopleIntoViews: ISharedLinkView[] = relevantPeople
		.filter(person => !views.some(view => view.user.email === person.email))
		.map(person => {
			return {
				id: person.id,
				name: person.name,
				time: 0,
				visitedPages: 0,
				user: { name: person.name, email: person.email || '' },
				startTime: '',
				endTime: '',
				linkData: { id: '', url: '', access: 'Not Sent' },
				company: { id: company.id, name: company.name },
				fund: person.fund,
				createdAt: ''
			}
		})

	const columns: ColumnDef<any>[] = [
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Name
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>
					{row.original.user.name ||
						relevantPeople.find(item => item.email === row.original.user.email)
							?.name ||
						'-'}
				</div>
			)
		},
		{
			accessorKey: 'email',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Email
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>{row.original.user.email}</div>
			)
		},
		// {
		// 	accessorKey: 'status',
		// 	header: ({ column }) => (
		// 		<Button
		// 			className='truncate px-0'
		// 			variant='ghost'
		// 			onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
		// 		>
		// 			Status
		// 			<CaretSortIcon className='ml-2 h-4 w-4' />
		// 		</Button>
		// 	),
		// 	cell: ({ row }) => <div className='truncate'>{row.original.status}</div>
		// },
		{
			accessorKey: 'access',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Access
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate capitalize'>
					{row.original.linkData.access}
				</div>
			)
		},
		{
			accessorKey: 'visitedPages',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Visited Pages
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate capitalize'>
					{row.original.visitedPages || '-'}
				</div>
			)
		},
		{
			accessorKey: 'totalTime',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Total Time
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='truncate'>
					{row.original.time ? secondsToHms(row.original.time) : '-'}
				</div>
			)
		},
		{
			accessorKey: 'generatedLink',
			header: ({ column }) => (
				<Button
					className='truncate px-0'
					variant='ghost'
					onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
				>
					Link
					<CaretSortIcon className='ml-2 h-4 w-4' />
				</Button>
			),
			cell: ({ row }) => (
				<div className='flex justify-center whitespace-nowrap'>
					{row.original.linkData.url ? (
						<a
							href={row.original.linkData.url}
							target='_blank'
							rel='noreferrer'
						>
							<div className='group flex w-fit cursor-pointer items-center gap-1 text-ellipsis rounded  bg-blue-200 px-3 py-1 text-xs font-semibold text-blue-800'>
								Open page
							</div>
						</a>
					) : (
						<ShareCompany
							companies={[company]}
							trigger={
								<div className='group flex w-fit cursor-pointer items-center gap-1 text-ellipsis rounded  bg-blue-700 px-3 py-1 text-xs font-semibold text-white'>
									Generate link
								</div>
							}
						/>
					)}
				</div>
			)
		}
	]

	return (
		<div className='flex flex-col'>
			<DataTable
				entity='none'
				className=''
				columns={columns}
				data={[...views, ...relevantPeopleIntoViews]}
				loading={isLoading}
				filterPlaceholder='Search....'
				onColumnUpdate={(updatedColumns: any) => {
					Logger.info('Updated columns', updatedColumns)
				}}
				onColumnVisibilityChange={(columnId: string, isVisible: boolean) => {
					Logger.info('Column visibility changed', columnId, isVisible)
				}}
			/>
		</div>
	)
}
